import { Box } from '@material-ui/core'
import { InformationItem } from 'components/InvestorProfile/Sidebar/InvestorInformation'
import { AvatarSection } from './AvatarSection'
import { ISearchInputDetail } from '../interfaces'
import { DescriptionSection } from './DescriptionSection'
import { getNumberValue } from '../helpers'

export const InvestorOverviewSection = ({
  option,
  onNavigation,
}: {
  option: ISearchInputDetail
  onNavigation: () => void
}) => {
  const {
    name,
    investor_url,
    investor_description,
    dimension1,
    number_of_acquisition_rounds,
    number_of_funding_rounds,
    total_acquisition_price_usd,
    total_funding_amount_usd,
    investor_logo_bucket_url,
  } = option.investorMetaData[0] || []

  return (
    <>
      <Box>
        <AvatarSection
          onNavigation={onNavigation}
          name={name}
          logo={investor_logo_bucket_url}
          url={investor_url}
        />
        <DescriptionSection description={investor_description || ''} />
        <Box>
          <InformationItem title="HQ location" data={[null]} icon="company"></InformationItem>
          <InformationItem title="Founded year" data={[null]} icon="calendar"></InformationItem>
          <InformationItem
            title="Top product invested in"
            data={[dimension1]}
            icon="stars"
          ></InformationItem>
          <InformationItem
            title="Total number of funding rounds"
            data={getNumberValue(number_of_funding_rounds)}
            icon="stars"
          ></InformationItem>
          <InformationItem
            title="Total amount invested"
            data={getNumberValue(total_funding_amount_usd, true)}
            icon="stars"
          ></InformationItem>
          <InformationItem
            title="Total number of acquisitions"
            data={getNumberValue(number_of_acquisition_rounds)}
            icon="stars"
          ></InformationItem>
          <InformationItem
            title="Total amount acquired"
            data={getNumberValue(total_acquisition_price_usd, true)}
            icon="stars"
          ></InformationItem>
        </Box>
      </Box>
    </>
  )
}

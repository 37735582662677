import { selectSearchOption } from '../search/SearchSelector'
import { createStructuredSelector } from 'reselect'
import { RootState } from '../../store'
import { selectWatchListId } from '../watch-list/WatchListSelector'
import { selectSubFilter } from '../company-deal/CompanyDealSelector'
import { ISubFilter } from '../../../types/CompanyDealFilter'
import { ISearchInputOption } from 'components/Search/interfaces'

export interface IGlobalData {
  search: {
    options: ISearchInputOption[]
  }
  watchList: {
    watchListId: number | null
  }
  compare: {
    compareFilter: ISubFilter
  }
}

const selectGlobalState = createStructuredSelector<RootState, IGlobalData>({
  search: createStructuredSelector({
    options: selectSearchOption,
  }),
  watchList: createStructuredSelector({
    watchListId: selectWatchListId,
  }),
  compare: createStructuredSelector({
    compareFilter: selectSubFilter,
  }),
  // add multiple source to global state here
})

export { selectGlobalState }

import { ETypeActionEdit, ETypeValue } from '../utils/Profile'

export interface IDataUseCase {
  problem_text: string
  target_client: ITargetClient[]
  current_clients: ICurrentClient[]
  detailed_text: string
  detailed_img_url: string
  marketing_video_url: string
  marketing_pdf_url: string
  contact: string
}

export interface ITargetClient {
  id: string
  value: string
  type: ETypeActionEdit
}

export interface ICurrentClient {
  id: string
  client_id: string
  name: string
  url: string
  logo_url: string
  type: ETypeActionEdit
}

export const INIT_USECASE: IDataUseCase = {
  problem_text: '',
  target_client: [],
  current_clients: [],
  detailed_text: '',
  detailed_img_url: '',
  marketing_video_url: '',
  marketing_pdf_url: '',
  contact: '',
}

export enum EUseCaseType {
  Problem_Statement = 1,
  Current_Clients = 2,
  Target_Segment = 3,
  Marketing_Video_URL = 4,
  Marketing_Material_URL = 5,
  SalesBD_Contact = 6,
  Value_Proposition_Image_Bucket_Url = 7,
  Value_Proposition_Text = 8,
}

export const SourceUseCase = [
  EUseCaseType.Problem_Statement,
  EUseCaseType.Current_Clients,
  EUseCaseType.Value_Proposition_Image_Bucket_Url,
  EUseCaseType.Value_Proposition_Text,
]

export const USECASE_TYPE_ID = new Map<EUseCaseType, keyof IDataUseCase>([
  [EUseCaseType.Problem_Statement, 'problem_text'],
  [EUseCaseType.Target_Segment, 'target_client'],
  [EUseCaseType.Current_Clients, 'current_clients'],
  [EUseCaseType.Marketing_Video_URL, 'marketing_video_url'],
  [EUseCaseType.Marketing_Material_URL, 'marketing_pdf_url'],
  [EUseCaseType.SalesBD_Contact, 'contact'],
  [EUseCaseType.Value_Proposition_Text, 'detailed_text'],
  [EUseCaseType.Value_Proposition_Image_Bucket_Url, 'detailed_img_url'],
])

export const USECASE_LABLE = new Map<EUseCaseType, string>([
  [EUseCaseType.Problem_Statement, 'Problem_Statement'],
  [EUseCaseType.Target_Segment, 'Target_Segment'],
  [EUseCaseType.Current_Clients, 'Current_Clients'],
  [EUseCaseType.Marketing_Video_URL, 'Marketing_Video_URL'],
  [EUseCaseType.Marketing_Material_URL, 'Marketing_Material_URL'],
  [EUseCaseType.SalesBD_Contact, 'SalesBD_Contact'],
  [EUseCaseType.Value_Proposition_Text, 'Value_Proposition_Text'],
  [EUseCaseType.Value_Proposition_Image_Bucket_Url, 'Value_Proposition_Image_Bucket_Url'],
])

export const USECASE_TYPE_ID_MAP = new Map<keyof IDataUseCase, EUseCaseType>([
  ['problem_text', EUseCaseType.Problem_Statement],
  ['target_client', EUseCaseType.Target_Segment],
  ['current_clients', EUseCaseType.Current_Clients],
  ['marketing_video_url', EUseCaseType.Marketing_Video_URL],
  ['marketing_pdf_url', EUseCaseType.Marketing_Material_URL],
  ['contact', EUseCaseType.SalesBD_Contact],
  ['detailed_text', EUseCaseType.Value_Proposition_Text],
  ['detailed_img_url', EUseCaseType.Value_Proposition_Image_Bucket_Url],
])

export interface IChangeRequestsUseCase {
  idRequests: string
  id: string
  typeId: EUseCaseType
  sourceValue: string | string[]
  newValue: string | string[]
  reason: string
  title: string
  typeValue: ETypeValue
  notSend?: boolean
  typeAction?: ETypeActionEdit
  currentClient?: ICurrentClient[]
}

export const TITLE_NAME_USECASE: Record<keyof IDataUseCase, string> = {
  problem_text: 'Problem Statements Addressed',
  target_client: 'Target Client Segment',
  current_clients: 'Current Clients',
  detailed_text: 'Detailed Value Proposition',
  detailed_img_url: 'Detailed Value Proposition - Image',
  marketing_video_url: 'Marketing Video',
  marketing_pdf_url: 'Marketing Materials',
  contact: 'Contact',
}

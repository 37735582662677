import { Typography, makeStyles, useTheme, Box } from '@material-ui/core'
import clsx from 'clsx'
import Flex from 'components/Flex/Flex'
import Icon from 'components/Icon/Icon'
import { passwordMustNotContains } from 'utils/auth/passwordPolicies'

interface IRuleItem {
  message: string
  valid?: boolean
  children?: IRuleItem[]
  hidden?: boolean
}

const useStyles = makeStyles(theme => ({
  card: {
    textAlign: 'left',
    width: '220px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  text12: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  passwordRequirements: {
    color: theme.palette.grey[500],
    fontWeight: 600,
    marginBottom: '10px',
  },
}))

const RuleItem = ({
  status,
  text,
  children,
}: {
  status: 'valid' | 'invalid' | 'pending'
  text: string
  children: IRuleItem[]
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const colorMap: Record<typeof status, string> = {
    valid: theme.palette.primary.main,
    invalid: theme.palette.error.main,
    pending: theme.palette.grey[500],
  }

  return (
    <>
      <Flex style={{ color: colorMap[status] as string }} alignItems="center" gap="6px" mb="10px">
        <Icon name={status === 'invalid' ? 'close' : 'checked'} width={'10px'} height={'10px'} />
        <Typography className={classes.text12}>{text}</Typography>
      </Flex>
      {!!children.length && (
        <Box ml="16px">
          {children
            .filter(i => !i.hidden)
            .map((i, idx) => (
              <RuleItem
                key={idx}
                status={
                  status === 'pending'
                    ? 'pending'
                    : !!i.valid || i.children?.every(c => !!c.valid)
                    ? 'valid'
                    : 'invalid'
                }
                text={i.message}
                children={i.children || []}
              />
            ))}
        </Box>
      )}
    </>
  )
}

const PasswordPolicy = ({
  password = '',
  firstName = '',
  lastName = '',
  email = '',
  hiddeMustNotContain = false,
}: {
  password: string
  firstName?: string
  lastName?: string
  email: string
  hiddeMustNotContain?: boolean
}) => {
  const classes = useStyles()
  const passwordPolicies = (s: string): IRuleItem[] => [
    {
      valid: s.length >= 12 && s.length <= 72,
      message: 'At least 12 characters in length',
    },
    {
      message: 'Must contain:',
      children: [
        {
          valid: !!s.match(/[a-z]/g),
          message: 'Lower case letter',
        },
        {
          valid: !!s.match(/[A-Z]/g),
          message: 'Upper case letter',
        },
        {
          valid: !!s.match(/[0-9]/g),
          message: 'Number',
        },
        // {
        //   valid: !!s.match(/[.,!@#\$%\^&\*]/g),
        //   message: 'Symbol',
        // },
      ],
    },
    {
      message: 'Must not contain:',
      hidden: hiddeMustNotContain,
      children: [
        {
          valid: passwordMustNotContains(password, {
            businessEmail: email,
          }),
          message: 'Part of email',
        },
        {
          valid: passwordMustNotContains(password, { firstName }),
          message: 'First name',
          hidden: !firstName,
        },
        {
          valid: passwordMustNotContains(password, { lastName }),
          message: 'Last name',
          hidden: !lastName,
        },
      ],
    },
  ]
  return (
    <Box className={classes.card}>
      <Typography className={clsx(classes.text, classes.passwordRequirements)}>
        Password Requirements
      </Typography>
      {passwordPolicies(password)
        .filter(i => !i.hidden)
        .map((i, idx) => {
          return (
            <RuleItem
              key={idx}
              status={
                !password
                  ? 'pending'
                  : !!i.valid || i.children?.every(c => !!c.valid)
                  ? 'valid'
                  : 'invalid'
              }
              text={i.message}
              children={i.children || []}
            />
          )
        })}
    </Box>
  )
}

export default PasswordPolicy

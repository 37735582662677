interface IVersionDocument {
  version: string
  acceptedDate: string
}
export interface IPrivacyPolicy extends IVersionDocument {}
export interface ITermsOfUse extends IVersionDocument {}

export interface IProfileUser {
  email: string
  firstName?: string
  recentCompanies?: { id: string; data: any }[]
  companyId?: string
  lastName?: string
  displayName?: string
  acceptedPrivacyPolicyVersions?: IPrivacyPolicy[]
  acceptedTermsOfUseVersions?: ITermsOfUse[]
  pendingApproval?: boolean
  acceptedCookiesDate?: string
  rejectedCookiesDate?: string
}

const { REACT_APP_ENV } = process.env

export const EGroupName = {
  TRIAL: `fct_banksy_${REACT_APP_ENV === 'prod' ? 'prod' : 'dev'}_trial`,
  EXPIRED: `fct_banksy_${REACT_APP_ENV === 'prod' ? 'prod' : 'dev'}_expired`,
  CLIENTS: `fct_banksy_${REACT_APP_ENV === 'prod' ? 'prod' : 'dev'}_client`,
  STARTUPS: `fct_banksy_${REACT_APP_ENV === 'prod' ? 'prod' : 'dev'}_startup`,
  FCT_INTERNAL: `fct_banksy_${REACT_APP_ENV === 'prod' ? 'prod' : 'dev'}_internal`,
  ADMIN: `fct_banksy_${REACT_APP_ENV === 'prod' ? 'prod' : 'dev'}_admin`,
}
export interface IUser {
  profile: IProfileUser
  id: string
  groups: { id: string; name: string }[]
  status: string
  passwordChanged: string | null
  company: {
    name: string
  }
}

export const isTrial = (user: IUser) => user?.groups?.some(g => g.name === EGroupName.TRIAL)

export const isClient = (user: IUser) =>
  isTrial(user) ||
  user?.groups?.some(
    g =>
      g.name === EGroupName.CLIENTS ||
      g.name === EGroupName.FCT_INTERNAL ||
      g.name === EGroupName.ADMIN
  )

export const isStartup = (user: IUser) =>
  !isClient(user) && user?.groups?.some(g => g.name === EGroupName.STARTUPS)

export const isAdmin = (user: IUser) => user?.groups?.some(g => g.name === EGroupName.ADMIN)

export const isExpired = (user: IUser) => user?.groups?.some(g => g.name === EGroupName.EXPIRED)

export const canEditCompany = (user: IUser, companyId: string) =>
  user?.profile?.companyId === companyId

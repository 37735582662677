import { Tooltip, TooltipProps } from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import theme from '../../theme'

const defaultTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      arrow: {
        color: '#fff',
      },
      tooltip: {
        backgroundColor: '#fff',
        color: `${theme.palette.grey['900']}`,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
        borderRadius: '3px',
        padding: 0,
      },
    },
  },
})

const darkTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      arrow: {
        color: `${theme.palette.grey['900']}`,
      },
      tooltip: {
        backgroundColor: `${theme.palette.grey['900']}`,
        color: '#fff',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
        borderRadius: '3px',
        padding: '10px 15px',
      },
    },
  },
})

const whiteTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      arrow: {
        color: '#fff',
      },
      tooltip: {
        backgroundColor: '#fff',
        color: `${theme.palette.grey['900']}`,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)',
        borderRadius: '3px',
        padding: '10px 15px',
      },
    },
  },
})

const FCTTooltip = (props: TooltipProps & { type?: 'dark' | 'white' | 'default' }) => {
  const { type = 'dark' } = props
  const styleTheme = type === 'dark' ? darkTheme : type === 'white' ? whiteTheme : defaultTheme
  return (
    <MuiThemeProvider theme={styleTheme}>
      <Tooltip {...props}></Tooltip>
    </MuiThemeProvider>
  )
}

export default FCTTooltip

import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import { getTitleForPreview } from '../helpers'
import { ISearchInputDetail } from '../interfaces'

const useStyles = makeStyles((theme: Theme) => ({
  nameText: {
    fontWeight: 600,
    marginRight: 10,
    fontSize: 26,
    lineHeight: '34px',
    color: '#0F172A',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  searchType: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    color: '#6C809D',
  },
}))

export const PreviewHeader = ({ option }: { option: ISearchInputDetail }) => {
  const classes = useStyles()

  const { name } = option

  return (
    <Box style={{ marginBottom: 12 }}>
      <div className={classes.searchType}>{getTitleForPreview(option)}</div>
      <Typography className={classes.nameText} title={name}>
        {name}
      </Typography>
    </Box>
  )
}

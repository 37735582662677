import { EXPAND_ROUND1_ID } from 'core/constants/companyFilter'
import React, { useEffect, useState } from 'react'
import { ICompanyDealFilter } from '../../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../../types/Filter'
import { getDealType, setDealTypeFilter } from '../../../utils/DealType'
import { GetDealFilterDataResult_getDealFilterData } from '../../../__generated__/GetDealFilterDataResult'
import { CheckboxTree, ITreeNode } from '../../CheckboxTree/CheckboxTree'
import FCTDropdownListItem from '../../DropdownListItem/FCTDropdownListItem'
import TotalRangeValue from './TotalRangeValue'

const initIsDealType = {
  dealType: [],
  allDealType: [],
}

const FundingItem = (props: IFundingItemProps) => {
  const { filter, setFilter, getFilterData } = props

  const [isDealType, setIsDealType] = useState<{
    dealType: ITreeNode[] | null
    allDealType: ITreeNode[] | null
  }>(initIsDealType)

  const fundingRound1s = getFilterData?.fundingRound1s || []
  const fundingRound2s = getFilterData?.fundingRound2s || []

  const setDealType = (value: (number | string)[]) => {
    setFilter({
      fundingRound1s: [],
      fundingRound2s: [],
      ...setDealTypeFilter(
        value,
        fundingRound1s,
        fundingRound2s,
        'latestFundingRound1s',
        'latestFundingRound2s'
      ),
    })
  }

  useEffect(() => {
    if (
      filter.fundingRound1s?.length ||
      filter.fundingRound2s?.length ||
      filter.latestFundingRound1s?.length ||
      filter.latestFundingRound2s?.length
    ) {
      if (filter.fundingRound1s?.length || filter.fundingRound2s?.length)
        setIsDealType({
          ...isDealType,
          dealType: null,
        })
      else
        setIsDealType({
          ...isDealType,
          allDealType: null,
        })
    } else setIsDealType(initIsDealType)
  }, [
    filter.fundingRound1s,
    filter.fundingRound2s,
    filter.latestFundingRound1s,
    filter.latestFundingRound2s,
  ])

  const dealType = getDealType(
    filter.latestFundingRound1s,
    filter.latestFundingRound2s,
    fundingRound1s,
    fundingRound2s
  )

  return (
    <>
      <FCTDropdownListItem
        isOpen={true}
        title="Total Funding"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <TotalRangeValue
          data={filter}
          setFilter={setFilter}
          tagTotalTo="totalFundingTo"
          tagTotalFrom="totalFundingFrom"
        />
      </FCTDropdownListItem>

      {/* TODO: just displayed, not yet queried */}
      <FCTDropdownListItem
        isOpen={true}
        title="Equity Funding"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <TotalRangeValue
          data={filter}
          setFilter={setFilter}
          tagTotalTo="equityFundingTo"
          tagTotalFrom="equityFundingFrom"
        />
      </FCTDropdownListItem>
      <FCTDropdownListItem
        isOpen={true}
        title="Latest Deal Amount"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <TotalRangeValue
          data={filter}
          setFilter={setFilter}
          tagTotalTo="latestDealAmountTo"
          tagTotalFrom="latestDealAmountFrom"
        />
      </FCTDropdownListItem>
      <FCTDropdownListItem
        isOpen={true}
        title="Latest Deal Type"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <CheckboxTree
          originalData={dealType.dealTypeOriginal}
          selectedIds={dealType.dealTypeIds}
          //TODO: current there is no latest deal type is IPO
          dataTree={dealType.dealTypeTree.filter(e => e.id !== EXPAND_ROUND1_ID.IPO)}
          setFilter={setDealType}
          //TODO: await
          // handleExpandedNodes={handleCheckLatestDeal}
          enableNodes={isDealType.dealType}
        />
      </FCTDropdownListItem>
    </>
  )
}

export default FundingItem

interface IFundingItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
}

import { gql } from '@apollo/client'
import { RestfulResponse } from './RestfulResponse'
export const SEARCH_COMPANIES = gql`
  query SEARCH_COMPANIES($input: String) {
    searchCompanies(input: $input)
      @rest(
        path: "?{args.input}"
        method: "GET"
        type: "SearchCompanies"
        endpoint: "companies_search"
      ) {
      hits
      # status
    }
  }
`
export interface ISearchCompany {
  company_id: [number]
  description?: [string]
  description_encode?: [string]
  name: [string]
  name_encode: [string]
  name_encode_word: string[]
  logo_url?: [string]
  total_fundings: [string]
  industry: String[]
  location_cities: String[]
  location_countries: String[]
  location_region1_names: String[]
  location_region2_names: String[]
  location_region3_names: String[]
  risks: String[]
  sectors: String[]
  value_chains: String[]
  operating_models: String[]
  insurtech_product: String[]
  fintech_product: String[]
  regtech_product: String[]
  tag_names?: string[]
  investor_names: String[]
  investor_types: String[]
  acquisition_names: String[]
  acquisition_types: String[]
  deal_types: string[]
  company_alias: string[]
  last_deal_type: [string]
  all_tag_names?: string[]
  all_tag_group_names?: string[]
  investor_id_names: string[] // ["id1:name1", "id2:name2",...]
  website_keywords: string[]
  website_url: string[]
  product_names: string[]
}
export interface SearchCompaniesResult {
  searchCompanies: RestfulResponse<ISearchCompany>
}

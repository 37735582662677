import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useCompanyDealFilter } from 'store/features/company-deal/CompanyDealHook'
import { initCompanyDealFilter } from 'store/features/company-deal/CompanyDealSlice'
import { GET_SIMILAR_COMPANIES_BY_KEYWORDS } from 'store/operations/queries/GetSimilarCompaniesByKeywords'
import { getCompanyInput } from 'utils/CompanyFilter'
import { MIN_SMART_CLUSTER_DISTANCE, PAGINATION_INIT } from 'utils/consts'
import { GetDealFilterDataResult_getDealFilterData } from '__generated__/GetDealFilterDataResult'
import { DealListViewQueryInput } from '__generated__/globalTypes'
import { GetSimilarCompaniesByKeywords } from './../../__generated__/GetSimilarCompaniesByKeywords'
import { useAnalysisFilter } from 'store/features/analysis/AnalysisHook'
import { IAnalysisFilter } from './../../types/Filter'
import { ICompanyDealFilter } from 'types/CompanyDealFilter'

export function useGetSimilarCompaniesByKeywords(
  filter: ICompanyDealFilter | IAnalysisFilter,
  filterData: GetDealFilterDataResult_getDealFilterData | undefined
) {
  const companyInput = () => {
    const payload = getCompanyInput(initCompanyDealFilter, filterData)
    return {
      ...payload,
      businessLinesRisksValueChains: null,
      //pageNumber and pageSize make no logical sense
      pageNumber: PAGINATION_INIT.PAGE_INDEX,
      pageSize: PAGINATION_INIT.PAGE_SIZE,
      selectedColumns: ['company_id'],
      orderBy: [],
      watchListId: null,
      search: null,
      companyIds: [],
      currentCompanyId: null,
    } as DealListViewQueryInput
  }

  const companyInputForTable = useMemo(companyInput, [])

  const input = {
    variables: {
      input: {
        ...companyInputForTable,
        keywords: filter?.smartCluster?.keywords,
        distance:
          !!Number(filter?.smartCluster?.distance) && filter?.smartCluster?.distance !== null
            ? {
                start: +MIN_SMART_CLUSTER_DISTANCE,
                end: +filter.smartCluster?.distance,
              }
            : null,
      },
    },
    skip: !filter?.smartCluster?.keywords,
  }

  const {
    data: similarCompaniesByKeywordsData,
    loading: loadingSimilarCompaniesByKeywords,
  } = useQuery<GetSimilarCompaniesByKeywords>(GET_SIMILAR_COMPANIES_BY_KEYWORDS, input)

  const { similarCompaniesIds, similarCompaniesByKeywords } = useMemo(() => {
    const similarCompaniesIds: number[] = []
    const distanceStart = !!similarCompaniesByKeywordsData?.getSimilarCompaniesByKeywords
      ?.distanceStart
      ? similarCompaniesByKeywordsData?.getSimilarCompaniesByKeywords?.distanceStart
      : 0.5
    const distanceEnd = !!similarCompaniesByKeywordsData?.getSimilarCompaniesByKeywords?.distanceEnd
      ? similarCompaniesByKeywordsData?.getSimilarCompaniesByKeywords?.distanceEnd
      : 2.1
    const similarCompaniesByKeywords = (
      (filter?.smartCluster?.keywords
        ? similarCompaniesByKeywordsData?.getSimilarCompaniesByKeywords?.items
        : []) || []
    ).map(({ distance, companyId }) => {
      return { distance, companyId }
    })

    const filteredCompanies = similarCompaniesByKeywords.filter(({ distance }) => {
      return distance >= distanceStart && distance <= distanceEnd
    })

    filteredCompanies.forEach(({ companyId }) => {
      similarCompaniesIds.push(companyId)
    })

    return { similarCompaniesIds, similarCompaniesByKeywords }
  }, [similarCompaniesByKeywordsData, filter?.smartCluster?.keywords])
  return {
    similarCompaniesIds,
    similarCompaniesByKeywords,
    loadingSimilarCompaniesByKeywords,
  }
}

import { superscriptNumbers } from '../utils/pptxgenjs/superscriptNumbers'

export enum ChartFootNote {
  CountByGeography = 'countByGeography',
  CountByTopCountry = 'countByTopCountry',
  CountByFoundingYear = 'countByFoundingYear',
  EFByFundingAmount = 'eFByFundingAmount',
  EFByCAGR = 'eFByCAGR',
  EFByFundingMaturity = 'eFByFundingMaturity',
  EFByMegaRounds = 'eFByMegaRounds',
  EFByGeography = 'eFByGeography',
  EFByInvestorType = 'eFByInvestorType',

  MAByAcquisition = 'mAByAcquisition',
  MAByAcquirer = 'mAByAcquirer',
  IposActivityByCount = 'iposActivityByCount',

  Default = 'default',
}

export interface IActionNote {
  index: number
  legend: number | string
  label: string
}

export const footNoteNumbers = new Map<ChartFootNote, IActionNote>([
  [ChartFootNote.CountByTopCountry, { index: 0, legend: '', label: superscriptNumbers.one }],
  [ChartFootNote.CountByFoundingYear, { index: 0, legend: '', label: superscriptNumbers.one }],
  [ChartFootNote.EFByFundingAmount, { index: 0, legend: '', label: superscriptNumbers.one }],
  [ChartFootNote.EFByCAGR, { index: 0, legend: '', label: superscriptNumbers.two }],
  [ChartFootNote.EFByFundingMaturity, { index: 6, legend: '', label: superscriptNumbers.one }],
  [ChartFootNote.EFByMegaRounds, { index: 0, legend: '', label: superscriptNumbers.one }],
  [ChartFootNote.EFByGeography, { index: -1, legend: '', label: superscriptNumbers.one }],
  [ChartFootNote.EFByInvestorType, { index: -1, legend: '', label: superscriptNumbers.one }],

  [ChartFootNote.MAByAcquisition, { index: 0, legend: '', label: '' }],
  [ChartFootNote.MAByAcquirer, { index: -1, legend: '', label: superscriptNumbers.one }],

  [ChartFootNote.Default, { index: -1, legend: '', label: '' }],
])

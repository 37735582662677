import { yupResolver } from '@hookform/resolvers/yup'
import { Typography, useTheme } from '@material-ui/core'
import SubmitButton from 'components/Authentication/BusinessEmail/SubmitButton'
import { IField, FormField } from 'components/Authentication/FormField/FormField'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

interface IForgotPasswordData {
  businessEmail: string
}

const ForgotPasswordForm = ({
  handleSubmit,
}: {
  handleSubmit: (data: IForgotPasswordData) => void | Promise<void>
}) => {
  const [loading, setLoading] = useState(false)

  const field: IField<IForgotPasswordData> = { name: 'businessEmail', label: 'Business Email' }

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
    trigger,
  } = useForm<IForgotPasswordData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      businessEmail: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        businessEmail: yup
          .string()
          .required('This field is required.')
          .email('Please enter a valid email address.'),
      })
    ),
  })

  return (
    <form
      id="forgotPasswordForm"
      onSubmit={async e => {
        try {
          setLoading(true)
          await handleSubmitForm(d => handleSubmit(d))(e)
        } finally {
          setLoading(false)
        }
      }}
    >
      <FormField
        control={control}
        field={field}
        error={errors[field.name]}
        onBlur={() => {
          control.setValue(field.name, control.getValues(field.name)?.trim() || '')
          trigger(field.name)
        }}
      />

      <SubmitButton
        form="forgotPasswordForm"
        disabled={!isValid}
        text="Request Reset Link"
        loading={loading}
      />
    </form>
  )
}

export default ForgotPasswordForm

export const ForgotPasswordTitle = () => {
  const theme = useTheme()

  return (
    <>
      <Typography
        style={{
          color: theme.palette.grey[900],
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '34px',
          marginBottom: '10px',
        }}
        component="h3"
      >
        Forgot Password
      </Typography>
      <Typography
        style={{
          color: theme.palette.grey[600],
          fontSize: 14,
          lineHeight: '22px',
          marginBottom: '32px',
        }}
      >
        Enter the email address associated with your account and we'll send you a link to reset your
        password.
      </Typography>
    </>
  )
}

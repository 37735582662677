import { useDispatch, useSelector } from 'react-redux'
import { selectNumOfNotifications } from './NotificationSelector'
import {
  updateNumOfNotifications,
  increaseNumOfNotifications,
  updateNotificationStatus,
} from './NotificationSlice'

const useNumOfNotifications = () => {
  const dispatch = useDispatch()
  const state = useSelector(selectNumOfNotifications)
  const update = (payload: number) => {
    dispatch(updateNumOfNotifications(payload))
  }

  const increase = (payload: number = 1) => {
    dispatch(increaseNumOfNotifications(payload))
  }

  const updateNotiStatus = (payload: boolean) => {
    dispatch(updateNotificationStatus(payload))
  }

  return { state, update, increase, updateNotiStatus }
}

export { useNumOfNotifications }

import Loading from 'components/Loading'
import { KnowledgeHubProvider } from 'contexts/KnowledgeHubContext'
import { lazy, PropsWithChildren, Suspense } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import KnowledgeHubTemplate from 'templates/KnowledgeHubTemplate'
import { useKnowledgeHubContext } from 'contexts/KnowledgeHubContext'
import KnowledgeHubSidebar from 'components/KnowledgeHub/KnowledgeHubSidebar/KnowledgeHubSidebar'
import KnowledgeHubBreadcrumbsContainer, {
  useKnowledgeBreadcrumbs,
} from 'containers/KnowledgeHubBreadcrumbsContainer'
import { Box } from '@material-ui/core'
import KnowledgeFooter from 'components/KnowledgeHub/KnowledgeFooter'
import KnowledgeHeader from 'components/KnowledgeHub/KnowledgeHeader'
import KnowledgeHubSearchContainer from 'containers/KnowledgeHubSearchContainer'
import componentLoader from 'utils/componentLoader'

const KnowledgeHubPage = lazy(() => componentLoader(() => import('pages/KnowledgeHubPage')))
const KnowledgeHubCategoryContainer = lazy(() =>
  componentLoader(() => import('containers/KnowledgeHubCategoryContainer'))
)
const KnowledgeHubTopicContainer = lazy(() =>
  componentLoader(() => import('containers/KnowledgeHubTopicContainer'))
)
const KnowledgeHubSearchPage = lazy(() =>
  componentLoader(() => import('pages/KnowledgeHubSearchPage'))
)

const KnowledgeHubRouter = () => {
  const { path } = useRouteMatch()

  return (
    <KnowledgeHubProvider>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={`${path}`} component={KnowledgeHubPage} />
          <Route
            path={`${path}/cate/:slug`}
            component={() => (
              <KnowledgeHubTemplateWrapper>
                <KnowledgeHubCategoryContainer />
              </KnowledgeHubTemplateWrapper>
            )}
          />
          <Route
            path={`${path}/topic/:slug`}
            component={() => (
              <KnowledgeHubTemplateWrapper>
                <KnowledgeHubTopicContainer />
              </KnowledgeHubTemplateWrapper>
            )}
          />

          <Route path={`${path}/search`} component={KnowledgeHubSearchPage} />
        </Switch>
      </Suspense>
    </KnowledgeHubProvider>
  )
}

export default KnowledgeHubRouter

const Nav = () => {
  const { categories } = useKnowledgeHubContext()
  const { getBreadCrumbs } = useKnowledgeBreadcrumbs()

  return (
    <KnowledgeHubSidebar
      categories={categories.sort((a, b) => +a['Ordinal number'] - +b['Ordinal number'])}
      slug={getBreadCrumbs().map(i => i.slug)}
    />
  )
}

const KnowledgeHubTemplateWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <KnowledgeHubTemplate
      Nav={<Nav />}
      Header={<KnowledgeHeader />}
      Search={
        <Box width={'684px'}>
          <KnowledgeHubSearchContainer />
        </Box>
      }
      Breadcrumbs={
        <Box mb="20px">
          <KnowledgeHubBreadcrumbsContainer />
        </Box>
      }
      Footer={<KnowledgeFooter />}
    >
      {children}
    </KnowledgeHubTemplate>
  )
}

import { useQuery } from '@apollo/client'
import { Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { omit, concat, flatMapDeep, find } from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import FCTButton from '../../components/Button/FCTButton'
import FCTIcon from '../../components/Icon/Icon'
import { ModalUpdateWatchListFilter } from '../../components/WatchList/ModalUpdateWatchListFilter'
import { EWatchListType, SelectTabWatchList } from '../../components/WatchList/SelectTabWatchList'
import { DEFAULT_DATA_MAX } from '../../containers/EDCompanyDealFilterContainer'
import { GetWatchListContext } from '../../features/watch-list/GetWatchList'
import { useAnalysisFilter } from '../../store/features/analysis/AnalysisHook'
import { initialAnalysisFilter } from '../../store/features/analysis/AnalysisSlice'
import {
  useCompanyDealFilter,
  useSubFilter,
} from '../../store/features/company-deal/CompanyDealHook'
import {
  initCompanyDealFilter,
  initSubFilter,
} from '../../store/features/company-deal/CompanyDealSlice'
import { updateSearchOption } from '../../store/features/search/SearchSlice'
import {
  useWatchListFilter,
  useWatchListId,
  useWatchListName,
  useWatchListTab,
  useWatchListType,
} from '../../store/features/watch-list/WatchListHook'
import { EWatchListTab } from '../../store/features/watch-list/WatchListSlice'
import { GET_DEAL_FILTER_DATA } from '../../store/operations/queries/GetDealFilterData'
import theme from '../../theme'
import { useBlockBeforeNavigate } from '../../utils/navigationHook'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
  GetDealFilterDataResult_getDealFilterData_products,
} from '../../__generated__/GetDealFilterDataResult'
import { GetMyWatchlist_getMyWatchlist } from '../../__generated__/GetMyWatchlist'
import { GetWatchlistWereShared_getWatchlistWereShared } from '../../__generated__/GetWatchlistWereShared'
import { GetWatchListSharedContext } from './../../features/watch-list/GetWatchListWereShared'
import { CreateNewWatchList, ETypeAction } from './CreateNewWatchList'
import {
  searchCompanyColumnsVar,
  initSearchCompanyColumns,
  KEY_COLUMN_DISTANCE,
} from '../../store/cache/CompaniesList'
import { searchDealsColumnsVar, initSearchDealsColumns } from '../../store/cache/DealsList'
import { parseFilterOptionWL } from '../../utils/WatchList'
import { CATEGORY_TAXONOMY, TAXONOMY_ID, useResetFilter } from '../../utils/CompanyFilter'
import mixpanel from '../../mixpanel'
import { pathnameTracking } from '../../utils/Tracking'
import { TaxonomyFilterMapping } from 'utils/TaxonomyMapping'
import { getMembers } from 'utils/chips'
import { getChildrenIds } from 'utils/Tree'
import { ITreeData } from 'types/Tree'
import { IAnalysisFilter } from '../../types/Filter'
import { ETaxonomyCategory } from 'utils/FlattenEcoSystem'
import { InternalDbCompanyTypes } from '__generated__/globalTypes'
import { ICompanyDealFilter } from './../../types/CompanyDealFilter'
import { SortDirection } from 'utils/consts'

const useStyles = makeStyles(theme => ({
  root: {},
  buttonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.grey[800],
    fontWeight: 600,
  },
  buttonIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[200]}`,
    },
  },
  button: {
    marginLeft: 12,
    minWidth: 200,
    maxWidth: 350,
    justifyContent: 'start',
    height: '40px',
  },
  popover: {
    minWidth: 200,
    boxSizing: 'border-box',
    border: '1px solid #E2E8F0',
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.06)',
    borderRadius: 3,
    padding: '8px 0px',
  },
  optionSelect: {
    display: 'flex',
    alignItems: 'start',
    padding: '8px 14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[50]}`,
    },
    flexDirection: 'column',
  },
  createList: {
    alignItems: 'center',
    textDecoration: 'none',
    flexDirection: 'row',
  },
  createListText: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 500,
    marginLeft: 8,
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.grey[200],
    marginBottom: '10px',
  },
}))

export const ViewWatchList = (props: ViewWatchListProps) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const isCurrentPage = (url: string) => {
    return history.location.pathname.includes(url)
  }
  const currentPath = history.location.pathname

  const [anchorEl, setAnchorEl] = useState(null)
  const [watchListId, setWatchListId] = useWatchListId()
  const [watchListName, setWatchListName] = useWatchListName()
  const [_, setWatchListType] = useWatchListType()
  const [__, setWatchListTab] = useWatchListTab()
  const getWatchList = useContext(GetWatchListContext)
  const getWatchListShared = useContext(GetWatchListSharedContext)

  const dataWatchList = getWatchList?.dataWatchList
  const dataWatchListShared = getWatchListShared?.dataWatchListShared

  const hasDefaultWL = useMemo(
    () =>
      dataWatchList?.find(e => e.watchlistId === watchListId)?.isDefault ||
      dataWatchListShared?.find(e => e.watchlistId === watchListId)?.isDefault,
    [watchListId, dataWatchList, dataWatchListShared]
  )

  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA)
  const companyFilterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData

  const hasDisableView =
    isCurrentPage('/reports') ||
    isCurrentPage('/search') ||
    isCurrentPage('/watch-lists') ||
    isCurrentPage('/profile')

  const [filter, setAnalysisFilter] = useAnalysisFilter()
  const [____, setWatchListFilter] = useWatchListFilter()
  const [dealsFilter, setCompanyDealFilter] = useCompanyDealFilter()
  const [______, setSubFilter] = useSubFilter()

  const { isBlocked } = useBlockBeforeNavigate()

  const [confirmNavigate, setConfirmNavigate] = useState<boolean>(false)

  const [watchlist, setWatchlist] = useState<
    GetMyWatchlist_getMyWatchlist | GetWatchlistWereShared_getWatchlistWereShared | null
  >(null)

  const productsData = (getFilterData?.products ||
    []) as GetDealFilterDataResult_getDealFilterData_products[]
  const filteredProducts = useMemo(() => {
    return productsData?.filter(product => product.category == filter.category) || []
  }, [productsData, filter])

  const getTreeIndustry = useCallback(
    (id: number, name: string) => {
      const products = productsData.filter(product => product.category == CATEGORY_TAXONOMY.get(id))
      return {
        id: id,
        name: name,
        children: TaxonomyFilterMapping(products || []).map(v => ({
          ...v,
          parentId: id,
        })),
        parentId: id,
      }
    },
    [productsData]
  )

  const productsTree = useMemo(() => {
    return concat(
      getTreeIndustry(TAXONOMY_ID.FINTECH_ID, 'FinTech'),
      getTreeIndustry(TAXONOMY_ID.REGTECH_ID, 'RegTech'),
      getTreeIndustry(TAXONOMY_ID.INSURANCE_ID, 'InsurTech')
    )
  }, [filteredProducts])

  const getChildrenProductIdsById = useCallback(
    (idCurrent: number) => {
      const flatArray = flatMapDeep(productsTree, getMembers)
      const nodes = find(flatArray, ({ id }) => id === idCurrent)
      if (nodes) {
        return getChildrenIds(nodes?.children as ITreeData[])
      } else {
        return []
      }
    },
    [productsTree, getMembers, getChildrenIds]
  )

  const handleOption = (
    watchlist: GetMyWatchlist_getMyWatchlist | GetWatchlistWereShared_getWatchlistWereShared
  ) => {
    const pathname = history.location.pathname
    if (!pathname.includes('/profile')) {
      const watchlistOwner = (watchlist as GetWatchlistWereShared_getWatchlistWereShared)?.sharedBy
      mixpanel.track(`${pathnameTracking(pathname)}_Load Watchlist`, {
        watchlistID: watchlist.watchlistId,
        watchlistName: watchlist.watchlistName,
        watchlistSaveType: !!watchlist.filterOption ? 'Filter' : 'ID',
        ...(watchlistOwner ? { watchlistOwner } : {}),
      })
    }

    setSubFilter(initSubFilter)
    setAnchorEl(null)
    dispatch(updateSearchOption([]))
    setWatchListName(watchlist.watchlistName)
    setWatchListId(watchlist.watchlistId)
    setWatchListType(
      watchlist.filterOption ? EWatchListType.filterConditions : EWatchListType.companyIDs
    )
    resetFilter()
    if (watchlist.filterOption) {
      let newFilter = parseFilterOptionWL(watchlist.filterOption) as ICompanyDealFilter

      if (
        newFilter?.numberOfInvestorsRanges?.end >
        String(companyFilterData?.maxNumOfInvestors || DEFAULT_DATA_MAX)
      ) {
        newFilter = {
          ...newFilter,
          numberOfInvestorsRanges: {
            start: newFilter?.numberOfInvestorsRanges?.start,
            end: String(companyFilterData?.maxNumOfInvestors || DEFAULT_DATA_MAX),
          },
        }
      }

      if (newFilter?.selectedProductId && !newFilter?.products?.length) {
        const childrenIds = getChildrenProductIdsById(newFilter.selectedProductId).map(e => +e)
        newFilter = { ...newFilter, products: [newFilter.selectedProductId, ...childrenIds] }
      }
      if (
        newFilter?.selectedBusinessLineId &&
        newFilter.category == InternalDbCompanyTypes.fintech &&
        !newFilter.businessLines?.length
      ) {
        newFilter = { ...newFilter, businessLines: [newFilter?.selectedBusinessLineId] }
      }

      if (
        newFilter?.selectedBusinessLineId &&
        newFilter.category == InternalDbCompanyTypes.insurtech &&
        !newFilter.valueChains?.length
      ) {
        newFilter = { ...newFilter, valueChains: [newFilter?.selectedBusinessLineId] }
      }

      if (newFilter?.smartCluster?.keywords) {
        newFilter = {
          ...newFilter,
          orderByCompany: [{ field: KEY_COLUMN_DISTANCE, direction: SortDirection.Asc }],
        }
      }
      setAnalysisFilter(newFilter)
      setCompanyDealFilter(newFilter)
      setWatchListFilter(newFilter)
    }
  }

  const resetFilter = () => {
    const isInvestorProfilePage = currentPath.includes('/investor-profile')
    useResetFilter(setAnalysisFilter, initialAnalysisFilter, companyFilterData)
    setSubFilter(initSubFilter)
    useResetFilter(
      setCompanyDealFilter,
      {
        ...initCompanyDealFilter,
        ...(isInvestorProfilePage
          ? {
              category: dealsFilter.category,
              viewBy: dealsFilter.viewBy,
              searchData: dealsFilter.searchData,
              investors: dealsFilter.investors,
              acquirers: dealsFilter.acquirers,
            }
          : {}),
      },
      companyFilterData
    )
  }

  const resetWatchList = () => {
    setWatchListId(null)
    setWatchListName(null)
  }

  const handleSwitchTab = () => {
    if (!dataWatchList?.length && getWatchListShared?.dataWatchListShared?.length) {
      setWatchListTab(EWatchListTab.Shared)
    } else setWatchListTab(EWatchListTab.MyWatchLists)
  }

  const resetColumns = () => {
    searchCompanyColumnsVar(initSearchCompanyColumns)
    searchDealsColumnsVar(initSearchDealsColumns)
  }

  return (
    <div className={classes.root}>
      <FCTButton
        variant="outlined"
        color="default"
        className={classes.button}
        onClick={(event: any) => setAnchorEl(event.currentTarget)}
        endIcon={
          <>
            {!!watchListId && !hasDisableView ? (
              <FCTIcon
                className={classes.buttonIcon}
                name="close1"
                width={10}
                height={10}
                color={theme.palette.grey[400]}
                onClick={(e: any) => {
                  resetWatchList()
                  resetFilter()
                  e.stopPropagation()
                }}
              />
            ) : (
              <FCTIcon
                name="arrowdown"
                width={10}
                height={10}
                style={{
                  marginRight: 8,
                  transform: Boolean(anchorEl) ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                color={theme.palette.grey[400]}
              />
            )}
          </>
        }
      >
        <Typography className={classes.buttonText}>
          {(!hasDisableView && watchListName) || 'Watchlist'}
        </Typography>
        {!hasDisableView && hasDefaultWL && (
          <FCTIcon
            name="stars"
            width={12}
            height={12}
            color="#F3A93C"
            style={{
              marginLeft: 8,
            }}
          />
        )}
      </FCTButton>
      <Popover
        classes={{ paper: classes.popover }}
        id={Boolean(anchorEl) ? 'simple-popover' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SelectTabWatchList
          handleOption={item => {
            if (isBlocked) {
              setWatchlist(item)
              setConfirmNavigate(true)
            } else handleOption(item)
          }}
        ></SelectTabWatchList>
        <div className={classes.line}></div>
        <div
          onClick={() => {
            handleSwitchTab()
            resetWatchList()
            setAnchorEl(null)
            resetFilter()
            resetColumns()
            if (!currentPath.includes('/watch-lists')) {
              mixpanel.track('Watchlist View')
              history.push({ pathname: '/app/watch-lists' })
            }
          }}
          className={clsx(classes.optionSelect, classes.createList)}
        >
          <FCTIcon name="list" width={16} height={16} color={theme.palette.primary.light} />
          <div className={classes.createListText}>Watchlist Management</div>
        </div>
        <CreateNewWatchList
          type={ETypeAction.Create}
          handleComplete={() => {
            setAnchorEl(null)
          }}
        ></CreateNewWatchList>
      </Popover>
      <ModalUpdateWatchListFilter
        confirmNavigate={confirmNavigate}
        setConfirmNavigate={setConfirmNavigate}
        navigateToDestination={() => handleOption(watchlist!)}
      />
    </div>
  )
}

interface ViewWatchListProps {}

import { useDispatch, useSelector } from 'react-redux'
import { ICompanyDealFilter, ISubFilter } from '../../../types/CompanyDealFilter'
import { selectCompanyDealFilter, selectSubFilter } from './CompanyDealSelector'
import { updateCompanyDealFilter, updateSubFilter } from './CompanyDealSlice'

const useCompanyDealFilter = (): [ICompanyDealFilter, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectCompanyDealFilter)
  const update = (state: any) => {
    dispatch(updateCompanyDealFilter(state))
  }
  return [state, update]
}

const useSubFilter = (): [ISubFilter, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectSubFilter)
  const update = (state: any) => {
    dispatch(updateSubFilter(state))
  }
  return [state, update]
}

export { useCompanyDealFilter, useSubFilter }

import { Box, makeStyles } from '@material-ui/core'
import AdminContainer from 'containers/AdminContainer'
import AdminFooter from 'components/Admin/AdminFooter'
import AdminHeader from 'components/Admin/AdminHeader'
import { useContext } from 'react'
import { Redirect } from 'react-router'
import { UserContext } from 'routers/InitUserWrapper'

const useStyles = makeStyles(theme => ({
  page: {
    color: `${theme.palette.grey[800]}`,
    display: 'flex',
    flexDirection: 'column',
  },
}))

const AdminPage = () => {
  const classes = useStyles()
  const { isAdmin } = useContext(UserContext)

  if (!isAdmin) {
    return <Redirect to="/app" />
  }

  return (
    <>
      <Box height="100vh" overflow="auto" className={classes.page}>
        <AdminHeader />
        <AdminContainer />
        <AdminFooter />
      </Box>
    </>
  )
}

export default AdminPage

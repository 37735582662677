import React, { useState } from 'react'
import { GetWatchListProvider } from './GetWatchList'
import { GetWatchListWereShared } from './GetWatchListWereShared'
import { GetDataMembersProvider } from './GetDataMembers'

export const WatchListProvider = (props: { children: React.ReactNode }) => {
  return (
    <>
      <GetWatchListProvider>
        <GetDataMembersProvider>
          <GetWatchListWereShared>{props.children}</GetWatchListWereShared>
        </GetDataMembersProvider>
      </GetWatchListProvider>
    </>
  )
}

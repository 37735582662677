import SearchContainer from '../containers/SearchContainer'
import { WatchListProvider } from '../features/watch-list/WatchListProvider'
import GlobalRouterWrapper from '../routers/GlobalRouterWrapper'
import MainTemplate from '../templates/MainTemplate'
import { useApolloClient } from '../store/ApolloClient'
import { ApolloProvider } from '@apollo/client'
import { useUserContext } from 'routers/InitUserWrapper'

const SearchPage = () => {
  const { client } = useApolloClient()
  const { restricted } = useUserContext()
  if (!client) return null
  return (
    <ApolloProvider client={client}>
      <WatchListProvider>
        <MainTemplate
          disabledHeader={{
            watchlist: restricted.watchlist,
          }}
        >
          <GlobalRouterWrapper>
            <SearchContainer></SearchContainer>
          </GlobalRouterWrapper>
        </MainTemplate>
      </WatchListProvider>
    </ApolloProvider>
  )
}

export default SearchPage

import { gql } from '@apollo/client'

export const GET_WATCH_LIST_WERE_SHARED = gql`
  query GetWatchlistWereShared {
    getWatchlistWereShared {
      watchlistId
      watchlistName
      createdTime
      numOfCompanies
      sharedBy
      isDefault
      isSubcribePlatform
      isSubcribeEmail
      auditTimestamp
      needRequest
      hadRequest
      roleId
      deletedDate
      roleKey
      filterOption
    }
  }
`

import { QueryLazyOptions } from '@apollo/client'
import {
  Avatar,
  Chip,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import { uniqBy } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useUserContext } from 'routers/InitUserWrapper'
import {
  GetCompanyCategoriesTags,
  GetCompanyCategoriesTags_getCompanyCategoriesTags_categoriesTags,
} from '__generated__/GetCompanyCategoriesTags'
import { ICheckTaxonomy, ITop10Data } from '../../../../containers/ProfileSidebarContainer'
import mixpanel from '../../../../mixpanel'
import theme from '../../../../theme'
import {
  FieldNameSimilarCompanies,
  renameLogo,
  TopCompaniesLocation,
  TopCompaniesOption,
} from '../../../../utils/convert/Title'
import {
  ETaxonomyAvatar,
  ETaxonomyCategory,
  ITaxonomyOptions,
} from '../../../../utils/FlattenEcoSystem'
import { CompanyInfoLocation } from '../../../../__generated__/CompanyInfoLocation'
import { GetCompanyInformation } from '../../../../__generated__/GetCompanyInformation'
import {
  GetTopSimilarVariables,
  GetTopSimilar_getTopSimilar,
} from '../../../../__generated__/GetTopSimilar'
import Flex from '../../../Flex/Flex'
import FCTIcon from '../../../Icon/Icon'
import Loading from '../../../Loading'
import FCTTooltip from '../../../Tooltip/Tooltip'
import { EmptyWatchList } from '../../../WatchList/EmptyWatchList'
import { ICompareProps } from '../ProfileSidebarSimilarCompany'
import { GEOGRAPHY_OPTIONS, Top10FilterDialog } from '../Top10FilterDialog'
import { useCompanyProfileContext } from 'contexts/CompanyProfileContext'
import FCTButton from 'components/Button/FCTButton'
import Icon from '../../../Icon/Icon'
import { ProfileContext } from 'features/profile/ProfileContext'
import {
  getCategoryForCompareProps,
  getCategoryFromSpecifyTaxonomy,
  getChipsFromTaxonomy,
  getDimensionIds,
} from 'utils/exportProfile'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%',
      marginBottom: 5,
    },
    tableCell: {
      fontSize: 12,
      padding: '7px 14px',
      lineHeight: '18px',
      color: '#1E293B',
      borderBottom: 0,
    },
    fontBold: {
      fontWeight: 600,
    },
    oneline: { whiteSpace: 'nowrap' },
    linkStyle: {
      color: 'currentcolor',
      textDecoration: 'none',
    },
    chipsFilter: {
      display: 'flex',
      margin: '0px 0 12px',
      overflow: 'auto',
      flexWrap: 'wrap',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
      scrollbarWidth: 'none',
    },
    chipRoot: {
      backgroundColor: '#D4F1E3',
      borderRadius: '3px',
      fontSize: 12,
      lineHeight: '18px',
      color: theme.palette.grey[900],
      margin: '0 8px 8px 0',
      '&:last-child': {
        marginRight: 0,
      },
    },
    options: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      width: '24px',
      height: '24px',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '22px',
      color: theme.palette.grey[800],
      marginRight: '8px',
    },
    containAvatar: {
      objectFit: 'contain',
    },
    loadingContainer: {
      display: 'flex',
      justifiedContent: 'center',
      alignItems: 'center',
      minHeight: '100px',
    },
    button: {
      verticalAlign: 'baseline',
      margin: '0 6px',
      minWidth: 32,
      height: 40,
    },
    filterIcon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: -8,
    },
  })
)

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  })
)(TableRow)

export type IModifiedTopCompanies = Omit<GetTopSimilar_getTopSimilar, '__typename' | 'total'> & {
  total: string
}

export interface IChips {
  label: string
  value?: number
  type?: ETaxonomyAvatar
}

export interface IDataTopCompanies {
  id: number
  name: string
  total: string
}

export interface Location extends Omit<CompanyInfoLocation, '__typename'> {
  isHeadquarter: boolean
}

export interface ITopCompaniesProps {
  companyId: string
  nameCompany: string
  data: IModifiedTopCompanies[] | undefined
  loadingTopCompanies: boolean
  companyInfo: GetCompanyInformation
  getTopSimilar: (options?: QueryLazyOptions<GetTopSimilarVariables> | undefined) => void
  setTop10InfoPDF: React.Dispatch<React.SetStateAction<ITop10Data>>
  hasBusinessValue: ICheckTaxonomy
  open: boolean
  setOpen: (value: boolean) => void
  isPermissionEdit: boolean
  filterChips: IChips[]
  category: string
  setCompareProps: React.Dispatch<React.SetStateAction<ICompareProps>>
  similarTypes: FieldNameSimilarCompanies
  compareUrl: string
}

export const getInitialCategory = (
  categoriesTags:
    | GetCompanyCategoriesTags_getCompanyCategoriesTags_categoriesTags
    | undefined
    | null,
  productLines: ITaxonomyOptions[]
) => {
  if (productLines.filter(item => item.category === ETaxonomyCategory.RegTech).length) {
    return ETaxonomyCategory.RegTech
  } else if (
    (categoriesTags?.valueChains?.some(item => item.is_primary) ||
      categoriesTags?.operatingModels?.some(item => item.is_primary)) &&
    productLines.filter(item => item.category === ETaxonomyCategory.InsurTech)[0]?.isPrimary
  ) {
    return ETaxonomyCategory.InsurTech
  } else {
    return ETaxonomyCategory.FinTech
  }
}

export interface IUserFilterTopCompanies_DefaulValue {
  geography: TopCompaniesLocation
  country: Location | null
  businessValue: number | null
  operatingValue: number | null
  productLine: number | null
}

export interface IEcoSystem {
  category: string
  children: IEcoSystem[]
  description: string
  dimension_desc: string
  id: number
  name: string
  parentId: number
}

export const useFilterTopCompanies = (defaultValue: IUserFilterTopCompanies_DefaulValue) => {
  const [businessValue, setBusinessValue] = useState<number | null>(defaultValue.businessValue)
  const [operatingValue, setOperatingValue] = useState<number | null>(defaultValue.operatingValue)
  const [productLine, setProductLine] = useState<number | null>(defaultValue.productLine)
  const [geography, setGeography] = useState<TopCompaniesLocation>(defaultValue.geography)
  const [country, setCountry] = useState<Location | null>(defaultValue.country)

  return {
    businessValue,
    setBusinessValue,
    operatingValue,
    setOperatingValue,
    productLine,
    setProductLine,
    geography,
    setGeography,
    country,
    setCountry,
  }
}

export const TopCompanies = (props: ITopCompaniesProps) => {
  const classes = useStyles()
  const {
    companyId,
    nameCompany,
    data,
    loadingTopCompanies,
    companyInfo,
    getTopSimilar = () => {},
    setTop10InfoPDF,
    hasBusinessValue,
    open,
    setOpen,
    isPermissionEdit,
    filterChips,
    category,
    setCompareProps,
    similarTypes,
    compareUrl,
  } = props

  const { hasEdit } = useContext(ProfileContext)
  const { isStartupUser } = useUserContext()
  const { companyCategoriesTags, loadingTags } = useCompanyProfileContext()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const categoriesTags = companyCategoriesTags?.getCompanyCategoriesTags?.categoriesTags
  // create Business - Value radio options
  const businessLineValueChains: ITaxonomyOptions[] = useMemo(() => {
    const businessValue: ITaxonomyOptions[] = []

    if (hasBusinessValue.businessLine) {
      businessValue.push(
        ...(categoriesTags?.businessLines || [])
          .filter(item => item && item?.name && item.is_primary && item?.origin_dimension_id)
          .map(item => ({
            avatar: ETaxonomyAvatar.BusinessLine,
            category: ETaxonomyCategory.FinTech,
            isPrimary: item.is_primary!,
            label: item!.name,
            value: +item!.origin_dimension_id!,
          }))
      )
    }

    if (hasBusinessValue.valueChain) {
      businessValue.push(
        ...(categoriesTags?.valueChains || [])
          .filter(item => item && item?.name && item.is_primary && item?.origin_dimension_id)
          .map(item => ({
            avatar: ETaxonomyAvatar.ValueChain,
            category: ETaxonomyCategory.InsurTech,
            isPrimary: item.is_primary!,
            label: item.name,
            value: +item.origin_dimension_id!,
          }))
      )
    }

    return [...businessValue].sort((a, b) => b.isPrimary - a.isPrimary || 0)
  }, [categoriesTags])

  // create Operating model radio options
  const operatingModels: ITaxonomyOptions[] = useMemo(() => {
    const operatingModels: ITaxonomyOptions[] = []

    operatingModels.push(
      ...(categoriesTags?.operatingModels || [])
        .filter(item => item && item?.name && item.is_primary && item?.origin_dimension_id)
        .map(item => ({
          avatar: ETaxonomyAvatar.OperatingModel,
          category: ETaxonomyCategory.InsurTech,
          isPrimary: item.is_primary!,
          label: item!.name,
          value: +item!.origin_dimension_id!,
        }))
    )

    return operatingModels.sort((a, b) => b.isPrimary - a.isPrimary || 0)
  }, [categoriesTags])

  // create Product Line radio options
  const productLines: ITaxonomyOptions[] = useMemo(() => {
    const prim: IEcoSystem[] = (categoriesTags?.ecoSystem || [])
      .filter(Boolean)
      .map((e: string | null) => JSON.parse(e || ''))
      //filter dimension is productline
      .filter(e => e.dimension == 1)

    const primNodes = getLeafNodes(prim)
      .map(item => ({
        avatar: ETaxonomyAvatar.ProductLine,
        category: item.category,
        isPrimary: 1,
        label: item.name,
        value: item.id,
      }))
      .sort((a, b) => b.category.localeCompare(a.category))

    const aux: IEcoSystem[] = (categoriesTags?.ecoSystemAuxiliary || [])
      .filter(Boolean)
      .map((e: string | null) => JSON.parse(e || ''))
    const auxNodes = getLeafNodes(aux).map(item => ({
      avatar: ETaxonomyAvatar.ProductLine,
      category: item.category,
      isPrimary: 0,
      label: item.name,
      value: item.id,
    }))
    return primNodes
  }, [categoriesTags])

  const {
    businessValue,
    setBusinessValue,
    operatingValue,
    setOperatingValue,
    productLine,
    setProductLine,
    geography,
    setGeography,
    country,
    setCountry,
  } = useFilterTopCompanies({
    businessValue: getCategoryFromSpecifyTaxonomy(businessLineValueChains, categoriesTags),
    operatingValue: getCategoryFromSpecifyTaxonomy(operatingModels, categoriesTags),
    productLine: getCategoryFromSpecifyTaxonomy(productLines, categoriesTags),
    geography: TopCompaniesLocation.Global,
    country: null,
  })

  // handle case Fin/Insur + Reg && call api
  useEffect(() => {
    const dimensionIds = getDimensionIds({
      businessLineValueChains,
      productLines,
      operatingModels,
    })
    setCompareProps(prev => ({
      ...prev,
      category: getCategoryForCompareProps({
        businessValue,
        businessLineValueChains,
        productLine,
        productLines,
        operatingValue,
        operatingModels,
        categoriesTags,
      }),
    }))
    buildChips({ dimensionIds, geography, country })
    getTopSimilar({
      variables: {
        input: {
          companyId: +companyId,
          locationType: geography,
          dimensionIds: dimensionIds,
          isHeadquarter: null,
          regionName: 'Global',
        },
      },
    })
  }, [id])

  const handleAgreeFilter = ({
    geography,
    country,
    businessValue,
    operatingValue,
    productLine,
    industry,
  }: {
    geography: TopCompaniesLocation
    country: Location | null
    businessValue: number | null
    operatingValue: number | null
    productLine: number | null
    industry: string
  }) => {
    setOpen(false)
    const dimensionIds: number[] = []
    businessValue && dimensionIds.push(businessValue)
    productLine && dimensionIds.push(productLine)
    operatingValue && dimensionIds.push(operatingValue)

    setBusinessValue(businessValue)
    setOperatingValue(operatingValue)
    setProductLine(productLine)
    setGeography(geography)
    setCountry(country)

    setCompareProps(prev => ({
      ...prev,
      region: {
        locationType: geography,
        regionName: handleRegionName({ country, geography }),
        countryInt: country?.country_int,
      },
    }))

    const taxonomyBusinessValueChain = businessLineValueChains.find(e => e.value == businessValue)
      ?.label
    mixpanel.track(`${isPermissionEdit ? 'FSD' : 'Profile'}_OtherSimilarCompFilter`, {
      locationType: geography,
      regionName: handleRegionName({ country, geography }),
      country: country?.country,
      taxonomyProduct: productLines.find(e => e.value == productLine)?.label,
      taxonomyValuechain:
        industry == ETaxonomyCategory.InsurTech ? taxonomyBusinessValueChain : null,
      taxonomyBusiness: ETaxonomyCategory.FinTech ? taxonomyBusinessValueChain : null,
    })

    buildChips({ dimensionIds, geography, country })
    getTopSimilar({
      variables: {
        input: {
          companyId: +companyId,
          locationType: geography,
          countryInt: country?.country_int,
          dimensionIds: dimensionIds,
          isHeadquarter: !country ? null : country.isHeadquarter,
          regionName: handleRegionName({ country, geography }),
        },
      },
    })
  }

  const buildChips = ({
    dimensionIds,
    country,
    geography,
  }: {
    geography: TopCompaniesLocation
    country: Location | null
    dimensionIds: number[]
  }) => {
    const chips: IChips[] = [
      ...getChipsFromTaxonomy({
        businessLineValueChains,
        productLines,
        operatingModels,
        dimensionIds,
      }),
      { label: handleRegionName({ country, geography }) },
    ]

    setCompareProps(prev => ({ ...prev, filterChips: uniqBy(chips, 'value') }))
    setTop10InfoPDF(prev => ({
      ...prev,
      filterChips: uniqBy(chips, 'value')
        .map(item => item.label)
        .join(', '),
    }))
  }

  const handleRegionName = ({
    country,
    geography,
  }: {
    country: Location | null
    geography: TopCompaniesLocation
  }): string => {
    if (country) {
      const key = GEOGRAPHY_OPTIONS.find(i => i.value === geography)?.key
      if (key && country[key]) {
        return `${country[key]}`
      }
    } else {
      const res = TopCompaniesOption.find(item => item.value === geography && !!item.label)
      return res?.label || ''
    }
    return ''
  }

  return (
    <>
      <div id="chipsFilter">
        <Flex justifyContent="space-between">
          <div className={classes.chipsFilter}>
            {filterChips.map((item, key) => (
              <Chip
                key={key}
                label={item.label}
                classes={{ root: classes.chipRoot }}
                data-testid="chipItems"
              />
            ))}
          </div>

          {!hasEdit && (
            <Flex>
              {!isStartupUser && (
                <FCTTooltip
                  title={<Typography style={{ fontSize: 12 }}>Compare</Typography>}
                  arrow
                  placement="top"
                >
                  <div>
                    <FCTButton
                      size="small"
                      variant="outlined"
                      onClick={() => window.open(compareUrl, '_blank')}
                      className={classes.button}
                      startIcon={
                        <div className={classes.filterIcon}>
                          <Icon
                            name="compare"
                            width={16}
                            height={16}
                            color={theme.palette.primary.light}
                          />
                        </div>
                      }
                      disabled={
                        (similarTypes === FieldNameSimilarCompanies.otherCompanies &&
                          !data?.length) ||
                        !nameCompany
                      }
                    />
                  </div>
                </FCTTooltip>
              )}

              <FCTTooltip
                title={<Typography style={{ fontSize: 12 }}>Filter</Typography>}
                arrow
                placement="top"
              >
                <div>
                  <FCTButton
                    variant="outlined"
                    className={classes.button}
                    onClick={() => setOpen(true)}
                    startIcon={
                      <div className={classes.filterIcon}>
                        <Icon
                          name="filter"
                          width={16}
                          height={16}
                          color={theme.palette.primary.light}
                        />
                      </div>
                    }
                  />
                </div>
              </FCTTooltip>
            </Flex>
          )}
        </Flex>

        {loadingTopCompanies ? (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        ) : data?.length ? (
          <Table className={classes.table} aria-label="customized table" data-testid="tableTop10">
            <TableHead>
              <TableCell
                className={clsx(classes.tableCell, classes.oneline, classes.fontBold)}
                scope="row"
              >
                Company Name
              </TableCell>
              <TableCell
                className={clsx(classes.tableCell, classes.oneline, classes.fontBold)}
                align="right"
              >
                FCT Score
              </TableCell>
            </TableHead>
            <TableBody>
              {data?.map((item: IModifiedTopCompanies, index: number) => (
                <StyledTableRow key={index} data-testid="tableTop10Row">
                  <TableCell className={clsx(classes.tableCell, classes.fontBold)} scope="row">
                    {isStartupUser ? (
                      <>
                        <Flex alignItems="center">
                          <FCTTooltip
                            title={
                              <Typography style={{ fontSize: 12 }}>{item.description}</Typography>
                            }
                            placement="top"
                            arrow
                            disableHoverListener={false}
                          >
                            <Avatar
                              alt={item.name + ''}
                              variant="square"
                              src={item.companyLogo || ''}
                              className={classes.avatar}
                              classes={{ img: classes.containAvatar }}
                              children={renameLogo(item.name + '')}
                            />
                          </FCTTooltip>
                          {item.name}
                        </Flex>
                      </>
                    ) : (
                      <Link to={() => `${item.id}`} target="_blank" className={classes.linkStyle}>
                        <Flex alignItems="center">
                          <FCTTooltip
                            title={
                              <Typography style={{ fontSize: 12 }}>{item.description}</Typography>
                            }
                            placement="top"
                            arrow
                            disableHoverListener={false}
                          >
                            <Avatar
                              alt={item.name + ''}
                              variant="square"
                              src={item.companyLogo || ''}
                              className={classes.avatar}
                              classes={{ img: classes.containAvatar }}
                              children={renameLogo(item.name + '')}
                            />
                          </FCTTooltip>
                          {item.name}
                        </Flex>
                      </Link>
                    )}
                  </TableCell>
                  <TableCell className={clsx(classes.tableCell, classes.oneline)} align="right">
                    {item.fctScore?.toFixed(0) || 0}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyWatchList
            icon={<FCTIcon name="company" width={32} height={32} color={theme.palette.grey[400]} />}
            title=""
            wrapIconStyle={{ width: 80, height: 80, marginTop: '20px' }}
            contentStyle={{ margin: '16px 0 40px' }}
            content={
              <>
                There are no similar companies within the same product line as{' '}
                <strong>{nameCompany}</strong> on this geographic level. Try selecting a different
                geographic filter.
              </>
            }
          />
        )}
      </div>

      {open && (
        <Top10FilterDialog
          defaultFilter={{
            businessValue,
            productLine,
            operatingValue,
            geography,
            country,
          }}
          open={open}
          setOpen={setOpen}
          companyInfo={companyInfo}
          businessLineValueChains={businessLineValueChains}
          productLines={productLines}
          operatingModels={operatingModels}
          handleAgreeFilter={handleAgreeFilter}
          industry={category}
          setIndustry={setCompareProps}
        />
      )}
    </>
  )
}

export function getLeafNodes<T extends { children: T[] }>(nodes: T[], result: T[] = []) {
  for (var i = 0, length = nodes.length; i < length; i++) {
    if (!nodes[i].children || nodes[i].children.length === 0) {
      result.push(nodes[i])
    } else {
      result = getLeafNodes(nodes[i].children, result)
    }
  }
  return result
}

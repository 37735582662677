import { TOPICS_PRIVACY } from 'core/constants/String'
import { PolicyDocumentPage } from './../components/Policy/PolicyDocumentPage'

export const PrivacyPage = () => {
  return (
    <PolicyDocumentPage
      topics={TOPICS_PRIVACY}
      getContent={async () => (await import('assets/webflow'))['privacy']}
    />
  )
}

import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateGlobalStateAction } from '../store/features/global/GlobalAction'
import { IGlobalData, selectGlobalState } from '../store/features/global/GlobalSelector'

import SyncStateRouterWrapper from './SyncStateRouterWrapper'
export interface ISearchState {
  g?: Partial<IGlobalData>
}
interface IProps {
  children: React.ReactElement
}

const GlobalRouterWrapper = (props: IProps) => {
  const history = useHistory()
  const globalData = useSelector(selectGlobalState)
  const dispatch = useDispatch()
  const setFilter = useMemo(
    () => ({ g }: ISearchState) => {
      g && dispatch(updateGlobalStateAction(g))
    },
    []
  )
  const filter = useMemo(
    () => ({
      g: globalData,
    }),
    [globalData]
  )

  const isCurrentPage = (url: string) => {
    return history.location.pathname.includes(url)
  }

  const skip =
    isCurrentPage('/analysis') ||
    isCurrentPage('/watch-lists') ||
    isCurrentPage('/list-view') ||
    isCurrentPage('/news')

  return (
    <SyncStateRouterWrapper
      skipSetUrl={skip}
      getState={filter}
      setState={setFilter}
      parseState={({ c, g }) =>
        ({
          ...(g ? { g } : {}),
        } as ISearchState)
      }
    >
      {props.children}
    </SyncStateRouterWrapper>
  )
}

export default GlobalRouterWrapper

import Loading from 'components/Loading'
import { lazy, Suspense } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import componentLoader from 'utils/componentLoader'

const ResetPasswordPage = lazy(() => componentLoader(() => import('../pages/ResetPasswordPage')))
const CreateAccountPage = lazy(() => componentLoader(() => import('../pages/CreateAccountPage')))

const CredentialsRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={`${path}/create-account/:token`} component={CreateAccountPage} />
        <Route path={`${path}/reset-password/:token`} component={ResetPasswordPage} />
      </Switch>
    </Suspense>
  )
}

export default CredentialsRouter

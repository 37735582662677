import { Grid, makeStyles, Typography } from '@material-ui/core'
import FCTIcon from 'components/Icon/Icon'
import { PropsWithChildren } from 'react'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  footer: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.grey[800],
    width: '100%',
    minWidth: 162,
    padding: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '22px',
    textDecoration: 'none',
  },
  otherLinkText: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[300],
    transition: 'color 500ms ease',
    mixBlendMode: 'normal',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  copyright: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[300],
    cursor: 'default',
  },
  flexCC: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
}))

const LinkWrapper = ({
  children,
  link,
  style = {},
  onClick,
}: PropsWithChildren<{ link: string; style?: React.CSSProperties; onClick?(): void }>) => {
  const classes = useStyles()
  return (
    <a href={link} onClick={onClick} className={classes.linkWrapper} style={style}>
      {children}
    </a>
  )
}

export default function () {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Grid container className={classes.flexCC} spacing={3}>
        <Grid container item className={classes.flexCC} sm={12} md={3}>
          <Grid item className={classes.flexCC} xs={11} sm={12}>
            <LinkWrapper link="/app/dashboard" style={{ width: 231, height: '100%' }}>
              <FCTIcon
                width="100%"
                height={40}
                name="newLogo"
                color={theme.palette.primary.contrastText}
              />
            </LinkWrapper>
          </Grid>
        </Grid>

        <div></div>

        <Grid item className={classes.flexCC} sm={12} md={4}>
          <Typography className={classes.copyright}>
            {`® Copyright ${new Date().getFullYear()} FinTech Control Tower`}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  )
}

const ISSUER = process.env.REACT_APP_ISSUER as string
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string
const LINKEDIN_IDP = process.env.REACT_APP_LINKEDIN_IDP_ID as string
const API = process.env.REACT_APP_AUTH_URL as string

export default {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: window.location.origin + '/auth/callback',
  pkce: true,
  linkedInIdpId: LINKEDIN_IDP,
  api: API,
}

import { Breadcrumbs, createStyles, makeStyles, useTheme } from '@material-ui/core'
import Icon from 'components/Icon/Icon'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(theme =>
  createStyles({
    linkItem: {
      color: theme.palette.grey[600],
      lineHeight: '22px',
      textDecoration: 'none',
      fontWeight: 500,
      fontSize: 14,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    activeLink: {
      color: theme.palette.primary.main,
    },
  })
)

interface IKnowledgeHubBreadcrumbsProps {
  breadcrumbs: Array<{
    name: string
    to: string
  }>
}

export const KnowledgeHubBreadcrumbs = ({ breadcrumbs }: IKnowledgeHubBreadcrumbsProps) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Breadcrumbs
      separator={<Icon name="arrowright" height="10" width="10" color={theme.palette.grey[400]} />}
      aria-label="breadcrumb"
    >
      {breadcrumbs.map((e, idx) => (
        <NavLink
          key={idx}
          to={e.to}
          activeClassName={idx === breadcrumbs.length - 1 ? classes.activeLink : ''}
          className={classes.linkItem}
        >
          {e.name}
        </NavLink>
      ))}
    </Breadcrumbs>
  )
}

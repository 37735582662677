import { gql } from '@apollo/client'

import { IColumnState } from '../../../cache/DealsList'

export const GET_SEARCH_DEALS_COLUMNS = gql`
  query GetSearchDealsColumns {
    searchDealsColumns @client
  }
`

export interface GetSearchDealsColumns {
  searchDealsColumns: IColumnState[] | null
}

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CardHeader, { CardHeaderProps } from '@material-ui/core/CardHeader'

const FCTCardHeader = withStyles({
  root: {
    padding: 0,
    paddingLeft: 16,
    height: 49,
    borderBottom: '1px solid #E7ECF3',
    fontWeight: 500,
    fontSize: 14,
  },
  action: {
    margin: 0,
    alignSelf: 'center',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
  },
})((props: FCTCardHeaderProps) => {
  const { titleElement, title, ...res } = props
  return <CardHeader title={titleElement || title} {...res}></CardHeader>
})

export default FCTCardHeader

export interface FCTCardHeaderProps extends CardHeaderProps {
  titleElement?: React.ReactNode | string
}

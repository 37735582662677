import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import { useState } from 'react'
import BCGDetails, { IBCGDetailsFormData } from 'components/GetAcessToFCT/BCGDetails'
import NonBCGDetails, { INonBCGDetailsFormData } from 'components/GetAcessToFCT/NonBCGDetails'
import {
  ISelectUserTypeFormData,
  SelectUserTypeForm,
  EUserType,
} from 'components/GetAcessToFCT/SelectUserTypeForm'
import getAccess from 'axios/getAccess'
import { useToasts } from 'react-toast-notifications'
import Icon, { IconMap } from 'components/Icon/Icon'

const useStyles = makeStyles(theme => ({
  titleGetAccess: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.grey[800],
  },
  desc: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 10,
    marginBottom: 20,
    color: theme.palette.grey[600],
  },
}))

export enum EStep {
  SELECT_USER_TYPE,
  COLLECT_DATA,
  SUCCESS,
}

export const RequestAccessContainer = () => {
  const classes = useStyles()

  const { addToast } = useToasts()
  const [step, setStep] = useState<EStep>(EStep.SELECT_USER_TYPE)
  const [userTypeFormData, setUserTypeFormData] = useState<ISelectUserTypeFormData>()
  const [name, setName] = useState('')

  const handleSubmitBCGRequest = async (data: IBCGDetailsFormData) => {
    const payload = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      isBCG: true,
      caseDetails: {
        caseCode: data.caseCode,
        partnerName: data.partnerName,
        clientName: data.clientName,
        numberOfLogins: +data.numberOfLogin,
        periodOfUseFrom: new Date(data.fromDate || '')?.toISOString(),
        periodOfUseTo: new Date(data.toDate || '')?.toISOString(),
        caseContext: data.caseText,
        tier1: data.isTier === 'Yes',
      },
    }
    await getAccess(payload).catch(e => {
      throw Error(e.response?.data?.data?.err)
    })
  }

  const handleSubmitNonBCGRequest = async (data: INonBCGDetailsFormData) => {
    const payload = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      isBCG: false,
      companyDetails: {
        name: data.companyName,
        url: data.companyUrl,
        roleInCompany: data.role,
        numberOfLogins: +data.numberOfLogin,
      },
    }
    await getAccess(payload).catch(e => {
      throw Error(e.response?.data?.data?.err)
    })
  }

  const nextStep = () => {
    const mapper = {
      [EStep.SELECT_USER_TYPE]: EStep.COLLECT_DATA,
      [EStep.COLLECT_DATA]: EStep.SUCCESS,
      [EStep.SUCCESS]: EStep.SUCCESS,
    }
    setStep(mapper[step])
  }

  const prevStep = () => {
    const mapper = {
      [EStep.SELECT_USER_TYPE]: EStep.SELECT_USER_TYPE,
      [EStep.COLLECT_DATA]: EStep.SELECT_USER_TYPE,
      [EStep.SUCCESS]: EStep.COLLECT_DATA,
    }
    setStep(mapper[step])
  }

  if (step === EStep.SUCCESS) {
    return (
      <div>
        <Successful
          icon="sendEmail"
          title={`Thank you, ${name}`}
          message={`Thank you for your interest in the FCT. Our team is reviewing your request and will get back to you shortly.`}
        />
      </div>
    )
  }

  return (
    <div>
      <Typography component={'h3'} className={classes.titleGetAccess}>
        Request Free Trial Access
      </Typography>
      <Typography className={classes.desc} style={{ textAlign: 'center' }}>
        Sign up to the FCT today and access cutting-edge FinTech insights.
      </Typography>

      {step === EStep.SELECT_USER_TYPE && (
        <SelectUserTypeForm
          defaultValues={userTypeFormData}
          handleSubmit={data => {
            setUserTypeFormData(data)
            nextStep()
          }}
        />
      )}

      {(() => {
        if (step === EStep.COLLECT_DATA && userTypeFormData) {
          switch (userTypeFormData.userType) {
            case EUserType.BCG:
              return (
                <BCGDetails
                  email={userTypeFormData.email}
                  handleChangeEmail={() => prevStep()}
                  handleSubmit={async data => {
                    try {
                      await handleSubmitBCGRequest(data)
                      setName(data.firstName)
                      nextStep()
                    } catch (error: any) {
                      addToast(<Typography>{error?.message}</Typography>, {
                        appearance: 'error',
                      })
                    }
                  }}
                />
              )
            case EUserType.NON_BCG:
              return (
                <NonBCGDetails
                  email={userTypeFormData.email}
                  handleChangeEmail={() => prevStep()}
                  handleSubmit={async data => {
                    try {
                      await handleSubmitNonBCGRequest(data)
                      setName(data.firstName)
                      nextStep()
                    } catch (error: any) {
                      addToast(<Typography>{error?.message}</Typography>, {
                        appearance: 'error',
                      })
                    }
                  }}
                />
              )
            default:
              return null
          }
        }
      })()}
    </div>
  )
}

function Successful({
  icon,
  title,
  message,
}: {
  title: string
  message: string
  icon: keyof typeof IconMap
}) {
  const theme = useTheme()
  return (
    <Box textAlign="center">
      <Icon name={icon} width="80px" height="80px" />
      <Typography
        style={{
          color: theme.palette.grey[900],
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '34px',
          marginBottom: '16px',
          marginTop: '24px',
        }}
        component="h3"
      >
        {title}
      </Typography>

      <Typography style={{ fontSize: 14, marginBottom: '10px' }}>{message}</Typography>
    </Box>
  )
}

import {
  Box,
  Checkbox,
  ClickAwayListener,
  FormLabel,
  Popover,
  Theme,
  Typography,
} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import withStyles, { CSSProperties } from '@material-ui/core/styles/withStyles'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useRect } from '../../core/hooks/useRect'
import theme from '../../theme'
import Flex from '../Flex/Flex'
import Icon from '../Icon/Icon'

export enum ChangeType {
  SELECT = 'SELECT',
  DESELECT = 'DESELECT',
}

const DropDownContainer = styled('div')`
  border-radius: 3px;
  position: relative;
  border: 1px solid #e7ecf3;
  background: #fff;
  box-sizing: border-box;
  min-height: 50px;

  &.open {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.openGroup {
    ${({ theme }: { theme: Theme }) => `
    border: 1px solid ${theme.palette.primary.light};
    border-top: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    `}
  }
  &.sizeSmall {
    min-height: 40px;
  }

  &.open,
  &:hover,
  &:focus {
    ${({ theme }: { theme: Theme }) => `
      border-color: ${theme.palette.primary.light};
  `}
  }
`

const DropDownLabel = styled('div')`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 8px;
  transform: translateY(-50%);
  padding: 0 5px;
`

const DropDownHeader = styled('div')`
  padding: 15px 14px 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.sizeSmall {
    padding: 10px;
  }
`
const DropDownListContainer = styled('div')`
  ${({ theme }: { theme: Theme }) => `
  padding: 0;
  background: #fff;
  border: 1px solid ${theme.palette.primary.light};
  border-top: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  &:hover: {  
    border-color: ${theme.palette.primary.light};
  }
  box-sizing: border-box;
`}}
`

const DropDownGroup = styled('div')`
  ${({ theme }: { theme: Theme }) => `
  padding: 0;
  background: #fff;
  border: 1px solid ${theme.palette.primary.light};
  border-bottom: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  &:hover: {  
    border-color: ${theme.palette.primary.light};
  }
  box-sizing: border-box;
`}}
`

const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  max-height: 310px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    ${({ theme }: { theme: Theme }) => `
    background: ${theme.palette.primary.main};
    border-right: 5px white solid;
    `}
  }

  &::-webkit-scrollbar-track {
    ${({ theme }: { theme: Theme }) => `
    border-radius: 10px;
    box-shadow: inset 0 0 5px  ${theme.palette.grey[100]}; 
  `}
  }
`

const StyledDropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  max-height: 310px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    ${({ theme }: { theme: Theme }) => `
  background: ${theme.palette.grey[300]};
  border-right: 5px white solid;
  `}
  }

  &::-webkit-scrollbar-track {
    ${({ theme }: { theme: Theme }) => `
  border-radius: 10px;
  box-shadow: inset 0 0 5px  ${theme.palette.grey[100]}; 
`}
  }
`

const ListItem = styled('li')`
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    ${({ theme }: { theme: Theme }) => `
    background: ${theme.palette.grey[100]};`}
  }
  &.openGroup {
    padding-left: 22px;
    height: 38px;
  }
`

const ListItemLegend = styled('li')`
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    ${({ theme }: { theme: Theme }) => `
  background: ${theme.palette.grey[100]};`}
  }
  height: 22px;
  font-size: 14px;
  ${({ theme }: { theme: Theme }) => `
  color: ${theme.palette.grey[800]};`}
  padding: 13px 0 0 11px;
`

const DropDownListInput = styled('input').attrs(() => ({
  type: 'text',
  placeholder: 'search keyword',
}))`
  ${({ theme }: { theme: Theme }) => `
  padding-left: 8px;
  width: 100%;
  box-sizing: border-box;
  background: #f5f5f5;
  border: 0;
  outline: 0;
  &::placeholder {
    color: ${theme.palette.grey[400]};
  }
`}
`

const DropDownListInputContainer = styled('div')`
  padding: 10px;
  margin: 0 10px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  border-radius: 3px;
`

export const ChipItem = withStyles({
  root: {
    borderRadius: '3px',
    zIndex: 1,
  },
  deleteIcon: {
    width: 10,
    height: 10,
  },
  deleteIconSmall: {
    width: 10,
    height: 10,
  },
})(Chip)

const StyledPopover = withStyles((theme: any) => ({
  root: {},
  paper: {
    boxShadow: 'none',
    borderRadius: 0,
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
}))((props: any) => <Popover {...props} />)

const StyledLabel = withStyles((theme: Theme) => ({
  root: {
    height: 22,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[800],
    padding: '13px 0 0 11px',
  },
}))((props: any) => <FormLabel {...props} />)

const DropDownSelectedList = ({
  list = [],
  onDelete,
  placeHolder = 'Select',
  format = (item: string, onDelete: () => void) => (
    <ChipItem label={item} size="small" onDelete={onDelete} />
  ),
  ...props
}: DropDownSelectedListProps) => (
  <Flex flexWrap="wrap" alignItems="center" minHeight={24} margin={'-2px'} width={'92%'}>
    {list?.length > 0 ? (
      list?.map((item, index) => (
        <Box
          key={`${item}-${index}`}
          lineHeight="24px"
          maxHeight="24px"
          margin="0 1px 2px"
          width="100%"
          textAlign="left"
        >
          {format(item, () => (onDelete ? onDelete(item) : null))}
        </Box>
      ))
    ) : (
      <Box lineHeight={'26px'}>
        {typeof placeHolder === 'string' ? (
          <Typography
            style={{
              fontSize: '14px',
              color: theme.palette.grey[500],
            }}
          >
            {placeHolder}
          </Typography>
        ) : (
          placeHolder
        )}
      </Box>
    )}
  </Flex>
)

const FCTSelect = ({
  isSearchable = true,
  isDisabled,
  isMultiple,
  placeHolder = 'Select',
  values,
  setValues,
  arrOptions,
  options,
  onFilter,
  onChange,
  required,
  size = 'small',
  icon,
  format,
  className,
  isSelectGroup = false,
  optionsGroup,
  optionsFontSize,
  formatElement,
  ...props
}: FCTSelectProps) => {
  // TODO: Implement Scroll Strategy

  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const position = useRect(wrapperRef)

  const [isTouched, setIsTouched] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  // Keep state of search string
  const [searchValue, setSearchValue] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<any>(null)

  const toggling = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(!isDisabled && !isOpen)
  }

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value
    setSearchValue(value)
    if (onFilter) debounce(onFilter, 400)(value)
  }

  const selectItem = (selectedList: string[], value: string) => {
    if (isDisabled) return
    setValues(isMultiple ? [...selectedList, value] : [value])
    onChange && onChange({ type: ChangeType.SELECT, value })
    !isMultiple && setIsOpen(false)
  }

  const deselectItem = (selectedList: string[], value: string) => {
    if (isDisabled) return
    setValues(selectedList.filter(item => item !== value))
    onChange && onChange({ type: ChangeType.DESELECT, value })
  }

  const toggleItem = (selectedList: string[], value: string) => {
    if (isDisabled) return
    if (isMultiple && selectedList.includes(value)) {
      deselectItem(selectedList, value)
    } else selectItem(selectedList, value)
  }

  const isError = (isTouched && required && values.length === 0) || props.isError ? true : false
  const containerStyle = {
    ...(isDisabled ? { opacity: 0.5, pointerEvents: 'none' } : {}),
    ...(isError ? { borderColor: theme.palette.error.main } : {}),
  } as CSSProperties

  if (arrOptions) options = arrOptions?.map(e => ({ value: e, label: e }))
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <DropDownContainer
        ref={wrapperRef}
        style={containerStyle}
        onMouseUp={() => setIsTouched(true)}
        className={`${size === 'small' ? 'sizeSmall' : ''} ${
          !isOpen ? '' : isSelectGroup ? 'openGroup' : 'open'
        } ${className}`}
      >
        <DropDownLabel>
          <Typography
            style={{
              fontSize: '12px',
            }}
          >
            {props.label}
            {required && ' *'}
          </Typography>
        </DropDownLabel>
        <DropDownHeader onClick={toggling} className={`${size === 'small' ? 'sizeSmall' : ''}`}>
          <DropDownSelectedList
            list={values}
            onDelete={item => deselectItem(values, item)}
            placeHolder={placeHolder}
            format={format}
          />
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {icon ? icon : <Icon name="arrowdown" width={12} height={12} />}
          </Box>
        </DropDownHeader>
        {!isSelectGroup ? (
          <StyledPopover
            open={isOpen}
            onClose={() => setIsOpen(false)}
            anchorEl={anchorEl}
            transitionDuration={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <DropDownListContainer
              ref={dropdownRef}
              style={{ width: position.width, zIndex: 2147483601 }}
            >
              {isSearchable && (
                <DropDownListInputContainer>
                  <Icon name="search" color={theme.palette.grey[400]} />
                  <DropDownListInput value={searchValue} onChange={handleInputChange} />
                </DropDownListInputContainer>
              )}
              <DropDownList>
                {options?.map(opt => {
                  const textType = opt.label.split('-')
                  return (
                    <ListItem key={opt.value} onClick={() => toggleItem(values, opt.value)}>
                      {isMultiple && (
                        <Checkbox
                          color="primary"
                          checked={!!opt?.value && !!values && !!values.includes(opt.value)}
                        />
                      )}
                      {formatElement !== undefined ? (
                        formatElement(opt)
                      ) : (
                        <Typography
                          variant="caption"
                          style={{
                            padding: 10,
                            paddingLeft: isMultiple ? 0 : 10,
                            fontSize: size === 'medium' ? '14px' : '12px',
                          }}
                        >
                          {opt.label}
                        </Typography>
                      )}
                    </ListItem>
                  )
                })}
              </DropDownList>
            </DropDownListContainer>
          </StyledPopover>
        ) : (
          <StyledPopover
            open={isOpen}
            onClose={() => setIsOpen(false)}
            anchorEl={anchorEl}
            transitionDuration={0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <DropDownGroup
              ref={dropdownRef}
              style={{
                width: position.width,
                paddingTop: '8px',
              }}
            >
              <StyledDropDownList>
                {optionsGroup?.map(option =>
                  !!option.values.length ? (
                    <>
                      <StyledLabel component="legend">{option.label}</StyledLabel>
                      {option.values.map(value => (
                        <ListItem
                          onMouseDown={(event: React.MouseEvent<any>) => event.preventDefault()}
                          onClick={() => toggleItem(values, value)}
                          key={value}
                          className={`openGroup`}
                        >
                          <Typography style={{ fontSize: 14 }}>{value}</Typography>
                        </ListItem>
                      ))}
                    </>
                  ) : (
                    <>
                      <ListItemLegend
                        onMouseDown={(event: React.MouseEvent<any>) => event.preventDefault()}
                        onClick={() => toggleItem(values, option.label)}
                        key={option.label}
                        className={`openGroup`}
                      >
                        <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                          {option.label}
                        </Typography>
                      </ListItemLegend>
                    </>
                  )
                )}
              </StyledDropDownList>
            </DropDownGroup>
          </StyledPopover>
        )}
      </DropDownContainer>
    </ClickAwayListener>
  )
}

export default FCTSelect

export type FCTSelectProps = {
  size?: 'small' | 'medium'
  values: string[]
  setValues(v: string[]): void
  label?: string
  options?: FCTOption[] | null
  arrOptions?: string[] | null
  isMultiple?: boolean
  isSearchable?: boolean
  isDisabled?: boolean
  onChange?(v: object): void
  onFilter?(keyword: string): void
  isError?: boolean
  required?: boolean
  className?: string
  icon?: React.ReactNode
  isSelectGroup?: boolean
  optionsGroup?: FCTOptionGroup[] | null
  optionsFontSize?: number
  formatElement?(item: FCTOption): React.ReactNode
} & Pick<DropDownSelectedListProps, 'onDelete' | 'placeHolder' | 'format'>

export type FCTOption = {
  value: string
  label: string
  description?: string
  disabled?: boolean
}
export interface FCTOptionGroup {
  label: string
  values: string[]
}

export type DropDownSelectedListProps = {
  list: string[]
  onDelete?(v: string): void
  placeHolder?: string | React.ReactNode
  format?(item: string, onDelete: () => void): React.ReactNode
}

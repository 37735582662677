type StackBarData<Stack> = {
  stacks: Stack[] | null
}

export default function totalByXAxis<T extends StackBarData<Stack | null>, Stack>(
  data: T[],
  stack: Stack,
  getValue: (data: T, key: string) => number,
  key: keyof Stack
): number {
  return data.reduce(
    (acc, cur) =>
      acc +
      (cur.stacks || [])
        .filter(s => s?.[key] === stack?.[key])
        .reduce((acc1, cur1) => acc1 + getValue(cur, '' + cur1?.[key]), 0),
    0
  )
}

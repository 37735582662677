import { makeStyles, createStyles } from '@material-ui/core'
import { NavLink, NavLinkProps } from 'react-router-dom'
import mixpanel from 'mixpanel'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapperAccordion: {
      padding: '40px 10% 120px 0',
    },
    topicItem: {
      paddingBottom: 8,
    },
    linkItem: {
      color: theme.palette.grey[600],
      lineHeight: '22px',
      textDecoration: 'none',
      fontSize: 14,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    activeLink: {
      color: theme.palette.primary.main,
    },
  })
)

type Props = {
  name: string
  slug: string
  // Not support cate yet
  type: 'topic'
  className?: string
  categoryName: string
} & Pick<NavLinkProps, 'isActive'>

const KnowledgeHubLink = ({ name, slug, type, className, categoryName, ...props }: Props) => {
  const classes = useStyles()
  return (
    <NavLink
      className={className ? className : classes.linkItem}
      activeClassName={classes.activeLink}
      to={`/knowledge-hub/${type}/${slug}`}
      onClick={() =>
        type === 'topic' &&
        mixpanel.track('KnowledgeHub_Topics', { topic: name, KH_Categories: categoryName })
      }
    >
      {name}
    </NavLink>
  )
}

export default KnowledgeHubLink

import { ICompanyDealFilter } from 'types/CompanyDealFilter'
import { ICompanyInformation } from '../components/Profile/Sidebar/CompanyInformation'
import { IInvestorInformation } from 'components/PDFInvestorProfile/PDFInvestorProfile'

export const STRINGS: {
  ICompanyInformation: Record<keyof ICompanyInformation, string>
} = {
  ICompanyInformation: {
    aliases: 'Aliases',
    foundedDate: 'Founded year',
    valuation: 'Equity funding',
    employees: 'Employees',
    femaleFounder: 'Female founders',
    website: 'Website',
    address: 'Address',
    lastestFundingStage: 'Lastest funding stage',
    headquarter: 'Headquarter',
    operatingPresence: 'Other Office Locations',
    socials: 'Socials',
    ownership: 'Ownership',
  },
}
export const STRINGSINVESTOR: {
  IInvestorInformation: Record<keyof IInvestorInformation, string>
} = {
  IInvestorInformation: {
    website: 'Website',
    headquarter: 'Headquarter',
    foundedYear: 'Founded Year',
    employees: 'Employees',
    socials: 'Socials',
  },
}

export const DISCLAIMER_CONTENT = [
  `The services and materials provided by Boston Consulting Group (BCG) are subject to BCG's Standard Terms (a copy of which is available upon request) or such other agreement as may have been previously executed by BCG. BCG does not provide legal, accounting, or tax advice. The Client is responsible for obtaining independent advice concerning these matters. This advice may affect the guidance given by BCG. Further, BCG has made no undertaking to update these materials after the date hereof, notwithstanding that such information may become outdated or inaccurate.\n`,
  `\n`,
  `The materials contained in this presentation are designed for the sole use by the board of directors or senior management of the Client and solely for the limited purposes described in the presentation. The materials shall not be copied or given to any person or entity other than the Client (“Third Party”) without the prior written consent of BCG. These materials serve only as the focus for discussion; they are incomplete without the accompanying oral commentary and may not be relied on as a stand-alone document. Further, Third Parties may not, and it is unreasonable for any Third Party to, rely on these materials for any purpose whatsoever. To the fullest extent permitted by law (and except to the extent otherwise agreed in a signed writing by BCG), BCG shall have no liability whatsoever to any Third Party, and any Third Party hereby waives any rights and claims it may have at any time against BCG with regard to the services, this presentation, or other materials, including the accuracy or completeness thereof. Receipt and review of this document shall be deemed agreement with and consideration forthe foregoing.\n`,
  `\n`,
  `BCG does not provide fairness opinions or valuations of market transactions, and these materials should not be relied on or construed as such. Further, the financial evaluations, projected market and financial information, and conclusions contained in these materials are based upon standard valuation methodologies, are not definitive forecasts, and are not guaranteed by BCG. BCG has used public and/or confidential data and assumptions provided to BCG by the Client. BCG has not independently verified the data and assumptions used in these analyses. Changes in the underlying data or operating assumptions will clearly impact the analyses and conclusions.\n`,
]

export const FIXED_APPENDIX: Record<string, string> = {
  ['Cross-FI']:
    'Enterprise-wide solutions, typically in tech and ops, that are not aligned to a specific business line.',
}

export const LOCAL_STORAGE_FIELDS = {
  SIGN_IN_UP_ERROR: 'sisuErrorCode',
  MARKETING_CONSENT: 'mktConsent',
}

export enum ESentimentLabel {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export const WORD_MAP_COLOR_LEVEL = [
  '#29BA74',
  '#3EAD92',
  '#197A56',
  '#03522D',
  '#9A9A9A',
  '#6E6F73',
]

export const WORD_MAP_OPTIONS = {
  colors: [...WORD_MAP_COLOR_LEVEL],
  enableTooltip: true,
  deterministic: true,
  fontFamily: 'impact',
  fontSizes: [9, 18],
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 5,
  rotations: 1,
  rotationAngles: [0, 90],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 1000,
}
export const INIT_YEAR = 2000
export const CURRENT_YEAR = new Date().getFullYear()
export const CURRENT_MONTH = new Date().getMonth() + 1
export const CURRENT_DATE = new Date().getDate()
export const CURRENT_QUARTER = Math.floor((CURRENT_MONTH - 1) / 3 + 1)
export const COMPANY_ID_COLUMN_NAME = 'company_id'

export const MAX_MONTH_BY_QUARTER = [3, 6, 9, 12]
export const MIN_MONTH_BY_QUARTER = [1, 4, 7, 10]

export const PAGINATION_INIT = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 10,
  PAGE_SIZE_OPTIONS: [10, 20, 30, 40, 50],
}

export enum ColumnSort {
  Asc,
  Desc,
  Default,
  Filter,
}

export const CHART_ID = {
  FUNDING_ROUND_CHART: 'fundingRoundChart',
  FUNDING_AMOUNT_CHART: 'fundingAmountChart',
  INVESTMENT_PL_VIEW_BY_DEAL_COUNT: 'investmentPlViewByDealCount',
  INVESTMENT_PL_VIEW_BY_DEAL_AMOUNT: 'investmentPlViewByDealAmount',
  INVESTMENT_MR_VIEW_BY_DEAL_COUNT: 'investmentMrViewByDealCount',
  INVESTMENT_MR_VIEW_BY_DEAL_AMOUNT: 'investmentMrViewByDealAmount',
  ACQUISITION_PL_VIEW_BY_DEAL_AMOUNT: 'maPlViewByDealAmount',
  ACQUISITION_PL_VIEW_BY_DEAL_COUNT: 'maPlViewByDealCount',
  PRODUCT_EVENTS_CHART: 'productEventsChart',
  NEW_SENTIMENT_CHART: 'sentimentChart',
}

export const DEAL_TYPE = {
  MA: 'M&A',
}

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_FORMAT_INPUT = 'YYYY-MM-DD'
export const descLength = 200

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export const LIST_CHIP_ORDERS: {
  key: keyof ICompanyDealFilter | (keyof ICompanyDealFilter)[]
  hasOrder: boolean
}[] = [
  {
    key: 'category',
    hasOrder: false,
  },
  {
    key: 'businessLines',
    hasOrder: true,
  },
  {
    key: 'products',
    hasOrder: true,
  },
  {
    key: 'valueChains',
    hasOrder: true,
  },
  {
    key: 'operatingModels',
    hasOrder: true,
  },
  {
    key: 'tagGroupIds',
    hasOrder: true,
  },
  {
    key: 'businessModelIds',
    hasOrder: true,
  },
  {
    key: 'distributionModelIds',
    hasOrder: true,
  },
  {
    key: 'companyStatuses',
    hasOrder: true,
  },
  {
    key: 'numOfEmployeesRanges',
    hasOrder: false,
  },
  {
    key: 'foundedYearFrom',
    hasOrder: false,
  },
  {
    key: 'foundedYearTo',
    hasOrder: false,
  },
  {
    key: 'regions',
    hasOrder: true,
  },
  {
    key: 'subRegions',
    hasOrder: true,
  },
  {
    key: 'subRegion2s',
    hasOrder: true,
  },
  {
    key: 'countries',
    hasOrder: true,
  },
  {
    key: 'investors',
    hasOrder: true,
  },
  {
    key: 'investorsTypes',
    hasOrder: true,
  },
  {
    key: 'leadInvestors',
    hasOrder: true,
  },
  {
    key: 'leadInvestorTypes',
    hasOrder: true,
  },
  {
    key: 'numberOfInvestorsRanges',
    hasOrder: false,
  },
  {
    key: 'totalFundingFrom',
    hasOrder: false,
  },
  {
    key: 'totalFundingTo',
    hasOrder: false,
  },
  {
    key: 'equityFundingFrom',
    hasOrder: false,
  },
  {
    key: 'equityFundingTo',
    hasOrder: false,
  },
  {
    key: 'latestDealAmountFrom',
    hasOrder: false,
  },
  {
    key: 'latestDealAmountTo',
    hasOrder: false,
  },
  {
    key: ([
      'latestFundingRound1s',
      'latestFundingRound2s',
    ] as unknown) as (keyof ICompanyDealFilter)[],
    hasOrder: true,
  },
  {
    key: 'dealAmountFrom',
    hasOrder: false,
  },
  {
    key: 'dealAmountTo',
    hasOrder: false,
  },
  {
    key: 'fundingRound1s',
    hasOrder: true,
  },
  {
    key: 'fundingRound2s',
    hasOrder: true,
  },
  {
    key: 'fundingDateFrom',
    hasOrder: false,
  },
  {
    key: 'fundingDateTo',
    hasOrder: false,
  },
  {
    key: 'numberOfFundingRounds',
    hasOrder: false,
  },
  {
    key: 'hasBeenAcquired',
    hasOrder: false,
  },
  {
    key: 'acquirers',
    hasOrder: true,
  },
  {
    key: 'acquiredDateFrom',
    hasOrder: false,
  },
  {
    key: 'acquiredDateTo',
    hasOrder: false,
  },
  {
    key: 'acquirerTypes',
    hasOrder: true,
  },
  {
    key: 'hasIPO',
    hasOrder: false,
  },
  {
    key: 'wentPublicOnDateFrom',
    hasOrder: false,
  },
  {
    key: 'wentPublicOnDateTo',
    hasOrder: false,
  },
  {
    key: 'modeName',
    hasOrder: false,
  },
]

export const SPECIAL_CHIPS = [
  'investorsTypes',
  'leadInvestorTypes',
  'leadInvestors',
  'acquirerTypes',
]

// Thresh hold to check id is not TAXONOMY_ID
export const TAXONOMY_ID_THRESH_HOLD_CHECK = 0

export const TRENDS_BY_PRODUCT_NAMES_DETAIL = {
  numberOfProductNamesInTotal: 60,
  numberOfSelectedProductNamesInTotal: 20,
  numberOfSelectedProductNamesOnInit: 10,
  numberOfDataColumns: 3,
}

export const TRENDS_BY_ALERTS_DETAIL = {
  numberOfAlertGroupNamesInTotal: 5,
  numberOfSelectedAlertGroupNamesInTotal: 5,
  numberOfSelectedAlertGroupNamesOnInit: 5,
  numberOfDataColumns: 1,
}

export const MIN_SMART_CLUSTER_DISTANCE = '0.5'
export const MARKS_SMART_CLUSTER_DISTANCE = [
  ...Array.from({ length: 5 }, (_, i) => (0.5 + i * 0.1).toFixed(1)),
  '1',
  ...Array.from({ length: 9 }, (_, i) => (1.1 + i * 0.1).toFixed(1)),
  '2',
  '2+',
]

export const THRESH_HOLD_PRODUCT_EXPORT = 10000
export const THRESH_HOLD_INVESTOR_DEALS_EXPORT = 2000
export const THRESH_HOLD_COMPANY_DEAL_EXPORT = 1000
export const THRESH_HOLD_COMPANY_EXPORT = 400
export const THRESH_HOLD_TOTAL_COMPANY_TO_EXPORT_BY_BATCH = 50

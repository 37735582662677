import { GEOGRAPHY_OPTIONS } from 'components/Profile/Sidebar/Top10FilterDialog'
import { getInitialCategory } from 'components/Profile/Sidebar/TopCompanies'
import { CompanyInfoLocation } from '__generated__/CompanyInfoLocation'
import { TopCompaniesLocation, TopCompaniesOption } from './convert/Title'
import { ETaxonomyCategory, ITaxonomyOptions } from './FlattenEcoSystem'
import { GetCompanyCategoriesTags_getCompanyCategoriesTags_categoriesTags } from '__generated__/GetCompanyCategoriesTags'

export interface Location extends Omit<CompanyInfoLocation, '__typename'> {
  isHeadquarter: boolean
}

export const getChipsFromTaxonomy = ({
  businessLineValueChains,
  productLines,
  operatingModels,
  dimensionIds,
}: {
  businessLineValueChains: ITaxonomyOptions[]
  productLines: ITaxonomyOptions[]
  operatingModels: ITaxonomyOptions[]
  dimensionIds: number[]
}) => {
  return [...businessLineValueChains, ...productLines, ...operatingModels]
    .filter(item => dimensionIds.includes(item.value))
    .map(item => ({
      label: item.label,
      value: item.value,
      type: item.avatar,
    }))
}

export const handleRegionName = ({
  country,
  geography,
}: {
  country: Location | null
  geography: TopCompaniesLocation
}): string => {
  if (country) {
    const key = GEOGRAPHY_OPTIONS.find(i => i.value === geography)?.key
    if (key && country[key]) {
      return `${country[key]}`
    }
  } else {
    const res = TopCompaniesOption.find(item => item.value === geography && !!item.label)
    return res?.label || ''
  }
  return ''
}

export const getCategoryFromSpecifyTaxonomy = (
  options: ITaxonomyOptions[],
  categoriesTags:
    | GetCompanyCategoriesTags_getCompanyCategoriesTags_categoriesTags
    | null
    | undefined
) => {
  return (
    options.filter(i => i.category === getInitialCategory(categoriesTags, options))[0]?.value ||
    null
  )
}

export const getCategoryForCompareProps = ({
  businessValue,
  businessLineValueChains,
  productLine,
  productLines,
  operatingValue,
  operatingModels,
  categoriesTags,
}: {
  businessValue: number | null
  productLine: number | null
  operatingValue: number | null
  businessLineValueChains: ITaxonomyOptions[]
  productLines: ITaxonomyOptions[]
  operatingModels: ITaxonomyOptions[]
  categoriesTags:
    | GetCompanyCategoriesTags_getCompanyCategoriesTags_categoriesTags
    | null
    | undefined
}) => {
  return businessValue
    ? (businessLineValueChains.find(i => i.value === businessValue)?.category as ETaxonomyCategory)
    : productLine
    ? (productLines.find(i => i.value === productLine)?.category as ETaxonomyCategory)
    : operatingValue
    ? (operatingModels.find(i => i.value === operatingValue)?.category as ETaxonomyCategory)
    : getInitialCategory(categoriesTags, productLines)
}

export const getDimensionIds = ({
  businessLineValueChains,
  productLines,
  operatingModels,
}: {
  businessLineValueChains: ITaxonomyOptions[]
  productLines: ITaxonomyOptions[]
  operatingModels: ITaxonomyOptions[]
}) => {
  let dimensionIds: number[] = []
  if (productLines[0]?.category === ETaxonomyCategory.RegTech) {
    dimensionIds = [productLines[0]?.value]
  } else {
    dimensionIds = [
      businessLineValueChains[0]?.value,
      productLines[0]?.value,
      operatingModels[0]?.value,
    ]
  }
  return (dimensionIds = dimensionIds.filter(Boolean))
}

export const getDomainName = (url: string) => {
  return url.trim().replace(/(https?:\/\/)?(www[0-9]?.)?/i, '')
}

export const appendProtocol = (url: string) => {
  return /^(http|https):\/\//.test(url) ? url : `http://${url}`
}

export const sleep = (ms: number = 500) => {
  return new Promise(r => setTimeout(r, ms))
}

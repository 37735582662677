import { createSelector } from '@reduxjs/toolkit'
import { Selector } from 'react-redux'
import { RootState } from '../../store'
import { ICompanyDealSlice } from './CompanyDealSlice'

const selectCompanyDeal: Selector<RootState, ICompanyDealSlice> = (state: RootState) =>
  state.companyDealReducer

const selectCompanyDealFilter = createSelector(selectCompanyDeal, state => state.filter)
const selectSubFilter = createSelector(selectCompanyDeal, state => state.subFilter)

export { selectCompanyDeal, selectCompanyDealFilter, selectSubFilter }

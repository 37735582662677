import { createSlice } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import { EEquityFChart } from '../../../containers/DashboardChartContainer'
import { EModeName, ICompanyDealFilter } from '../../../types/CompanyDealFilter'
import { ESwitchFemale, IAnalysisFilter, IAnalysisLocalFilter } from '../../../types/Filter'
import { ETimePeriod, LocationType, PeriodType } from '../../../utils/convert/Title'
import { mergeDeeps } from '../../../utils/TaxonomyMapping'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../../../__generated__/globalTypes'
import { initSmartCluster } from '../company-deal/CompanyDealSlice'
import { EGroupChart, ETypeActionGen, ETypeChartExport, ETypeSlide } from './../../../types/Export'

export const initialAnalysisFilter: IAnalysisFilter = {
  businessLines: null,
  valueChains: null,
  risks: null,
  products: null,
  operatingModels: null,
  regions: null,
  subRegions: null,
  subRegion2s: null,
  countries: null,
  businessModelIds: [0],
  distributionModelIds: null,
  fundingRound1s: [],
  fundingRound2s: [],
  category: InternalDbCompanyTypes.all,
  viewBy: GlobalFilterViewBy.product,
  selectedBusinessLineId: null,
  selectedProductId: null,
  searchData: {
    options: [],
  },
  companyStatuses: null,
  //new
  productParentId: null,
  numOfEmployeesRanges: {
    start: '1',
    end: '10001+',
  },
  switchFemalePicker: ESwitchFemale.CustomCount,
  numOfFemaleFounderFrom: '',
  numOfFemaleFounderTo: '',
  numOfFemaleFounderPercentFrom: '',
  numOfFemaleFounderPercentTo: '',
  yearFoundedRange: false,
  foundedYearFrom: null,
  foundedYearTo: null,
  latestFundingRound1s: [],
  latestFundingRound2s: [],
  totalFundingFrom: '',
  totalFundingTo: '',
  equityFundingFrom: '',
  equityFundingTo: '',
  latestDealAmountFrom: '',
  latestDealAmountTo: '',
  dealAmountFrom: '',
  dealAmountTo: '',
  yearFundingRange: false,
  fundingDateFrom: null,
  fundingDateTo: null,
  investors: [],
  investorsTypes: [],
  leadInvestors: [],
  leadInvestorTypes: [],
  numberOfInvestorsRanges: {
    start: '0',
    end: 0,
  },
  numberOfFundingRounds: {
    start: '0',
    end: 0,
  },
  acquirerTypes: [],
  acquirers: [],
  acquisitionYearRange: false,
  acquiredDateFrom: null,
  acquiredDateTo: null,
  tagGroupIds: [],
  includedEntities: [],
  wentPublicOnDateFrom: null,
  wentPublicOnDateTo: null,
  smartCluster: initSmartCluster,
  modeName: null,
}

export interface IBreadcrumbsData {
  id: number | null
  name: string | null
}

export interface IListGenPptx {
  option: any //action gen pptx
  data: any
  typeAction: ETypeActionGen
  url?: string
}

export interface IPptx {
  value: IListGenPptx[]
  type: ETypeSlide
}

export interface IExportMultiple {
  id: string
  typeChart: ETypeChartExport
  optionChart: string
  groupIndex: EGroupChart
  order: number
  pptx: IPptx[]
  filter: IAnalysisFilter | null
  localFilter: IAnalysisLocalFilter | null
  watchListId: number | null
  viewBy: EEquityFChart | null
}

export type ChipItem = {
  label: string
  key: keyof IAnalysisFilter | keyof ICompanyDealFilter
  value: IAnalysisFilter[keyof IAnalysisFilter] | ICompanyDealFilter[keyof ICompanyDealFilter]
}

export interface IAnalysisSlice {
  filter: IAnalysisFilter
  localFilter: IAnalysisLocalFilter
  chipsHeader: ChipItem[]
  breadcrumbs: IBreadcrumbsData[]
  exportMultiple: IExportMultiple[]
}

export const initLocalFilter: IAnalysisLocalFilter = {
  periodType: PeriodType.Annually,
  locationType: LocationType.Country,
  timePeriod: ETimePeriod.TenYears,
  breakdownStatus: true,
  isFunding: true,
  customizeDate: {
    dateFrom: '',
    dateTo: '',
  },
}

const analysisSlice = createSlice({
  name: 'analysis',
  initialState: {
    filter: initialAnalysisFilter,
    localFilter: initLocalFilter,
    chipsHeader: [],
    breadcrumbs: [],
    exportMultiple: [],
  },
  reducers: {
    updateAnalysisFilter: (state, action) => {
      const newFilter = mergeDeeps(state.filter, action.payload)
      if (!isEqual(state.filter, newFilter)) {
        state.filter = newFilter
      }
    },
    updateAnalysisOption: (state, action) => {
      state.filter = {
        ...state.filter,
        searchData: {
          ...state.filter.searchData,
          options: action.payload,
        },
      }
    },
    clearAnalysisSearchOption: (state, action) => {
      state.filter = mergeDeeps(state.filter, {
        searchData: { options: [] },
      })
    },
    updateAnalysisLocalFilter: (state, action) => {
      state.localFilter = mergeDeeps(state.localFilter, action.payload)
    },
    updateChipsHeader: (state, action) => {
      state.chipsHeader = action.payload
    },
    updateBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload
    },
    updateExportMultiple: (state, action) => {
      state.exportMultiple = action.payload
    },
  },
})
export const {
  updateAnalysisFilter,
  updateAnalysisOption,
  clearAnalysisSearchOption,
  updateAnalysisLocalFilter,
  updateChipsHeader,
  updateBreadcrumbs,
  updateExportMultiple,
} = analysisSlice.actions
export default analysisSlice.reducer

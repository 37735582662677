import { NetworkStatus, useQuery } from '@apollo/client'
import { CompanyPeopleDataGroup } from 'containers/CompanyPeopleContainer'
import { StringIdFilterItem } from 'containers/ProfileSidebarContainer'
import { xor } from 'lodash'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { TBusinessChangeItem } from 'types/BusinessProfile'
import { ColumnSort } from 'utils/consts'
import { ETypeValue } from 'utils/Profile'
import { checkDisableTaxonomy, INIT_TAGS, ITaxonomy } from 'utils/Taxonomy'
import { GetSimilar_getSimilar } from '__generated__/GetSimilar'
import { TFundraisingChangeItem } from '../../components/Profile/Overview/FundingInvestors/types'
import { TTechnologyChangeItem } from '../../components/Profile/Technology/components/types'
import { UserContext } from '../../routers/InitUserWrapper'
import { GET_COMPANY_PENDING_STATUS } from '../../store/operations/queries/GetCompanyPendingStatus'
import { IChangeRequestsUseCase, IDataUseCase, INIT_USECASE } from '../../types/UseCase'
import {
  GetCompanyOverviewResult_getCompanyOverview_fundingInvestors_acquisitionInvestorsItems_investors,
  GetCompanyOverviewResult_getCompanyOverview_fundingInvestors_items_investors,
} from './../../__generated__/GetCompanyOverviewResult'
import {
  GetCompanyPendingStatus,
  GetCompanyPendingStatus_getCompanyPendingStatus,
} from './../../__generated__/GetCompanyPendingStatus'

export enum EExportPart {
  ALL = 'ALL',
  GENERAL = 'GENERAL',
  PARTNERSHIPS = 'PARTNERSHIPS',
  USE_CASE = 'USE_CASE',
  USE_CASE_FSD = 'USE_CASE_FSD',
  USE_CASE_FCT = 'USE_CASE_FCT',
  BUSINESS = 'BUSINESS',
  FUNDING = 'FUNDING',
  PEOPLE = 'PEOPLE',
  PRODUCTS = 'PRODUCTS',
  NEWS = 'NEWS',
}

export const PARTNERSHIPS_PAGINATION_INIT = {
  pageIndex: 1,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30, 40, 50],
}

const INIT_PARTNERSHIPS_STATE = {
  paging: {
    pageIndex: PARTNERSHIPS_PAGINATION_INIT.pageIndex,
    pageSize: PARTNERSHIPS_PAGINATION_INIT.pageSize,
  },
  sortBy: ColumnSort.Desc,
}

export const EXPORT_PART_NAME = new Map<EExportPart, string>([
  [EExportPart.PARTNERSHIPS, 'Partnerships'],
  [EExportPart.USE_CASE, 'Use Case'],
  [EExportPart.USE_CASE_FSD, 'Use Case FSD'],
  [EExportPart.USE_CASE_FCT, 'Use Case FCT'],
  [EExportPart.BUSINESS, 'Business'],
  [EExportPart.FUNDING, 'Funding'],
  [EExportPart.PEOPLE, 'People'],
])

export const initProfileContext: IProfileContextState = {
  hasEdit: false,
  setHasEdit: () => {},
  isPermissionEdit: false,
  companyPending: null,
  fnPendingStatus: () => {},
  refetchLoadingStatus: false,
  checkDisable: false,
  openConfirm: false,
  setOpenConfirm: () => {},
  openRevision: false,
  setOpenRevision: () => {},
  changeRequestsProfile: [],
  setChangeRequestsProfile: () => {},
  alias: [],
  setAlias: () => {},
  otherLocation: [],
  setOtherLocation: () => {},
  suggestedCompanies: [],
  setSuggestedCompanies: () => {},
  isNewSuggestList: false,
  setIsNewSuggestList: () => {},
  tags: INIT_TAGS,
  setTags: () => {},
  taxonomy: [],
  setTaxonomy: () => {},
  setChangeRequestsAcquisition: () => {},
  setChangeRequestsFunding: () => {},
  changeRequestsAcquisition: [],

  changRequestAcquisitionInvestor: [],
  setChangeRequestAcquisitionInvestor: () => {},
  changRequestFundingInvestor: [],
  setChangeRequestFundingInvestor: () => {},
  changeRequestsFunding: [],

  fundingRoundAppend: [],
  setFundingRoundAppend: () => {},
  acquisitionAppend: [],
  setAcquisitionAppend: () => {},
  setAcquisitionInvestorAppend: () => {},
  acquisitionInvestorAppend: [],
  fundingInvestorAppend: [],
  setFundingInvestorAppend: () => {},
  isReset: false,
  setIsReset: () => {},
  businessChanges: [],
  setBusinessChanges: () => {},
  people: [],
  setPeople: () => {},
  changeRequestsPeople: [],
  setChangeRequestsPeople: () => {},
  resetData: () => {},

  idsPeopleRevert: [],
  setIdsPeopleRevert: () => {},
  isEditFunding: false,
  setIsEditFunding: () => {},
  isEditAcquisition: false,
  setIsEditAcquisition: () => {},
  isExport: false,
  setIsExport: () => {},
  changeRequestsUseCase: [],
  setChangeRequestsUseCase: () => {},
  dataUseCase: INIT_USECASE,
  setDataUseCase: () => {},
  initUseCase: INIT_USECASE,
  setInitUseCase: () => {},
  backupList: [],
  setBackupList: () => {},
  exportUseCase: false,
  setExportUseCase: () => {},
  technologyChanges: [],
  setTechnologyChanges: () => {},

  fundraisingChanges: [],
  setFundraisingChanges: () => {},

  partnerships: INIT_PARTNERSHIPS_STATE,
  setPartnerships: () => {},
}

export const ProfileContext = createContext<IProfileContextState>(initProfileContext)

export const ProfileContextProvider = (props: { children: React.ReactNode }) => {
  const { id } = useParams<{ id: string }>()
  const { user } = useContext(UserContext)
  const isPermissionEdit = user?.profile?.companyId === id

  const [changeRequestsFunding, setChangeRequestsFunding] = useState<IChangeRequests[]>([])
  const [changeRequestsAcquisition, setChangeRequestsAcquisition] = useState<IChangeRequests[]>([])
  const [changRequestFundingInvestor, setChangeRequestFundingInvestor] = useState<
    IInvestorChangeRequests[]
  >([])

  const [changRequestAcquisitionInvestor, setChangeRequestAcquisitionInvestor] = useState<
    IInvestorChangeRequests[]
  >([])

  const [fundingRoundAppend, setFundingRoundAppend] = useState<IFundingRoundAppend[]>([])
  const [acquisitionAppend, setAcquisitionAppend] = useState<IAcquisitionAppend[]>([])
  const [acquisitionInvestorAppend, setAcquisitionInvestorAppend] = useState<
    IAcquisitionInvestorAppend[]
  >([])

  const [fundingInvestorAppend, setFundingInvestorAppend] = useState<IFundingInvestorAppend[]>([])
  const [changeRequestsProfile, setChangeRequestsProfile] = useState<IChangeRequests[]>([])
  const [alias, setAlias] = useState<IDataAlias[]>([])
  const [otherLocation, setOtherLocation] = useState<IDataOtherLocation[]>([])
  const [suggestedCompanies, setSuggestedCompanies] = useState<GetSimilarCompanies[]>([])
  const [isNewSuggestList, setIsNewSuggestList] = useState<boolean>(false)
  const [tags, setTags] = useState<IDataTags>(INIT_TAGS)
  const [taxonomy, setTaxonomy] = useState<ITaxonomy[]>([])
  const [hasEdit, setHasEdit] = useState<boolean>(true)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [openRevision, setOpenRevision] = useState<boolean>(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [isEditFunding, setIsEditFunding] = useState<boolean>(false)
  const [isEditAcquisition, setIsEditAcquisition] = useState<boolean>(false)
  const [businessChanges, setBusinessChanges] = useState<TBusinessChangeItem[]>([])
  const [technologyChanges, setTechnologyChanges] = useState<TTechnologyChangeItem[]>([])
  const [fundraisingChanges, setFundraisingChanges] = useState<TTechnologyChangeItem[]>([])
  const [people, setPeople] = useState<IDataPeople[]>([])
  const [changeRequestsPeople, setChangeRequestsPeople] = useState<IChangeRequests[]>([])
  const [idsPeopleRevert, setIdsPeopleRevert] = useState<string[]>([])
  const [isExport, setIsExport] = useState<boolean>(false)
  const [changeRequestsUseCase, setChangeRequestsUseCase] = useState<IChangeRequestsUseCase[]>([])
  const [dataUseCase, setDataUseCase] = useState<IDataUseCase>(INIT_USECASE)
  const [initUseCase, setInitUseCase] = useState<IDataUseCase>(INIT_USECASE)
  const [exportUseCase, setExportUseCase] = useState<boolean>(false)
  const [backupList, setBackupList] = useState<CompanyPeopleDataGroup[]>([])
  const [partnerships, setPartnerships] = useState<IPartnershipsState>(INIT_PARTNERSHIPS_STATE)

  const checkDisable = useMemo(
    () =>
      !changeRequestsProfile.length &&
      !alias.length &&
      !otherLocation.length &&
      !isNewSuggestList &&
      !xor(
        tags.tags.map(e => e.sourceValue.id).filter(e => !!e),
        tags.tags.map(e => e.newValue.id).filter(e => !!e)
      ).length &&
      !xor(
        tags.businessModel.map(e => e.sourceValue.id).filter(e => !!e),
        tags.businessModel.map(e => e.newValue.id).filter(e => !!e)
      ).length &&
      !businessChanges.length &&
      !changeRequestsFunding.length &&
      !changeRequestsAcquisition.length &&
      !changRequestFundingInvestor.length &&
      !changRequestAcquisitionInvestor.length &&
      !fundingRoundAppend.length &&
      !acquisitionAppend.length &&
      !acquisitionInvestorAppend.length &&
      !fundingInvestorAppend.length &&
      !people.length &&
      !changeRequestsPeople.length &&
      !changeRequestsUseCase.length &&
      !technologyChanges.length &&
      !fundraisingChanges.length &&
      checkDisableTaxonomy(taxonomy),
    [
      changeRequestsProfile,
      alias,
      otherLocation,
      isNewSuggestList,
      tags,
      businessChanges,
      changeRequestsFunding,
      changeRequestsAcquisition,
      changRequestFundingInvestor,
      changRequestAcquisitionInvestor,
      fundingRoundAppend,
      acquisitionAppend,
      acquisitionInvestorAppend,
      people,
      changeRequestsPeople,
      changeRequestsUseCase,
      technologyChanges,
      fundraisingChanges,
      taxonomy,
    ]
  )

  const {
    data: getPendingStatus,
    refetch: fnPendingStatus,
    networkStatus,
  } = useQuery<GetCompanyPendingStatus>(GET_COMPANY_PENDING_STATUS, {
    variables: { companyId: +id },
    notifyOnNetworkStatusChange: true,
  })
  const refetchLoadingStatus = networkStatus === NetworkStatus.refetch

  const companyPending = getPendingStatus?.getCompanyPendingStatus || null

  const resetData = (skip?: ISkipReset) => {
    setChangeRequestsProfile([])
    setAlias([])
    setAcquisitionAppend([])
    setChangeRequestsAcquisition([])
    setFundingRoundAppend([])
    setOtherLocation([])
    setIsNewSuggestList(false)
    setTags(INIT_TAGS)
    setTaxonomy(
      taxonomy.map(e => ({
        ...e,
        newValue: e.sourceValue,
        notSend: false,
        reason: '',
      }))
    )
    setChangeRequestsFunding([])
    setFundingInvestorAppend([])
    setAcquisitionInvestorAppend([])
    setChangeRequestFundingInvestor([])
    setChangeRequestAcquisitionInvestor([])
    !skip?.business && setBusinessChanges([])
    !skip?.technology && setTechnologyChanges([])
    !skip?.fundraising && setFundraisingChanges([])
    setPeople([])
    setChangeRequestsPeople([])
    setDataUseCase(initUseCase)
    setChangeRequestsUseCase([])
    setIsReset(true)
  }

  return (
    <ProfileContext.Provider
      value={{
        //Edit
        hasEdit,
        setHasEdit,
        isPermissionEdit,
        //Status
        companyPending,
        fnPendingStatus,
        refetchLoadingStatus,
        checkDisable,
        openConfirm,
        setOpenConfirm,
        openRevision,
        setOpenRevision,
        //Profile
        changeRequestsProfile,
        setChangeRequestsProfile,
        alias,
        setAlias,
        otherLocation,
        setOtherLocation,
        suggestedCompanies,
        setSuggestedCompanies,
        isNewSuggestList,
        setIsNewSuggestList,
        //Taxonomy
        tags,
        setTags,
        taxonomy,
        setTaxonomy,
        //Business
        businessChanges,
        setBusinessChanges,
        //Funding
        setChangeRequestsFunding,
        setChangeRequestsAcquisition,

        changeRequestsFunding,
        changeRequestsAcquisition,
        fundingRoundAppend,
        setFundingRoundAppend,
        acquisitionAppend,
        setAcquisitionAppend,
        setAcquisitionInvestorAppend,
        acquisitionInvestorAppend,
        fundingInvestorAppend,
        setFundingInvestorAppend,
        changRequestAcquisitionInvestor,
        setChangeRequestAcquisitionInvestor,
        changRequestFundingInvestor,
        setChangeRequestFundingInvestor,

        isEditFunding,
        setIsEditFunding,
        isEditAcquisition,
        setIsEditAcquisition,
        //Usecase
        dataUseCase,
        setDataUseCase,
        initUseCase,
        setInitUseCase,
        changeRequestsUseCase,
        setChangeRequestsUseCase,
        exportUseCase,
        setExportUseCase,
        //People
        people,
        setPeople,
        changeRequestsPeople,
        setChangeRequestsPeople,
        idsPeopleRevert,
        setIdsPeopleRevert,
        backupList,
        setBackupList,
        //Reset
        isReset,
        setIsReset,
        resetData,
        //exportPDF
        isExport,
        setIsExport,
        technologyChanges,
        setTechnologyChanges,
        // Fundraising
        fundraisingChanges,
        setFundraisingChanges,
        //partnerships
        partnerships,
        setPartnerships,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  )
}

export interface IProfileContextState {
  hasEdit: boolean
  setHasEdit: (value: boolean) => void
  isPermissionEdit: boolean
  companyPending: GetCompanyPendingStatus_getCompanyPendingStatus | null
  fnPendingStatus: () => void
  refetchLoadingStatus: boolean
  checkDisable: boolean
  openConfirm: boolean
  setOpenConfirm: (value: boolean) => void
  openRevision: boolean
  setOpenRevision: (value: boolean) => void
  changeRequestsProfile: IChangeRequests[]
  setChangeRequestsProfile: Dispatch<SetStateAction<IChangeRequests[]>>
  alias: IDataAlias[]
  setAlias: Dispatch<SetStateAction<IDataAlias[]>>
  setChangeRequestsFunding: (value: IChangeRequests[]) => void
  setChangeRequestsAcquisition: (value: IChangeRequests[]) => void
  changeRequestsFunding: IChangeRequests[]
  changeRequestsAcquisition: IChangeRequests[]
  fundingRoundAppend: IFundingRoundAppend[]
  setFundingRoundAppend: (value: IFundingRoundAppend[]) => void
  acquisitionAppend: IAcquisitionAppend[]
  setAcquisitionAppend: (value: IAcquisitionAppend[]) => void
  setAcquisitionInvestorAppend: (value: IAcquisitionInvestorAppend[]) => void
  acquisitionInvestorAppend: IAcquisitionInvestorAppend[]
  fundingInvestorAppend: IFundingInvestorAppend[]
  setFundingInvestorAppend: (value: IFundingInvestorAppend[]) => void
  isReset: boolean
  setIsReset: (value: boolean) => void
  otherLocation: IDataOtherLocation[]
  setOtherLocation: Dispatch<SetStateAction<IDataOtherLocation[]>>
  suggestedCompanies: GetSimilarCompanies[]
  setSuggestedCompanies: (value: GetSimilarCompanies[]) => void
  isNewSuggestList: boolean
  setIsNewSuggestList: (value: boolean) => void
  tags: IDataTags
  setTags: Dispatch<SetStateAction<IDataTags>>
  taxonomy: ITaxonomy[]
  setTaxonomy: Dispatch<SetStateAction<ITaxonomy[]>>
  businessChanges: TBusinessChangeItem[]
  setBusinessChanges: Dispatch<SetStateAction<TBusinessChangeItem[]>>
  people: IDataPeople[]
  setPeople: (value: IDataPeople[]) => void
  changeRequestsPeople: IChangeRequests[]
  setChangeRequestsPeople: (value: IChangeRequests[]) => void
  resetData(skip?: ISkipReset): void
  idsPeopleRevert: string[]
  setIdsPeopleRevert: (value: string[]) => void
  isEditFunding: boolean
  setIsEditFunding: (value: boolean) => void
  isEditAcquisition: boolean
  setIsEditAcquisition: (value: boolean) => void
  isExport: boolean
  setIsExport: (value: boolean) => void
  changeRequestsUseCase: IChangeRequestsUseCase[]
  setChangeRequestsUseCase: Dispatch<SetStateAction<IChangeRequestsUseCase[]>>
  dataUseCase: IDataUseCase
  setDataUseCase: (value: IDataUseCase) => void
  initUseCase: IDataUseCase
  setInitUseCase: (value: IDataUseCase) => void
  backupList: CompanyPeopleDataGroup[]
  setBackupList: (value: CompanyPeopleDataGroup[]) => void
  exportUseCase: boolean
  setExportUseCase: (value: boolean) => void
  changRequestAcquisitionInvestor: IInvestorChangeRequests[]
  setChangeRequestAcquisitionInvestor: (value: IInvestorChangeRequests[]) => void
  changRequestFundingInvestor: IInvestorChangeRequests[]
  setChangeRequestFundingInvestor: (value: IInvestorChangeRequests[]) => void
  technologyChanges: TTechnologyChangeItem[]
  setTechnologyChanges: Dispatch<SetStateAction<TTechnologyChangeItem[]>>
  fundraisingChanges: TFundraisingChangeItem[]
  setFundraisingChanges: Dispatch<SetStateAction<TFundraisingChangeItem[]>>
  partnerships: IPartnershipsState
  setPartnerships: Dispatch<SetStateAction<IPartnershipsState>>
}

export interface ISkipReset {
  business?: boolean
  technology?: boolean
  fundraising?: boolean
}
export interface IChangeRequests {
  idRequests: string
  id: string | null
  tableName: string
  columnName: string
  sourceValue: string
  newValue: string
  isDelete: boolean
  typeReason?: ETypeReason
  reason: string
  supportingDetails: string
  title: string
  typeValue?: ETypeValue
  companyName?: string
  uuid?: string
}

export interface IFundingRoundAppend {
  roundType1: string | null
  roundType2: string
  investment: string
  valuation: string
  date: string
  comment: string
  lead_investors: Array<GetCompanyOverviewResult_getCompanyOverview_fundingInvestors_items_investors>
  investors: Array<GetCompanyOverviewResult_getCompanyOverview_fundingInvestors_items_investors>
  isAppend: boolean
  idRequests: string
  reason: string
  typeReason?: string
}

export interface IAcquisitionAppend {
  amount: string
  date: string
  comment: string
  status: string
  investors: GetCompanyOverviewResult_getCompanyOverview_fundingInvestors_acquisitionInvestorsItems_investors[]
  isAppend: boolean
  idRequests: string
  reason: string
  typeReason?: string
}

export interface IAcquisitionInvestorAppend {
  acquisition_id: string | null
  investors: (number | undefined | string)[]
  reason: string | null
}

export interface IFundingInvestorAppend {
  funding_id: string | null
  investors: (number | undefined | string)[]
  lead_investors: (number | undefined | string)[]
  reason: string | null
}

export interface IInvestorChangeRequests {
  id: string | null
  tableName: string
  columnName: string
  newValue: string
  isDelete: boolean
  reason: string
  parent_id: string | null
}

export interface IDataAlias {
  idRequests: string
  companyAlias: string

  title: string
  typeReason?: ETypeReason
  reason: string
  supportingDetails: string
}

export interface IDataOtherLocation {
  idRequests: string
  country: string
  city: string
  is_headquarters: false
  title: string
  typeReason?: ETypeReason
  reason: string
  supportingDetails: string
}

export interface ITagsItem {
  sourceValue: StringIdFilterItem
  newValue: StringIdFilterItem
  supportingDetails: string
  isShow: boolean
}

export interface IDataTags {
  tags: ITagsItem[]
  businessModel: ITagsItem[]
}

export interface IDataPeople {
  idRequests: string
  id: string
  name: string
  gender: string
  imageUrl?: string
  facebook?: string
  linkedin?: string
  twitter?: string
  jobTitle: string
  titleName: string
  description?: string
  numExits?: string
  numFoundedOrganizations?: string
  emailAddress?: string
  // TODO:
  // lgbtqIdentity?: string
  // ethnicity?: string
  image?: string
  reason?: string
}

export enum ETypeReason {
  Incorrect = 'incorrect',
  NoMissing = 'noMissing',
  Other = 'other',
}

export const TYPE_REASON_TITLE: Record<ETypeReason, string> = {
  incorrect: 'Incorrect Information',
  noMissing: 'No / Missing Information',
  other: 'Other',
}

export const ReasonOption = [
  { value: ETypeReason.Incorrect, label: TYPE_REASON_TITLE[ETypeReason.Incorrect] },
  { value: ETypeReason.NoMissing, label: TYPE_REASON_TITLE[ETypeReason.NoMissing] },
  { value: ETypeReason.Other, label: TYPE_REASON_TITLE[ETypeReason.Other] },
]

export enum EPendingStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
}

export const initChangeRequests: IChangeRequests = {
  idRequests: '',
  id: '',
  tableName: '',
  columnName: '',
  sourceValue: '',
  newValue: '',
  isDelete: false,
  typeReason: ETypeReason.Other,
  reason: ' ',
  supportingDetails: '',
  title: '',
}

export interface IPersonalProfile {
  people_id: string | null
  name: string | null
  gender: string | null
  image_url: string | null
  image_bucket_url?: string | null
  title_name: string | null
  title_type_name: string | null
  linkedin?: string | null
  email_address?: string | null
  full_title_names: (string | null)[] | null
  uuid: string | null
  // TODO:
  // lgbtq_identity?: string | null
  // ethnicity?: string | null
}

interface IPagination {
  pageIndex: number
  pageSize: number
}
export interface IPartnershipsState {
  paging: IPagination
  sortBy: ColumnSort
}

export type GetSimilarCompanies = Omit<GetSimilar_getSimilar, '__typename'>

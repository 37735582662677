import axios from 'axios'
import config from 'core/config/auth'

export default function (token: string) {
  return axios.get(`${config.api}/noExpired`, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
}

//issue load chunk failed: https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
export default function componentLoader(
  lazyComponent: any,
  attemptsLeft: number = 3,
  interval: number = 1000
): any {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: any) => {
        // let us retry after 1000 ms
        setTimeout(() => {
          if (attemptsLeft === 0) {
            window.location.reload()
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, interval)
      })
  })
}

import { makeStyles } from '@material-ui/core'
import FCTTextField from 'components/TextField/FCTTextField'
import { ReactNode } from 'react'
import { Control, Controller } from 'react-hook-form'

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
}))

export type IField<T> = {
  name: keyof T
  label: string
  type?: string
  endArdoment?: ReactNode
  disabled?: boolean
  hidden?: boolean
  placeholder?: string
}

export type FormFieldProps<T> = {
  field: IField<T>
  onBlur(): void
  onFocus?(): void
  onKeyDown?(): void
  control: Control<T>
  error?: { message?: string }
  afterChange?(): void
  disabled?: boolean
}

export function FormField<T>({
  field,
  onBlur,
  control,
  error,
  afterChange = () => {},
  onFocus = () => {},
}: FormFieldProps<T>) {
  const classes = useStyles()

  return (
    <Controller
      control={control as any}
      name={field.name as string}
      disabled={field.disabled}
      render={props => (
        <FCTTextField
          {...props}
          disabled={field.disabled}
          type={field.type}
          className={classes.input}
          label={field.label}
          placeholder={field.placeholder || field.label}
          error={!!error}
          FormHelperTextProps={!!error ? { role: 'alert' } : undefined}
          helperText={error?.message || ''}
          fullWidth
          onBlur={() => onBlur()}
          onFocus={() => onFocus()}
          onChange={e => {
            props.onChange(e)
            afterChange()
          }}
          inputProps={{
            id: `#${field.name}`,
          }}
          InputLabelProps={{
            disabled: field.disabled,
            htmlFor: `#${field.name}`,
            shrink: true,
          }}
          InputProps={{
            endAdornment: field.endArdoment,
          }}
        />
      )}
    />
  )
}

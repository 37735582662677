import {
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Cell, Row, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import FCTIcon from '../../components/Icon/Icon'
import { IColumnSort, KEY_COLUMN_DISTANCE } from '../../store/cache/CompaniesList'
import { initOpenOptionFilter } from '../../store/features/filter/FilterSlice'
import theme from '../../theme'
import { SortInfo } from '../../types/CompanyDealFilter'
import { numberWithCommas } from '../../utils/convert/number'
import { InternalDbCompanyTypes } from '../../__generated__/globalTypes'
import FCTButton from '../Button/FCTButton'
import Loading from '../Loading'
import { FCTTablePagination } from '../Pagination/FCTTablePagination'
import { ChangeType } from '../Select/FCTSelect'
import { ICompanyDealFilter, EModeName } from './../../types/CompanyDealFilter'
import { useCompanyDealFilter } from 'store/features/company-deal/CompanyDealHook'
import { SortDirection } from 'utils/consts'
import FCTTooltip from 'components/Tooltip/Tooltip'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  container: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  paper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pagination: {},
  pages: {
    display: 'flex',
    padding: '21px 0px 21px 32px',
  },
  currentPage: {
    fontWeight: 'bold',
  },
  tableCell: {
    padding: '10px 16px',
    border: 0,
    color: theme.palette.grey[800],
    fontSize: 12,
    '&:first-child': {
      position: 'sticky',
      padding: '0 0 0 6px',
      maxWidth: 34,
      left: 0,
      zIndex: 1,
      boxShadow: '3px 0px 3px rgba(0, 0, 0, 0.03), 1px 0px 0px #E5E7EF',
      backgroundColor: 'inherit',
      fontWeight: 600,
    },
    '&:nth-child(2)': {
      paddingLeft: '10px',
      position: 'sticky',
      left: 40,
      zIndex: 1,
      boxShadow: '3px 0px 3px rgba(0, 0, 0, 0.03), 1px 0px 0px #E5E7EF',
      backgroundColor: 'inherit',
      fontWeight: 600,
    },
  },
  tableCellWithoutCheckbox: {
    padding: '10px 16px',
    border: 0,
    color: theme.palette.grey[800],
    fontSize: 12,
    '&:first-child': {
      position: 'sticky',
      padding: '0 0 0 20px',
      left: 0,
      zIndex: 1,
      boxShadow: '3px 0px 3px rgba(0, 0, 0, 0.03), 1px 0px 0px #E5E7EF',
      backgroundColor: 'inherit',
      fontWeight: 600,
    },
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: 'white',
  },
  table: {
    height: '100%',
    borderBottom: '1px solid #E2E8F0',
    backgroundColor: '#FFF',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F8FAFC',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#FFF',
    },
  },
  firstRowInHighLight: {
    position: 'sticky',
    top: 56,
    zIndex: 2,
    backgroundColor: '#F8FAFC !important',
  },
  rowsInHighLight: {
    backgroundColor: '#F4FCF8',
    //TODO: await
    // backgroundColor: '#eaf8e6',
  },
  columnFilter: {
    border: 0,
    position: 'sticky',
    right: 0,
    zIndex: 1,
    padding: 0,
    backgroundColor: '#fff',
  },
  filterCell: {
    width: '60px',
  },
  iconSort: {
    marginRight: 5,
  },
  loading: {
    height: 'calc(100vh - 400px)',
    width: '100%',
  },
  popover: {
    padding: '12px 8px',
    minWidth: 200,
    boxSizing: 'border-box',
  },
  textPopupSort: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
    margin: '0 8px 4px',
  },
  sortItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    '&:hover': {
      backgroundColor: `${theme.palette.grey[100]}`,
      cursor: 'pointer',
    },
    borderRadius: 3,
  },
  textDetailSort: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
    flex: 1,
    marginLeft: 10,
  },
  line: {
    backgroundColor: '#E7ECF3',
    height: '1px',
    margin: 8,
  },
  textFooter: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#1e293b',
  },
  wrapperIconArrowDown: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
  },
  wrapperScrollFooter: {
    backgroundColor: '#fff',
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  wrapperScrollDown: {
    textAlign: 'center',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  wrapperLoadMore: {
    backgroundColor: 'white',
    height: 30,
    width: '100%',
  },
  button: {
    position: 'absolute',
    verticalAlign: 'baseline',
    minWidth: 32,
    height: 40,
    padding: 11,
    right: 0,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  infoDescription: {
    opacity: 1,
    display: 'block',
  },
})

// define key to hide gotoFilter by condition
const BUSINESS_LINE_KEY = 'businessLineRiskValueChain'

interface IPagination {
  pageIndex: number
  pageSize: number
}

interface Props {
  columns: any
  data: any[]
  total: number
  pageCount?: number
  loading: boolean
  getRowId(row: any): void
  sortColumn?(value: string, type: ColumnSort): void
  columnSort?: IColumnSort[]
  arrColumnStyleCenter?: string[]
  columnFilter?: React.ReactNode
  arrColumnStyleRight?: string[]
  filterSortColumn?: SortInfo[] | null
  pagination?: IPagination
  setPagination?(value: IPagination): void
  lablePagination?: string
  setOpenOptionFilter?(value: any): void
  onRowSelectedChange?(value: any): void
  companyDealFilter?: ICompanyDealFilter
  hiddenPagination?: boolean
  hasNoFilterCell?: boolean
  onWheel?(e: any): void
  tableRef?: any
  showScroll?: boolean
  loadMore?(): void
  handleBackToTop?(): void
  topSimilarCompanyIds?: number[]
  idActiveScroll?: string
  showFull?: boolean
}

export const LISTVIEW_PAGINATION_INIT = {
  pageIndex: 1,
  pageSize: 50,
  pageSizeOptions: [10, 25, 50, 100],
}

const FCTSelectTable = (
  {
    columns,
    data,
    total,
    pageCount = 1,
    loading,
    getRowId,
    sortColumn,
    columnSort,
    arrColumnStyleCenter,
    arrColumnStyleRight,
    columnFilter,
    filterSortColumn,
    pagination = {
      pageIndex: 1,
      pageSize: total,
    },
    setPagination = () => {},
    lablePagination,
    setOpenOptionFilter,
    onRowSelectedChange,
    companyDealFilter,
    hiddenPagination,
    hasNoFilterCell,
    onWheel = () => {},
    tableRef,
    showScroll,
    loadMore = () => {},
    handleBackToTop = () => {},
    topSimilarCompanyIds,
    idActiveScroll,
    showFull,
  }: Props,
  ref?: any
) => {
  const classes = useStyles()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setPageSize,
    page,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      getRowId,
      manualPagination: false,
      pageCount: pageCount,
      autoResetSelectedRows: false,
    } as any,
    useSortBy,
    usePagination,
    useRowSelect
  ) as any
  const [companyFilter, __] = useCompanyDealFilter()

  useEffect(() => {
    onRowSelectedChange ? onRowSelectedChange(Object.keys(selectedRowIds)) : null
  }, [selectedRowIds])

  useImperativeHandle(ref, () => ({
    resetSelectedRow() {
      toggleAllRowsSelected(false)
    },
    allRowsSelected() {
      toggleAllRowsSelected(true)
    },
  }))

  const arrColumnSort = columnSort?.map(e => e.key) || []
  const defaultSort = arrColumnSort?.reduce(
    (acc: any, curr) => ((acc[curr] = ColumnSort.Default), acc),
    {}
  )

  const [isSort, setIsSort] = React.useState(defaultSort)
  const [sortId, setSortId] = React.useState<ISortId>({
    id: '',
    hasGoFilter: false,
    hasSortBy: true,
  })

  useEffect(() => {
    if (!loading) {
      setPageSize(
        String(((showScroll || showFull) && data.length) || LISTVIEW_PAGINATION_INIT.pageSize)
      )
    }
  }, [data.length])

  useEffect(() => {
    if (filterSortColumn !== null) {
      let arr = defaultSort
      filterSortColumn?.forEach(e => {
        let type = e.direction === SortDirection.Asc ? ColumnSort.Asc : ColumnSort.Desc
        arr[e.field] = type
      })
      setIsSort(arr)
    }
  }, [filterSortColumn])

  const handlePopup = (event: any, id: string) => {
    const newSortId = {
      id: id,
      hasGoFilter:
        columnSort
          ?.filter(e => e.groupFilter !== '')
          .map(e => e.key)
          .includes(id) || false,
      hasSortBy: columnSort?.find(e => e.key === id)?.hasSortBy,
    }
    setSortId(newSortId)
    setAnchorEl(event.currentTarget)
  }

  const handleSort = (event: React.ChangeEvent<{}>, id: string, type: ColumnSort) => {
    if (type != ColumnSort.Filter) {
      if (isSort[id] === type) {
        setIsSort({ ...isSort, [id]: ColumnSort.Default })
        sortColumn && sortColumn(id, ColumnSort.Default)
      } else {
        setIsSort({ ...isSort, [id]: type })
        sortColumn && sortColumn(id, type) //update and add field sort new to filter
      }
    } else {
      const groupFilter = columnSort?.find(e => e.key === id)?.groupFilter || ''
      setOpenOptionFilter &&
        setOpenOptionFilter({ ...initOpenOptionFilter, filter: true, [groupFilter]: true })
      event.stopPropagation()
      if (idActiveScroll) {
        const scrollId = document.getElementById(idActiveScroll)
        if (scrollId)
          scrollId.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
      }
    }
    setAnchorEl(null)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const isLoading = showScroll ? loading && (!data.length || data.length < 10) : loading

  return (
    <div className={classes.root}>
      <div
        className={classes.container}
        ref={tableRef}
        onScroll={() => {
          if (!showScroll) return
          loadMore()
        }}
        onWheel={onWheel}
      >
        {/* TODO using Loading HOC */}
        {isLoading ? (
          <div className={classes.loading}>
            <Loading />
          </div>
        ) : (
          <>
            <Table {...getTableProps()} size="small" className={classes.table}>
              <TableHead className={classes.tableHead}>
                <>
                  {headerGroups.map((headerGroup: any) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any, index: number) => {
                        return (
                          <TableCell
                            {...column.getHeaderProps()}
                            style={{
                              backgroundColor: index < 2 ? '#FFFFFF' : '',
                            }}
                            className={clsx({
                              [classes.tableCell]: !hiddenPagination || showScroll,
                              [classes.tableCellWithoutCheckbox]: !!hiddenPagination && !showScroll,
                            })}
                          >
                            {arrColumnSort?.includes(column.id) ? (
                              <div
                                style={{
                                  display: 'flex',
                                  cursor: 'pointer',
                                  alignItems: 'center',
                                }}
                                onClick={e => {
                                  if (column.id !== KEY_COLUMN_DISTANCE) handlePopup(e, column.id)
                                }}
                                aria-describedby={Boolean(anchorEl) ? 'simple-popover' : undefined}
                              >
                                <FCTIcon
                                  className={classes.iconSort}
                                  name="up"
                                  width={12}
                                  height={12}
                                  color={
                                    isSort[column.id] == ColumnSort.Asc ||
                                    isSort[column.id] == ColumnSort.Desc
                                      ? theme.palette.primary.light
                                      : theme.palette.grey['400']
                                  }
                                  style={{
                                    transform:
                                      isSort[column.id] == ColumnSort.Desc
                                        ? 'rotate(180deg)'
                                        : 'rotate(0deg)',
                                  }}
                                />
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  {column.render('Header')}
                                  {column.id === KEY_COLUMN_DISTANCE && (
                                    <FCTTooltip
                                      placement="top"
                                      arrow
                                      disableHoverListener={false}
                                      title={
                                        <Typography style={{ fontSize: 12 }}>
                                          {'Shorter distance indicates closer match'}
                                        </Typography>
                                      }
                                    >
                                      <span
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginLeft: '8px',
                                        }}
                                      >
                                        <FCTIcon
                                          name="info"
                                          width={12}
                                          height={12}
                                          color={theme.palette.grey[400]}
                                          className={classes.infoDescription}
                                        />
                                      </span>
                                    </FCTTooltip>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div style={{ whiteSpace: 'nowrap' }}>{column.render('Header')}</div>
                            )}
                          </TableCell>
                        )
                      })}
                      {columnFilter && (
                        <TableCell style={{ paddingRight: 8 }} className={classes.columnFilter}>
                          {columnFilter}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  <Popover
                    classes={{ paper: classes.popover }}
                    id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {sortId.hasSortBy && (
                      <>
                        <Typography className={classes.textPopupSort}>Sort by</Typography>
                        <div
                          className={classes.sortItem}
                          onClick={e => handleSort(e, sortId.id, ColumnSort.Asc)}
                        >
                          <FCTIcon
                            color={theme.palette.primary.light}
                            width={16}
                            height={16}
                            name="ascending"
                          />
                          <Typography className={classes.textDetailSort}>Ascending</Typography>
                          {isSort[sortId.id] == ColumnSort.Asc && (
                            <FCTIcon
                              color={theme.palette.primary.light}
                              width={14}
                              height={14}
                              name="checked"
                            />
                          )}
                        </div>
                        <div
                          className={classes.sortItem}
                          onClick={e => handleSort(e, sortId.id, ColumnSort.Desc)}
                        >
                          <FCTIcon
                            color={theme.palette.primary.light}
                            width={16}
                            height={16}
                            name="descending"
                          />
                          <Typography className={classes.textDetailSort}>Descending</Typography>
                          {isSort[sortId.id] == ColumnSort.Desc && (
                            <FCTIcon
                              color={theme.palette.primary.light}
                              width={14}
                              height={14}
                              name="checked"
                            />
                          )}
                        </div>
                      </>
                    )}
                    {sortId.hasGoFilter && (
                      <>
                        {sortId.hasSortBy && <div className={classes.line}></div>}
                        <Typography className={classes.textPopupSort}>Filter</Typography>
                        <div
                          className={classes.sortItem}
                          onClick={e => handleSort(e, sortId.id, ColumnSort.Filter)}
                        >
                          <FCTIcon
                            color={theme.palette.primary.light}
                            width={16}
                            height={16}
                            name="filter"
                          />
                          <Typography className={classes.textDetailSort}>Go to Filter</Typography>
                        </div>
                      </>
                    )}
                  </Popover>
                </>
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row: Row, indexRow: number) => {
                  const hasHighLightCompany = topSimilarCompanyIds?.includes(+row.id)
                  prepareRow(row)
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      className={clsx({
                        [classes.tableRow]: !hasHighLightCompany,
                        [classes.rowsInHighLight]: hasHighLightCompany,
                        [classes.firstRowInHighLight]:
                          companyFilter.modeName === EModeName.SmartCluster
                            ? false
                            : indexRow === 0 && hasHighLightCompany,
                      })}
                      data-testid="listview-item-row"
                    >
                      {row.cells.map((cell: Cell, indexCell: number) => {
                        return (
                          <TableCell
                            style={{
                              textAlign: arrColumnStyleCenter?.includes(cell.column.id)
                                ? 'center'
                                : arrColumnStyleRight?.includes(cell.column.id)
                                ? 'right'
                                : 'left',
                            }}
                            {...cell.getCellProps()}
                            className={clsx({
                              [classes.tableCell]: !hiddenPagination || showScroll,
                              [classes.tableCellWithoutCheckbox]: !!hiddenPagination && !showScroll,
                            })}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        )
                      })}
                      {!hasNoFilterCell && (
                        <TableCell style={{ border: 0 }} className={classes.filterCell}></TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {!!showScroll && !!data?.length && data?.length < total && (
              <div className={classes.wrapperLoadMore}>
                <Loading size={20} />
              </div>
            )}
          </>
        )}
      </div>

      {!hiddenPagination && (
        <FCTTablePagination
          rowsPerPageOptions={LISTVIEW_PAGINATION_INIT.pageSizeOptions}
          rowsPerPage={LISTVIEW_PAGINATION_INIT.pageSize}
          onChangeRowsPerPage={(obj: { type: ChangeType; value: string }) => {
            setPageSize(obj.value)
            setPagination({
              pageIndex: LISTVIEW_PAGINATION_INIT.pageIndex,
              pageSize: Number(obj.value),
            })
          }}
          labelDisplayedRows={
            <>
              of <strong>{numberWithCommas(total)}</strong> {lablePagination}
            </>
          }
          paginationProps={{
            count: Math.ceil(total / pagination.pageSize),
            page: pagination.pageIndex,
            onChange: (e, value) => {
              setPagination({ ...pagination, pageIndex: value })
            },
          }}
        />
      )}

      {!!showScroll && (
        <div className={classes.wrapperScrollFooter}>
          <p className={classes.textFooter}>
            Displaying <strong data-testid="listview-count">{data.length || 0}</strong> of{' '}
            <strong data-testid="listview-companies">{numberWithCommas(total)}&nbsp;</strong>
            {lablePagination}
          </p>
          {pagination.pageIndex === LISTVIEW_PAGINATION_INIT.pageIndex ? (
            <>
              {data?.length < total && (
                <div className={clsx(classes.wrapperScrollDown, classes.textFooter)}>
                  <div className={classes.wrapperIconArrowDown}>
                    <FCTIcon
                      color={theme.palette.grey[600]}
                      width={14}
                      height={14}
                      name="arrowdown"
                    />
                  </div>
                  <p>Scroll down to see 50 additional results</p>
                </div>
              )}
            </>
          ) : (
            <FCTButton variant="outlined" className={classes.button} onClick={handleBackToTop}>
              <FCTIcon
                name="arrowtop"
                width={16}
                height={16}
                color={theme.palette.primary.contrastText}
              />
            </FCTButton>
          )}
        </div>
      )}
    </div>
  )
}

export default forwardRef(FCTSelectTable)

export enum ColumnSort {
  Asc,
  Desc,
  Default,
  Filter,
}

interface ISortId {
  id: string
  hasGoFilter: boolean
  hasSortBy: boolean | undefined
}

import { ReactNode, useState, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import FCTTextField from '../../TextField/FCTTextField'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import SubmitButton from '../BusinessEmail/SubmitButton'
import { IconButton, InputAdornment, makeStyles, useTheme, Box } from '@material-ui/core'
import Icon from '../../Icon/Icon'

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
}))

export interface ISignInWithCredentialsFormData {
  username: string
  password: string
}

type Props = {
  handleSubmit: (data: ISignInWithCredentialsFormData) => void | Promise<void>
  ForgotPasswordElement?: React.ReactElement
}

const SignInWithCredentialsForm = ({ handleSubmit, ForgotPasswordElement }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
    trigger,
  } = useForm<ISignInWithCredentialsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        username: yup.string().required('This field is required.'),
        password: yup.string().required('This field is required.'),
      })
    ),
  })

  const FIELDS: {
    name: keyof ISignInWithCredentialsFormData
    label: string
    type?: string
    endArdoment?: ReactNode
  }[] = [
    { name: 'username', label: 'Business Email' },
    {
      name: 'password',
      label: 'Password',
      type: showPassword ? 'text' : 'password',
      endArdoment: useMemo(
        () => (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword(prev => !prev)
              }}
              onMouseDown={e => {
                e.preventDefault()
              }}
            >
              <Icon name={showPassword ? 'eyeopen' : 'eyeClose'} color={theme.palette.grey[400]} />
            </IconButton>
          </InputAdornment>
        ),
        [showPassword]
      ),
    },
  ]

  return (
    <>
      <form
        id="signInWithCredentialsForm"
        onSubmit={async e => {
          try {
            setLoading(true)
            await handleSubmitForm(d => handleSubmit(d))(e)
          } finally {
            setLoading(false)
          }
        }}
      >
        {FIELDS.map((field, idx) => (
          <Box
            key={field.name}
            marginTop="30px"
            marginBottom={!!errors[field.name] && idx === FIELDS.length - 1 ? '18px' : 'unset'}
          >
            <Controller
              control={control}
              name={field.name}
              render={props => (
                <FCTTextField
                  {...props}
                  type={field.type}
                  className={classes.input}
                  label={field.label}
                  placeholder={field.label}
                  error={!!errors[field.name]}
                  FormHelperTextProps={!!errors[field.name] ? { role: 'alert' } : undefined}
                  helperText={errors[field.name]?.message || ''}
                  fullWidth
                  onBlur={() => {
                    control.setValue(field.name, control.getValues(field.name)?.trim() || '')
                    trigger(field.name)
                  }}
                  inputProps={{
                    style: { opacity: 1 },
                    id: `#${field.name}`,
                  }}
                  InputLabelProps={{
                    disabled: false,
                    htmlFor: `#${field.name}`,
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: field.endArdoment,
                  }}
                />
              )}
            />
          </Box>
        ))}
        {ForgotPasswordElement && ForgotPasswordElement}
        <SubmitButton
          form="signInWithCredentialsForm"
          disabled={!isValid}
          text="Login"
          loading={loading}
        />
      </form>
    </>
  )
}

export default SignInWithCredentialsForm

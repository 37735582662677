import { useQuery } from '@apollo/client'
import { makeStyles, Typography } from '@material-ui/core'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Loading from '../components/Loading'
import SearchHeader from '../components/Search/SearchHeader'
import { convertOptionsToHighlight, convertOptionsToQuery } from '../store/cache/Common'
import { selectSearchOption } from '../store/features/search/SearchSelector'
import { resetSearchOption } from '../store/features/search/SearchSlice'
import {
  SearchCompaniesResult,
  SEARCH_COMPANIES,
} from '../store/operations/queries/local/rest/SearchCompanies'
import theme from '../theme'
import { commonCompanySearchQuery } from '../utils/CompanyFilter'
import SearchResultContainer from './SearchResultContainer'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullWidth: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 58px)',
    overflow: 'auto',
  },
  body: {
    background: '#fff',
    width: '90%',
    maxWidth: 971,
    margin: '0 auto',
    marginTop: 30,
    border: '1px solid #E7ECF3',
    boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    borderRadius: 3,
  },
  link: {
    color: '#29BA74',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 400,
  },
  notiText: {
    fontSize: 14,
    lineHeight: '22px',
  },
}))

const SearchContainer = () => {
  const classes = useStyles()
  const history = useHistory()
  const searchOptions = useSelector(selectSearchOption)
  const dispatch = useDispatch()
  const companyQuery = useMemo(
    () => ({
      query: convertOptionsToQuery(searchOptions || []),
      highlight: convertOptionsToHighlight(searchOptions || []),
    }),
    [searchOptions]
  )
  const { data, loading } = useQuery<SearchCompaniesResult>(SEARCH_COMPANIES, {
    variables: {
      input: `q=${encodeURIComponent(
        companyQuery.query
      )}&sort=status_code desc,_score desc&&q.parser=structured&highlight=${
        companyQuery.highlight
      }`,
    },
    skip: isNil(searchOptions) || !searchOptions.length,
  })

  const companySearchQuery = useMemo(() => convertOptionsToQuery(searchOptions || []), [
    searchOptions,
  ])
  const { data: companySearchFilter } = useQuery<SearchCompaniesResult>(SEARCH_COMPANIES, {
    variables: {
      input: `q=${encodeURIComponent(companySearchQuery)}${commonCompanySearchQuery}`,
    },
    skip: isNil(searchOptions) || !searchOptions.length,
  })

  const listAllCompanyIds = companySearchFilter?.searchCompanies.hits.hit.map(hit =>
    String(hit.fields.company_id[0])
  )

  const selectedName = useMemo(() => {
    const listCompanies = data?.searchCompanies?.hits?.hit
    if (listCompanies?.length === 1) return listCompanies[0]?.fields?.name[0]
  }, [data?.searchCompanies?.hits?.hit])

  return (
    <div className={classes.root}>
      <div className={classes.fullWidth}>
        <SearchHeader
          selectedName={selectedName}
          listAllCompanyIds={listAllCompanyIds || []}
          total={data?.searchCompanies?.hits?.found}
        />
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.body}>
            <SearchResultContainer
              companies={data?.searchCompanies?.hits?.hit}
              searchKey={new URLSearchParams(history.location.search).get('q') || ''}
              total={data?.searchCompanies?.hits?.found}
              totalCompanies={data?.searchCompanies?.hits?.found}
            />
          </div>
        )}
        {!!data?.searchCompanies?.hits?.found && (
          <div style={{ padding: '32px 0 38px 0', margin: '0 auto' }}>
            <Typography
              variant="body1"
              component={'span'}
              style={{ color: theme.palette.grey[900] }}
            >
              Can't find what you are looking for?{' '}
            </Typography>
            <Link
              to="/app/analysis"
              className={classes.link}
              style={{ fontSize: 16, fontWeight: 600 }}
              onClick={() => {
                dispatch(resetSearchOption(null))
              }}
            >
              Try navigating taxonomy
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
export default SearchContainer

import csv2json from 'csvjson-csv2json'
import { Entity, Transform } from 'dto-mapping'
import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import { useUserContext } from 'routers/InitUserWrapper'
import { categories as CATEGORIES_CSV, topics as TOPICS_CSV } from '../assets/webflow'

const initialState = {
  categories: [],
  topics: [],
  query: '',
  updateQuery: () => {},
}

const readFile = (url: string) => {
  return fetch(url, {
    headers: {
      'Content-Type': 'text/plain',
      Accept: 'text/plain',
    },
  })
    .then(r => r.text())
    .then(text => text)
}

interface IKnowledgeHubState {
  categories: IKnowledgeHubCategory[]
  topics: IKnowledgeHubTopic[]
  query: string
  updateQuery(q: string): void
}

const Context = React.createContext<IKnowledgeHubState>(initialState)

const knowledgeHubReducer = (state: IKnowledgeHubState, action: any) => {
  switch (action.type) {
    case 'UPDATE_CATEGORIES':
      return { ...state, categories: action.payload }
    case 'UPDATE_TOPICS':
      return { ...state, topics: action.payload }
    case 'UPDATE_QUERY': {
      if (typeof action.payload !== 'string') return state
      return { ...state, query: action.payload }
    }
    default:
      return state
  }
}

export const KnowledgeHubProvider = ({ children }: any) => {
  const { isStartupUser } = useUserContext()

  const [state, dispatch] = useReducer(knowledgeHubReducer, initialState)

  const updateCategories = useCallback((categories: IKnowledgeHubCategory[]) => {
    dispatch({ type: 'UPDATE_CATEGORIES', payload: categories })
  }, [])

  const updateTopics = useCallback((topics: IKnowledgeHubTopic[]) => {
    dispatch({ type: 'UPDATE_TOPICS', payload: topics })
  }, [])

  const updateQuery = useCallback((topics: string) => {
    dispatch({ type: 'UPDATE_QUERY', payload: topics })
  }, [])

  useEffect(() => {
    const fetchFile = async () => {
      const cateData = await readFile(CATEGORIES_CSV)

      const topicData = await readFile(TOPICS_CSV)
      const topicJson = (csv2json(topicData) as any[]).map(i => new KnowledgeHubTopic(i))

      const topicLookup: Record<string, IKnowledgeHubTopic> = topicJson.reduce((acc, cur) => {
        return { ...acc, [cur.Slug]: cur }
      }, {})

      const cateState = (csv2json(cateData) as any[])
        .map(i => {
          const temp = new KnowledgeHubCategory(i)
          return {
            ...temp,
            topicsObj: temp.Topics.map(slug => topicLookup[slug]).filter(Boolean),
          }
        })
        .filter(i => !isStartupUser || i.Name === 'About FCT' || i.Name === 'For FinTechs')

      const cateLookup: Record<string, string> = cateState.reduce((acc, cur) => {
        return { ...acc, [cur.Slug]: cur.Name }
      }, {})

      const topicState = topicJson
        .map(i => {
          return {
            ...i,
            relatedTopicsObj: i.RelatedTopics.map(slug => topicLookup[slug]).filter(Boolean),
            subTopicsObj: i.SubTopics.map(slug => topicLookup[slug]).filter(Boolean),
            parentTopicObj: topicLookup[i['Parent Topic']] || null,
            Content: i.Content.replaceAll(
              'https://knowledge-hubfct.bcg.com',
              `${window.location.origin}/knowledge-hub`
            ).replaceAll('javascript:window.location.origin', `${window.location.origin}`),
            CategoryName: cateLookup[i.Category],
          }
        })
        .filter(i => !isStartupUser || cateState.some(c => c.Topics.includes(i.Slug)))

      updateCategories(cateState)
      updateTopics(topicState)
    }
    fetchFile()
  }, [])

  return <Context.Provider value={{ ...state, updateQuery }}>{children}</Context.Provider>
}

export const useKnowledgeHubContext = () => {
  const context = useContext(Context)
  if (!context) throw new Error('useKnowledgeHubContext must be used within a KnowledgeHubProvider')
  return context
}

const toArrayString = (s: string) =>
  typeof s === 'string' && s.length > 0 ? s?.split(';')?.map(i => i?.trim()) || [] : []

export interface IWebflowCollection {
  ['Collection ID']: string
  ['Created On']: string
  ['Item ID']: string
  Name: string
  ['Published On']: string
  Slug: string
  ['Updated On']: string
}

export interface IKnowledgeHubCategory extends IWebflowCollection {
  Topics: string[]
  Icon: string
  Description: string
  ['Ordinal number']: string
  topicsObj: IKnowledgeHubTopic[]
}

@Entity()
export class KnowledgeHubCategory implements IKnowledgeHubCategory {
  ['Collection ID']: string;
  ['Created On']: string;
  ['Item ID']: string
  Name: string = '';
  ['Published On']: string
  Slug: string = '';
  ['Updated On']: string
  @Transform({
    fn: (model: { Topics: string }) => toArrayString(model.Topics),
  })
  Topics: string[] = []
  Icon: string = ''
  Description: string = '';
  ['Ordinal number']: string

  topicsObj: IKnowledgeHubTopic[] = []

  constructor(model: any) {}
}

export interface IKnowledgeHubTopic extends IWebflowCollection {
  Category: string
  CategoryName: string
  Content: string
  Desc: string
  IsRecommend: boolean
  ['Parent Topic']: string
  ['Popular Number']: string
  RelatedTopics: string[]
  SubTopics: string[]

  relatedTopicsObj: IKnowledgeHubTopic[]
  subTopicsObj: IKnowledgeHubTopic[]
  parentTopicObj: IKnowledgeHubTopic | null
}

@Entity()
export class KnowledgeHubTopic implements IKnowledgeHubTopic {
  ['Collection ID']: string;
  ['Created On']: string;
  ['Item ID']: string
  Name: string = '';
  ['Published On']: string
  Slug: string = '';
  ['Updated On']: string
  Category: string = ''
  CategoryName: string = ''
  Content: string = ''
  Desc: string = ''
  @Transform({
    fn: (model: { ['Is Recommend']: string }) => model?.['Is Recommend'] === 'true',
  })
  IsRecommend: boolean = false;
  ['Parent Topic']: string;
  ['Popular Number']: string

  relatedTopicsObj = []
  subTopicsObj = []
  parentTopicObj = null

  @Transform({
    fn: (model: { ['Related Topics']: string }) => toArrayString(model?.['Related Topics']),
  })
  RelatedTopics: string[] = []

  @Transform({
    fn: (model: { ['Sub Topics']: string }) => toArrayString(model?.['Sub Topics']),
  })
  SubTopics: string[] = []

  constructor(model: any) {}
}

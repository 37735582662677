import { makeStyles, Theme, Box, Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import theme from '../../../theme'
import FCTCheckbox from '../../Checkbox/FCTCheckbox'
import Flex from '../../Flex/Flex'
import FCTTextField from '../../TextField/FCTTextField'
import SubmitButton from './SubmitButton'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import Hint from './Hint'
import getEmailDomain from '../../../utils/email/getEmailDomain'

const VALID_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '3px',
    height: '50px',
    fontSize: '14px',
    border: 'none',
    outline: 'none',
    width: '100%',
    cursor: 'pointer',
    marginTop: '30px',
    '&:disabled': {
      opacity: 0.8,
    },
  },
  secondaryBtn: {
    backgroundColor: `#FFF`,
    color: '#000',
    marginTop: '12px',
  },
  formElement: {
    marginTop: '30px',
  },
  input: {
    width: '100%',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: `${theme.palette.grey[800]}`,
  },
  error: {
    marginTop: 16,
    fontSize: '14px',
    lineHeight: '22px',
    color: 'red',
  },
  pointer: {
    cursor: 'pointer',
  },
  emailSuffix: {
    maxWidth: '40%',
    width: '40%',
    overflow: 'auto',
    padding: '0 9px',
    marginRight: '9px',
    height: '100%',
    borderLeft: '1px solid #CBD5E1',
    '&::-webkit-scrollbar': {
      height: 0,
    },
  },
}))

export interface IEnterEmailFormData {
  anotherEmail: string
  email: string
  checkAnotherEmail: boolean
}

interface IEnterEmailFormProps {
  signUpAsFintech: boolean
  companyURL: string
  handleSubmit(data: IEnterEmailFormData): void
  loadingSubmit?: boolean
  onBack?(): void
  defaultValues?: IEnterEmailFormData
}

export const DEFAULT_EMAIL_FORM = { anotherEmail: '', email: '', checkAnotherEmail: false }

export const isEmail = (email: string, companyUrl?: string) => {
  if (!email) return true
  const domain = getEmailDomain(companyUrl || '')
  const matches = `${email}${!domain ? '' : `@${domain}`}`.match(VALID_EMAIL_REGEX)
  return !!matches
}

const EnterEmailForm = ({
  signUpAsFintech,
  companyURL,
  handleSubmit,
  loadingSubmit,
  onBack,
  defaultValues,
}: IEnterEmailFormProps) => {
  const classes = useStyles()

  const schema = (companyUrl?: string) => {
    return yup.object().shape({
      checkAnotherEmail: yup.boolean(),
      email: yup.string().when('checkAnotherEmail', (anotherEmail: boolean, schema: any) => {
        return !anotherEmail
          ? schema
              .required('Enter a valid email!')
              .test('Email format', 'Invalid email address format', (email: string = '') =>
                isEmail(email, companyUrl)
              )
          : schema
      }),
      anotherEmail: yup.string().when('checkAnotherEmail', (anotherEmail: boolean, schema: any) => {
        return anotherEmail
          ? schema
              .required('Enter a valid email!')
              .test('Email format', 'Email incorrect format', (email: string = '') =>
                isEmail(email)
              )
          : schema
      }),
    })
  }

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
    trigger,
  } = useForm<IEnterEmailFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || {
      ...DEFAULT_EMAIL_FORM,
    },
    resolver: yupResolver(schema(companyURL || '')),
  })
  const checkAnotherEmail =
    typeof control.getValues('checkAnotherEmail') === 'boolean'
      ? control.getValues('checkAnotherEmail')
      : defaultValues?.checkAnotherEmail

  const handleBlur = (key: keyof IEnterEmailFormData) => {
    const value = control.getValues(key)
    if (typeof value === 'string' && value.length > 0) {
      control.setValue(key, value.trim() || '')
    }

    trigger(key)
  }

  return (
    <form onSubmit={handleSubmitForm(data => handleSubmit(data))}>
      <Box className={classes.formElement}>
        <Box display={checkAnotherEmail ? 'none' : 'block'}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={props => (
              <FCTTextField
                {...props}
                disabled={checkAnotherEmail}
                className={classes.input}
                error={!!errors.email}
                helperText={errors?.email?.message || ''}
                FormHelperTextProps={
                  !!errors?.email?.message
                    ? {
                        role: 'alert',
                      }
                    : undefined
                }
                label="Business email"
                placeholder={!companyURL ? 'E.g: johnapp@bostonconsulting.com' : 'E.g: johnapp'}
                fullWidth={true}
                onBlur={() => handleBlur('email')}
                InputProps={{
                  endAdornment: !companyURL ? null : (
                    <Box className={classes.emailSuffix}>
                      <Typography
                        className={classes.text}
                        style={{ whiteSpace: 'nowrap', color: theme.palette.grey[300] }}
                      >
                        @{getEmailDomain(companyURL || '')}
                      </Typography>
                    </Box>
                  ),
                  id: '#email',
                }}
                InputLabelProps={{
                  htmlFor: '#email',
                  shrink: true,
                }}
              />
            )}
          />
        </Box>
        <Box display={!checkAnotherEmail ? 'none' : 'block'}>
          <Controller
            name="anotherEmail"
            control={control}
            defaultValue=""
            render={props => (
              <FCTTextField
                {...props}
                className={classes.input}
                error={!!errors.anotherEmail}
                helperText={errors?.anotherEmail?.message || ''}
                FormHelperTextProps={
                  errors?.anotherEmail?.message
                    ? {
                        role: 'alert',
                      }
                    : undefined
                }
                label={'Another email'}
                placeholder="E.g: johnapp@gmail.com"
                fullWidth={true}
                onBlur={() => handleBlur('anotherEmail')}
                InputProps={{
                  id: '#anotherEmail',
                }}
                InputLabelProps={{
                  htmlFor: '#anotherEmail',
                  shrink: true,
                }}
              />
            )}
          />
        </Box>
        <Hint
          boxProps={{
            mt:
              (!checkAnotherEmail && !!errors.email) || (checkAnotherEmail && !!errors.anotherEmail)
                ? '30px'
                : '8px',
          }}
          text={
            checkAnotherEmail
              ? 'Our team will review your information and respond to your request.'
              : 'Business Email has to match for FinTech Control Tower to reconcile access.'
          }
        />
      </Box>

      {signUpAsFintech && (
        <Controller
          name="checkAnotherEmail"
          control={control}
          defaultValue={false}
          render={props => (
            <Flex
              className={classes.pointer}
              ml="-9px"
              mt="16px"
              alignItems="center"
              onClick={() => {
                props.onChange(!props.value)
                trigger(['checkAnotherEmail']) // force render
              }}
            >
              <FCTCheckbox inputProps={{ role: 'checkbox' }} checked={props.value} />
              <Typography className={classes.text}>I want to use another email address</Typography>
            </Flex>
          )}
        />
      )}

      <SubmitButton
        disabled={!isValid}
        loading={loadingSubmit}
        text={signUpAsFintech ? 'Continue' : 'Submit'}
      />

      {onBack && (
        <button
          className={clsx(classes.button, classes.secondaryBtn)}
          onClick={() => onBack()}
          type="button"
          disabled={loadingSubmit}
        >
          Back
        </button>
      )}
    </form>
  )
}

export default EnterEmailForm

import { makeStyles } from '@material-ui/core'
import React from 'react'
import { ENotiTypes } from '../../store/operations/queries/GetListNotifications'

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  content: {
    width: condition => (condition ? '100%' : 'unset'),
    height: condition => (condition ? '50%' : 'unset'),
    maxWidth: 266,
    textAlign: 'center',
    position: condition => (condition ? 'relative' : 'unset'),
  },
  wrapperIcon: {
    borderRadius: '50%',
    backgroundColor: '#EEF2F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: condition => (condition ? '50%' : ''),
    transform: condition => (condition ? 'translateX(-50%)' : ''),
    position: condition => (condition ? 'absolute' : 'unset'),
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 0,
    fontFamily: 'Inter',
    color: '#1E293B',
    textAlign: 'center',
    position: condition => (condition ? 'absolute' : 'unset'),
  },
  contentText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'center',
    color: '#6C809D',
    fontFamily: 'Inter',
    position: condition => (condition ? 'absolute' : 'unset'),
    marginTop: condition => (condition ? '110px' : ''),
    width: condition => (condition ? '100%' : ''),
  },
}))

export const EmptyWatchList: React.FC<EmptyWLProps> = ({
  titleStyle = { lineHeight: '24px' },
  title,
  content,
  contentStyle = { marginTop: 12 },
  contentStyle_WU = { marginTop: 110 },
  wrapIconStyle = { width: 110, height: 110 },
  children,
  icon,
  notiType,
}) => {
  const condition = notiType === ENotiTypes.WatchlistUpdates || notiType === ENotiTypes.Requests
  const classes = useStyles(condition)
  return (
    <div className={classes.wrapper} data-testid="emptyWL">
      <div className={classes.content}>
        <div className={classes.wrapperIcon} style={wrapIconStyle}>
          {icon}
        </div>
        {title && (
          <h5 className={classes.title} style={titleStyle}>
            {title}
          </h5>
        )}
        {content && (
          <p className={classes.contentText} style={condition ? contentStyle_WU : contentStyle}>
            {content}
          </p>
        )}
        {children}
      </div>
    </div>
  )
}

interface EmptyWLProps extends React.SVGProps<SVGSVGElement> {
  icon: React.ReactNode
  children?: React.ReactNode
  title: string
  content?: string | React.ReactNode
  titleStyle?: object
  contentStyle?: object
  wrapIconStyle?: object
  contentStyle_WU?: object
  notiType?: ENotiTypes
}

import { makeStyles, Typography } from '@material-ui/core'
import { sumBy } from 'lodash'
import { EChartPosition } from '../containers/DashboardChartContainer'
import { formatAbbreviation, formatPercent, numberWithCommas } from '../utils/convert/number'
import { GetCountByGeoChartData_getCountByGeoChartData_items } from '../__generated__/GetCountByGeoChartData'
import { GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_groupInfo } from '../__generated__/GetEquityFundingByFundingAmount'
import StatisticsBox from './StatisticsBox/StatisticsBox'
import FCTTooltip from './Tooltip/Tooltip'
import { ChartFootNote, footNoteNumbers } from '../types/FootNote'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '20px',
    justifyContent: 'space-between',
  },
  blockContainer: {
    display: 'block',
    margin: 'auto',
  },
  colorItem: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginRight: 0,
    },
  },
  box: {
    height: '16px',
    width: '16px',
    marginRight: '10px',
    borderRadius: 2,
  },
  flexContainer: {
    display: 'flex',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    border: '1px solid #E2E8F0',
    borderRadius: '3px',
    margin: 0,
    flex: 1,
    '& li': {
      padding: '8px 20px',
    },
  },
  item: {
    listStyle: 'none',
    fontWeight: 600,
    fontSize: '12px',
    color: `${theme.palette.grey[800]}`,
    lineHeight: '18px',
    borderRight: '1px solid #E2E8F0',
    '&:last-child': {
      borderRight: 'none',
    },
    '& p': {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      '&:last-child': {
        fontSize: '14px',
        color: `${theme.palette.primary.main}`,
        lineHeight: '22px',
      },
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  tooltipItem: {
    fontSize: 12,
  },
  badge: {
    color: theme.palette.grey[500],
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '14px',
    marginTop: '-3px',
    paddingRight: '6px',
  },
}))

export enum TooltipDesc {
  Count = 'Number of founded companies to date, excludes companies with no HQ location.',
  YoYCount = 'Number of founded companies compared to the same period in the previous year.',
  TotalFunding = 'Total equity funding raised by founded companies to date.',
  YoYFunding = 'Total equity funding raised by founded companies in the last 12 months compared to the same period in the previous year.',

  SeriesE = 'Rounds beyond Series E.',
  Unattributed = 'Deals raised that were not attributed to a specific round type.',
  Corporate = 'Corporate minority and corporate majority rounds.',
  PE = 'Private equity rounds.',
}

const legendsWithTooltip = ['Series E+', 'Unattributed', 'Corporate', 'PE']

interface Props {
  data: GetCountByGeoChartData_getCountByGeoChartData_items[] | null
  legendItems: any
  dataStatisticsBox?: Array<{
    content: string
    title: string
  }>
  type?: 'small' | ''
  positionChart?: EChartPosition
  isTooltip?: boolean
  groupInfo?:
    | GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_groupInfo[]
    | null
  isDownLoadPptx?: boolean
  typeChart?: ChartFootNote
}

const calculateYoY = (lastInfo: number, thisInfo: number) => {
  if (lastInfo === 0) {
    return 'N/A'
  }
  return `${(((thisInfo - lastInfo) * 100) / lastInfo).toFixed(2)}`
}

const Legend = ({
  data,
  legendItems,
  dataStatisticsBox,
  type,
  positionChart,
  groupInfo,
  isTooltip,
  isDownLoadPptx = false,
  typeChart = ChartFootNote.Default,
}: Props) => {
  const classes = useStyles()

  return (
    <div
      className={
        positionChart === EChartPosition.Dashboard ? classes.blockContainer : classes.container
      }
    >
      <div
        className={classes.flexContainer}
        style={{ flexWrap: type === 'small' ? 'nowrap' : 'wrap' }}
      >
        {legendItems &&
          legendItems.map((category: any, index: number) => (
            <div key={index} className={classes.flexContainer} data-testid="item-legend">
              {isDownLoadPptx && footNoteNumbers.get(typeChart)?.index === index && (
                <span className={classes.badge}>{footNoteNumbers.get(typeChart)?.legend}</span>
              )}
              <div
                className={classes.colorItem}
                style={{ margin: type === 'small' ? '0px 15px 5px 0px' : '0px 24px 10px 0px' }}
                key={category.name}
              >
                <div
                  className={classes.box}
                  style={{
                    backgroundColor: category.color,
                    marginRight: type === 'small' ? 5 : 10,
                  }}
                />
                <FCTTooltip
                  title={
                    <Typography className={classes.tooltipItem}>
                      {category.name === 'Series E+'
                        ? TooltipDesc.SeriesE
                        : category.name === 'Unattributed'
                        ? TooltipDesc.Unattributed
                        : category.name === 'Corporate'
                        ? TooltipDesc.Corporate
                        : category.name === 'PE'
                        ? TooltipDesc.PE
                        : groupInfo?.length
                        ? groupInfo?.map(item =>
                            item.name === category.name ? item.description : ''
                          )
                        : ''}
                    </Typography>
                  }
                  placement="bottom"
                  arrow
                  disableHoverListener={!legendsWithTooltip.includes(category.name) || !isTooltip}
                >
                  <div className={classes.noWrap} style={{ fontSize: type === 'small' ? 12 : 14 }}>
                    {category.name}
                  </div>
                </FCTTooltip>
              </div>
            </div>
          ))}
      </div>
      <div>
        {data && (
          <ul className={classes.list}>
            <FCTTooltip
              title={<Typography className={classes.tooltipItem}>{TooltipDesc.Count}</Typography>}
              placement="top"
              arrow
            >
              <li className={classes.item}>
                <p>
                  Count
                  {typeChart === ChartFootNote.CountByGeography && isDownLoadPptx && (
                    <span className={classes.badge}>1</span>
                  )}
                </p>
                <p className={classes.noWrap} data-testid="geography-count">
                  {numberWithCommas(sumBy(data, 'countCompany')) || 0}
                </p>
              </li>
            </FCTTooltip>
            <FCTTooltip
              title={
                <Typography className={classes.tooltipItem}>{TooltipDesc.YoYCount}</Typography>
              }
              placement="top"
              arrow
            >
              <li className={classes.item}>
                <p>YoY Count</p>
                <p className={classes.noWrap} data-testid="geography-yoyCount">
                  {formatPercent(
                    calculateYoY(
                      sumBy(data, 'countCompanyLastYear'),
                      sumBy(data, 'countCompanyThisYear')
                    )
                  )}
                </p>
              </li>
            </FCTTooltip>
            <FCTTooltip
              title={
                <Typography className={classes.tooltipItem}>{TooltipDesc.TotalFunding}</Typography>
              }
              placement="top"
              arrow
            >
              <li className={classes.item}>
                <p>Equity Funding</p>
                <p className={classes.noWrap} data-testid="geography-equityFunding">
                  {formatAbbreviation(sumBy(data, 'totalFundings')) || 0}
                </p>
              </li>
            </FCTTooltip>
            <FCTTooltip
              title={
                <Typography className={classes.tooltipItem}>{TooltipDesc.YoYFunding}</Typography>
              }
              placement="top"
              arrow
            >
              <li className={classes.item}>
                <p>YoY Funding</p>
                <p className={classes.noWrap} data-testid="geography-yoyFunding">
                  {formatPercent(
                    calculateYoY(
                      sumBy(data, 'totalFundingsLastYear'),
                      sumBy(data, 'totalFundingsThisYear')
                    )
                  )}
                </p>
              </li>
            </FCTTooltip>
          </ul>
        )}
        {!!dataStatisticsBox?.length && (
          <StatisticsBox items={dataStatisticsBox || []}></StatisticsBox>
        )}
      </div>
    </div>
  )
}

export default Legend

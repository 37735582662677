import { Box, Chip, ChipProps } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import { DEFAULT_DATA_MAX } from '../../containers/EDCompanyDealFilterContainer'
import InvestorListContainer from '../../containers/InvestorListContainer'
import { ChipItem } from '../../store/features/analysis/AnalysisSlice'
import { IOpenOptionFilter } from '../../store/features/filter/FilterSlice'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import { GetInvestorsFilterData_getInvestorsFilterData } from '../../__generated__/GetInvestorsFilterData'
import FCTDropdownListItem from '../DropdownListItem/FCTDropdownListItem'
import { AccordionItem } from '../Filters/AccordionItem'
import { ListCheckBoxFilter } from '../Filters/ListCheckBoxFilter'

import FCTSlider, { IRealValue } from '../Slider/FCTSlider'

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: theme.palette.grey['800'],
    fontSize: 12,
    fontWeight: 600,
  },
  flex: {
    flex: 1,
  },
  container: {
    borderBottom: '1px solid #E7ECF3',
  },
  smallMarginTop: {
    marginTop: 10,
  },
}))

export const StyledChip = withStyles({
  root: {
    zIndex: 1,
    borderRadius: '3px',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    position: 'relative',
  },
  deleteIcon: {
    width: 12,
    height: 12,
    position: 'absolute',
    right: '0px',
    padding: '4px',
    background: 'rgba(255,255,255,1)',
    overflow: 'visible',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
})(Chip)

export const StyledChipItem = (props: ChipProps & { onDelete(c: ChipItem): void }) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Box
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ maxWidth: '100%' }}
    >
      <StyledChip
        {...props}
        deleteIcon={isHover ? props.deleteIcon : undefined}
        onDelete={isHover ? props.onDelete : undefined}
      />
    </Box>
  )
}

function InvestorItem(props: Props) {
  const classes = useStyles()
  const {
    filter,
    setFilter,
    handleChange,
    getFilterData,
    openOption,
    setOpenOption,
    customStyle,
  } = props

  return (
    <>
      <div className={classes.container}>
        {/* TODO: handle ALL Filters (except for Number of Investors) */}
        <AccordionItem
          title="Investor"
          open={openOption?.investor}
          setOpen={value => setOpenOption && setOpenOption(value, 'investor')}
          customStyle={customStyle}
        >
          <InvestorChildItem filter={filter} setFilter={setFilter} getFilterData={getFilterData} />
        </AccordionItem>
      </div>
    </>
  )
}

export default InvestorItem

interface Props {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: Partial<ICompanyDealFilter | IAnalysisFilter>): void
  handleChange(e: React.SyntheticEvent): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  openOption?: IOpenOptionFilter
  setOpenOption?: (value: boolean, tag: string) => void
  customStyle?: { title?: React.CSSProperties }
}

interface IInvestorChildItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: Partial<ICompanyDealFilter | IAnalysisFilter>): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  isOnAdvancedSearchPage?: boolean
}

export const InvestorChildItem = (props: IInvestorChildItemProps) => {
  const { filter, setFilter, getFilterData, isOnAdvancedSearchPage = false } = props

  const investorTypesData = getFilterData?.investorTypes?.map(e => ({
    id: e,
    name: e,
  }))

  const handleInvestorChange = useCallback(
    (investors: GetInvestorsFilterData_getInvestorsFilterData[], isLead?: boolean) => {
      if (isLead) setFilter({ leadInvestors: investors.map(i => i.id) })
      else setFilter({ investors: investors.map(i => i.id) })
    },
    []
  )

  return (
    <>
      <FCTDropdownListItem
        isOpen={true}
        title="Investor"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <InvestorListContainer
          hiddenSelectedOption
          placeHolder="Search for investor name..."
          value={filter.investors}
          onChange={handleInvestorChange}
          onDelete={investor =>
            setFilter({ investors: filter.investors.filter(id => id !== investor.id) })
          }
          isAcquirer={false}
        />
      </FCTDropdownListItem>

      <FCTDropdownListItem
        isOpen={!isOnAdvancedSearchPage}
        title="Investor Type"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <ListCheckBoxFilter
          nameField="investorsTypes"
          data={investorTypesData || []}
          filterIds={filter.investorsTypes || []}
          setFilterData={setFilter}
        />
      </FCTDropdownListItem>

      <FCTDropdownListItem
        isOpen={true}
        title="Lead Investor"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <InvestorListContainer
          hiddenSelectedOption
          placeHolder="Search for investor name..."
          value={filter.leadInvestors}
          onChange={v => handleInvestorChange(v, true)}
          onDelete={investor =>
            setFilter({ leadInvestors: filter.leadInvestors.filter(id => id !== investor.id) })
          }
          isAcquirer={false}
        />
      </FCTDropdownListItem>

      <FCTDropdownListItem
        isOpen={!isOnAdvancedSearchPage}
        title="Lead Investor Type"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <ListCheckBoxFilter
          data={investorTypesData || []}
          nameField="leadInvestorTypes"
          filterIds={filter.leadInvestorTypes || []}
          setFilterData={setFilter}
        />
      </FCTDropdownListItem>

      <FCTDropdownListItem
        isOpen={true}
        title="Number of Investors"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <FCTSlider
          defaultRealValue={filter.numberOfInvestorsRanges}
          min={0}
          max={getFilterData?.maxNumOfInvestors || DEFAULT_DATA_MAX}
          step={getFilterData?.maxNumOfInvestors || DEFAULT_DATA_MAX}
          onChangeRealValue={(v: IRealValue) =>
            setFilter({
              numberOfInvestorsRanges: { start: String(v.start), end: String(v.end) },
            })
          }
        />
      </FCTDropdownListItem>
    </>
  )
}

import styled from 'styled-components'
import { ICompanyOption } from '../../features/search-companies-name/SearchCompanies'
import { numberWithCommas } from '../../utils/convert/number'

export enum EMessageType {
  Add,
  AddFilter,
  RemoveFilter,
  Copy,
  Delete,
  CreateNew,
  Create,
  WLDelete,
  WLDuplicate,
  WLUpdateFilter,
  WLPermanentlyDelete,
}

interface IWatchListMessageProps {
  type: EMessageType
  selectedName?: string
  selectedList?: ICompanyOption[] | string[]
  watchListName?: string
  hasRemoveWL?: boolean
}

const FCTStrong = styled.strong`
  max-width: ${props => props.theme.maxWidth};
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
`

export const WatchListMessage = (props: IWatchListMessageProps) => {
  const { type, selectedName, selectedList, watchListName, hasRemoveWL } = props

  const watchListMessage = (type: EMessageType) => {
    switch (type) {
      case EMessageType.Add:
        return (
          <>
            Add{' '}
            <strong>
              {selectedList?.length! > 1
                ? `${numberWithCommas(selectedList?.length!)} companies`
                : selectedName || `${numberWithCommas(selectedList?.length!)} company`}
            </strong>{' '}
            to <strong>{watchListName}.</strong>
          </>
        )

      case EMessageType.AddFilter:
        return (
          <>
            Adding new company IDs to <strong>{watchListName}</strong> will disable the saved filter
            conditions. Do you want to continue?
          </>
        )

      case EMessageType.RemoveFilter:
        return (
          <>
            Deleting companies from <strong>{watchListName}</strong> will disable the saved filter
            conditions. Do you want to continue?
          </>
        )

      case EMessageType.Copy:
        return (
          <>
            Copy{' '}
            <strong>
              {selectedList?.length! > 1
                ? `${numberWithCommas(selectedList?.length!)} companies`
                : selectedName}
            </strong>{' '}
            to:
          </>
        )

      case EMessageType.Delete:
        if (hasRemoveWL) {
          if (selectedList?.length === 1) {
            return (
              <>
                Deleting the last company from{' '}
                <FCTStrong theme={{ maxWidth: '50%' }}>{watchListName}</FCTStrong> will also delete
                the watchlist. Do you want to continue?
              </>
            )
          } else {
            return (
              <>
                Deleting all companies from{' '}
                <FCTStrong theme={{ maxWidth: '50%' }}>{watchListName}</FCTStrong> will also delete
                the watchlist. Do you want to continue?
              </>
            )
          }
        } else {
          return (
            <>
              Delete{' '}
              <strong>
                {selectedList?.length! > 1
                  ? `${numberWithCommas(selectedList?.length!)} companies`
                  : selectedName}
              </strong>{' '}
              from <FCTStrong theme={{ maxWidth: '50%' }}>{watchListName}.</FCTStrong>
            </>
          )
        }

      case EMessageType.CreateNew:
        return <>Add any company to start. You can remove them later.</>

      case EMessageType.Create:
        return (
          <>
            Create a new watchlist for{' '}
            <strong>
              {selectedList?.length! > 1
                ? `${numberWithCommas(selectedList?.length!)} companies`
                : selectedName}
              .
            </strong>
          </>
        )

      case EMessageType.WLDelete:
        return (
          <>
            Delete <strong>{watchListName}.</strong>
          </>
        )

      case EMessageType.WLDuplicate:
        return (
          <>
            You are duplicate <strong>{watchListName}.</strong>
          </>
        )

      case EMessageType.WLUpdateFilter:
        return (
          <>
            Update <FCTStrong theme={{ maxWidth: '40%' }}>{watchListName}</FCTStrong> with the new
            Filter conditions.
          </>
        )
      case EMessageType.WLPermanentlyDelete:
        return (
          <>
            <FCTStrong theme={{ maxWidth: '40%' }}>{watchListName}</FCTStrong> will be permanently
            deleted. Continue?
          </>
        )

      default:
        break
    }
  }

  return <>{watchListMessage(type)}</>
}

import { RouterName } from './../router/RouterSlice'
import { onOpenPage } from '../router/RouterAction'
import { Action } from 'redux-actions'
import { ofType } from 'redux-observable'
import { RootState } from '../../store'
import { concat, Observable, of } from 'rxjs'
import { switchMap, filter } from 'rxjs/operators'
import { resetSearchOption, updateSearchOption } from './SearchSlice'
import { onSearchActionEmpty } from './SearchAction'
import { ISearchInputOption } from 'components/Search/interfaces'

const updateSearchOptionEpic = (action$: Observable<Action<any>>, store: RootState) =>
  action$.pipe(
    ofType(updateSearchOption.type),
    filter((action: Action<ISearchInputOption[]>) => !action.payload.length),
    switchMap((action: Action<ISearchInputOption[]>) => {
      return concat(of(onSearchActionEmpty()))
    })
  )

const resetSearchOptionEpic = (action$: Observable<Action<any>>, store: RootState) =>
  action$.pipe(
    ofType(resetSearchOption.type),
    switchMap((action: Action<ISearchInputOption[]>) => {
      return concat(of(onSearchActionEmpty()))
    })
  )

const resetSearchOptionSomePageOpenEpic = (action$: Observable<Action<any>>, store: RootState) =>
  action$.pipe(
    ofType(onOpenPage.type),
    filter((action: Action<RouterName>) =>
      [RouterName.DASHBOARD, RouterName.INVESTOR_PROFILE].includes(action.payload)
    ),
    switchMap((action: Action<RouterName>) => {
      return concat(of(resetSearchOption(null)))
    })
  )

export default [updateSearchOptionEpic, resetSearchOptionEpic, resetSearchOptionSomePageOpenEpic]

import { makeStyles } from '@material-ui/styles'
import { ETypeDialog, FCTDialog } from '../../components/Dialog/FCTDialog'
import { EMessageType, WatchListMessage } from '../../templates/WatchListHandling/WatchListMessage'
import theme from '../../theme'

const useStyles = makeStyles(() => ({
  dialogConfirmContent: {
    padding: 20,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 'normal',
  },
}))

interface IConfirmDialogProps {
  open: boolean
  setOpen: (value: boolean) => void
  handleAgree: () => void
  watchListName: string | null
}

const ConfirmDisableFilterDialog = ({
  open,
  setOpen,
  handleAgree,
  watchListName,
}: IConfirmDialogProps) => {
  const classes = useStyles()

  return (
    <FCTDialog
      maxWidth={'sm'}
      open={open}
      setOpen={setOpen}
      title={'Add to Watchlist'}
      type={ETypeDialog.Default}
      titleBtnAgree="Confirm"
      handleAgree={handleAgree}
      minHeight={30}
    >
      <div className={classes.dialogConfirmContent}>
        <WatchListMessage type={EMessageType.AddFilter} watchListName={watchListName!} />
      </div>
    </FCTDialog>
  )
}

export default ConfirmDisableFilterDialog

import { AccordionDetails, makeStyles, Typography } from '@material-ui/core'
import MuiAccordionSummary, { AccordionSummaryProps } from '@material-ui/core//AccordionSummary'
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import jobListDot from 'assets/images/job-list-dot.svg'
import htmlparse from 'html-react-parser'
import { useState } from 'react'
import styled from 'styled-components'
import theme from 'theme'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  margin: '0px !important',
  border: 'none !important',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  transition: 'all 10s ease 2s',
  '&.MuiAccordionSummary-root': {
    backgroundColor: `${theme.palette.grey[100]}`,
    padding: '17px 20px 17px 24px !important',
    borderRadius: 5,
    transition: 'background-color 500ms ease',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[200]}`,
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
    '& p': {
      color: `${theme.palette.grey[800]}`,
    },
  },
  '& .MuiIconButton-edgeEnd': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  marginTop: 4,
  minHeight: 'unset !important',
}))

const useStyles = makeStyles(theme => ({
  content: {
    padding: '0px 4px',
    maxHeight: 440,
    '& strong': {
      color: `${theme.palette.primary.light}`,
      fontWeight: 600,
    },
    '& li': {
      marginBottom: 12,
      paddingLeft: 20,
      lineHeight: '22px',
      backgroundPosition: '0% 8px',
      backgroundImage: `url(${jobListDot})`,
      listStyleType: 'none',
      backgroundRepeat: 'no-repeat',
    },
    '& p': {
      color: `${theme.palette.grey[600]}`,
    },
    '& .MuiAccordionDetails-root': {
      '& p': {
        lineHeight: '22px',
      },
    },
  },
  title: {
    transition: 'all 500ms ease',
    fontWeight: 600,
    lineHeight: '22px',
  },
}))

type Props = {
  data: {
    title: string
    content: string
  }[]
}

const AccordionFaqs = ({ data }: Props) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      {data.map((items, idx) => (
        <Accordion
          expanded={expanded === items.title}
          onChange={handleChange(items.title)}
          key={idx}
          className={classes.content}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                color: expanded === items.title ? `${theme.palette.primary.light}` : '',
              }}
              className={classes.title}
            >
              {items.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.content}>{htmlparse(items.content)}</div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

export default AccordionFaqs

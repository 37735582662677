import { makeStyles } from '@material-ui/core'
import React, { forwardRef } from 'react'
import ReactTooltip, { Type, TooltipProps as ReactTooltipProps } from 'react-tooltip'
import clsx from 'clsx'
import './style.css'

const useStyles = makeStyles(theme => ({
  tooltip: {
    opacity: '1!important',
    fontWeight: 'normal',
    fontSize: 12,
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.12)',
  },
  customeTheme: {
    color: ' #000000 !important',
    backgroundColor: '#F9E7E5!important',
    '&.place-top': {
      '&:after': {
        borderTopColor: '#F9E7E5!important',
      },
    },
  },
}))

export const FCTTooltipMove = forwardRef((props: FCTTooltipMoveProps, ref: any) => {
  const classes = useStyles()
  const {
    children,
    text,
    isMove = true,
    disable = false,
    isHover = false,
    type = 'dark',
    delayTime = 1000,
  } = props
  const id = Math.random()
  return (
    <>
      <ReactTooltip
        {...props}
        id={String(id)}
        type={type}
        className={
          isHover ? 'extraClass' : clsx(classes.tooltip, type === 'error' && classes.customeTheme)
        }
        effect={isHover ? 'solid' : isMove ? 'float' : 'solid'}
        disable={disable}
        delayHide={isHover ? delayTime : 0}
      >
        {text}
      </ReactTooltip>
      <div data-tip data-for={String(id)} ref={ref}>
        {children}
      </div>
    </>
  )
})

export interface FCTTooltipMoveProps extends ReactTooltipProps {
  children: React.ReactNode
  text: string | React.ReactNode
  isMove?: boolean
  disable?: boolean
  isHover?: boolean
  type?: Type
  delayTime?: number
}

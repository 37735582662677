import { createSelector } from '@reduxjs/toolkit'
import { Selector } from 'react-redux'
import { RootState } from '../../store'
import { INotificationSlice } from './NotificationSlice'

const selectNotification: Selector<RootState, INotificationSlice> = (state: RootState) =>
  state.notificationReducer

const selectNumOfNotifications = createSelector(
  selectNotification,
  state => state.numOfNotifications
)

const selectNotificationStatus = createSelector(
  selectNotification,
  state => state.notificationStatus
)

export { selectNotification, selectNumOfNotifications, selectNotificationStatus }

import theme from '../theme'

export enum EFlattenType {
  Flatten,
  TakeLast,
}

export enum ETaxonomyAvatar {
  BusinessLine = 'B',
  ValueChain = 'V',
  ProductLine = 'P',
  OperatingModel = 'O',
}

export enum ETaxonomyCategory {
  FinTech = 'fintech',
  InsurTech = 'insurtech',
  RegTech = 'regtech',
}

export const TaxonomyFilterLabel = new Map<ETaxonomyCategory, string>([
  [ETaxonomyCategory.FinTech, 'FinTech'],
  [ETaxonomyCategory.InsurTech, 'InsurTech'],
  [ETaxonomyCategory.RegTech, 'RegTech'],
])

export const TaxonomyFilterAvatar = new Map<ETaxonomyAvatar, { content: string; color: string }>([
  [ETaxonomyAvatar.BusinessLine, { content: 'Business Line', color: theme.palette.primary.light }],
  [ETaxonomyAvatar.ValueChain, { content: 'Value Chain', color: theme.palette.grey[300] }],
  [ETaxonomyAvatar.ProductLine, { content: 'Product Line', color: '#D5DD57' }],
  [ETaxonomyAvatar.OperatingModel, { content: 'Operating Model', color: theme.palette.grey[500] }],
])

export interface ITaxonomyOptions {
  avatar: ETaxonomyAvatar
  category: ETaxonomyCategory | string
  isPrimary: number
  label: string
  value: number
}

// Usage: FlattenEcoSystem(false, EFlattenType.Flatten)(ecoSystemObj)
export const FlattenEcoSystem = (isPrimary: boolean, type: EFlattenType) => {
  let products: ITaxonomyOptions[] = []

  const flattenEcoSystem = (value: any) => {
    if (value) {
      if (value.children) {
        flattenEcoSystem(value.children[0])
        products = [
          ...products,
          {
            avatar: ETaxonomyAvatar.ProductLine,
            category: value.category,
            isPrimary: !isPrimary ? 0 : 1,
            label: value.name,
            value: value.id,
          },
        ]
      } else {
        products = [
          ...products,
          {
            avatar: ETaxonomyAvatar.ProductLine,
            category: value.category,
            isPrimary: !isPrimary ? 0 : 1,
            label: value.name,
            value: value.id,
          },
        ]
      }
    }
    return type === EFlattenType.TakeLast ? [products[0]] : products
  }

  // closure: return inner function
  return (value: any) => {
    return flattenEcoSystem(value)
  }
}

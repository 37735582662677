import { gql } from '@apollo/client'

export const GET_INVESTOR_BASIC_INFO = gql`
  query GetInvestorBasicInfo($id: String!) {
    getInvestorBasicInfo(id: $id) {
      investorId
      investorName
      investorType
      investorUrl
      investorDescription
      investorLongDescription
      investorLinkedin
      investorFacebook
      investorTwitter
      investorFoundedYear
      investorFtesRange
      investorFtesExact
      investorLogoUrl
      investorOperatingStatus
      investorHqCountry
      investorHqCity
      includedEntities {
        entityId
        entityName
        numberOfDeals
      }
      associatedCompanyId
      investorLogoBucketUrl
    }
  }
`

import * as React from 'react'
import { ReactComponent as chartcompare } from '../../assets/images/bubble/chartcompare.svg'
import { ReactComponent as addBuilding } from '../../assets/images/icons/add-building.svg'
import { ReactComponent as addInformation } from '../../assets/images/icons/add-information.svg'
import { ReactComponent as addUser } from '../../assets/images/icons/add-user.svg'
import { ReactComponent as add } from '../../assets/images/icons/add.svg'
import { ReactComponent as analytics } from '../../assets/images/icons/analytics.svg'
import { ReactComponent as arrowdown1 } from '../../assets/images/icons/arrowdown-1.svg'
import { ReactComponent as arrowdown } from '../../assets/images/icons/arrowdown.svg'
import { ReactComponent as arrowleft } from '../../assets/images/icons/arrowleft.svg'
import { ReactComponent as arrowright } from '../../assets/images/icons/arrowright.svg'
import { ReactComponent as arrowtop } from '../../assets/images/icons/arrowtop.svg'
import { ReactComponent as ascending } from '../../assets/images/icons/ascending.svg'
import { ReactComponent as asset } from '../../assets/images/icons/asset.svg'
import { ReactComponent as bankDeposit } from '../../assets/images/icons/bank-deposit.svg'
import { ReactComponent as bell } from '../../assets/images/icons/bell.svg'
import { ReactComponent as bubble } from '../../assets/images/icons/bubble.svg'
import { ReactComponent as business } from '../../assets/images/icons/business.svg'
import { ReactComponent as calendar } from '../../assets/images/icons/calendar.svg'
import { ReactComponent as camera } from '../../assets/images/icons/camera.svg'
import { ReactComponent as cancel } from '../../assets/images/icons/cancel.svg'
import { ReactComponent as chart } from '../../assets/images/icons/chart.svg'
import { ReactComponent as check } from '../../assets/images/icons/check.svg'
import { ReactComponent as checked } from '../../assets/images/icons/checked.svg'
import { ReactComponent as clock } from '../../assets/images/icons/clock.svg'
import { ReactComponent as closeFilter } from '../../assets/images/icons/close-filter.svg'
import { ReactComponent as close } from '../../assets/images/icons/close.svg'
import { ReactComponent as close1 } from '../../assets/images/icons/close1.svg'
import { ReactComponent as cloud } from '../../assets/images/icons/cloud.svg'
import { ReactComponent as collapse } from '../../assets/images/icons/collapse.svg'
import { ReactComponent as companyWatchlist } from '../../assets/images/icons/company-watchlist.svg'
import { ReactComponent as company } from '../../assets/images/icons/company.svg'
import { ReactComponent as compare } from '../../assets/images/icons/compare.svg'
import { ReactComponent as compare2 } from '../../assets/images/icons/compare2.svg'
import { ReactComponent as coordinate } from '../../assets/images/icons/coordinate.svg'
import { ReactComponent as copy } from '../../assets/images/icons/copy.svg'
import { ReactComponent as customers } from '../../assets/images/icons/customers.svg'
import { ReactComponent as dashboard } from '../../assets/images/icons/dashboard.svg'
import { ReactComponent as descending } from '../../assets/images/icons/descending.svg'
import { ReactComponent as down } from '../../assets/images/icons/down.svg'
import { ReactComponent as download } from '../../assets/images/icons/download.svg'
import { ReactComponent as edit } from '../../assets/images/icons/edit.svg'
import { ReactComponent as email } from '../../assets/images/icons/email-black.svg'
import { ReactComponent as emptyStatistics } from '../../assets/images/icons/empty-statistics.svg'
import { ReactComponent as emptyViews } from '../../assets/images/icons/empty-views.svg'
import { ReactComponent as explore } from '../../assets/images/icons/explore.svg'
import { ReactComponent as export2 } from '../../assets/images/icons/export-1.svg'
import { ReactComponent as export1 } from '../../assets/images/icons/export.svg'
import { ReactComponent as eyeClose } from '../../assets/images/icons/eye-close.svg'
import { ReactComponent as eyeopen } from '../../assets/images/icons/eye-open.svg'
import { ReactComponent as filter } from '../../assets/images/icons/filter.svg'
import { ReactComponent as fullscreen } from '../../assets/images/icons/fullscreen.svg'
import { ReactComponent as funding } from '../../assets/images/icons/funding.svg'
import { ReactComponent as globe } from '../../assets/images/icons/globe.svg'
import { ReactComponent as google } from '../../assets/images/icons/google.svg'
import { ReactComponent as group } from '../../assets/images/icons/group.svg'
import { ReactComponent as group1 } from '../../assets/images/icons/group1.svg'
import { ReactComponent as handshake } from '../../assets/images/icons/handshake.svg'
import { ReactComponent as hierachy } from '../../assets/images/icons/hierachy.svg'
import { ReactComponent as home } from '../../assets/images/icons/home.svg'
import { ReactComponent as house } from '../../assets/images/icons/house.svg'
import { ReactComponent as idCard } from '../../assets/images/icons/idCard.svg'
import { ReactComponent as image } from '../../assets/images/icons/image.svg'
import { ReactComponent as info } from '../../assets/images/icons/info.svg'
import { ReactComponent as layerGroup } from '../../assets/images/icons/layer-group.svg'
import { ReactComponent as link } from '../../assets/images/icons/link.svg'
import { ReactComponent as linkedIn } from '../../assets/images/icons/linkedIn.svg'
import { ReactComponent as list2 } from '../../assets/images/icons/list-2.svg'
import { ReactComponent as list } from '../../assets/images/icons/list.svg'
import { ReactComponent as location } from '../../assets/images/icons/location.svg'
import { ReactComponent as lock } from '../../assets/images/icons/lock.svg'
import { ReactComponent as mail } from '../../assets/images/icons/mail.svg'
import { ReactComponent as medal } from '../../assets/images/icons/medal.svg'
import { ReactComponent as minus1 } from '../../assets/images/icons/minus-1.svg'
import { ReactComponent as minus } from '../../assets/images/icons/minus.svg'
import { ReactComponent as minus2 } from '../../assets/images/icons/minus2.svg'
import { ReactComponent as money } from '../../assets/images/icons/money.svg'
import { ReactComponent as monitor } from '../../assets/images/icons/monitor.svg'
import { ReactComponent as navCollapse } from '../../assets/images/icons/nav-collapse.svg'
import { ReactComponent as navExpand } from '../../assets/images/icons/nav-expand.svg'
import { ReactComponent as network } from '../../assets/images/icons/network.svg'
import { ReactComponent as newLogo } from '../../assets/images/icons/new-logo.svg'
import { ReactComponent as news } from '../../assets/images/icons/news.svg'
import { ReactComponent as noInfo } from '../../assets/images/icons/noInfo.svg'
import { ReactComponent as noUseCase } from '../../assets/images/icons/noUseCase.svg'
import { ReactComponent as page } from '../../assets/images/icons/page.svg'
import { ReactComponent as partnership } from '../../assets/images/icons/partnership.svg'
import { ReactComponent as passwordChanged } from '../../assets/images/icons/password-changed.svg'
import { ReactComponent as payment } from '../../assets/images/icons/payment.svg'
import { ReactComponent as pdfFile } from '../../assets/images/icons/pdfFile.svg'
import { ReactComponent as pictureBlack } from '../../assets/images/icons/picture-black.svg'
import { ReactComponent as pieChart } from '../../assets/images/icons/pie-chart.svg'
import { ReactComponent as pinIcon } from '../../assets/images/icons/pin-icon.svg'
import { ReactComponent as play } from '../../assets/images/icons/play.svg'
import { ReactComponent as plusBorder } from '../../assets/images/icons/plus-border.svg'
import { ReactComponent as plus } from '../../assets/images/icons/plus.svg'
import { ReactComponent as product } from '../../assets/images/icons/product.svg'
import { ReactComponent as question } from '../../assets/images/icons/question.svg'
import { ReactComponent as realEstate } from '../../assets/images/icons/real-estate-pay-building.svg'
import { ReactComponent as referral } from '../../assets/images/icons/referral.svg'
import { ReactComponent as refreshAuth } from '../../assets/images/icons/refresh-auth.svg'
import { ReactComponent as refresh } from '../../assets/images/icons/refresh.svg'
import { ReactComponent as refresh2 } from '../../assets/images/icons/refresh2.svg'
import { ReactComponent as removeInformation } from '../../assets/images/icons/remove-information.svg'
import { ReactComponent as removeUser } from '../../assets/images/icons/removeUser.svg'
import { ReactComponent as report } from '../../assets/images/icons/report.svg'
import { ReactComponent as save } from '../../assets/images/icons/save.svg'
import { ReactComponent as searchPlus } from '../../assets/images/icons/search-plus.svg'
import { ReactComponent as search } from '../../assets/images/icons/search.svg'
import { ReactComponent as seeMore } from '../../assets/images/icons/see-more.svg'
import { ReactComponent as sendEmail } from '../../assets/images/icons/sendEmail.svg'
import { ReactComponent as setting } from '../../assets/images/icons/setting.svg'
import { ReactComponent as share } from '../../assets/images/icons/share.svg'
import { ReactComponent as sortTimeAsc } from '../../assets/images/icons/sort-time-asc.svg'
import { ReactComponent as sortTimeDesc } from '../../assets/images/icons/sort-time-desc.svg'
import { ReactComponent as stars } from '../../assets/images/icons/stars.svg'
import { ReactComponent as support } from '../../assets/images/icons/support.svg'
import { ReactComponent as target } from '../../assets/images/icons/target.svg'
import { ReactComponent as team } from '../../assets/images/icons/team.svg'
import { ReactComponent as trash } from '../../assets/images/icons/trash.svg'
import { ReactComponent as treeMap } from '../../assets/images/icons/tree-map.svg'
import { ReactComponent as trend } from '../../assets/images/icons/trend.svg'
import { ReactComponent as trophy } from '../../assets/images/icons/trophy.svg'
import { ReactComponent as up } from '../../assets/images/icons/up.svg'
import { ReactComponent as update } from '../../assets/images/icons/update.svg'
import { ReactComponent as uploadThumbnail } from '../../assets/images/icons/upload-thumbnail.svg'
import { ReactComponent as upload } from '../../assets/images/icons/upload.svg'
import { ReactComponent as upload2 } from '../../assets/images/icons/upload2.svg'
import { ReactComponent as uploadAlt } from '../../assets/images/icons/uploadAlt.svg'
import { ReactComponent as verified } from '../../assets/images/icons/verified.svg'
import { ReactComponent as videoFile } from '../../assets/images/icons/videoFile.svg'
import { ReactComponent as warningCircle } from '../../assets/images/icons/warning-circle.svg'
import { ReactComponent as pwdLock } from '../../assets/images/icons/pwd-lock.svg'
import { ReactComponent as expiredWarning } from '../../assets/images/icons/expiredWarning.svg'
import { ReactComponent as admin } from '../../assets/images/icons/admin-panel.svg'
import { ReactComponent as reportsPage } from '../../assets/images/icons/reports-page.svg'
import { ReactComponent as advancedSearch } from '../../assets/images/icons/advanced-search.svg'

export const IconMap = {
  add,
  arrowdown,
  arrowleft,
  arrowright,
  arrowtop,
  bankDeposit,
  bubble,
  calendar,
  chart,
  checked,
  close,
  customers,
  dashboard,
  export1,
  export2,
  eyeClose,
  money,
  network,
  handshake,
  link,
  location,
  filter,
  referral,
  refresh,
  realEstate,
  search,
  team,
  plus,
  minus1,
  down,
  up,
  list,
  compare,
  analytics,
  fullscreen,
  collapse,
  hierachy,
  coordinate,
  payment,
  edit,
  company,
  partnership,
  news,
  chartcompare,
  navCollapse,
  navExpand,
  save,
  explore,
  closeFilter,
  funding,
  info,
  close1,
  asset,
  support,
  sortTimeAsc,
  sortTimeDesc,
  report,
  treeMap,
  home,
  linkedIn,
  google,
  mail,
  refreshAuth,
  download,
  page,
  product,
  target,
  eyeopen,
  trend,
  trophy,
  pieChart,
  seeMore,
  searchPlus,
  ascending,
  descending,
  plusBorder,
  trash,
  addBuilding,
  addUser,
  copy,
  minus2,
  bell,
  removeUser,
  house,
  companyWatchlist,
  warningCircle,
  lock,
  check,
  update,
  setting,
  list2,
  share,
  email,
  monitor,
  arrowdown1,
  stars,
  business,
  pictureBlack,
  idCard,
  camera,
  upload,
  upload2,
  uploadAlt,
  refresh2,
  globe,
  clock,
  verified,
  cancel,
  play,
  videoFile,
  pdfFile,
  noUseCase,
  noInfo,
  question,
  addInformation,
  removeInformation,
  emptyStatistics,
  emptyViews,
  newLogo,
  layerGroup,
  cloud,
  medal,
  uploadThumbnail,
  image,
  compare2,
  pinIcon,
  group,
  group1,
  sendEmail,
  passwordChanged,
  pwdLock,
  expiredWarning,
  minus,
  admin,
  reportsPage,
  advancedSearch,
}

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: keyof typeof IconMap
}

const Icon: React.FC<IconProps> = ({ name, width = '16', height = '16', ...rest }) => {
  const MatchIcon = IconMap[name] || null
  return <MatchIcon width={width} height={height} data-testid="FCTIcon" {...rest} />
}

export default Icon

import { makeStyles, Link, Box, IconButton } from '@material-ui/core'
import { ETypeDialog, FCTDialog } from 'components/Dialog/FCTDialog'
import ForgotPasswordForm, {
  ForgotPasswordTitle,
} from 'components/ForgotPasswordForm/ForgotPasswordForm'
import { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import CheckYourEmail from 'components/CheckYourEmail/CheckYourEmail'
import forgotPassword from 'axios/forgotPassword'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 8,
    color: theme.palette.grey[500],
  },
}))

const ForgotPasswordContainer = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen(v: boolean): void
}) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')

  return (
    <FCTDialog
      hasNoTitle
      hasNoAction
      open={open}
      setOpen={(value: boolean) => setOpen(value)}
      title="Forgot Password"
      type={ETypeDialog.Default}
      handleAgree={async () => {}}
      handleCancel={async () => {}}
      hasCloseWhenCancel={false}
      itemStyle={{
        textAlign: 'center',
        maxWidth: '500px',
        minWidth: '500px',
      }}
    >
      <Box padding="40px" position="relative">
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            setOpen(false)
            setEmail('')
          }}
        >
          <CloseIcon />
        </IconButton>

        {email ? (
          <CheckYourEmail
            email={email}
            onClickResend={async () => {
              await forgotPassword(email)
            }}
          />
        ) : (
          <>
            <ForgotPasswordTitle />
            <ForgotPasswordForm
              handleSubmit={async data => {
                try {
                  await forgotPassword(data.businessEmail)
                } finally {
                  setEmail(data.businessEmail)
                }
              }}
            />
          </>
        )}
      </Box>
    </FCTDialog>
  )
}

export default ForgotPasswordContainer

import { useQuery } from '@apollo/client'
import { uniq } from 'lodash'
import { useMemo } from 'react'
import { TOOLTIPS } from 'utils/Taxonomy'
import SearchResult, { ISearchResultProps } from '../components/Search/SearchResult'
import { calculateStats } from '../components/Search/utils'
import { TooltipDesc } from '../components/Tabs/FCTTab'
import { IHit } from '../store/operations/queries/local/rest/RestfulResponse'
import { ISearchCompany } from '../store/operations/queries/local/rest/SearchCompanies'
import { SEARCH_DIMENSIONS } from '../store/operations/queries/SearchDimensions'
import { SEARCH_TAGS } from '../store/operations/queries/SearchTags'
import { FIXED_APPENDIX } from '../utils/consts'
import { SearchDimensions, SearchDimensionsVariables } from '../__generated__/SearchDimensions'
import { SearchTags, SearchTagsVariables } from '../__generated__/SearchTags'
import { TagGroups } from 'components/Search/enums'

const getNames = (data: IHit<ISearchCompany>[], type: TagGroups) =>
  uniq(
    data
      .map(item => calculateStats(item, type))
      .reduce((acc: string[], curr) => {
        return [...acc, ...Array.from(curr.keywords)]
      }, [])
  )

const SearchResultContainer = (props: ISearchResultProps) => {
  const tagNames = useMemo(() => getNames(props.companies || [], TagGroups.Tag), [props.companies])
  const dimensionNames = useMemo(() => getNames(props.companies || [], TagGroups.Dimension), [
    props.companies,
  ])

  const { data: tags } = useQuery<SearchTags, SearchTagsVariables>(SEARCH_TAGS, {
    variables: { names: [...tagNames, '(', ')'] },
    skip: !tagNames.length,
  })

  const { data: dimensions } = useQuery<SearchDimensions, SearchDimensionsVariables>(
    SEARCH_DIMENSIONS,
    {
      variables: { names: [...dimensionNames, '(', ')'] },
      skip: !dimensionNames.length,
    }
  )

  return (
    <SearchResult
      {...props}
      getTooltip={(name, type) => {
        if (FIXED_APPENDIX[name]) return FIXED_APPENDIX[name]
        if (TOOLTIPS[name?.toLowerCase()]) return TOOLTIPS[name?.toLowerCase()] as string

        if (type === TagGroups.Tag) {
          return (
            tags?.searchTags?.find(
              i =>
                !!i.tagDesc &&
                (i.tagName?.toUpperCase() === name.toUpperCase() ||
                  i.tagName?.replace(/(\(|\))/g, '').toUpperCase() === name.toUpperCase())
            )?.tagDesc || ''
          )
        }
        if (type === TagGroups.Dimension) {
          return (
            dimensions?.searchDimensions?.find(
              i =>
                !!i.dimensionDesc &&
                (i.dimensionName?.toUpperCase() === name.toUpperCase() ||
                  i.dimensionName?.replace(/(\(|\))/g, '').toUpperCase() === name.toUpperCase())
            )?.dimensionDesc || ''
          )
        }
        return ''
      }}
    />
  )
}

export default SearchResultContainer

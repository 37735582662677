import { useDispatch, useSelector } from 'react-redux'
import { selectOpenFilter, selectOpenOptionFilter } from './FilterSelector'
import { IOpenOptionFilter, updateOpenFilter, updateOpenOptionFilter } from './FilterSlice'

const useOpenFilter = (): [boolean, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectOpenFilter)
  const update = (state: any) => {
    dispatch(updateOpenFilter(state))
  }

  return [state, update]
}

const useOpenOptionFilter = (): [IOpenOptionFilter, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectOpenOptionFilter)
  const update = (state: any) => {
    dispatch(updateOpenOptionFilter(state))
  }

  return [state, update]
}

export { useOpenFilter, useOpenOptionFilter }

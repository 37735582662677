import { Entity, SafeType } from 'dto-mapping'
import { ICompanyOption } from 'features/search-companies-name/SearchCompanies'
import { IRealValue } from '../components/Slider/FCTSlider'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../__generated__/globalTypes'
import { ESwitchFemale } from './Filter'
import { ISearchInputOption } from 'components/Search/interfaces'

export enum ECompareType {
  ProfileTop10 = 1,
  ProfileSimilar = 2,
  Notification = 3,
}

export enum EModeName {
  SmartCluster = 'Smart Cluster',
}

export interface ISubFilter {
  companyIds: number[]
  compareType: ECompareType | null
  currentCompanyName: string | null
  product?: number | null
  businessLines?: number | null
  valueChains?: number | null
  operatingModel?: number | null
}

export interface ISmartCluster {
  keywordsInclude: string[] | null
  keywordsExclude: string[] | null
  similarSelectedCompanies: ICompanyOption[] | null
  keywords: string | null
  distance: string | number | null
}

export interface ICompanyDealFilter {
  category: InternalDbCompanyTypes
  businessLines: number[] | null
  risks: number[] | null
  valueChains: number[] | null
  operatingModels: number[] | null
  products: number[]
  productParentId: number | null
  regions: string[] | null
  subRegions: string[] | null
  subRegion2s: string[] | null
  countries: number[] | null
  businessModelIds: number[] | null
  distributionModelIds: number[] | null
  companyStatuses: number[] | null
  fundingRound1s: number[]
  fundingRound2s: number[]
  searchData: ICompanySearchData
  viewBy: GlobalFilterViewBy
  selectedProductId: number | null
  selectedBusinessLineId: number | null
  //
  numOfEmployeesRanges: IRealValue
  switchFemalePicker: ESwitchFemale
  numOfFemaleFounderFrom: string | null
  numOfFemaleFounderTo: string | null
  numOfFemaleFounderPercentFrom: string | null
  numOfFemaleFounderPercentTo: string | null
  yearFoundedRange: boolean
  foundedYearFrom: string[] | null
  foundedYearTo: string[] | null
  latestFundingRound1s: number[]
  latestFundingRound2s: number[]
  totalFundingFrom: string | null
  totalFundingTo: string | null
  equityFundingFrom: string | null
  equityFundingTo: string | null
  latestDealAmountFrom: string | null
  latestDealAmountTo: string | null
  dealAmountFrom: string | null
  dealAmountTo: string | null
  yearFundingRange: boolean
  fundingDateFrom: string | null
  fundingDateTo: string | null
  investors: string[]
  investorsTypes: string[]
  leadInvestors: string[]
  leadInvestorTypes: string[]
  numberOfInvestorsRanges: IRealValue
  numberOfFundingRounds: IRealValue
  acquirerTypes: string[]
  acquirers: string[]
  acquisitionYearRange: boolean
  acquiredDateFrom: string | null
  acquiredDateTo: string | null
  orderByCompany: SortInfo[] | null
  orderByDeal: SortInfo[] | null
  tagGroupIds: number[] | null
  includedEntities: string[] | null
  wentPublicOnDateFrom: string | null
  wentPublicOnDateTo: string | null
  smartCluster: ISmartCluster
  modeName: EModeName | null
  hasIPO?: string
  hasBeenAcquired?: string
}

export type SortInfo = {
  field: string
  direction: string
}

export interface ICompanySearchData {
  options: ISearchInputOption[]
}

@Entity()
export class SubFilters implements Partial<ISubFilter> {
  @SafeType({ type: Number })
  companyIds?: number[]
  @SafeType({ type: Number })
  compareType?: ECompareType | null
  currentCompanyName?: string | undefined
  constructor(_subFilters: any) {}
}

@Entity()
export class SearchCompany implements ICompanySearchData {
  options: ISearchInputOption[] = []
}
@Entity()
export class CompanyDealFilters implements Partial<ICompanyDealFilter> {
  category?: InternalDbCompanyTypes
  viewBy?: GlobalFilterViewBy
  @SafeType({ type: Number })
  selectedProductId?: number | null

  @SafeType({ type: Number })
  selectedBusinessLineId?: number | null

  @SafeType({ type: Number })
  businessLines?: number[] | null

  @SafeType({ type: Number })
  risks?: number[] | null

  @SafeType({ type: Number })
  valueChains?: number[] | null

  @SafeType({ type: Number })
  operatingModels?: number[] | null

  @SafeType({ type: Number })
  products?: number[]

  @SafeType({ type: Number })
  productParentId?: number

  @SafeType({ type: Number })
  businessModelIds?: number[] | null

  @SafeType({ type: Number })
  distributionModelIds?: number[] | null

  @SafeType({ type: Number })
  tagGroupIds?: number[] | null

  @SafeType({ type: Number })
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: IRealValue

  @SafeType({ type: Number })
  switchFemalePicker?: ESwitchFemale

  numOfFemaleFounderFrom?: string | null
  numOfFemaleFounderTo?: string | null
  numOfFemaleFounderPercentFrom?: string | null
  numOfFemaleFounderPercentTo?: string | null

  @SafeType({ type: Boolean })
  yearFoundedRange?: boolean

  foundedYearFrom?: string[] | null
  foundedYearTo?: string[] | null
  totalFundingFrom?: string | null
  totalFundingTo?: string | null
  latestDealAmountFrom?: string | null
  latestDealAmountTo?: string | null
  dealAmountFrom?: string | null
  dealAmountTo?: string | null

  @SafeType({ type: Boolean })
  yearFundingRange?: boolean
  @SafeType({ type: Number })
  latestFundingRound1s?: number[]
  @SafeType({ type: Number })
  latestFundingRound2s?: number[]
  fundingDateFrom?: string | null
  fundingDateTo?: string | null

  investorsTypes?: string[]
  investors?: string[]
  leadInvestors?: string[]
  leadInvestorTypes?: string[]
  numberOfInvestorsRanges?: IRealValue
  numberOfFundingRounds?: IRealValue
  acquirerTypes?: string[]
  acquirers?: string[]
  includedEntities?: string[]

  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null

  @SafeType({ type: Number })
  countries?: number[] | null
  orderByCompany?: SortInfo[] | null
  orderByDeal?: SortInfo[] | null

  @SafeType({ type: Boolean })
  acquisitionYearRange?: boolean
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  @SafeType({ type: Number })
  fundingRound1s?: number[]
  @SafeType({ type: Number })
  fundingRound2s?: number[]
  allFundingRound1s?: string[]
  allFundingRound2s?: string[]
  @SafeType({ type: SearchCompany })
  searchData?: SearchCompany
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  smartCluster?: ISmartCluster
  modeName?: EModeName | null

  // Using filters: any type for converting type from any to CompanyDealFilters
  constructor(_filters: any) {}
}

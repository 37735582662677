import { useQuery } from '@apollo/client'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { convertOptionsToQuery } from '../../store/cache/Common'
import {
  SearchCompaniesResult,
  SEARCH_COMPANIES,
} from '../../store/operations/queries/local/rest/SearchCompanies'
import { commonCompanySearchQuery } from '../../utils/CompanyFilter'

export default function useGetSearchCompanies(filter: any) {
  // Get companyIds filter
  const companySearchQuery = useMemo(
    () => convertOptionsToQuery(filter.searchData?.options || []),
    [filter]
  )
  const { data: companySearchFilter } = useQuery<SearchCompaniesResult>(SEARCH_COMPANIES, {
    variables: {
      input: `q=${encodeURIComponent(companySearchQuery)}${commonCompanySearchQuery}`,
    },
    skip: isNil(filter?.searchData?.options) || !filter?.searchData?.options.length,
  })

  return companySearchFilter
}

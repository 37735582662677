import Loading from 'components/Loading'
import { useState, useEffect } from 'react'
import csv2json from 'csvjson-csv2json'
import { EDocumentTemplate, IDocumentTemplate, IWebflowCollection } from './types'
import { PolicyDocumentTemplate } from './PolicyDocumentTemplate'
import { readFile } from './utils/readFile'

export type IPrivacyDocumentProps = IDocumentTemplate
const PrivacyDocument = ({ template }: IPrivacyDocumentProps) => {
  const [content, setContent] = useState<IWebflowCollection>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchFile = async () => {
      setLoading(true)
      const text = (await import('../../assets/webflow'))['privacy']
      const data = await readFile(text)
      const json: IWebflowCollection[] = csv2json(data)
      setContent(json[0])
      setLoading(false)
    }
    fetchFile()
  }, [])

  if (loading) return <Loading />
  return !content ? null : <PolicyDocumentTemplate loading={loading} content={content} />
}

export default PrivacyDocument

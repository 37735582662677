import { Typography } from '@material-ui/core'
import axios from 'axios'
import { Dispatch, SetStateAction } from 'react'
import { useToasts } from 'react-toast-notifications'
import PrivacyDialog from '../components/Policy/PrivacyDialog'
import TermsOfUseDialog from '../components/Policy/TermsOfUseDialog'
import theme from '../theme'
import config from '../core/config/auth'
import { useOktaAuth } from '@okta/okta-react'
import { IPrivacyDocumentProps } from 'components/Policy/PrivacyDocument'
import { IDocumentTemplate } from 'components/Policy/types'

const NotNow = () => (
  <Typography
    style={{
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '22px',
      color: theme.palette.grey[500],
    }}
  >
    Not Now
  </Typography>
)

enum EPolicyTypes {
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_OF_USE = 'terms_of_use',
}

const UpdatePoliciesContainer = ({
  showPrivacy,
  setShowPrivacy,
  showTermsOfUse,
  setShowTermsOfUse,
  template,
}: {
  showPrivacy: boolean
  setShowPrivacy: Dispatch<SetStateAction<boolean>>
  showTermsOfUse: boolean
  setShowTermsOfUse: Dispatch<SetStateAction<boolean>>
} & IDocumentTemplate) => {
  const { oktaAuth } = useOktaAuth()
  const { addToast } = useToasts()

  const updatePolicies = async (token: string, types: Array<EPolicyTypes>) => {
    try {
      await axios.post(
        `${config.api}/users/me/policies`,
        { types },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        }
      )
    } catch (error) {
      addToast(<Typography style={{ fontSize: '14px' }}>Failed to update policies</Typography>, {
        appearance: 'error',
      })
    }
  }

  if (!oktaAuth.getAccessToken()) return null
  return (
    <>
      <TermsOfUseDialog
        template={template}
        open={!showPrivacy && showTermsOfUse}
        setOpen={() => {}}
        titleBtnCancel={<NotNow />}
        handleAgree={async () => {
          await updatePolicies(oktaAuth.getAccessToken() as string, [EPolicyTypes.TERMS_OF_USE])
          setShowTermsOfUse(false)
        }}
        handleCancel={async () => {
          await oktaAuth.signOut()
        }}
      />
      <PrivacyDialog
        template={template}
        open={showPrivacy}
        setOpen={() => {}}
        titleBtnCancel={<NotNow />}
        childrenFooter={
          <div
            style={{
              fontSize: 12,
              lineHeight: '18px',
              color: theme.palette.grey[600],
            }}
          >
            <span>* We have updated our privacy policy to serve you better.</span>
            <br />
            <span>To continue using the platform, scroll to read more and agree.</span>
          </div>
        }
        handleAgree={async () => {
          await updatePolicies(oktaAuth.getAccessToken() as string, [EPolicyTypes.PRIVACY_POLICY])
          setShowPrivacy(false)
        }}
        handleCancel={async () => {
          await oktaAuth.signOut()
        }}
      />
    </>
  )
}
export default UpdatePoliciesContainer

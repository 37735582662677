import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { ADD_DOWNLOAD_HISTORY } from 'store/operations/mutations/AddDownloadHistory'
import { GET_DOWNLOAD_HISTORY } from 'store/operations/queries/GetDownloadHistory'
import { GetDownloadHistory } from '__generated__/GetDownloadHistory'
import { DownloadHistoryInputItem } from '__generated__/globalTypes'
import { DOWNLOAD_HISTORY_TYPE } from './../../constants/DownloadHistory'
import { commonErrorHandler } from './../../../utils/CommonErrorHandler'
import { useToasts } from 'react-toast-notifications'
import { Typography } from '@material-ui/core'
import { useUserContext } from 'routers/InitUserWrapper'

export function useDownloadHistory(type: number) {
  const [downloadHistoryItems, setDownloadHistoryItems] = useState<DownloadHistoryInputItem[]>([])
  const [loadingAddDownload, setLoadingAddDownload] = useState(false)
  const { isTrial } = useUserContext()
  const {
    data: getDownloadHistory,
    loading,
    refetch: refetchGetDownloadHistory,
  } = useQuery<GetDownloadHistory>(GET_DOWNLOAD_HISTORY, {
    variables: {
      input: {
        type,
        isTrial,
      },
    },
  })
  const { addToast } = useToasts()
  const [addDownloadHistory] = useMutation(ADD_DOWNLOAD_HISTORY)

  const handleAddDownloadHistory = async (
    payload: DownloadHistoryInputItem[],
    hasCatchError?: boolean
  ) => {
    setLoadingAddDownload(true)
    try {
      await addDownloadHistory({
        variables: {
          input: {
            listDownloadHistories: payload,
            isTrial,
          },
        },
      })
      const res = await refetchGetDownloadHistory()
      setLoadingAddDownload(false)
      if (hasCatchError) return true
      const total = res.data.getDownloadHistory?.total || 0
      const limit = res.data.getDownloadHistory?.limit
      return (limit ? +limit : 0) - total
    } catch (error) {
      setLoadingAddDownload(false)
      if (hasCatchError) {
        commonErrorHandler(err => {
          addToast(<Typography>{err.graphQLError.message}</Typography>, {
            appearance: 'error',
          })
        })(error)
        return false
      }
      throw error
    }
  }

  const data = getDownloadHistory?.getDownloadHistory

  const disabled = data?.total == data?.limit

  const remainingAmount = useMemo(() => {
    if (disabled || type === DOWNLOAD_HISTORY_TYPE.ANALYTICS) return 0
    return (data?.limit ? +data.limit : 0) - (data?.total || 0)
  }, [data])

  const exportTooltipMes = useMemo(() => {
    const now = new Date()
    let strValue = 'no'
    if (!disabled) {
      strValue = `${remainingAmount} of ${data?.limit || 0}`
    }

    return `You have ${strValue} ${
      type === DOWNLOAD_HISTORY_TYPE.REQUEST ? 'requests' : 'downloads'
    } left. Next refresh: ${moment(new Date(now.getFullYear(), now.getMonth() + 1, 1)).format(
      'D MMM YYYY'
    )}.`
  }, [data, remainingAmount])

  return {
    data,
    exportTooltipMes,
    disabled,
    loading,
    loadingAddDownload,
    remainingAmount,
    addDownloadHistory: handleAddDownloadHistory,
    setDownloadHistoryItems,
    downloadHistoryItems,
  }
}

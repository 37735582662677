import { useQuery } from '@apollo/client'
import { FCTOption } from 'components/Select/FCTSelect'
import React, { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PROFILE_FILTER } from 'store/operations/queries/local/GetProfileFilter'
import { GetCompanyInformation } from '__generated__/GetCompanyInformation'
import { GetCompanyCategoriesTags } from '__generated__/GetCompanyCategoriesTags'
import { GET_COMPANY_INFORMATION } from './../store/operations/queries/GetCompanyInformation'
import { IProfileFilter } from './../types/ProfileFilter'
import { UserContext, GroupInternal } from './../routers/InitUserWrapper'
import { GET_PROFILE_FILTER } from './../store/operations/queries/local/GetProfileFilter'
import { EProfileTab } from 'types/Profile'
import mixpanel from 'mixpanel-browser'
import { GET_COMPANY_CATEGORIES_TAGS } from 'store/operations/queries/GetCompanyCategoriesTags'

const PROFILE_TYPE = {
  COMPANY: 'Company',
  INVESTOR: 'Investor',
}

const PROFILE_TYPE_OPTIONS: FCTOption[] = [
  { label: PROFILE_TYPE.COMPANY, value: PROFILE_TYPE.COMPANY },
  { label: PROFILE_TYPE.INVESTOR, value: PROFILE_TYPE.INVESTOR },
]

const initialState = {
  options: [],
  companyId: '',
  investorId: null,
  companyProfileInfo: undefined,
  loading: false,
  selectedProfile: PROFILE_TYPE.COMPANY,
  isPermissionEdit: false,
  isUserInternal: false,
  companyCategoriesTags: undefined,
  loadingTags: false,
}

interface ICompanyProfileState {
  options: FCTOption[]
  loading: boolean
  companyId: string
  investorId: number | null
  companyProfileInfo?: GetCompanyInformation
  selectedProfile: string
  isPermissionEdit: boolean
  isUserInternal: boolean
  companyCategoriesTags?: GetCompanyCategoriesTags
  loadingTags: boolean
}

const Context = React.createContext<ICompanyProfileState>(initialState)

export const CompanyProfileProvider = ({ children }: any) => {
  const [investorId, setInvestorId] = useState<number | null>(null)
  const { id } = useParams<{ id: string }>()
  const { data: profileFilter } = useQuery<PROFILE_FILTER>(GET_PROFILE_FILTER)
  const filter = profileFilter?.profileFilter as IProfileFilter
  const { user } = useContext(UserContext)
  const isPermissionEdit = user?.profile?.companyId === id
  const isUserInternal = user?.groups.some(e => GroupInternal.includes(e.name))
  //getDataInformation
  const { data: companyProfileInfo, loading } = useQuery<GetCompanyInformation>(
    GET_COMPANY_INFORMATION,
    {
      variables: {
        id: +id,
      },
      onCompleted: res => {
        const associatedInvestorId =
          res?.getCompanyInformation?.companyInformation?.associated_investor_id
        const investorHasDeal = res?.getCompanyInformation?.companyInformation?.investorHasDeal
        if (investorHasDeal && associatedInvestorId !== investorId)
          setInvestorId(associatedInvestorId ? +associatedInvestorId : null)
        if (!isUserInternal) {
          res?.getCompanyInformation?.name &&
            mixpanel.track(`${isPermissionEdit ? 'FSD_' : 'Profile '}View`, {
              companyID: id,
              companyName: res.getCompanyInformation.name,
            })
          if (
            !isPermissionEdit &&
            filter.profileTab &&
            filter.profileTab !== EProfileTab.News &&
            filter.profileTab !== EProfileTab.Statistics
          ) {
            mixpanel.track('Profile Tab', {
              id,
              name: filter.profileTab,
            })
          }
        }
      },
    }
  )

  const { data: companyCategoriesTags, loading: loadingTags } = useQuery<GetCompanyCategoriesTags>(
    GET_COMPANY_CATEGORIES_TAGS,
    {
      variables: {
        id: +id,
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  return (
    <Context.Provider
      value={{
        options: PROFILE_TYPE_OPTIONS,
        investorId,
        companyId: id,
        companyProfileInfo,
        companyCategoriesTags,
        loading,
        loadingTags,
        selectedProfile: PROFILE_TYPE.COMPANY,
        isPermissionEdit,
        isUserInternal,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useCompanyProfileContext = () => {
  const context = useContext(Context)
  if (!context)
    throw new Error('useCompanyProfileContext must be used within a CompanyProfileProvider')
  return context
}

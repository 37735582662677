import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import theme from '../../theme'
import FCTTooltip from '../Tooltip/Tooltip'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 8,
  },
  statistics: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 3,
    background: '#FFFFFF',
  },
  item: {
    margin: '8px 0 8px 16px',
    paddingRight: 28,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  title: {
    margin: 0,
    color: theme.palette.grey[800],
    lineHeight: '18px',
    fontWeight: 600,
    fontSize: 12,
  },
  content: {
    margin: 0,
    color: theme.palette.primary.main,
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 600,
  },
  flex: {
    display: 'flex',
  },
  tooltipItem: {
    fontSize: 12,
  },
})

export enum TooltipDesc {
  CAGR = 'Compounded annual growth rate of active companies over the last 3, 5, 10, or 15 complete years.',
}

const StatisticsBox = (props: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <div className={classes.statistics}>
          {props.items.map((item, index) => (
            <FCTTooltip
              key={index}
              title={<Typography className={classes.tooltipItem}>{TooltipDesc.CAGR}</Typography>}
              placement="top"
              arrow
            >
              <Grid className={classes.item}>
                <p className={classes.title}>{item.title}</p>
                <p className={classes.content}>{item.content}</p>
              </Grid>
            </FCTTooltip>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StatisticsBox

type Props = {
  items: Array<{
    content: string
    title: string
  }>
}

import { useQuery } from '@apollo/client'
import { FCTOption } from 'components/Select/FCTSelect'
import mixpanel from 'mixpanel'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GET_INVESTOR_BASIC_INFO } from './../store/operations/queries/GetInvestorProfileBasicInfo'
import { GetInvestorBasicInfo } from './../__generated__/GetInvestorBasicInfo'

const PROFILE_TYPE = {
  COMPANY: 'Company',
  INVESTOR: 'Investor',
}

const PROFILE_TYPE_OPTIONS: FCTOption[] = [
  { label: PROFILE_TYPE.COMPANY, value: PROFILE_TYPE.COMPANY },
  { label: PROFILE_TYPE.INVESTOR, value: PROFILE_TYPE.INVESTOR },
]

const initialState = {
  options: [],
  companyId: null,
  investorId: '',
  investorProfileInfo: undefined,
  loading: false,
  fnInvestorBasicInfo: async () => {},
  isNotEmpty: false,
  setIsNotEmpty: () => {},
  setIsRefetch: () => {},
  selectedProfile: PROFILE_TYPE.COMPANY,
}

interface IInvestorProfileState {
  options: FCTOption[]
  loading: boolean
  companyId: number | null
  investorId: string
  investorProfileInfo?: GetInvestorBasicInfo
  fnInvestorBasicInfo: () => Promise<void>
  isNotEmpty: boolean
  setIsNotEmpty: (value: boolean) => void
  setIsRefetch: (value: boolean) => void
  selectedProfile: string
}

const Context = React.createContext<IInvestorProfileState>(initialState)

export const InvestorProfileProvider = ({ children }: any) => {
  const [companyId, setCompanyId] = useState<number | null>(null)
  const { id: investorId } = useParams<{ id: string }>()
  const [isNotEmpty, setIsNotEmpty] = useState(false)
  const [isRefetch, setIsRefetch] = useState(false)

  const {
    data: investorProfileInfo,
    loading: loadingInvestorBasicInfo,
    refetch: fnInvestorBasicInfo,
  } = useQuery<GetInvestorBasicInfo>(GET_INVESTOR_BASIC_INFO, {
    variables: {
      id: investorId,
    },
    onCompleted: res => {
      mixpanel.track('InvestorProfile View', {
        investorID: investorId,
        investorName: res.getInvestorBasicInfo?.investorName,
      })
      const associatedCompanyId = res?.getInvestorBasicInfo?.associatedCompanyId
      associatedCompanyId !== companyId && setCompanyId(associatedCompanyId || null)
    },
    fetchPolicy: isRefetch ? 'network-only' : 'cache-first',
  })

  return (
    <Context.Provider
      value={{
        options: PROFILE_TYPE_OPTIONS,
        investorId,
        companyId,
        investorProfileInfo,
        loading: loadingInvestorBasicInfo,
        fnInvestorBasicInfo: async () => {
          await fnInvestorBasicInfo()
        },
        isNotEmpty,
        setIsNotEmpty,
        setIsRefetch,
        selectedProfile: PROFILE_TYPE.INVESTOR,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useInvestorProfileContext = () => {
  const context = useContext(Context)
  if (!context)
    throw new Error('useInvestorProfileContext must be used within a InvestorProfileProvider')
  return context
}

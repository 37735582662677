import { isEqual, omit } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { EWatchListType } from '../components/WatchList/SelectTabWatchList'
import { setProfileFilter } from '../store/cache/ProfileFilterPolicies'
import { selectAnalysisFilter } from '../store/features/analysis/AnalysisSelector'
import { selectCompanyDealFilter } from '../store/features/company-deal/CompanyDealSelector'
import {
  selectWatchListFilter,
  selectWatchListId,
  selectWatchListType,
} from '../store/features/watch-list/WatchListSelector'
import { EProfileTab } from '../types/Profile'
import { IAnalysisFilter } from './../types/Filter'
export const useNavigateProfile = () => {
  const history = useHistory()

  const navigateProfile = (id: number | null) => {
    if (id) {
      setProfileFilter({ profileTab: EProfileTab.News })
      const win = window.open(`/app/profile/${id}`, '_blank')
      win && win.focus()
    }
  }
  return { navigateProfile }
}

const isEmptyValue = (v: any) => {
  return (
    v == null ||
    v == undefined ||
    v == '' ||
    (Array.isArray(v) && !v.length) ||
    (typeof v == 'object' && !Object.keys(v).length)
  )
}

const compareObjects = (object: IAnalysisFilter, othObject: any) => {
  for (const key in othObject) {
    const k = key as keyof IAnalysisFilter
    if (isEmptyValue(object[k]) && isEmptyValue(othObject[k])) continue
    if (!isEqual(object[k], othObject[k])) return false
  }
  return true
}

export const useBlockBeforeNavigate = (): { isBlocked: boolean | null } => {
  const history = useHistory()
  const currentPath = history.location.pathname
  const selectingWatchListId = useSelector(selectWatchListId)
  const selectingWatchListType = useSelector(selectWatchListType)
  const selectingWatchListFilter = useSelector(selectWatchListFilter)
  let filterToCompare
  if (
    currentPath.includes('/sunburst') ||
    currentPath.includes('/landscape') ||
    currentPath.includes('/charts') ||
    currentPath.includes('/news')
  ) {
    filterToCompare = useSelector(selectAnalysisFilter)
  } else {
    filterToCompare = useSelector(selectCompanyDealFilter)
  }
  const isBlocked =
    !currentPath.includes('/report') &&
    !currentPath.includes('/search') &&
    !currentPath.includes('/notifications') &&
    selectingWatchListId != null &&
    selectingWatchListType == EWatchListType.filterConditions &&
    !compareObjects(
      selectingWatchListFilter,
      omit(filterToCompare, ['searchData', 'orderByCompany', 'orderByDeal'])
    )

  return { isBlocked }
}

export const useWatchListFilterChanged = (): { watchListFilterChanged: boolean | null } => {
  const history = useHistory()
  const selectingWatchListId = useSelector(selectWatchListId)
  const selectingWatchListFilter = useSelector(selectWatchListFilter)
  const currentPath = history.location.pathname
  let filterToCompare
  if (
    currentPath.includes('sunburst') ||
    currentPath.includes('landscape') ||
    currentPath.includes('charts') ||
    currentPath.includes('news')
  ) {
    filterToCompare = useSelector(selectAnalysisFilter)
  } else {
    filterToCompare = useSelector(selectCompanyDealFilter)
  }
  const watchListFilterChanged =
    !!selectingWatchListId &&
    !compareObjects(
      selectingWatchListFilter,
      omit(filterToCompare, ['searchData', 'orderByCompany', 'orderByDeal'])
    )
  return { watchListFilterChanged }
}

import { NetworkStatus, useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core'
import { EXPAND_ROUND1_ID } from 'core/constants/companyFilter'
import * as d3 from 'd3'
import { cloneDeep, concat, intersection } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useUserContext } from 'routers/InitUserWrapper'
import { GET_ATLAS_VIEW_REST } from 'store/operations/queries/local/rest/GetAtlasView'
import { ITreeData } from 'types/Tree'
import { ETaxonomyCategory } from 'utils/FlattenEcoSystem'
import { getChildrenIds, getChildrenValueChainIds } from 'utils/Tree'
import { ESearchTab } from '../components/ExploreDBComponent/MockData'
import Loading from '../components/Loading'
import { RevampTooltip } from '../components/RevampTooltip'
import SunburstChart from '../components/SunburstChart'
import { EWatchListType } from '../components/WatchList/SelectTabWatchList'
import { VIEW_BY } from '../core/constants/Filter'
import useGetSearchCompanies from '../core/hooks/useGetSearchCompanies'
import mixpanel from '../mixpanel'
import { AddWatchList } from '../module/WatchList/AddWatchList'
import { IColumnState } from '../store/cache/CompaniesList'
import { searchDealsColumnsVar } from '../store/cache/DealsList'
import {
  useAnalysisFilter,
  useBreadcrumbs,
  useChipsHeader,
} from '../store/features/analysis/AnalysisHook'
import { useCompanyDealFilter, useSubFilter } from '../store/features/company-deal/CompanyDealHook'
import { useOpenFilter } from '../store/features/filter/FilterHook'
import { useWatchListId, useWatchListType } from '../store/features/watch-list/WatchListHook'
import { setBubbleActiveNode, setCurrentProfileTab } from '../store/operations/mutations'
import { GET_COMPANY_IDS_LIST } from '../store/operations/queries/GetCompanyList'
import { GET_DEAL_FILTER_DATA } from '../store/operations/queries/GetDealFilterData'
import { GET_EQUITY_FUNDING_BY_FUNDING_AMOUNT } from '../store/operations/queries/GetEquityFundingByFundingAmount'
import { GET_WATCH_LIST_COMPANY_IDS } from '../store/operations/queries/GetWatchListCompanyIds'
import {
  GetBubbleActiveNode,
  GET_BUBBLE_ACTIVE_NODE,
} from '../store/operations/queries/local/GetBubbleActiveNode'
import {
  GetSearchDealsColumns,
  GET_SEARCH_DEALS_COLUMNS,
} from '../store/operations/queries/local/GetSearchDealsColumns'
import { IDimensionTree } from '../types/Dimension'
import { IAnalysisFilter } from '../types/Filter'
import { InternalCompanyName } from '../types/InternalCompanyName'
import {
  CATEGORY_TAXONOMY,
  convertProductId,
  getCompanyInput,
  getProducts,
  getProductsFilterWhenSelectedId,
  getValueChainsBySelectedId,
  TAXONOMY_ID,
} from '../utils/CompanyFilter'
import { PeriodType } from '../utils/convert/Title'
import { getInputAnalysis, navigateToExploreDatabase } from '../utils/Filter'
import { FindTaxonomy, getTreeByID } from '../utils/FindTaxonomy'
import formatAtlasView from '../utils/FormatAtlasView'
import { useBlockBeforeNavigate, useNavigateProfile } from '../utils/navigationHook'
import { TaxonomyMapping, TaxonomyMappingWithBusiness } from '../utils/TaxonomyMapping'
import { trackingTaxonomy, useTrackingFilterChart } from '../utils/Tracking'
import {
  GetAtlasViewData,
  GetAtlasViewData_getAtlasViewData,
  GetAtlasViewData_getAtlasViewData_companies,
  GetAtlasViewData_getAtlasViewData_latestDeals,
} from '../__generated__/GetAtlasViewData'
import {
  GetCompanyList,
  GetCompanyList_getCompanyList_items,
} from '../__generated__/GetCompanyList'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '../__generated__/GetDealFilterDataResult'
import { GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData } from '../__generated__/GetEquityFundingByFundingAmount'
import { GetWatchListCompanyIds } from '../__generated__/GetWatchListCompanyIds'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../__generated__/globalTypes'
import { IColumn } from './LatestInvestmentsContainer'
import { ModalUpdateWatchListFilter } from 'components/WatchList/ModalUpdateWatchListFilter'
import { getValueChainsFilterWhenSelectedId } from './../utils/CompanyFilter'
import { TAXONOMY_ID_THRESH_HOLD_CHECK } from 'utils/consts'
import { useGetSimilarCompaniesByKeywords } from './../core/hooks/useGetSimilarCompaniesByKeywords'
import { GET_ATLAS_VIEW_DATA } from 'store/operations/queries/GetAtlasViewData'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  chartArea: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  watchList: {
    position: 'absolute',
    top: 14,
    right: 100,
  },
}))

export enum RootName {
  fintech = 'FinTech',
  insurtech = 'InsurTech',
  out = 'Out',
  regtech = 'RegTech',
  all = 'All Industries',
}

export enum NavigateTo {
  Landscape = 'Landscape',
  Atlas = 'Atlas',
  Analytics = 'Analytics',
  ListView = 'List View',
}

export const checkProductIdAtlas = (id: number | null, businessId: number | null) => {
  return id !== businessId ? id : null
}

const compareCompany = (
  a: GetAtlasViewData_getAtlasViewData_companies,
  b: GetAtlasViewData_getAtlasViewData_companies
) => (b?.fct_score_overall || 0) - (a?.fct_score_overall || 0)

const getCount = (item: IDimensionTree, data: IDimensionTree[]) => {
  const childrenFirst = item.businessLineId
    ? data.filter(v => v.parentId == item.id && v.businessLineId == item.businessLineId)
    : data.filter(v => v.parentId == item.id)
  let count = item.size

  for (const v of childrenFirst) {
    const children = data.filter(i => i.parentId == v.id)
    if (!!children.length && !v.isShow) {
      count = count + getCount(v, data)
    } else {
      count = !v.isShow ? count + v.size : count
    }
  }

  return count
}

const compareLatestDeals = (
  a: GetAtlasViewData_getAtlasViewData_latestDeals,
  b: GetAtlasViewData_getAtlasViewData_latestDeals
) => {
  const d1 = new Date(a.deal_date || '')
  const d2 = new Date(b.deal_date || '')
  return d2.getTime() - d1.getTime()
}

const getTopCompany = (item: IDimensionTree, data: IDimensionTree[]) => {
  let companies = item.companies || []
  const children = item.businessLineId
    ? data.filter(v => v.parentId == item.id && v.businessLineId == item.businessLineId)
    : data.filter(v => v.parentId == item.id)
  if (children.length) {
    for (const v of children) {
      companies = concat(getTopCompany(v, data), companies)
    }
  }

  return companies
}

const getLatestDeals = (item: IDimensionTree, data: IDimensionTree[], elementsValid: number[]) => {
  let latestDeals = item.latestDeals || []
  const checkDealFromChildSelected = (id: number) => {
    if (elementsValid.length) return elementsValid.includes(id)
    return true
  }

  const children = item.businessLineId
    ? data.filter(
        v =>
          v.parentId == item.id &&
          v.businessLineId == item.businessLineId &&
          checkDealFromChildSelected(v.id)
      )
    : data.filter(v => v.parentId == item.id && checkDealFromChildSelected(v.id))

  if (children.length) {
    for (const v of children) {
      latestDeals = concat(getLatestDeals(v, data, elementsValid), latestDeals)
    }
  }

  return latestDeals
}

const getTotalFunding = (item: IDimensionTree, data: IDimensionTree[]) => {
  let total = item.totalFundings || 0
  const children = item.businessLineId
    ? data.filter(v => v.parentId == item.id && v.businessLineId == item.businessLineId)
    : data.filter(v => v.parentId == item.id)
  if (children.length) {
    for (const v of children) {
      total = total + getTotalFunding(v, data)
    }
  }

  return total
}

const fillCompany = (
  company: GetAtlasViewData_getAtlasViewData_companies,
  data: GetAtlasViewData_getAtlasViewData_companies
): GetAtlasViewData_getAtlasViewData_companies => {
  const newCompany = {} as GetAtlasViewData_getAtlasViewData_companies
  newCompany.logo_url = company.logo_url || data.logo_url || ''
  newCompany.name = company.name || data.name || ''
  newCompany.totalFunding = company.totalFunding || data.totalFunding || 0
  newCompany.company_id = company.company_id || data.company_id || 0
  newCompany.fct_score_overall = company.fct_score_overall || data.fct_score_overall || 0

  return newCompany
}

const fillLatestInvestments = (
  company: GetAtlasViewData_getAtlasViewData_latestDeals,
  data: GetAtlasViewData_getAtlasViewData_latestDeals
): GetAtlasViewData_getAtlasViewData_latestDeals => {
  const newLatest = {} as GetAtlasViewData_getAtlasViewData_latestDeals
  newLatest.amount = company.amount || data.amount || 0
  newLatest.company_id = company.company_id || data.company_id || 0
  newLatest.deal_date = company.deal_date || data.deal_date || ''
  newLatest.deal_id = company.deal_id || data.deal_id || ''
  newLatest.investor_names = company.investor_names || data.investor_names || ''
  newLatest.logo_url = company.logo_url || data.logo_url || ''
  newLatest.round = company.round || data.round || ''

  return newLatest
}

const selectedIdFn = (
  id: number | null,
  businessId: number | null,
  filter: IAnalysisFilter,
  filterData: GetDealFilterDataResult_getDealFilterData | null,
  node: any
) => {
  let productId: number | null = id
  let businessLineId = null
  if (filter.viewBy != GlobalFilterViewBy.product) {
    businessLineId = id
    productId = null
  }
  if (filter.viewBy == GlobalFilterViewBy.business) {
    productId = id != businessId ? id : null
    businessLineId = businessId || null
  }

  let category = filter.category
  if (
    filter.category == InternalDbCompanyTypes.all ||
    filter.category == InternalDbCompanyTypes.fintech
  ) {
    category =
      CATEGORY_TAXONOMY.get(id || 0) ||
      filterData?.products?.find(item => item.id == id)?.category ||
      filter.category
  }

  const newSelectedProductId = productId && productId > -1 ? productId : null
  let newSelectedBusinessLineId =
    id == TAXONOMY_ID.REGTECH_ID ||
    (filter.viewBy == GlobalFilterViewBy.business && category == InternalDbCompanyTypes.regtech)
      ? null
      : businessLineId

  let products: number[] | null = null,
    businessLines = null,
    valueChains: number[] | null = null,
    operatingModels = null,
    isFilteredChildOfProduct = false,
    isFilteredChildOfValueChain = false

  const taxonomyIds = [TAXONOMY_ID.FINTECH_ID, TAXONOMY_ID.REGTECH_ID, TAXONOMY_ID.INSURANCE_ID]
  if (node && node?.id !== 0 && !taxonomyIds.includes(node?.id)) {
    const childrens = (node?.children || []) as ITreeData[]
    const childrenIds = getChildrenIds(childrens)
    isFilteredChildOfProduct = childrens.some(e => filter.products?.includes(+e.id))

    // NOTE: Fintech - product
    if (node.category === ETaxonomyCategory.FinTech && !node.businessLineId) {
      products = [node?.id, ...childrenIds]
    }

    // NOTE: Fintech - business - only business
    if (
      node.category === ETaxonomyCategory.FinTech &&
      node.businessLineId &&
      node.businessLineId === node.id
    ) {
      businessLines = [node?.businessLineId]
    }

    // NOTE: Fintech - business - have business and product
    if (
      node.category === ETaxonomyCategory.FinTech &&
      node.businessLineId &&
      node.businessLineId !== node.id
    ) {
      products = [node?.id, ...childrenIds]
      businessLines = [node?.businessLineId]
    }

    // NOTE: Regtech - product
    if (node.category === ETaxonomyCategory.RegTech && !node.businessLineId) {
      products = [node?.id, ...childrenIds]
    }

    // NOTE: InsurTech - product
    if (
      node.category === ETaxonomyCategory.InsurTech &&
      filter.viewBy === GlobalFilterViewBy.product
    ) {
      products = [node?.id, ...childrenIds]
    }

    // NOTE: InsurTech - value chain
    if (
      node.category === ETaxonomyCategory.InsurTech &&
      filter.viewBy === GlobalFilterViewBy.valuechain
    ) {
      const childrenValueChainIds = getChildrenValueChainIds(node?.id, filterData?.valueChains)
      isFilteredChildOfValueChain = childrenValueChainIds.some(id =>
        filter.valueChains?.includes(id)
      )
      const arrIds = [node?.id, ...childrenValueChainIds]
      valueChains = isFilteredChildOfValueChain
        ? (filter.valueChains || []).filter(id => arrIds.includes(id))
        : arrIds

      if (isFilteredChildOfValueChain && valueChains.length === 1)
        newSelectedBusinessLineId = valueChains[0]
    }

    // NOTE: InsurTech - operating model
    if (
      node.category === ETaxonomyCategory.InsurTech &&
      filter.viewBy === GlobalFilterViewBy.operatingModel
    ) {
      operatingModels = [node?.id]
    }
  }

  const isChangeSelectedBusinessLineId =
    newSelectedBusinessLineId && newSelectedBusinessLineId !== filter.selectedBusinessLineId

  return {
    selectedProductId: newSelectedProductId,
    selectedBusinessLineId: newSelectedBusinessLineId,
    category: category,
    products:
      newSelectedProductId !== filter.selectedProductId
        ? isFilteredChildOfProduct
          ? (filter.products || []).filter(id => products?.includes(id))
          : products
        : filter.products,
    businessLines: isChangeSelectedBusinessLineId ? businessLines : filter.businessLines,
    valueChains:
      isChangeSelectedBusinessLineId && filter.viewBy === GlobalFilterViewBy.valuechain
        ? isFilteredChildOfValueChain
          ? (filter.valueChains || []).filter(id => valueChains?.includes(id))
          : valueChains
        : filter.valueChains,
    operatingModels:
      isChangeSelectedBusinessLineId && filter.viewBy === GlobalFilterViewBy.operatingModel
        ? operatingModels
        : filter.operatingModels,
    viewBy: category == InternalDbCompanyTypes.regtech ? GlobalFilterViewBy.product : filter.viewBy,
  }
}

const SunburstContainer = () => {
  const history = useHistory()
  const classes = useStyles()
  const { restricted, user } = useUserContext()
  const [filter, setAnalysisFilter] = useAnalysisFilter()
  const { isBlocked } = useBlockBeforeNavigate()
  const [confirmNavigate, setConfirmNavigate] = useState<boolean>(false)
  const [nodeActive, setNodeActive] = useState<{
    event: MouseEvent
    node: IDimensionTree
    isRoot: boolean
    route: string
  }>()

  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA)
  const filterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData
  const { data: searchDealsColumnData } = useQuery<GetSearchDealsColumns>(GET_SEARCH_DEALS_COLUMNS)
  const searchDealsColumn = searchDealsColumnData?.searchDealsColumns || []

  const companySearchFilter = useGetSearchCompanies(filter)
  const {
    similarCompaniesIds,
    loadingSimilarCompaniesByKeywords,
  } = useGetSimilarCompaniesByKeywords(filter, filterData)

  const inputAnalysis = getInputAnalysis(
    filter,
    filterData,
    companySearchFilter,
    similarCompaniesIds
  )
  const [_, setCompanyDealFilter] = useCompanyDealFilter()
  const [__, setOpenFilter] = useOpenFilter()
  const { navigateProfile } = useNavigateProfile()
  const [watchListId, ___] = useWatchListId()
  const [watchListType, ____] = useWatchListType()
  const [chipsHeader, _____] = useChipsHeader()
  const [breadcrumbs, ______] = useBreadcrumbs()

  const [isTooltip, setIsTooltip] = useState<boolean>(false)
  const [isPin, setIsPin] = useState<boolean>(false)
  const [dataTooltip, setDataTooltip] = useState<any>()
  const [companyIds, setCompanyIds] = useState<number[]>()
  const [subFilter, _______] = useSubFilter()

  const { data: queryCompanyData, loading: companyLoading } = useQuery<GetCompanyList>(
    GET_COMPANY_IDS_LIST,
    {
      variables: {
        input: {
          companyIds: companyIds || [],
          isFundingIncluded: true,
          isGeographyIncluded: true,
        },
      },
      skip: !companyIds?.length,
    }
  )

  const companyData = queryCompanyData?.getCompanyList
    ?.items as GetCompanyList_getCompanyList_items[]

  const {
    data: taxonomiesConnection,
    loading: atlasViewLoading,
    refetch: fnAtlasData,
    networkStatus,
  } = useQuery(GET_ATLAS_VIEW_DATA, {
    variables: {
      input: {
        ...inputAnalysis,
        products:
          filter.viewBy !== GlobalFilterViewBy.business &&
          filter.viewBy !== GlobalFilterViewBy.product
            ? filter.products
            : [],
        riskValueChains: filter.viewBy !== GlobalFilterViewBy.valuechain ? filter.valueChains : [],
        selectedProductId: null,
        selectedBusinessLineId: null,
        watchListId: watchListType == EWatchListType.filterConditions ? null : watchListId,
      },
    },
    skip: loadingSimilarCompaniesByKeywords,
    notifyOnNetworkStatusChange: true,
  })

  const refetchLoading = networkStatus === NetworkStatus.refetch

  const selectedId = (id: number, businessId: number | null, node: any) =>
    selectedIdFn(id, businessId, filter, filterData || null, node)

  const getInputEquityFundingByFundingAmount = () => {
    const filter: any = {
      ...inputAnalysis,
      ...selectedId(dataTooltip?.data.id, dataTooltip?.data.businessLineId, dataTooltip?.data),
      businessLines: null,
      products: null,
      riskValueChains: null,
      operatingModels: null,
      watchListId: watchListType == EWatchListType.filterConditions ? null : watchListId,
      type: PeriodType.Annually,
      megaRoundsOnly: false,
    }
    delete filter.valueChains
    return filter
  }

  const { data: queryData, loading: chartLoading } = useQuery(
    GET_EQUITY_FUNDING_BY_FUNDING_AMOUNT,
    {
      variables: {
        input: getInputEquityFundingByFundingAmount(),
      },
      skip: !isPin,
    }
  )
  const chartData = queryData?.getEquityFundingByFundingAmountChartData as GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData

  useTrackingFilterChart({
    typeName: 'Atlas_Filter',
    filter: filter,
    chips: chipsHeader,
    filterData: filterData,
  })

  useEffect(() => {
    fnAtlasData()
  }, [watchListId])

  const { data: activeNodeConnection, loading: bubbleLoading } = useQuery<GetBubbleActiveNode>(
    GET_BUBBLE_ACTIVE_NODE
  )

  const taxonomies = (taxonomiesConnection?.getAtlasViewData || []) as IDimensionTree[]

  const businessFilter = (filterData?.businessLines || []).map(v => v.id)

  const taxonomyMapping = useMemo(() => {
    const data = cloneDeep(taxonomies)
    return taxonomies
      .map(el => {
        return {
          ...el,
          size: getCount(el, data),
          category: el.id == -1 ? InternalDbCompanyTypes.insurtech : el.category,
        }
      })
      .map(v => ({
        ...v,
        size: v.isShow ? v.size : 0,
      }))
  }, [taxonomies])

  const dataFilterBreadcrumbs = React.useMemo(() => {
    const products = filter?.products || []
    const valueChains = filter?.valueChains || []
    if (
      products.length > 0 &&
      filter.viewBy != GlobalFilterViewBy.risk &&
      filter.viewBy != GlobalFilterViewBy.operatingModel &&
      filter.viewBy != GlobalFilterViewBy.valuechain
    )
      return taxonomyMapping.map((item: IDimensionTree) => {
        const isKeep =
          products.includes(item.id) &&
          (filter.selectedBusinessLineId && filter.viewBy === GlobalFilterViewBy.business
            ? item.businessLineId == filter.selectedBusinessLineId ||
              (item.businessLineId &&
                filter.selectedBusinessLineId < TAXONOMY_ID_THRESH_HOLD_CHECK &&
                !businessFilter.includes(item.businessLineId))
            : true)

        return {
          ...item,
          size: isKeep ? item.size : 0,
          companies: isKeep ? item.companies : [],
          totalFundings: isKeep ? item.totalFundings : 0,
        }
      })

    if (valueChains.length > 0 && filter.viewBy === GlobalFilterViewBy.valuechain)
      return taxonomies.map((item: IDimensionTree) => {
        const isKeep = item.id && valueChains.includes(item.id)
        return {
          ...item,
          size: isKeep ? item.size : 0,
        }
      })

    return taxonomyMapping
  }, [filter?.products, taxonomyMapping, filter.selectedBusinessLineId, filter?.valueChains])

  const dataFilter = React.useMemo(() => {
    if (!!filter.businessLines?.length && filter.viewBy === GlobalFilterViewBy.business) {
      return dataFilterBreadcrumbs.map((item: IDimensionTree) => {
        let isKeep = item.businessLineId && filter.businessLines?.includes(item.businessLineId)
        if (!!filter.businessLines?.filter(v => v < 0)?.length && !isKeep) {
          isKeep =
            item.businessLineId && businessFilter && !businessFilter.includes(item.businessLineId)
        }
        return {
          ...item,
          size: isKeep ? item.size : 0,
          companies: isKeep ? item.companies : [],
          totalFundings: isKeep ? item.totalFundings : 0,
        }
      })
    }
    return dataFilterBreadcrumbs
  }, [filter.businessLines, dataFilterBreadcrumbs])

  const activeNode = activeNodeConnection?.bubbleActiveNode

  const elementsValid = useMemo(() => {
    const mapper = {
      [GlobalFilterViewBy.product]: filter.products,
      [GlobalFilterViewBy.valuechain]: filter.valueChains,
      [GlobalFilterViewBy.business]: filter.businessLines,
      [GlobalFilterViewBy.operatingModel]: filter.operatingModels,
      [GlobalFilterViewBy.risk]: null,
    }
    return mapper[filter.viewBy]
  }, [
    filter.products,
    filter.valueChains,
    filter.businessLines,
    filter.operatingModels,
    filter.viewBy,
  ])

  const dataMapCompanies = useMemo(() => {
    const data = cloneDeep(dataFilter)
    return dataFilter.map(el => {
      const companies = getTopCompany(el, dataFilter).map(v =>
        fillCompany(v, {} as GetAtlasViewData_getAtlasViewData_companies)
      )

      const latestDeals = getLatestDeals(el, dataFilter, elementsValid || []).map(v =>
        fillLatestInvestments(v, {} as GetAtlasViewData_getAtlasViewData_latestDeals)
      )

      return {
        ...el,
        companies: companies.sort(compareCompany),
        latestDeals: latestDeals.sort(compareLatestDeals).slice(0, 5),
        totalFundings: getTotalFunding(el, data),
      }
    })
  }, [dataFilter, elementsValid])

  const updateData = React.useMemo(() => {
    if (filter.viewBy === GlobalFilterViewBy.business) {
      return TaxonomyMappingWithBusiness(dataMapCompanies)
    }
    return TaxonomyMapping(dataMapCompanies)
  }, [dataMapCompanies])

  const loading =
    atlasViewLoading || bubbleLoading || refetchLoading || loadingSimilarCompaniesByKeywords

  const navigateToDestination = (
    event: MouseEvent,
    node: IDimensionTree,
    isRoot: boolean,
    route: string = 'map'
  ) => {
    let {
      category,
      selectedProductId,
      selectedBusinessLineId,
      viewBy,
      products,
      businessLines,
      valueChains,
      operatingModels,
    } = selectedId(node.id, node.businessLineId, node)

    if (
      !isRoot &&
      filter.viewBy == GlobalFilterViewBy.valuechain &&
      !selectedProductId &&
      !selectedBusinessLineId
    )
      return

    if (!isRoot && filter.viewBy == GlobalFilterViewBy.business) {
      selectedProductId = node.id && node.id > 0 ? node.id : null
    }

    setIsTooltip(false)
    setOpenFilter(true)
    if (event) event.stopPropagation()
    if (route !== 'list-view') {
      if (route == 'analysis/charts') mixpanel.track('Analytics View')
      if (route == 'analysis/landscape') mixpanel.track('Landscape View')
      setAnalysisFilter({
        category: category,
        selectedProductId: selectedProductId,
        selectedBusinessLineId: selectedBusinessLineId,
        viewBy: viewBy,
        products: selectedProductId !== filter.selectedProductId ? products : filter.products,
        businessLines,
        valueChains,
        operatingModels,
      })

      const findTaxonomyByNodeId = updateProps ? FindTaxonomy(updateProps.data) : null
      findTaxonomyByNodeId && setBubbleActiveNode(findTaxonomyByNodeId(node.id))
    } else {
      mixpanel.track('List View', trackingTaxonomy(filter, filterData, breadcrumbs))
      navigateToExploreDatabase({
        filterData: filterData,
        filter: { ...filter, products, businessLines },
        setCompanyDealFilter,
        selectedBusinessLineId: selectedBusinessLineId,
        selectedProductId: selectedProductId,
        category: category,
        viewBy: viewBy,
      })
    }
    if (isRoot) history.push({ pathname: `/app/${route}` })
  }

  const onSetActiveNode = (
    event: MouseEvent,
    node: IDimensionTree,
    isRoot: boolean,
    route: string = 'map'
  ) => {
    if (isRoot && isBlocked) {
      setNodeActive({ event, node, isRoot, route })
      setConfirmNavigate(true)
    } else {
      navigateToDestination(event, node, isRoot, route)
    }
  }

  const handleNavigate = (
    category: InternalDbCompanyTypes,
    viewBy: GlobalFilterViewBy,
    selectedBusinessLineId: number | null,
    id: number,
    setFilter: (value: any) => void
  ) => {
    if (category !== InternalDbCompanyTypes.all) {
      const selectedIdArr = selectedBusinessLineId ? [selectedBusinessLineId] : [id]
      const products = getProductsFilterWhenSelectedId({
        selectedId: id,
        productsData: filterData?.products || [],
        productsFilter: filter?.products || [],
        category: category,
      })

      switch (viewBy) {
        case GlobalFilterViewBy.business: {
          setFilter({
            businessLines: selectedIdArr,
            products: products,
          })
          break
        }
        case GlobalFilterViewBy.product: {
          setFilter({
            products: products,
          })
          break
        }
        case GlobalFilterViewBy.operatingModel: {
          setFilter({
            operatingModels: selectedIdArr,
          })
          break
        }
        case GlobalFilterViewBy.valuechain: {
          const valueChains = getValueChainsFilterWhenSelectedId({
            selectedId: id,
            valueChainsData: filterData?.valueChains || [],
            valueChainsFilter: filter?.valueChains || [],
          })

          setFilter({
            valueChains: valueChains,
            selectedBusinessLineId: valueChains[0] || null,
          })
          break
        }
      }
    }
  }

  const onclickNavigate = (id: number, businessId: number, url: string) => {
    const { category, selectedProductId, selectedBusinessLineId, viewBy } = selectedId(
      id,
      businessId,
      null
    )

    if (url !== 'list-view') {
      history.push({ pathname: `/app/analysis/${url}` })
      setAnalysisFilter({
        category: category,
        selectedProductId: selectedProductId,
        selectedBusinessLineId: selectedBusinessLineId,
        viewBy: viewBy,
      })

      handleNavigate(category, viewBy, selectedBusinessLineId, id, setAnalysisFilter)
    } else {
      navigateToExploreDatabase({
        filterData: filterData,
        filter: filter,
        selectedBusinessLineId:
          viewBy === GlobalFilterViewBy.operatingModel ? null : selectedBusinessLineId,
        selectedProductId: selectedProductId,
        category: category,
        setCompanyDealFilter,
        viewBy: viewBy,
      })

      handleNavigate(category, viewBy, selectedBusinessLineId, id, setCompanyDealFilter)

      history.push({ pathname: `/app/${url}` })
    }
  }

  const updateProps = React.useMemo(() => {
    if (!updateData.id && updateData.name != RootName[filter.category as keyof typeof RootName])
      updateData.name = RootName[filter.category as keyof typeof RootName]
    let nodeId = filter.selectedProductId
    let parentId = null
    if (filter.viewBy != GlobalFilterViewBy.product) {
      nodeId = filter.selectedBusinessLineId
      parentId = null
    }
    if (filter.viewBy == GlobalFilterViewBy.business) {
      nodeId = filter.selectedProductId
      parentId = filter.selectedBusinessLineId
    }

    return {
      data: { ...updateData, viewBy: filter.viewBy, oldCategory: filter.category },
      nodeId: nodeId,
      parentId: parentId,
      onclickNavigate: onclickNavigate,
    }
  }, [updateData, filter.selectedProductId, filter.selectedBusinessLineId])

  const dataName = VIEW_BY[filter.category].propName as keyof IAnalysisFilter
  const payload = getCompanyInput(filter, filterData, companySearchFilter)
  const { data: getWatchListCompanyIds } = useQuery<GetWatchListCompanyIds>(
    GET_WATCH_LIST_COMPANY_IDS,
    {
      variables: {
        input: {
          ...payload,
          businessLinesRisksValueChains: filter[dataName],
          pageNumber: 0,
          pageSize: 10,
          selectedColumns: [],
          orderBy: [],
          watchListId: watchListType == EWatchListType.filterConditions ? null : watchListId,
          selectedProductId: checkProductIdAtlas(
            filter.selectedProductId,
            filter.selectedBusinessLineId
          ),
          companyIds: [...(payload.companyIds || []), ...similarCompaniesIds],
        },
      },
      skip: !history.location.pathname.includes('/sunburst'),
    }
  )

  const onActiveTooltip = (isActive: boolean, d: any) => {
    setIsTooltip(isActive)
    isActive && setDataTooltip(d)
  }

  const onPinTooltip = useCallback((e: any, d: any, filter: IAnalysisFilter) => {
    setIsPin(true)
    setDataTooltip(d)
    const data = d?.data as GetAtlasViewData_getAtlasViewData | null
    const companyIds = [
      ...(data?.companies?.slice(0, 5)?.map(e => e.company_id || 0) || []),
      ...(data?.latestDeals?.slice(0, 5)?.map(e => e.company_id || 0) || []),
    ]
    setCompanyIds(companyIds)
  }, [])

  useEffect(() => {
    if (isPin && dataTooltip)
      mixpanel.track('Atlas_Pin', {
        atlasLastEndPoint: dataTooltip?.data.name,
        atlasLastEndPoint_ID: dataTooltip?.data.id,
        industry: InternalCompanyName.get(filter.category),
        ...trackingTaxonomy(filter, filterData, breadcrumbs),
      })
  }, [isPin, dataTooltip])

  useEffect(() => {
    const root = d3
      .hierarchy(updateProps.data)
      .sum(d => d.size)
      .sort((a: any, b: any) => b.size - a.size)
    const tree = d3.partition().size([2 * Math.PI, root.height + 1])(root)
    const data = getTreeByID(tree, dataTooltip?.data?.id)
    !!data && setDataTooltip(data)
  }, [taxonomiesConnection])

  const selectedIds = (getWatchListCompanyIds?.getWatchListCompanyIds || [])?.map(e => String(e))

  return (
    <div className={classes.root}>
      {!restricted.watchlist && (
        <div className={classes.watchList}>
          <AddWatchList
            selectedList={selectedIds}
            disableFilter={!!subFilter.companyIds.length}
          ></AddWatchList>
        </div>
      )}
      <div className={classes.chartArea}>
        {!loading ? (
          <SunburstChart
            data={updateProps}
            activeNode={activeNode}
            onSelectActiveNode={onSetActiveNode}
            onActiveTooltip={onActiveTooltip}
            onPinTooltip={(e: any, d: any) => onPinTooltip(e, d, filter)}
            isPin={isPin}
          />
        ) : (
          <Loading />
        )}
        {(isTooltip || isPin) && (
          <SunburstTooltip
            data={updateProps.data}
            companyData={companyData}
            companyLoading={companyLoading}
            d={dataTooltip}
            loading={loading}
            onclickNavigate={onclickNavigate}
            navigateProfile={navigateProfile}
            isPin={isPin}
            setIsPin={setIsPin}
            filter={filter}
            setFilter={setAnalysisFilter}
            filterData={filterData}
            chartData={chartData}
            chartLoading={chartLoading}
            searchDealsColumn={searchDealsColumn}
            watchListId={watchListId}
          />
        )}
      </div>
      <ModalUpdateWatchListFilter
        confirmNavigate={confirmNavigate}
        setConfirmNavigate={setConfirmNavigate}
        navigateToDestination={() => {
          navigateToDestination(
            nodeActive?.event as MouseEvent,
            nodeActive?.node as IDimensionTree,
            nodeActive?.isRoot as boolean,
            nodeActive?.route as string
          )
        }}
      />
    </div>
  )
}

export default SunburstContainer

type P = {
  data: any
  companyData: GetCompanyList_getCompanyList_items[]
  companyLoading: boolean
  d: any
  loading: boolean
  onclickNavigate: (id: number, businessId: number, url: string) => void
  navigateProfile: (id: number | null) => void
  isPin: boolean
  setIsPin: (v: boolean) => void
  filter: IAnalysisFilter
  setFilter(state: Partial<IAnalysisFilter>): void
  filterData: GetDealFilterDataResult_getDealFilterData
  chartData:
    | GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData
    | null
    | undefined
  chartLoading: boolean
  searchDealsColumn: IColumnState[]
  watchListId: number | null
}

const SunburstTooltip = ({
  data,
  d: dataTooltip,
  loading,
  onclickNavigate,
  navigateProfile,
  isPin,
  setIsPin,
  filter,
  setFilter,
  filterData,
  chartData,
  chartLoading,
  searchDealsColumn,
  watchListId,
  companyData,
  companyLoading,
}: P) => {
  const history = useHistory()
  const [_, setCompanyDealFilter] = useCompanyDealFilter()

  useEffect(() => {
    setIsPin(false)
  }, [
    filter?.businessLines,
    filter?.valueChains,
    filter?.risks,
    filter?.products,
    filter?.tagGroupIds,
    filter?.businessModelIds,
    filter?.selectedBusinessLineId,
    filter?.selectedProductId,
    watchListId,
  ])

  const selectedId = (id: number, businessId: number, node: any) =>
    selectedIdFn(id, businessId, filter, filterData || null, node)

  const setOriginalColumns = (defaultRow: IColumn[]) => {
    const arrKeyShow = defaultRow.filter(e => e.status === true).map(e => e.key)
    const arrKeyNotShow = defaultRow.filter(e => e.status === false).map(e => e.key)
    searchDealsColumnsVar(
      searchDealsColumn.map((col: IColumnState) =>
        arrKeyShow.includes(col.key)
          ? {
              ...col,
              selected: true,
            }
          : arrKeyNotShow.includes(col.key)
          ? {
              ...col,
              selected: false,
            }
          : col
      )
    )
  }

  const handleNavigateShowMore = (
    viewBy: GlobalFilterViewBy,
    selectedBusinessLineId: number | null,
    products: (string | number)[],
    setFilter: (value: any) => void
  ) => {
    switch (viewBy) {
      case GlobalFilterViewBy.business: {
        setFilter({
          businessLines: selectedBusinessLineId ? [selectedBusinessLineId] : [],
          products: products,
        })
        break
      }
      case GlobalFilterViewBy.product: {
        setFilter({
          products: products,
        })
        break
      }
      case GlobalFilterViewBy.valuechain: {
        let valueChains: number[] = []
        if (selectedBusinessLineId) {
          valueChains = getValueChainsFilterWhenSelectedId({
            selectedId: selectedBusinessLineId,
            valueChainsData: filterData?.valueChains || [],
            valueChainsFilter: filter?.valueChains || [],
          })
        }

        setFilter({
          valueChains: valueChains,
          selectedBusinessLineId: valueChains[0] || null,
        })
        break
      }
      case GlobalFilterViewBy.operatingModel: {
        setFilter({
          operatingModels: selectedBusinessLineId ? [selectedBusinessLineId] : [],
        })
        break
      }
    }
  }

  const navigateListView = useCallback(() => {
    const TABLE_ROW = [
      { key: 'deal_amount', status: true },
      { key: 'deal_type', status: true },
      { key: 'investor', status: true },
      { key: 'announced_date', status: true },
      { key: 'acquirer', status: false },
    ]

    const { category, selectedProductId, selectedBusinessLineId, viewBy } = selectedId(
      dataTooltip?.data.id,
      dataTooltip?.data.businessLineId,
      null
    )

    setCompanyDealFilter({
      ...filter,
      category: category,
      viewBy: viewBy,
      orderByDeal: [{ field: 'announced_date', direction: 'desc' }],
      selectedProductId: selectedProductId,
      selectedBusinessLineId: selectedBusinessLineId,
    })

    const products = selectedProductId
      ? getProducts(selectedProductId as number, filterData?.products || [], category).map(e => +e)
      : []

    handleNavigateShowMore(viewBy, selectedBusinessLineId, products, setCompanyDealFilter)

    setOriginalColumns(TABLE_ROW)
    setCurrentProfileTab(ESearchTab.Funding)
    history.push({ pathname: '/app/list-view' })
  }, [dataTooltip, filter])

  const navigateAnalysis = useCallback(() => {
    const { category, selectedProductId, selectedBusinessLineId, viewBy } = selectedId(
      dataTooltip?.data.id,
      dataTooltip?.data.businessLineId,
      null
    )

    setFilter({
      category: category,
      selectedProductId: selectedProductId,
      selectedBusinessLineId: selectedBusinessLineId,
      viewBy: viewBy,
    })

    const products = selectedProductId
      ? getProducts(selectedProductId as number, filterData?.products || [], category).map(e => +e)
      : []

    handleNavigateShowMore(viewBy, selectedBusinessLineId, products, setFilter)

    history.push('/app/analysis/charts/equity-funding/by-funding-amount')
  }, [dataTooltip, filter])

  return (
    <RevampTooltip
      data={data}
      companyData={companyData}
      companyLoading={companyLoading}
      loading={loading}
      d={dataTooltip}
      onclickNavigate={onclickNavigate}
      navigateProfile={navigateProfile}
      navigateAnalysis={navigateAnalysis}
      isPin={isPin}
      setIsPin={setIsPin}
      navigateListView={navigateListView}
      filter={filter}
      filterData={filterData}
      chartData={chartData}
      chartLoading={chartLoading}
    />
  )
}

import { makeVar } from '@apollo/client'
import { IDimensionTree } from '../../types/Dimension'

export const bubbleActiveNodeVar = makeVar<IDimensionTree | null>(null)

export const taxonomiesBublePolicies = {
  bubbleActiveNode: {
    read() {
      return bubbleActiveNodeVar()
    },
  },
}

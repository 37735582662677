import { useOktaAuth } from '@okta/okta-react'
import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import config from '../../core/config/auth'
import { IDataMember } from '../../utils/WatchList'

const GetDataMembersContext = createContext<ICreateContextState>({
  dataMembers: [],
  loadingMembers: true,
})

export const useDataMembersContext = () => {
  const context = useContext(GetDataMembersContext)
  if (!context)
    throw new Error('useDataMembersContext must be used within a GetDataMembersContext.Provider')
  return context
}

export const GetDataMembersProvider = (props: { children: React.ReactNode }) => {
  const [dataMembers, setDataMembers] = useState<IDataMember[]>([])
  const [loadingMembers, setLoadingMembers] = useState<boolean>(true)

  const { oktaAuth } = useOktaAuth()
  const accessToken = oktaAuth.getAccessToken()

  const getPeopleGroup = async (token: string) => {
    setLoadingMembers(true)
    await axios
      .get(`${config.api}/members`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      })
      .then(response => {
        setDataMembers(response?.data?.data)
      })
      .catch(error => {
        console.error(error)
      })
    setLoadingMembers(false)
  }

  useEffect(() => {
    if (accessToken) {
      getPeopleGroup(accessToken)
    }
  }, [])

  return (
    <GetDataMembersContext.Provider
      value={{
        dataMembers,
        loadingMembers,
      }}
    >
      {props.children}
    </GetDataMembersContext.Provider>
  )
}

interface ICreateContextState {
  dataMembers: IDataMember[]
  loadingMembers: boolean
}

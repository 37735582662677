import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { FCTTablePagination } from 'components/Pagination/FCTTablePagination'
import { ChangeType } from 'components/Select/FCTSelect'
import theme from 'theme'
import { numberWithCommas } from 'utils/convert/number'
import { ClientUser } from '../../../containers/AdminContainer'
import FCTIcon from '../../../components/Icon/Icon'
import moment from 'moment'

const useStyles = makeStyles({
  container: {
    height: '500px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey['300'],
      borderRadius: 10,
    },
  },
  table: {},
  itemRow: {
    padding: '9px 16px !important',
  },
  tableBody: {
    height: '100%',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1,
  },
  emptyPage: {
    minHeight: 300,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  emptyText: {
    marginTop: 16,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[500],
    padding: '0 75px',
    textAlign: 'center',
  },
})

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #E7ECF3',
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
    maxWidth: 200,
  },
  body: {
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '& div:first-child': {
      padding: '2px 0px 2px 16px',
      borderBottom: '2px solid #fff',
    },
    '& div:last-child': {
      padding: '2px 0px 2px 16px',
    },
  },
}))(TableCell)

const StyledTableRow = withStyles(() => ({
  root: {
    border: '1px solid #FFF',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[50]}`,
    },
  },
}))(TableRow)

type Props = {
  data: ClientUser[]
  indexOfFirst: number
  indexOfLast: number
  pageSize: number
  pageIndex: number
  total: number
  setPageSize: (value: number) => void
  setPageIndex: (value: number) => void
  tableRow: TableRow[]
  isExpired?: boolean
}
const TableAdmin = ({
  data,
  indexOfFirst,
  indexOfLast,
  pageSize,
  pageIndex,
  total,
  setPageSize,
  setPageIndex,
  tableRow,
  isExpired = false,
}: Props) => {
  const classes = useStyles()

  return (
    <>
      {!data?.length ? (
        <div className={classes.emptyPage}>
          <FCTIcon name="emptyViews" width={120} height={120} color="#94A3B8" />
          <div className={classes.emptyText}>No Data Available</div>
        </div>
      ) : (
        <>
          <TableContainer className={classes.container}>
            <Table className={classes.table}>
              <TableHead className={classes.stickyHeader}>
                <TableRow>
                  {tableRow.map((item, idx) => (
                    <StyledTableCell align="left" key={idx}>
                      {item.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {data?.slice(indexOfFirst, indexOfLast)?.map((item: ClientUser, idx: number) => {
                  return (
                    <StyledTableRow key={idx}>
                      <StyledTableCell className={classes.itemRow}>{item?.id}</StyledTableCell>
                      <StyledTableCell className={classes.itemRow}>{item?.name}</StyledTableCell>
                      <StyledTableCell className={classes.itemRow}>{item?.email}</StyledTableCell>
                      {isExpired && (
                        <StyledTableCell className={classes.itemRow}>
                          {moment(new Date(item?.banksy_client_access_end_date as string))
                            .utc()
                            .format('DD/MM/YYYY')}
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <FCTTablePagination
            onChangeRowsPerPage={(obj: { type: ChangeType; value: string }) => {
              setPageSize(+obj.value)
              setPageIndex(1)
            }}
            paginationProps={{
              count: Math.ceil((total || 0) / (pageSize || 1)),
              page: pageIndex,
              onChange: (e, value) => {
                setPageIndex(+value)
              },
            }}
            rowsPerPage={pageSize}
            rowsPerPageOptions={NEWS_PAGINATION_INIT.pageSizeOptions}
            labelDisplayedRows={
              <>
                of <strong data-testid="tab-news-total">{numberWithCommas(total || 0)}</strong>
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default TableAdmin

export const NEWS_PAGINATION_INIT = {
  pageIndex: 1,
  pageSize: 10,
  pageSizeOptions: [5, 10, 15, 50],
}

export type TableRow = {
  name: string
}

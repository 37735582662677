import { makeStyles } from '@material-ui/core'
import { useState } from 'react'
import { FilterItem } from './FilterItem'
import FCTIcon from '../Icon/Icon'
import theme from '../../theme'

interface IListCheckBoxFilterProps {
  data: Item[]
  filterIds: string[] | number[]
  nameField: string
  setFilterData: (filter: any) => void // any type use for setAnalysisFilter and mergeDeeps
  isDescription?: boolean
  title?: string
}

const useStyles = makeStyles({
  root: {
    display: 'block',
    padding: '0 10px',
  },
  title: {
    fontSize: 12,
    color: theme.palette.grey[800],
    lineHeight: '18px',
    display: 'flex',
    padding: '0 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    marginBottom: 10,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 3,
    height: 32,
  },
  icon: {
    paddingRight: 8,
    marginLeft: 2,
  },
})

export const ListCheckBoxFilter = ({
  data,
  filterIds,
  nameField,
  setFilterData,
  isDescription,
  title,
}: IListCheckBoxFilterProps) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true)

  const setFilter = (value: any) => {
    setFilterData({ [nameField]: value?.length ? value : null })
  }

  return (
    <>
      {title && (
        <div className={classes.title} onClick={() => setIsOpen(!isOpen)}>
          {title}
          <div className={classes.icon}>
            <FCTIcon width="6" height="6" name={isOpen ? 'up' : 'down'} />
          </div>
        </div>
      )}
      <div className={classes.root}>
        {isOpen &&
          (data || []).length > 0 &&
          data?.map((item: Item, index: number) => (
            <div key={index}>
              <FilterItem
                id={item.id}
                name={item.name}
                filterIds={filterIds}
                setFilterIds={setFilter}
                isDescription={isDescription}
                description={item.description}
              />
            </div>
          ))}
      </div>
    </>
  )
}

interface Item {
  id: number | string
  name: string
  description?: string
}

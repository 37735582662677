import { makeStyles } from '@material-ui/core'
import { EXPAND_ROUND1_ID } from 'core/constants/companyFilter'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { GetDealFilterDataResult_getDealFilterData } from '__generated__/GetDealFilterDataResult'
import LinedStackedBarChart from '../components/LineStackedBarChart'
import { EChartPosition } from '../containers/DashboardChartContainer'
import { NAME_EXPORT_CHART } from '../core/constants/Chart'
import { useAnalysisFilter } from '../store/features/analysis/AnalysisHook'
import { initialAnalysisFilter } from '../store/features/analysis/AnalysisSlice'
import { useCompanyDealFilter } from '../store/features/company-deal/CompanyDealHook'
import { initCompanyDealFilter } from '../store/features/company-deal/CompanyDealSlice'
import { setCurrentProfileTab } from '../store/operations/mutations'
import { ToolTipTemplate } from '../templates/ToolTipTemplate/ToolTipTemplate'
import theme from '../theme'
import { IAnalysisFilter } from '../types/Filter'
import { ChartFootNote } from '../types/FootNote'
import getColorScale, { legendList } from '../utils/charts/colorScale'
import totalByXAxis from '../utils/charts/totalByXAxis'
import {
  ETypeChart,
  listViewMappingId,
  mappingBarTotal,
  navigateEFChartToAnalysis,
} from '../utils/CompanyFilter'
import { formatAbbreviation, formatPercent, numberWithCommas } from '../utils/convert/number'
import {
  convertTickAxisLabel,
  FieldNameDrawYAxis,
  GrowthType,
  growthTypeNameFundingAndMegaRound,
  PeriodType,
} from '../utils/convert/Title'
import { analysisMappingId, getCompareDate } from '../utils/Filter'
import formatAbbreviationFlexible from '../utils/FormatAbbreviation'
import { useNavigateProfile } from '../utils/navigationHook'
import {
  GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData,
  GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData,
  GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData_stacks,
  GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_groupInfo,
} from '../__generated__/GetEquityFundingByFundingAmount'
import { GlobalFilterViewBy } from '../__generated__/globalTypes'
import { ESearchTab } from './ExploreDBComponent/MockData'
import Legend from './Legend'
import FCTSwitch from './Switch/FCTSwitch'

const useStyles = makeStyles(() => ({
  chartContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  wrapperHeaderChart: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 31,
  },
  wrapperToggle: {
    minWidth: '129px',
  },
  text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.secondary.main,
    verticalAlign: 'middle',
    marginRight: 15,
  },
}))

const axis = {
  x: {
    label: '',
  },
  y1: {
    label: 'Funding amount ($)',
  },
}

const EFByFundingAmountChart = (props: IEFByFundingAmountChartProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { filter, isDownLoadPptx, changeBreakdownStatus = () => {} } = props
  const [_, setCompanyDealFilter] = useCompanyDealFilter()
  const [__, setAnalysisFilter] = useAnalysisFilter()
  const { navigateProfile } = useNavigateProfile()
  const {
    chartData,
    groupInfo,
  }: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData = props.data || {
    groupInfo: [],
  }

  const keys = groupInfo?.map(
    (item: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_groupInfo) =>
      `${item.id}`
  )

  const getValue = (
    d: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData,
    key: string
  ) =>
    d?.stacks?.find(
      (
        item: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData_stacks
      ) => item.id === +key
    )?.fundings || 0

  const colorScale = getColorScale(legendList)

  const {
    data,
    legendItems,
  }: {
    data: Array<
      GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData & {
        total?: number
        barIndex: number
        yearGroup: number
        lineChartCount: number
      }
    >
    legendItems: Record<'name' | 'color', string>[]
  } = React.useMemo(() => {
    //TODO: hard code waiting BE
    let data = (!chartData
      ? []
      : props.type === EChartPosition.Analysis
      ? [...chartData]
      : props.type === EChartPosition.Sunburst
      ? [...chartData.slice(7)]
      : [...chartData.slice(6)]
    )
      ?.sort(
        (
          a: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData,
          b: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData
        ) => new Date(a.from).getTime() - new Date(b.from).getTime()
      )
      .map((item, index) => {
        return {
          ...item,
          total: Number(item.total),
          barIndex: index,
          yearGroup: new Date(item.from).getUTCFullYear(),
          lineChartCount: Number(item.lineChartCount),
        }
      })

    const groupInfoIds = groupInfo?.map(e => e.id)
    return {
      //Make order groupId is the same with barChart
      legendItems:
        [...(data?.[0]?.stacks || [])]
          ?.filter(e => groupInfoIds?.includes(e.id))
          ?.sort(
            (a, b) => totalByXAxis(data, b, getValue, 'id') - totalByXAxis(data, a, getValue, 'id')
          )
          ?.map((d, index) => ({
            name: d?.name || '',
            color: colorScale(index + '') as string,
          })) || [],
      data: data,
    }
  }, [chartData])

  const getTipData = (
    d: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData,
    key: string
  ):
    | GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData_stacks
    | undefined =>
    d?.stacks?.find(
      (
        item: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData_stacks
      ) => item.id === +key
    )

  const tipLayout = (
    e: MouseEvent,
    d: any,
    type: string,
    navigate: string,
    topCompanies: string
  ) => {
    const fundings = !!type ? d.total : d.fundings
    const fundingGrowth = !!type ? d.totalToolTip.fundingGrowth : d.toolTip.fundingGrowth
    const count = !!type ? d.totalToolTip.count : d.toolTip.count
    const countGrowth = !!type ? d.totalToolTip.countGrowth : d.toolTip.countGrowth
    const name = !!type ? d.totalToolTip.name : d.toolTip.name
    const content = `
      <div class="tip-item">
        <p>Equity Funding</p>
        <span>$ ${formatAbbreviation(+fundings)}</span>
      </div>
      <div class="tip-item">
        <p>${growthTypeNameFundingAndMegaRound(props.periodType, GrowthType.Fungding)}</p>
        <span>${formatPercent(fundingGrowth)}</span>
      </div>
      <div class="tip-item">
        <p>Deal Count</p>
        <span>${numberWithCommas(count)}</span>
      </div>
      <div class="tip-item">
        <p>${growthTypeNameFundingAndMegaRound(props.periodType, GrowthType.Deals)}</p>
        <span>${formatPercent(countGrowth)}</span>
      </div>
    `
    return ToolTipTemplate(name || '', content, navigate, topCompanies)
  }

  const topCompaniesToolTipContent = (e: MouseEvent, d: any, type: string) => {
    const companies = !!type ? d.totalToolTip.companies : d.toolTip.companies
    return {
      companies: companies,
      nameList: 'Top companies <span>(By funding amount)</span>',
    }
  }

  const lineChartData = data?.map(item => {
    return {
      x: item.barIndex,
      y: item.lineChartCount,
      text: String(numberWithCommas(item.lineChartCount)),
    }
  })

  const navigationTipTopLabel = (
    data: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData,
    url: string
  ) => {
    if (url === 'list-view') {
      mappingBarTotal({
        setFilter: setCompanyDealFilter,
        filter: filter,
        dataFilter: props.filterData as GetDealFilterDataResult_getDealFilterData,
        from: data.from,
        to: data.to,
        typeChart: ETypeChart.EquityFunding,
      })
      if (props.type == EChartPosition.Dashboard)
        setCompanyDealFilter({
          viewBy: props.viewBy,
        })
      setCurrentProfileTab(ESearchTab.Funding)
    } else {
      let newFilter = filter
      if (props.type == EChartPosition.Dashboard) {
        newFilter = {
          ...initialAnalysisFilter,
          viewBy: props.viewBy as GlobalFilterViewBy,
        }
      }

      navigateEFChartToAnalysis({
        setFilter: setAnalysisFilter,
        filter: newFilter,
        filterData: props.filterData as GetDealFilterDataResult_getDealFilterData,
        from: data.from,
        to: data.to,
      })
    }
    history.push({ pathname: `/app/${url}` })
  }

  const navigationTip = (
    data: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData,
    stacks: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData_stacks,
    url: string
  ) => {
    if (url === 'list-view') {
      listViewMappingId({
        id: stacks.id as number,
        setFilter: setCompanyDealFilter,
        filter: props.filter,
        products: props.filterData?.products || [],
        resetFilter: initCompanyDealFilter,
        viewByNew: props.type === EChartPosition.Dashboard ? props.viewBy : null,
        valueChains: props.filterData?.valueChains || [],
      })

      setCompanyDealFilter({
        fundingRound2s: !!filter.fundingRound2s?.length
          ? (filter.fundingRound2s as number[])
          : props.filterData?.fundingRound2s?.map(e => e.id),
        fundingRound1s: [EXPAND_ROUND1_ID.EQUITY_FINANCING],
        fundingDateFrom: getCompareDate(filter.fundingDateFrom, data.from, 'later'),
        fundingDateTo: getCompareDate(filter.fundingDateTo, data.to, 'sooner'),
      })
      setCurrentProfileTab(ESearchTab.Funding)
    } else {
      let newFilter = filter
      if (props.type === EChartPosition.Dashboard) {
        newFilter = initialAnalysisFilter
      }
      analysisMappingId({
        id: stacks.id as number,
        setFilter: setAnalysisFilter,
        filter: newFilter,
        products: props.filterData?.products || [],
        viewByNew: props.type === EChartPosition.Dashboard ? props.viewBy : null,
        valueChains: props.filterData?.valueChains || [],
      })
      navigateEFChartToAnalysis({
        setFilter: setAnalysisFilter,
        filter: newFilter,
        filterData: props.filterData as GetDealFilterDataResult_getDealFilterData,
        from: data.from,
        to: data.to,
      })
    }
    history.push({ pathname: `/app/${url}` })
  }

  return (
    <div
      className={classes.chartContainer}
      style={{ flexDirection: props.type ? 'column-reverse' : 'column' }}
      id={NAME_EXPORT_CHART}
    >
      {props.type != EChartPosition.Sunburst && (
        <div className={classes.wrapperHeaderChart}>
          <div style={{ margin: props.type ? 'auto' : 'unset' }}>
            {props?.breakdownStatus !== false && (
              <Legend
                legendItems={legendItems}
                data={null}
                type={props.type ? 'small' : ''}
                positionChart={props.type}
                groupInfo={groupInfo}
                isTooltip={true}
                typeChart={ChartFootNote.EFByFundingAmount}
                isDownLoadPptx={props?.isDownLoadPptx}
              />
            )}
          </div>
          <div className={classes.wrapperToggle}>
            {props?.breakdownStatus !== undefined && !props?.isDownLoadPptx && (
              <>
                <span className={classes.text}>Breakdown</span>
                <FCTSwitch
                  size="small"
                  checked={props?.breakdownStatus}
                  onChange={event => changeBreakdownStatus()}
                  onClick={event => event.stopPropagation()}
                />
              </>
            )}
          </div>
        </div>
      )}
      <LinedStackedBarChart
        data={data}
        keys={keys || []}
        getValue={getValue}
        tipLayout={tipLayout}
        getTipData={getTipData}
        lineChartData={lineChartData}
        axis={axis}
        format={(v: number | string) => (!!v ? formatAbbreviation(+v) : '')}
        xTickFormat={(index: number) => {
          return convertTickAxisLabel(data[index].from, props.periodType) || ''
        }}
        yTickFormat={(value: number) => {
          return formatAbbreviationFlexible(value)
        }}
        periodTypeDisplay={props.periodType}
        lineChartLabel={props.type !== EChartPosition.Sunburst ? 'No. of deals' : undefined}
        navigationTip={navigationTip}
        navigationTipTopLabel={navigationTipTopLabel}
        typeChart={props.type}
        topCompaniesToolTipContent={topCompaniesToolTipContent}
        navigateProfile={navigateProfile}
        fieldNameDrawYAxis={FieldNameDrawYAxis.fundings}
        breakdownStatus={props.breakdownStatus}
        isDownLoadPptx={isDownLoadPptx}
        chartType={ChartFootNote.EFByFundingAmount}
        orderBarsFn={(a, b, d) => {
          return totalByXAxis(data, b, getValue, 'id') - totalByXAxis(data, a, getValue, 'id')
        }}
      />
    </div>
  )
}

export default EFByFundingAmountChart

interface IEFByFundingAmountChartProps {
  data: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData
  periodType: PeriodType
  type: EChartPosition
  filter: IAnalysisFilter
  filterData?: GetDealFilterDataResult_getDealFilterData
  viewBy?: GlobalFilterViewBy
  breakdownStatus?: boolean
  isDownLoadPptx?: boolean
  changeBreakdownStatus?: () => void
}

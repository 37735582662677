import { makeStyles } from '@material-ui/core/styles'
import { DefaultToast, ToastProps, ToastProvider } from 'react-toast-notifications'
import theme from '../../theme'
import './styles.css'

const useStyles = makeStyles(theme => ({
  custom: {
    '& .react-toast-notifications__toast--success': {
      backgroundColor: appearances['success'].bg,
    },
    '& .react-toast-notifications__toast--error': {
      backgroundColor: appearances['error'].bg,
    },
  },
}))

//TODO: wait design
export const appearances: any = {
  success: {
    text: theme.palette.grey[800],
    bg: '#D4F1E3',
  },
  error: {
    text: theme.palette.grey[800],
    bg: '#F9E7E5',
  },
  warning: {
    text: theme.palette.grey[800],
    bg: '#D4F1E3',
  },
  info: {
    text: theme.palette.grey[800],
    bg: 'white',
  },
}

export const MyCustomToast = (props: ToastProps & { children: React.ReactNode }) => {
  const classes = useStyles()
  return (
    <div className={classes.custom}>
      <DefaultToast {...props}>
        <div
          style={{
            color: appearances[props.appearance].text,
          }}
        >
          {props.children}
        </div>
      </DefaultToast>
    </div>
  )
}

export const FCTNotifications = (props: FCTNotificationProps) => {
  const classes = useStyles()
  const { children } = props
  return (
    <ToastProvider components={{ Toast: MyCustomToast }} newestOnTop={true}>
      {children}
    </ToastProvider>
  )
}

export interface FCTNotificationProps {
  children: React.ReactNode
}

import { TaxonomyFilterLabel, ETaxonomyCategory, ETaxonomyAvatar } from 'utils/FlattenEcoSystem'
import { CategoriesName, SearchFields, TagGroups } from './enums'
import { ISearchInputDetail } from './interfaces'
import { ISearchCompany } from 'store/operations/queries/local/rest/SearchCompanies'
import { TagGroupMap, TagMap } from './consts'
import { formatAbbreviation } from 'utils/convert/number'

export const keyToTagGroup = (key: string) => {
  const tagGroup = TagGroupMap[key as keyof ISearchCompany] || ''
  if ([TagGroups.Dimension, TagGroups.Tag].includes(tagGroup)) return TagGroups.Taxonomy
  else if ([TagGroups.InvestorName].includes(tagGroup)) return TagGroups.Financing
  return tagGroup
}
export const keyToTag = (key: string) => TagMap[key as keyof ISearchCompany] || ''

export const getSearchType = (option: ISearchInputDetail) => {
  switch (option.field) {
    case SearchFields.NAME:
      return (
        TaxonomyFilterLabel.get(
          (option.companyMetaData[0]?.industry || [])[0] as ETaxonomyCategory
        ) || keyToTag(option.field)
      )

    case SearchFields.INVESTOR_NAMES:
      return keyToTag(option.field)

    case SearchFields.LOCATION_COUNTRIES:
      return 'Companies in country'

    case SearchFields.LOCATION_CITIES:
      return 'Companies in city'

    case SearchFields.INDUSTRY:
    case SearchFields.FINTECH_PRODUCT:
    case SearchFields.INSURTECH_PRODUCT:
    case SearchFields.REGTECH_PRODUCT:
    case SearchFields.SECTORS:
    case SearchFields.VALUE_CHAINS:
    case SearchFields.OPERATING_MODELS:
    case SearchFields.RISKS:
    case SearchFields.LOCATION_REGION1_NAMES:
    case SearchFields.LOCATION_REGION2_NAMES:
    case SearchFields.LOCATION_REGION3_NAMES:
      return 'Companies in'

    case SearchFields.INVESTOR_TYPES:
      return 'Investor type'

    case SearchFields.ACQUISITION_TYPES:
      return 'Acquirer type'

    case SearchFields.DEAL_TYPES:
      return 'Deal type'

    case SearchFields.LATEST_DEAL_TYPE:
      return 'Latest deal type'

    default:
      return keyToTagGroup(option.field)
  }
}

export const getTitleForPreview = (option: ISearchInputDetail) => {
  switch (option.field) {
    case SearchFields.INVESTOR_TYPES:
    case SearchFields.ACQUISITION_TYPES:
      return 'Investor'

    case SearchFields.DEAL_TYPES:
    case SearchFields.LATEST_DEAL_TYPE:
      return 'Financing'

    case SearchFields.LOCATION_COUNTRIES:
    case SearchFields.LOCATION_CITIES:
      return 'Company in'

    default:
      return getSearchType(option)
  }
}

export const getTaxonomyIcon = (category: CategoriesName) => {
  switch (category) {
    case CategoriesName.BusinessLine:
      return ETaxonomyAvatar.BusinessLine
    case CategoriesName.ProductLine:
      return ETaxonomyAvatar.ProductLine
    case CategoriesName.ValueChain:
      return ETaxonomyAvatar.ValueChain
    case CategoriesName.OperatingModel:
      return ETaxonomyAvatar.OperatingModel
    default:
      return ''
  }
}

export const getNumberValue = (value: string | number, includeDollarIcon = false) => {
  const icon = includeDollarIcon ? '$ ' : ''
  return value != null ? [`${icon} ${formatAbbreviation(+value)}`] : [null]
}

export const getEntityMessage = (selectedOption: ISearchInputDetail) => {
  switch (selectedOption.field) {
    case SearchFields.INVESTOR_TYPES:
      return 'investments'
    case SearchFields.ACQUISITION_TYPES:
      return 'acquisitions'

    default:
      return 'companies'
  }
}

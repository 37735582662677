import { omit } from 'lodash'
import { GetDealFilterDataResult_getDealFilterData } from '../__generated__/GetDealFilterDataResult'
import { ICompanyDealFilter } from './../types/CompanyDealFilter'
import { IAnalysisFilter } from './../types/Filter'
import { getCompanyInput } from './CompanyFilter'
export enum IRoleKey {
  NoAccess = 'NO_ACCESS',
  Owner = 'OWNER',
  Editor = 'EDIT',
  Viewer = 'VIEW',
}

export interface IRoleBy {
  name: string
}

export type IRoleMap = {} & Record<any, IRoleBy>

export const ROLE_BY: IRoleMap = {
  NO_ACCESS: {
    name: 'No Access',
  },
  OWNER: {
    name: 'Owner',
  },
  EDIT: {
    name: 'Edit',
  },
  VIEW: {
    name: 'View',
  },
}

export const TYPE_ROLE: IAccessRole = {
  NO_ACCESS: {
    setDefault: false,
    sharedWatchlist: false,
    seeWatchlist: false,
    rename: false,
    removeWL: false,
    recoverWL: false,
    deleteWLTrash: false,
    copyWL: false,
    seeCompanies: false,
    copyCompanies: false,
    addCompanies: false,
    deleteCompanies: false,
    searchCompanies: false,
    filterCompanies: false,
    updateFilter: false,
    requestEdit: false,
  },
  OWNER: {
    setDefault: true,
    sharedWatchlist: true,
    seeWatchlist: true,
    rename: true,
    removeWL: true,
    recoverWL: true,
    deleteWLTrash: true,
    copyWL: true,
    seeCompanies: true,
    copyCompanies: true,
    addCompanies: true,
    deleteCompanies: true,
    searchCompanies: true,
    filterCompanies: true,
    updateFilter: true,
    requestEdit: false,
  },
  EDIT: {
    setDefault: true,
    sharedWatchlist: false,
    seeWatchlist: true,
    rename: true,
    removeWL: true,
    recoverWL: true,
    deleteWLTrash: true,
    copyWL: true,
    seeCompanies: true,
    copyCompanies: true,
    addCompanies: true,
    deleteCompanies: true,
    searchCompanies: true,
    filterCompanies: true,
    updateFilter: true,
    requestEdit: false,
  },
  VIEW: {
    setDefault: true,
    sharedWatchlist: false,
    seeWatchlist: true,
    rename: false,
    removeWL: true,
    recoverWL: true,
    deleteWLTrash: true,
    copyWL: true,
    seeCompanies: true,
    copyCompanies: true,
    addCompanies: false,
    deleteCompanies: false,
    searchCompanies: true,
    filterCompanies: true,
    updateFilter: false,
    requestEdit: true,
  },
}

export type IAccessRole = {} & Record<any, IAction>

export interface IAction {
  //WatchList
  setDefault: boolean
  sharedWatchlist: boolean
  seeWatchlist: boolean
  rename: boolean
  removeWL: boolean
  recoverWL: boolean
  deleteWLTrash: boolean
  copyWL: boolean
  //Companies
  seeCompanies: boolean
  copyCompanies: boolean
  addCompanies: boolean
  deleteCompanies: boolean
  searchCompanies: boolean
  filterCompanies: boolean
  updateFilter: boolean
  requestEdit: boolean
}

export interface IDataMember {
  id: string | null
  profile: IProfileMember
}

interface IProfileMember {
  email: string | null
  firstName: string | null
  lastName: string | null
}

export const parseFilterOptionWL = (filter: string) => {
  const KEY_FILTER = 'filterOption'
  try {
    const data = JSON.parse(filter)
    return (KEY_FILTER in data ? data[KEY_FILTER] : data) as IAnalysisFilter | ICompanyDealFilter
  } catch {
    return {}
  }
}

export const stringifyFilterOptionWL = (
  filter: IAnalysisFilter | ICompanyDealFilter,
  filterData: GetDealFilterDataResult_getDealFilterData | undefined
) => {
  const json = JSON.stringify({
    filterOption: omit(filter, ['searchData', 'orderByCompany', 'orderByDeal']),
    filterInput: getCompanyInput(filter, filterData),
  })
  return json
}

import { gql } from '@apollo/client'

export const UPDATE_COMPANY_WATCH_LIST = gql`
  mutation UpdateCompanyWatchlist($input: UpdateCompanyWatchlistInput!) {
    updateCompanyWatchlist(input: $input) {
      id
      new
      duplicated
    }
  }
`

import { makeStyles } from '@material-ui/core'
import Loading from 'components/Loading'
import { useState } from 'react'
import { ClientUser } from '../../containers/AdminContainer'
import BreadcrumbAdmin from './BreadcrumbAdmin'
import TableAdmin, { NEWS_PAGINATION_INIT } from './TableAdmin/TableAdmin'

type Props = {
  noExpiredUsers: ClientUser[]
  title: string
  loading: boolean
  total: number
}

const NoExpiredUser = ({ noExpiredUsers, title, loading, total }: Props) => {
  const [pageSize, setPageSize] = useState(NEWS_PAGINATION_INIT.pageSize)
  const [pageIndex, setPageIndex] = useState(NEWS_PAGINATION_INIT.pageIndex)

  //Get current data
  const indexOfLast = pageIndex * pageSize
  const indexOfFirst = indexOfLast - pageSize

  return (
    <>
      <BreadcrumbAdmin title={title} />

      {loading ? (
        <Loading style={{ margin: 40 }} />
      ) : (
        <TableAdmin
          data={noExpiredUsers || []}
          total={total}
          indexOfFirst={indexOfFirst}
          indexOfLast={indexOfLast}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageSize={pageSize}
          pageIndex={pageIndex}
          tableRow={tableRow}
        />
      )}
    </>
  )
}

export default NoExpiredUser

export const tableRow = [{ name: 'ID' }, { name: 'Name' }, { name: 'Email' }]

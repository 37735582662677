import { Tab, TabProps, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import FCTTooltip from './Tooltip/Tooltip'

export const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.grey['500'],
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.grey['800'],
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
    minWidth: '0',
    minHeight: '50px',
  },
  wrapper: {
    display: 'contents',
  },
  selected: {},
}))((props: IAntTabProps) => {
  const { tooltip, style = {} } = props

  return (
    <FCTTooltip
      title={<Typography style={{ fontSize: 12 }}>{tooltip}</Typography>}
      placement="top"
      arrow
      disableHoverListener={!tooltip}
    >
      <span style={style}>
        <Tab disableRipple {...props} />
      </span>
    </FCTTooltip>
  )
})

interface IAntTabProps extends TabProps {
  tooltip?: string
  style?: React.CSSProperties
}

import * as d3 from 'd3'
import Chart from '../../utils/Chart'
import d3tip from '../../core/lib/d3-tip'
import './styles.css'
import '../../templates/ToolTipTemplate/styleToolTip.css'
import { PeriodType } from '../../utils/convert/Title'
import { EChartPosition } from '../../containers/DashboardChartContainer'
import { debounce } from '@material-ui/core'
import landscapeIcon from '../../assets/images/icons/dashboardGreen.svg'
import listViewIcon from '../../assets/images/icons/listGreen.svg'
import analyticsIcon from '../../assets/images/icons/analyticsGreen.svg'
import { renameLogo } from '../../utils/convert/Title'
import { LIMITED_COMPANIES_STYLE_TOOLTIP, MAXIMUM_PERCENT } from '../../core/constants/Chart'
import { ChartFootNote, footNoteNumbers } from '../../types/FootNote'
import getColorScale, { legendList } from '../../utils/charts/colorScale'

const isPercentChartFn = data => data.every(el => el.total == 100)

class LinedStackedBar extends Chart {
  init({
    data,
    getValue,
    axis,
    getTipData = () => {},
    tipLayout,
    lineChartLabel,
    format = (diff, data) => diff,
    hasNote = false,
    hasNoteTopLabels = false,
    hasLabelVertical = false,
    hasTopLabel = true,
    keys,
    xTickFormat,
    yTickFormat,
    lineChartData,
    periodTypeDisplay,
    dataGroupXAxis,
    navigationTip,
    navigationTipTopLabel,
    typeChart,
    topCompaniesToolTipContent,
    navigateProfile,
    fieldNameDrawYAxis,
    isAlignLeft,
    breakdownStatus,
    isDownLoadPptx,
    chartType = ChartFootNote.Default,
    orderBarsFn,
    extraSpacing = 10,
    hasTooltip,
    scrollable = false,
  }) {
    let vis = this
    vis.data = data.data
    vis.axis = axis
    vis.getTipData = getTipData
    vis.lineChartLabel = lineChartLabel || ''

    // fix for format values
    vis.format = format
    vis.hasLabelVertical = hasLabelVertical
    vis.hasTopLabel = hasTopLabel
    vis.getValue = getValue
    vis.keys = keys
    vis.xTickFormat = xTickFormat
    vis.yTickFormat = yTickFormat
    vis.lineChartData = lineChartData
    vis.tipLayout = tipLayout
    vis.periodTypeDisplay = periodTypeDisplay
    vis.dataGroupXAxis = dataGroupXAxis
    vis.navigationTip = navigationTip
    vis.navigationTipTopLabel = navigationTipTopLabel
    vis.typeChart = typeChart
    vis.hasNote = hasNote
    vis.hasNoteTopLabels = hasNoteTopLabels
    vis.hasTooltip = hasTooltip
    vis.topCompaniesToolTipContent = topCompaniesToolTipContent
    vis.navigateProfile = navigateProfile
    vis.fieldNameDrawYAxis = fieldNameDrawYAxis
    vis.isAlignLeft = isAlignLeft
    vis.color = getColorScale(legendList)
    vis.breakdownStatus = breakdownStatus
    vis.isDownLoadPptx = isDownLoadPptx
    vis.chartType = chartType
    vis.stackOrderFn = orderBarsFn || ((a, b, d) => b[fieldNameDrawYAxis] - a[fieldNameDrawYAxis])
    vis.extraSpacing = extraSpacing
    vis.scrollable = scrollable
    vis.scrollBandwidth = scrollable ? vis.WIDTH / 12 : 0

    if (vis?.axis?.y1) {
      vis.y1AxisCall = d3.axisLeft().tickSizeOuter(0).ticks(6)
      vis.y1Axis = vis.g
        .append('g')
        .attr('class', 'y1 axis')
        .call(g =>
          g
            .append('foreignObject')
            .attr('class', 'axis-label y')
            .attr('fill', 'currentColor')
            .attr('width', '200px')
            .attr('height', '40px')
            .text(`${axis.y1.label}`)
        )
    }

    if (vis?.axis?.y2) {
      vis.y2AxisCall = d3.axisRight().tickSizeOuter(0).ticks(6)
      vis.y2Axis = vis.g
        .append('g')
        .attr('class', 'y2 axis')
        .attr('transform', `translate(${vis.WIDTH}, 0)`)
        .call(g =>
          g
            .append('foreignObject')
            .attr('class', 'axis-label y y2')
            .attr('fill', 'currentColor')
            .text(`${axis.y2.label}`)
        )
    }

    vis.BODY = vis.scrollable ? d3.select(vis.element).append('div') : vis.svg

    if (vis.scrollable) {
      // Set overflow body
      d3.select(vis.element)
        .insert('div')
        .style('width', '70px')
        .style('position', 'absolute')
        .style('background-color', '#fff')
        .style('left', '0px')
        .style('height', '100%')
        .attr('id', 'hidden-xAxis-scroll')

      vis.BODY.style('max-width', `${vis.WIDTH}px`)
        .style('overflow-x', 'scroll')
        .style('overflow-y', 'hidden')
        .style('-webkit-overflow-scrolling', 'touch')
        .attr('class', 'wrapper-svgBody')
        .attr('id', 'area-svgBody')

      vis.svgBody = vis.BODY.append('svg')
        .attr('width', 0)
        .attr('height', 0)
        .style('margin-bottom', '12px')
        .style('display', 'block')

      vis.gBody = vis.svgBody.append('g').attr('id', 'gBody')

      vis.svg
        .attr('width', vis.WIDTH)
        .attr('height', vis.HEIGHT)
        .style('position', 'absolute')
        .style('pointer-events', 'none')
        .style('z-index', 1)
    }

    //Choose a body with Scrollable decision
    vis.g = vis.scrollable ? vis.gBody : vis.g

    if (vis?.axis?.x) {
      vis.xAxisCall = d3.axisBottom().tickSize(0)
      vis.xAxisGroup = vis.g.append('g').attr('class', 'x axis invisible')
      vis.xAxisGroup
        .attr('transform', `translate(0, ${vis.HEIGHT})`)
        .call(g =>
          g
            .append('foreignObject')
            .attr('class', 'axis-label x')
            .attr('x', vis.WIDTH)
            .attr('y', 18)
            .attr('fill', 'currentColor')
            .attr('text-anchor', 'middle')
            .attr('width', '100px')
            .attr('height', '50px')
            .text(`${axis.x.label}`)
        )
    }

    vis.t = () => d3.transition().duration(1000)
    vis.x = d3.scaleBand().range([0, vis.WIDTH]).padding(0.3)
    vis.y = d3.scaleLinear().range([vis.HEIGHT, 0])
    vis.y2 = d3.scaleLinear().range([vis.HEIGHT, 0])

    vis.tip = d3tip().attr('class', 'd3-tip').html(vis.tipLayout)
    vis.svg.call(vis.tip)

    'mouseout click'.split(' ').forEach(function (e) {
      vis.element.addEventListener(
        e,
        () => {
          vis.tip.hide()
        },
        false
      )
    })
  }

  wrap(text, vis) {
    const getXAxisCoordinatesBar = barIndex => {
      let barIndex_X = vis.x(barIndex || 0)
      let bandwidth = vis.x.bandwidth()
      return bandwidth < vis.WIDTH / 12
        ? barIndex_X
        : bandwidth <= vis.WIDTH / 9
        ? barIndex_X - (vis.WIDTH / 48) * barIndex
        : bandwidth < vis.WIDTH / 7
        ? barIndex_X - (vis.WIDTH / 25) * barIndex
        : bandwidth < vis.WIDTH / 6
        ? barIndex_X - (vis.WIDTH / 18) * barIndex
        : bandwidth < vis.WIDTH / 4
        ? barIndex_X - (vis.WIDTH / 6) * barIndex
        : bandwidth > vis.WIDTH / 3
        ? barIndex_X - (vis.WIDTH / 6) * (barIndex + 1)
        : barIndex_X - Math.abs((vis.WIDTH / 2) * barIndex - vis.WIDTH / 10)
    }
    let width = vis.isAlignLeft
      ? getXAxisCoordinatesBar(1) - getXAxisCoordinatesBar(0)
      : vis.scrollable
      ? vis.scrollBandwidth * 2
      : vis.x.bandwidth() + 20
    setTimeout(() => {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr('y'),
          dy = parseFloat(text.attr('dy')),
          tspan = text
            .text(null)
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', dy + 'em')
        while ((word = words.pop())) {
          line.push(word)
          tspan.text(line.join(' '))
          if (tspan.node().getComputedTextLength() > width) {
            line.pop()
            tspan.text(line.join(' '))
            line = [word]
            tspan = text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', ++lineNumber * lineHeight + dy + 'em')
              .text(' ' + word + ' ')
          }
        }
      })
    }, 100)
  }

  update({
    data,
    keys,
    lineChartData,
    xTickFormat,
    yTickFormat,
    dataGroupXAxis,
    periodTypeDisplay,
    navigationTip,
    navigationTipTopLabel,
    typeChart,
    tipLayout,
    topCompaniesToolTipContent,
    navigateProfile,
    breakdownStatus,
    isDownLoadPptx,
    extraSpacing,
    orderBarsFn,
    scrollable,
    scrollBandwidth,
    fieldNameDrawYAxis,
    axis,
  }) {
    let vis = this
    vis.data = data || vis.data
    vis.tipLayout = tipLayout || vis.tipLayout
    vis.topCompaniesToolTipContent = topCompaniesToolTipContent || vis.topCompaniesToolTipContent
    vis.navigateProfile = navigateProfile || vis.navigateProfile
    vis.breakdownStatus = breakdownStatus !== undefined ? breakdownStatus : vis.breakdownStatus
    vis.isDownLoadPptx = isDownLoadPptx !== undefined ? isDownLoadPptx : vis.isDownLoadPptx
    vis.stackOrderFn = orderBarsFn || vis.stackOrderFn
    vis.typeChart = typeChart || vis.typeChart
    vis.scrollable = scrollable || vis.scrollable
    let limitBandwidth = width => (width > 80 ? 80 : width > 50 ? width : 50)
    vis.scrollBandwidth =
      (vis.scrollable
        ? vis.x.bandwidth() < vis.WIDTH / 12
          ? limitBandwidth(vis.WIDTH / 14)
          : limitBandwidth(vis.WIDTH / 12)
        : 0) ||
      scrollBandwidth ||
      vis.scrollBandwidth
    vis.fieldNameDrawYAxis = fieldNameDrawYAxis || vis.fieldNameDrawYAxis
    vis.axis = axis || vis.axis

    if (vis?.axis?.y1) {
      vis.y1Axis
        .select('.axis-label.y')
        .text(
          `${vis.axis.y1.label} ${isDownLoadPptx ? footNoteNumbers.get(vis.chartType)?.label : ''}`
        )
    }

    const navigateProfileAndHideTip = id => {
      vis.tip.hide('notDelay')
      vis.navigateProfile(id)
    }
    window.navigateProfileWindow = navigateProfileAndHideTip

    vis.tip = vis.hasTooltip
      ? vis.tip.html((e, d, type, id, s) => {
          if (typeof vis.tipLayout !== 'function') return ''
          let isTopLabel = type == 'top-label'
          let chartData = encodeURI(JSON.stringify(d).replaceAll("'", ''))
          let stackData = !!s ? encodeURI(JSON.stringify(s).replaceAll("'", '')) : null
          const footerTooltipContent = isTopLabel
            ? vis.topCompaniesToolTipContent(e, d, type)
            : vis.topCompaniesToolTipContent(e, s, type)

          const navigateLandscape = `<div class='tip-header-icon-wrapper'>
        <div class="tip-header-icon" onclick="onclickNavigate('${chartData}', '${stackData}', '${type}', 'analysis/landscape')">
          <img class="tip-icon" src=${landscapeIcon} alt='landscape' />
        </div>
        <div class='navigateTo'>Landscape</div>
      </div>`

          const navigateListView = `<div class='tip-header-icon-wrapper'>
        <div class="tip-header-icon" onclick="onclickNavigate('${chartData}', '${stackData}', '${type}', 'list-view')">
          <img class="tip-icon" src=${listViewIcon} alt='list-view' />
        </div>
        <div class='navigateTo'>List view</div>
      </div>`

          const navigateAnalysis = `<div class='tip-header-icon-wrapper'>
        <div class="tip-header-icon" onclick="onclickNavigate('${chartData}', '${stackData}', '${type}', 'analysis/charts')">
          <img class="tip-icon" src=${analyticsIcon} alt='analytics' />
        </div>
        <div class='navigateTo'>Analytics</div>
      </div>`

          let navigate =
            vis.typeChart === EChartPosition.InvestorProfile
              ? navigateLandscape + navigateAnalysis + navigateListView
              : vis.typeChart === EChartPosition.Dashboard
              ? navigateLandscape + navigateListView
              : `
        <div class="tip-header-icon" onclick="onclickNavigate('${chartData}', '${stackData}', '${type}', 'analysis/landscape')">
          <img class="tip-icon" src=${landscapeIcon} alt='landscape' />
        </div>
        <div class="tip-header-icon" onclick="onclickNavigate('${chartData}', '${stackData}', '${type}', 'list-view')">
          <img class="tip-icon" src=${listViewIcon} alt='list-view' />
        </div>
        `

          let topCompanies = `<div class="tip-container-company">
        <div class="tip-title">${footerTooltipContent.nameList}</div>
          ${
            !!footerTooltipContent.companies.length
              ? `<div class=${
                  footerTooltipContent.companies.length >= LIMITED_COMPANIES_STYLE_TOOLTIP
                    ? 'tip-list-company-space-between'
                    : 'tip-list-company'
                }>
                ${footerTooltipContent.companies
                  ?.map(
                    item => `
                      <div class="tip-company">
                        <div class="logo-img" onclick="navigateProfileWindow(${item.company_id})">${
                      !!item.logo_url
                        ? `<img class="tip-logo" src=${item.logo_url} alt='image' />`
                        : `<div class="tip-text-logo">${renameLogo(item.name || '')}</div>`
                    }</div>
                        <span>${item.name || ''}</span>
                      </div>`
                  )
                  .join('')}
                </div>`
              : footerTooltipContent.children || ''
          }
      </div>`
          return isTopLabel
            ? vis.tipLayout(e, d, type, navigate, topCompanies)
            : vis.tipLayout(e, s, type, navigate, topCompanies)
        })
      : vis.tip

    vis.keys = keys || vis.keys
    vis.color = getColorScale(legendList)
    vis.lineChartData = lineChartData || vis.lineChartData
    vis.dataGroupXAxis = dataGroupXAxis || vis.dataGroupXAxis
    vis.periodTypeDisplay = periodTypeDisplay || vis.periodTypeDisplay
    vis.navigationTip = navigationTip || vis.navigationTip
    vis.navigationTipTopLabel = navigationTipTopLabel || vis.navigationTipTopLabel
    vis.typeChart = typeChart || vis.typeChart
    vis.xTickFormat = xTickFormat || vis.xTickFormat
    vis.yTickFormat = yTickFormat || vis.yTickFormat
    vis.extraSpacing = extraSpacing || vis.extraSpacing

    const isPercentChart = isPercentChartFn(vis.data)

    vis.stackedData = vis.data.map(d => {
      const orderedKeys = d.stacks
        .slice()
        .sort((a, b) => vis.stackOrderFn(a, b, d))
        .map(el => el.groupId || el.id)
      let bottom = 0
      const total = isPercentChart
        ? Number(d.stacks?.map(item => item[vis.fieldNameDrawYAxis]).reduce((a, b) => a + b, 0))
        : 0

      const result = orderedKeys.map(key => {
        const value = d.stacks.find(el => (el.groupId || el.id) == key)[vis.fieldNameDrawYAxis]
        const valuePercent = isPercentChart && !!total ? (value * 100) / total : 0
        const res = [bottom, bottom + (isPercentChart ? valuePercent : value)]
        res.data = d
        res.key = key
        bottom += isPercentChart ? valuePercent : value
        return res
      })
      result.key = d.barIndex
      return result
    })

    const navigate = (chartData, stackData, type, navigate) => {
      vis.tip.hide('notDelay')
      let d = JSON.parse(decodeURI(chartData))
      let s = JSON.parse(decodeURI(stackData))
      if (type == 'top-label') vis.navigationTipTopLabel(d, navigate, this)
      else vis.navigationTip(d, s, navigate, this)
    }

    window.onclickNavigate = navigate

    if (vis?.axis?.x) {
      vis.xAxisGroup.attr('transform', `translate(0, ${vis.HEIGHT})`)
      vis.g.selectAll('.axis-label.x').attr('x', vis.WIDTH + 15)
    }
    if (vis?.axis?.y2) {
      vis.y2Axis.attr('transform', `translate(${vis.WIDTH}, 0)`)
    }

    //Set height
    const yNote = vis.hasNoteTopLabels ? 25 : 0
    const y2Note = vis.hasNoteTopLabels ? 1.2 : 1

    vis.x = vis.x.range([0, vis.WIDTH])
    vis.y = vis.y.range([vis.HEIGHT, 0])
    vis.y2 = vis.y2.range([vis.HEIGHT, vis.hasNoteTopLabels ? -10 : 0])
    vis.x.domain(vis.data.map(d => d.barIndex))
    if (!!vis.lineChartData?.length) {
      vis.y.domain([
        0,
        d3.max(vis.data, d => d?.totalVirtual || d.total) * 1.6 + (vis.extraSpacing || 0) + yNote,
      ])
      vis.y2.domain([
        -d3.max(vis.data, d => d.lineChartCount) * 2.1,
        d3.max(vis.data, d => d.lineChartCount) * y2Note,
      ])
    } else {
      vis.y.domain([
        0,
        d3.max(vis.data, d => d?.totalVirtual || d.total) + (vis.extraSpacing || 0) + yNote,
      ])
      vis.y2.domain([0, d3.max(vis.data, d => d.lineChartCount) * y2Note])
    }

    //TODO: await
    // isPercentChart &&
    //   vis.g.selectAll('.tick').attr('visibility', function (d, i) {
    //     if (d > MAXIMUM_PERCENT) return 'hidden'
    //     return 'visible'
    //   })

    const getXAxisCoordinatesBar = d => {
      let barIndex = d
      let barIndex_X = vis.x(barIndex || 0)
      let bandwidth = vis.x.bandwidth()
      let res =
        bandwidth < vis.WIDTH / 12
          ? barIndex_X
          : bandwidth <= vis.WIDTH / 9
          ? barIndex_X - (vis.WIDTH / 48) * barIndex
          : bandwidth < vis.WIDTH / 7
          ? barIndex_X - (vis.WIDTH / 25) * barIndex
          : bandwidth < vis.WIDTH / 6
          ? barIndex_X - (vis.WIDTH / 18) * barIndex
          : bandwidth < vis.WIDTH / 4
          ? barIndex_X - (vis.WIDTH / 6) * barIndex
          : bandwidth > vis.WIDTH / 3
          ? barIndex_X - (vis.WIDTH / 6) * (barIndex + 1)
          : barIndex_X - Math.abs((vis.WIDTH / 2) * barIndex - vis.WIDTH / 10)
      bandwidth > vis.WIDTH / 12 ? (res += vis.WIDTH / 32) : (res += bandwidth / 2)
      if (vis.scrollable) res = vis.getXAxisLimit(d) + vis.scrollBandwidth / 2
      return `translate(${res},0)`
    }

    if (vis?.axis?.x) {
      vis.xAxisCall.scale(vis.x).tickFormat(vis.xTickFormat)
      vis.xAxisGroup
        .transition(vis.t)
        .call(vis.xAxisCall)
        .selectAll('.tick text')
        .call(vis.wrap.bind(this), vis)
    }
    if ((vis.isAlignLeft || vis.scrollable) && vis?.axis?.x) {
      vis.xAxisCall.scale(vis.x).tickFormat(vis.xTickFormat)
      vis.xAxisGroup
        .transition(vis.t)
        .call(vis.xAxisCall)
        .selectAll('.tick')
        .attr('transform', d => getXAxisCoordinatesBar(d))
    }
    if (vis?.axis?.y1) {
      vis.y1AxisCall.scale(vis.y).tickFormat(vis.yTickFormat)
      vis.y1Axis.transition(vis.t).call(vis.y1AxisCall)
    }
    if (vis?.axis?.y2) {
      vis.y2AxisCall.scale(vis.y2)
      vis.y2Axis.transition(vis.t).call(vis.y2AxisCall)
    }
    //rotation tick text
    vis.hasLabelVertical && vis.svg.selectAll('.tick text')

    if ((!!vis.WIDTH || !!vis.data.length) && vis.scrollable) {
      let OVER_WIDTH = (vis.data.length + 1) * vis.scrollBandwidth
      let RATIO_FLAG =
        vis.WIDTH > vis.getXAxisLimit(vis.data.length - 1) + (vis.scrollBandwidth * 3) / 2
      let WIDTH_BODY =
        (RATIO_FLAG
          ? vis.WIDTH - vis.scrollBandwidth
          : vis.getXAxisLimit(vis.data.length - 1) + vis.scrollBandwidth) || vis.WIDTH

      // expand xAxis
      if (vis?.axis?.x) {
        vis.xAxisGroup.select('path.domain').remove()
        vis.xAxisGroup.call(g =>
          g
            .append('path')
            .attr('class', 'domain')
            .attr('stroke', 'currentColor')
            .attr('d', `M0.5,0.5H${WIDTH_BODY + vis.scrollBandwidth / 2}`)
        )
        vis.xAxisGroup
          .select('foreignObject.axis-label')
          .attr('x', WIDTH_BODY + (vis.axis.x.label ? vis.scrollBandwidth / 2 + 10 : 0))
          .attr('y', 28)

        if (!vis.axis.x.label) vis.svg.attr('width', vis.WIDTH + 100)
      }

      // expand body
      vis.svg.attr('height', vis.HEIGHT + (vis.MARGIN.BOTTOM * 3) / 2)
      vis.BODY.style('max-width', `${vis.WIDTH + (vis.axis.x.label ? vis.scrollBandwidth : 0)}px`)
      if (vis.svgBody) {
        vis.svgBody
          .attr('width', WIDTH_BODY + vis.scrollBandwidth / 2 + (vis.axis.x.label ? 100 : 0))
          .attr('height', vis.HEIGHT + (vis.MARGIN.BOTTOM * 3) / 2)
          .style('display', 'block')
      }

      if (vis.gBody) {
        vis.gBody.attr(
          'transform',
          `translate(${vis.x.bandwidth() < vis.WIDTH / 24 ? vis.scrollBandwidth / 4 : 0}, ${
            vis.MARGIN.TOP
          })`
        )
      }

      document.getElementById('area-svgBody')?.scrollBy({
        left: +OVER_WIDTH,
        behavior: 'smooth',
      })
    }
    vis.drawChart()
  }

  drawChart() {
    let vis = this

    //call drawXAxisGroup
    vis.drawXAxisGroup()
    //call drawBarChart
    vis.drawBarChart()
    //call drawLineChart
    vis.drawLineChart()

    //remove ticks origin
    vis.g.selectAll('.tick line').each(function (d, i) {
      if (d == 0) {
        this.remove()
      }
    })
    //small text when chart in dashboard
    if (vis.typeChart != EChartPosition.Analysis) {
      vis.g
        .selectAll('.axis-label, .axis-label-x, .y2.axis text, .y1.axis text, .x.axis text')
        .style('font-size', '12px')
      vis.g
        .selectAll('.lineText, .top-label, .bar-label, .xAxisLabelGroup, .line-chart-label')
        .style('font-size', '12px')
    }
  }
  getXAxisLimit(barIndex) {
    let vis = this
    let barIndex_X = vis.x(barIndex || 0)
    let bandwidth = vis.x.bandwidth()

    const result =
      bandwidth < vis.WIDTH / 64
        ? barIndex_X + ((vis.scrollBandwidth * 3) / 2) * barIndex
        : bandwidth < vis.WIDTH / 56
        ? barIndex_X + vis.scrollBandwidth * barIndex
        : bandwidth < vis.WIDTH / 24
        ? barIndex_X + ((bandwidth * 5) / 2) * barIndex
        : bandwidth < vis.WIDTH / 12
        ? barIndex_X + (bandwidth / 2) * barIndex
        : bandwidth <= vis.WIDTH / 9
        ? barIndex_X + (bandwidth / 12) * barIndex
        : bandwidth < vis.WIDTH / 7
        ? barIndex_X + (bandwidth / 64) * barIndex
        : bandwidth < vis.WIDTH / 6
        ? barIndex_X + (bandwidth / 36) * barIndex
        : bandwidth < vis.WIDTH / 4
        ? barIndex_X + (bandwidth / 4) * barIndex
        : bandwidth > vis.WIDTH / 3
        ? barIndex_X + (bandwidth / 3) * (barIndex + 1)
        : barIndex_X + bandwidth / 2 + 20
    return result + vis.scrollBandwidth / 3
  }

  drawXAxisGroup() {
    let vis = this
    let columnDistance = vis.getXAxisLimit(1) - vis.getXAxisLimit(0) - vis.scrollBandwidth
    let isOnlyOnePeriod = vis.dataGroupXAxis?.length === 1
    const getPositionTicksGroup = d => {
      if (vis.scrollable) {
        if (isOnlyOnePeriod) return vis.scrollBandwidth / 2
        return vis.getXAxisLimit(d.barIndexGroup) - columnDistance / 2
      }
      return vis.x(d.barIndexGroup) - vis.x(vis.dataGroupXAxis[0].barIndexGroup) / 2
    }
    if (
      vis.periodTypeDisplay === PeriodType.Quarterly ||
      vis.periodTypeDisplay === PeriodType.Monthly
    ) {
      //draw xAxisTickGroup
      vis.g
        .selectAll('line.xAxisTickGroup')
        .data(vis.dataGroupXAxis)
        .join(
          enter =>
            enter
              .append('line')
              .attr('class', 'xAxisTickGroup')
              .style('stroke', 'currentColor')
              .style('stroke-width', 0.8)
              .attr('transform', d => {
                return `translate(${getPositionTicksGroup(d)}, ${vis.HEIGHT})`
              })
              .attr('y2', 40),
          update =>
            update.attr('transform', d => {
              return `translate(${getPositionTicksGroup(d)}, ${vis.HEIGHT})`
            }),
          exit => exit.remove()
        )

      const attrXAxisLabelGroup = (d, i) => {
        if (vis.scrollable) {
          let prev = 0
          let cont = 0
          if (isOnlyOnePeriod)
            return (
              vis.getXAxisLimit(d.barIndexGroup) + vis.scrollBandwidth / 2 - vis.MARGIN.RIGHT / 2
            )
          if (i >= vis.dataGroupXAxis.length - 1) {
            prev = vis.getXAxisLimit(d.barIndexGroup)
            cont = vis.getXAxisLimit(vis.data.length - 1) + vis.scrollBandwidth + columnDistance
          } else {
            prev = vis.getXAxisLimit(d.barIndexGroup)
            cont = vis.getXAxisLimit(vis.dataGroupXAxis[i + 1]?.barIndexGroup)
          }
          return (cont - prev) / 2 + (prev - columnDistance / 2) - vis.MARGIN.RIGHT / 2
        }
        if (i <= vis.dataGroupXAxis.length - 2) {
          return (
            vis.x(d.barIndexGroup) +
            (vis.x(vis.dataGroupXAxis[i + 1].barIndexGroup) - vis.x(d.barIndexGroup)) / 2 -
            30
          )
        } else {
          return vis.x(d.barIndexGroup) + (vis.WIDTH - vis.x(d.barIndexGroup)) / 2 - 30
        }
      }

      //draw xAxisLabelGroup
      vis.g
        .selectAll('text.xAxisLabelGroup')
        .data(vis.dataGroupXAxis)
        .join(
          enter =>
            enter
              .append('text')
              .attr('class', 'xAxisLabelGroup')
              .attr('x', (d, i) => {
                return attrXAxisLabelGroup(d, i)
              })
              .attr(
                'y',
                vis.typeChart != EChartPosition.Analysis ? vis.HEIGHT + 40 : vis.HEIGHT + 55
              )
              .text(function (d) {
                return d.yearGroup
              }),
          update =>
            update
              .attr('x', (d, i) => {
                return attrXAxisLabelGroup(d, i)
              })
              .attr(
                'y',
                vis.typeChart != EChartPosition.Analysis ? vis.HEIGHT + 40 : vis.HEIGHT + 55
              )
              .text(function (d) {
                return d.yearGroup
              }),
          exit => exit.remove()
        )

      d3.select('#hidden-xAxis-scroll').style('height', '98%')
    } else {
      d3.select('#hidden-xAxis-scroll').style('height', '92%').style('width', '70px')
      vis.g.selectAll('line.xAxisTickGroup').remove()
      vis.g.selectAll('text.xAxisLabelGroup').remove()
    }
  }

  drawLineChart() {
    let vis = this
    let middlePosition = vis.scrollable ? vis.scrollBandwidth / 2 : vis.x.bandwidth() / 2
    if (!!vis.lineChartData?.length) {
      vis.line = d3
        .line()
        .x(d => (vis.scrollable ? vis.getXAxisLimit(d.x) : vis.x(d.x)) + middlePosition)
        .y(d => vis.y2(d.y))
      //draw line chart
      vis.g.selectAll('.lineChart').remove()
      vis.g
        .append('path')
        .attr('class', 'lineChart')
        .attr('fill', 'none')
        .attr('stroke', '#E2E8F0')
        .attr('stroke-miterlimit', 1)
        .attr('stroke-width', 1.5)
        .attr('d', vis.line(vis.lineChartData))
      //draw dot on line chart
      vis.g
        .selectAll('circle.lineCircle')
        .data(vis.lineChartData)
        .join(
          enter =>
            enter
              .append('circle')
              .attr('class', 'lineChart')
              .attr('r', 4)
              .attr('fill', '#94A3B8')
              .attr(
                'cx',
                d => (vis.scrollable ? vis.getXAxisLimit(d.x) : vis.x(d.x)) + middlePosition
              )
              .attr('cy', d => vis.y2(d.y)),
          update =>
            update.attr('cx', d => vis.x(d.x) + middlePosition).attr('cy', d => vis.y2(d.y)),
          exit => exit.remove()
        )
      //draw text on line chart
      vis.g
        .selectAll('.lineText')
        .data(vis.lineChartData)
        .join(
          enter =>
            enter
              .append('text')
              .attr('class', 'lineText')
              .attr('text-anchor', 'middle')
              .attr('x', d => (vis.scrollable ? vis.getXAxisLimit(d.x) : vis.x(d.x) || 0))
              .attr('y', d => vis.y2(d.y) - (vis.HEIGHT < 300 ? 10 : 20) || 0)
              .text(d => d.text)
              .attr('transform', `translate(${middlePosition}, 0)`),
          update =>
            update
              .attr('x', d => (vis.scrollable ? vis.getXAxisLimit(d.x) : vis.x(d.x) || 0))
              .attr('y', d => vis.y2(d.y) - (vis.HEIGHT < 300 ? 10 : 20) || 0)
              .text(d => d.text)
              .attr('transform', `translate(${middlePosition}, 0)`)
              .raise(),
          exit => exit.remove()
        )
      //draw line chart label
      if (vis.lineChartLabel) {
        vis.g.selectAll('.line-chart-label').remove()
        vis.g.append('g').call(g =>
          g
            .append('foreignObject')
            .attr('class', 'line-chart-label text-bold')
            .attr(
              'x',
              (vis.scrollable
                ? vis.getXAxisLimit(vis.lineChartData[vis.lineChartData?.length - 1].x)
                : vis.x(vis.lineChartData[vis.lineChartData?.length - 1].x)) + middlePosition
            )
            .attr(
              'y',
              vis.y2(
                vis.lineChartData[vis.lineChartData?.length - 1].y +
                  (vis.hasNoteTopLabels ? (vis.HEIGHT < 250 ? 25 : vis.HEIGHT < 350 ? 15 : 0) : 0)
              )
            )
            .attr('fill', 'currentColor')
            .text(`${vis.lineChartLabel}`)
        )
      }
    }
  }

  drawBarChart() {
    let vis = this
    let isOut = true
    let bandwidth = vis.x.bandwidth()
    let middlePosition = vis.scrollable ? vis.scrollBandwidth / 2 : bandwidth / 2
    const getXAxisCoordinatesBar = d => {
      let barIndex = d.barIndex
      let barIndex_X = vis.x(barIndex || 0)
      if (vis.scrollable) return vis.getXAxisLimit(barIndex)
      if (vis.isAlignLeft) {
        return bandwidth < vis.WIDTH / 12
          ? barIndex_X
          : bandwidth <= vis.WIDTH / 9
          ? barIndex_X - (vis.WIDTH / 48) * barIndex
          : bandwidth < vis.WIDTH / 7
          ? barIndex_X - (vis.WIDTH / 25) * barIndex
          : bandwidth < vis.WIDTH / 6
          ? barIndex_X - (vis.WIDTH / 18) * barIndex
          : bandwidth < vis.WIDTH / 4
          ? barIndex_X - (vis.WIDTH / 6) * barIndex
          : bandwidth > vis.WIDTH / 3
          ? barIndex_X - (vis.WIDTH / 6) * (barIndex + 1)
          : barIndex_X - Math.abs((vis.WIDTH / 2) * barIndex - vis.WIDTH / 10)
      }
      return bandwidth > vis.WIDTH / 12
        ? vis.x(d.barIndex || 0) + bandwidth / 2 - vis.WIDTH / 24
        : vis.x(d.barIndex || 0)
    }

    const getXAxisTopLabel = d => {
      let x = getXAxisCoordinatesBar(d)
      if (vis.scrollable) return x + middlePosition
      if (vis.isAlignLeft)
        return bandwidth > vis.WIDTH / 12 ? x + vis.WIDTH / 32 : x + bandwidth / 2
      return (vis.x(d.barIndex) || 0) + bandwidth / 2
    }

    const getXAxisLabel = d => {
      let x = getXAxisCoordinatesBar(d.data)
      if (vis.scrollable) return x + middlePosition
      if (vis.isAlignLeft)
        return bandwidth > vis.WIDTH / 12 ? x + vis.WIDTH / 32 : x + bandwidth / 2
      return vis.x(d.data.barIndex) + bandwidth / 2
    }

    //draw total stack label bar chart
    if (vis.hasTopLabel) {
      vis.topLabels = vis.g
        .selectAll('text.top-label')
        .data(vis.data, d => d.barIndex)
        .join(
          enter =>
            enter
              .append('text')
              .attr('class', 'top-label')
              .attr('y', d => vis.y(d?.totalVirtual || d.total) || 0)
              .attr('x', d => getXAxisTopLabel(d))
              .attr('dy', -10)
              .style('fill', '#333333')
              .attr('text-anchor', 'middle'),
          update =>
            update.call(update =>
              update
                .transition(vis.t)
                .attr('y', d => vis.y(d?.totalVirtual || d.total))
                .attr('x', d => getXAxisTopLabel(d))
            ),
          exit => exit.remove()
        )
        .text(d => vis.format(d.total, d, 'top-label'))
        .raise()

      if (vis.hasNoteTopLabels)
        vis.topLabels = vis.g
          .selectAll('text.top-label-note')
          .data(vis.data, d => d.barIndex)
          .join(
            enter =>
              enter
                .append('text')
                .attr('class', 'top-label top-label-note')
                .attr('y', d => vis.y(d?.totalVirtual || d.total) - 20)
                .attr('x', d => getXAxisTopLabel(d))
                .attr('dy', -10)
                .style('fill', '#333333')
                .attr('text-anchor', 'middle'),
            update =>
              update.call(update =>
                update
                  .transition(vis.t)
                  .attr('y', d => vis.y(d?.totalVirtual || d.total) - 20)
                  .attr('x', d => getXAxisTopLabel(d))
              ),
            exit => exit.remove()
          )
          .text(d => vis.format(d.total, d, 'top-label-note'))
          .raise()

      //event tooltip for top-label bar chart

      const tooltipTop = x => {
        x.data(vis.data, d => d.barIndex)
          .on('mouseout', function (e, d) {
            isOut = true
            return vis.tip.hide()
          })
          .on('mouseover', function (e, d) {
            isOut = false
          })
          .on(
            'mousemove',
            debounce(function (e, d) {
              if (isOut) return null
              if (d) {
                return vis.tip.show(e, d, 'top-label', `${d.total}${d.from}`, this)
              }
              return vis.tip.hide()
            }, 300)
          )
      }

      const navigationTipTop = x => {
        x.data(vis.data, d => d.barIndex).on('click', function (e, d) {
          vis.tip.hide('notDelay')
          vis.navigationTipTopLabel(d, 'list-view', this)
        })
      }

      if (vis.tipLayout) {
        tooltipTop(vis.g.selectAll('text.top-label'))
        tooltipTop(vis.g.selectAll('text.top-label-note'))
      }

      if (vis.typeChart == EChartPosition.Analysis) {
        navigationTipTop(vis.g.selectAll('text.top-label'))
        navigationTipTop(vis.g.selectAll('text.top-label-note'))
      }
    }

    vis.barChart = vis.g
      .selectAll('g.barChart')
      .data(vis.stackedData)
      .join(
        enter => enter.append('g').attr('class', 'barChart'),
        update => update,
        exit => exit.remove()
      )

    const getBarWidth = () => {
      if (vis.scrollable) return vis.scrollBandwidth
      return vis.typeChart == EChartPosition.Sunburst
        ? vis.WIDTH / 8
        : vis.x.bandwidth() < vis.WIDTH / 12
        ? vis.x.bandwidth()
        : vis.isAlignLeft
        ? vis.WIDTH / 16
        : vis.WIDTH / 12
    }
    //draw rect bar chart
    vis.rects = vis.barChart
      .selectAll('rect.bar')
      .data(d => d)
      .join(
        enter =>
          enter
            .append('rect')
            .attr('class', 'bar')
            .attr(
              'x',
              (d, i) =>
                getXAxisCoordinatesBar(d.data, i) -
                (vis.typeChart == EChartPosition.Sunburst ? 8 : 0)
            )
            .attr('y', d => vis.y(d[1]) || 0)
            .attr('height', (d, i) => {
              //remove line between stack bar chart when breakdown is true
              if (vis.breakdownStatus === false && i !== 0 && d[0] && d[1])
                return vis.y(d[0]) + 1 - vis.y(d[1])
              return vis.y(d[0]) - vis.y(d[1])
            })
            .attr('width', () => getBarWidth())
            .attr('fill', (d, index) => {
              if (vis.breakdownStatus === false) return '#7FD6AC'
              return vis.color('' + index)
            }),
        update =>
          update.call(update =>
            update
              .transition(vis.t)
              .attr(
                'x',
                (d, i) =>
                  getXAxisCoordinatesBar(d.data, i) -
                  (vis.typeChart == EChartPosition.Sunburst ? 8 : 0)
              )
              .attr('y', d => vis.y(d[1]) || 0)
              .attr('height', (d, i) => {
                //remove line between stack bar chart when breakdown is true
                if (vis.breakdownStatus === false && i !== 0 && d[0] && d[1])
                  return vis.y(d[0]) + 1 - vis.y(d[1])
                return vis.y(d[0]) - vis.y(d[1])
              })
              .attr('width', () => getBarWidth())
              .attr('fill', (d, index) => {
                if (vis.breakdownStatus === false) return '#7FD6AC'
                return vis.color('' + index)
              })
          ),
        exit => exit.remove()
      )
      .on('mouseout', function (e, d) {
        isOut = true
        return vis.tip.hide()
      })
      .on('mouseover', function (e, d) {
        isOut = false
      })
      .on(
        'mousemove',
        debounce(function (e, d) {
          if (isOut) return null
          //check to show tooltip for total
          if (vis.breakdownStatus === false) {
            if (d?.data) {
              return vis.tip.show(e, d?.data, 'top-label', `${d?.data.total}${d?.data.from}`, this)
            }
            return vis.tip.hide()
          }
          const data = vis.getTipData(d.data, d.key)
          if (data && vis.hasTooltip)
            return vis.tip.show(
              e,
              d.data,
              null,
              `${data.id}${data.from || data.year}${
                data.toolTip?.name || (data.companies && data.companies[1]?.company_id)
              }`,
              data,
              this
            )
          return vis.tip.hide()
        }, 300)
      )

    if (vis.typeChart == EChartPosition.Analysis) {
      vis.rects.on('click', function (e, d) {
        vis.tip.hide('notDelay')
        if (!vis.breakdownStatus) vis.navigationTipTopLabel(d.data, 'list-view', this)
        else {
          const data = vis.getTipData(d.data, d.key)
          vis.navigationTip(d.data, data, 'list-view', this)
        }
      })
    }

    //draw stack item label bar chart
    vis.labels = vis.barChart
      .selectAll('text.bar-label')
      .data(d => d)
      .join(
        enter =>
          enter
            .append('text')
            .attr('class', 'bar-label')
            .style('fill', '#ffffff')
            .attr('x', d => getXAxisLabel(d))
            .attr(
              'y',
              d => vis.y(d[1]) + (vis.y(d[0]) - vis.y(d[1])) / 2 - (vis.hasNote ? 8 : 0) || 0
            )
            .attr('dy', 6)
            .attr('text-anchor', 'middle'),
        update =>
          update.call(update =>
            update
              .transition(vis.t)
              .attr('x', d => getXAxisLabel(d))
              .attr(
                'y',
                d => vis.y(d[1]) + (vis.y(d[0]) - vis.y(d[1])) / 2 - (vis.hasNote ? 8 : 0) || 0
              )
          ),
        exit => exit.remove()
      )
      .style('display', d =>
        vis.y(d[0]) - vis.y(d[1]) < (vis.hasNote ? 34 : 15) ||
        (vis.x.bandwidth() < 20 && !vis.scrollable)
          ? 'none'
          : 'inline'
      )
      .text(d => {
        if (vis.breakdownStatus === false) return ''
        return vis.format(d[1] - d[0], d)
      })

    //draw stack item label bar chart note
    if (vis.hasNote) {
      vis.labels = vis.barChart
        .selectAll('text.bar-label-note')
        .data(d => d)
        .join(
          enter =>
            enter
              .append('text')
              .attr('class', 'bar-label-note')
              .attr(
                'y',
                d =>
                  vis.y(d[1]) +
                  (vis.y(d[0]) - vis.y(d[1])) / 2 +
                  (vis.x.bandwidth() < 60 ? 3 : 6 || 0)
              )
              .attr('x', d => vis.x(d.data.barIndex) + vis.x.bandwidth() / 2 || 0)
              .attr('dy', 6)
              .attr('text-anchor', 'middle'),
          update =>
            update.call(update =>
              update
                .transition(vis.t)
                .attr(
                  'y',
                  d =>
                    vis.y(d[1]) +
                    (vis.y(d[0]) - vis.y(d[1])) / 2 +
                    (vis.x.bandwidth() < 60 ? 3 : 6 || 0)
                )
                .attr('x', d => vis.x(d.data.barIndex) + vis.x.bandwidth() / 2 || 0)
            ),
          exit => exit.remove()
        )
        .style('font-size', d => {
          if (vis.x.bandwidth() < 50) {
            vis.g.selectAll('.bar-label').style('font-size', '10px')
            return '10px'
          }
          if (vis.x.bandwidth() < 60) {
            vis.g.selectAll('.bar-label').style('font-size', '12px')
            return '12px'
          }
          vis.g.selectAll('.bar-label').style('font-size', '14px')
          return '14px'
        })
        .style('display', d =>
          vis.y(d[0]) - vis.y(d[1]) < 34 || (vis.x.bandwidth() < 37 && !vis.scrollable)
            ? 'none'
            : 'inline'
        )
        .text(d => {
          if (vis.breakdownStatus === false) return
          return vis.format(d[1] - d[0], d, 'note')
        })
    }
  }

  clean() {}
}

export default LinedStackedBar

import { gql } from '@apollo/client'

export const GET_MY_WATCH_LIST = gql`
  query GetMyWatchlist {
    getMyWatchlist {
      watchlistId
      watchlistName
      createdTime
      numOfCompanies
      isDefault
      isSubcribePlatform
      isSubcribeEmail
      auditTimestamp
      filterOption
    }
  }
`

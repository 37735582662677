import { gql } from '@apollo/client'

export const GET_COMPANY_LIST_VIEW_DATA = gql`
  query CompanyListViewQueryResult($input: CompanyListViewQueryInput!) {
    getCompanyListViewData(input: $input) {
      total
      items {
        company_id
        name
        description
        industry
        long_description
        hasEquityRound
        founded_year
        status
        closed_date
        numEmployees
        logo_url
        phone_number
        contact_email
        facebook_url
        linkedin_url
        twitter_url
        country_name
        city
        region1_name
        region2_name
        region3_name
        latestDealDate
        latestDealAmount
        latestDealType
        equity_funding_amount
        total_funding_amount
        total_funding_round
        finTechBussinessLine
        issueTechValueChainL1
        issueTechValueChainL2
        issueTechOperatingModel
        number_of_investors
        finTech_type
        distribution_model
        website_url
        tag_name
        went_public_on
        other_office_locations
        similar_companies
        fct_score_overall
        fct_score_total_equity_funding
        fct_score_ftes
        fct_score_have_exit
        fct_score_portfolio
        fct_score_news_volume
        fct_score_sentiment
        aliases
        distance
        products {
          dimension_id
          dimension_name
          parent_dimension_id
          level
        }
        acquisitions {
          acquirer_name
          acquirer_type
          acquisition_date
        }
        teams {
          name
          image_url
          title_name
          title_type_name
          linkedin
        }
        categories {
          company_id
          category_name
        }
        leadInvestors {
          funding_id
          investor_name
          investor_type
        }
        investors {
          funding_id
          investor_name
          investor_type
        }
      }
    }
  }
`

export const GET_TOTAL_COMPANY_LIST_VIEW_DATA = gql`
  query TotalCompanyListViewQueryResult($input: CompanyListViewQueryInput!) {
    getTotalCompanyListViewData(input: $input) {
      totalCompanies
      totalByDistance {
        distance
        total
      }
    }
  }
`

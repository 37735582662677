import { HAVING_COUNT_FIELDS } from '../consts'
import { SearchFields } from '../enums'
import { ISearchInputDetail } from '../interfaces'
import { CompanyOverviewSection } from './CompanyOverviewSection'
import { DealTypeDataSection } from './DealTypeSection'
import { InvestorOverviewSection } from './InvestorOverviewSection'
import { OtherDataSection } from './OtherDataSection'

export const OverviewSection = ({
  option,
  onNavigation,
  onNavigateTopResults,
}: {
  option: ISearchInputDetail | null
  onNavigation: () => void
  onNavigateTopResults: (value: any) => void
}) => {
  if (!option) return <></>

  if (HAVING_COUNT_FIELDS.includes(option.field as SearchFields))
    return (
      <OtherDataSection
        onClickSearch={onNavigation}
        onNavigateTopResults={onNavigateTopResults}
        option={option}
      />
    )

  switch (option.field) {
    case SearchFields.NAME:
      return <CompanyOverviewSection onNavigation={onNavigation} option={option} />

    case SearchFields.INVESTOR_NAMES:
      return <InvestorOverviewSection onNavigation={onNavigation} option={option} />

    case SearchFields.DEAL_TYPES:
    case SearchFields.LATEST_DEAL_TYPE:
      return <DealTypeDataSection onClickSearch={onNavigation} option={option} />

    default:
      return <></>
  }
}

import { makeStyles, Theme } from '@material-ui/core'
import { useRef, useState } from 'react'
import { FCTDialog, ETypeDialog, FCTDialogProps } from '../Dialog/FCTDialog'
import PrivacyDocument, { IPrivacyDocumentProps } from './PrivacyDocument'
import { EDocumentTemplate } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  textNote: {
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.grey[600],
  },
}))

type Props = {
  open: boolean
  setOpen(v: boolean): void
  handleAgree(): void
} & Pick<FCTDialogProps, 'titleBtnCancel' | 'childrenFooter' | 'handleCancel'> &
  Partial<Pick<IPrivacyDocumentProps, 'template'>>

export default function PrivacyDialog({
  open,
  setOpen,
  handleAgree,
  titleBtnCancel,
  childrenFooter,
  handleCancel = () => {},
  template = EDocumentTemplate.CLIENT,
}: Props) {
  const classes = useStyles()
  const [disableAgree, setDisableAgree] = useState(true)
  const [loadingBtnAgree, setLoadingBtnAgree] = useState(false)
  const [loadingBtnCancel, setLoadingBtnCancel] = useState(false)

  const ref = useRef<any>(null)

  const checkScrollFinal = (ref: any) => {
    const isEnd =
      ref.current?.scrollTop >= ref.current?.scrollHeight - ref.current?.clientHeight - 200
    isEnd && setDisableAgree(false)
  }

  return (
    <>
      <FCTDialog
        open={open}
        setOpen={(value: boolean) => setOpen(value)}
        title="Privacy Policy"
        type={ETypeDialog.Default}
        titleBtnAgree="I Agree"
        titleBtnCancel={titleBtnCancel || 'Back'}
        handleAgree={async () => {
          try {
            setLoadingBtnAgree(true)
            await handleAgree()
          } finally {
            setLoadingBtnAgree(false)
          }
        }}
        handleCancel={async () => {
          try {
            setLoadingBtnCancel(true)
            await handleCancel()
          } finally {
            setLoadingBtnCancel(false)
          }
        }}
        loadingBtnAgree={loadingBtnAgree}
        disableBtnAgree={disableAgree}
        loadingBtnCancel={loadingBtnCancel}
        minHeight={30}
        itemStyle={{ width: 750, height: 620, position: 'relative' }}
        childrenFooter={
          childrenFooter || (
            <div className={classes.textNote}>* Scroll to read more and continue</div>
          )
        }
        hasCloseOutside={false}
        hasButtonClose={false}
        DialogContentProps={{
          ref,
          onScroll: e => checkScrollFinal(ref),
        }}
      >
        <PrivacyDocument template={template} />
      </FCTDialog>
    </>
  )
}

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Button, ClickAwayListener, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Replay } from '@material-ui/icons'
import IPOItem from 'components/ExploreDBComponent/IPOItem'
import { omit } from 'lodash'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import FCTButton from '../components/Button/FCTButton'
import { CustomPopover } from '../components/CustomPopover/CustomPopover'
import { ETypeDialog, FCTDialog } from '../components/Dialog/FCTDialog'
import FinancingItem from '../components/ExploreDBComponent/FinancingItem'
import InvestorItem from '../components/ExploreDBComponent/InvestorItem'
import SmartClusterItem from '../components/ExploreDBComponent/SmartClusterItem'
import MAItem from '../components/ExploreDBComponent/M&AItem'
import OverviewItem from '../components/ExploreDBComponent/OverviewItem'
import { AccordionItem } from '../components/Filters/AccordionItem'
import { GeographyFilter } from '../components/Filters/GeographyFilter'
import FCTIcon from '../components/Icon/Icon'
import { IRealValue } from '../components/Slider/FCTSlider'
import { FCTTooltipMove } from '../components/Tooltip/TooltipMove'
import { EWatchListType } from '../components/WatchList/SelectTabWatchList'
import { VIEW_BY } from '../core/constants/Filter'
import useGetSearchCompanies from '../core/hooks/useGetSearchCompanies'
import { GetWatchListContext } from '../features/watch-list/GetWatchList'
import { MAX_COMPANY } from '../module/WatchList/AddWatchList'
import { useAnalysisFilter } from '../store/features/analysis/AnalysisHook'
import { useCompanyDealFilter, useSubFilter } from '../store/features/company-deal/CompanyDealHook'
import {
  initCompanyDealFilter,
  initSubFilter,
} from '../store/features/company-deal/CompanyDealSlice'
import { useOpenOptionFilter } from '../store/features/filter/FilterHook'
import {
  useWatchListFilter,
  useWatchListId,
  useWatchListName,
  useWatchListType,
} from '../store/features/watch-list/WatchListHook'
import { UPDATE_WATCH_LIST_FILTER_CONDITIONS } from '../store/operations/mutations/UpdateWatchlist'
import { GET_DEAL_FILTER_DATA } from '../store/operations/queries/GetDealFilterData'
import { GET_WATCH_LIST_COMPANY_IDS } from '../store/operations/queries/GetWatchListCompanyIds'
import { EMessageType, WatchListMessage } from '../templates/WatchListHandling/WatchListMessage'
import { WatchListToastsError } from '../templates/WatchListHandling/WatchListToastsError'
import {
  EToastType,
  WatchListToastsSuccess,
} from '../templates/WatchListHandling/WatchListToastsSuccess'
import theme from '../theme'
import { ICompanyDealFilter } from '../types/CompanyDealFilter'
import { CountrySortBy } from '../types/Filter'
import { commonErrorHandler } from '../utils/CommonErrorHandler'
import { getCompanyInput } from '../utils/CompanyFilter'
import { useWatchListFilterChanged } from '../utils/navigationHook'
import { stringifyFilterOptionWL } from '../utils/WatchList'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '../__generated__/GetDealFilterDataResult'
import { GetWatchListCompanyIds } from '../__generated__/GetWatchListCompanyIds'
import { useChipsHeader } from 'store/features/analysis/AnalysisHook'

const useStyles = makeStyles(theme => ({
  button: {
    verticalAlign: 'baseline',
    minWidth: 32,
    height: 40,
  },
  update: {
    minWidth: 150,
    marginLeft: 20,
    textTransform: 'unset',
  },
  updateDisabled: {
    minWidth: 150,
    marginLeft: 20,
    opacity: '0.7',
    textTransform: 'unset',
  },
  reset: {
    color: '#FF9F19',
    textTransform: 'unset',
  },
  filterIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: -8,
  },
  filterTitle: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#fff',
    marginLeft: '8px',
    fontWeight: 600,
  },
  closeFilter: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    cursor: 'pointer',
  },
  dialogContent: {
    padding: 20,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

const FilterSearchContainer = styled('div')`
  width: 320px;
  box-sizing: border-box;
`

export const DEFAULT_DATA_MAX = 0

const EDCompanyFilterContainer = (props: { isWatchList: boolean }) => {
  const [chips] = useChipsHeader()
  const showSmartCluster = useMemo(() => {
    return chips.map(item => item.value).includes('Smart Cluster')
  }, [chips])
  const classes = useStyles()
  const childRef = useRef<any>()
  const [companyFilter, setCompanyDealFilter] = useCompanyDealFilter()
  const [
    companyFilterData,
    setCompanyFilterData,
  ] = React.useState<GetDealFilterDataResult_getDealFilterData>()
  const [openOption, setOpenOptionFilter] = useOpenOptionFilter()
  const setOpenOption = (value: boolean, tag: string) => {
    setOpenOptionFilter({ [tag]: value })
  }
  const [_______, setSubFilter] = useSubFilter()

  const client = useApolloClient()

  const [watchListId, _] = useWatchListId()
  const [watchListName, __] = useWatchListName()
  const [watchlistType, ___] = useWatchListType()
  const [watchlistFilter, setWatchListFilter] = useWatchListFilter()
  const [____, setAnalysisFilter] = useAnalysisFilter()
  const { watchListFilterChanged } = useWatchListFilterChanged()
  const { addToast } = useToasts()
  const getWatchList = useContext(GetWatchListContext)
  const [confirmUpdate, setConfirmUpdate] = useState(false)
  const [updateWatchlistFilterConditions, { loading: updateLoading }] = useMutation(
    UPDATE_WATCH_LIST_FILTER_CONDITIONS
  )
  const history = useHistory()
  const companySearchFilter = useGetSearchCompanies(companyFilter)

  useEffect(() => {
    const fetchData = async () => {
      const res = await client.query({
        query: GET_DEAL_FILTER_DATA,
        variables: {
          input: { countrySortBy: CountrySortBy.countCompany },
        },
      })
      const companyFilterData = res?.data?.getDealFilterData
        ?.data as GetDealFilterDataResult_getDealFilterData

      setCompanyFilterData(companyFilterData)
      setCompanyDealFilter({
        numberOfInvestorsRanges: {
          end: +companyFilter.numberOfInvestorsRanges.end
            ? companyFilter.numberOfInvestorsRanges.end
            : String(companyFilterData?.maxNumOfInvestors || DEFAULT_DATA_MAX),
        } as IRealValue,
        numberOfFundingRounds: {
          end: +companyFilter.numberOfInvestorsRanges.end
            ? companyFilter.numberOfFundingRounds.end
            : String(companyFilterData?.maxNumOfFundingRounds || DEFAULT_DATA_MAX),
        } as IRealValue,
      })
    }

    fetchData()
  }, [])

  const handleChange = (evt: React.SyntheticEvent) => {
    const target = evt.target as HTMLInputElement
    if (target) {
      const value = target.type === 'checkbox' ? target.checked : target.value
      setCompanyDealFilter({ [target.name]: value })
    }
  }

  const resetFilters = () => {
    if (watchListId && watchlistType == EWatchListType.filterConditions) {
      setCompanyDealFilter(watchlistFilter)
      return
    }
    if (childRef?.current?.reset) {
      childRef.current.reset()
    }
    setSubFilter(initSubFilter)
    setCompanyDealFilter({
      ...initCompanyDealFilter,
      numberOfInvestorsRanges: {
        start: '0',
        end: String(companyFilterData?.maxNumOfInvestors || DEFAULT_DATA_MAX),
      },
      numberOfFundingRounds: {
        start: '0',
        end: String(companyFilterData?.maxNumOfFundingRounds || DEFAULT_DATA_MAX),
      },
    })
  }

  const dataName = VIEW_BY[companyFilter.category].propName as keyof ICompanyDealFilter
  const {
    data: getWatchListCompanyIds,
    loading: companyIdsLoading,
  } = useQuery<GetWatchListCompanyIds>(GET_WATCH_LIST_COMPANY_IDS, {
    variables: {
      input: {
        ...getCompanyInput(companyFilter, companyFilterData, companySearchFilter),
        businessLinesRisksValueChains: companyFilter[dataName],
        pageNumber: 0,
        pageSize: 10,
        selectedColumns: [],
        orderBy: [],
        watchListId: null,
      },
    },
  })

  const selectedIds = (getWatchListCompanyIds?.getWatchListCompanyIds || [])?.map(e => String(e))

  const hasUpdate =
    watchListFilterChanged && selectedIds.length <= MAX_COMPANY && !companyIdsLoading

  const handleAgree = () => {
    updateWatchlistFilterConditions({
      variables: {
        input: {
          watchlistId: watchListId,
          filterOption: stringifyFilterOptionWL(companyFilter, companyFilterData),
          companyIds: selectedIds.map(e => +e),
        },
      },
    })
      .then(res => {
        setConfirmUpdate(false)
        setWatchListFilter(omit(companyFilter, ['searchData', 'orderByCompany', 'orderByDeal']))
        addToast(
          <WatchListToastsSuccess
            type={EToastType.UpdateWatchListFilter}
            watchListName={watchListName || ''}
          />,
          {
            appearance: 'success',
          }
        )
        getWatchList.fnWatchList()
      })
      .catch(
        commonErrorHandler((err: any) => {
          addToast(<WatchListToastsError res={err.graphQLError.message} />, {
            appearance: 'error',
          })
        })
      )
  }

  const handleClickUpdate = () => {
    const currentPath = history.location.pathname
    if (currentPath.includes('list-view')) {
      setConfirmUpdate(true)
    }
    if (currentPath.includes('watch-lists')) {
      setAnalysisFilter(omit(companyFilter, ['orderByCompany', 'orderByDeal']))
      history.push('analysis/sunburst')
    }
  }

  const setFilter = (value: Partial<ICompanyDealFilter>) => {
    let newFilter = {}
    if (value.hasOwnProperty('products') && !value.products?.length) {
      newFilter = { ...value, selectedProductId: null }
    } else if (value.hasOwnProperty('businessLines') && !value.businessLines) {
      newFilter = { ...value, selectedBusinessLineId: null, selectedProductId: null }
    } else if (value.hasOwnProperty('valueChains') && !value.valueChains?.length) {
      newFilter = { ...value, selectedBusinessLineId: null }
    } else {
      newFilter = value
    }
    setCompanyDealFilter(newFilter)
  }

  const renderButtonListView = (
    <FCTButton
      variant="outlined"
      color="primary"
      className={classes.button}
      onClick={() => setOpenOptionFilter({ filter: !openOption.filter })}
      startIcon={
        <div className={classes.filterIcon}>
          <FCTIcon name="filter" width={16} height={16} color="#fff" />
          <Typography className={classes.filterTitle}>Filter</Typography>
        </div>
      }
    ></FCTButton>
  )

  const renderButtonWatchList = (
    <FCTTooltipMove text="Filter" isMove={false}>
      <FCTButton
        variant="outlined"
        className={classes.button}
        onClick={() => setOpenOptionFilter({ filter: !openOption.filter })}
      >
        <FCTIcon name="filter" width={16} height={16} color={theme.palette.primary.light} />
      </FCTButton>
    </FCTTooltipMove>
  )

  const popoverHeader = (
    <>
      <div
        className={classes.closeFilter}
        onClick={() => setOpenOptionFilter({ filter: !openOption.filter })}
      >
        <FCTIcon name="closeFilter" />
        <Typography style={{ fontSize: '14px', marginLeft: '8px' }}>Filter</Typography>
      </div>
      {watchListId && watchlistType === EWatchListType.filterConditions && (
        <Button
          className={hasUpdate ? classes.update : classes.updateDisabled}
          variant="text"
          startIcon={<FCTIcon name="update" color="#29BA74" />}
          onClick={() => {
            hasUpdate && handleClickUpdate()
          }}
        >
          Update Watchlist
        </Button>
      )}

      <Button
        className={classes.reset}
        variant="text"
        startIcon={<Replay />}
        onClick={() => {
          resetFilters()
          if (childRef?.current?.reset) {
            childRef.current.reset()
          }
        }}
      >
        Reset
      </Button>
      <FCTDialog
        open={confirmUpdate}
        setOpen={(value: boolean) => setConfirmUpdate(value)}
        title="Update Watchlist"
        type={ETypeDialog.Update}
        titleBtnAgree="Update"
        handleAgree={handleAgree}
        minHeight={30}
        loadingBtnAgree={updateLoading}
      >
        <div className={classes.dialogContent}>
          <WatchListMessage
            type={EMessageType.WLUpdateFilter}
            watchListName={watchListName || ''}
          />
        </div>
      </FCTDialog>
    </>
  )

  return (
    <ClickAwayListener onClickAway={() => setOpenOptionFilter({ filter: false })}>
      <div>
        <CustomPopover
          open={openOption.filter}
          renderButton={props.isWatchList ? renderButtonWatchList : renderButtonListView}
          popoverHeader={popoverHeader}
        >
          <FilterSearchContainer>
            <OverviewItem
              filter={companyFilter}
              setFilter={setFilter}
              handleChange={handleChange}
              getFilterData={companyFilterData}
              companyStatusesData={companyFilterData?.companyStatuses}
              openOption={openOption}
              setOpenOption={setOpenOption}
            />
            <div style={{ borderBottom: '1px solid #E7ECF3' }}>
              <AccordionItem
                title="HQ Geography"
                open={openOption?.geography}
                setOpen={value => setOpenOption(value, 'geography')}
              >
                <GeographyFilter
                  filter={companyFilter}
                  setFilter={setFilter}
                  filterData={companyFilterData}
                  ref={childRef}
                />
              </AccordionItem>
            </div>
            <InvestorItem
              filter={companyFilter}
              setFilter={setFilter}
              handleChange={handleChange}
              getFilterData={companyFilterData}
              openOption={openOption}
              setOpenOption={setOpenOption}
            />
            <FinancingItem
              filter={companyFilter}
              setFilter={setFilter}
              handleChange={handleChange}
              getFilterData={companyFilterData}
              openOption={openOption}
              setOpenOption={setOpenOption}
            />
            <MAItem
              filter={companyFilter}
              setFilter={setFilter}
              handleChange={handleChange}
              getFilterData={companyFilterData}
              openOption={openOption}
              setOpenOption={setOpenOption}
            />
            <IPOItem
              filter={companyFilter}
              setFilter={setFilter}
              handleChange={handleChange}
              getFilterData={companyFilterData}
              openOption={openOption}
              setOpenOption={setOpenOption}
            />
            {showSmartCluster && <SmartClusterItem filter={companyFilter} setFilter={setFilter} />}
          </FilterSearchContainer>
        </CustomPopover>
      </div>
    </ClickAwayListener>
  )
}

export default EDCompanyFilterContainer

import { concat, intersection, xor } from 'lodash'
import moment from 'moment'
import { ColumnSort } from '../components/SelectTable/FCTSelectTable'
import { DEFAULT_DATA_MAX } from '../containers/EDCompanyDealFilterContainer'
import {
  EXPAND_ROUND1_ID,
  NUM_OF_EMPLOYEES_RANGES_ARR_VALUE,
} from '../core/constants/companyFilter'
import { initCompanyDealFilter } from '../store/features/company-deal/CompanyDealSlice'
import { SearchCompaniesResult } from '../store/operations/queries/local/rest/SearchCompanies'
import { ICompanyDealFilter, SortInfo } from '../types/CompanyDealFilter'
import { IDimensionTree } from '../types/Dimension'
import { ESwitchFemale, IAnalysisFilter } from '../types/Filter'
import { ITreeData } from '../types/Tree'
import {
  GetDealFilterDataResult_getDealFilterData,
  GetDealFilterDataResult_getDealFilterData_products,
  GetDealFilterDataResult_getDealFilterData_valueChains,
} from '../__generated__/GetDealFilterDataResult'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../__generated__/globalTypes'
import { SortDirection, TAXONOMY_ID_THRESH_HOLD_CHECK } from './consts'
import { LocationType } from './convert/Title'
import { setEFDealType } from './DealType'
import { getCompareDate, getValueChains } from './Filter'
import { FindTaxonomy } from './FindTaxonomy'
import { TaxonomyFilterMapping } from './TaxonomyMapping'
import { getChildrenIds } from './Tree'

export function NumOfEmployeesRanges(
  startValue: string | number,
  endValue: string | number,
  data: IData[]
) {
  //get all value of numberOfEmployee including undefined
  if (startValue === '1' && endValue === '10001+') return null
  let numOfEmployeesRanges: string[] = []
  data.forEach(e => {
    let start = Number(startValue)
    let end = endValue === '10001+' ? 10001 : Number(endValue)
    e.id >= start && e.id < end && numOfEmployeesRanges.push(e.value)
  })
  return numOfEmployeesRanges
}

interface IData {
  id: number
  value: string
}

export function addSortArray(data: SortInfo[] | null, value: string, type: ColumnSort) {
  let newData = data?.filter(e => e.field !== value) || []
  if (type != ColumnSort.Default)
    newData = [
      {
        field: value,
        direction: type ? SortDirection.Desc : SortDirection.Asc,
      },
      ...newData,
    ]
  return newData
}

export function ConvertYear(data: string[] | null) {
  if (!data) return null
  if (data && data[0] === 'Pre 2000') return -1
  return Number(data && data[0])
}

export function convertProductId(
  id: number,
  products: GetDealFilterDataResult_getDealFilterData_products[],
  category?: InternalDbCompanyTypes
) {
  if (id < TAXONOMY_ID_THRESH_HOLD_CHECK)
    return products.filter(e => e.category == category).map(e => e.id)
  let getTreeNodes: IDimensionTree[] = []
  const productsTree = TaxonomyFilterMapping(products)
  for (const node of productsTree) {
    const findTaxonomyByNodeId = FindTaxonomy(node)
    getTreeNodes = concat(
      getTreeNodes,
      (findTaxonomyByNodeId(id) ? [findTaxonomyByNodeId(id)] : []) as IDimensionTree[]
    )
  }
  const result = !!getTreeNodes[0]?.children
    ? getChildrenIds(getTreeNodes[0]?.children as ITreeData[])
    : []
  return !!result.length ? result : [id]
}

export function checkOrderColumn(order: SortInfo[] | null, selected: string[]) {
  return order
    ?.map(e => {
      if (selected.includes(e.field)) return e
    })
    .filter(e => !!e)
}

export const getCompanyInput = (
  filter: ICompanyDealFilter | IAnalysisFilter,
  filterData: GetDealFilterDataResult_getDealFilterData | undefined,
  companySearchFilter?: SearchCompaniesResult,
  similarCompaniesIds?: number[]
) => {
  const tagGroupIds = filter.tagGroupIds?.filter(
    e => !filterData?.tags?.map(e => e.tagGroupId)?.includes(e)
  )
  return {
    descriptionKeywords: null,
    descriptionKeywordsCombination: null,
    category: filter.category,
    products: filter.products,
    operatingModels: filter.operatingModels,
    fintechTypes: filter.businessModelIds && filter.businessModelIds[0],
    tagIds: tagGroupIds,
    companyStatuses: filter.companyStatuses,
    distributionModelIds: filter.distributionModelIds,
    numOfEmployeesRanges: NumOfEmployeesRanges(
      filter.numOfEmployeesRanges.start,
      filter.numOfEmployeesRanges.end,
      NUM_OF_EMPLOYEES_RANGES_ARR_VALUE
    ),
    foundedYearFrom: ConvertYear(filter.foundedYearFrom),
    foundedYearTo: ConvertYear(filter.foundedYearTo),
    totalFundingFrom: filter.totalFundingFrom ? Number(filter.totalFundingFrom) : null,
    totalFundingTo: filter.totalFundingTo ? Number(filter.totalFundingTo) : null,
    equityFundingFrom: filter.equityFundingFrom ? Number(filter.equityFundingFrom) : null,
    equityFundingTo: filter.equityFundingTo ? Number(filter.equityFundingTo) : null,
    latestDealSizeFrom: filter.latestDealAmountFrom ? Number(filter.latestDealAmountFrom) : null,
    latestDealSizeTo: filter.latestDealAmountTo ? Number(filter.latestDealAmountTo) : null,
    fundingDateFrom:
      filter.fundingDateFrom && moment(new Date(filter.fundingDateFrom)).format('YYYY-MM-DD'),
    fundingDateTo:
      filter.fundingDateTo && moment(new Date(filter.fundingDateTo)).format('YYYY-MM-DD'),
    investorTypes: filter.investorsTypes,
    investors: filter.investors,
    leadInvestorTypes: filter.leadInvestorTypes,
    leadInvestors: filter.leadInvestors,
    acquirerTypes: filter.acquirerTypes,
    acquirers: filter.acquirers,
    acquiredDateFrom:
      filter.acquiredDateFrom && moment(new Date(filter.acquiredDateFrom)).format('YYYY-MM-DD'),
    acquiredDateTo:
      filter.acquiredDateTo && moment(new Date(filter.acquiredDateTo)).format('YYYY-MM-DD'),
    dealAmountFrom: filter.dealAmountFrom ? Number(filter.dealAmountFrom) : null,
    dealAmountTo: filter.dealAmountTo ? Number(filter.dealAmountTo) : null,
    regions: filter.regions,
    subRegions: filter.subRegions,
    subRegion2s: filter.subRegion2s,
    countries: filter.countries,
    latestFundingRound1s: setEFDealType(filter.latestFundingRound1s, filter.latestFundingRound2s),
    latestFundingRound2s: filter.latestFundingRound2s,
    fundingRound1s: setEFDealType(filter.fundingRound1s, filter.fundingRound2s) as number[],
    fundingRound2s: filter.fundingRound2s as number[],
    numOfFundingRoundsFrom: !!Number(filter.numberOfFundingRounds.start)
      ? Number(filter.numberOfFundingRounds.start)
      : null,
    numOfFundingRoundsTo: !!filterData?.maxNumOfFundingRounds
      ? !!Number(filter.numberOfFundingRounds.end) &&
        Number(filter.numberOfFundingRounds.end) !== filterData?.maxNumOfFundingRounds
        ? Number(filter.numberOfFundingRounds.end)
        : null
      : null,
    numOfInvestorsFrom: !!Number(filter.numberOfInvestorsRanges.start)
      ? Number(filter.numberOfInvestorsRanges.start)
      : null,
    numOfInvestorsTo: !!filterData?.maxNumOfInvestors
      ? !!Number(filter.numberOfInvestorsRanges.end) &&
        Number(filter.numberOfInvestorsRanges.end) !== filterData?.maxNumOfInvestors
        ? Number(filter.numberOfInvestorsRanges.end)
        : null
      : null,
    selectedProductId: (filter.selectedProductId || 0) > 0 ? filter.selectedProductId : null,
    selectedBusinessLineId: filter.selectedBusinessLineId,
    companyIds: [
      ...(companySearchFilter?.searchCompanies.hits.hit.map(hit =>
        Number(hit.fields.company_id[0])
      ) || []),
      ...(similarCompaniesIds || []),
    ],
    numOfFemaleFounderFrom:
      filter.switchFemalePicker == ESwitchFemale.LeastFemale
        ? 1
        : filter.switchFemalePicker == ESwitchFemale.CustomCount
        ? Number(filter.numOfFemaleFounderFrom) || null
        : null,
    numOfFemaleFounderTo:
      filter.switchFemalePicker == ESwitchFemale.CustomCount
        ? filter.numOfFemaleFounderTo && !!filterData?.maxFemaleFounder
          ? Number(filter.numOfFemaleFounderTo) !== filterData?.maxFemaleFounder
            ? Number(filter.numOfFemaleFounderTo)
            : null
          : null
        : null,
    numOfFemaleFounderPercentFrom:
      filter.switchFemalePicker == ESwitchFemale.CustomDistribution
        ? Number(filter.numOfFemaleFounderPercentFrom) / 100 || null
        : null,
    numOfFemaleFounderPercentTo:
      filter.switchFemalePicker == ESwitchFemale.CustomDistribution
        ? Number(filter.numOfFemaleFounderPercentTo) !== 100 && !!filter.numOfFemaleFounderPercentTo
          ? Number(filter.numOfFemaleFounderPercentTo) / 100
          : null
        : null,
    wentPublicOnDateFrom:
      filter.wentPublicOnDateFrom &&
      moment(new Date(filter.wentPublicOnDateFrom)).format('YYYY-MM-DD'),
    wentPublicOnDateTo:
      filter.wentPublicOnDateTo && moment(new Date(filter.wentPublicOnDateTo)).format('YYYY-MM-DD'),
    hasFilterSmartCluster: !!filter?.smartCluster?.keywords?.length,
  }
}
export const getCompanyInvestorProfileInput = (
  filter: ICompanyDealFilter | IAnalysisFilter,
  filterData: GetDealFilterDataResult_getDealFilterData | undefined,
  companySearchFilter?: SearchCompaniesResult
) => {
  const tagGroupIds = filter.tagGroupIds?.filter(
    e => !filterData?.tags?.map(e => e.tagGroupId)?.includes(e)
  )
  return {
    category: filter.category,
    products: filter.products,
    fintechTypes: filter.businessModelIds && filter.businessModelIds[0],
    distributionModelIds: filter.distributionModelIds,
    tagIds: tagGroupIds,
    latestDealSizeFrom: filter.latestDealAmountFrom ? Number(filter.latestDealAmountFrom) : null,
    latestDealSizeTo: filter.latestDealAmountTo ? Number(filter.latestDealAmountTo) : null,
    dealAmountFrom: filter.dealAmountFrom ? Number(filter.dealAmountFrom) : null,
    dealAmountTo: filter.dealAmountTo ? Number(filter.dealAmountTo) : null,
    regions: filter.regions,
    subRegions: filter.subRegions,
    subRegion2s: filter.subRegion2s,
    countries: filter.countries,
    latestFundingRound1s: setEFDealType(filter.latestFundingRound1s, filter.latestFundingRound2s),
    latestFundingRound2s: filter.latestFundingRound2s,
    fundingRound1s: setEFDealType(filter.fundingRound1s, filter.fundingRound2s) as number[],
    fundingRound2s: filter.fundingRound2s as number[],
    includedEntities: filter.includedEntities,
    companyIds: companySearchFilter?.searchCompanies.hits.hit.map(hit =>
      Number(hit.fields.company_id[0])
    ),
  }
}

export const TAXONOMY_ID = {
  INSURANCE_ID: -1,
  FINTECH_ID: -2,
  REGTECH_ID: -3,
  SUPPORT_ID: 11,
}

export const getProducts = (
  id: number,
  products: GetDealFilterDataResult_getDealFilterData_products[],
  category: InternalDbCompanyTypes
) => {
  const productIds = convertProductId(id, products, category)
  if (productIds.length === 1 && productIds[0] === id) return [id]
  return [id, ...productIds]
}

export const getValueChainsBySelectedId = (
  id: number,
  valueChains: GetDealFilterDataResult_getDealFilterData_valueChains[]
) => {
  const childBySelectedId = valueChains.filter(e => e.parent_id === id).map(({ id }) => id)
  if (childBySelectedId.length) return [id, ...childBySelectedId]
  return [id]
}

export const getValueChainsFilterWhenSelectedId = (props: {
  selectedId: number
  valueChainsData: GetDealFilterDataResult_getDealFilterData_valueChains[]
  valueChainsFilter: number[] | null
}) => {
  const { selectedId, valueChainsData, valueChainsFilter } = props
  const valueChainsById = getValueChainsBySelectedId(selectedId, valueChainsData || [])
  return valueChainsFilter?.length && valueChainsById.some(e => !valueChainsFilter?.includes(+e))
    ? intersection(valueChainsById, valueChainsFilter || [])
    : valueChainsById
}

export const getProductsFilterWhenSelectedId = (props: {
  selectedId: number
  productsData: GetDealFilterDataResult_getDealFilterData_products[]
  productsFilter: number[]
  category: InternalDbCompanyTypes
}) => {
  const { selectedId, productsData, productsFilter, category } = props
  const productsById = getProducts(selectedId, productsData || [], category)

  return selectedId < TAXONOMY_ID_THRESH_HOLD_CHECK ||
    (productsFilter?.length && productsById.some(e => !productsFilter?.includes(+e)))
    ? intersection(convertProductId(selectedId, productsData || [], category), productsFilter || [])
    : productsById
}

export const CATEGORY_TAXONOMY = new Map([
  [TAXONOMY_ID.FINTECH_ID, InternalDbCompanyTypes.fintech],
  [TAXONOMY_ID.REGTECH_ID, InternalDbCompanyTypes.regtech],
  [TAXONOMY_ID.INSURANCE_ID, InternalDbCompanyTypes.insurtech],
])

export function listViewMappingId(props: {
  id: number
  setFilter(value: any): void
  filter: IAnalysisFilter
  products: GetDealFilterDataResult_getDealFilterData_products[]
  resetFilter: ICompanyDealFilter
  viewByNew?: GlobalFilterViewBy | null
  categoryNew?: InternalDbCompanyTypes | null
  isHaveBusinessAndProduct?: boolean
  businessId?: number
  valueChains: GetDealFilterDataResult_getDealFilterData_valueChains[]
}) {
  const {
    id,
    setFilter,
    filter,
    products,
    resetFilter,
    categoryNew,
    viewByNew,
    isHaveBusinessAndProduct = false,
    businessId,
    valueChains,
  } = props
  const categoryViewAll =
    products.find(item => item.id == id)?.category || CATEGORY_TAXONOMY.get(id) || filter.category
  const category = !!categoryNew
    ? categoryNew
    : filter.category == InternalDbCompanyTypes.all
    ? categoryViewAll
    : filter.category
  const viewBy = !!viewByNew ? viewByNew : filter.viewBy
  setFilter({
    ...resetFilter,
    ...filter,
    category: category,
    viewBy: viewBy,
  })

  const productsList =
    id < 2 ||
    (filter?.products?.length &&
      getProducts(id, products, category).some(e => !filter?.products?.includes(+e)))
      ? intersection(convertProductId(id, products, category), filter.products || [])
      : getProducts(id, products, category)

  const valueChainsList = getValueChainsFilterWhenSelectedId({
    selectedId: id,
    valueChainsData: valueChains || [],
    valueChainsFilter: filter?.valueChains || [],
  })

  if (category !== InternalDbCompanyTypes.all) {
    switch (viewBy) {
      case GlobalFilterViewBy.business: {
        setFilter({
          selectedBusinessLineId: isHaveBusinessAndProduct ? businessId : id,
          businessLines: isHaveBusinessAndProduct ? [businessId] : [id],
        })
        break
      }
      case GlobalFilterViewBy.product: {
        setFilter({
          products: productsList,
          selectedProductId: id > TAXONOMY_ID_THRESH_HOLD_CHECK ? id : null,
        })
        break
      }
      case GlobalFilterViewBy.valuechain: {
        setFilter({
          selectedBusinessLineId: valueChainsList[0] || null,
          valueChains: valueChainsList,
        })
        break
      }
      case GlobalFilterViewBy.operatingModel: {
        setFilter({
          operatingModels: [id],
        })
        break
      }
      default:
        break
    }
  } else {
    if (id > TAXONOMY_ID_THRESH_HOLD_CHECK)
      setFilter({
        products: intersection(convertProductId(id, products, category), filter.products),
        selectedProductId: id,
      })
  }
}

export function mappingBarTotal(props: {
  setFilter(value: any): void
  filter: IAnalysisFilter
  from: string
  to: string
  typeChart?: ETypeChart
  dataFilter: GetDealFilterDataResult_getDealFilterData
  resetFilter?: boolean
  filterOther?: Object
}) {
  const {
    setFilter,
    filter,
    from,
    to,
    typeChart,
    dataFilter,
    resetFilter = true,
    filterOther = {},
  } = props
  resetFilter && setFilter({ ...initCompanyDealFilter, ...filter })
  setFilter({ ...filterOther })
  switch (typeChart) {
    case ETypeChart.Count: {
      setFilter({
        foundedYearFrom: [getCompareDate((filter.foundedYearFrom || [])[0], from, 'later')],
        foundedYearTo: [getCompareDate((filter.foundedYearTo || [])[0], to, 'sooner')],
        yearFoundedRange: true,
      })
      break
    }
    case ETypeChart.EquityFunding: {
      setFilter({
        fundingDateFrom: getCompareDate(filter.fundingDateFrom, from, 'later'),
        fundingDateTo: getCompareDate(filter.fundingDateTo, to, 'sooner'),
        ...getFundingRoundFilterChartEF({ filter, filterData: dataFilter }),
      })
      break
    }
    case ETypeChart.MA: {
      setFilter({
        acquiredDateFrom: getCompareDate(filter.fundingDateFrom, from, 'later'),
        acquiredDateTo: getCompareDate(filter.fundingDateTo, to, 'sooner'),
        fundingRound1s: [EXPAND_ROUND1_ID.MA],
      })
      break
    }
    case ETypeChart.IPO: {
      setFilter({
        wentPublicOnDateFrom: getCompareDate(filter.wentPublicOnDateFrom, from, 'later'),
        wentPublicOnDateTo: getCompareDate(filter.wentPublicOnDateTo, to, 'sooner'),
        fundingRound1s: [EXPAND_ROUND1_ID.IPO],
      })
      break
    }
    default: {
      break
    }
  }
}

export const getFundingRoundFilterChartEF = (props: {
  filter: IAnalysisFilter
  filterData: GetDealFilterDataResult_getDealFilterData
}) => {
  const { filter, filterData } = props
  return {
    fundingRound2s:
      (!!filter.fundingRound2s?.length
        ? (filter.fundingRound2s as number[])
        : filterData?.fundingRound2s?.map(e => e.id)) || [],
    fundingRound1s:
      filter.fundingRound2s?.length &&
      filterData?.fundingRound2s?.some(e => !filter.fundingRound2s?.includes(e.id))
        ? []
        : [EXPAND_ROUND1_ID.EQUITY_FINANCING],
  }
}

export const navigateEFChartToAnalysis = (props: {
  setFilter(value: any): void
  filter: IAnalysisFilter
  filterData: GetDealFilterDataResult_getDealFilterData
  from: string
  to: string
}) => {
  const { setFilter, filter, from, to, filterData } = props
  setFilter({
    fundingDateFrom: getCompareDate(filter.fundingDateFrom, from, 'later'),
    fundingDateTo: getCompareDate(filter.fundingDateTo, to, 'sooner'),
    ...getFundingRoundFilterChartEF({ filter, filterData }),
  })
}

export const getGeographyId = (props: {
  id: number
  name: string
  locationType: LocationType
  groupInfo: GroupIds[] | null
  filterData: GetDealFilterDataResult_getDealFilterData | undefined
}) => {
  const { id, name, locationType, groupInfo, filterData } = props
  if (id < TAXONOMY_ID_THRESH_HOLD_CHECK) {
    const groupId = groupInfo?.filter(item => (item.id || 0) > 0)?.map(item => item.id) || []
    const groupName = groupInfo?.filter(item => (item.id || 0) > 0)?.map(item => item.name) || []
    switch (locationType) {
      case LocationType.Region:
        return xor(filterData?.regions?.map(item => item.name) || [], groupName)

      case LocationType.SubRegion:
        return xor(filterData?.subRegions?.map(item => item.name) || [], groupName)

      case LocationType.SubRegion2:
        return xor(filterData?.subRegion2s?.map(item => item.name) || [], groupName)

      case LocationType.Country:
        return xor(filterData?.countries?.map(item => item.id) || [], groupId)
      default:
        return null
    }
  } else if (locationType === LocationType.Country) return [id]
  else return [name]
}

export enum ETypeChart {
  Count,
  EquityFunding,
  MA,
  IPO,
}

export const commonCompanySearchQuery =
  '&return=company_id&sort=status_code desc,_score desc&q.parser=structured&size=10000'

export interface GroupIds {
  id: number | null
  name: string | null
}

export const useResetFilter = (
  setFilter: (value: any) => void,
  initFilter: IAnalysisFilter | ICompanyDealFilter,
  filterData: GetDealFilterDataResult_getDealFilterData | undefined
) => {
  setFilter({
    ...initFilter,
    numberOfInvestorsRanges: {
      start: '0',
      end: String(filterData?.maxNumOfInvestors || DEFAULT_DATA_MAX),
    },
    numberOfFundingRounds: {
      start: '0',
      end: String(filterData?.maxNumOfFundingRounds || DEFAULT_DATA_MAX),
    },
  })
}

import * as d3 from 'd3'
import formatAbbreviationFlexible from '../FormatAbbreviation'

export const numberFormat = (num: number, currency?: string) => {
  return (currency || '') + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

// Input: 111.111 => Output: 111.1K
const formatSi3 = d3.format('.4s')

// Input: 111.111 => Output: 111K
const formatSi2 = d3.format('.3s')

// Input: 111.111 => Output: 110K
const formatSi1 = d3.format('.2s')

export const formatAbbreviation = (number: number) => {
  let numConvert = ''
  let length = String(Math.round(number)).length
  if (number < 1000) return String(Math.round(number))
  if (length % 3 === 1) numConvert = formatSi1(number)
  else if (length % 3 === 2) numConvert = formatSi2(number)
  else {
    let numberRound = +d3.format('.4r')(number)
    if (String(numberRound).length % 3 === 1) numConvert = formatSi1(numberRound)
    else numConvert = formatSi3(number)
  }
  switch (numConvert[numConvert.length - 1]) {
    case 'G':
      return numConvert.slice(0, -1) + 'B' // billions
    case 'k':
      return numConvert.slice(0, -1) + 'K' // thousands
  }
  return numConvert
}

export const formatPercent = (value: string) => {
  if (isNaN(+value) || value === 'Infinity') return 'N/A'
  let number = +value
  if (number > 0 && number < 0.00001) return '0.00001%'
  if (number > -0.00001 && number < 0) return '-0.00001%'
  if (number > -0.1 && number < 0.1 && number !== 0)
    return formatAbbreviationFlexible(number, '.0') + '%'
  let percent = Math.round(number * 10) / 10
  if (Number.isInteger(percent)) return numberWithCommas(percent) + '.0%'
  return numberWithCommas(percent) + '%'
}

export const numberWithCommas = (number: number | string) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const pluralize = (num: number, singular: string, plural: string) => {
  return num <= 1 ? singular : plural
}

export const formatNumWithKMBToNum = (numWithKMB: string) => {
  let num: number | string = numWithKMB
  if (numWithKMB.indexOf('+') !== -1) {
    num = numWithKMB.slice(0, numWithKMB.indexOf('+'))
  }
  if (numWithKMB.indexOf('K') !== -1) {
    num = Number(numWithKMB.slice(0, numWithKMB.indexOf('K'))) * 1000
  } else if (numWithKMB.indexOf('M') !== -1) {
    num = Number(numWithKMB.slice(0, numWithKMB.indexOf('M'))) * 1000000
  } else if (numWithKMB.indexOf('B') !== -1) {
    num = Number(numWithKMB.slice(0, numWithKMB.indexOf('B'))) * 1000000000
  } else {
    num = Number(num)
  }
  return num
}

export const formatNumberToString = (value: any) => {
  var result = value
  if (!value) return ''
  if (value.toString().includes('.')) result = value.toString().split('.').join('')
  if (value.toString().includes(',')) result = value.toString().split(',').join('')
  return result
}

export enum ValidateInput {
  MAX_RANGE_BIGINT = 18446744073709551615,
  MAX_RANGE_INT = 2147483647,
}

export const validateMoney = (value?: string | number | null) => {
  if (value === null) return ''
  if (value && (Number.isNaN(+value) || value < 0 || +value > +ValidateInput.MAX_RANGE_BIGINT))
    return 'Invalid money'
  const newValue = Number(value)
  if (newValue === 0) return '$0'
  return newValue ? '$' + newValue.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : ''
}

export const validateURL = (str: string) => {
  if (!str?.trim()) return true
  if (validateEmail(str)) return false
  const pattern = new RegExp(
    /^(http(s)?:\/\/)?(www\.)?(\w{2,256}(\.|\-)\w{2,256})+\b((?!.*[@:%-_\\+.~?&/=]{2,})[\w@:%-_\+.~#?&/=]*)/
  )

  const pattern2 = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator

  return !!pattern.test(str.toLowerCase()) || !!pattern2.test(str.slice(0, 25))
}

export const validateLinkedin = (str: string) => {
  if (!str?.trim()) return true
  const regex = /^(?:(?:http|https):\/\/)?(?:www.)?([a-z]{0,2}.)?linkedin.com\/?/
  return !!regex.test(str)
}

export const validateFacebook = (str: string) => {
  if (!str?.trim()) return true
  const regex = /^(?:(?:http|https):\/\/)?(?:www.)?(facebook|fb).com\/?/
  return !!regex.test(str)
}

export const validateTwitter = (str: string) => {
  if (!str?.trim()) return true
  const regex = /^(?:(?:http|https):\/\/)?(?:www.)?twitter.com\/?/
  return !!regex.test(str)
}

export const validateLongText = (str: string) => {
  if (!str?.trim()) return false
  const wordCount = str.match(/(\w+)/g)?.length || 0
  return wordCount > 150 || str.length > 4000
}

export const validateCountWord = (str: string, countWordLimit: number) => {
  return str?.trim().split(/\s+/).filter(Boolean).length > countWordLimit
}

export const validateTextLimit = (str: string, limit: number) => {
  if (!str?.trim()) return false
  return str.length > limit
}

export const validateEmail = (text: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!regex.test(text)
}

export const formatNumber = (n: string | undefined) => {
  if (!n) return ''
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import theme from '../../../theme'

export const ChipItemFilter = withStyles({
  root: {
    zIndex: 1,
    background: theme.palette.grey[100],
    borderRadius: 3,
    padding: '4px 0',
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.grey[500],
    maxWidth: '100%',
  },
  deleteIcon: {
    width: 10,
    height: 10,
  },
  deleteIconSmall: {
    width: 10,
    height: 10,
  },
})(Chip)

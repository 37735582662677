import { Box, Container, Grid, makeStyles, useTheme } from '@material-ui/core'
import Flex from 'components/Flex/Flex'
import { PropsWithChildren } from 'react'

const useStyles = makeStyles(theme => ({
  content: {
    flex: 1,
  },
  left: {
    boxShadow: `1px 0 0 0 ${theme.palette.divider}`,
    '&>div': {
      position: 'sticky',
      top: '59px',
    },
  },
  right: {
    padding: '40px 40px 120px',
  },
}))

type Props = PropsWithChildren<{
  Nav?: React.ReactElement
  Header: React.ReactElement
  Footer: React.ReactElement
  Search: React.ReactElement
  Breadcrumbs: React.ReactElement
}>

const KnowledgeHubTemplate = ({ Nav, children, Header, Footer, Search, Breadcrumbs }: Props) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Flex flexDirection={'column'} height={'100vh'} overflow={'auto'}>
      {Header}
      <Flex
        bgcolor={theme.palette.grey[800]}
        alignItems={'center'}
        justifyContent={'center'}
        minHeight={'114px'}
        maxHeight={'114px'}
      >
        {Search}
      </Flex>
      <Container maxWidth="lg" className={classes.content}>
        <Grid container>
          {!!Nav && (
            <Grid item sm={12} md={3} className={classes.left}>
              <Box>{Nav}</Box>
            </Grid>
          )}
          <Grid item sm={12} md={!!Nav ? 9 : 12} className={classes.right}>
            <Box mb="20px">{Breadcrumbs}</Box>
            {children}
          </Grid>
        </Grid>
      </Container>
      {Footer}
    </Flex>
  )
}

export default KnowledgeHubTemplate

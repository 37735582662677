import { createSelector } from '@reduxjs/toolkit'
import { Selector } from 'react-redux'
import { RootState } from '../../store'
import { IInvestorProfileSlice } from './InvestorProfileSlice'

const selectInvestorProfile: Selector<RootState, IInvestorProfileSlice> = (state: RootState) =>
  state.investorProfileReducer

const selectSelectedTab = createSelector(selectInvestorProfile, state => state.selectedTab)

export { selectSelectedTab }

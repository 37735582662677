import { Link, Typography, useTheme, makeStyles, Box } from '@material-ui/core'
import Icon from 'components/Icon/Icon'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[900],
  },
  link: {
    color: `${theme.palette.primary.light}`,
    marginLeft: '4px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}))

type Props = {
  email: string
  onClickResend(): void
}

export default function CheckYourEmail({ email, onClickResend }: Props) {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Box textAlign="center">
      <Icon name="sendEmail" width="80px" height="80px" />
      <Typography
        style={{
          color: theme.palette.grey[900],
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '34px',
          marginBottom: '16px',
          marginTop: '24px',
        }}
        component="h3"
      >
        Check your email!
      </Typography>

      <Typography
        className={classes.text}
        style={{
          marginBottom: '10px',
          fontWeight: 600,
        }}
      >
        We’ve sent an email to{' '}
        <Typography
          className={classes.text}
          component={'span'}
          style={{
            color: theme.palette.primary.light,
          }}
        >
          {email}
        </Typography>
      </Typography>

      <Typography
        className={classes.text}
        style={{
          marginBottom: '24px',
        }}
      >
        Click on the link in the email to reset your password.
      </Typography>

      <Typography
        className={classes.text}
        style={{
          marginBottom: '4px',
        }}
      >
        Didn’t receive the link?
      </Typography>
      <Typography className={classes.text}>
        Check your spam folder or
        <Link className={classes.link} onClick={onClickResend}>
          <Icon name="refreshAuth" width={9} height={9} style={{ marginRight: '3px' }} />
          Send again
        </Link>
      </Typography>
    </Box>
  )
}

import { gql } from '@apollo/client'

export const GET_DEAL_FILTER_DATA = gql`
  query GetDealFilterDataResult($input: GetDealFilterInput) {
    getDealFilterData(input: $input)
      @rest(
        path: "?{args.input}"
        method: "GET"
        type: "GetFilterData"
        endpoint: "get_filter_data"
      ) {
      data {
        businessLines {
          id
          name
          description
        }
        risks {
          id
          name
        }
        valueChains {
          id
          name
          description
          level
          parent_id
        }
        operatingModels {
          id
          name
          description
        }
        products {
          id
          name
          category
          parentIds
          productLv1Id
          description
          can_be_final
        }
        businessModels {
          id
          name
        }
        distributionModels {
          id
          name
        }
        companyStatuses {
          id
          name
          isShow
        }
        fundingRound1s {
          id
          name
        }
        fundingRound2s {
          id
          name
        }
        tags {
          tagGroupId
          tagGroupName
          tags {
            tag_id
            tag_name
            tag_desc
          }
        }
        investorTypes
        acquirerTypes
        regions {
          id
          name
        }
        subRegions {
          id
          name
          region1
        }
        subRegion2s {
          id
          name
          region1
          region2
        }
        countries {
          id
          name
          region1
          region2
          region3
        }
        maxFundingAmount
        maxNumOfInvestors
        maxNumOfFundingRounds
        maxFemaleFounder
      }
    }
  }
`

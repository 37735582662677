import { gql } from '@apollo/client'

export const GET_INVESTORS_FILTER_DATA = gql`
  query GetInvestorsFilterData($input: GetInvestorsFilterDataInput!) {
    getInvestorsFilterData(input: $input) {
      id
      name
    }
  }
`

import { gql } from '@apollo/client'
import { RestfulResponse } from './RestfulResponse'
export const SUGGEST = gql`
  query SUGGEST($input: String) {
    suggest(input: $input)
      @rest(path: "?{args.input}", method: "GET", type: "Suggest", endpoint: "suggest") {
      hits
      # status
    }
  }
`
export interface ISearchInfo {
  value: [string]
  id: string
  fields: string[]
  meta: string[]
  count: string[]
}
export interface ISuggestResult {
  suggest: RestfulResponse<ISearchInfo>
}

import { RetryLink } from '@apollo/client/link/retry/retryLink'
import { onError } from 'apollo-link-error'

export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
})

export const errorLink = onError(({ graphQLErrors, networkError, forward }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log('GraphQL error', `Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  if (networkError) console.log('Network error', `${networkError}`)
  return
})

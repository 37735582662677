import { useQuery } from '@apollo/client'
import { Avatar } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { concat, isEmpty, isNil } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { VIEW_BY } from '../../core/constants/Filter'
import useGetSearchCompanies from '../../core/hooks/useGetSearchCompanies'
import { useWatchListId } from '../../store/features/watch-list/WatchListHook'
import {
  GET_COMPANY_LIST_VIEW_DATA,
  GET_TOTAL_COMPANY_LIST_VIEW_DATA,
} from '../../store/operations/queries/GetCompanyListViewData'
import { GET_DEAL_FILTER_DATA } from '../../store/operations/queries/GetDealFilterData'
import { GET_WATCH_LIST_COMPANY_IDS } from '../../store/operations/queries/GetWatchListCompanyIds'
import { IAnalysisFilter } from '../../types/Filter'
import { checkOrderColumn, getCompanyInput } from '../../utils/CompanyFilter'
import { useNavigateProfile } from '../../utils/navigationHook'
import {
  CompanyListViewQueryResult,
  CompanyListViewQueryResult_getCompanyListViewData_items,
} from '../../__generated__/CompanyListViewQueryResult'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '../../__generated__/GetDealFilterDataResult'
import { GetWatchListCompanyIds } from '../../__generated__/GetWatchListCompanyIds'
import { DealListViewQueryInput } from '../../__generated__/globalTypes'
import { TotalCompanyListViewQueryResult } from '../../__generated__/TotalCompanyListViewQueryResult'
import Loading from '../Loading'
import FCTSearchInput from '../SearchInput/FCTSearchInput'
import { LISTVIEW_PAGINATION_INIT } from '../SelectTable/FCTSelectTable'
import FCTIcon from '../Icon/Icon'
import theme from './../../theme'
const useStyles = makeStyles(theme => ({
  containerItem: {
    minHeight: '56px',
    display: 'flex',
    padding: '7px 14px',
    boxSizing: 'border-box',
    border: '1px solid #FFFFFF',
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[50]}`,
    },
    '&:hover': {
      background: '#FFFFFF',
      border: '1px solid #E7ECF3',
      boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  avatar: {
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    width: 24,
    height: 24,
    fontSize: 10,
  },
  content: {
    fontWeight: 600,
    textAlign: 'left',
    alignSelf: 'center',
    fontSize: 14,
    lineHeight: '22px',
  },
  webCompany: {
    display: 'table',
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    color: '#6C809D',
    '&:hover': {
      textDecoration: 'underline',
    },
    maxWidth: 280,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  link: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  infiniteList: {
    marginTop: 10,
    overflowY: 'auto',
    boxSizing: 'border-box',
    background: '#ffffff',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  wrapperIcon: {
    height: 'calc(100% - 75px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  blankTitle: {
    fontSize: '1.2em',
    fontWeight: 600,
    marginTop: 10,
    color: theme.palette.grey[400],
  },
}))

const fixedColumns = [
  'company_id',
  'name',
  'description',
  'long_description',
  'logo_url',
  'website_url',
]

interface IProps {
  filter: IAnalysisFilter
  setTotal: (total: number) => void
  setWatchListCompanyIds: (listCompanyIds: (number | null)[]) => void
  totalCompany?: Number
}

export const ListCompaniesFilterConditions = ({
  filter,
  setTotal,
  setWatchListCompanyIds,
  totalCompany,
}: IProps) => {
  const classes = useStyles()
  const [watchListId, __] = useWatchListId()
  const { navigateProfile } = useNavigateProfile()
  const companySearchFilter = useGetSearchCompanies(filter)

  const [searchText, setSearchText] = useState<string>('')
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false)
  const [isLoadData, setIsLoadData] = useState<boolean>(true)
  const timeOutRef = useRef<NodeJS.Timeout | null>(null)
  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA)
  const filterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData
  const dataName = VIEW_BY[filter.category].propName as keyof IAnalysisFilter

  const [pagination, setPagination] = React.useState({
    pageIndex: LISTVIEW_PAGINATION_INIT.pageIndex,
    pageSize: 20,
  })

  const [companyListData, setCompanyListData] = useState<
    CompanyListViewQueryResult_getCompanyListViewData_items[]
  >([])

  const companyInput = () => {
    return {
      ...getCompanyInput(filter, filterData, companySearchFilter),
      businessLinesRisksValueChains: filter[dataName],
      pageNumber: pagination.pageIndex,
      pageSize: pagination.pageSize,
      selectedColumns: fixedColumns,
      orderBy: checkOrderColumn(null, fixedColumns),
      watchListId: null,
      search: searchText,
    } as DealListViewQueryInput
  }

  const companyInputForTable = useMemo(companyInput, [
    filter,
    companySearchFilter,
    pagination,
    watchListId,
    searchText,
  ])
  const companyInputForTotal = useMemo(companyInput, [filter, companySearchFilter, watchListId])

  const { data: totalCompanies, refetch: fnTotal } = useQuery<TotalCompanyListViewQueryResult>(
    GET_TOTAL_COMPANY_LIST_VIEW_DATA,
    {
      variables: {
        input: {
          ...companyInputForTotal,
          search: '',
        },
      },
      skip: !+filter?.numberOfFundingRounds?.end,
    }
  )

  const { data: getWatchListCompanyIds } = useQuery<GetWatchListCompanyIds>(
    GET_WATCH_LIST_COMPANY_IDS,
    {
      variables: {
        input: {
          ...getCompanyInput(filter, filterData, companySearchFilter),
          businessLinesRisksValueChains: filter[dataName],
          pageNumber: 0,
          pageSize: 10,
          selectedColumns: [],
          orderBy: [],
          watchListId: null,
        },
      },
    }
  )

  const {
    data: getCompanyData,
    loading,
    refetch: fnCompanyData,
  } = useQuery<CompanyListViewQueryResult>(GET_COMPANY_LIST_VIEW_DATA, {
    variables: {
      input: companyInputForTable,
    },
    notifyOnNetworkStatusChange: true,
    skip: !+filter?.numberOfFundingRounds.end,
  })

  useEffect(() => {
    getWatchListCompanyIds?.getWatchListCompanyIds &&
      setWatchListCompanyIds(getWatchListCompanyIds?.getWatchListCompanyIds)
  }, [getWatchListCompanyIds])

  useEffect(() => {
    !isLoadData && setIsLoadData(true)
  }, [filter])

  useEffect(() => {
    const total = totalCompanies?.getTotalCompanyListViewData?.totalCompanies
    setTotal(total || 0)
  }, [totalCompanies])

  const timeOut = (callBack: () => void, time: number) => {
    return setTimeout(() => {
      callBack()
    }, time)
  }

  useEffect(() => {
    if (getCompanyData?.getCompanyListViewData?.items && !loading) {
      if (timeOutRef?.current) clearTimeout(timeOutRef.current)

      if (isLoadMore) {
        const callBack = () => {
          getCompanyData?.getCompanyListViewData?.items &&
            setCompanyListData(concat(companyListData, getCompanyData.getCompanyListViewData.items))
          setIsLoadMore(false)
        }
        timeOutRef.current = timeOut(callBack, 700)
      }

      if (isLoadData) {
        const callBack = () => {
          getCompanyData?.getCompanyListViewData?.items &&
            setCompanyListData(getCompanyData.getCompanyListViewData.items)
          setIsLoadData(false)
        }
        timeOutRef.current = timeOut(callBack, 400)
      }
    }
  }, [getCompanyData, isLoadData, isLoadMore])

  useEffect(() => {
    if (isLoadData) return
    !isLoadMore && setIsLoadMore(true)
  }, [pagination.pageIndex])

  useEffect(() => {
    //when search by key pageIdex always is 1
    if (pagination.pageIndex !== LISTVIEW_PAGINATION_INIT.pageIndex)
      setPagination({ ...pagination, pageIndex: 1 })
    !isLoadData && setIsLoadData(true)
  }, [searchText])

  const loadMore = () => {
    if (searchText) return
    setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 })
  }

  return (
    <>
      <FCTSearchInput
        handleSearch={setSearchText}
        resetSearch={() => setSearchText('')}
        search={searchText}
        placeholder="Search"
      />
      {companyListData.length ? (
        <>
          <InfiniteScroll
            dataLength={(isLoadData ? [] : companyListData).length}
            next={loadMore}
            hasMore={true}
            loader={
              <div style={{ height: '30px' }}>
                <Loading size={20} />
              </div>
            }
            height="calc(100vh - 495px)"
            className={classes.infiniteList}
          >
            {(isLoadData ? [] : companyListData).map((e, key) => {
              return (
                <div className={classes.containerItem} key={key}>
                  <div className={classes.link}>
                    <div
                      className={classes.logoWrapper}
                      onClick={() => navigateProfile(e.company_id)}
                    >
                      {!isEmpty(e.logo_url) && !isNil(e.logo_url) ? (
                        <Avatar
                          alt={e.name || ''}
                          variant="square"
                          src={e.logo_url || ''}
                          className={classes.avatar}
                        />
                      ) : (
                        <Avatar className={classes.avatar} variant="square">
                          {e.name?.split('')[0]}
                        </Avatar>
                      )}
                    </div>
                    <div className={classes.content}>
                      <span onClick={() => navigateProfile(e.company_id)}>{e.name}</span>
                      <Link
                        underline="none"
                        href={e.website_url || undefined}
                        target="_blank"
                        className={classes.webCompany}
                      >
                        {e.website_url}
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </InfiniteScroll>
        </>
      ) : (
        <>
          {searchText && !loading && !isLoadData ? (
            <div className={classes.wrapperIcon}>
              <FCTIcon name="searchPlus" width={36} height={36} color={theme.palette.grey[400]} />
              <p className={classes.blankTitle}>No result found</p>
            </div>
          ) : (
            <div style={{ height: '30px' }}>
              <Loading size={20} />
            </div>
          )}
        </>
      )}
    </>
  )
}

import { makeStyles } from '@material-ui/core'
import { EXPAND_ROUND1_ID } from 'core/constants/companyFilter'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { GetDealFilterDataResult_getDealFilterData } from '__generated__/GetDealFilterDataResult'
import LinedStackedBarChart from '../components/LineStackedBarChart'
import { EChartPosition } from '../containers/DashboardChartContainer'
import { NAME_EXPORT_CHART } from '../core/constants/Chart'
import { useAnalysisFilter } from '../store/features/analysis/AnalysisHook'
import { initialAnalysisFilter } from '../store/features/analysis/AnalysisSlice'
import { useCompanyDealFilter } from '../store/features/company-deal/CompanyDealHook'
import { initCompanyDealFilter } from '../store/features/company-deal/CompanyDealSlice'
import { setCurrentProfileTab } from '../store/operations/mutations'
import { ToolTipTemplate } from '../templates/ToolTipTemplate/ToolTipTemplate'
import theme from '../theme'
import { IAnalysisFilter } from '../types/Filter'
import { ChartFootNote } from '../types/FootNote'
import getColorScale, { legendList } from '../utils/charts/colorScale'
import totalByXAxis from '../utils/charts/totalByXAxis'
import {
  ETypeChart,
  getGeographyId,
  mappingBarTotal,
  navigateEFChartToAnalysis,
} from '../utils/CompanyFilter'
import { formatAbbreviation, formatPercent, numberWithCommas } from '../utils/convert/number'
import {
  convertTickAxisLabel,
  FieldNameDrawYAxis,
  LocationType,
  PeriodType,
} from '../utils/convert/Title'
import { getCompareDate } from '../utils/Filter'
import formatAbbreviationFlexible from '../utils/FormatAbbreviation'
import { useNavigateProfile } from '../utils/navigationHook'
import { locationTypeToString } from '../utils/TableChart'
import {
  GetEquityFundingByGeographyData_getEquityFundingByGeographyData,
  GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData,
  GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData_stacks,
  GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData_stacks_topCountries,
  GetEquityFundingByGeographyData_getEquityFundingByGeographyData_groupInfo,
} from '../__generated__/GetEquityFundingByGeographyData'
import { GlobalFilterViewBy } from '../__generated__/globalTypes'
import { ESearchTab } from './ExploreDBComponent/MockData'
import Legend from './Legend'
import FCTSwitch from './Switch/FCTSwitch'

const useStyles = makeStyles({
  chartContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  wrapperHeaderChart: {
    display: 'flex',
    minHeight: 31,
    justifyContent: 'space-between',
  },
  wrapperToggle: {
    minWidth: '129px',
  },
  text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.secondary.main,
    verticalAlign: 'middle',
    marginRight: 15,
  },
})

const axis = {
  x: {
    label: '',
  },
  y1: {
    label: 'Funding amount ($)',
  },
}

export enum TooltipDesc {
  Funding = 'Share of funding by geography.',
}

const EFByGeographyChart = (props: IEFByGeographyChartProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { filter, locationType, isDownLoadPptx, changeBreakdownStatus = () => {} } = props
  const [_, setCompanyDealFilter] = useCompanyDealFilter()
  const [__, setAnalysisFilter] = useAnalysisFilter()
  const { navigateProfile } = useNavigateProfile()

  const {
    chartData,
    groupInfo,
  }: GetEquityFundingByGeographyData_getEquityFundingByGeographyData = props.data || {
    groupInfo: [],
  }

  const keys =
    groupInfo?.map(
      (item: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_groupInfo) =>
        `${item.id}`
    ) || []

  const getValue = (
    d: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData,
    key: string
  ) =>
    d.stacks?.find(
      (item: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData_stacks) =>
        item.id === +key
    )?.totalFunding || 0

  const colorScale = getColorScale(legendList)

  const {
    data,
    legendItems,
  }: {
    data: Array<
      GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData & {
        total?: number
        barIndex: number
        yearGroup: number
        lineChartCount: number
      }
    >
    legendItems: Record<'name' | 'color', string>[]
  } = React.useMemo(() => {
    //TODO: hard code waiting BE
    let data = (!chartData
      ? []
      : props.type === EChartPosition.Analysis
      ? [...chartData]
      : [...chartData.slice(6)]
    )
      ?.sort(
        (
          a: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData,
          b: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData
        ) => new Date(a.from).getTime() - new Date(b.from).getTime()
      )
      .map((item, index) => {
        return {
          ...item,
          total: Number(item.stacks?.map(item2 => item2.totalFunding).reduce((a, b) => a + b, 0)),
          barIndex: index,
          yearGroup: new Date(item.from).getUTCFullYear(),
          lineChartCount: Number(item.lineChartCount),
        }
      })

    const prepareData = [...(data?.[0]?.stacks || [])].filter(d =>
      groupInfo?.some(e => e.name == 'All Other Countries')
        ? d
        : !(d?.name == 'All Other Countries')
    )

    return {
      legendItems:
        //Make order groupId is the same with barChart
        prepareData
          ?.sort(
            (a, b) => totalByXAxis(data, b, getValue, 'id') - totalByXAxis(data, a, getValue, 'id')
          )
          ?.map((d, index) => ({
            name:
              d?.name === 'All Other Countries'
                ? `All Other ${locationTypeToString(locationType as LocationType)}`
                : d?.name || '',
            color: colorScale(index + '') as string,
          })) || [],

      data: data,
    }
  }, [chartData])

  const getTipData = (
    d: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData,
    key: string
  ): GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData_stacks | undefined =>
    d?.stacks?.find(
      (item: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData_stacks) =>
        item.id === +key
    )

  const tipLayout = (
    e: MouseEvent,
    d: any,
    type: string,
    navigate: string,
    topCompanies: string
  ) => {
    const topCountries: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData_stacks_topCountries[] = !!type
      ? d.totalToolTip.topCountries
      : d.topCountries
    const name = !!type
      ? convertTickAxisLabel(d.from, props.periodType, true)
      : `${d.name} - ${convertTickAxisLabel(d.from, props.periodType, true)}`
    const companies = !!type ? d.totalToolTip.companies : d.companies
    const content = `
      <div class="tip-item">
        <p>Countries</p>
        ${topCountries?.map(item => `<span class="list">${item?.name}</span>`).join('')}
      </div>
      <div class="tip-item">
        <p>Equity Funding</p>
        ${topCountries
          ?.map(item => `<span class="list">$${formatAbbreviation(item?.totalFunding || 0)}</span>`)
          .join('')}
      </div>
      ${
        topCountries.length > 1
          ? `<div class="tip-item">
                <p>% Funding</p>
        ${topCountries
          ?.map(item => `<span class="list">${formatPercent(String(item?.percentFunding))}</span>`)
          .join('')}
        </div>`
          : ``
      }
    `

    return ToolTipTemplate(name || '', content, navigate, topCompanies)
  }

  const topCompaniesToolTipContent = (e: MouseEvent, d: any, type: string) => {
    const companies = !!type ? d.totalToolTip.companies : d.companies
    return {
      companies: companies,
      nameList: 'Top Companies <span>(By funding amount)</span>',
    }
  }

  const navigationTipTopLabel = (
    data: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData,
    url: string
  ) => {
    if (url === 'list-view') {
      mappingBarTotal({
        setFilter: setCompanyDealFilter,
        filter: { ...filter, viewBy: props.type ? GlobalFilterViewBy.business : filter.viewBy },
        dataFilter: props.filterData as GetDealFilterDataResult_getDealFilterData,
        from: data.from,
        to: data.to,
        typeChart: ETypeChart.EquityFunding,
      })
      setCurrentProfileTab(ESearchTab.Funding)
    } else {
      let newFilter = filter
      if (props.type) {
        newFilter = {
          ...initialAnalysisFilter,
          viewBy: GlobalFilterViewBy.business,
        }
      }
      navigateEFChartToAnalysis({
        setFilter: setAnalysisFilter,
        filter: newFilter,
        filterData: props.filterData as GetDealFilterDataResult_getDealFilterData,
        from: data.from,
        to: data.to,
      })
    }
    history.push({ pathname: `/app/${url}` })
  }

  const lineChartData = data?.map(item => {
    return {
      x: item.barIndex,
      y: item.lineChartCount,
      text: String(numberWithCommas(item.lineChartCount)),
    }
  })

  const navigationTip = useCallback(
    (
      data: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData,
      stacks: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData_stacks,
      url: string
    ) => {
      const locationId = getGeographyId({
        id: stacks.id,
        name: stacks.name,
        locationType: locationType || LocationType.Country,
        groupInfo: groupInfo,
        filterData: props.filterData,
      })
      if (url === 'list-view') {
        setCompanyDealFilter({
          ...initCompanyDealFilter,
          ...filter,
          fundingRound2s: !!filter.fundingRound2s?.length
            ? (filter.fundingRound2s as number[])
            : props.filterData?.fundingRound2s?.map(e => e.id),
          fundingRound1s: [EXPAND_ROUND1_ID.EQUITY_FINANCING],
          regions: locationType === LocationType.Region ? locationId : filter.regions,
          subRegions: locationType === LocationType.SubRegion ? locationId : filter.subRegions,
          subRegion2s: locationType === LocationType.SubRegion2 ? locationId : filter.subRegion2s,
          countries: locationType === LocationType.Country ? locationId : filter.countries,
          fundingDateFrom: getCompareDate(filter.fundingDateFrom, data.from, 'later'),
          fundingDateTo: getCompareDate(filter.fundingDateTo, data.to, 'sooner'),
        })
        if (props.type)
          setCompanyDealFilter({
            viewBy: GlobalFilterViewBy.business,
          })
        setCurrentProfileTab(ESearchTab.Funding)
      } else {
        let newFilter = filter
        if (props.type) {
          newFilter = {
            ...initialAnalysisFilter,
            viewBy: GlobalFilterViewBy.business,
          }
        }
        setAnalysisFilter({
          ...newFilter,
          fundingDateFrom: getCompareDate(filter.fundingDateFrom, data.from, 'later'),
          fundingDateTo: getCompareDate(filter.fundingDateTo, data.to, 'sooner'),
          fundingRound2s: !!filter.fundingRound2s?.length
            ? (filter.fundingRound2s as number[])
            : props.filterData?.fundingRound2s?.map(e => e.id),
          fundingRound1s:
            filter.fundingRound2s?.length &&
            props.filterData?.fundingRound2s?.some(e => !filter.fundingRound2s?.includes(e.id))
              ? []
              : [EXPAND_ROUND1_ID.EQUITY_FINANCING],
          regions: locationType === LocationType.Region ? locationId : filter.regions,
          subRegions: locationType === LocationType.SubRegion ? locationId : filter.subRegions,
          subRegion2s: locationType === LocationType.SubRegion2 ? locationId : filter.subRegion2s,
          countries: locationType === LocationType.Country ? locationId : filter.countries,
        })
      }
      history.push({ pathname: `/app/${url}` })
    },
    [locationType]
  )

  return (
    <div
      className={classes.chartContainer}
      style={{ flexDirection: props.type ? 'column-reverse' : 'column' }}
      id={NAME_EXPORT_CHART}
    >
      <div className={classes.wrapperHeaderChart}>
        <div style={{ margin: props.type ? 'auto' : 'unset' }}>
          {props?.breakdownStatus !== false && (
            <Legend
              legendItems={legendItems}
              data={null}
              type={props.type ? 'small' : ''}
              positionChart={props.type}
            />
          )}
        </div>
        <div className={classes.wrapperToggle}>
          {props?.breakdownStatus !== undefined && !props?.isDownLoadPptx && (
            <>
              <span className={classes.text}>Breakdown</span>
              <FCTSwitch
                size="small"
                checked={props?.breakdownStatus}
                onChange={event => changeBreakdownStatus()}
                onClick={event => event.stopPropagation()}
              />
            </>
          )}
        </div>
      </div>

      <LinedStackedBarChart
        data={data}
        lineChartData={lineChartData}
        keys={keys || []}
        getValue={getValue}
        getTipData={getTipData}
        tipLayout={tipLayout}
        axis={axis}
        format={(v: number | string) => (!!v ? formatAbbreviation(+v) : '')}
        xTickFormat={(index: number) => {
          return convertTickAxisLabel(data[index].from, props.periodType) || ''
        }}
        yTickFormat={(value: number) => {
          return formatAbbreviationFlexible(value)
        }}
        periodTypeDisplay={props.periodType}
        lineChartLabel="No. of deals"
        hasLabelX={true}
        navigationTip={navigationTip}
        navigationTipTopLabel={navigationTipTopLabel}
        typeChart={props.type}
        topCompaniesToolTipContent={topCompaniesToolTipContent}
        navigateProfile={navigateProfile}
        fieldNameDrawYAxis={FieldNameDrawYAxis.totalFunding}
        breakdownStatus={props.breakdownStatus}
        isDownLoadPptx={isDownLoadPptx}
        chartType={ChartFootNote.EFByGeography}
        orderBarsFn={(a, b, d) => {
          return totalByXAxis(data, b, getValue, 'id') - totalByXAxis(data, a, getValue, 'id')
        }}
      />
    </div>
  )
}

export default EFByGeographyChart

interface IEFByGeographyChartProps {
  data: GetEquityFundingByGeographyData_getEquityFundingByGeographyData
  periodType: PeriodType
  type: EChartPosition
  filter: IAnalysisFilter
  locationType?: LocationType
  filterData?: GetDealFilterDataResult_getDealFilterData
  breakdownStatus?: boolean
  isDownLoadPptx?: boolean
  changeBreakdownStatus?: () => void
}

import { TOPICS_TERMS } from 'core/constants/String'
import { PolicyDocumentPage } from './../components/Policy/PolicyDocumentPage'

export const TermsOfUsePage = () => {
  return (
    <PolicyDocumentPage
      topics={TOPICS_TERMS}
      getContent={async () => (await import('assets/webflow'))['termsOfUse']}
      isTermOfUse={true}
    />
  )
}

import { IAnalysisFilter } from '../types/Filter'
import { isArray, set } from 'lodash'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

export const mergeQueryParamsState = ({ filterChart }: { filterChart: IAnalysisFilter }) => ({
  ...(filterChart as any),
})

export const useCustomParams = (): [string, (searchParams: string) => void] => {
  const history = useHistory()

  const updateParams = (searchParams: string) => {
    history.replace({
      search: '?' + searchParams,
    })
  }

  return [history.location.search.replace(/^\?/, ''), updateParams]
}

export function isObject(arg: any): arg is Object {
  return typeof arg === 'object'
}

export function encodeObjectToURLParams<T extends Object = Object>(
  obj: T,
  prefix?: string
): string {
  const str = []
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const propName = prefix
        ? isArray(obj)
          ? prefix + '[' + prop + ']'
          : prefix + '.' + prop
        : prop
      const value = typeof obj[prop] == 'string' ? encodeURIComponent(obj[prop] as any) : obj[prop]
      str.push(isObject(value) ? encodeObjectToURLParams(value, propName) : propName + '=' + value)
    }
  }
  return str.join('&')
}

// result is any (depends params to object)
export function decodeObjectToURLParams<T>(
  searchString: string,
  correctTypeFn: (result: any) => T
) {
  const obj = queryString.parse(searchString)
  const result = {}
  Object.keys(obj).map(key => {
    set(result, key, typeof obj[key] == 'string' ? decodeURIComponent(obj[key] as any) : obj[key])
  })
  return pickNonEmptyProps(correctTypeFn(result), true)
}

export const pickNonEmptyProps = (obj: any, hasDecode?: boolean) => {
  const result = {} as any
  for (var propName in obj) {
    if (
      obj[propName] == null ||
      typeof obj[propName] == 'undefined' ||
      (Array.isArray(obj[propName]) && obj[propName].length == 0) ||
      (typeof obj[propName] == 'object' && Object.keys(obj[propName]).length == 0) ||
      (obj[propName] == '' && obj[propName] !== false)
    ) {
      continue
    } else if (Array.isArray(obj[propName])) {
      result[propName] = obj[propName]
    } else if (typeof obj[propName] == 'object') {
      result[propName] = pickNonEmptyProps(obj[propName], hasDecode)
    } else if (typeof obj[propName] == 'boolean' && !hasDecode) {
      result[propName] = obj[propName] ? 'true' : ''
    } else {
      result[propName] = obj[propName]
    }
  }
  return result
}

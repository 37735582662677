import FCTTooltip from '../Tooltip/Tooltip'
import { makeStyles, Typography } from '@material-ui/core'
import FCTIcon from '../Icon/Icon'
import clsx from 'clsx'
import theme from '../../theme'
import { DESCRIPTIONS_DATA_MISSING } from 'components/Tabs/FCTTab'

interface FCTToltipDescriptionProps {
  description: string | undefined
  style?: string
  id?: string | number
  isDescription?: boolean
}

const useStyles = makeStyles(theme => ({
  description: {
    display: 'flex',
  },
  tooltip: {
    marginLeft: 8,
  },
}))

export const TooltipDescription = (props: FCTToltipDescriptionProps) => {
  const classes = useStyles()

  const iconClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const textTooltip =
    DESCRIPTIONS_DATA_MISSING.find(e => e.id === props.id)?.text || props.description

  return (
    <div className={classes.tooltip}>
      <FCTTooltip
        title={<Typography style={{ fontSize: 12 }}>{textTooltip}</Typography>}
        arrow
        placement="top"
      >
        <div>
          {props.isDescription && !!textTooltip && (
            <FCTIcon
              name="info"
              width={12}
              height={12}
              color={theme.palette.grey[400]}
              className={clsx(props.style, classes.description)}
              onClick={iconClick}
            />
          )}
        </div>
      </FCTTooltip>
    </div>
  )
}

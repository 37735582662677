import { gql } from '@apollo/client'

export const GET_DOWNLOAD_HISTORY = gql`
  query GetDownloadHistory($input: GetDownloadHistoryInput!) {
    getDownloadHistory(input: $input) {
      data {
        type
        name
        sub_type
        filter_option
        properties
        timestamp
      }
      total
      limit
    }
  }
`

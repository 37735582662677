import Loading from 'components/Loading'
import { useState } from 'react'
import { ClientUser } from '../../containers/AdminContainer'
import BreadcrumbAdmin from './BreadcrumbAdmin'
import TableAdmin, { NEWS_PAGINATION_INIT } from './TableAdmin/TableAdmin'

type Props = {
  expiredUsers: ClientUser[]
  title: string
  loading: boolean
  total: number
}

const ExpiredUser = ({ expiredUsers, title, loading, total }: Props) => {
  const [pageSize, setPageSize] = useState(NEWS_PAGINATION_INIT.pageSize)
  const [pageIndex, setPageIndex] = useState(NEWS_PAGINATION_INIT.pageIndex)

  //Get current data
  const indexOfLast = pageIndex * pageSize
  const indexOfFirst = indexOfLast - pageSize

  return (
    <>
      <BreadcrumbAdmin title={title} />

      {loading ? (
        <Loading style={{ marginTop: 40 }} />
      ) : (
        <>
          <TableAdmin
            data={expiredUsers || []}
            total={total}
            indexOfFirst={indexOfFirst}
            indexOfLast={indexOfLast}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
            tableRow={tableRow}
            isExpired={true}
          />
        </>
      )}
    </>
  )
}

export default ExpiredUser

export const tableRow = [
  { name: 'ID' },
  { name: 'Name' },
  { name: 'Email' },
  { name: 'Expired day' },
]

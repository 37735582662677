/* eslint-disable no-mixed-operators */
import './styles.css'
import * as d3 from 'd3'
import d3tip from '../../core/lib/d3-tip'
import Chart from '../../utils/Chart'
import { isEqual } from 'lodash'

const Colors = [
  '#54C890',
  '#B4DDF0',
  '#C9BCE2',
  '#E4ADE1',
  '#F9B1C6',
  '#F5B298',
  '#EAD494',
  '#F3A93C',
  '#CE618E',
  '#EF8251',
]

class D3Chart extends Chart {
  init({ data, onBrush, setPanBox, setBrushEvent }) {
    let vis = this
    vis.onBrush = onBrush
    vis.setPanBox = setPanBox
    vis.setBrushEvent = setBrushEvent

    vis.svg
      .style('background', '#ffffff')
      .style('border', '1px solid #E7ECF3')
      .style('border-radius', '3px')
    vis.numberFormat = d3.format(',')

    vis.color = d3.scaleOrdinal(Colors)

    vis.DIAMETER = Math.min(vis.WIDTH, vis.HEIGHT)
    vis.RADIUS = vis.DIAMETER / 8

    vis.g = vis.g.append('g').attr('transform', `translate(${vis.WIDTH / 2}, ${vis.HEIGHT / 2})`)

    vis.format = d3.format(',d')

    vis.arc = d3
      .arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(vis.RADIUS * 1.5)
      .innerRadius(d => d.y0 * vis.RADIUS)
      .outerRadius(d => Math.max(d.y0 * vis.RADIUS, d.y1 * vis.RADIUS - 1))

    vis.partition = data => {
      const root = d3
        .hierarchy(data)
        .sum(d => d.size)
        .sort((a, b) => b.size - a.size)
      return d3.partition().size([2 * Math.PI, root.height + 1])(root)
    }

    vis.brush = d3
      .brush() // Add the brush feature using the d3.brush function
      .extent([
        [0, 0],
        [
          vis.WIDTH + vis.MARGIN.LEFT + vis.MARGIN.RIGHT,
          vis.HEIGHT + vis.MARGIN.TOP + vis.MARGIN.BOTTOM,
        ],
      ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
      .on('start end', e => vis.setBrushEvent(e)) // Each time the brush selection changes, trigger the 'updateChart' function

    vis.svg.call(vis.brush)

    vis.TOTAL_WIDTH = vis.WIDTH + vis.MARGIN.LEFT + vis.MARGIN.RIGHT
    vis.TOTAL_HEIGHT = vis.HEIGHT + vis.MARGIN.TOP + vis.MARGIN.BOTTOM

    vis.setPanBox(vis)
  }

  update({ data, nodeId, parentId, onSelectActiveNode }) {
    let vis = this
    vis.onSelectActiveNode = onSelectActiveNode

    if (!isEqual(data, vis.data)) {
      vis.g.selectAll('*').remove()
      vis.data = data || vis.data
      vis.root = vis.partition(vis.data)
      vis.root.each(d => (d.current = d))

      vis.path = vis.g
        .append('g')
        .selectAll('path')
        .data(vis.root.descendants().slice(1), d => d.data.name)
        .join('path')
        .attr('fill', d => {
          while (d.depth > 1) d = d.parent
          return vis.color(d.data.name)
        })
        .attr('fill-opacity', d =>
          d.current.y0 === 1 ? 0.6 : d.current.y0 === 2 ? 0.8 : d.current.y0 === 3 ? 1 : 0
        )
        .attr('pointer-events', d => (d.current.y0 <= 3 ? 'all' : 'none'))
        .attr('stroke', '#f8fafc')
        .attr('stroke-width', d => (d.current.y0 <= 3 ? '2px' : '0px'))
        .attr('d', d => vis.arc(d.current))
    }
  }
}

export default D3Chart

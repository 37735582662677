import { useMutation } from '@apollo/client'
import { Typography } from '@material-ui/core'
import React from 'react'
import { useToasts } from 'react-toast-notifications'
import SendContactMailDialog, { IContactMail } from '../components/SendContactMailDialog'
import { useUserContext } from '../routers/InitUserWrapper'
import { SEND_CONTACT_US_REQUEST } from '../store/operations/mutations/CreateContactUsRequest'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SendContactMailContainer({ open, setOpen }: Props) {
  const [sendFeedback, { loading: requestLoading }] = useMutation(SEND_CONTACT_US_REQUEST)
  const { user } = useUserContext()
  const { addToast } = useToasts()

  const handleRequest = async (value: IContactMail, userType: 'StartUp' | 'Client') => {
    await sendFeedback({
      variables: {
        input: {
          lastName: value.lastName,
          firstName: value.firstName,
          contactContent: value.content,
          userType: userType,
        },
      },
    })
      .then(res => {
        setOpen(false)
      })
      .catch(res => {
        addToast(
          <Typography style={{ fontSize: '14px' }}>Failed to send feedback mail</Typography>,
          {
            appearance: 'error',
          }
        )
      })
  }

  return (
    <SendContactMailDialog
      handleAgree={async (value: IContactMail) => {
        await handleRequest(value, !!user.profile?.companyId ? 'StartUp' : 'Client')
      }}
      defaultValue={{
        lastName: user.profile?.lastName || '',
        firstName: user.profile?.firstName || '',
        content: '',
        email: user.profile?.email || '',
      }}
      open={open}
      setOpen={setOpen}
    />
  )
}

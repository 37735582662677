import { createSlice } from '@reduxjs/toolkit'
import {
  EModeName,
  ICompanyDealFilter,
  ISmartCluster,
  ISubFilter,
} from '../../../types/CompanyDealFilter'
import { ESwitchFemale } from '../../../types/Filter'
import { mergeDeeps } from '../../../utils/TaxonomyMapping'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../../../__generated__/globalTypes'
import { isEqual } from 'lodash'
import { FCT_SCORE_OVERALL_KEY } from 'store/cache/CompaniesList'
import { IRealValue } from './../../../components/Slider/FCTSlider'

export const initSubFilter: ISubFilter = {
  companyIds: [],
  compareType: null,
  currentCompanyName: null,
  product: null,
  businessLines: null,
  valueChains: null,
  operatingModel: null,
}

export const initSmartClusterDistance = '2+'

export const initSmartCluster: ISmartCluster = {
  keywords: null,
  keywordsExclude: null,
  keywordsInclude: null,
  similarSelectedCompanies: null,
  distance: initSmartClusterDistance,
}

export const initCompanyDealFilter: ICompanyDealFilter = {
  category: InternalDbCompanyTypes.all,
  businessLines: null,
  risks: null,
  valueChains: null,
  products: [],
  operatingModels: [],
  productParentId: null, // Note: have not used at any where so far
  regions: [],
  subRegions: [],
  subRegion2s: [],
  countries: [],
  businessModelIds: [0],
  distributionModelIds: null,
  companyStatuses: null,
  numOfEmployeesRanges: {
    start: '1',
    end: '10001+',
  },
  switchFemalePicker: ESwitchFemale.CustomCount,
  numOfFemaleFounderFrom: '',
  numOfFemaleFounderTo: '',
  numOfFemaleFounderPercentFrom: '',
  numOfFemaleFounderPercentTo: '',
  yearFoundedRange: false,
  foundedYearFrom: null,
  foundedYearTo: null,
  latestFundingRound1s: [],
  latestFundingRound2s: [],
  fundingRound1s: [],
  fundingRound2s: [],
  totalFundingFrom: '',
  totalFundingTo: '',
  equityFundingFrom: '',
  equityFundingTo: '',
  latestDealAmountFrom: '',
  latestDealAmountTo: '',
  dealAmountFrom: '',
  dealAmountTo: '',
  yearFundingRange: false,
  fundingDateFrom: null,
  fundingDateTo: null,
  investors: [],
  investorsTypes: [],
  leadInvestors: [],
  leadInvestorTypes: [],
  numberOfInvestorsRanges: {
    start: '0',
    end: 0,
  },
  numberOfFundingRounds: {
    start: '0',
    end: 0,
  },
  acquirerTypes: [],
  acquirers: [],
  acquisitionYearRange: false,
  acquiredDateFrom: null,
  acquiredDateTo: null,
  orderByCompany: [{ field: FCT_SCORE_OVERALL_KEY, direction: 'desc' }],
  orderByDeal: [
    { field: 'deal_amount', direction: 'desc' },
    { field: 'acquisition_amount', direction: 'desc' },
    { field: 'ipo_amount', direction: 'desc' },
  ],
  searchData: {
    options: [],
  },
  viewBy: GlobalFilterViewBy.product,
  selectedProductId: null,
  selectedBusinessLineId: null,
  tagGroupIds: [],
  includedEntities: [],
  wentPublicOnDateFrom: null,
  wentPublicOnDateTo: null,
  smartCluster: initSmartCluster,
  modeName: null,
}

export interface ICompanyDealSlice {
  filter: ICompanyDealFilter
  subFilter: ISubFilter
}
const companyDealSlice = createSlice({
  name: 'companyDeal',
  initialState: {
    filter: initCompanyDealFilter,
    subFilter: initSubFilter,
  },
  reducers: {
    updateCompanyDealFilter: (state, action) => {
      const newFilter = mergeDeeps(state.filter, action.payload)
      if (!isEqual(state.filter, newFilter)) {
        state.filter = newFilter
      }
    },
    updateCompanyDealSearchOption: (state, action) => {
      state.filter = {
        ...state.filter,
        searchData: {
          ...state.filter.searchData,
          options: action.payload,
        },
      }
    },
    clearCompanyDealSearchOption: (state, action) => {
      state.filter = { ...state.filter, searchData: { options: [] } }
    },
    updateSubFilter: (state, action) => {
      state.subFilter = mergeDeeps(state.subFilter, action.payload)
    },
  },
})
export const {
  updateCompanyDealFilter,
  clearCompanyDealSearchOption,
  updateCompanyDealSearchOption,
  updateSubFilter,
} = companyDealSlice.actions
export default companyDealSlice.reducer

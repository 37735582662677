import { Action } from 'redux-actions'
import { ofType } from 'redux-observable'
import { RootState } from '../../store'
import { concat, Observable, of } from 'rxjs'
import { switchMap, filter, withLatestFrom } from 'rxjs/operators'
import { RouterName, routerNameMap, updateRouterName, updateRouter } from './RouterSlice'
import { onOpenPage } from './RouterAction'

const updateRouterEpic = (action$: Observable<Action<any>>, store$: Observable<RootState>) =>
  action$.pipe(
    ofType(updateRouter.type),
    withLatestFrom(store$),
    switchMap(([action, store]) => {
      const page = routerNameMap.find(page => (action?.payload?.path || '').includes(page.key))
      const newRouterName = page?.name ? page.name : RouterName.UNKNOWN
      const actions = []
      if (store?.routerReducer?.routerName !== newRouterName) {
        actions.push(of(updateRouterName(newRouterName)))
        actions.push(of(onOpenPage(newRouterName)))
      }
      return concat(...actions)
    })
  )

export default [updateRouterEpic]

import { makeStyles, Popover, PopoverOrigin, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { CheckboxTree } from 'components/CheckboxTree/CheckboxTree'
import { TooltipDescription } from 'components/Tooltip/TooltipDescription'
import React, { useEffect, useRef, useState } from 'react'
import { IOriginalData, ITreeData } from 'types/Tree'
import theme from '../../../theme'
import FCTButton from '../../Button/FCTButton'
import { FilterItem } from '../../Filters/FilterItem'
import Flex from '../../Flex/Flex'
import FCTIcon from '../../Icon/Icon'
import { RadioItem } from '../../Profile/Sidebar/Top10FilterDialog'
import FCTSearchInput from '../../SearchInput/FCTSearchInput'
import { FCTOption } from '../FCTSelect'

const useStyles = (props: FCTSelectRadioProps) =>
  makeStyles(theme => ({
    popover: {
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.grey[200]}`,
      boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.06)',
      borderRadius: 3,
      padding: '8px 0',
    },
    root: {
      maxHeight: 300,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey['300'],
        borderRadius: 10,
      },
      scrollbarWidth: 'thin',
      scrollbarColor: theme.palette.grey['300'],
    },
    buttonMore: {
      width: '100%',
      minWidth: 120,
      height: 40,
      padding: '8px 14px',
      border: `1px solid ${theme.palette.divider}`,
      fontWeight: 'normal',
      '&::before': {
        content: `"${props.headLabel || ''}"`,
        display: props.headLabel ? 'block' : 'none',
        position: 'absolute',
        left: 10,
        top: -10,
        height: 12,
        backgroundColor: theme.palette.primary.contrastText,
        padding: '0 5px',
      },
    },
    padding: {
      padding: '6px 14px',
    },
    option: {
      height: 22,
      display: 'flex',
      alignItems: 'center',
      padding: '6px 14px',
      borderRadius: 3,
      cursor: 'pointer',

      '&.disabled': {
        opacity: 0.5,
        cursor: 'default',
      },
      '&.active': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    optionHover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    text: {
      fontWeight: 'normal',
      color: theme.palette.grey[800],
    },
    paddingRadio: {
      padding: '6px 7px',
    },
    extraText: {
      color: theme.palette.grey[800],
      marginRight: 4,
    },
    valueStyle: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '300px',
      color: theme.palette.grey[800],
      fontWeight: props.extraText || props.boldTextValue ? 'bold' : 'normal',
    },
    hoverTitle: {
      '&:hover $infoDescription': {
        opacity: 1,
      },
    },
    infoDescription: {
      opacity: 0,
    },
  }))

export const FCTSelectRadio = (props: FCTSelectRadioProps) => {
  const classes = useStyles(props)()
  const {
    data,
    value,
    dataGroup,
    dataTree = [],
    formatValue,
    setValue = () => {},
    iconArrow = true,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    },
    type = 'single',
    setFilterIds = () => {},
    placeholder,
    isSearch = false,
    inputInRadio,
    radioValueKeepDropdown,
    buttonCSS = {},
    isDescription = false,
    placeholderValue,
    fullWidthPercent = 1,
    size = 'medium',
    containerStyle = {},
    extraText = '',
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  const ref = useRef(null)
  //current width button - padding option
  const widthPopup = Math.max((ref?.current as any)?.clientWidth, 310 * fullWidthPercent)

  const dataOption =
    data?.filter(x => x.label?.toLowerCase()?.includes(searchValue.toLowerCase())) || []

  useEffect(() => {
    anchorEl && setSearchValue('')
  }, [anchorEl])

  const label = data
    .filter(e => value?.includes(e.value))
    .map(e => e.label)
    .join(' ,')

  return (
    <>
      <div ref={ref} style={containerStyle}>
        <FCTButton
          className={classes.buttonMore}
          style={buttonCSS}
          onClick={(event: any) => {
            setAnchorEl(event.currentTarget)
          }}
          role="btnSelect"
          endIcon={
            iconArrow && (
              <FCTIcon
                name="arrowdown"
                width={10}
                height={10}
                style={{
                  marginLeft: 8,
                  transform: Boolean(anchorEl) ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                color={theme.palette.grey[400]}
              />
            )
          }
        >
          <Typography
            className={classes.extraText}
            style={{ fontSize: size === 'small' ? 12 : 14 }}
          >
            {extraText + ' '}
          </Typography>
          <Typography
            className={classes.valueStyle}
            style={{ fontSize: size === 'small' ? 12 : 14 }}
            data-testid="selectLabel"
          >
            {formatValue || label || placeholderValue}
          </Typography>
        </FCTButton>
      </div>

      <Popover
        classes={{ paper: classes.popover }}
        PaperProps={{ style: { marginTop: 8, width: widthPopup } }}
        id={Boolean(anchorEl) ? 'simple-popover' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {isSearch && (
          <div className={classes.padding}>
            <FCTSearchInput
              handleSearch={setSearchValue}
              resetSearch={() => setSearchValue('')}
              search={searchValue}
              placeholder={placeholder}
            />
          </div>
        )}
        <div className={classes.root}>
          {dataOption.length ? (
            <>
              {(() => {
                switch (type) {
                  case 'single':
                    return dataOption?.map((e, index) => (
                      <div
                        key={index}
                        className={clsx({
                          [classes.option]: true,
                          [classes.hoverTitle]: isDescription,
                          [classes.optionHover]: !e.disabled,
                          disabled: e.disabled,
                          active: value?.[0] === e.value,
                        })}
                        onClick={() => {
                          if (!e.disabled) {
                            setValue(e)
                            setAnchorEl(null)
                          }
                        }}
                        data-testid="singleItems"
                      >
                        <div
                          className={classes.text}
                          style={{ fontSize: size === 'small' ? 12 : 14 }}
                        >
                          {e.label}
                        </div>
                        <TooltipDescription
                          description={e.description || ''}
                          style={classes.infoDescription}
                          isDescription={isDescription}
                          id={index}
                        />
                      </div>
                    ))

                  case 'multiple':
                    return (
                      <div className={classes.padding}>
                        {dataOption?.map((item: FCTOption, index: number) => (
                          <div key={index} data-testid="multipleItems">
                            <FilterItem
                              id={item.value}
                              name={item.label}
                              description={item.description}
                              filterIds={value}
                              isDescription={isDescription}
                              setFilterIds={setFilterIds}
                            />
                          </div>
                        ))}
                      </div>
                    )

                  case 'radio':
                    return (
                      <div className={classes.paddingRadio}>
                        {dataOption?.map((item: FCTOption, index: number) => (
                          <Flex key={index} alignItems="center" data-testid="radioItems">
                            <RadioItem
                              label={
                                <Typography
                                  className={classes.text}
                                  style={{ fontSize: size === 'small' ? 12 : 14 }}
                                >
                                  {item.label}
                                  <Typography
                                    style={{ fontSize: size === 'small' ? 12 : 14 }}
                                    className={classes.text}
                                    component="span"
                                    color="textSecondary"
                                  ></Typography>
                                </Typography>
                              }
                              checked={item.value === value?.[0]}
                              onChange={() => {
                                setValue(item)
                                item.value !== radioValueKeepDropdown && setAnchorEl(null)
                              }}
                            />
                          </Flex>
                        ))}
                        {inputInRadio}
                      </div>
                    )

                  case 'collapseCheckbox':
                    return (
                      <div className={classes.padding}>
                        <CheckboxTree
                          dataTree={dataGroup || []}
                          originalData={dataTree}
                          selectedIds={value}
                          setFilter={(value: (number | string)[]) =>
                            setFilterIds(value.map(i => i.toString()))
                          }
                          defaultExpanded={dataGroup?.map(i => i.id.toString())}
                          isDescription={true}
                          isCollapsible={true}
                          searchValue={searchValue}
                        />
                      </div>
                    )

                  default:
                    return null
                }
              })()}
            </>
          ) : (
            <div
              style={{ fontSize: size === 'small' ? 12 : 14 }}
              className={clsx(classes.text, classes.padding)}
            >
              No Options
            </div>
          )}
        </div>
      </Popover>
    </>
  )
}

export interface FCTSelectRadioProps {
  data: FCTOption[]
  value: string[]
  dataGroup?: ITreeData[]
  dataTree?: IOriginalData[]
  formatValue?: string
  setValue?: (value: FCTOption) => void
  setFilterIds?: (value: string[]) => void
  iconArrow?: boolean
  transformOrigin?: PopoverOrigin
  anchorOrigin?: PopoverOrigin
  type?: 'single' | 'multiple' | 'radio' | 'collapseCheckbox'
  placeholder?: string
  isSearch?: boolean
  inputInRadio?: React.ReactNode
  radioValueKeepDropdown?: string
  buttonCSS?: React.CSSProperties
  isDescription?: boolean
  placeholderValue?: string
  fullWidthPercent?: number
  size?: 'small' | 'medium'
  containerStyle?: React.CSSProperties
  headLabel?: string
  extraText?: string
  boldTextValue?: boolean
}

import { uniq } from 'lodash'
import { useMemo } from 'react'
import {
  GetDealFilterDataResult_getDealFilterData_fundingRound1s,
  GetDealFilterDataResult_getDealFilterData_fundingRound2s,
} from '../__generated__/GetDealFilterDataResult'

export const EquityFinancingId = 2
export const MAId = -1

export const getDealType = (
  fun1sIds: (string | number)[] | null,
  fun2sIds: (string | number)[] | null,
  fundingRound1s: GetDealFilterDataResult_getDealFilterData_fundingRound1s[],
  fundingRound2s: GetDealFilterDataResult_getDealFilterData_fundingRound2s[]
) => {
  const dealTypeTree = useMemo(() => {
    return fundingRound1s.map((fun1s: GetDealFilterDataResult_getDealFilterData_fundingRound1s) => {
      if (fun1s.id == EquityFinancingId) {
        return {
          ...fun1s,
          parentId: null,
          children: fundingRound2s.map(
            (fun2s: GetDealFilterDataResult_getDealFilterData_fundingRound2s) => ({
              ...fun2s,
              parentId: fun1s.id,
              children: [],
            })
          ),
        }
      }
      return {
        ...fun1s,
        parentId: null,
        children: [],
      }
    })
  }, [fundingRound1s])

  const dealTypeIds = useMemo(() => {
    return [...(fun1sIds || []), ...(fun2sIds || [])]
  }, [fun1sIds, fun2sIds])

  const dealTypeOriginal = useMemo(() => {
    const fundingRound1 = fundingRound1s.map(
      (item: GetDealFilterDataResult_getDealFilterData_fundingRound1s) => ({
        ...item,
        parentIds: [],
      })
    )
    const fundingRound2 = fundingRound2s.map(
      (item: GetDealFilterDataResult_getDealFilterData_fundingRound2s) => ({
        ...item,
        parentIds: [EquityFinancingId],
      })
    )
    return [...fundingRound1, ...fundingRound2]
  }, [fun1sIds, fun2sIds])
  return {
    dealTypeTree,
    dealTypeIds,
    dealTypeOriginal,
  }
}

export const setDealTypeFilter = (
  value: (number | string)[],
  fundingRound1s: GetDealFilterDataResult_getDealFilterData_fundingRound1s[],
  fundingRound2s: GetDealFilterDataResult_getDealFilterData_fundingRound2s[],
  tagFunding1s?: string,
  tagFunding2s?: string
) => {
  const tag1s = !!tagFunding1s ? tagFunding1s : 'fundingRound1s'
  const tag2s = !!tagFunding2s ? tagFunding2s : 'fundingRound2s'
  const fundingRound1sId = fundingRound1s
    .filter((item: GetDealFilterDataResult_getDealFilterData_fundingRound1s) =>
      value.includes(item.id)
    )
    .map((item: GetDealFilterDataResult_getDealFilterData_fundingRound1s) => item.id)
  const fundingRound2sId = fundingRound2s
    .filter((item: GetDealFilterDataResult_getDealFilterData_fundingRound2s) =>
      value.includes(item.id)
    )
    .map((item: GetDealFilterDataResult_getDealFilterData_fundingRound2s) => item.id)
  return {
    [tag1s]: fundingRound1sId.length > 0 ? fundingRound1sId : [],
    [tag2s]: fundingRound2sId.length > 0 ? fundingRound2sId : [],
  }
}

//TODO add Equity Financing
export const setEFDealType = (
  fundingRound1s: (number | string)[] | null,
  fundingRound2s: (number | string)[] | null
) => {
  if (!!fundingRound2s?.length) return uniq([...(fundingRound1s || []), EquityFinancingId])
  return fundingRound1s
}

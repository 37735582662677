import { makeStyles, Theme } from '@material-ui/core'
import FCTButton from 'components/Button/FCTButton'

const useStyles = makeStyles((theme: Theme) => ({
  wrapperBtn: {
    marginTop: 20,
  },
}))

export const ApplyButton = ({ onClick }: { onClick: () => void }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapperBtn}>
      <FCTButton variant="outlined" color="primary" onClick={onClick} size="small">
        Apply
      </FCTButton>
    </div>
  )
}

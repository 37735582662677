import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

const RedirectPage = () => {
  const { search } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const query = new URLSearchParams(search)

  useEffect(() => {
    const callback = query.get('cb') as string
    const action = query.get('action') as string
    if (action) {
      dispatch({ type: action })
    }
    history.replace(callback)
  }, [query])

  return <></>
}
export default RedirectPage

import { useDispatch, useSelector } from 'react-redux'
import { EInvestorProfilePortfolioTab } from 'types/InvestorProfile'
import { selectSelectedTab } from './InvestorProfileSelector'
import { updateSelectedTab } from './InvestorProfileSlice'

const useInvestorProfileTab = () => {
  const dispatch = useDispatch()
  const selectedTab = useSelector(selectSelectedTab)

  const setSelectedTab = (payload: EInvestorProfilePortfolioTab) => {
    dispatch(updateSelectedTab(payload))
  }

  return { selectedTab, setSelectedTab }
}

export { useInvestorProfileTab }

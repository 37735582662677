import { useQuery } from '@apollo/client'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Replay } from '@material-ui/icons'
import IPOItem from 'components/ExploreDBComponent/IPOItem'
import React, { useEffect, useRef } from 'react'
import FinancingItem from '../../components/ExploreDBComponent/FinancingItem'
import InvestorItem from '../../components/ExploreDBComponent/InvestorItem'
import MAItem from '../../components/ExploreDBComponent/M&AItem'
import OverviewItem from '../../components/ExploreDBComponent/OverviewItem'
import { AccordionItem } from '../../components/Filters/AccordionItem'
import { GeographyFilter } from '../../components/Filters/GeographyFilter'
import FCTIcon from '../../components/Icon/Icon'
import FCTTab, { TooltipDesc } from '../../components/Tabs/FCTTab'
import FCTTabs from '../../components/Tabs/FCTTabs'
import { DEFAULT_DATA_MAX } from '../../containers/EDCompanyDealFilterContainer'
import { initialWatchlistFilter } from '../../store/features/watch-list/WatchListSlice'
import { GET_DEAL_FILTER_DATA } from '../../store/operations/queries/GetDealFilterData'
import { CountrySortBy, IAnalysisFilter } from '../../types/Filter'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '../../__generated__/GetDealFilterDataResult'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../../__generated__/globalTypes'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '366px',
    maxHeight: 'calc(100vh - 530px)',
    overflow: 'auto',
    padding: '0 15px 5px 15px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    position: 'relative',
    background: '#ffffff',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  group: {
    borderBottom: '1px solid #E7ECF3',
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '6px',
    borderBottom: '1px solid #E7ECF3;',
  },
  closeFilter: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  reset: {
    color: '#FF9F19',
    textTransform: 'unset',
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    margin: '10px 0',
  },
  tabsRow: {
    marginLeft: 0,
    display: 'flex',
    alignItems: 'center',
  },
  tabsGroup: {
    width: '100%',
  },
}))

const customStyleFilter = {
  title: { padding: '10px 0' },
}

export const WatchListFilterConditions = (props: WatchListFilterConditionsProps) => {
  const { filter, setWatchListFilterCreating, open } = props
  const classes = useStyles()
  const childRef = useRef<any>()

  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA, {
    variables: {
      input: { countrySortBy: CountrySortBy.countCompany },
    },
  })
  const filterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData

  useEffect(() => {
    open && resetFilters()
  }, [open])

  const handleChange = (evt: React.SyntheticEvent) => {
    const target = evt.target as HTMLInputElement
    if (target) {
      const value = target.type === 'checkbox' ? target.checked : target.value
      setWatchListFilterCreating({ [target.name]: value })
    }
  }

  const resetFilters = () => {
    if (childRef?.current?.reset) {
      childRef.current.reset()
    }
    setWatchListFilterCreating({
      ...initialWatchlistFilter,
      category: filter.category,
      numberOfInvestorsRanges: {
        start: '0',
        end: String(filterData?.maxNumOfInvestors || DEFAULT_DATA_MAX),
      },
      numberOfFundingRounds: {
        start: '0',
        end: String(filterData?.maxNumOfFundingRounds || DEFAULT_DATA_MAX),
      },
    })
  }

  const changeInternalCompany = (
    event: React.ChangeEvent<{}>,
    newValue?: InternalDbCompanyTypes
  ) => {
    if (filter.category != newValue) {
      setWatchListFilterCreating({
        viewBy: GlobalFilterViewBy.product,
        category: newValue,
        businessLines: null,
        risks: null,
        valueChains: null,
        products: [],
        productParentId: null,
        selectedProductId: null,
        selectedBusinessLineId: null,
      })
      event.stopPropagation()
    }
  }

  return (
    <>
      <div className={classes.tabContainer}>
        <div className={classes.tabsRow}>
          <FCTTabs
            size="small"
            value={filter.category}
            onChange={changeInternalCompany}
            className={classes.tabsGroup}
          >
            <FCTTab
              size="small"
              label="All Industries"
              value={InternalDbCompanyTypes.all}
              style={{ padding: '0 13px' }}
              tooltip={TooltipDesc.AllIndustries}
            />
            <FCTTab
              size="small"
              label="FinTech"
              value={InternalDbCompanyTypes.fintech}
              style={{ padding: '0 13px' }}
              tooltip={TooltipDesc.FinTech}
            />
            <FCTTab
              size="small"
              label="RegTech"
              value={InternalDbCompanyTypes.regtech}
              style={{ padding: '0 13px' }}
              tooltip={TooltipDesc.RegTech}
            />
            <FCTTab
              size="small"
              label="InsurTech"
              value={InternalDbCompanyTypes.insurtech}
              style={{ padding: '0 13px' }}
              tooltip={TooltipDesc.InsurTech}
            />
          </FCTTabs>
        </div>
      </div>
      <div className={classes.filterHeader}>
        <div className={classes.closeFilter}>
          <FCTIcon name="filter" color="#6C809D" />
          <Typography style={{ fontSize: '14px', marginLeft: '8px' }}>Filter</Typography>
        </div>
        <Button
          className={classes.reset}
          variant="text"
          startIcon={<Replay />}
          onClick={resetFilters}
        >
          Reset
        </Button>
      </div>
      <div className={classes.container}>
        <OverviewItem
          filter={filter}
          setFilter={setWatchListFilterCreating}
          handleChange={handleChange}
          getFilterData={filterData}
          companyStatusesData={filterData?.companyStatuses}
          customStyle={customStyleFilter}
        />
        <div className={classes.group}>
          <AccordionItem title="HQ Geography" customStyle={customStyleFilter}>
            <GeographyFilter
              filter={filter}
              setFilter={setWatchListFilterCreating}
              filterData={filterData}
              ref={childRef}
            />
          </AccordionItem>
        </div>
        <InvestorItem
          filter={filter}
          setFilter={setWatchListFilterCreating}
          handleChange={handleChange}
          getFilterData={filterData}
          customStyle={customStyleFilter}
        />
        <FinancingItem
          filter={filter}
          setFilter={setWatchListFilterCreating}
          handleChange={handleChange}
          getFilterData={filterData}
          customStyle={customStyleFilter}
        />
        <MAItem
          filter={filter}
          setFilter={setWatchListFilterCreating}
          handleChange={handleChange}
          getFilterData={filterData}
          customStyle={customStyleFilter}
        />

        <IPOItem
          filter={filter}
          setFilter={setWatchListFilterCreating}
          handleChange={handleChange}
          getFilterData={filterData}
        />
      </div>
    </>
  )
}

interface WatchListFilterConditionsProps {
  open: boolean
  filter: IAnalysisFilter
  setWatchListFilterCreating: (value: any) => void
}

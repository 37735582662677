import { makeStyles } from '@material-ui/core/styles'
import { EXPAND_ROUND1_ID } from 'core/constants/companyFilter'
import React, { useEffect, useState } from 'react'
import { ITreeData } from 'types/Tree'
import { DEFAULT_DATA_MAX } from '../../../containers/EDCompanyDealFilterContainer'
import { ICompanyDealFilter } from '../../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../../types/Filter'
import { getDealType, setDealTypeFilter } from '../../../utils/DealType'
import { GetDealFilterDataResult_getDealFilterData } from '../../../__generated__/GetDealFilterDataResult'
import { CheckboxTree, ITreeNode } from '../../CheckboxTree/CheckboxTree'
import { FCTDateRangePicker } from '../../DateRangePicker/FCTDateRange'
import FCTDropdownListItem from '../../DropdownListItem/FCTDropdownListItem'
import FCTSlider, { IRealValue } from '../../Slider/FCTSlider'
import TotalRangeValue from './TotalRangeValue'

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: theme.palette.grey['800'],
    fontSize: 12,
    fontWeight: 600,
  },
  flex: {
    flex: 1,
  },
  container: {
    borderBottom: '1px solid #E7ECF3',
  },
}))

export const initIsDealType = {
  dealType: [],
  allDealType: [],
}

const AllDealItem = (props: IAllDealItemProps) => {
  const { filter, setFilter, getFilterData, disabledFundingDate } = props

  const [isDealType, setIsDealType] = useState<{
    dealType: ITreeNode[] | null
    allDealType: ITreeNode[] | null
  }>(initIsDealType)

  const fundingRound1s = getFilterData?.fundingRound1s || []
  const fundingRound2s = getFilterData?.fundingRound2s || []

  const setAllDealType = (value: (number | string)[]) => {
    setFilter({
      latestFundingRound1s: [],
      latestFundingRound2s: [],
      ...setDealTypeFilter(
        value,
        fundingRound1s,
        fundingRound2s,
        'fundingRound1s',
        'fundingRound2s'
      ),
    })
  }

  useEffect(() => {
    if (
      filter.fundingRound1s?.length ||
      filter.fundingRound2s?.length ||
      filter.latestFundingRound1s?.length ||
      filter.latestFundingRound2s?.length
    ) {
      if (filter.fundingRound1s?.length || filter.fundingRound2s?.length)
        setIsDealType({
          ...isDealType,
          dealType: null,
        })
      else
        setIsDealType({
          ...isDealType,
          allDealType: null,
        })
    } else setIsDealType(initIsDealType)
  }, [
    filter.fundingRound1s,
    filter.fundingRound2s,
    filter.latestFundingRound1s,
    filter.latestFundingRound2s,
  ])

  const dealType = getDealType(
    filter.latestFundingRound1s,
    filter.latestFundingRound2s,
    fundingRound1s,
    fundingRound2s
  )

  const allDealType = getDealType(
    filter.fundingRound1s,
    filter.fundingRound2s,
    fundingRound1s,
    fundingRound2s
  )

  const handleCheckLatestDeal = (item: ITreeData) => {
    const childrenIds = item.children?.map(el => el.id)
    return dealType.dealTypeIds.length &&
      !dealType.dealTypeIds.some(e => e == item.id || childrenIds?.includes(e))
      ? null
      : isDealType.dealType
  }

  return (
    <>
      <FCTDropdownListItem
        isOpen={true}
        title="All Deal Amount"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <TotalRangeValue
          data={filter}
          setFilter={setFilter}
          tagTotalTo="dealAmountTo"
          tagTotalFrom="dealAmountFrom"
        ></TotalRangeValue>
      </FCTDropdownListItem>
      <FCTDropdownListItem
        isOpen={true}
        title="All Deal Type"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <CheckboxTree
          originalData={allDealType.dealTypeOriginal}
          selectedIds={allDealType.dealTypeIds}
          dataTree={allDealType.dealTypeTree.filter(
            e => e.id !== EXPAND_ROUND1_ID.IPO && e.id !== EXPAND_ROUND1_ID.MA
          )}
          setFilter={setAllDealType}
          enableNodes={isDealType.allDealType}
        />
      </FCTDropdownListItem>
      <FCTDropdownListItem
        isOpen={true}
        title="Funding Date"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <FCTDateRangePicker
          filter={filter}
          setFilter={setFilter}
          label=""
          placeholder="Select date"
          tagDateFrom="fundingDateFrom"
          tagDateTo="fundingDateTo"
          disabled={disabledFundingDate}
        />
      </FCTDropdownListItem>
      <FCTDropdownListItem
        isOpen={true}
        title="Number of Funding Rounds"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <FCTSlider
          defaultRealValue={filter.numberOfFundingRounds}
          min={0}
          max={getFilterData?.maxNumOfFundingRounds || DEFAULT_DATA_MAX}
          step={getFilterData?.maxNumOfFundingRounds || DEFAULT_DATA_MAX}
          onChangeRealValue={(v: IRealValue) =>
            setFilter({ numberOfFundingRounds: { start: String(v.start), end: String(v.end) } })
          }
        />
      </FCTDropdownListItem>
    </>
  )
}

export default AllDealItem

interface IAllDealItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  disabledFundingDate?: boolean
}

import React, { CSSProperties } from 'react'
import { EChartPosition } from '../../containers/DashboardChartContainer'
import { ChartFootNote } from '../../types/FootNote'
import ChartWrapper from '../ChartWrapper'
import LinedStackedBar from './LineStackedBar'

const stackedBarSchemeSet = [
  '#173E59',
  '#B775B3',
  '#7160A3',
  '#CE618E',
  '#EF8251',
  '#F3A93C',
  '#C70039',
  '#008E88',
  '#62C5F3',
  '#9400F9',
  '#968830',
  '#6495ED',
  '#6CAB57',
  '#C5D275',
  '#F9DB7F',
  '#9A6324',
  '#99AEBA',
  '#1451C7',
  '#C9BCE2',
  '#FF6766',
]

export const EXTRA_HEIGHT_CHART_ANALYSIS = -10

export interface LinedStackedBarChartData {
  from?: string | null
  to?: string | null
  barIndex?: number | null
  yearGroup?: number | null
  lineChartCount?: number | null
  total?: number | null
  round?: string | null
}

export interface LineChartData {
  y: number
  x: number
  text: string
}

export interface ICompanies {
  company_id: number | null
  logo_url: string | null
  name?: string | null
}

export interface TopCompaniesToolTipContent {
  companies: ICompanies[]
  nameList: string
  children?: string
}

interface Props<T, D> {
  data: T[]
  getValue: (d: T, key: string) => number | string
  axis: Partial<Record<'x' | 'y1' | 'y2', { label: string }>>
  getTipData?: (d: T, key: string) => D | null | undefined
  tipLayout?: (e: MouseEvent, d: D, type: string, navigate: string, topCompanies: string) => string
  topCompaniesToolTipContent?: (e: MouseEvent, d: D, type: string) => TopCompaniesToolTipContent
  keys: string[]
  lineChartData?: LineChartData[]
  format(v: number, d: d3.SeriesPoint<any>, type?: string): string
  hasNote?: boolean
  hasNoteTopLabels?: boolean
  hasTopLabel?: boolean
  xTickFormat?(value: number): string
  yTickFormat?(value: number): string
  hasLabelVertical?: boolean
  periodTypeDisplay?: string
  lineChartLabel?: string
  extraWidth?: number
  extraHeight?: number
  hasLabelX?: boolean
  navigationTip?: (data: T, d: D, navigate: string) => void
  navigationTipTopLabel?: (data: T, navigate: string) => void
  typeChart?: EChartPosition
  navigateProfile: Function
  fieldNameDrawYAxis: string
  isAlignLeft?: boolean
  breakdownStatus?: boolean
  isDownLoadPptx?: boolean
  chartType?: ChartFootNote
  orderBarsFn?: (a: D, b: D, d: T) => number
  style?: CSSProperties
  extraSpacing?: number
  hasTooltip?: boolean
  scrollable?: boolean
}

function LinedStackedBarChart<T extends LinedStackedBarChartData, D>({
  data,
  getValue,
  axis,
  tipLayout,
  topCompaniesToolTipContent,
  getTipData,
  keys,
  lineChartData,
  format,
  hasNote,
  hasNoteTopLabels,
  hasTopLabel,
  xTickFormat,
  yTickFormat,
  hasLabelVertical,
  periodTypeDisplay,
  lineChartLabel,
  extraWidth,
  extraHeight = EXTRA_HEIGHT_CHART_ANALYSIS,
  hasLabelX = false,
  navigationTip = () => {},
  navigationTipTopLabel = () => {},
  typeChart = EChartPosition.Analysis,
  navigateProfile,
  fieldNameDrawYAxis,
  isAlignLeft,
  breakdownStatus = true,
  isDownLoadPptx,
  chartType,
  orderBarsFn,
  style,
  extraSpacing = 10,
  hasTooltip = true,
  scrollable = false,
}: Props<T, D>) {
  let originalYear = 0
  const dataGroupXAxis = React.useMemo(
    () =>
      data
        .filter((item, i) => {
          if (i === 0 || item.yearGroup !== originalYear) {
            originalYear = item.yearGroup || 0
            return true
          }
        })
        .map(item => ({ barIndexGroup: item.barIndex, yearGroup: item.yearGroup })),
    [data]
  )

  return (
    <ChartWrapper
      style={style}
      Chart={LinedStackedBar}
      initProps={{
        data,
        keys,
        getValue,
        axis,
        tipLayout,
        topCompaniesToolTipContent,
        getTipData,
        lineChartData,
        format,
        hasTopLabel,
        lineChartLabel,
        hasLabelVertical,
        hasNote,
        hasNoteTopLabels,
        navigateProfile,
        fieldNameDrawYAxis,
        isAlignLeft,
        breakdownStatus,
        isDownLoadPptx,
        chartType,
        orderBarsFn,
        extraSpacing,
        hasTooltip,
        typeChart,
        scrollable,
      }}
      updateProps={{
        data,
        keys,
        lineChartData,
        tipLayout,
        topCompaniesToolTipContent,
        xTickFormat,
        yTickFormat,
        dataGroupXAxis,
        periodTypeDisplay,
        navigationTip,
        navigationTipTopLabel,
        typeChart,
        navigateProfile,
        isAlignLeft,
        breakdownStatus,
        isDownLoadPptx,
        extraSpacing,
        orderBarsFn,
        scrollable,
        fieldNameDrawYAxis,
        axis,
      }}
      margin={{
        TOP: typeChart != EChartPosition.Analysis ? 50 : 60,
        BOTTOM: 60,
        LEFT: 70,
        RIGHT: hasLabelX ? 112 : !!lineChartLabel ? 70 : 30,
      }}
      extraHeight={typeChart != EChartPosition.Analysis ? 0 : extraHeight}
      extraWidth={extraWidth}
    />
  )
}

export default LinedStackedBarChart
export { LinedStackedBar, stackedBarSchemeSet }

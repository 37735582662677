import { Theme, Typography } from '@material-ui/core'
import styled from 'styled-components'
import theme from '../../theme'
import FCTCheckbox from '../Checkbox/FCTCheckbox'

const DropDownListContainer = styled('div')`
  border-radius: 3px;
`

const DropDownList = styled('ul')`
  padding: 0;
  margin: 6px 0 12px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.palette.grey[200]};
    border-right: 5px white solid;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }
`
const ListItem = styled('li')`
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    ${({ theme }: { theme: Theme }) => `
    background: ${theme.palette.grey[100]};`}
`

const FCTListCheckbox = ({
  values,
  setValues,
  data,
  searchValue,
  customerStyleDropList = {},
}: FCTListCheckboxProps) => {
  // Keep state of search string
  const options = data.filter((x: any) => x.name?.toLowerCase().includes(searchValue.toLowerCase()))

  const selectItem = (selectedList: string[], value: string) => {
    setValues([...selectedList, value])
  }

  const deselectItem = (selectedList: string[], value: string) => {
    setValues(selectedList.filter(item => item !== value))
  }

  const toggleItem = (selectedList: string[], value: string) => {
    if (selectedList.includes(value)) {
      deselectItem(selectedList, value)
    } else selectItem(selectedList, value)
  }

  return (
    <DropDownListContainer>
      <DropDownList style={customerStyleDropList}>
        {options.map((opt, index) => (
          <ListItem key={`${opt.id}_${index}`} onClick={() => toggleItem(values, opt.id)}>
            <FCTCheckbox
              style={{ padding: '6px 6px 6px 0' }}
              color="primary"
              checked={values.includes(opt.id)}
            />
            <Typography
              variant="caption"
              style={{
                paddingLeft: 0,
                fontSize: '12px',
              }}
            >
              {opt.name}
            </Typography>
          </ListItem>
        ))}
      </DropDownList>
    </DropDownListContainer>
  )
}

export default FCTListCheckbox

export type FCTListCheckboxProps = {
  values: string[]
  setValues(v: string[]): void
  data: FCTOption[]
  searchValue: string
  customerStyleDropList?: React.CSSProperties
}

export type FCTOption = {
  id: string
  name: string
}

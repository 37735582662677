import { Box, FormControl, makeStyles, Theme } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Select from '@material-ui/core/Select/Select'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete/Autocomplete'
import { uniqBy } from 'lodash'
import {
  forwardRef,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useOutSideDiv } from '../../core/hooks/useOutSideDiv'
import { IHit } from '../../store/operations/queries/local/rest/RestfulResponse'
import { ISearchInfo } from '../../store/operations/queries/local/rest/Suggest'
import { FCTAutoSuggest, FCTAutoSuggestProps } from '../AutoSuggest/FCTAutoSuggest'
import Flex from '../Flex/Flex'
import FCTIcon from '../Icon/Icon'
import { ChipItem } from '../Select/FCTSelect'
import { SearchFields, SearchMetaDataType } from './enums'
import clsx from 'clsx'
import { FieldBoostMap, HAVING_COUNT_FIELDS } from './consts'
import { ISearchInputDetail, ISearchInputOption } from './interfaces'
import { IFieldBoostMap } from './type'
import { keyToTag, keyToTagGroup } from './helpers'
import { OverviewSection } from './Overview/OverviewSection'
import { OptionsSection } from './Suggestion/OptionsSection'

const useStyles = makeStyles((theme: Theme) => ({
  chipRoot: {
    margin: 0,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: `${theme.palette.primary.contrastText}`,
    borderRadius: 'none',
    fontSize: '0.8rem',
    maxWidth: 'none',
    textOverflow: 'unset',
    '& .MuiChip-label': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    '&:hover .MuiChip-label': {
      paddingRight: 0,
    },
    '&:hover .MuiChip-deleteIcon': {
      display: 'block',
    },
  },
  selectContainer: {
    '&:before, &:after ': {
      content: 'unset',
    },
  },
  selectRoot: {
    minHeight: 'auto',
    padding: '0',
    lineHeight: '28px',
    fontSize: '0.75rem',
  },
  muiSelect: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  deleteIcon: {
    color: '#6C809D',
    backgroundColor: '#ffffff',
    width: '14px',
    height: '14px',
    display: 'none',
    padding: '1px 1px',
    margin: 0,
    position: 'relative',
    right: '13px',
    '&:hover': {
      color: '#C41300',
    },
  },
  deleteAllIcon: {
    color: '#6C809D',
    width: '14px',
    height: '14px',
    position: 'absolute',
    right: '24px',
    top: '50%',
    transform: 'translate(0, -50%)',
    marginRight: '16px',
    cursor: 'pointer',
    '&:hover': {
      color: '#C41300',
    },
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginRight: '8px',
    margin: '1px 0px',
  },
  searchContainer: {
    position: 'relative',
  },
  iconSearch: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(0, -50%)',
    color: '#94A3B8',
    marginRight: '16px',
    cursor: 'pointer',
  },
  option: {
    display: 'flex',
    width: '100%',
  },
  optionSection: {
    flex: '1',
  },
  moreTag: {
    width: 32,
    height: 26,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    background: '#fff',
    cursor: 'pointer',
    margin: '2px 3px',
    fontSize: 12,
  },
  root: {
    '&.MuiPopover-paper': {
      width: 60,
      height: 36,
    },
  },
  limitChip: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
      background: 'transparent',
    },
  },
  autoCompleteBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
  },
  overviewContainer: {
    padding: 20,
    borderLeft: '1px solid #E7ECF3',
    overflowY: 'auto',
    maxHeight: '50vh',
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))

/* Refer to: https://bcgplatinionwesa.atlassian.net/jira/software/projects/FB/boards/5?assignee=60009b3a1051d10075ecbe21&selectedIssue=FB-244
a.	Companies with   status = NOT closed
b.	All hits related to Taxonomy
c.	All hits related to Company name
d.	All hits related to Company alias
e.	All hits related to Company description
f.	All hits related to Geography
g.	All hits related to Acquirer / investor name or type
h.	All hits related to Website keywords (tag with source = "switchpitch")
h.	All hits related to Product names
*/

const onChangeClosure = (props: ISearchInputProps) => {
  return (value: ISearchInputOption) => {
    let currentValue = [...props.getValue, value]
    props.setValue(currentValue)
    props.onValueChange && props.onValueChange(currentValue, '')
    props.handleOnBlur && props.handleOnBlur()
  }
}

export type ISearchInputProps = FCTAutoSuggestProps<ISearchInputOption> & {
  onClear?: () => void
  enableLimitTags?: boolean
  refInput?: RefObject<HTMLDivElement>
}

export default ({ enableLimitTags = false, options, ...args }: ISearchInputProps) => {
  const classes = useStyles()

  const refInput = args?.refInput || useRef<HTMLDivElement | null>(null)
  const { limit, setLimit } = useLimitTags(refInput, enableLimitTags, args.getValue)

  const SuggestionContainer = useCallback(
    forwardRef(() => {
      const [selectedOption, setSelectedOption] = useState<ISearchInputDetail | null>(null)

      const optionDetails = useMemo(
        () =>
          (options || []).map(option => {
            const metaList = option.meta ? option.meta.map(meta => JSON.parse(meta)) : []
            const companyMetaData = metaList.filter(meta =>
              [SearchMetaDataType.COMPANY_ID, SearchMetaDataType.ALIAS].includes(meta.type)
            )
            const investorMetaData = metaList.filter(meta =>
              [SearchMetaDataType.INVESTOR, SearchMetaDataType.ACQUIRER].includes(meta.type)
            )

            const dealTypeMetaData = metaList.filter(meta =>
              [SearchMetaDataType.DEAL_TYPES, SearchMetaDataType.LATEST_DEAL_TYPE].includes(
                meta.type as SearchMetaDataType
              )
            )

            const investorTypeAndAcquirerTypeMetaData = metaList.filter(meta =>
              [SearchMetaDataType.INVESTOR_TYPES].includes(meta.type as SearchMetaDataType)
            )

            const otherMetaData = metaList.filter(meta =>
              [SearchMetaDataType.OTHERS].includes(meta.type as SearchMetaDataType)
            )

            return {
              ...option,
              companyMetaData,
              investorMetaData,
              dealTypeMetaData,
              investorTypeAndAcquirerTypeMetaData,
              otherMetaData,
            } as ISearchInputDetail
          }),
        [options]
      )

      const onNavigation = () => {
        selectedOption && onChangeClosure(args)(selectedOption)
      }
      const onNavigateTopResults = (data: any) => {
        onChangeClosure(args)(data)
      }
      return (
        <Box
          className={classes.autoCompleteBox}
          onMouseDown={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <OptionsSection options={optionDetails} onClick={setSelectedOption} />
          <Box className={clsx(classes.overviewContainer, classes.scroll)}>
            <OverviewSection
              onNavigation={onNavigation}
              option={selectedOption}
              onNavigateTopResults={onNavigateTopResults}
            />
          </Box>
        </Box>
      )
    }),
    [options]
  )

  return (
    <div className={classes.searchContainer} ref={refInput}>
      <FCTAutoSuggest
        {...(args as any)}
        options={options}
        generateOption={(name: string) => ({
          id: uuidv4(),
          name,
          operator: 'AND',
          field: 'text',
        })}
        ListboxComponent={SuggestionContainer}
        renderTags={(
          props: ISearchInputProps,
          __value: ISearchInputOption[],
          getTagProps: AutocompleteGetTagProps
        ) =>
          props.getValue.slice(0, limit || props.getValue.length).map((option, index) => (
            <Flex key={index} alignItems="center" className={'chip'}>
              <div className={classes.tagContainer}>
                {index > 0 && (
                  <FormControl>
                    <Select
                      className={classes.selectContainer}
                      value={option.operator}
                      IconComponent={KeyboardArrowDown}
                      classes={{
                        root: classes.selectRoot,
                        select: classes.muiSelect,
                      }}
                      onChange={event => {
                        const value = props.getValue.map((value: ISearchInputOption) =>
                          value.id == option.id
                            ? { ...value, operator: event.target.value as any }
                            : value
                        )
                        props.setValue(value)
                        props?.onValueChange && props?.onValueChange(value, 'update')
                      }}
                      onMouseDown={e => {
                        e.stopPropagation()
                        e.preventDefault()
                      }}
                    >
                      <MenuItem value="AND">and</MenuItem>
                      <MenuItem value="OR">or</MenuItem>
                    </Select>
                  </FormControl>
                )}

                <ChipItem
                  label={
                    <>
                      <strong>{`${option.name}`}</strong>{' '}
                      <span>{`(${keyToTag(option.field)})`}</span>
                    </>
                  }
                  size="small"
                  classes={{ root: classes.chipRoot, deleteIcon: classes.deleteIcon }}
                  {...getTagProps({ index })}
                />
              </div>

              {!!limit && index + 1 === limit && props.getValue.length - limit > 0 && (
                <ChipItem
                  label={`+${props.getValue.length - limit}`}
                  size="small"
                  className={classes.limitChip}
                  onClick={() => {
                    setLimit(undefined)
                  }}
                />
              )}
            </Flex>
          ))
        }
        renderOption={(props: ISearchInputProps, option: ISearchInputOption) => {
          return (
            <div
              className={classes.option}
              onMouseDown={e => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <div className={classes.optionSection}>
                <strong>{option.name}</strong>
              </div>
              <div className={classes.optionSection}>
                {['name', 'investor_names'].includes(option.field)
                  ? keyToTag(option.field)
                  : keyToTagGroup(option.field)}
              </div>
              <div className={classes.optionSection}>
                {['name', 'investor_names'].includes(option.field) ? '' : keyToTag(option.field)}
              </div>
            </div>
          )
        }}
        endAdornment={
          !args.getValue.length ? null : (
            <FCTIcon
              width={1}
              height={14}
              className={classes.deleteAllIcon}
              name="close1"
              onClick={() => {
                args.setValue([])
                args?.onValueChange && args?.onValueChange([], 'update')
                args?.onClear && args.onClear()
              }}
            />
          )
        }
        handleOnFocus={() => {
          setLimit(undefined)
          args.handleOnFocus && args.handleOnFocus()
        }}
      />
      <FCTIcon width={12} height={12} className={classes.iconSearch} name="search" />
    </div>
  )
}

export function mapSuggestToOption(hits: IHit<ISearchInfo>[] = []) {
  const result: any[] = []

  hits.map(hit => {
    const metaList = hit.fields.meta ? hit.fields.meta.map(meta => JSON.parse(meta)) : []
    const aliasList = metaList.filter(meta => meta.type == 'alias')
    aliasList.map(alias => {
      result.push({
        id: `${alias.value}_${alias.fields[0]}`,
        name: alias.value,
        operator: 'AND',
        field: alias.fields[0],
        ...(hit.fields.meta ? { meta: hit.fields.meta } : { meta: [] }),
        ...(FieldBoostMap[alias.fields[0] as keyof IFieldBoostMap]
          ? { boost: FieldBoostMap[alias.fields[0] as keyof IFieldBoostMap] }
          : {}),
      })
    })
    hit.fields.fields
      .filter(field => field != 'company_alias')
      .map((field: string) => {
        result.push({
          id: `${hit.fields.value[0]}_${field}`,
          name: hit.fields.value[0],
          operator: 'AND',
          field,
          ...(hit.fields.meta ? { meta: hit.fields.meta } : { meta: [] }),
          ...(FieldBoostMap[field as keyof IFieldBoostMap]
            ? { boost: FieldBoostMap[field as keyof IFieldBoostMap] }
            : {}),
        })
      })
  })
  return uniqBy(result, 'id')
}

const useLimitTags = (
  containerRef: RefObject<HTMLDivElement>,
  enableLimitTags: boolean,
  value: ISearchInputOption[]
) => {
  const dirty = useRef(false)
  const [limit, setLimit] = useState<number>()

  const getLimitTags = (containerRef: RefObject<HTMLDivElement>, enableLimitTags: boolean) => {
    if (!enableLimitTags) return undefined
    const PADDING_X = 44
    const INPUT_MIN_WIDTH = 100
    const chipsAccumulatedWidth = Array.from(containerRef?.current?.querySelectorAll('.chip') || [])
      ?.map(item => item.getBoundingClientRect().width)
      ?.reduce((acc: number[], cur) => {
        const v = acc.length ? acc[acc.length - 1] + cur : cur

        return [...acc, v]
      }, [])

    const containerWidth =
      (containerRef?.current?.getBoundingClientRect()?.width || Infinity) -
      PADDING_X -
      INPUT_MIN_WIDTH

    const overflowIndex = chipsAccumulatedWidth.findIndex(item => item > containerWidth)

    return overflowIndex > -1 ? overflowIndex : undefined
  }

  const handleOutSideDiv = useCallback(() => {
    if (enableLimitTags) {
      setLimit(prev => prev || getLimitTags(containerRef, true))
    }
  }, [containerRef, enableLimitTags])

  useLayoutEffect(() => {
    if (value.length) {
      if (!dirty.current) {
        setLimit(getLimitTags(containerRef, enableLimitTags))
      }
      dirty.current = true
    }
  }, [value, enableLimitTags])

  useOutSideDiv(containerRef, () => {
    handleOutSideDiv()
  })

  return { limit, setLimit }
}

import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { EInvestorProfilePortfolioTab } from 'types/InvestorProfile'

export interface IInvestorProfileSlice {
  selectedTab: EInvestorProfilePortfolioTab
}

const InvestorProfileSlice = createSlice<
  IInvestorProfileSlice,
  SliceCaseReducers<IInvestorProfileSlice>
>({
  name: 'investorProfile',
  initialState: {
    selectedTab: EInvestorProfilePortfolioTab.Investments,
  },
  reducers: {
    updateSelectedTab: (state, action: PayloadAction<EInvestorProfilePortfolioTab>) => {
      state.selectedTab = action.payload
    },
  },
})

export const { updateSelectedTab } = InvestorProfileSlice.actions

export default InvestorProfileSlice.reducer

import { flatMapDeep } from 'lodash'

export function getMembers(node) {
  const member = { ...node } // copy

  if (!node.children || !node.children.length) {
    return member // return copied
  }

  // return copied, but pass original to flatMapDeep
  return [member, flatMapDeep(node.children, getMembers)]
}

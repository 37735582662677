import { createStyles, makeStyles, TextareaAutosize, Theme } from '@material-ui/core'
import React, { useEffect } from 'react'
import { ETypeDialog, FCTDialog } from './Dialog/FCTDialog'
import Flex from './Flex/Flex'
import FCTTextField from './TextField/FCTTextField'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: 20,
      fontSize: 14,
      lineHeight: '22px',
      color: theme.palette.grey[800],
      fontWeight: 'normal',
    },
    textbox: {
      width: '50%',
      '&:first-child': {
        margin: '0 12px 12px 0',
      },
    },
    textarea: {
      width:
        '100%                                                                                                                   ',
      minWidth: 460,
      maxWidth: 460,
      outline: 'none',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '3px',
      padding: '9px 14px',
      marginTop: 12,
    },
  })
)

export default function SendContactMailDialog(props: Props) {
  const MAX_LENGTH = 4000
  const { handleAgree, defaultValue, open, setOpen } = props
  const [contactFormValue, setContactFormValue] = React.useState<IContactMail>(defaultValue)
  const [loadingBtnAgree, setLoadingBtnAgree] = React.useState(false)
  const classes = useStyles()

  const validateDialog = () =>
    !(contactFormValue.firstName && contactFormValue.lastName && contactFormValue.content)

  useEffect(() => {
    if (open) setContactFormValue(defaultValue)
  }, [open])

  return (
    <FCTDialog
      open={open}
      setOpen={(value: boolean) => setOpen(value)}
      title="Contact Us"
      type={ETypeDialog.Default}
      titleBtnAgree="Submit"
      handleAgree={async () => {
        try {
          setLoadingBtnAgree(true)
          await handleAgree(contactFormValue)
        } finally {
          setLoadingBtnAgree(false)
        }
      }}
      disableBtnAgree={validateDialog()}
      minHeight={30}
      minWidth={500}
      loadingBtnAgree={loadingBtnAgree}
    >
      <div className={classes.dialogContent}>
        <Flex justifyContent="space-between">
          <div className={classes.textbox}>
            <FCTTextField
              placeholder="First Name"
              value={contactFormValue?.firstName}
              inputProps={{
                style: { padding: '9px 14px' },
              }}
              onChange={e => setContactFormValue(prev => ({ ...prev, firstName: e.target.value }))}
              fullWidth
            />
          </div>
          <div className={classes.textbox}>
            <FCTTextField
              placeholder="Last Name"
              value={contactFormValue?.lastName}
              inputProps={{
                style: { padding: '9px 14px' },
              }}
              onChange={e => setContactFormValue(prev => ({ ...prev, lastName: e.target.value }))}
              fullWidth
            />
          </div>
        </Flex>
        <FCTTextField
          disabled
          value={contactFormValue?.email}
          inputProps={{
            style: { padding: '9px 14px' },
          }}
        />
        <TextareaAutosize
          maxLength={MAX_LENGTH}
          rowsMin={10}
          className={classes.textarea}
          placeholder="Tell us how we can help..."
          onChange={e => setContactFormValue(prev => ({ ...prev, content: e.target.value }))}
          onBlur={() => setContactFormValue(prev => ({ ...prev, content: prev.content.trim() }))}
        ></TextareaAutosize>
      </div>
    </FCTDialog>
  )
}
interface Props {
  handleAgree: (value: IContactMail) => void | Promise<void>
  defaultValue: IContactMail
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface IContactMail {
  lastName: string
  email: string
  firstName: string
  content: string
}

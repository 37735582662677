import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton, InputAdornment, useTheme, Box } from '@material-ui/core'
import SubmitButton from 'components/Authentication/BusinessEmail/SubmitButton'
import { PasswordField } from 'components/Authentication/PasswordField/PasswordField'
import { IField, FormField, FormFieldProps } from 'components/Authentication/FormField/FormField'
import Icon from 'components/Icon/Icon'
import { MouseEventHandler, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { passwordMustNotContains, PASSWORD_REGEX } from 'utils/auth/passwordPolicies'
import * as yup from 'yup'

interface IResetPasswordData {
  oldPassword: string
  password: string
  confirmPassword: string
}

export interface IResetPasswordFormProps {
  handleSubmit: (data: IResetPasswordData) => void | Promise<void>
  firstName?: string
  lastName?: string
  businessEmail: string
  requiredOldPassword?: boolean
}

const ResetPasswordForm = ({
  firstName,
  lastName,
  businessEmail,
  handleSubmit,
  requiredOldPassword,
}: IResetPasswordFormProps) => {
  const [loading, setLoading] = useState(false)

  const [show, setShow] = useState<{
    oldPassword: boolean
    password: boolean
    confirmPassword: boolean
  }>({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  })

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
    trigger,
  } = useForm<IResetPasswordData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        oldPassword: !requiredOldPassword
          ? yup.string()
          : yup.string().required('Please enter old password'),
        password: yup
          .string()
          .required('Please enter password')
          .max(72, 'The field is too long')
          .matches(PASSWORD_REGEX, {
            message: 'Please fullfill all password requirements.',
          })
          .test({
            name: 'NotIncludesNameAndEmail',
            test: pwd => {
              return passwordMustNotContains(pwd || '', {
                firstName,
                lastName,
                businessEmail,
              })
            },
            message: 'Please fullfill all password requirements.',
          }),
        confirmPassword: yup
          .string()
          .required('Please enter confirm password')
          .oneOf([yup.ref('password')], 'Passwords do not match.'),
      })
    ),
  })

  const FIELDS: IField<IResetPasswordData>[] = [
    {
      name: 'oldPassword',
      label: 'Old Password',
      type: show.oldPassword ? 'text' : 'password',
      hidden: !requiredOldPassword,
      endArdoment: useMemo(
        () => (
          <ShowPasswordBtn
            showPassword={show.oldPassword}
            onClick={e => {
              setShow(prev => ({ ...prev, oldPassword: !prev.oldPassword }))
              e.stopPropagation()
            }}
          />
        ),
        [show.oldPassword]
      ),
    },
    {
      name: 'password',
      label: 'New Password',
      type: show.password ? 'text' : 'password',
      endArdoment: useMemo(
        () => (
          <ShowPasswordBtn
            showPassword={show.password}
            onClick={e => {
              setShow(prev => ({ ...prev, password: !prev.password }))
              e.stopPropagation()
            }}
          />
        ),
        [show.password]
      ),
    },
    {
      name: 'confirmPassword',
      label: 'Confirm New Password',
      type: show.confirmPassword ? 'text' : 'password',
      endArdoment: useMemo(
        () => (
          <ShowPasswordBtn
            showPassword={show.confirmPassword}
            onClick={e => {
              setShow(prev => ({ ...prev, confirmPassword: !prev.confirmPassword }))
              e.stopPropagation()
            }}
          />
        ),
        [show.confirmPassword]
      ),
    },
  ]

  return (
    <form
      onSubmit={async e => {
        try {
          setLoading(true)
          await handleSubmitForm(d => handleSubmit(d))(e)
        } finally {
          setLoading(false)
        }
      }}
    >
      {FIELDS.filter(e => !e.hidden).map(field => {
        const fieldProps: FormFieldProps<IResetPasswordData> = {
          control,
          field,
          error: errors[field.name],
          disabled: field.disabled,
          onBlur: () => {
            control.setValue(field.name, control.getValues(field.name)?.trim() || '')
            trigger([
              field.name,
              ...(field.name === 'password'
                ? (['confirmPassword'] as Array<keyof IResetPasswordData>)
                : !!control.getValues('password')
                ? (['password'] as Array<keyof IResetPasswordData>)
                : []),
            ])
          },
        }

        return (
          <Box key={field.name} mt="30px">
            {field.name === 'password' ? (
              <PasswordField
                {...fieldProps}
                firstName={firstName}
                lastName={lastName}
                email={businessEmail}
              />
            ) : (
              <FormField {...fieldProps} />
            )}
          </Box>
        )
      })}
      <SubmitButton disabled={!isValid} text="Reset Password" loading={loading} />
    </form>
  )
}
export default ResetPasswordForm

const ShowPasswordBtn = ({
  onClick,
  showPassword,
}: {
  onClick: MouseEventHandler
  showPassword: boolean
}) => {
  const theme = useTheme()
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={e => {
          onClick(e)
        }}
        onMouseDown={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Icon name={showPassword ? 'eyeopen' : 'eyeClose'} color={theme.palette.grey[400]} />
      </IconButton>
    </InputAdornment>
  )
}

import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { createBrowserHistory } from 'history'

const isLocal = window.location.hostname === 'localhost'

const {
  REACT_APP_APOLLO_SERVER,
  REACT_APP_SEARCH_API,
  REACT_APP_AUTH_URL,
  REACT_APP_PUBLIC_API,
  REACT_APP_COMPANY_API,
} = process.env as Record<string, string>

const history = createBrowserHistory()

export function initSentry() {
  if (isLocal) {
    return
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    sampleRate: 0.1,
    tracesSampleRate: 0.05,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracingOrigins: [
          REACT_APP_APOLLO_SERVER,
          REACT_APP_SEARCH_API,
          REACT_APP_AUTH_URL,
          REACT_APP_PUBLIC_API,
          REACT_APP_COMPANY_API,
          /^\//,
        ],
        idleTimeout: 15000,
      }),
    ],
    beforeBreadcrumb: (breadcrumb, hint?) => {
      const graphQLStringQuery = (hint?.input || [])[1]?.body
      const graphQLQuery = graphQLStringQuery && JSON.parse(graphQLStringQuery)
      const subUrl =
        graphQLQuery && breadcrumb.data?.url === REACT_APP_APOLLO_SERVER
          ? graphQLQuery.operationName
          : ''

      return {
        ...breadcrumb,
        data: { ...breadcrumb.data, url: breadcrumb.data?.url + `/${subUrl}` },
      }
    },
  })
}

export function logError(error: any, errorInfo: any = null) {
  if (isLocal) {
    return
  }

  Sentry.withScope(scope => {
    errorInfo && scope.setExtras(errorInfo)
    Sentry.captureException(error)
  })
}

export function onError(error: any) {
  if (isLocal) {
    console.log(error)
    return
  }

  let errorInfo = {
    url: undefined,
  }

  if (!(error instanceof Error) && error.message) {
    errorInfo = error
    error = new Error(error.message)
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url
  }

  logError(error, errorInfo)
}

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  decodeObjectToURLParams,
  encodeObjectToURLParams,
  pickNonEmptyProps,
  useCustomParams,
} from './QueryParams'
import { useState } from 'react'
import { identity, pickBy } from 'lodash'
import { HTMLAttributes } from 'react'
export interface IBaseSyncState {}
export interface ISyncStateRouterWrapperProps<IState> extends HTMLAttributes<HTMLElement> {
  skipSetUrl?: boolean
  getState: IState
  setState: (state: any) => void
  parseState: (state: any) => IState
}
function updateQueryParamsToState<IState>(
  queryStr: string,
  setState: (state: any) => void,
  parseState: (state: any) => IState
) {
  setState(decodeObjectToURLParams<IState>(queryStr, parseState))
}
function SyncStateRouterWrapper<IState extends IBaseSyncState>(
  props: ISyncStateRouterWrapperProps<IState>
) {
  const [params, updateSearch] = useCustomParams() as [string, (searchParams: string) => void]
  const [initialized, setInitialized] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (params) {
      updateQueryParamsToState(params, props.setState, props.parseState)
    } else {
      const newFilter = pickNonEmptyProps(pickBy(props.getState, identity))
      updateSearch(encodeObjectToURLParams(newFilter))
    }
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized && !props.skipSetUrl) {
      const newFilter = pickNonEmptyProps(pickBy(props.getState, identity))
      updateSearch(encodeObjectToURLParams(newFilter))
    }
  }, [props.getState, location.pathname])

  return <>{initialized && props.children}</>
}

export default SyncStateRouterWrapper

import axios, { AxiosRequestConfig } from 'axios'
import config from 'core/config/auth'

interface IChangeUserPassword {
  oldPassword: string
  newPassword: string
  token?: string
}

export default function (body: IChangeUserPassword, c?: AxiosRequestConfig) {
  return axios.put<{ data: boolean }>(`${config.api}/users/password`, body, c)
}

import { useMutation } from '@apollo/client'
import { DialogContent, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Loading from 'components/Loading'
import { FCTTooltipMove } from 'components/Tooltip/TooltipMove'
import {
  DownloadHistoryRequestType,
  DOWNLOAD_HISTORY_TYPE,
  IPropertiesRequestCompany,
} from 'core/constants/DownloadHistory'
import { useEffect, useMemo, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import mixpanel from '../../mixpanel'
import { SEND_MAIL_SEARCH_REQUEST } from '../../store/operations/mutations/SetSendMailSearchRequest'
import { validateCountWord, validateURL } from '../../utils/convert/number'
import FCTButton from '../Button/FCTButton'
import { ETypeDialog, FCTDialog } from '../Dialog/FCTDialog'
import FCTRadio from '../Radio/FCTRadio'
import FCTTextField from '../TextField/FCTTextField'
import { useDownloadHistory } from './../../core/hooks/useDownloadHistory'
import { commonErrorHandler } from './../../utils/CommonErrorHandler'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: 'calc(50% - 8px)',
    padding: '2px 3px',
    justifyContent: 'start',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'left',
    height: 40,
  },
  active: {
    border: '1px solid #29BA74',
  },
  searchInput: {
    margin: '15px 0 12px',
    '& .MuiOutlinedInput-input': {
      padding: '12px 14px',
    },
  },
  error: {
    marginTop: 10,
    fontSize: '12px',
    lineHeight: '22px',
    color: theme.palette.error.main,
  },
  tooltipExportText: {
    width: 213,
    margin: '0px -7px',
    textAlign: 'left',
    lineHeight: '18px',
  },
}))

const SearchNotLookingForDialog = (props: SearchNotLookingForDialogProps) => {
  const { open, setOpen, setInputValue } = props
  const classes = useStyles()
  const {
    exportTooltipMes,
    disabled: disableBtnRequest,
    addDownloadHistory,
    remainingAmount,
    loadingAddDownload,
    loading: loadingGetDownload,
  } = useDownloadHistory(DOWNLOAD_HISTORY_TYPE.REQUEST)
  const { addToast } = useToasts()
  const [option, setOption] = useState<SubmitRequestType>(SubmitRequestType.companyName)
  const [dataSubmitRequest, setDataSubmitRequest] = useState({ requestCompany: '', orther: '' })
  const [sendMailSearchRequest, { loading: createLoading }] = useMutation(SEND_MAIL_SEARCH_REQUEST)
  const onChangeRequestCompany = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDataSubmitRequest({ requestCompany: e.target.value, orther: '' })
  }

  const onChangeOther = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDataSubmitRequest({ orther: e.target.value, requestCompany: '' })
  }

  const isCheckCountCompanyOther = useMemo(() => validateCountWord(dataSubmitRequest.orther, 150), [
    dataSubmitRequest.orther,
  ])

  const disableBtnAgree = useMemo(
    () =>
      (!dataSubmitRequest.requestCompany && !dataSubmitRequest.orther) ||
      isCheckCountCompanyOther ||
      !validateURL(dataSubmitRequest.requestCompany),
    [dataSubmitRequest]
  )

  const handleAgree = async (option: SubmitRequestType, data: IdataSubmitRequest) => {
    const isRequestCompany = option === SubmitRequestType.companyName
    isRequestCompany
      ? mixpanel.track('Search_Request Company', {
          requestCompanyUrl: dataSubmitRequest.requestCompany,
        })
      : mixpanel.track('Search_Request Other', {
          requestOther: dataSubmitRequest.orther,
        })
    try {
      let amount
      if (isRequestCompany) {
        amount = await addDownloadHistory([
          {
            type: DOWNLOAD_HISTORY_TYPE.REQUEST,
            name: `Add request company ${dataSubmitRequest.requestCompany}`,
            sub_type: '',
            filter_option: null,
            properties: JSON.stringify({
              companyUrl: dataSubmitRequest.requestCompany,
              type: DownloadHistoryRequestType.Company,
            } as IPropertiesRequestCompany),
          },
        ])
      }
      await sendMailSearchRequest({
        variables: {
          input: {
            requestType: isRequestCompany ? 'company' : 'other',
            companyUrl: dataSubmitRequest.requestCompany,
            suggestion: dataSubmitRequest.orther,
          },
        },
      })
      setOpen(false)
      addToast(
        <Typography style={{ fontSize: 14 }}>
          {isRequestCompany ? (
            <>
              <strong>{dataSubmitRequest.requestCompany}</strong> profile has been requested
              successfully. You have <strong>{amount}</strong> company and use case request
              {`${(amount || 0) > 1 ? 's' : ''}`} remaining.
            </>
          ) : (
            <>
              <strong>Request sent successfully</strong>
              <br />
              Thank you for your request. You will be notified via email when your request has been
              reviewed by our team.
            </>
          )}
        </Typography>,
        { appearance: 'success' }
      )
    } catch (error) {
      commonErrorHandler((err: any) => {
        addToast(<Typography>{err.graphQLError.message}</Typography>, {
          appearance: 'error',
        })
      })(error)
    }
  }

  useEffect(() => {
    if (open) {
      setDataSubmitRequest({ requestCompany: '', orther: '' })
      setInputValue('')
    }
  }, [open])

  useEffect(() => {
    setOption(remainingAmount ? SubmitRequestType.companyName : SubmitRequestType.others)
  }, [remainingAmount])

  return (
    <FCTDialog
      open={open}
      setOpen={setOpen}
      title="Submit your request"
      type={ETypeDialog.Default}
      handleAgree={() => handleAgree(option, dataSubmitRequest)}
      disableBtnAgree={
        disableBtnAgree || (option === SubmitRequestType.companyName && disableBtnRequest)
      }
      minWidth={430}
      titleBtnAgree="Submit"
      hasButtonClose={false}
      loadingBtnAgree={createLoading || loadingAddDownload}
    >
      <DialogContent>
        {loadingGetDownload ? (
          <Loading />
        ) : (
          <>
            <div className={classes.buttonGroup}>
              <FCTTooltipMove
                text={<div className={classes.tooltipExportText}>{exportTooltipMes}</div>}
                isMove={false}
              >
                <FCTButton
                  variant="outlined"
                  className={
                    option == SubmitRequestType.companyName
                      ? clsx(classes.button, classes.active)
                      : classes.button
                  }
                  style={{ minWidth: 174 }}
                  onClick={() => setOption(SubmitRequestType.companyName)}
                  disabled={disableBtnRequest}
                >
                  <FCTRadio checked={option == SubmitRequestType.companyName} />
                  Request Company
                </FCTButton>
              </FCTTooltipMove>

              <FCTButton
                variant="outlined"
                className={
                  option == SubmitRequestType.others
                    ? clsx(classes.button, classes.active)
                    : classes.button
                }
                onClick={() => setOption(SubmitRequestType.others)}
              >
                <FCTRadio checked={option == SubmitRequestType.others} />
                Other
              </FCTButton>
            </div>

            {option == SubmitRequestType.companyName && (
              <>
                <div className={classes.searchInput}>
                  <FCTTextField
                    placeholder="Company URL"
                    onChange={e => onChangeRequestCompany(e)}
                    value={dataSubmitRequest.requestCompany}
                    error={!validateURL(dataSubmitRequest.requestCompany)}
                  />
                </div>
                {!validateURL(dataSubmitRequest.requestCompany) && (
                  <Typography className={classes.error}>Invalid URL</Typography>
                )}
              </>
            )}

            {option == SubmitRequestType.others && (
              <>
                <div className={classes.searchInput}>
                  <FCTTextField
                    placeholder="Suggestion"
                    rows={4}
                    multiline={true}
                    onChange={e => onChangeOther(e)}
                    value={dataSubmitRequest.orther}
                    error={isCheckCountCompanyOther}
                  />
                </div>
                {isCheckCountCompanyOther && (
                  <Typography className={classes.error}>The max length is 150 words</Typography>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
    </FCTDialog>
  )
}

export default SearchNotLookingForDialog

interface SearchNotLookingForDialogProps {
  open: boolean
  setOpen: (value: boolean) => void
  setInputValue: any
}

export enum SubmitRequestType {
  companyName,
  others,
}

export interface IdataSubmitRequest {
  requestCompany: string
  orther: string
}
export interface IRequestSearchType {
  requestedByEmail: string
  requestType: string
  companyUrl: string
  suggestion: string
}

import { REACT_APP_LOG_LEVEL } from '../core/config/app-config'

declare const window: any
export class Logger {
  static LogBuilder = class {
    private _messages: { type: string; value: any }[] = []
    constructor(private func: string) {}
    title(title: string) {
      this._messages.push({
        type: 'title',
        value: title,
      })
      return this
    }
    message(msg: any) {
      this._messages.push({
        type: 'message',
        value: msg,
      })
      return this
    }
    table(msg: any) {
      this._messages.push({
        type: 'table',
        value: msg,
      })
      return this
    }
    private _logMessage(group: string, type: string) {
      if (!REACT_APP_LOG_LEVEL?.includes(type)) return
      console.groupCollapsed(group)
      let openGroup = false
      this._messages.map(msg => {
        if (msg.type == 'title') {
          if (openGroup) {
            console.groupEnd()
          }
          openGroup = true
          console.group(msg.value)
        } else if (msg.type === 'message') console.log(msg.value)
        else if (msg.type === 'table') console.table(msg.value)
        else if (type === 'ERROR') console.error(msg.value)
        else console.log(msg.value)
      })
      if (openGroup) {
        openGroup = false
        console.groupEnd()
      }
      console.groupEnd()
    }
    debug() {
      this._logMessage(`[DEBUG] ${this.func}`, 'DEBUG')
    }
    info() {
      this._logMessage(`[INFO] ${this.func}`, 'INFO')
    }
    error() {
      this._logMessage(`[ERROR] ${this.func}`, 'ERROR')
    }
  }
  constructor(private func: Function | string) {}
  build() {
    return new Logger.LogBuilder(typeof this.func === 'function' ? this.func.name : this.func)
  }
  debug(...args: any) {
    console.log(`[DEBUG]`, typeof this.func === 'function' ? this.func.name : this.func, ...args)
  }
  table(...args: any) {
    console.log(
      `[DEBUG]`,
      `[${typeof this.func === 'function' ? this.func.name : this.func}]`,
      args.filter((x: any) => typeof x == 'string').join(', ')
    )
    console.table(...args.filter((x: any) => typeof x !== 'string'))
  }
  info(...args: any) {
    console.info(
      `[INFO]`,
      `[${typeof this.func === 'function' ? this.func.name : this.func}]`,
      ...args
    )
  }
  error(...args: any) {
    console.info(...args)
  }
}

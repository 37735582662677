import gql from 'graphql-tag'

export const GET_COMPANY_CATEGORIES_TAGS = gql`
  query GetCompanyCategoriesTags($id: Int!) {
    getCompanyCategoriesTags(id: $id) {
      categoriesTags {
        productLines {
          dimension_id
          is_primary
          dimension_name
          dimension_desc
          parent_dimension_id
          category
          isShow
          origin_dimension_id
          link_id
        }
        businessLines {
          id
          name
          description
          is_primary
          origin_dimension_id
          link_id
        }
        valueChains {
          id
          name
          description
          category
          is_primary
          origin_dimension_id
          parent_dimension_id
          isShow
          can_be_final
          link_id
        }
        operatingModels {
          id
          name
          description
          category
          is_primary
          origin_dimension_id
          parent_dimension_id
          isShow
          can_be_final
          link_id
        }
        operatingModels {
          id
          name
          description
          is_primary
          origin_dimension_id
          link_id
        }
        risks
        industries
        industriesFullInfo {
          id
          name
          description
        }
        fintechType {
          id
          name
        }
        distributionModels {
          id
          name
        }
        tags
        tagsFullInfo {
          id
          name
          description
        }
        ecoSystem
        ecoSystemAuxiliary
        ecoSystemArray {
          dimension_id
          is_primary
          dimension_name
          dimension_desc
          dimension
          parent_dimension_id
          category
          isShow
          origin_dimension_id
          can_be_final
        }
        ecoSystemAuxiliaryArray {
          dimension_id
          is_primary
          dimension_name
          dimension
          parent_dimension_id
          category
          isShow
          origin_dimension_id
          can_be_final
        }
      }
    }
  }
`

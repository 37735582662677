import { ITree } from '../types/Tree'

export function DeepFindInArray<T, V>(
  sources: ITree<V>[] = [],
  search: (obj: ITree<V>) => boolean
): T | null {
  let result: T | null = null
  for (let i = 0; i < sources.length; i++) {
    if (search(sources[i])) {
      result = sources[i] as T
      break
    }
    if (sources[i].children) {
      result = DeepFindInArray(sources[i].children, search)
      if (result) break
    }
  }
  return result
}

export function DeepFind<T, V>(source: ITree<V>, search: (obj: ITree<V>) => boolean): T | null {
  if (search(source)) {
    return source as T
  }
  if (source.children) {
    return DeepFindInArray(source.children, search)
  }
  return null
}

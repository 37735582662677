// TODO move this file to components/
import { makeStyles } from '@material-ui/core'
import { FilterItem } from './FilterItem'

interface IBusinessFilterProps {
  data: any // To match with dynamic type in GetAnalysisFilterData Props type
  businessLines: number[]
  name: string
  valueChains: number[]
  risks: number[]
  setAnalysisFilter: (filter: any) => void // any type use for setAnalysisFilter and mergeDeeps
  isDescription?: boolean
}

const useStyles = makeStyles({
  root: {
    display: 'block',
    padding: '0 10px',
  },
})

export const BusinessFilter = ({
  data,
  businessLines,
  name,
  valueChains,
  risks,
  setAnalysisFilter,
  isDescription,
}: IBusinessFilterProps) => {
  const classes = useStyles()
  const setFilter = (value: any) => {
    if (name == 'businessLines') {
      setAnalysisFilter({ businessLines: value?.length ? value : null, valueChains: null })
    } else {
      setAnalysisFilter({ businessLines: null, valueChains: value?.length ? value : null })
    }
  }

  return (
    <div className={classes.root}>
      {data.length > 0 &&
        data.map((item: any) => (
          <div key={item.id}>
            <FilterItem
              {...item}
              filterIds={name == 'businessLines' ? businessLines : valueChains}
              setFilterIds={setFilter}
              isDescription={isDescription}
              id={item.id}
              hasDisable
            />
          </div>
        ))}
    </div>
  )
}

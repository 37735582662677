import { ReactiveVar } from '@apollo/client'
type IS_UPDATED = boolean
export default (queryParamsVar: ReactiveVar<string>) => {
  return (query: string): IS_UPDATED => {
    if (query === queryParamsVar()) {
      return false
    }
    queryParamsVar(query)
    return true
  }
}

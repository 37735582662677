import { Box, Container, createStyles, Grid, makeStyles, Tabs, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Loading from 'components/Loading'
import { IWebflowCollection } from 'components/Policy/types'
import { readFile } from 'components/Policy/utils/readFile'
import csv2json from 'csvjson-csv2json'
import { format } from 'date-fns'
import htmlparse from 'html-react-parser'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    topicItem: {
      paddingBottom: 8,
    },
    container: {
      height: '100%',
    },
    linkItem: {
      color: `${theme.palette.grey[600]} !important`,
      lineHeight: '22px',
      textDecoration: 'none',
      marginBottom: 12,
      fontSize: 14,
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
      },
      transition: 'color 500ms ease',
      display: 'block',
      cursor: 'pointer',
    },
    page: {
      position: 'relative',
      padding: '20px 20px 0 47px',
      fontSize: '14px',
      lineHeight: '22px',
      color: theme.palette.grey[800],
      scrollBehavior: 'smooth',
      '& a': {
        color: theme.palette.primary.light,
      },
      '& h2': {
        fontSize: '16px',
        lineHeight: '24px',
        marginLeft: '-27px',
        '& span:first-child': {
          marginLeft: '-27px',
        },
      },
      '& h3': {
        fontSize: '15px',
        lineHeight: '24px',
        '& span:first-child': {
          marginLeft: '-27px',
        },
        '& span:last-child': {
          fontWeight: 'normal',
          fontSize: '14px',
        },
      },
      '& strong': {
        fontWeight: 600,
      },
      '& h4': {
        fontSize: '22px',
        lineHeight: '36px',
        marginBottom: 20,
        marginTop: 0,
        '& > strong': {
          color: theme.palette.primary.light,
        },
      },
      '& h5': {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '24px 0px',
        '& > strong': {
          color: theme.palette.primary.light,
        },
      },
      '& h6': {
        fontSize: '14px',
        lineHeight: '22px !important',
        marginTop: 0,
        marginBottom: 16,
        color: theme.palette.grey[800],
        fontWeight: 'normal',
      },
      '& ul li::marker': {
        color: theme.palette.primary.light,
      },
    },
    content: {
      '& strong': {
        fontWeight: 600,
        color: theme.palette.primary.light,
      },
      '& div': {
        margin: '6px 0px',
        '& span': {
          marginRight: 5,
        },
      },
      padding: '0px 32px',
      overflowY: 'auto',
      '& h6': {
        marginLeft: `-27px`,
      },
    },
    spreader: {
      maxWidth: '100%',
      marginBottom: 32,
      borderBottom: '1px solid #e2e8f0',
    },
    tabs: {
      flexShrink: 1,
    },
    text: {
      fontSize: '14px',
      lineHeight: '22px',
      color: theme.palette.grey[500],
      '& strong': {
        color: theme.palette.grey[800],
      },
    },
    wrapperTitle: {
      marginLeft: -27,
      marginBottom: 56,
    },
    title: {
      fontSize: 60,
      marginBottom: 20,
      marginTop: 0,
      lineHeight: '72px',
    },
    activeLink: {
      color: `${theme.palette.primary.main} !important`,
    },
  })
)

interface PolicyDocumentPageProps {
  topics: string[]
  getContent: () => Promise<string>
  isTermOfUse?: boolean
}

const getId = (i: number) => `toc-${i}`

export const PolicyDocumentPage = ({
  topics,
  getContent,
  isTermOfUse = false,
}: PolicyDocumentPageProps) => {
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState<IWebflowCollection>()
  const classes = useStyles()
  const sizeTopic = topics.length
  const [elements, setElements] = useState<Element[]>([])
  const currentId = useIntersection({
    elements,
    rootMargin: `0px 0px ${isTermOfUse ? '-600px' : '-800px'} 0px`,
  })

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const json: IWebflowCollection[] = csv2json(await readFile(await getContent()))

        setContent(json[0])
      } catch (error) {}
      setLoading(false)
    }
    fetchFile()
  }, [])

  useEffect(() => {
    const arrElement: Element[] = []
    Array.from({ length: sizeTopic }).forEach((e, i) => {
      const element = document.getElementById(getId(i))
      if (element) arrElement.push(element)
    })
    if (arrElement.length) setElements(arrElement)
  }, [sizeTopic, loading])

  return (
    <Box height="100vh" overflow="auto" className={classes.page}>
      <Container className={classes.container}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid container style={{ padding: '50px 0px' }}>
              <Grid lg={4} item style={{ padding: '0px 32px' }}>
                <div style={{ marginTop: 0, position: 'sticky', top: 0 }}>
                  <div style={{ height: '130px' }}></div>

                  {topics.map((e, i) => (
                    <a
                      className={clsx({
                        [classes.linkItem]: true,
                        [classes.activeLink]: getId(i) === currentId,
                      })}
                      href={`#${getId(i)}`}
                      key={i}
                    >
                      {e}
                    </a>
                  ))}
                </div>
              </Grid>
              <Grid lg={8} item className={classes.content}>
                <div className={classes.wrapperTitle}>
                  <Typography variant="h1" className={classes.title}>
                    {content?.Name?.split('-')?.[0]}
                  </Typography>
                  {!!content?.['Updated On'] && (
                    <Typography className={classes.text}>
                      Last Updated{' '}
                      <strong>{format(new Date(content?.['Updated On']), 'MMM dd, yyyy')}</strong>
                    </Typography>
                  )}
                </div>
                {content?.Content ? htmlparse(content.Content) : null}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Box>
  )
}

const useIntersection = ({
  elements,
  rootMargin,
}: {
  elements: Element[]
  rootMargin?: string
}) => {
  const [id, setState] = useState('')
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(e => {
          if (e.isIntersecting) setState(e.target.id)
        })
      },
      { rootMargin, threshold: 1 }
    )
    elements && elements.forEach(e => observer.observe(e))
    return () => elements.forEach(e => observer.unobserve(e))
  }, [elements])

  return id
}

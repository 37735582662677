import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import FCTIcon from '../Icon/Icon'
import Flex from '../Flex/Flex'

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[800],
    lineHeight: '22px',
    alignItems: 'center',
    padding: '16px 0',
  },
  dropdown: {
    overflow: 'hidden',
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
}))

export const AccordionItem = (props: IAccordionItemProps) => {
  const classes = useStyles()
  const { open, setOpen, customStyle } = props
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={classes.root}>
      {!props.disabled && (
        <div>
          <div
            className={classes.title}
            style={customStyle?.title || {}}
            onClick={() =>
              open === undefined || setOpen === undefined ? setIsOpen(!isOpen) : setOpen(!open)
            }
          >
            <Flex alignItems="center" gap={8}>
              {props.dotColor && (
                <div className={classes.dot} style={{ backgroundColor: props.dotColor }}></div>
              )}
              {props.title}
            </Flex>
            <FCTIcon name={(open === undefined ? isOpen : open) ? 'minus1' : 'plus'} />
          </div>
          <div className={classes.dropdown}>
            {(open === undefined ? isOpen : open) && props.children}
          </div>
        </div>
      )}
    </div>
  )
}

interface IAccordionItemProps {
  disabled?: boolean
  dotColor?: any
  children?: React.ReactNode
  title?: string
  open?: boolean
  setOpen?: (value: boolean) => void
  customStyle?: { title?: React.CSSProperties }
}

import { GetAllTaxonomies_getAllTaxonomies } from '../__generated__/GetAllTaxonomies'
import { IDimensionTree } from '../types/Dimension'
import { DeepFind } from './DeepFind'

export function FindTaxonomy(tree: IDimensionTree | object) {
  return (nodeId: number) =>
    DeepFind<IDimensionTree, GetAllTaxonomies_getAllTaxonomies>(
      tree,
      item => (item as any).id === nodeId
    )
}

export const getTreeByID = (tree: any, id: number | undefined) => {
  if (!id) return null
  let result = null
  if (id === tree.data.id) {
    return tree
  } else {
    if (tree?.children) {
      tree?.children?.some((node: any) => (result = getTreeByID(node, id)))
    }
    return result
  }
}

import { makeStyles, Typography } from '@material-ui/core'
import Collapse, { CollapseProps } from '@material-ui/core/Collapse'
import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FCTIcon from '../Icon/Icon'
import themeMain from '../../theme'
import FCTTooltip from 'components/Tooltip/Tooltip'

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: '12px 0px 12px 0px',
    color: theme.palette.grey['800'],
    fontWeight: 600,
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginLeft: 2,
      marginRight: 7,
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemText-primary': {
      fontWeight: 600,
      fontSize: 14,
      color: theme.palette.grey['800'],
    },
  },
  smallListItem: {
    padding: '0 10px',
    marginBottom: 2,
    backgroundColor: '#EEF2F6',
    borderRadius: 3,
    height: 32,
  },
  smallTitle: {
    '& .MuiListItemText-primary': {
      fontSize: 12,
      display: 'flex',
    },
  },
  children: {
    padding: '10px 0 16px',
  },
  tooltipIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    width: 12,
    height: 12,
    margin: '0 8px',
  },
}))

const FCTDropdownListItem = (props: IDropdownListItemProps) => {
  const {
    title,
    openedIcon,
    closedIcon,
    size,
    children,
    isOpen,
    itemStyle,
    childrenStyle,
    tooltipText,
  } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState<boolean>(isOpen || false)

  return (
    <div style={size === 'large' ? { borderBottom: '1px solid #EBEFF4' } : {}}>
      <ListItem
        onClick={() => setOpen(!open)}
        classes={{ root: classes.listItem }}
        className={size === 'small' ? classes.smallListItem : ''}
        style={itemStyle || {}}
      >
        <ListItemText className={size === 'small' ? classes.smallTitle : ''}>
          {title}
          {!!tooltipText && (
            <FCTTooltip
              title={<Typography style={{ fontSize: 12 }}>{tooltipText}</Typography>}
              arrow
              placement="top"
            >
              <div className={classes.tooltipIcon}>
                <FCTIcon name="warningCircle" className={classes.tooltip} />
              </div>
            </FCTTooltip>
          )}
        </ListItemText>

        {closedIcon && openedIcon && size === 'small' && (
          <ListItemIcon>
            {open ? (
              typeof closedIcon === 'string' ? (
                <FCTIcon name={closedIcon} width={6} height={5} color="#475569" />
              ) : (
                closedIcon
              )
            ) : typeof openedIcon === 'string' ? (
              <FCTIcon name={openedIcon} width={6} height={5} color="#475569" />
            ) : (
              openedIcon
            )}
          </ListItemIcon>
        )}
        {closedIcon &&
          openedIcon &&
          size === 'large' &&
          (open ? (
            typeof closedIcon === 'string' ? (
              <FCTIcon
                name={closedIcon}
                width={10}
                height={10}
                color={themeMain.palette.secondary.main}
              />
            ) : (
              closedIcon
            )
          ) : typeof openedIcon === 'string' ? (
            <FCTIcon
              name={openedIcon}
              width={10}
              height={10}
              color={themeMain.palette.secondary.main}
            />
          ) : (
            openedIcon
          ))}
      </ListItem>
      <Collapse in={open}>
        <div className={classes.children} style={childrenStyle || {}}>
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default FCTDropdownListItem

export interface IDropdownListItemProps extends CollapseProps {
  title: string
  openedIcon?: 'plus' | 'down' | React.ReactElement
  closedIcon?: 'minus1' | 'up' | React.ReactElement
  size: 'small' | 'large'
  children?: React.ReactNode
  isOpen?: boolean
  itemStyle?: React.CSSProperties
  childrenStyle?: React.CSSProperties
  tooltipText?: string
}

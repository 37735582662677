import { TooltipDesc } from './../../components/Tabs/FCTTab'
import moment from 'moment'
import { InternalDbCompanyTypes } from '../../__generated__/globalTypes'
import { ECompareType } from 'types/CompanyDealFilter'

export const TitleCategory = new Map<InternalDbCompanyTypes, string>([
  [InternalDbCompanyTypes.fintech, 'FinTech'],
  [InternalDbCompanyTypes.insurtech, 'InsurTech'],
  [InternalDbCompanyTypes.regtech, 'RegTech'],
  [InternalDbCompanyTypes.all, 'All Industries'],
])

export const MappingDecsCategory = new Map<InternalDbCompanyTypes, TooltipDesc>([
  [InternalDbCompanyTypes.fintech, TooltipDesc.FinTech],
  [InternalDbCompanyTypes.insurtech, TooltipDesc.InsurTech],
  [InternalDbCompanyTypes.regtech, TooltipDesc.RegTech],
  [InternalDbCompanyTypes.all, TooltipDesc.AllIndustries],
])

export const convertTickAxisLabel = (
  date: string,
  type: PeriodType,
  isTooltip?: boolean,
  noUtc?: boolean
) => {
  const month = (noUtc ? new Date(date).getMonth() : new Date(date).getUTCMonth()) + 1
  const quarter = Math.round(
    (noUtc ? new Date(date).getMonth() : new Date(date).getUTCMonth()) / 3 + 1
  )
  const year = noUtc ? new Date(date).getFullYear() : new Date(date).getUTCFullYear()
  if (type === PeriodType.Monthly) {
    const MTD = month == new Date().getMonth() + 1 && year == new Date().getFullYear() ? 'MTD ' : ''
    return isTooltip
      ? MTD + String(moment('' + month, 'MM').format('MMM')) + ' ' + String(year)
      : MTD + String(moment('' + month, 'MM').format('MMM'))
  }
  if (type === PeriodType.Annually) {
    if (year == new Date().getFullYear()) return 'YTD ' + String(year)
    return String(year)
  }
  if (type === PeriodType.Quarterly) {
    const QTD =
      Math.round(new Date().getMonth() / 3 + 1) == quarter && year == new Date().getFullYear()
        ? 'QTD '
        : ''
    switch (quarter) {
      case 1:
        return isTooltip ? QTD + 'Q1 ' + year : QTD + 'Q1'
      case 2:
        return isTooltip ? QTD + 'Q2 ' + year : QTD + 'Q2'
      case 3:
        return isTooltip ? QTD + 'Q3 ' + year : QTD + 'Q3'
      default:
        return isTooltip ? QTD + 'Q4 ' + year : QTD + 'Q4'
    }
  }
}

export enum PeriodType {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Annually = 'annually',
}

export enum GrowthType {
  Fungding = ' funding',
  Deals = ' deals',
}

export const TitlePeriodType = new Map<PeriodType, string>([
  [PeriodType.Monthly, 'Monthly'],
  [PeriodType.Annually, 'Annually'],
  [PeriodType.Quarterly, 'Quarterly'],
])

export enum LocationType {
  Region = 'region',
  SubRegion = 'subRegion',
  SubRegion2 = 'subRegion2',
  Country = 'country',
}

export const TitleLocationType = new Map<LocationType, string>([
  [LocationType.Region, 'Region'],
  [LocationType.SubRegion, 'Sub-Region'],
  [LocationType.SubRegion2, 'Sub-Region 2'],
  [LocationType.Country, 'Country'],
])

export enum TopCompaniesLocation {
  Global = 'global',
  Region = 'region',
  SubRegion = 'subRegion',
  SubRegion2 = 'subRegion2',
  Country = 'country',
}

export const TopCompaniesOption = [
  { value: TopCompaniesLocation.Global, label: 'Global' },
  { value: TopCompaniesLocation.Region, label: 'Region' },
  { value: TopCompaniesLocation.SubRegion, label: 'Sub-region' },
  { value: TopCompaniesLocation.SubRegion2, label: 'Sub-region 2' },
  { value: TopCompaniesLocation.Country, label: 'Country' },
]

export enum ETimePeriod {
  ThreeYears = '3',
  FiveYears = '5',
  TenYears = '10',
  FifteenYears = '15',
}

export enum FieldNameDrawYAxis {
  count = 'count',
  countRound = 'countRound',
  fundings = 'fundings',
  totalFunding = 'totalFunding',
  totalAmount = 'totalAmount',
  countPercent = 'countPercent',
  fundingsPercent = 'fundingsPercent',
}

export enum FieldNameSimilarCompanies {
  otherCompanies = 'FCT View',
  fintechSuggested = 'Self Suggested',
}

export const COMPARE_TYPE = new Map<ECompareType | null, FieldNameSimilarCompanies>([
  [ECompareType.ProfileTop10, FieldNameSimilarCompanies.otherCompanies],
  [ECompareType.ProfileSimilar, FieldNameSimilarCompanies.fintechSuggested],
])

export const formatIndustry = (v: string) => {
  switch (v) {
    case 'fintech':
      return 'FinTech'
    case 'regtech':
      return 'RegTech'
    case 'insurtech':
      return 'InsurTech'
    case 'out':
      return 'Out'
  }
}

export const growthTypeName = (value: PeriodType) => {
  return value === PeriodType.Monthly
    ? 'MoM Growth'
    : value === PeriodType.Quarterly
    ? 'QoQ Growth'
    : 'YoY Growth'
}

export const growthTypeNameFundingAndMegaRound = (value: PeriodType, type?: GrowthType) => {
  const periodType =
    value === PeriodType.Monthly ? 'MoM' : value === PeriodType.Quarterly ? 'QoQ' : 'YoY'

  const growthType = type === GrowthType.Fungding ? 'Funding' : 'Deals'

  return `${periodType} ${growthType}`
}

export const renameLogo = (value: string) => {
  if (!value) return ''
  return value
    .toUpperCase()
    .match(/\b(\w)/g)
    ?.join('')
}

export const getTitleShowMore = (value: string, length: number) => {
  if (value.length > length) return value.slice(0, length) + '...'
  return value
}

export enum NavigateHeaderTitle {
  landscape = 'Landscape',
  charts = 'Analytics',
  listView = 'List view',
}

import { makeStyles, Typography, useTheme } from '@material-ui/core'
import { useRef, useState, useEffect, ReactElement } from 'react'
import FCTTooltip from '../Tooltip/Tooltip'
import FCTIcon from '../Icon/Icon'
import clsx from 'clsx'
import { TagGroupMapColor } from './consts'

const useStyles = makeStyles(theme => ({
  stats: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    flexWrap: 'wrap',
    marginBottom: 0,
    overflow: 'hidden',
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  statsItemContent: {
    height: 28,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.contrastText,
    margin: '12px 12px 0px 0px',
    textTransform: 'unset',
    textAlign: 'center',
    lineHeight: '20px',
    display: 'block',
    background: '#E2E8F0',
    padding: '5px 10px',
    borderRadius: '3px',
    whiteSpace: 'nowrap',
    cursor: 'context-menu',
    boxSizing: 'border-box',
    '&:last-child': {
      marginRight: 0,
    },
  },
  chipContainer: {
    display: 'flex',
  },
  arrowRightIcon: {
    position: 'absolute',
    right: 8,
    marginTop: 17,
    fontSize: 14,
    color: theme.palette.grey[600],
    cursor: 'pointer',
    height: 25,
  },
  arrowTopIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 14,
    color: theme.palette.grey[600],
    margin: '15px -23px -8px 0px',
    cursor: 'pointer',
    height: 25,
  },
}))

type Item = { value: string; group: string; tooltip?: string }

const SearchResultChips = ({
  data,
  onClickChipItem,
  isClickable,
}: {
  isClickable(i: Item): boolean
  onClickChipItem?(i: Item): void | Promise<void>
  data: Item[]
}) => {
  const HEIGHT_PARENT_CHIP_ONE_LINE = 40
  const classes = useStyles()
  const theme = useTheme()

  const parentChipsRef = useRef<HTMLDivElement>(null)

  const [isShowAllChip, setIsShowAllChip] = useState<boolean>(true)
  const [hasDownLine, setHasDownLine] = useState<boolean>(false)

  useEffect(() => {
    if ((parentChipsRef?.current?.offsetHeight || 0) > HEIGHT_PARENT_CHIP_ONE_LINE) {
      setHasDownLine(true)
      setIsShowAllChip(false)
    }
  }, [parentChipsRef?.current])

  return (
    <>
      <div className={classes.chipContainer}>
        <div
          className={classes.stats}
          style={{
            width: isShowAllChip ? '95%' : '90%',
            height: isShowAllChip ? 'auto' : HEIGHT_PARENT_CHIP_ONE_LINE,
          }}
          ref={parentChipsRef}
        >
          {data.length > 0 &&
            data.map((item: any, i: number) => {
              const TooltipWrapper = ({ children }: { children: ReactElement }) =>
                item.tooltip ? (
                  <FCTTooltip
                    title={<Typography style={{ fontSize: 12 }}>{item.tooltip}</Typography>}
                    placement="top"
                    arrow
                  >
                    {children}
                  </FCTTooltip>
                ) : (
                  children
                )
              return (
                <TooltipWrapper key={i}>
                  <span
                    className={clsx(classes.statsItemContent, {
                      [classes.pointer]: isClickable(item),
                    })}
                    style={{ backgroundColor: TagGroupMapColor.get(item.group) }}
                    onClick={async () => onClickChipItem && (await onClickChipItem(item))}
                  >
                    {item.value}
                  </span>
                </TooltipWrapper>
              )
            })}
        </div>
        {hasDownLine && (
          <div
            className={classes.arrowRightIcon}
            onClick={() => {
              setIsShowAllChip(prev => !prev)
            }}
          >
            {!isShowAllChip ? 'More' : 'Hidden'}
            <FCTIcon
              style={{ marginTop: 4, marginLeft: 11 }}
              name={!isShowAllChip ? 'arrowright' : 'arrowtop'}
              width={10}
              height={10}
              color={theme.palette.primary.main}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default SearchResultChips

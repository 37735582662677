import AuthButton from 'components/AuthButton/AuthButton'

const SubmitButton = ({
  onClick = () => {},
  disabled = false,
  text = 'Continue',
  loading = false,
  form,
}: {
  onClick?(): void
  disabled?: boolean
  text?: string | React.ReactNode
  loading?: boolean
  form?: string
}) => {
  return (
    <AuthButton
      role="submit"
      type="submit"
      variant="contained"
      onClick={() => onClick()}
      disabled={disabled}
      loading={loading}
      form={form}
      text={text}
    />
  )
}

export default SubmitButton

import { makeStyles, Box, IconButton } from '@material-ui/core'
import { ETypeDialog, FCTDialog } from 'components/Dialog/FCTDialog'
import CloseIcon from '@material-ui/icons/Close'
import PasswordExpired, { EPaswordExpiredStep, PasswordExpiredProps } from './PasswordExpired'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 8,
    color: theme.palette.grey[500],
  },
}))

export const usePasswordExpired = () => {
  const [passwordExpiredData, setPasswordExpiredData] = useState<{
    firstName: string
    lastName: string
    businessEmail: string
    remainingDays?: number
  }>()

  const [expiredToken, setExpiredToken] = useState('')

  return {
    passwordExpiredData,
    setPasswordExpiredData,
    expiredToken,
    setExpiredToken,
  }
}

const PasswordExpiredDialog = (props: Omit<PasswordExpiredProps, 'step' | 'setStep'>) => {
  const classes = useStyles()
  const [step, setStep] = useState<EPaswordExpiredStep>(EPaswordExpiredStep.ASK_FOR_RESET)

  return (
    <FCTDialog
      title=""
      hasNoTitle
      hasNoAction
      open={true}
      setOpen={v => {}}
      type={ETypeDialog.Default}
      handleAgree={async () => {}}
      handleCancel={async () => {}}
      hasCloseWhenCancel={false}
      itemStyle={{
        textAlign: 'center',
        maxWidth: '500px',
        minWidth: '500px',
      }}
    >
      <Box padding="40px" position="relative">
        {step === EPaswordExpiredStep.FILL_PASSWORD && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => props.handleCancel()}
          >
            <CloseIcon />
          </IconButton>
        )}
        <PasswordExpired step={step} setStep={setStep} {...props} />
      </Box>
    </FCTDialog>
  )
}

export default PasswordExpiredDialog

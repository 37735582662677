import { makeStyles, Theme, Typography } from '@material-ui/core'
import { TooltipDesc } from '../../Tabs/FCTTab'
import SubmitButton from './SubmitButton'
import clsx from 'clsx'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Flex from '../../Flex/Flex'
import FCTRadio from '../../Radio/FCTRadio'
import FCTTooltip from '../../Tooltip/Tooltip'
import FCTTextField from '../../TextField/FCTTextField'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '3px',
    height: '50px',
    fontSize: '14px',
    border: 'none',
    outline: 'none',
    width: '100%',
    cursor: 'pointer',
    marginTop: '30px',
    '&:disabled': {
      opacity: 0.8,
    },
  },
  primaryBtn: {
    backgroundColor: `${theme.palette.primary.light}`,
    color: '#FFF',
  },
  secondaryBtn: {
    backgroundColor: `#FFF`,
    color: '#000',
    marginTop: '12px',
  },
  formElement: {
    marginTop: '30px',
  },
  input: {
    width: '100%',
  },
  error: {
    marginTop: 16,
    fontSize: '14px',
    lineHeight: '22px',
    color: 'red',
  },
  pointer: {
    cursor: 'pointer',
  },
  font12: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  font14: {
    fontSize: '14px',
    lineHeight: '22px',
  },
}))

enum ECategory {
  FIN = 'FinTech',
  INS = 'InsurTech',
  REG = 'RegTech',
}

export const DEFAULT_COMPANY_DETAILS = {
  productDesc: '',
  description: '',
  category: ECategory.FIN,
}
const TOOLTIPS = {
  FinTech: TooltipDesc.FinTech,
  RegTech: TooltipDesc.RegTech,
  InsurTech: TooltipDesc.InsurTech,
}

export interface ICompanyDetailsData {
  productDesc: string
  description: string
  category: ECategory
}

interface ICompanyDetailsProps {
  handleSubmit(data: ICompanyDetailsData): void
  loadingSubmit?: boolean
  onBack?(): void
  defaultValues?: ICompanyDetailsData
}

const CompanyDetailsForm = ({
  handleSubmit,
  loadingSubmit,
  onBack,
  defaultValues,
}: ICompanyDetailsProps) => {
  const classes = useStyles()
  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
    trigger,
  } = useForm<ICompanyDetailsData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || {
      ...DEFAULT_COMPANY_DETAILS,
    },
    resolver: yupResolver(
      yup.object().shape({
        productDesc: yup.string().required('This field is required.'),
        description: yup.string().required('This field is required.'),
        category: yup.string().required('This field is required.'),
      })
    ),
  })

  const fields: {
    name: keyof ICompanyDetailsData
    label: string
    type: 'input' | 'radio' | 'textarea'
  }[] = [
    { name: 'productDesc', label: 'What best describes your product?', type: 'input' },
    { name: 'category', label: 'Are you a:', type: 'radio' },
    { name: 'description', label: 'Company Description', type: 'textarea' },
  ]

  return (
    <form onSubmit={handleSubmitForm(data => handleSubmit(data))}>
      {fields.map(field => (
        <div key={field.name} className={classes.formElement}>
          <Controller
            control={control}
            name={field.name}
            defaultValue=""
            render={props => {
              if (field.type === 'radio') {
                return (
                  <>
                    <Typography className={classes.font14} style={{ textAlign: 'left' }}>
                      {field.label}
                    </Typography>
                    <Flex gap="24px" margin="0 -9px">
                      {[
                        { value: ECategory.FIN, label: 'FinTech', tooltip: TOOLTIPS.FinTech },
                        { value: ECategory.INS, label: 'InsurTech', tooltip: TOOLTIPS.InsurTech },
                        { value: ECategory.REG, label: 'RegTech', tooltip: TOOLTIPS.RegTech },
                      ].map(i => (
                        <Flex
                          key={i.value}
                          className={classes.pointer}
                          alignItems="center"
                          onClick={() => props.onChange(i.value)}
                        >
                          <FCTRadio checked={i.value === control.getValues(field.name)} />
                          <FCTTooltip
                            arrow
                            title={<Typography className={classes.font12}>{i.tooltip}</Typography>}
                            placement="top"
                          >
                            <Typography className={classes.font12}>{i.label}</Typography>
                          </FCTTooltip>
                        </Flex>
                      ))}
                    </Flex>
                  </>
                )
              }
              return (
                <FCTTextField
                  {...props}
                  {...(field.type === 'textarea'
                    ? {
                        multiline: true,
                        rows: 4,
                      }
                    : {})}
                  className={classes.input}
                  label={field.label}
                  placeholder={field.label}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message || ''}
                  fullWidth
                  onBlur={() => {
                    control.setValue(field.name, control.getValues(field.name)?.trim() || '')
                    trigger(field.name)
                  }}
                  FormHelperTextProps={
                    !!errors[field.name]
                      ? {
                          role: 'alert',
                        }
                      : undefined
                  }
                  inputProps={{ id: `#${field.name}` }}
                  InputLabelProps={{ htmlFor: `#${field.name}` }}
                />
              )
            }}
          />
        </div>
      ))}

      <SubmitButton disabled={!isValid} loading={loadingSubmit} />
      {onBack && (
        <button
          className={clsx(classes.button, classes.secondaryBtn)}
          onClick={() => onBack()}
          type="button"
          disabled={loadingSubmit}
        >
          Back
        </button>
      )}
    </form>
  )
}

export default CompanyDetailsForm

import { Typography, makeStyles, useTheme } from '@material-ui/core'
import Flex from 'components/Flex/Flex'
import Icon from 'components/Icon/Icon'
import { LOCAL_STORAGE_FIELDS } from '../../utils/consts'

const useStyles = makeStyles(theme => ({
  error: {
    marginTop: '8px',
    padding: '10px',
    background: '#FFEFEF',
    textAlign: 'left',
    alignItems: 'baseline',

    '& p': {
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.grey[800],
    },
  },
}))

export enum EErrorCode {
  EMAIL_ALREADY_USED,
  ACCOUNT_HAS_NOT_BEEN_CREATED,
  IS_PENDING_APPROVAL,
  UNKNOWN,
  HAVE_LINKEDIN_NO_OKTA,
  // User is neither Startups nor Clients
  NOT_ASSIGN_TO_ANY_GROUPS,
  USER_GROUP_EXPIRED,
}

type Type = 'signIn' | 'signUp'

const getErrorMessage = (type: Type, errorCode: string | EErrorCode): string => {
  const ERROR_MESSAGES = {
    [`${EErrorCode.EMAIL_ALREADY_USED}`]: 'This email address is already being used.',
    [`${EErrorCode.ACCOUNT_HAS_NOT_BEEN_CREATED}`]: `Account doesn't exist.`,
    [`${EErrorCode.IS_PENDING_APPROVAL}`]: `Account is waiting for admin approval.`,
    [`${EErrorCode.UNKNOWN}`]:
      type === 'signIn' ? 'Login failed, try again.' : 'Registration failed.',
    [`${EErrorCode.HAVE_LINKEDIN_NO_OKTA}`]: 'User not found. Please login with your business email.',
    [`${EErrorCode.NOT_ASSIGN_TO_ANY_GROUPS}`]: 'User does not have permission to login.',
  }
  return ERROR_MESSAGES[`${errorCode}`] || ''
}

export default function AuthError({ type, error = '' }: { type?: Type; error?: string }) {
  const text =
    error ||
    (!type
      ? ''
      : getErrorMessage(
          type,
          localStorage.getItem(LOCAL_STORAGE_FIELDS.SIGN_IN_UP_ERROR) || EErrorCode.UNKNOWN
        ))

  if (!text) return null
  return <ErrorMessage text={text} />
}

export function ErrorMessage({ text }: { text: string }) {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Flex className={classes.error} gap="8px">
      <div>
        <Icon
          name="warningCircle"
          width={'12px'}
          height={'12px'}
          color={theme.palette.error.main}
        />
      </div>
      <Typography>{text}</Typography>
    </Flex>
  )
}

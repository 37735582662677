import {
  Avatar,
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  RadioProps,
  Theme,
  Typography,
} from '@material-ui/core'
import { Replay } from '@material-ui/icons'
import clsx from 'clsx'
import FCTButton from 'components/Button/FCTButton'
import { ETypeDialog, FCTDialog } from 'components/Dialog/FCTDialog'
import { PopoverWithoutAnchor } from 'components/FCTPopover/FCTPopover'
import Flex from 'components/Flex/Flex'
import FCTRadio from 'components/Radio/FCTRadio'
import { FCTOption } from 'components/Select/FCTSelect'
import { FCTSelectRadio } from 'components/Select/FCTSelectRadio'
import { useCompanyProfileContext } from 'contexts/CompanyProfileContext'
import { startCase } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import theme from 'theme'
import { TopCompaniesLocation } from 'utils/convert/Title'
import { ETaxonomyCategory, ITaxonomyOptions, TaxonomyFilterAvatar } from 'utils/FlattenEcoSystem'
import { GetCompanyCategoriesTags } from '__generated__/GetCompanyCategoriesTags'
import { GetCompanyInformation } from '__generated__/GetCompanyInformation'
import FCTIcon from '../../../Icon/Icon'
import { ICompareProps } from '../ProfileSidebarSimilarCompany'
import {
  getInitialCategory,
  IUserFilterTopCompanies_DefaulValue,
  Location,
  useFilterTopCompanies,
} from '../TopCompanies'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: '1px solid #E2E8F0',
      padding: '8px 0',
    },
    scrollContainer: {
      height: '100%',
      overflow: 'auto',
      marginRight: '4px',
      '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey['300'],
        borderRadius: 10,
      },
      scrollbarWidth: 'thin',
      scrollbarColor: theme.palette.grey['300'],
    },
    buttonMore: {
      width: '100%',
      height: 40,
      padding: '8px 14px',
      border: '1px solid #E7ECF3',
      fontSize: 14,
      fontWeight: 'normal',
      background: '#FFFFFF',
      minWidth: 190,
    },
    titleHeader: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '26px',
    },
    reset: {
      color: '#FF9F19',
      textTransform: 'unset',
    },
    dialogContent: {
      display: 'flex',
      height: '50vh',
    },
    item: {
      padding: '20px',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey['300'],
        borderRadius: 10,
      },
      scrollbarWidth: 'thin',
      scrollbarColor: theme.palette.grey['300'],
    },
    border: {
      position: 'relative',
      '&::before': {
        position: 'absolute',
        left: 'calc(100% - 1px)',
        top: 0,
        bottom: 0,
        height: '100%',
        width: '1px',
        backgroundColor: theme.palette.divider,
        content: '""',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '42px',
    },
    textHeader: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    filterContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    textContent: {
      fontSize: 14,
      lineHeight: '22px',
    },
    subHeader: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '22px',
    },
    textPrimary: {
      color: theme.palette.primary.light,
    },
    options: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      width: '24px',
      height: '24px',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '22px',
      color: theme.palette.grey[800],
      marginRight: '8px',
    },
  })
)

enum ETaxonomyLabel {
  businessLine = 'Business Line',
  valueChain = 'Value Chain',
}

export const Top10FilterDialog = ({
  defaultFilter,
  open,
  setOpen,
  companyInfo,
  businessLineValueChains,
  productLines,
  operatingModels,
  handleAgreeFilter,
  industry,
  setIndustry,
}: {
  defaultFilter: IUserFilterTopCompanies_DefaulValue
  open: boolean
  setOpen: (value: boolean) => void
  companyInfo: GetCompanyInformation
  businessLineValueChains: ITaxonomyOptions[]
  productLines: ITaxonomyOptions[]
  operatingModels: ITaxonomyOptions[]
  handleAgreeFilter: (input: {
    geography: TopCompaniesLocation
    country: Location | null
    operatingValue: number | null
    businessValue: number | null
    productLine: number | null
    industry: string
  }) => void | Promise<void>
  industry: string
  setIndustry: React.Dispatch<React.SetStateAction<ICompareProps>>
}) => {
  const classes = useStyles()
  const { companyCategoriesTags } = useCompanyProfileContext()

  const companyInformation = companyInfo?.getCompanyInformation?.companyInformation
  const categoriesTags = companyCategoriesTags?.getCompanyCategoriesTags?.categoriesTags

  const showBusinessValues = businessLineValueChains.filter(item => item.category === industry)
  const showProductLines = productLines.filter(item => item.category === industry)
  const showOperatingModels = operatingModels.filter(item => item.category === industry)
  const locations: Location[] = [
    ...(!companyInformation?.headquarterObject
      ? []
      : [{ ...companyInformation?.headquarterObject, isHeadquarter: true }]),
  ]
    .filter(Boolean)
    .map(i => ({
      location_id: i!.location_id,
      headquarter: i!.headquarter,
      address: i!.address,
      company_id: i!.company_id,
      country: i!.country,
      city: i!.city,
      region1_name: i!.region1_name,
      region2_name: i!.region2_name,
      region3_name: i!.region3_name,
      country_int: i!.country_int,
      isHeadquarter: i!.location_id === companyInformation?.headquarterObject?.location_id,
    }))

  const {
    businessValue,
    setBusinessValue,
    operatingValue,
    setOperatingValue,
    productLine,
    setProductLine,
    geography,
    setGeography,
    country,
    setCountry,
  } = useFilterTopCompanies(defaultFilter)

  const resetInitialTab = () => {
    setIndustry(prev => ({ ...prev, category: getInitialCategory(categoriesTags, productLines) }))
  }

  const resetInitialTaxonomy = () => {
    setBusinessValue(businessLineValueChains.filter(item => item.category === industry)[0]?.value)
    setProductLine(productLines.filter(item => item.category === industry)[0]?.value)
    setOperatingValue(operatingModels.filter(item => item.category === industry)[0]?.value)
  }

  const resetInitialGeography = () => {
    setGeography(TopCompaniesLocation.Global)
    setCountry(null)
  }

  const handleResetFilter = () => {
    resetInitialTab()
    resetInitialTaxonomy()
    resetInitialGeography()
  }

  const labelBusinessValue: ETaxonomyLabel | null = useMemo(() => {
    if (industry === ETaxonomyCategory.FinTech) {
      return ETaxonomyLabel.businessLine
    } else if (industry === ETaxonomyCategory.InsurTech) {
      return ETaxonomyLabel.valueChain
    }
    return null
  }, [industry])

  const TAXONOMY_CATEGORIES: FCTOption[] = [
    {
      label: 'FinTech',
      value: ETaxonomyCategory.FinTech,
      disabled:
        !categoriesTags?.businessLines?.filter(i => i.is_primary)?.length &&
        !productLines.filter(item => item.isPrimary && item.category === ETaxonomyCategory.FinTech)
          .length,
    },
    {
      label: 'RegTech',
      value: ETaxonomyCategory.RegTech,
      disabled: !productLines.filter(
        item => item.isPrimary && item.category === ETaxonomyCategory.RegTech
      ).length,
    },
    {
      label: 'InsurTech',
      value: ETaxonomyCategory.InsurTech,
      disabled:
        (!categoriesTags?.valueChains?.filter(i => i.is_primary)?.length ||
          !categoriesTags?.operatingModels?.filter(i => i.is_primary)?.length) &&
        !productLines.filter(
          item => item.isPrimary && item.category === ETaxonomyCategory.InsurTech
        ).length,
    },
  ]

  useEffect(() => {
    setIndustry(prev => ({
      ...prev,
      category: businessValue
        ? (businessLineValueChains.find(i => i.value === businessValue)
            ?.category as ETaxonomyCategory)
        : productLine
        ? (productLines.find(i => i.value === productLine)?.category as ETaxonomyCategory)
        : operatingValue
        ? (operatingModels.find(i => i.value === operatingValue)?.category as ETaxonomyCategory)
        : getInitialCategory(categoriesTags, productLines),
    }))
  }, [])

  return (
    <FCTDialog
      open={open}
      setOpen={setOpen}
      title={
        <div className={classes.header} style={{ height: '26px' }}>
          <Typography className={classes.titleHeader}>Filter</Typography>
          <Button
            className={classes.reset}
            variant="text"
            startIcon={<Replay />}
            onClick={handleResetFilter}
          >
            Reset
          </Button>
        </div>
      }
      type={ETypeDialog.Default}
      titleBtnAgree="Confirm"
      handleAgree={() => {
        handleAgreeFilter({
          geography,
          businessValue,
          operatingValue,
          productLine,
          country,
          industry,
        })
      }}
      handleCancel={() => setOpen(false)}
      disableBtnAgree={!businessValue && !productLine && !operatingValue}
      itemStyle={{ width: 813 }}
      hasCloseOutside={true}
      hasButtonClose={false}
    >
      <div className={classes.dialogContent}>
        <div className={clsx(classes.item, classes.border)} style={{ flex: '6 0 0' }}>
          <div className={classes.header}>
            <Typography className={classes.textHeader}>Taxonomy</Typography>
            <FCTSelectRadio
              data={TAXONOMY_CATEGORIES}
              value={[industry]}
              setValue={value => setIndustry(prev => ({ ...prev, category: value.value }))}
              fullWidthPercent={150 / 310}
              buttonCSS={{ height: 30, width: 150 }}
              size="small"
            />
          </div>

          {!!showBusinessValues.length && (
            <div className={classes.filterContent} style={{ paddingTop: '16px' }}>
              <Typography className={classes.subHeader}>{labelBusinessValue}</Typography>
              <Box margin="12px -8px">
                {showBusinessValues.map(item => (
                  <RadioItem
                    key={item.value}
                    label={<TaxonomyItemLabel item={item} />}
                    checked={businessValue === item.value}
                    onChange={() => {
                      setBusinessValue(prev => (prev === item.value ? null : item.value))
                    }}
                  />
                ))}
              </Box>
            </div>
          )}

          {!!showProductLines.length && (
            <>
              {!!showBusinessValues.length && <Divider />}
              <div className={classes.filterContent} style={{ paddingTop: '16px' }}>
                <Typography className={classes.subHeader}>Product Line</Typography>
                <Box margin="12px -8px">
                  {showProductLines.map(item => (
                    <RadioItem
                      key={item.value}
                      label={<TaxonomyItemLabel item={item} />}
                      checked={productLine === item.value}
                      onChange={() => {
                        setProductLine(prev => (prev === item.value ? null : item.value))
                      }}
                    />
                  ))}
                </Box>
              </div>
            </>
          )}

          {!!showOperatingModels.length && (
            <>
              {!!showProductLines.length && <Divider />}
              <div className={classes.filterContent} style={{ paddingTop: '16px' }}>
                <Typography className={classes.subHeader}>Operating Model</Typography>
                <Box margin="12px -8px">
                  {showOperatingModels.map(item => (
                    <RadioItem
                      key={item.value}
                      label={<TaxonomyItemLabel item={item} />}
                      checked={operatingValue === item.value}
                      onChange={() => {
                        setOperatingValue(prev => (prev === item.value ? null : item.value))
                      }}
                    />
                  ))}
                </Box>
              </div>
            </>
          )}
        </div>

        <div className={classes.item} style={{ flex: '4 0 0' }}>
          <div className={classes.header}>
            <Typography className={classes.textHeader}>Geography</Typography>
          </div>

          <div className={classes.filterContent} style={{ height: 'calc(100% - 42px)' }}>
            <FilterDialogGeography
              locations={locations}
              country={country}
              setCountry={setCountry}
              geography={geography}
              setGeography={setGeography}
            />
          </div>
        </div>
      </div>
    </FCTDialog>
  )
}

const TaxonomyItemLabel = ({ item }: { item: ITaxonomyOptions }) => {
  const classes = useStyles()

  return (
    <div className={classes.options}>
      <Avatar
        variant="circular"
        src={''}
        className={classes.avatar}
        children={item.avatar}
        style={{ backgroundColor: TaxonomyFilterAvatar.get(item.avatar)?.color }}
      />
      <Typography className={classes.textContent}>
        {item.label}
        {item.isPrimary ? <span className={classes.textPrimary}> (Primary)</span> : null}
      </Typography>
    </div>
  )
}

export const GEOGRAPHY_OPTIONS: {
  label: string
  value: TopCompaniesLocation
  key: keyof Location
}[] = [
  { label: 'Region', value: TopCompaniesLocation.Region, key: 'region1_name' },
  { label: 'Sub-region', value: TopCompaniesLocation.SubRegion, key: 'region2_name' },
  { label: 'Sub-region 2', value: TopCompaniesLocation.SubRegion2, key: 'region3_name' },
  { label: 'Country', value: TopCompaniesLocation.Country, key: 'country' },
]

const FilterDialogGeography = ({
  locations,
  country,
  setCountry,
  geography,
  setGeography,
}: {
  locations: Location[]
  country: Location | null
  setCountry: React.Dispatch<React.SetStateAction<Location | null>>
  geography: TopCompaniesLocation
  setGeography: React.Dispatch<React.SetStateAction<TopCompaniesLocation>>
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<TAnchorEl>(null)

  const handleOptionGeography = (value: TopCompaniesLocation) => {
    setGeography(value)
    value === TopCompaniesLocation.Global && setCountry(null)
  }

  const handleOptionLocation = (location: Location) => {
    setCountry(location)
    setGeography(TopCompaniesLocation.Country)
  }

  const label = country?.country
    ? country.country + `${country.isHeadquarter ? ` (Headquarter)` : ''}`
    : startCase(geography)

  return (
    <div>
      <GeographyPopover
        label={label}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        Content={
          <Paper className={classes.paper}>
            <Box maxHeight={190} className={classes.scrollContainer}>
              <RadioItem
                label="Global"
                checked={geography === TopCompaniesLocation.Global}
                onChange={() => {
                  handleOptionGeography(TopCompaniesLocation.Global)
                  setAnchorEl(null)
                }}
              />
              {(locations || []).map(option => (
                <Flex key={option.location_id} alignItems="center">
                  <RadioItem
                    label={
                      <Typography className={classes.textContent}>
                        {option.country}
                        <Typography
                          className={classes.textContent}
                          component="span"
                          color="textSecondary"
                        >
                          {option.isHeadquarter ? `(Headquarter)` : `(Operating)`}
                        </Typography>
                      </Typography>
                    }
                    checked={country?.location_id === option.location_id}
                    onChange={() => {
                      handleOptionLocation(option)
                      setAnchorEl(null)
                    }}
                  />
                </Flex>
              ))}
            </Box>
          </Paper>
        }
      />

      {country && (
        <Flex margin="10px -8px 0 -8px" flexDirection="column" gap="8px">
          {GEOGRAPHY_OPTIONS.filter(i => country[i.key]).map((i, idx) => (
            <React.Fragment key={idx}>
              <RadioItem
                radioProps={{ style: { marginTop: -20 } }}
                label={
                  <Box>
                    <Typography className={classes.textContent}>{i.label}</Typography>
                    <Typography color="textSecondary" className={classes.textContent}>
                      {country[i.key]}
                    </Typography>
                  </Box>
                }
                checked={geography === i.value}
                onChange={() => handleOptionGeography(i.value)}
              />
            </React.Fragment>
          ))}
        </Flex>
      )}
    </div>
  )
}

export const RadioItem = ({
  checked,
  onChange,
  label,
  radioProps,
}: {
  checked: boolean
  onChange(): void
  label: string | React.ReactElement
  radioProps?: RadioProps
}) => {
  const classes = useStyles()
  return (
    <Flex
      alignItems="center"
      style={{ cursor: 'pointer' }}
      onClick={onChange}
      data-testid="radioBtn"
    >
      <FCTRadio {...radioProps} checked={checked} />
      {typeof label === 'string' ? (
        <Typography className={classes.textContent}>{label}</Typography>
      ) : (
        label
      )}
    </Flex>
  )
}

export type TAnchorEl = HTMLButtonElement | HTMLDivElement | null
export const GeographyPopover = ({
  label,
  Content,
  anchorEl,
  setAnchorEl,
}: {
  label: string
  Content: React.ReactElement
  anchorEl: TAnchorEl
  setAnchorEl: React.Dispatch<React.SetStateAction<TAnchorEl>>
}) => {
  const classes = useStyles()

  return (
    <PopoverWithoutAnchor
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      fullWidth
      Trigger={props => (
        <FCTButton
          className={classes.buttonMore}
          role="btnSelect"
          endIcon={
            <FCTIcon
              name="arrowdown"
              width={10}
              height={10}
              color={theme.palette.grey[400]}
              style={{
                marginLeft: 8,
                transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          }
        >
          {label}
        </FCTButton>
      )}
      Content={Content}
    />
  )
}

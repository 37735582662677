import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/core'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import './index.css'
import 'react-virtualized/styles.css'
import './polyfills'
import theme from './theme'
import { createPack } from 'react-component-pack'
import { client } from './store/ApolloClient'
import ConfigContainer from './ConfigContainer'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { AuthRouter } from './routers/AuthRouter'
import { initSentry } from './utils/sentry'
import { Provider as ReduxProvider } from 'react-redux'
import store from './store/store'
import { SyncHistoryWithStore } from './store/syncHistoryWithStore'
import { FCTNotifications } from './components/AlertNotifications/FCTNotifications'
import { Worker } from '@react-pdf-viewer/core'
import { pdfjs } from 'react-pdf'
// https://github.com/mozilla/pdf.js/issues/10813
import workerContent from './core/lib/pdf-js/2.5.207/pdf.worker.min.json'
var workerBlob = new Blob([workerContent], { type: 'text/javascript' })
var workerBlobURL = URL.createObjectURL(workerBlob)
pdfjs.GlobalWorkerOptions.workerSrc = workerBlobURL

initSentry()

const ProviderPack = createPack(
  ConfigContainer,
  (props: any) => <ApolloProvider {...props} client={client} />,
  (props: any) => <ThemeProvider {...props} theme={theme} />,
  (props: any) => <SCThemeProvider {...props} theme={theme} />,
  (props: any) => <BrowserRouter {...props} />,
  (props: any) => <ReduxProvider {...props} store={store} />,
  (props: any) => <SyncHistoryWithStore {...props} />,
  (props: any) => <FCTNotifications {...props} />,
  (props: any) => <Worker workerUrl={workerBlobURL} {...props} />
)

ReactDOM.render(
  <ProviderPack>
    <AuthRouter />
  </ProviderPack>,
  document.getElementById('root')
)

export enum EDocumentTemplate {
  CLIENT,
  STARTUP,
}
export interface IDocumentTemplate {
  template: EDocumentTemplate
}

export interface IWebflowCollection {
  ['Collection ID']: string
  Content: string
  ['Created On']: string
  ['Item ID']: string
  Name: string
  ['Published On']: string
  Slug: string
  ['Updated On']: string
  ['Updated date']: string
  Version: string
}

import { concat, intersection, isEqual, isNumber, union, xor } from 'lodash'
import moment from 'moment'
import { ESearchTab } from '../components/ExploreDBComponent/MockData'
import { TitleTooltip } from '../components/ChipsHeader'
import { NUM_OF_EMPLOYEES_RANGES_ARR_VALUE } from '../core/constants/companyFilter'
import { initialAnalysisFilter, ChipItem } from '../store/features/analysis/AnalysisSlice'
import { initCompanyDealFilter } from '../store/features/company-deal/CompanyDealSlice'
import { setCurrentProfileTab } from '../store/operations/mutations'
import { SearchCompaniesResult } from '../store/operations/queries/local/rest/SearchCompanies'
import { ECompareType, ICompanyDealFilter } from '../types/CompanyDealFilter'
import { ESwitchFemale, IAnalysisFilter } from '../types/Filter'
import { GetAtlasViewData_getAtlasViewData } from '../__generated__/GetAtlasViewData'
import {
  GetDealFilterDataResult_getDealFilterData,
  GetDealFilterDataResult_getDealFilterData_products,
  GetDealFilterDataResult_getDealFilterData_tags,
  GetDealFilterDataResult_getDealFilterData_valueChains,
} from '../__generated__/GetDealFilterDataResult'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../__generated__/globalTypes'
import {
  convertProductId,
  ConvertYear,
  CATEGORY_TAXONOMY,
  NumOfEmployeesRanges,
  TAXONOMY_ID,
  getProducts,
  getValueChainsBySelectedId,
  getValueChainsFilterWhenSelectedId,
  getProductsFilterWhenSelectedId,
} from './CompanyFilter'
import { setEFDealType } from './DealType'
import { useWatchListId, useWatchListType } from '../store/features/watch-list/WatchListHook'
import { useState } from 'react'
import { useSubFilter } from 'store/features/company-deal/CompanyDealHook'
import { EEquityFChart } from 'containers/DashboardChartContainer'
import { IAppendixItem } from '../utils/pptxgenjs/genCompanyAppendix'
import { EWatchListType } from 'components/WatchList/SelectTabWatchList'

const generateTreeNode = (
  data: GetAtlasViewData_getAtlasViewData[],
  id: number | null,
  businessLineId: number | null
) => {
  if (!isNumber(id)) return []
  let nodes: GetAtlasViewData_getAtlasViewData[] = []
  const node = data.find(
    item =>
      item.id == id &&
      (isNumber(businessLineId) && id != 0 ? item.businessLineId == businessLineId : true)
  ) as GetAtlasViewData_getAtlasViewData
  const nodeById = data.find(el => el.id == id)
  nodes = node ? [node] : nodeById ? [nodeById] : []

  if (node && isNumber(node.parentId)) {
    nodes = concat(generateTreeNode(data, node.parentId, businessLineId), nodes)
  }

  if (!node && nodeById && isNumber(nodeById.parentId)) {
    nodes = concat(generateTreeNode(data, nodeById.parentId, businessLineId), nodes)
  }

  return nodes
}

export const getSelectedNode = (
  viewBy: any,
  data: GetAtlasViewData_getAtlasViewData[],
  productId: number | null,
  parentId: number | null
) => {
  if (!data?.length) return []
  const root = data.find((item: GetAtlasViewData_getAtlasViewData) => item.id == 0)

  if (!productId && !parentId) {
    return root ? [root] : []
  }

  if (viewBy == GlobalFilterViewBy.product) return generateTreeNode(data, productId, null)

  if (viewBy == GlobalFilterViewBy.business && productId)
    return generateTreeNode(data, productId, parentId || 0)

  return generateTreeNode(data, parentId || 0, null)
}

export function useCalled<T>() {
  const [called, setCalled] = useState<T[]>([])
  const isCalled = (input: T) => {
    return called.some((item: T) => isEqual(input, item))
  }
  return {
    isCalled,
    called,
    setCalled: (input: T) => {
      setCalled(prev => (isCalled(input) ? prev : [...prev, input]))
    },
  }
}

export const navigateToExploreDatabase = (props: {
  filterData: GetDealFilterDataResult_getDealFilterData
  filter: IAnalysisFilter
  selectedBusinessLineId?: number | null
  selectedProductId?: number | null
  category?: InternalDbCompanyTypes
  viewBy?: GlobalFilterViewBy
  setCompanyDealFilter: (state: any) => void
}) => {
  const {
    filterData,
    filter,
    selectedBusinessLineId,
    selectedProductId,
    category,
    viewBy,
    setCompanyDealFilter,
  } = props
  // TODO Map Analysis filter filter to Explore database
  let newBusinessLineId =
    selectedBusinessLineId !== undefined ? selectedBusinessLineId : filter.selectedBusinessLineId
  let newProductId = selectedProductId !== undefined ? selectedProductId : filter.selectedProductId

  setCompanyDealFilter({
    ...initCompanyDealFilter,
    ...filter,
    businessModelIds: filter.businessModelIds || [],
    businessLines: filter.businessLines,
    valueChains: filter.valueChains,
    products: filter.products,
    regions: filter.regions,
    subRegions: filter.subRegions,
    subRegion2s: filter.subRegion2s,
    countries: filter.countries,
    fundingRound1s: filter.fundingRound1s as number[],
    fundingRound2s: filter.fundingRound2s as number[],
    category: CATEGORY_TAXONOMY.get(newProductId || 0) || category || filter.category,
    companyStatuses: filter.companyStatuses,
    viewBy: !!viewBy ? viewBy : filter.viewBy,
    selectedProductId: newProductId,
    selectedBusinessLineId: newBusinessLineId,
  })
  setCurrentProfileTab(ESearchTab.Company)
}

export const getParentIds = (
  id: number,
  list: GetDealFilterDataResult_getDealFilterData_products[]
) => {
  let parentIds: number[] = [id]
  const parentNodes = list.find(el => !!list.find(i => i.id == id)?.parentIds?.includes(el.id))
  if (parentNodes) {
    parentIds = union(getParentIds(parentNodes.id, list), [parentNodes.id], parentIds, [id])
  }
  return parentIds
}

export const getSelectedIdFilter = (
  ids: number[],
  analysisFilter: any,
  productList: GetDealFilterDataResult_getDealFilterData_products[]
) => {
  const productLv1Ids = productList.filter(el => ids.includes(el.id)).map(el => el.productLv1Id)
  const updateFilter = {
    selectedProductId: ids?.length ? analysisFilter.selectedProductId : null,
    selectedBusinessLineId: analysisFilter.selectedBusinessLineId,
  }

  let ancestor: number[] = []
  let selectedBusinessLineId = null

  const businessLines = intersection(
    ...productList.filter(v => intersection(productLv1Ids).includes(v.id)).map(v => v.parentIds)
  )

  if (
    ((analysisFilter.viewBy == GlobalFilterViewBy.business && businessLines.length == 1) ||
      intersection(productLv1Ids).length == 1) &&
    ids?.length
  ) {
    for (const item of ids) {
      ancestor = ancestor.length
        ? intersection(ancestor, getParentIds(item, productList))
        : getParentIds(item, productList)
    }

    const productLv1 = productList.find(v => v.id == productLv1Ids[0])
    const businessFilterIds = (analysisFilter?.businessLines || []).filter((v: any) =>
      productLv1?.parentIds.includes(v)
    )

    if (
      analysisFilter.viewBy == GlobalFilterViewBy.business &&
      productLv1?.parentIds?.length == 1 &&
      !analysisFilter.businessLines?.length
    ) {
      selectedBusinessLineId = productLv1.parentIds[0]
    }

    if (analysisFilter.viewBy == GlobalFilterViewBy.business && businessFilterIds.length == 1) {
      selectedBusinessLineId = businessFilterIds[0]
    }

    if (analysisFilter.viewBy == GlobalFilterViewBy.business) {
      if (selectedBusinessLineId) {
        updateFilter.selectedProductId = ancestor[ancestor.length - 1] || null
        updateFilter.selectedBusinessLineId = selectedBusinessLineId
      } else {
        updateFilter.selectedProductId = null
        updateFilter.selectedBusinessLineId = null
      }
    } else if (analysisFilter.viewBy != GlobalFilterViewBy.valuechain) {
      updateFilter.selectedProductId = ancestor[ancestor.length - 1] || null
    }

    if (businessLines[0] == -2) {
      updateFilter.selectedBusinessLineId = -2
      updateFilter.selectedProductId = null
    }
  }

  return updateFilter
}

export const getValueChains = (
  id: number,
  valueChains: GetDealFilterDataResult_getDealFilterData_valueChains[]
) => {
  return valueChains.filter(e => e.level === 2 && e.parent_id === id).map(e => e.id)
}

export const analysisMappingId = (props: {
  id: number
  setFilter(value: any): void
  filter: IAnalysisFilter
  products: GetDealFilterDataResult_getDealFilterData_products[]
  viewByNew?: GlobalFilterViewBy | null
  categoryNew?: InternalDbCompanyTypes | null
  isHaveBusinessAndProduct?: boolean
  businessId?: number
  valueChains: GetDealFilterDataResult_getDealFilterData_valueChains[]
}) => {
  const {
    id,
    setFilter,
    filter,
    products,
    viewByNew,
    categoryNew,
    isHaveBusinessAndProduct = false,
    businessId,
    valueChains,
  } = props
  const categoryViewAll =
    products.find(item => item.id == id)?.category || CATEGORY_TAXONOMY.get(id) || filter.category
  const category = !!categoryNew
    ? categoryNew
    : filter.category == InternalDbCompanyTypes.all
    ? categoryViewAll
    : filter.category
  const viewBy = !!viewByNew ? viewByNew : filter.viewBy
  setFilter({
    ...filter,
    category: category,
    viewBy: viewBy,
  })

  const productsList = getProductsFilterWhenSelectedId({
    selectedId: id,
    productsData: products || [],
    productsFilter: filter.products || [],
    category: category,
  })

  const valueChainsList = getValueChainsFilterWhenSelectedId({
    selectedId: id,
    valueChainsData: valueChains || [],
    valueChainsFilter: filter?.valueChains || [],
  })

  if (category !== InternalDbCompanyTypes.all) {
    switch (viewBy) {
      case GlobalFilterViewBy.business: {
        setFilter({
          selectedBusinessLineId: isHaveBusinessAndProduct ? businessId : id,
          businessLines: isHaveBusinessAndProduct ? [businessId] : [id],
        })
        break
      }
      case GlobalFilterViewBy.product: {
        setFilter({
          products: productsList,
          selectedProductId: id > 0 ? id : null,
        })
        break
      }
      case GlobalFilterViewBy.valuechain: {
        setFilter({
          selectedBusinessLineId: valueChainsList[0] || null,
          valueChains: valueChainsList,
        })
        break
      }
      case GlobalFilterViewBy.operatingModel: {
        setFilter({
          operatingModels: [id],
        })
        break
      }
      default:
        break
    }
  } else {
    if (id > 0)
      setFilter({
        products: intersection(convertProductId(id, products, category), filter.products || []),
        selectedProductId: id,
      })
  }
}

export const navigateToAnalysisDefault = (props: {
  filter: ICompanyDealFilter
  analysisData: GetDealFilterDataResult_getDealFilterData | null
  setAnalysisFilter: (state: any) => void
}) => {
  const { filter, analysisData, setAnalysisFilter } = props

  const products = filter.selectedProductId
    ? convertProductId(filter.selectedProductId, analysisData?.products || [])
    : []
  setAnalysisFilter({
    ...initialAnalysisFilter,
    ...filter,
    businessLines: filter.businessLines,
    valueChains: filter.valueChains,
    products: filter.selectedProductId
      ? !!xor(products, filter.products).length || products[0] === filter.products[0]
        ? filter.products
        : null
      : filter.products,
    regions: filter.regions,
    subRegions: filter.subRegions,
    subRegion2s: filter.subRegion2s,
    countries: filter.countries,
    fundingRound1s: filter.fundingRound1s as number[],
    fundingRound2s: filter.fundingRound2s as number[],
    businessModelIds: filter.businessModelIds,
    searchData: filter.searchData,
    category: filter.category,
    viewBy: filter.viewBy,
    selectedBusinessLineId: filter.selectedBusinessLineId,
    selectedProductId: filter.selectedProductId,
    companyStatuses: filter.companyStatuses,
  })
}

const getNumOfEmployeesRanges = (filter: IAnalysisFilter): string[] | null => {
  return NumOfEmployeesRanges(
    filter.numOfEmployeesRanges.start,
    filter.numOfEmployeesRanges.end,
    NUM_OF_EMPLOYEES_RANGES_ARR_VALUE
  )
}

export const getInputAnalysis = (
  filter: IAnalysisFilter,
  filterData: GetDealFilterDataResult_getDealFilterData,
  companySearchFilter?: SearchCompaniesResult,
  similarCompaniesIds?: number[]
) => {
  const [watchListId, ___] = useWatchListId()
  const [watchListType, _____] = useWatchListType()
  const [subFilter, ____] = useSubFilter()
  const tagGroupIds = filter.tagGroupIds?.filter(
    e => !filterData?.tags?.map(e => e.tagGroupId)?.includes(e)
  )
  const listCompanyIds =
    companySearchFilter?.searchCompanies.hits.hit.map(hit => Number(hit.fields.company_id[0])) || []

  const listSubFilterIds =
    subFilter.compareType === ECompareType.ProfileSimilar ? subFilter.companyIds : []

  return {
    businessLines: filter.businessLines,
    products: filter.products,
    regions: filter.regions,
    subRegions: filter.subRegions,
    subRegion2s: filter.subRegion2s,
    countries: filter.countries,
    fintechTypes: filter.businessModelIds && filter.businessModelIds[0],
    fundingRound1s: setEFDealType(filter.fundingRound1s, filter.fundingRound2s) as number[],
    fundingRound2s: filter.fundingRound2s as number[],
    category: filter.category,
    viewBy: filter.viewBy,
    riskValueChains: filter.valueChains,
    selectedProductId:
      filter.viewBy === GlobalFilterViewBy.product && (filter.selectedProductId || 0) > 0
        ? filter.selectedProductId
        : null,
    selectedBusinessLineId: filter.selectedBusinessLineId ? +filter.selectedBusinessLineId : null,
    companyIds: [...listCompanyIds, ...listSubFilterIds, ...(similarCompaniesIds || [])],
    companyStatuses: filter.companyStatuses,
    distributionModelIds: filter.distributionModelIds,
    tagIds: tagGroupIds,
    //new
    numOfEmployeesRanges: getNumOfEmployeesRanges(filter),
    foundedYearFrom: ConvertYear(filter.foundedYearFrom),
    foundedYearTo: ConvertYear(filter.foundedYearTo),
    totalFundingFrom: filter.totalFundingFrom ? Number(filter.totalFundingFrom) : null,
    totalFundingTo: filter.totalFundingTo ? Number(filter.totalFundingTo) : null,
    equityFundingFrom: filter.equityFundingFrom ? Number(filter.equityFundingFrom) : null,
    equityFundingTo: filter.equityFundingTo ? Number(filter.equityFundingTo) : null,
    latestDealSizeFrom: filter.latestDealAmountFrom ? Number(filter.latestDealAmountFrom) : null,
    latestDealSizeTo: filter.latestDealAmountTo ? Number(filter.latestDealAmountTo) : null,
    fundingDateFrom:
      filter.fundingDateFrom && moment(new Date(filter.fundingDateFrom)).format('YYYY-MM-DD'),
    fundingDateTo:
      filter.fundingDateTo && moment(new Date(filter.fundingDateTo)).format('YYYY-MM-DD'),
    investorTypes: filter.investorsTypes,
    investors: filter.investors,
    leadInvestorTypes: filter.leadInvestorTypes,
    leadInvestors: filter.leadInvestors,
    acquirerTypes: filter.acquirerTypes,
    acquirers: filter.acquirers,
    acquiredDateFrom:
      filter.acquiredDateFrom && moment(new Date(filter.acquiredDateFrom)).format('YYYY-MM-DD'),
    acquiredDateTo:
      filter.acquiredDateTo && moment(new Date(filter.acquiredDateTo)).format('YYYY-MM-DD'),
    dealAmountFrom: filter.dealAmountFrom ? Number(filter.dealAmountFrom) : null,
    dealAmountTo: filter.dealAmountTo ? Number(filter.dealAmountTo) : null,
    latestFundingRound1s: setEFDealType(
      filter.latestFundingRound1s,
      filter.latestFundingRound2s
    ) as number[],
    latestFundingRound2s: filter.latestFundingRound2s as number[],
    numOfFundingRoundsFrom: !!Number(filter.numberOfFundingRounds.start)
      ? Number(filter.numberOfFundingRounds.start)
      : null,
    numOfFundingRoundsTo: !!filterData?.maxNumOfFundingRounds
      ? !!Number(filter.numberOfFundingRounds.end) &&
        Number(filter.numberOfFundingRounds.end) !== filterData?.maxNumOfFundingRounds
        ? Number(filter.numberOfFundingRounds.end)
        : null
      : null,
    numOfInvestorsFrom: !!Number(filter.numberOfInvestorsRanges.start)
      ? Number(filter.numberOfInvestorsRanges.start)
      : null,
    numOfInvestorsTo: !!filterData?.maxNumOfInvestors
      ? !!Number(filter.numberOfInvestorsRanges.end) &&
        Number(filter.numberOfInvestorsRanges.end) !== filterData?.maxNumOfInvestors
        ? Number(filter.numberOfInvestorsRanges.end)
        : null
      : null,
    watchListId: watchListType == EWatchListType.filterConditions ? null : watchListId,
    numOfFemaleFounderFrom:
      filter.switchFemalePicker == ESwitchFemale.LeastFemale
        ? 1
        : filter.switchFemalePicker == ESwitchFemale.CustomCount
        ? Number(filter.numOfFemaleFounderFrom) || null
        : null,
    numOfFemaleFounderTo:
      filter.switchFemalePicker == ESwitchFemale.CustomCount
        ? filter.numOfFemaleFounderTo && !!filterData?.maxFemaleFounder
          ? Number(filter.numOfFemaleFounderTo) !== filterData?.maxFemaleFounder
            ? Number(filter.numOfFemaleFounderTo)
            : null
          : null
        : null,
    numOfFemaleFounderPercentFrom:
      filter.switchFemalePicker == ESwitchFemale.CustomDistribution
        ? Number(filter.numOfFemaleFounderPercentFrom) / 100 || null
        : null,
    numOfFemaleFounderPercentTo:
      filter.switchFemalePicker == ESwitchFemale.CustomDistribution
        ? Number(filter.numOfFemaleFounderPercentTo) !== 100 && !!filter.numOfFemaleFounderPercentTo
          ? Number(filter.numOfFemaleFounderPercentTo) / 100
          : null
        : null,
    wentPublicOnDateFrom:
      filter.wentPublicOnDateFrom &&
      moment(new Date(filter.wentPublicOnDateFrom)).format('YYYY-MM-DD'),
    wentPublicOnDateTo:
      filter.wentPublicOnDateTo && moment(new Date(filter.wentPublicOnDateTo)).format('YYYY-MM-DD'),
    operatingModels: filter.operatingModels,
    hasFilterSmartCluster: !!filter?.smartCluster?.keywords?.length,
  }
}

export const getCompareDate = (
  dateFirst: string | null,
  dateSecond: string | null,
  type: string
) => {
  if (!dateFirst) return dateSecond
  if (!dateSecond) return dateFirst
  if (type === 'later')
    return new Date(dateFirst).getTime() > new Date(dateSecond).getTime() ? dateFirst : dateSecond
  else
    return new Date(dateFirst).getTime() < new Date(dateSecond).getTime() ? dateFirst : dateSecond
}

export const tagGroupsMapDataFilter = (
  tagsData: GetDealFilterDataResult_getDealFilterData_tags[]
) => {
  let data: { id: number; name: string }[] = []
  tagsData.forEach(e => {
    const dataMap = e.tags?.map(el => ({
      id: el?.tag_id || -1,
      name: el?.tag_name || '',
    }))
    data = concat(data, dataMap || [], { id: e.tagGroupId, name: e.tagGroupName })
  })
  return data
}

export const useSetSelectedTab = (
  setAnalysisFilter: (state: any) => void,
  filter: IAnalysisFilter,
  value: EEquityFChart
) => {
  if (value === EEquityFChart.EFProductLine && filter.viewBy !== GlobalFilterViewBy.product) {
    setAnalysisFilter({
      ...filter,
      viewBy: GlobalFilterViewBy.product,
    })
    return
  }
  if (value === EEquityFChart.EFBusinessLine || value === EEquityFChart.EFValueChain)
    setAnalysisFilter({
      ...filter,
      viewBy:
        filter.category === InternalDbCompanyTypes.fintech
          ? GlobalFilterViewBy.business
          : GlobalFilterViewBy.valuechain,
    })
  if (value === EEquityFChart.EFOperatingModel) {
    setAnalysisFilter({
      ...filter,
      viewBy: GlobalFilterViewBy.operatingModel,
    })
  }
}

export const genFiltersApplied = (chipsHeader: ChipItem[]) => {
  const objChips: any = {}
  chipsHeader.forEach(e => {
    const value = objChips[e.key] ? objChips[e.key] + ', ' : ''
    objChips[e.key] = value + e.label
  })
  const data: IAppendixItem[] = Object.keys(objChips).map(key => {
    return {
      name: key in TitleTooltip ? TitleTooltip[key as keyof typeof TitleTooltip] : '',
      description: objChips[key],
    }
  })
  const dataFilter = data.map(e => `${e.name} = ${e.description}`)
  return dataFilter.join('; ')
}

import { useDispatch, useSelector } from 'react-redux'
import {
  selectWatchListId,
  selectWatchListTab,
  selectWatchListName,
  selectSharePermission,
  selectWatchListFilter,
  selectWatchListType,
  selectShareWatchList,
  selectOpenNotifications,
} from './WatchListSelector'
import {
  updateWatchListId,
  updateWatchListTab,
  updateWatchListName,
  ISharePermission,
  updateSharePermission,
  updateWatchListFilter,
  updateWatchListType,
  EWatchListTab,
  IShareWatchList,
  updateShareWatchList,
  updateOpenNotificationse,
} from './WatchListSlice'
import { IAnalysisFilter } from '../../../types/Filter'
import { EWatchListType } from '../../../components/WatchList/SelectTabWatchList'

const useWatchListId = (): [number | null, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectWatchListId)
  const update = (state: any) => {
    dispatch(updateWatchListId(state))
  }

  return [state, update]
}

const useWatchListName = (): [string | null, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectWatchListName)
  const update = (state: any) => {
    dispatch(updateWatchListName(state))
  }

  return [state, update]
}

const useWatchListFilter = (): [IAnalysisFilter, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectWatchListFilter)
  const update = (state: any) => {
    dispatch(updateWatchListFilter(state))
  }
  return [state, update]
}

const useWatchListType = (): [EWatchListType | null, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectWatchListType)
  const update = (state: any) => {
    dispatch(updateWatchListType(state))
  }
  return [state, update]
}
const useWatchListTab = (): [EWatchListTab, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectWatchListTab)
  const update = (state: any) => {
    dispatch(updateWatchListTab(state))
  }

  return [state, update]
}

const useSharePermission = (): [ISharePermission, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectSharePermission)
  const update = (state: any) => {
    dispatch(updateSharePermission(state))
  }

  return [state, update]
}

const useShareWatchList = (): [IShareWatchList, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectShareWatchList)
  const update = (state: any) => {
    dispatch(updateShareWatchList(state))
  }

  return [state, update]
}

const useOpenNotifications = (): [boolean, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectOpenNotifications)
  const update = (state: any) => {
    dispatch(updateOpenNotificationse(state))
  }

  return [state, update]
}

export {
  useWatchListId,
  useWatchListName,
  useWatchListTab,
  useSharePermission,
  useWatchListFilter,
  useWatchListType,
  useShareWatchList,
  useOpenNotifications,
}

import { Box, Container, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import heroResearch from 'assets/images/researchAndAdvisory/hero-reseach-bg.jpg'

import clsx from 'clsx'
import FCTButton from 'components/Button/FCTButton'
import { RESEARCH_DATA } from 'core/constants/String'

const useStyles = makeStyles(theme =>
  createStyles({
    scheroResearch: {
      paddingTop: 300,
      paddingBottom: 240,
      backgroundImage: `url(${heroResearch})`,
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    container: {
      maxWidth: 1280,
      paddingRight: 32,
      paddingLeft: 32,
    },
    scheroReseachTitle: {
      marginBottom: 20,
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      fontWeight: 700,
      fontSize: 60,
      lineHeight: '72px',
      whiteSpace: 'normal',
    },
    scheroReseachSub: {
      color: 'hsla(0, 0%, 100%, 0.7)',
      textAlign: 'center',
    },
    sccontentResearch: {
      paddingTop: 60,
      paddingBottom: 140,
    },
    researchItem: {
      paddingTop: 80,
    },
    researchItemImg: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      height: '100%',
      transform: 'translate(-50%, -50%)',
      display: 'inline-block',
      maxWidth: '100%',
    },
    researchItemContent: {
      marginLeft: 18,
      marginRight: 18,
    },
    researchItemTitle: {
      marginBottom: 24,
      fontSize: 24,
      lineHeight: '34px',
      fontWeight: 700,
    },
    reseachItemSub: {
      marginBottom: 15,
      color: theme.palette.grey[600],
    },
    text16: {
      fontSize: 16,
      lineHeight: '24px',
    },
    last: {
      marginBottom: 32,
    },
    button: {
      verticalAlign: 'baseline',
      minWidth: 32,
      marginTop: 1,
      padding: '14px 40px !important',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    linkItem: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontSize: 14,
      lineHeight: '22px',
    },
    researchItemImgWrap: {
      position: 'relative',
      overflow: 'hidden',
      height: 320,
      marginRight: 60,
      marginLeft: 20,
      borderRadius: 10,
    },
  })
)

export const ResearchAndAdvisoryPage = () => {
  const classes = useStyles()

  return (
    <Box height="100vh" overflow="auto" width="100%">
      <div className={classes.scheroResearch}>
        <Container className={classes.container}>
          <Typography variant="h1" className={classes.scheroReseachTitle}>
            Advisory and research services in FinTech, RegTech and InsurTech
          </Typography>
          <Typography className={classes.scheroReseachSub}>
            We provide organizations of all sizes with actionable insights to decisively execute on
            their FinTech agenda
          </Typography>
        </Container>
      </div>
      <div className={classes.sccontentResearch}>
        <Container className={classes.container}>
          <Grid container>
            {RESEARCH_DATA.map((e, i) => (
              <ResearchItem item={e} index={i} />
            ))}
          </Grid>
        </Container>
      </div>
    </Box>
  )
}

export interface IResearchItem {
  img: string
  title: string
  subTitle: string
  subLastTitle: string
  textButton?: string
}

const ResearchItem = ({ item, index }: { item: IResearchItem; index: number }) => {
  const classes = useStyles()

  const ResearchImgItem = () => {
    return (
      <div className={classes.researchItemImgWrap}>
        <img src={item.img} loading="lazy" alt="img" className={classes.researchItemImg}></img>
      </div>
    )
  }

  const ResearchContentItem = () => {
    return (
      <div className={classes.researchItemContent}>
        <Typography variant="h3" className={classes.researchItemTitle}>
          {item.title}
        </Typography>
        <Typography className={clsx(classes.text16, classes.reseachItemSub)}>
          {item.subTitle}
        </Typography>
        <Typography className={clsx(classes.reseachItemSub, classes.text16, classes.last)}>
          {item.subLastTitle}
        </Typography>
        {item?.textButton ? (
          <FCTButton variant="outlined" color="default" className={classes.button}>
            <a href="https://www.bcg.com/" target="_blank" className={classes.linkItem}>
              {item.textButton}
            </a>
          </FCTButton>
        ) : null}
      </div>
    )
  }

  return (
    <>
      <Grid item md={6} className={classes.researchItem}>
        {index % 2 ? <ResearchContentItem /> : <ResearchImgItem />}
      </Grid>
      <Grid item md={6} className={classes.researchItem}>
        {index % 2 ? <ResearchImgItem /> : <ResearchContentItem />}
      </Grid>
    </>
  )
}

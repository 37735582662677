import { gql } from '@apollo/client'

export const GET_COMPANY_PENDING_STATUS = gql`
  query GetCompanyPendingStatus($companyId: Int!) {
    getCompanyPendingStatus(companyId: $companyId) {
      pending_status
      date_verified
    }
  }
`

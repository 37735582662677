import { useOktaAuth } from '@okta/okta-react'
import LinkedIn, { LinkedInProps } from '../components/Authentication/LinkedIn'
import config from '../core/config/auth'
import { ESignUpAs } from '../pages/RegistrationPage'

type Props = {
  signUpAs?: ESignUpAs.FINTECH
} & Pick<LinkedInProps, 'type' | 'disabled'>

/*
 * Legacy
 * Disabled due to (FB-1027)[https://bcgplatinionwesa.atlassian.net/browse/FB-1027]
 */

const LinkedinAuthContainer = ({ type, signUpAs, disabled = false }: Props) => {
  const { oktaAuth } = useOktaAuth()
  const isFintech = signUpAs === ESignUpAs.FINTECH

  const handleSignUp = () => {
    oktaAuth.signInWithRedirect({
      idp: config.linkedInIdpId,
      redirectUri:
        config.redirectUri +
        `${type == 'signUp' ? '/register' + (isFintech ? '?as=fintech' : '') : '/login'}`,
      originalUri: window.location.href,
    })
  }

  return <LinkedIn disabled={disabled} onClick={handleSignUp} type={type} />
}

export default LinkedinAuthContainer

import { gql } from '@apollo/client'
import { IDimensionTree } from '../../../../types/Dimension'

export const GET_BUBBLE_ACTIVE_NODE = gql`
  query GetBubbleActiveNode {
    bubbleActiveNode @client
  }
`

export interface GetBubbleActiveNode {
  bubbleActiveNode: IDimensionTree | null
}

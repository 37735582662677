import { yupResolver } from '@hookform/resolvers/yup'
import {
  makeStyles,
  Typography,
  Theme,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  Button,
} from '@material-ui/core'
import SubmitButton from 'components/Authentication/BusinessEmail/SubmitButton'
import { IField, FormField } from 'components/Authentication/FormField/FormField'
import Flex from 'components/Flex/Flex'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import FCTIcon from 'components/Icon/Icon'
import clsx from 'clsx'
import theme from 'theme'
import isUrl from 'utils/isUrl'

export interface INonBCGDetailsFormData {
  email: string
  firstName: string
  lastName: string
  companyName: string
  companyUrl: string
  role: string
  numberOfLogin: string
}

const useStyles = makeStyles((theme: Theme) => ({
  formElement: {
    marginTop: '30px',
  },
  input: {
    width: '100%',
    borderRadius: 3,
    paddingRight: 'unset',
    height: 68,
    backgroundColor: theme.palette.grey[100],
    fontSize: 14,
    '& .MuiFilledInput-input': {
      paddingLeft: 17,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[100],
    },
    '&:focus': {
      backgroundColor: 'unset',
    },
    '&.MuiFilledInput-underline::before': {
      content: 'unset ',
      position: 'unset',
    },
    '&.MuiFilledInput-underline::after': {
      content: 'unset',
      position: 'unset',
    },
  },
  switchLink: {
    color: `${theme.palette.primary.light}`,
    marginLeft: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: `${theme.palette.grey[800]}`,
  },
  isTier: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 12,
  },
  labelRadio: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  yourDetail: {
    color: theme.palette.grey[600],
    textAlign: 'left',
    marginBottom: 16,
  },
  companyDetail: {
    color: theme.palette.grey[600],
    textAlign: 'left',
    marginTop: 32,
  },
  labelEmail: {
    color: theme.palette.grey[600],
    fontSize: 14,
    fontWeight: 400,
    marginTop: 4,
    marginLeft: 6,
  },
  btnChange: {
    color: theme.palette.primary.light,
    textTransform: 'unset',
    marginBottom: 28,
    paddingRight: 20,
    '& span': {
      marginLeft: '5px',
      fontSize: 14,
      fontWeight: 400,
    },
  },
}))

type Props = {
  handleChangeEmail(): void
  handleSubmit: (data: INonBCGDetailsFormData) => void | Promise<void>
  email: string
}

const NonBCGDetails = ({ handleChangeEmail, handleSubmit, email }: Props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
  } = useForm<INonBCGDetailsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: email,
      firstName: '',
      lastName: '',
      companyName: '',
      companyUrl: '',
      role: '',
      numberOfLogin: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required('This field is required.'),
        firstName: yup.string().required('This field is required.'),
        lastName: yup.string().required('This field is required.'),
        companyName: yup.string().required('This field is required.'),
        companyUrl: yup
          .string()
          .required('This field is required.')
          .test('URL format', 'Invalid url format', (url: string = '') => isUrl(url)),
        role: yup.string().required('This field is required.'),
        numberOfLogin: yup.string().required('This field is required.'),
      })
    ),
  })

  const onBlurField = (field: IField<INonBCGDetailsFormData>) => {
    const value = control.getValues(field.name)
    typeof value === 'string' && control.setValue(field.name, value?.trim() || '')
  }

  return (
    <div>
      <form
        id="getAccessNonBCGDetailsForm"
        onSubmit={async e => {
          try {
            setLoading(true)
            await handleSubmitForm(d => handleSubmit(d))(e)
          } finally {
            setLoading(false)
          }
        }}
      >
        <Typography className={clsx(classes.yourDetail, classes.title)}>Your Details</Typography>

        <FormControl style={{ width: '100%' }}>
          <InputLabel htmlFor="filled-adornment" className={classes.labelEmail} variant="filled">
            Email Address
          </InputLabel>
          <Controller
            control={control}
            rules={{ required: true }}
            name="email"
            render={props => (
              <FilledInput
                id="filled-adornment"
                value={props.value}
                onChange={e => {}}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      onClick={() => handleChangeEmail()}
                      style={{ minWidth: 'unset' }}
                      className={classes.btnChange}
                    >
                      <FCTIcon color={theme.palette.primary.light} name="edit" />
                      <span>Change</span>
                    </Button>
                  </InputAdornment>
                }
                disabled={true}
                fullWidth
                className={classes.input}
              />
            )}
          />
        </FormControl>

        <Flex gap="12px">
          {([
            { name: 'firstName', label: 'First Name', placeholder: 'Enter first name' },
            { name: 'lastName', label: 'Last Name', placeholder: 'Enter last name' },
          ] as IField<INonBCGDetailsFormData>[]).map(field => (
            <div key={field.name} className={classes.formElement}>
              <FormField
                control={control}
                field={field}
                error={errors[field.name]}
                onBlur={() => onBlurField(field)}
              />
            </div>
          ))}
        </Flex>

        <Typography className={clsx(classes.companyDetail, classes.title)}>
          Company Details
        </Typography>

        <Flex gap="12px">
          {([
            { name: 'companyName', label: 'Company Name', placeholder: 'Enter company name' },
            {
              name: 'companyUrl',
              label: 'Company URL',
              placeholder: 'Enter company URL',
            },
          ] as IField<INonBCGDetailsFormData>[]).map(field => (
            <div key={field.name} className={classes.formElement}>
              <FormField
                control={control}
                field={field}
                error={errors[field.name]}
                onBlur={() => onBlurField(field)}
              />
            </div>
          ))}
        </Flex>

        <Flex gap="12px">
          {([
            { name: 'role', label: 'Your Role in Company', placeholder: 'Enter role in company' },
            {
              name: 'numberOfLogin',
              label: 'Number of Logins',
              placeholder: 'Enter number of logins',
              type: 'number',
            },
          ] as IField<INonBCGDetailsFormData>[]).map(field => (
            <div key={field.name} className={classes.formElement}>
              <FormField
                control={control}
                field={field}
                error={errors[field.name]}
                onBlur={() => onBlurField(field)}
              />
            </div>
          ))}
        </Flex>

        <SubmitButton
          form="getAccessNonBCGDetailsForm"
          disabled={!isValid}
          text="Submit"
          loading={loading}
        />
      </form>
    </div>
  )
}

export default NonBCGDetails

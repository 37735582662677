import { makeStyles, Typography } from '@material-ui/core'
import parse from 'html-react-parser'

const useStyles = makeStyles(theme => ({
  notiText: {
    fontSize: 14,
    lineHeight: '22px',
  },
}))

interface IWatchListToastsErrorProps {
  res: any
}

export const WatchListToastsError = (props: IWatchListToastsErrorProps) => {
  const classes = useStyles()
  const { res } = props

  return <Typography className={classes.notiText}>{parse(res || 'Unknown Error!!!')}</Typography>
}

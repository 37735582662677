import { useQuery } from '@apollo/client'
import { GET_INVESTORS_FILTER_DATA } from '../store/operations/queries/GetInvestorsFilterData'
import {
  GetInvestorsFilterData,
  GetInvestorsFilterDataVariables,
  GetInvestorsFilterData_getInvestorsFilterData,
} from '../__generated__/GetInvestorsFilterData'
import { AutocompleteInputChangeReason } from '@material-ui/lab'
import FCTAutoCompleteVirtualized, {
  IOption,
} from '../components/AutoComplete/FCTAutoCompleteVirtualized'
import Flex from '../components/Flex/Flex'
import theme from '../theme'
import { StyledChipItem } from '../components/ExploreDBComponent/InvestorItem'
import Icon from '../components/Icon/Icon'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from '@material-ui/core'

type Props = {
  placeHolder?: string
  label?: string
  hideSelectedList?: boolean
  value?: string[]
  onChange?(value: GetInvestorsFilterData_getInvestorsFilterData[]): void
  onDelete?(value: GetInvestorsFilterData_getInvestorsFilterData): void
  isAcquirer: boolean | null
  hiddenSelectedOption?: boolean
  allowPagination?: boolean
}

const PAGE_SIZE = 50

const InvestorListContainer = ({
  value = [],
  onChange = () => {},
  onDelete = () => {},
  ...props
}: Props) => {
  const [list, setList] = useState<GetInvestorsFilterData_getInvestorsFilterData[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [pageNumber, setPageNumber] = useState(props.allowPagination ? 1 : 0)
  const [searchText, setSearchText] = useState('')
  const { data, loading } = useQuery<GetInvestorsFilterData, GetInvestorsFilterDataVariables>(
    GET_INVESTORS_FILTER_DATA,
    {
      variables: {
        input: {
          search: props.allowPagination ? searchText : '',
          types: [],
          isAcquirer: props.isAcquirer,
          ...(props.allowPagination ? { pageNumber, pageSize: PAGE_SIZE } : {}),
        },
      },
      skip: !isMounted,
    }
  )

  useEffect(() => {
    if (props.allowPagination) {
      const incomingData = (data?.getInvestorsFilterData || []).map(item => ({
        ...item,
        id: `${item.id}`,
      }))
      setList(prev => [...(pageNumber === 1 ? [] : prev), ...incomingData])

      if (incomingData?.length < PAGE_SIZE) {
        setHasMore(false)
      }
    } else {
      setList(data?.getInvestorsFilterData || [])
    }
  }, [data])

  useEffect(() => {
    setTimeout(() => setIsMounted(true), 700)
  }, [])

  const handleCompanyListScroll = (
    event: Record<'scrollTop' | 'clientHeight' | 'scrollHeight', number>,
    hasMore: boolean
  ) => {
    if (!hasMore || !props.allowPagination) return
    const listboxNode = event
    if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 20) {
      setPageNumber(prev => prev + 1)
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    text: string,
    _reason: AutocompleteInputChangeReason
  ) => {
    if (props.allowPagination) {
      setList([])
      setPageNumber(1)
      setHasMore(true)
      setSearchText(text)
      event?.stopPropagation()
      event?.preventDefault()
    }
  }

  const aValue = useMemo(
    () => (props.allowPagination ? undefined : list?.filter(d => value?.includes(d.id))),
    [props.allowPagination, list, value]
  )

  return (
    <>
      <FCTAutoCompleteVirtualized
        multiple
        hiddenSelectedOption={props.hiddenSelectedOption}
        value={aValue}
        options={list || []}
        onInputChange={handleInputChange}
        placeHolder={props.placeHolder}
        label={props.label}
        onChange={(e, v) => {
          if (v !== null) Array.isArray(v) ? onChange(v) : onChange([v])
        }}
        loading={loading}
        ListboxProps={{
          loading,
          visibleItem: 7,

          onScroll: debounce(event => {
            !loading && handleCompanyListScroll(event, hasMore)
          }, 100),
        }}
      />
      {!props.hideSelectedList && (
        <Flex gap={4} mt={'10px'} flexWrap={'wrap'}>
          {value?.map(id => {
            const investor = list?.find(d => d.id === id)
            return (
              <StyledChipItem
                key={id}
                label={investor?.name || id || ''}
                variant="outlined"
                deleteIcon={<Icon name={'close1'} style={{ color: theme.palette.grey[500] }} />}
                style={{
                  color: theme.palette.grey[500],
                  borderColor: theme.palette.grey[400],
                  backgroundColor: '#FFFFFF',
                }}
                onDelete={() => {
                  investor && onDelete(investor)
                }}
              />
            )
          })}
        </Flex>
      )}
    </>
  )
}

export default InvestorListContainer

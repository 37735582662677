import { Dispatch, SetStateAction } from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { getSearchType } from '../helpers'
import { ISearchInputDetail } from '../interfaces'

const useStyles = makeStyles((theme: Theme) => ({
  autoCompleteItemContainer: {
    padding: '5px 14px',
    border: '1px solid #FFFFFF',
    borderRight: 'none',
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[50]}`,
      borderRight: 'none',
    },
    '&:hover': {
      background: '#FFFFFF',
      border: '1px solid #E7ECF3',
      borderRight: 'none',
      boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    },
    '&:hover $mutateAction': {
      visibility: 'visible',
    },
  },
  searchType: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    color: '#6C809D',
  },
  searchName: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
  },
}))

export const ResultEntity = ({
  option,
  onClick,
}: {
  option: ISearchInputDetail
  onClick: Dispatch<SetStateAction<ISearchInputDetail | null>>
}) => {
  const classes = useStyles()
  return (
    <div
      className={classes.autoCompleteItemContainer}
      onClick={() => {
        onClick(option)
      }}
    >
      <div className={classes.searchType}>{getSearchType(option)}</div>
      <div className={classes.searchName}>
        <strong>{option.name}</strong>
      </div>
    </div>
  )
}

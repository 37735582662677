import { useApolloClient, useQuery } from '@apollo/client'
import {
  Badge,
  createStyles,
  Drawer,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Flex from '../components/Flex/Flex'
import Icon, { IconMap } from '../components/Icon/Icon'
import FCTTooltip from '../components/Tooltip/Tooltip'
import { ModalUpdateWatchListFilter } from '../components/WatchList/ModalUpdateWatchListFilter'
import { EWatchListType } from '../components/WatchList/SelectTabWatchList'
import { DEFAULT_DATA_MAX } from '../containers/EDCompanyDealFilterContainer'
import SendContactMailContainer from '../containers/SendContactMailContainer'
import mixpanel from '../mixpanel'
import { useUserContext } from '../routers/InitUserWrapper'
import { useAnalysisFilter, useAnalysisLocalFilter } from '../store/features/analysis/AnalysisHook'
import { initialAnalysisFilter } from '../store/features/analysis/AnalysisSlice'
import { useCompanyDealFilter, useSubFilter } from '../store/features/company-deal/CompanyDealHook'
import {
  initCompanyDealFilter,
  initSubFilter,
} from '../store/features/company-deal/CompanyDealSlice'
import { useNumOfNotifications } from '../store/features/notification/NotificationHook'
import { resetSearchOption } from '../store/features/search/SearchSlice'
import {
  useWatchListFilter,
  useWatchListId,
  useWatchListName,
  useWatchListType,
} from '../store/features/watch-list/WatchListHook'
import { GET_DEAL_FILTER_DATA } from '../store/operations/queries/GetDealFilterData'
import { GET_UNREAD_NOTICATIONS_COUNT } from '../store/operations/queries/GetListNotifications'
import theme from '../theme'
import { useResetFilter } from '../utils/CompanyFilter'
import { NavigateHeaderTitle, PeriodType } from '../utils/convert/Title'
import { useBlockBeforeNavigate } from '../utils/navigationHook'
import { onError } from '../utils/sentry'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '../__generated__/GetDealFilterDataResult'

const drawerWidth = 230
const drawerHeight = 'calc(100vh - 57px)'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      height: drawerHeight,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      position: 'absolute',
      top: '57px',
      zIndex: 11,
    },
    paper: {
      height: drawerHeight,
      top: '57px',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 50,
      [theme.breakpoints.up('sm')]: {
        width: 50,
      },
    },
    flexNavItem: {
      height: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    rules: {
      '& > span': {
        width: 4,
        height: 4,
        background: theme.palette.grey[300],
        borderRadius: '50%',
        display: 'inline-block',
        margin: '0px 8px',
      },
    },
    privacy: {
      fontWeight: 400,
      fontSize: 12,
      color: theme.palette.grey[600],
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    footWrapper: {
      margin: '0 15px',
      padding: '13px 0 13px 0',
      borderTop: '1px solid #E7ECF3',
    },
    policy: {
      display: 'flex',
    },
  })
)

const NavbarContainer = styled.div`
  ${({ theme }: { theme: Theme }) => `
    background-color: ${theme.palette.primary.contrastText};
  `}
  padding-top: 10px;
  min-width: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const NavItem = styled.span`
  display: flex;
  align-items: center;
  padding: 13px 17px;
  text-decoration: none;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  ${({ theme }: { theme: Theme }) => `
    color: ${theme.palette.grey['500']};

    &.active,
    &:hover {
      color: ${theme.palette.primary.main};

      ${NavItemText} {
        font-weight: 600;
      }
    }

    &.active:before {
      content: '';
      width: 4px;
      height: 28px;
      background-color: ${theme.palette.primary.main};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%)
    }
  `}
`
const NavIcon = styled(Icon)`
  margin-right: 8px;
`

const NavItemText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 17px;
  cursor: pointer;
  min-width: 60px;
`

const ToolbarText = styled(Typography)`
  margin-left: 6px;
  font-size: 14px;
  ${({ theme }: { theme: Theme }) => `
    color: ${theme.palette.grey['700']};
  `}
`

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 10,
      top: -7,
      border: 0,
      padding: '2px 4px',
    },
  })
)(Badge)

const NavItemTooltip = (props: NavItemTooltipProp) => {
  const classes = useStyles()
  const { name, icon, open, badge } = props

  const Content = React.useCallback(() => {
    return (
      <div className={classes.flexNavItem}>
        {!!badge ? (
          <StyledBadge badgeContent={badge} color="error">
            <NavIcon name={icon} />
          </StyledBadge>
        ) : (
          <NavIcon name={icon} />
        )}

        {open && <NavItemText>{name}</NavItemText>}
      </div>
    )
  }, [open, name, badge, icon])

  return open ? (
    <Content />
  ) : (
    <FCTTooltip
      title={<Typography style={{ fontSize: 12 }}>{name}</Typography>}
      arrow
      placement="right"
    >
      <>
        <Content />
      </>
    </FCTTooltip>
  )
}

const NavbarFooter = ({ open }: { open: boolean }) => {
  const [contactDialog, setContactDialog] = React.useState(false)
  const classes = useStyles()
  return (
    <>
      {open && (
        <div className={classes.footWrapper}>
          <Flex className={classes.rules} alignItems="center">
            <a onClick={() => setContactDialog(true)} className={classes.privacy}>
              Contact Us
            </a>
            <span> </span>
            <a href={`${window.location.origin}/faqs`} target="_blank" className={classes.privacy}>
              FAQ
            </a>
          </Flex>

          <a
            href={`${window.location.origin}/privacy`}
            target="_blank"
            className={clsx(classes.privacy, classes.policy)}
          >
            Privacy Policy
          </a>
          <a
            href={`${window.location.origin}/terms`}
            target="_blank"
            className={clsx(classes.privacy, classes.policy)}
          >
            Acceptable Use and Content Policy
          </a>

          <Flex className={classes.rules} alignItems="center">
            <a href="javascript:CookieScript.instance.show();" className={classes.privacy}>
              Cookie Settings
            </a>
          </Flex>
        </div>
      )}
      <SendContactMailContainer open={contactDialog} setOpen={setContactDialog} />
    </>
  )
}

type Props = {
  disabled?: boolean
  isAdmin?: boolean
  restricted?: {
    notifications?: boolean
  }
}

const Navbar = (props: Props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [analysisFilter, setAnalysisFilter] = useAnalysisFilter()
  const [__, setLocalFilter] = useAnalysisLocalFilter()
  const [companyFilter, setCompanyFilter] = useCompanyDealFilter()
  const [watchListId, setWatchListId] = useWatchListId()
  const [_______, setSubFilter] = useSubFilter()
  const [____, setWatchListName] = useWatchListName()
  const [watchListFilter, _____] = useWatchListFilter()
  const [watchListType, ______] = useWatchListType()
  const { updateNotiStatus } = useNumOfNotifications()
  const dispatch = useDispatch()
  const { oktaAuth } = useOktaAuth()
  const { user } = useUserContext()

  const [
    companyFilterData,
    setCompanyFilterData,
  ] = React.useState<GetDealFilterDataResult_getDealFilterData>()

  const client = useApolloClient()
  const [confirmNavigate, setConfirmNavigate] = useState<boolean>(false)
  const history = useHistory()
  const { isBlocked } = useBlockBeforeNavigate()

  const [destinationPath, setDestinationPath] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.query({ query: GET_DEAL_FILTER_DATA })
        const companyFilterData = res?.data?.getDealFilterData
          ?.data as GetDealFilterDataResult_getDealFilterData
        await setCompanyFilterData(companyFilterData)
      } catch (err) {
        onError(err)
      }
    }
    !props.disabled && fetchData()
  }, [props.disabled])

  const resetWatchList = () => {
    setWatchListId(null)
    setWatchListName(null)
  }

  const resetFilter = useCallback(() => {
    useResetFilter(setAnalysisFilter, initialAnalysisFilter, companyFilterData)
    useResetFilter(setCompanyFilter, initCompanyDealFilter, companyFilterData)
    setSubFilter(initSubFilter)
  }, [companyFilterData])

  const pathName = history.location.pathname
  const hasWatchList = pathName.includes('/watch-lists')

  const handleClickNavbar = (path: string) => {
    setDestinationPath(path)
    handleSetFilter()
    if (isBlocked) {
      setConfirmNavigate(true)
    } else {
      history.push(path)
      dispatch(resetSearchOption(null))
      if (hasWatchList) {
        resetWatchList()
        resetFilter()
      }
    }
    setOpen(false)
  }

  const handleSetFilter = () => {
    if (hasWatchList) return
    const isOnAnalysisPage = pathName.includes('/analysis')
    const isOnPageNews = pathName.includes('/news')
    const isOnPageInvestors = pathName.includes('/investor-profile')
    if (isOnAnalysisPage || isOnPageNews || isOnPageInvestors) {
      setCompanyFilter(analysisFilter)
    } else {
      setAnalysisFilter(companyFilter)
    }
  }

  const logout = () => {
    oktaAuth.signOut()
  }

  const pathname = history.location.pathname

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <NavbarContainer>
        <div>
          {props.disabled ? (
            <NavItem
              className={pathname == '/app/profile' ? 'active' : ''}
              onClick={() => handleClickNavbar(`/app/profile/${user?.profile?.companyId}`)}
            >
              <NavItemTooltip name="Profile" icon="company" open={open}></NavItemTooltip>
            </NavItem>
          ) : (
            <>
              <NavItem
                className={pathname == '/app/smart-search' ? 'active' : ''}
                onClick={() => handleClickNavbar('/app/smart-search')}
              >
                <NavItemTooltip
                  name="Smart Search"
                  icon="advancedSearch"
                  open={open}
                ></NavItemTooltip>
              </NavItem>
              <NavItem
                className={pathname == '/app/dashboard' ? 'active' : ''}
                onClick={() => {
                  mixpanel.track('Dashboard View')
                  handleClickNavbar('/app/dashboard')
                }}
              >
                <NavItemTooltip name="Dashboard" icon="chart" open={open}></NavItemTooltip>
              </NavItem>
              <NavItem
                className={pathname == '/app/analysis/sunburst' ? 'active' : ''}
                onClick={() => {
                  mixpanel.track('Atlas View')
                  handleClickNavbar('/app/analysis/sunburst')
                }}
              >
                <NavItemTooltip name="Atlas" icon="pieChart" open={open}></NavItemTooltip>
              </NavItem>
              <NavItem
                className={pathname == '/app/list-view' ? 'active' : ''}
                onClick={() => {
                  mixpanel.track('List View')
                  handleClickNavbar('/app/list-view')
                }}
              >
                <NavItemTooltip
                  name={NavigateHeaderTitle.listView}
                  icon="list"
                  open={open}
                ></NavItemTooltip>
              </NavItem>
              <NavItem
                className={pathname.includes('/app/analysis/charts') ? 'active' : ''}
                onClick={() => {
                  mixpanel.track('Analytics View')
                  handleClickNavbar('/app/analysis/charts')
                }}
              >
                <NavItemTooltip
                  name={NavigateHeaderTitle.charts}
                  icon="chartcompare"
                  open={open}
                ></NavItemTooltip>
              </NavItem>
              <NavItem
                className={pathname == '/app/analysis/landscape' ? 'active' : ''}
                onClick={() => {
                  mixpanel.track('Landscape View')
                  handleClickNavbar('/app/analysis/landscape')
                }}
              >
                <NavItemTooltip
                  name={NavigateHeaderTitle.landscape}
                  icon="dashboard"
                  open={open}
                ></NavItemTooltip>
              </NavItem>
              <NavItem
                className={pathname == '/app/news' ? 'active' : ''}
                onClick={() => {
                  //TODO: await
                  // mixpanel.track('Reports View')
                  handleClickNavbar('/app/news')
                }}
              >
                <NavItemTooltip name="News" icon="page" open={open}></NavItemTooltip>
              </NavItem>
              <NavItem
                className={pathname == '/app/reports' ? 'active' : ''}
                onClick={() => {
                  mixpanel.track('Reports View')
                  handleClickNavbar('/app/reports')
                }}
              >
                <NavItemTooltip name="Reports" icon="reportsPage" open={open}></NavItemTooltip>
              </NavItem>
              <ModalUpdateWatchListFilter
                confirmNavigate={confirmNavigate}
                setConfirmNavigate={setConfirmNavigate}
                navigateToDestination={() => {
                  history.push(destinationPath)
                  if (hasWatchList) {
                    resetWatchList()
                    resetFilter()
                  }
                }}
              />
            </>
          )}
        </div>
        <div>
          {props.isAdmin && (
            <NavItem
              className={pathname == '/admin' ? 'active' : ''}
              onClick={() => {
                handleClickNavbar('/admin')
              }}
            >
              <NavItemTooltip name="Admin Panel" icon="admin" open={open} />
            </NavItem>
          )}
          <NavItem
            className={pathname == '/knowledge-hub' ? 'active' : ''}
            onClick={() => {
              mixpanel.track('KnowledgeHub View')
              handleClickNavbar('/knowledge-hub')
            }}
          >
            <NavItemTooltip name="Knowledge Hub" icon="question" open={open} />
          </NavItem>
          {!props.disabled && !props.restricted?.notifications && (
            <NotificationBellContainer
              onClick={() => {
                updateNotiStatus(false)
                handleClickNavbar('/app/notifications')
              }}
              open={open}
              active={pathname == '/app/notifications'}
            />
          )}
        </div>
      </NavbarContainer>

      <Toolbar onClick={() => logout()}>
        <div className={classes.flexNavItem}>
          <Icon name="export2" width={20} height={20} color={`${theme.palette.primary.light}`} />
          {open && <ToolbarText>Log out</ToolbarText>}
        </div>
      </Toolbar>

      <NavbarFooter open={open} />
    </Drawer>
  )
}

export default Navbar

interface NavItemTooltipProp {
  name: string
  icon: keyof typeof IconMap
  open: boolean
  badge?: React.ReactNode
}

const NotificationBellContainer = ({
  active,
  onClick,
  open,
}: {
  open: boolean
  active: boolean
  onClick(): void | Promise<void>
}) => {
  const { state: numOfNotifications, update: setNumOfNotifications } = useNumOfNotifications()
  const [watchlistId, _] = useWatchListId()
  useQuery(GET_UNREAD_NOTICATIONS_COUNT, {
    fetchPolicy: 'network-only',
    variables: {
      watchlistId: watchlistId,
    },
    onCompleted: data => {
      setNumOfNotifications(
        (data?.requestCount?.totalNotifications || 0) +
          (data?.companyUpdateCount?.totalNotifications || 0) +
          (data?.watchListUpdateCount?.totalNotifications || 0)
      )
    },
  })

  return (
    <NavItem className={active ? 'active' : ''} onClick={onClick}>
      <NavItemTooltip name="Notifications" icon="bell" open={open} badge={numOfNotifications} />
    </NavItem>
  )
}

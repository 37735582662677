import { useLazyQuery, useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import FCTSearchInput from '../../components/SearchInput/FCTSearchInput'
import { ISuggestResult, SUGGEST } from '../../store/operations/queries/local/rest/Suggest'

export interface ISearchCompaniesProps {
  handleData: (data: ICompanyOption[]) => void
  searchValue: string
  setSearchValue: (value: string) => void
  setLoading?: (value: boolean) => void
}

export interface ICompanyOption {
  id: number
  name: string
  logoUrl: string
  url: string
  total: number | null
  rank?: number
  inputId?: number[]
  fintechScore?: number
  occurrences?: number
  relevance?: number
}

export interface IGetSuggestCompanies {
  inputCompanyId: number
  similarCompanyId: number | null
  rankCompany: number | null
  similarCompanyName: string | null
  similarCompanyWebsiteUrl: string | null
  logoBucketUrl: string | null
  fintechScore: number | null
}

export const mapSuggestToOption = (data: ISuggestResult): ICompanyOption[] => {
  if (!data?.suggest?.hits?.hit) return []
  const raw = data.suggest.hits.hit.map(hit => ({
    ...hit,
    meta: (hit.fields.meta || []).map(m => JSON.parse(m)),
  }))
  return raw
    ?.filter(hit => hit.fields.fields?.includes('name'))
    ?.map(hit => {
      const meta = hit.meta.find(m => m['type'] === 'company_id')
      return {
        id: meta.company_id,
        name: hit.fields.value[0],
        logoUrl: meta.logo,
        url: meta.url,
        total: meta.total,
      }
    })
  //TODO: check later
  // .concat(
  //   ...raw
  //     ?.filter(hit => hit.fields.fields?.includes('company_alias'))
  //     ?.map(hit => {
  //       const meta = hit.meta.find(m => m['type'] === 'alias')
  //       return {
  //         id: meta.company_id,
  //         name: meta.value,
  //         logoUrl: meta.logo,
  //         url: meta.url,
  //       }
  //     })
  // )
}

export const SearchCompanies: React.FC<ISearchCompaniesProps> = props => {
  const { searchValue, setSearchValue, setLoading = () => {} } = props
  const { data: searchData, loading } = useQuery<ISuggestResult>(SUGGEST, {
    variables: {
      input: `q=${searchValue}&suggester=value`,
    },
    skip: !searchValue,
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  const options = useMemo(() => mapSuggestToOption(searchData as ISuggestResult), [searchData])
  useEffect(() => {
    props.handleData(options)
  }, [options])
  return (
    <FCTSearchInput
      search={searchValue}
      handleSearch={setSearchValue}
      resetSearch={() => setSearchValue('')}
      placeholder="Search company"
    />
  )
}

import { Theme, Typography, makeStyles } from '@material-ui/core'
import Flex from 'components/Flex/Flex'
import { InformationItemContainer } from 'components/InvestorProfile/Sidebar/InvestorInformation'
import { ETaxonomyCategory, TaxonomyFilterAvatar, ETaxonomyAvatar } from 'utils/FlattenEcoSystem'
import { BUSINESS_AND_DIMENSIONS } from '../consts'
import { getTaxonomyIcon } from '../helpers'
import clsx from 'clsx'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles((theme: Theme) => ({
  contentText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.3px',
    color: '#1E293B',
    wordBreak: 'break-word',
  },
  dimensionIcon: {
    width: '24px',
    height: '24px',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    marginRight: '8px',
  },
  nullColor: {
    color: '#94A3B8',
  },
}))

export const TaxonomySection = ({
  dimensionGroups,
  category,
}: {
  dimensionGroups: string[][]
  category: string
}) => {
  const classes = useStyles()

  const validGroups: string[][] =
    category === ETaxonomyCategory.RegTech
      ? dimensionGroups.slice(0, 1)
      : dimensionGroups.filter(item => item.length)

  return (
    <InformationItemContainer
      title={'Primary Taxonomy mapping'}
      icon={'stars'}
      widthXl={4}
      widthSm={5}
    >
      {validGroups.length === 0 && (
        <Typography className={clsx(classes.contentText, classes.nullColor)}>{'NA'}</Typography>
      )}
      {validGroups.map((dimensionGroup, index) => {
        const name = BUSINESS_AND_DIMENSIONS[category as ETaxonomyCategory][index + 1]
        const taxonomy = getTaxonomyIcon(name)

        if (dimensionGroup.length === 0) return null

        return (
          <Flex
            alignItems="center"
            key={index}
            style={{ marginBottom: index < validGroups.length - 1 ? 10 : 0 }}
          >
            <Avatar
              variant="circular"
              src={''}
              className={classes.dimensionIcon}
              children={taxonomy}
              style={{
                backgroundColor: TaxonomyFilterAvatar.get(taxonomy || ETaxonomyAvatar.BusinessLine)
                  ?.color,
              }}
            />
            <Typography className={classes.contentText}>{dimensionGroup.join(', ')}</Typography>
          </Flex>
        )
      })}
    </InformationItemContainer>
  )
}

import { NetworkStatus, useQuery, ApolloQueryResult, useApolloClient } from '@apollo/client'
import { omit } from 'lodash'
import React, { createContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { EWatchListType } from '../../components/WatchList/SelectTabWatchList'
import { useCompanyDealFilter } from '../../store/features/company-deal/CompanyDealHook'
import {
  useWatchListFilter,
  useWatchListId,
  useWatchListName,
  useWatchListType,
} from '../../store/features/watch-list/WatchListHook'
import { GET_MY_WATCH_LIST } from '../../store/operations/queries/GetMyWatchlist'
import { onError } from '../../utils/sentry'
import { parseFilterOptionWL } from '../../utils/WatchList'
import { GetMyWatchlist, GetMyWatchlist_getMyWatchlist } from '../../__generated__/GetMyWatchlist'

export const initGetWLContext: ICreateContextState = {
  dataWatchList: [],
  fnWatchList: async () => {},
  loading: true,
  refetchLoading: false,
}

export const GetWatchListContext = createContext<ICreateContextState>(initGetWLContext)

export const GetWatchListProvider = (props: { children: React.ReactNode }) => {
  const history = useHistory()
  const hasLogin = Boolean(Number(localStorage.getItem('login')))
  const client = useApolloClient()

  const [watchListId, setWatchListId] = useWatchListId()
  const [__, setWatchListName] = useWatchListName()
  const [___, setWatchListType] = useWatchListType()
  const [____, setWatchListFilter] = useWatchListFilter()
  const [_____, setCompanyDealFilter] = useCompanyDealFilter()
  const {
    data: myWatchlist,
    loading,
    refetch: fnWatchList,
    networkStatus,
  } = useQuery<GetMyWatchlist>(GET_MY_WATCH_LIST, {
    notifyOnNetworkStatusChange: true,
  })

  const dataWatchList = myWatchlist?.getMyWatchlist as GetMyWatchlist_getMyWatchlist[]
  const refetchLoading = networkStatus === NetworkStatus.refetch
  const currentPath = history.location.pathname

  const setDefault = (data: GetMyWatchlist_getMyWatchlist) => {
    setWatchListId(data?.watchlistId)
    setWatchListName(data?.watchlistName)
    if (data?.filterOption) {
      setWatchListType(EWatchListType.filterConditions)
      const newFilter = parseFilterOptionWL(data?.filterOption)
      setWatchListFilter({ ...newFilter })
      if (currentPath.includes('/watch-lists') || currentPath.includes('/investor-profile'))
        setCompanyDealFilter({ ...newFilter })
    } else {
      setWatchListType(EWatchListType.companyIDs)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.query<GetMyWatchlist>({
          query: GET_MY_WATCH_LIST,
        })

        const data = res?.data?.getMyWatchlist
        const defaultWL = data?.find(e => e.isDefault === true)
        const watchListUrl = data?.find(e => e.watchlistId === watchListId)

        if (hasLogin && !!defaultWL) {
          setDefault(defaultWL)
          localStorage.setItem('login', '0')
        }

        if (!!watchListUrl && !!watchListId) {
          setDefault(watchListUrl)
        }
      } catch (err) {
        onError(err)
      }
    }

    fetchData()
  }, [])

  return (
    <GetWatchListContext.Provider
      value={{
        dataWatchList,
        fnWatchList: async () => {
          await fnWatchList()
        },
        loading,
        refetchLoading,
      }}
    >
      {props.children}
    </GetWatchListContext.Provider>
  )
}

interface ICreateContextState {
  dataWatchList: GetMyWatchlist_getMyWatchlist[]
  fnWatchList: () => Promise<void>
  loading: boolean
  refetchLoading: boolean
}

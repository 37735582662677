import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import mixpanel from '../../../mixpanel'
import MuiAccordionDetails from '@material-ui/core//AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps } from '@material-ui/core//AccordionSummary'
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion'
import Icon from 'components/Icon/Icon'
import { IKnowledgeHubCategory, useKnowledgeHubContext } from 'contexts/KnowledgeHubContext'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import KnowledgeHubLink from '../shared/KnowledgeHubLink'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperAccordion: {
      padding: '40px 10% 120px 0',
    },
    topicItem: {
      paddingBottom: 8,
    },
    linkItem: {
      color: theme.palette.grey[600],
      lineHeight: '22px',
      textDecoration: 'none',
      fontSize: 14,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    categoryLink: {
      color: theme.palette.grey[600],
      textDecoration: 'none',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    activeLink: {
      color: theme.palette.primary.main,
    },
  })
)

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  margin: '0px !important',
  border: 'none !important',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  transition: 'all 0.5s ease 0.1s',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
  },
  margin: '0px !important',
  padding: '6px 0px !important',
  minHeight: 'unset !important',
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  display: 'block',
}))

interface IKnowledgeHubSidebarProps {
  categories: IKnowledgeHubCategory[]
  slug: string[]
}

const KnowledgeHubSidebar = ({ categories, slug }: IKnowledgeHubSidebarProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const [expanded, setExpanded] = React.useState<string[]>(slug || [])

  const handleChange = (panel: string, category: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    mixpanel.track('KnowledgeHub_Top Categories', { category })
    setExpanded(prev => (prev.includes(panel) ? prev.filter(i => i !== panel) : [...prev, panel]))
  }

  return (
    <div className={classes.wrapperAccordion}>
      {categories.map((e, index) => {
        const id = e.Slug
        const isExpanded = expanded.includes(id)

        return (
          <Accordion expanded={isExpanded} onChange={handleChange(id, e.Name)} key={index}>
            <AccordionSummary
              id={`${id}bh-header`}
              aria-controls={`${id}bh-content`}
              expandIcon={
                <Icon name="arrowdown" color={theme.palette.grey[400]} height="10" width="10" />
              }
            >
              <NavLink
                className={classes.categoryLink}
                activeClassName={classes.activeLink}
                to={`/knowledge-hub/cate/${e.Slug}`}
                isActive={match => match?.isExact || isExpanded}
              >
                {e.Name}
              </NavLink>
            </AccordionSummary>
            <AccordionDetails>
              {(e.topicsObj || []).map(i => (
                <div className={classes.topicItem} key={i.Slug}>
                  <KnowledgeHubLink
                    type="topic"
                    name={i.Name}
                    slug={i.Slug}
                    categoryName={e.Name}
                  />
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

export default KnowledgeHubSidebar

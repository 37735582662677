import { InMemoryCache } from '@apollo/client'
import { taxonomiesBublePolicies } from './TaxonomiesBubble'
import { commonPolicies } from './Common'
import { companiesListPolicies } from './CompaniesList'
import { loadingPolicies } from './Loading'
import { defaultDataIdFromObject } from 'apollo-cache-inmemory/lib/inMemoryCache'
import { currentTabPolicies } from './CompanyDealFilterPolicies'
import { dealsListPolicies } from './DealsList'
import { isAuthenticatedPolicies } from './Authenticated'
import { profileFilterPolicies } from './ProfileFilterPolicies'

var hash = (obj: object) => btoa(unescape(encodeURIComponent(JSON.stringify(obj))))

const cache = new InMemoryCache({
  dataIdFromObject: (object: any, context: any) => {
    switch (object.__typename) {
      case 'CountByGeoChartItem':
        return `CountByGeoChartItem_${hash(object)}` as any
      case 'ToolTipInfo':
        return `ToolTipInfo_${hash(object)}` as any
      default:
        return defaultDataIdFromObject(object) // fall back to default handling
    }
  },

  typePolicies: {
    Query: {
      fields: {
        ...commonPolicies,
        ...taxonomiesBublePolicies,
        ...companiesListPolicies,
        ...dealsListPolicies,
        ...loadingPolicies,
        ...currentTabPolicies,
        ...isAuthenticatedPolicies,
        ...profileFilterPolicies,
      },
    },
    AtlasViewDataDetail: {
      keyFields: ['id', 'parentId', 'businessLineId', 'name', 'size'],
    },
    MapViewGroup: {
      keyFields: false,
    },
    MapViewQueryResult: {
      keyFields: false,
    },
    MapViewCompanyInfo: {
      keyFields: false,
    },
    ToolTipInfo: {
      keyFields: false,
    },
    ChartGroupInfo: {
      keyFields: false,
    },
    DashboardLastestNewsItem: {
      keyFields: false,
    },
    // CountByFoundingYearChart
    CountByFoundingYearGroupItem: {
      keyFields: false,
    },
    CountByFoundingYearChartData: {
      keyFields: false,
    },
    CountByFoundingYearToolTipInfo: {
      keyFields: false,
    },
    CountByFoundingYearChartResult: {
      keyFields: false,
    },
    // CountByTopCountryChart
    CountryChartToolTipInfo: {
      keyFields: false,
    },
    CountByTopCountryGroupItem: {
      keyFields: false,
    },
    CountByTopCountryChartData: {
      keyFields: false,
    },
    CountByTopCountryChartResult: {
      keyFields: false,
    },
    // CountByFemaleFounderChart
    CountByFemaleFounderChartStack: {
      keyFields: false,
    },
    CountByFemaleFounderChartItem: {
      keyFields: false,
    },
    CountByFemaleFounderChartResult: {
      keyFields: false,
    },
    CountByFemaleFounderToolTipInfo: {
      keyFields: false,
    },

    // EFByFundingAmountChart
    EquityFundingByFundingAmountChartResult: {
      keyFields: false,
    },
    EquityFundingByFundingAmountChartItem: {
      keyFields: false,
    },
    EquityFundingByFundingAmountChartStack: {
      keyFields: false,
    },
    EquityFundingByFundingAmountChartToolTipInfo: {
      keyFields: false,
    },
    ChartToolTipCompanyInfo: {
      keyFields: false,
    },
    //EFByMaturityCountChart
    EquityFundingByFundingMaturityChartResult: {
      keyFields: false,
    },
    EquityFundingByFundingMaturityChartItem: {
      keyFields: false,
    },
    EquityFundingByFundingMaturityChartStack: {
      keyFields: false,
    },
    EquityFundingByFundingMaturityChartToolTipInfo: {
      keyFields: false,
    },
    //EFByGeographyChart
    EquityFundingByGeographyChartResult: {
      keyFields: false,
    },
    EquityFundingByGeographyData: {
      keyFields: false,
    },
    EquityFundingByGeographyStack: {
      keyFields: false,
    },
    TopCountryInfo: {
      keyFields: false,
    },
    EquityFundingByGeographyChartGroupInfo: {
      keyFields: false,
    },
    EquityFundingByGeographyToolTipInfo: {
      keyFields: false,
    },
    //EFByInvestorTypeChart
    EquityFundingByInvestorTypeChartResult: {
      keyFields: false,
    },
    EquityFundingByInvestorTypeChartItem: {
      keyFields: false,
    },
    EquityFundingByInvestorTypeChartStack: {
      keyFields: false,
    },
    //EFByFemaleFounderChart
    EquityFundingByFemaleFounderChartStack: {
      keyFields: false,
    },
    EquityFundingByFemaleFounderChartItem: {
      keyFields: false,
    },
    EquityFundingByFemaleFounderChartResult: {
      keyFields: false,
    },
    // MaByAcquirerTypeChart
    MaByAcquirerTypeChartResult: {
      keyFields: false,
    },
    MaByAcquirerTypeData: {
      keyFields: false,
    },
    MaByAcquirerTypeStack: {
      keyFields: false,
    },
    MaAcquirerTypeToolTipInfo: {
      keyFields: false,
    },
    //MaByProductLineChart
    MaByProductLineChartResult: {
      keyFields: false,
    },
    MaByProductLineData: {
      keyFields: false,
    },
    MaByProductLineStack: {
      keyFields: false,
    },
    MaProductLineToolTipInfo: {
      keyFields: false,
    },
    //MaByGeographyChart
    MaByGeographyChartResult: {
      keyFields: false,
    },
    MaByGeographyData: {
      keyFields: false,
    },
    MaByGeographyStack: {
      keyFields: false,
    },
    MaByGeographyChartGroupInfo: {
      keyFields: false,
    },
    MaGeographyToolTipInfo: {
      keyFields: false,
    },
    //EFByCAGRChart
    EquityFundingByCagrChartResult: {
      keyFields: false,
    },
    EquityFundingByCagrData: {
      keyFields: false,
    },
    EquityFundingByCagrToolTipInfo: {
      keyFields: false,
    },
    //DealListView
    DealListViewQueryResult: {
      keyFields: false,
    },
    DealListViewItem: {
      keyFields: false,
    },
    FundingInvestor: {
      keyFields: false,
    },
    CompanyPeople: {
      keyFields: false,
    },
    DimensionDetail: {
      keyFields: false,
    },
    IntIdFilterItem: {
      keyFields: ['id', 'name'],
    },
    StringIdFilterItem: {
      keyFields: false,
    },
    //Profile
    StringIdDescFilterItem: {
      keyFields: false,
    },
    //Heatmap
    FundingHeatMapResult: {
      keyFields: false,
    },
    FundingHeatMapItem: {
      keyFields: false,
    },
    //DashboardLastestInvestments
    DashboardLastestInvestmentItem: {
      keyFields: false,
    },
    //IposByGeographyChart
    IposByGeographyChartResult: {
      keyFields: false,
    },
    IposByGeographyData: {
      keyFields: false,
    },
    IposByGeographyStack: {
      keyFields: false,
    },
    IposByGeographyChartGroupInfo: {
      keyFields: false,
    },
    IposGeographyToolTipInfo: {
      keyFields: false,
    },
    //MaByProductLineChart
    IposByProductLineChartResult: {
      keyFields: false,
    },
    IposByProductLineData: {
      keyFields: false,
    },
    IposByProductLineStack: {
      keyFields: false,
    },
    IposProductLineToolTipInfo: {
      keyFields: false,
    },
    CompanyKeywordItem: {
      keyFields: false,
    },
  },
}) as any

export default cache

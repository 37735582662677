import { makeStyles } from '@material-ui/styles'
import { LIMITED_COMPANIES_STYLE_TOOLTIP } from '../core/constants/Chart'
import theme from '../theme'
import { formatAbbreviation, numberFormat } from '../utils/convert/number'
import FCTIcon from './Icon/Icon'
import clsx from 'clsx'
import {
  Avatar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  TableCell,
} from '@material-ui/core'
import { PeriodType, renameLogo } from '../utils/convert/Title'
import FCTTooltip from './Tooltip/Tooltip'
import { StyledTableRow } from './DashboardComponent/LatestInvestmentsTable'
import { GetAtlasViewData_getAtlasViewData_latestDeals } from '../__generated__/GetAtlasViewData'
import { EChartPosition } from '../containers/DashboardChartContainer'
import EFByFundingAmountChart from './EFByFundingAmountChart'
import { IAnalysisFilter } from '../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../__generated__/GetDealFilterDataResult'
import { GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData } from '../__generated__/GetEquityFundingByFundingAmount'
import Loading from './Loading'
import Moment from 'react-moment'
import { useMemo } from 'react'
import { GetCompanyList_getCompanyList_items } from '../__generated__/GetCompanyList'
import { CompanyTooltip } from './Tooltip/CompanyTooltip'
import { sum } from 'lodash'
import { DESCRIPTIONS_DATA_MISSING } from './Tabs/FCTTab'
import { GlobalFilterViewBy } from '__generated__/globalTypes'

const useStyles = makeStyles(() => ({
  revampTooltip: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '60vh',
    maxWidth: 450,
    minHeight: 400,
    maxHeight: 'calc(100vh - 300px)',
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.grey[400]}`,
    boxSizing: 'border-box',
    boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    borderRadius: 3,
    overflow: 'auto',
    zIndex: 10,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    position: 'sticky',
    background: '#fff',
    left: 0,
    zIndex: 20,
  },
  tipHeader: {
    borderBottom: '1px solid #e7ecf3',
    padding: '10px 14px',
    fontSize: 14,
    lineHeight: '22px',
    display: 'flex',
    position: 'sticky',
    background: '#fff',
    left: 0,
    top: 0,
    zIndex: 25,
  },
  tipHeaderText: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: 600,
    flex: 1,
  },
  viewBy: {
    paddingTop: 12,
    fontSize: 12,
    lineHeight: '18px',
    color: '#1da462',
    fontWeight: 400,
  },
  tipHeaderIconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 10,
  },
  navigateTo: {
    fontSize: 10,
    textAlign: 'center',
    marginLeft: 8,
  },
  tipHeaderIcon: {
    width: 40,
    height: 40,
    backgroundColor: '#f6faf8',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ' #eff1f0',
    },
  },
  tipInfo: {
    borderBottom: '1px solid #e7ecf3',
    padding: ' 10px 14px',
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    display: 'flex',
  },
  tipInfoItem: {
    flex: 1,
    whiteSpace: 'nowrap',
    '& span': {
      color: '#1da462',
      fontWeight: 600,
    },
  },
  tipBody: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    padding: '10px 14px',
    minHeight: 18,
    borderBottom: '1px solid #e7ecf3',
  },
  tipTitleTop: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '18px',
    padding: '10px 14px',
  },
  tipTopCompany: {
    padding: '0 14px 10px',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
  },
  tipTopCompanyStyle: {
    justifyContent: 'space-between',
  },
  tipCompany: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
    alignItems: 'center',
    width: '15%',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  logoImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #e2e8f0',
    width: 62,
    height: 62,
    borderRadius: 3,
    cursor: 'pointer',
  },
  tipLogo: {
    height: '100%',
    width: '100%',
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    boxSizing: 'border-box',
    borderRadius: 3,
  },
  tipCompanyText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    color: '#1e293b',
    padding: '10px 0',
    textAlign: 'center',
    wordWrap: 'normal',
    maxWidth: '80px',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
  },
  content: {
    whiteSpace: 'normal',
    fontWeight: 600,
    fontSize: 14,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1,
  },
  investorText: {
    width: '90px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    width: 24,
    height: 24,
    fontSize: 10,
  },
  linkTo: {
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.grey[800],
    alignItems: 'center',
    cursor: 'pointer',
  },
  linkShow: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '4px 10px',
    margin: '10px auto',
    borderRadius: 3,
    width: '100%',
    boxSizing: 'border-box',
  },
  showText: {
    fontSize: 12,
    lineHeight: '22px',
    color: '#1DA462',
    fontWeight: 'normal',
    paddingRight: '10px',
  },
  border: {
    width: '100%',
    height: 1,
    background: '#E7ECF3',
  },
  container: {
    boxSizing: 'border-box',
    overflow: 'auto',
    minWidth: 440,
  },
  containerTable: {
    overflow: 'hidden',
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: 200,
  },
  emptyText: {
    marginTop: 16,
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.grey['500'],
  },
  chartStyle: {
    height: 350,
    padding: '0 10px',
    boxSizing: 'border-box',
    width: '100%',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: 200,
  },
  iconEmpty: {
    padding: 24,
    borderRadius: '50%',
    background: theme.palette.grey['100'],
    display: 'flex',
  },
}))

const StyledTableCell = withStyles(() => ({
  root: {
    padding: '9px 10px',
  },
  head: {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #E7ECF3',
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    fontWeight: 600,
    whiteSpace: 'nowrap',
    maxWidth: 400,
  },
  body: {
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    border: 0,
    whiteSpace: 'nowrap',
  },
}))(TableCell)

export const RevampTooltip = (props: RevampTooltipProps) => {
  const classes = useStyles()
  const {
    data,
    d,
    companyData,
    companyLoading,
    loading,
    chartData,
    chartLoading,
    isPin,
    setIsPin,
    onclickNavigate,
    navigateProfile,
    navigateListView,
    navigateAnalysis,
    filter,
    filterData,
  } = props

  const getViewBy = (viewBy: any, data: any) => {
    if (viewBy == GlobalFilterViewBy.operatingModel) {
      return 'Operating Model'
    }

    if (viewBy == GlobalFilterViewBy.valuechain) {
      return 'Value Chain'
    }

    if (viewBy == GlobalFilterViewBy.business && data?.depth == 1) {
      return 'Business Line'
    }

    return 'Product'
  }

  const emptyChart = useMemo(() => {
    if (!chartData?.chartData?.length) return 0
    return sum([...chartData.chartData.slice(7)]?.map(e => e.total || 0))
  }, [chartData])

  const missingTooltip = useMemo(() => {
    return DESCRIPTIONS_DATA_MISSING.find(e => e.id === d?.data.id)?.text || ''
  }, [d])

  return (
    <div className={clsx(classes.revampTooltip, classes.scroll)}>
      <div className={classes.tipHeader}>
        <div className={classes.tipHeaderText}>
          <div className={classes.viewBy}>{getViewBy(data?.viewBy, d)}</div>
          {d?.data.name}
        </div>
        {isPin && (
          <div className={classes.tipHeaderIconWrapper}>
            <div className={classes.tipHeaderIcon} onClick={() => setIsPin(false)}>
              <FCTIcon
                className={classes.icon}
                name="pinIcon"
                width={16}
                height={16}
                color={theme.palette.primary.light}
              />
            </div>
            <div className={classes.navigateTo}>Unpin</div>
          </div>
        )}
        <div className={classes.tipHeaderIconWrapper}>
          <div
            className={classes.tipHeaderIcon}
            onClick={() => onclickNavigate(d.data.id, d.data.businessLineId, 'landscape')}
          >
            <FCTIcon
              className={classes.icon}
              name="dashboard"
              width={16}
              height={16}
              color={theme.palette.primary.light}
            />
          </div>
          <div className={classes.navigateTo}>Landscape</div>
        </div>
        <div className={classes.tipHeaderIconWrapper}>
          <div
            className={classes.tipHeaderIcon}
            onClick={() => onclickNavigate(d.data.id, d.data.businessLineId, 'charts')}
          >
            <FCTIcon
              className={classes.icon}
              name="chartcompare"
              width={16}
              height={16}
              color={theme.palette.primary.light}
            />
          </div>
          <div className={classes.navigateTo}>Analytics</div>
        </div>
        <div className={classes.tipHeaderIconWrapper}>
          <div
            className={classes.tipHeaderIcon}
            onClick={() => onclickNavigate(d.data.id, d.data.businessLineId, 'list-view')}
          >
            <FCTIcon
              className={classes.icon}
              name="list"
              width={16}
              height={16}
              color={theme.palette.primary.light}
            />
          </div>
          <div className={classes.navigateTo}>List view</div>
        </div>
      </div>
      <div className={classes.header}>
        <div className={classes.tipInfo}>
          <div className={classes.tipInfoItem}>
            Companies: <span>{d?.value ? numberFormat(d?.value) : 0}</span>
          </div>
          {d?.data.totalFundings ? (
            <div className={classes.tipInfoItem} style={{ textAlign: 'right' }}>
              Equity Funding:
              <span> $ {formatAbbreviation(d?.data.totalFundings)}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        {d?.data.description ? (
          <div className={classes.tipBody}>{d?.data.description || ''}</div>
        ) : (
          <>{missingTooltip && <div className={classes.tipBody}>{missingTooltip}</div>}</>
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.tipTitleTop}>Top Companies</div>
        {loading ? (
          <div className={classes.loading}>
            <Loading />
          </div>
        ) : (
          <>
            {!!d?.data.companies.length && (
              <div
                className={clsx(
                  classes.tipTopCompany,
                  d?.data.companies.length >= LIMITED_COMPANIES_STYLE_TOOLTIP &&
                    classes.tipTopCompanyStyle
                )}
              >
                {d?.data.companies.slice(0, 5)?.map((item: any, index: number) => {
                  const info = companyData?.find(e => e.id == item.company_id)
                  return (
                    <CompanyTooltip
                      logoUrl={info?.logoBucketUrl || ''}
                      name={info?.name || ''}
                      foundedYear={info?.foundedYear || ''}
                      fundingAmount={info?.totalFundingAmount || 0}
                      country={info?.country || ''}
                      status={info?.status || ''}
                      description={info?.description || ''}
                      loading={companyLoading}
                      key={index}
                      fctScoreOverall={info?.fct_score_overall || 0}
                    >
                      <div className={classes.tipCompany}>
                        <div
                          className={classes.logoImg}
                          onClick={() => navigateProfile(item.company_id)}
                        >
                          <Avatar
                            alt={item.logo_url || ''}
                            variant="square"
                            src={item.logo_url || ''}
                            className={classes.tipLogo}
                            children={renameLogo(item.name || '')}
                          />
                        </div>
                        <span className={classes.tipCompanyText}>{item.name || ''}</span>
                      </div>
                    </CompanyTooltip>
                  )
                })}
              </div>
            )}
          </>
        )}
        <div className={classes.border}></div>
        <div className={classes.tipTitleTop}>Latest Investments & Acquisitions</div>
        {loading ? (
          <div className={classes.loading}>
            <Loading />
          </div>
        ) : (
          <>
            {!loading && !!d?.data.latestDeals.length ? (
              <TableContainer className={classes.containerTable}>
                <Table className={classes.table}>
                  <TableHead className={classes.stickyHeader}>
                    <TableRow>
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="left">Amount</StyledTableCell>
                      <StyledTableCell align="left">Round</StyledTableCell>
                      <StyledTableCell align="left">Investor</StyledTableCell>
                      <StyledTableCell align="left">Date</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {d?.data.latestDeals?.map(
                      (item: GetAtlasViewData_getAtlasViewData_latestDeals, index: number) => {
                        const info = companyData?.find(e => e.id == item.company_id)
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="left">
                              <CompanyTooltip
                                logoUrl={info?.logoBucketUrl || ''}
                                name={info?.name || ''}
                                foundedYear={info?.foundedYear || ''}
                                fundingAmount={info?.totalFundingAmount || 0}
                                country={info?.country || ''}
                                status={info?.status || ''}
                                description={info?.description || ''}
                                loading={companyLoading}
                                key={index}
                                fctScoreOverall={info?.fct_score_overall || 0}
                              >
                                <div
                                  className={classes.linkTo}
                                  onClick={() => props.navigateProfile(item.company_id)}
                                >
                                  <div className={classes.logoWrapper}>
                                    <Avatar
                                      alt={item.logo_url || ''}
                                      variant="square"
                                      src={item.logo_url || ''}
                                      className={classes.avatar}
                                      children={renameLogo(info?.name || '')}
                                    />
                                  </div>
                                </div>
                              </CompanyTooltip>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.amount
                                ? item.amount === 0
                                  ? 'Undisclosed '
                                  : '$ ' + formatAbbreviation(item.amount || 0)
                                : 'Undisclosed'}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <FCTTooltip
                                title={
                                  <Typography style={{ fontSize: 12 }}>{item?.round}</Typography>
                                }
                                arrow
                                placement="top"
                                disableHoverListener={(item?.round?.length || 0) < 10}
                              >
                                <div className={classes.investorText}>{item?.round}</div>
                              </FCTTooltip>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item?.investor_names ? (
                                <FCTTooltip
                                  title={
                                    <Typography style={{ fontSize: 12 }}>
                                      {item?.investor_names}
                                    </Typography>
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <div className={classes.investorText}>{item?.investor_names}</div>
                                </FCTTooltip>
                              ) : (
                                'Undisclosed'
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Moment format="MMM D, YYYY">{item?.deal_date || ''}</Moment>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      }
                    )}
                  </TableBody>
                </Table>
                <div className={classes.linkShow} onClick={navigateListView}>
                  <Typography className={classes.showText}>Show more</Typography>
                  <FCTIcon
                    name="arrowright"
                    width={8}
                    height={8}
                    color={`${theme.palette.grey[500]}`}
                  />
                </div>
              </TableContainer>
            ) : (
              <div className={classes.empty}>
                <div className={classes.iconEmpty}>
                  <FCTIcon name="money" width={32} height={32} color="#94A3B8" />
                </div>
                <div className={classes.emptyText}>
                  No data available based on your selection.
                  <br />
                  Try changing the filter conditions.
                </div>
              </div>
            )}
          </>
        )}
        {isPin && (
          <>
            <div className={classes.border}></div>
            <div className={classes.tipTitleTop}>Funding Amount</div>
            {chartLoading ? (
              <div className={classes.loading}>
                <Loading />
              </div>
            ) : (
              <>
                {!!emptyChart && !!chartData ? (
                  <>
                    <div className={classes.chartStyle}>
                      <EFByFundingAmountChart
                        data={chartData}
                        periodType={PeriodType.Annually}
                        type={EChartPosition.Sunburst}
                        filter={filter}
                        filterData={filterData}
                        viewBy={filter.viewBy}
                      ></EFByFundingAmountChart>
                    </div>
                    <div
                      className={classes.linkShow}
                      style={{ marginTop: 0 }}
                      onClick={navigateAnalysis}
                    >
                      <Typography className={classes.showText}>
                        Click to view more details
                      </Typography>
                      <FCTIcon
                        name="arrowright"
                        width={8}
                        height={8}
                        color={`${theme.palette.grey[500]}`}
                      />
                    </div>
                  </>
                ) : (
                  <div className={classes.empty}>
                    <div className={classes.iconEmpty}>
                      <FCTIcon name="chartcompare" width={32} height={32} color="#94A3B8" />
                    </div>
                    <div className={classes.emptyText}>
                      No data available based on your selection.
                      <br />
                      Try changing the filter conditions.
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

interface RevampTooltipProps {
  data: any
  d: any
  companyData: GetCompanyList_getCompanyList_items[]
  companyLoading: boolean
  loading: boolean
  onclickNavigate: (id: number, businessId: number, url: string) => void
  navigateProfile: (id: number | null) => void
  isPin: boolean
  setIsPin: (v: boolean) => void
  navigateListView: () => void
  navigateAnalysis: () => void
  filter: IAnalysisFilter
  filterData: GetDealFilterDataResult_getDealFilterData
  chartData:
    | GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData
    | null
    | undefined
  chartLoading: boolean
}

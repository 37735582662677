import { makeStyles, withStyles } from '@material-ui/core/styles'
import React, { useCallback, useMemo } from 'react'
import CustomSliderSingle from 'components/CustomSliderSingle/CustomSliderSingle'
import { MARKS_SMART_CLUSTER_DISTANCE } from 'utils/consts'
import { AccordionItem } from '../Filters/AccordionItem'
import { IOpenOptionFilter } from '../../store/features/filter/FilterSlice'
import { initSmartClusterDistance } from 'store/features/company-deal/CompanyDealSlice'
import { useChipsHeader } from 'store/features/analysis/AnalysisHook'
import FCTDropdownListItem from 'components/DropdownListItem/FCTDropdownListItem'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { getSliderText } from 'utils/slider'
const useStyles = makeStyles(theme => ({
  flex: {
    flex: 1,
  },
  disabled: {
    pointerEvents: 'none',
  },
  container: {
    borderTop: '1px solid #E7ECF3',
  },
  sliderText: {
    fontSize: 12,
    color: theme.palette.grey[700],
    fontWeight: 400,
    marginLeft: 10,
    marginTop: -5,
  },
}))

function SmartClusterItem(props: Props) {
  const { openOption, setOpenOption } = props

  const sliderText = useMemo(() => {
    return getSliderText(props.filter.smartCluster?.distance)
  }, [props.filter.smartCluster?.distance])

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <AccordionItem
        title="Smart Cluster"
        open={openOption?.investor}
        setOpen={value => setOpenOption && setOpenOption(value, 'smartCluster')}
      >
        <FCTDropdownListItem
          isOpen={true}
          title="Distance"
          size="small"
          openedIcon="down"
          closedIcon="up"
        >
          <CustomSliderSingle
            dataMarks={MARKS_SMART_CLUSTER_DISTANCE}
            step={0.1}
            onChangeValue={(value: string | number) => {
              props.setFilter({
                smartCluster: {
                  ...props.filter.smartCluster,
                  distance: value,
                },
              })
            }}
            defaultRealValue={props.filter.smartCluster?.distance || initSmartClusterDistance}
          />
          <div className={classes.sliderText}>{sliderText}</div>
        </FCTDropdownListItem>
      </AccordionItem>
    </div>
  )
}

export default SmartClusterItem

interface Props {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  openOption?: IOpenOptionFilter
  setOpenOption?: (value: boolean, tag: string) => void
  customStyle?: { title?: React.CSSProperties }
}

import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useInvestorProfileContext } from 'contexts/InvestorProfileContext'
import React from 'react'
import Moment from 'react-moment'
import theme from '../../../theme'
import { numberWithCommas } from '../../../utils/convert/number'
import FCTIcon, { IconMap } from '../../Icon/Icon'
import { GroupTitle } from '../../Profile/Sidebar/shared/GroupTitle'
import FCTTooltip from '../../Tooltip/Tooltip'
import { GetInvestorProfile_getInvestorProfileData } from './../../../__generated__/GetInvestorProfile'
import { FCTTooltipMove } from './../../Tooltip/TooltipMove'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupStyle: {
      marginBottom: 18,
      alignItems: 'start',
    },
    titleStyle: {
      display: 'flex',
      maxHeight: 40,
      flexWrap: 'nowrap',
      flex: 1,
      alignItems: 'center',
    },
    titleText: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '22px',
      marginLeft: 12,
      letterSpacing: '0.3px',
      color: '#1E293B',
    },
    contentText: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      color: '#1E293B',
      wordBreak: 'break-word',
    },
    linkColor: {
      color: theme.palette.primary.light,
    },
    nullColor: {
      color: '#94A3B8',
    },
    rootText: {
      width: '100%',
      padding: 0,
    },
    titleSocials: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      color: theme.palette.grey[600],
      padding: '12px 0',
    },
    aliasesGroup: {
      display: 'flex',
      marginTop: 12,
      alignItems: 'center',
    },
    aliasesInput: {
      paddingRight: 6,
      flex: 1,
    },
    iconTrash: {
      padding: 8,
      borderRadius: 3,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
    },
    buttonText: {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 'normal',
      marginLeft: 8,
      minWidth: 'auto',
      padding: '8px 14px',
    },
    dialogContent: {
      padding: 20,
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '22px',
      color: theme.palette.grey[800],
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    footnoteTick: {
      verticalAlign: 'super',
      fontSize: 11,
      fontWeight: 700,
      lineHeight: 0,
    },
  })
)

interface IInvestorInformationProps {
  data: GetInvestorProfile_getInvestorProfileData | undefined | null
}

interface IInformationItemProps {
  title: string
  icon: keyof typeof IconMap
  data: Array<string | null>
  isCommas?: boolean
  tooltipText?: string
  hasTooltipInfo?: boolean
  isDate?: boolean
  footnote?: string
  dataTestid?: string
  widthXl?: number
  widthSm?: number
}

export const InformationItemContainer = (props: any) => {
  const { tooltipText, widthXl, widthSm } = props
  const classes = useStyles()
  const width = 7
  return (
    <Grid container direction="row" className={classes.groupStyle}>
      <Grid
        container
        item
        sm={widthSm ? widthSm : width}
        md={widthSm ? widthSm : width}
        lg={widthSm ? widthSm : width}
        xl={widthXl ? widthXl : width}
        alignItems="center"
      >
        <div className={classes.titleStyle}>
          <FCTIcon name={props.icon} width="14px" height="14px" color={theme.palette.grey[400]} />
          <Typography className={classes.titleText}>
            {props.title} <span className={classes.footnoteTick}> {props.footnote}</span>
          </Typography>
        </div>
      </Grid>
      <Grid item sm md lg xl>
        <div className={clsx(tooltipText && classes.content)}>
          {props.children}
          <FCTTooltip
            title={<Typography style={{ fontSize: 12 }}>{tooltipText}</Typography>}
            arrow
            placement="top"
          >
            {!!tooltipText ? (
              <div>
                <FCTIcon
                  name="info"
                  width={12}
                  height={12}
                  color={theme.palette.grey[400]}
                  style={{ margin: '0 8px' }}
                />
              </div>
            ) : (
              <></>
            )}
          </FCTTooltip>
        </div>
      </Grid>
      {props.childrenNote && (
        <Grid item sm md lg xl>
          {props.childrenNote}
        </Grid>
      )}
    </Grid>
  )
}

export const InformationItem = (props: IInformationItemProps) => {
  const classes = useStyles()
  const {
    data,
    isCommas,
    tooltipText,
    hasTooltipInfo,
    isDate,
    dataTestid,
    widthXl,
    widthSm,
  } = props

  const renderContent = (value: string, index: number) => {
    return (
      <div className={classes.content} key={index}>
        <Typography className={classes.contentText} data-testid={dataTestid}>
          {isCommas ? (
            numberWithCommas(value)
          ) : isDate ? (
            <Moment format="D MMM YYYY" utc={true}>
              {value}
            </Moment>
          ) : (
            (hasTooltipInfo ? value.split(';')?.[0] : value) || ''
          )}
        </Typography>
        {!!hasTooltipInfo && (
          <FCTTooltip
            title={<Typography style={{ fontSize: 12 }}>{value.split(';')?.[1] || ''}</Typography>}
            arrow
            placement="top"
          >
            <div className={classes.content}>
              <FCTIcon
                name="info"
                width={12}
                height={12}
                color={theme.palette.grey[400]}
                style={{ margin: '0px 6px' }}
              />
            </div>
          </FCTTooltip>
        )}
      </div>
    )
  }

  return (
    <InformationItemContainer
      title={props.title}
      icon={props.icon}
      tooltipText={tooltipText}
      footnote={props.footnote}
      widthXl={widthXl}
      widthSm={widthSm}
    >
      {(data || []).map((value, index) => {
        if (index > 2) {
          return null
        }

        if (index === 2) {
          return (
            <FCTTooltipMove
              key={index}
              type="light"
              isHover={true}
              isMove={false}
              text={
                <div style={{ minWidth: 300, display: 'flex', flexDirection: 'column' }}>
                  {(data || []).map((e, i) => renderContent(e || '', i))}
                </div>
              }
            >
              <Typography
                className={classes.contentText}
                style={{ color: theme.palette.primary.light }}
              >
                View all
              </Typography>
            </FCTTooltipMove>
          )
        }

        return value ? (
          renderContent(value, index)
        ) : (
          <Typography key={index} className={clsx(classes.contentText, classes.nullColor)}>
            N/A
          </Typography>
        )
      })}
    </InformationItemContainer>
  )
}

export const InvestorInformation = (props: IInvestorInformationProps) => {
  const { data } = props
  const { isNotEmpty } = useInvestorProfileContext()

  const isNumber = (value: any) => {
    return typeof value === 'number'
  }

  return (
    <>
      <div style={{ marginTop: -7 }}>
        <GroupTitle name="INVESTMENT FOCUS" />
      </div>
      <Grid item container direction="column" xs={12}>
        <InformationItem
          title="No. of Equity Funding Deals"
          data={
            isNumber(data?.countEquityFundingDeals) ? [`${data?.countEquityFundingDeals}`] : [null]
          }
          icon="funding"
          isCommas={isNumber(data?.countEquityFundingDeals)}
          footnote={isNotEmpty ? '1' : ''}
          dataTestid="investor-profile-equity-funding-deals"
        ></InformationItem>
        <InformationItem
          title="No. of Acquisition Deals"
          data={isNumber(data?.countAcquisitionDeals) ? [`${data?.countAcquisitionDeals}`] : [null]}
          icon="handshake"
          isCommas={isNumber(data?.countAcquisitionDeals)}
          footnote={isNotEmpty ? '1' : ''}
          dataTestid="investor-profile-acquisition-deals"
        ></InformationItem>
        <InformationItem
          title="Top Product Line"
          data={
            data?.topProductLines
              ? (data?.topProductLines?.map(
                  e => `${e.dimension_name};${e.dimension_desc}`
                ) as string[])
              : [null]
          }
          hasTooltipInfo={true}
          icon="stars"
          dataTestid="investor-profile-product-line"
        ></InformationItem>
        <InformationItem
          title="Top Deal Type"
          data={data?.topDealTypes || [null]}
          icon="stars"
          dataTestid="investor-profile-top-deal"
        ></InformationItem>
        <InformationItem
          title="Latest Deal Type"
          data={data?.latestDealTypes || [null]}
          icon="dashboard"
          dataTestid="investor-profile-latest-deal-type"
        ></InformationItem>
        <InformationItem
          title="Latest Deal Date"
          data={data?.latestDealDate ? [data?.latestDealDate] : [null]}
          icon="calendar"
          isDate={!!data?.latestDealDate}
          dataTestid="investor-profile-latest-deal-date"
        ></InformationItem>
        <InformationItem
          title="Top Region"
          data={data?.topRegions || [null]}
          icon="globe"
          dataTestid="investor-profile-top-region"
        ></InformationItem>
      </Grid>
    </>
  )
}

import { Popover, PopoverProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import theme from '../../theme'
import FCTButton from '../Button/FCTButton'
import FCTIcon from '../Icon/Icon'

const useStyles = makeStyles({
  popover: {
    transform: 'translateY(2px)',
  },
  paper: {
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.06)',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  buttonMore: {
    width: '100%',
    height: 40,
    padding: '8px 14px',
    border: '1px solid #E7ECF3',
    fontSize: 14,
    fontWeight: 'normal',
    background: '#FFFFFF',
    minWidth: 190,
  },
})

type Props = {
  Content: React.ReactElement
  label?: string
  Trigger?: React.FunctionComponent<{ open: boolean }>
  fullWidth?: boolean
  popoverProps?: Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'>
}

export default function FCTPopover({
  Content,
  label = '',
  Trigger,
  fullWidth = false,
  popoverProps,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<TAnchorEl>(null)

  return (
    <PopoverWithoutAnchor
      Content={Content}
      label={label}
      Trigger={Trigger}
      fullWidth={fullWidth}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      popoverProps={popoverProps}
    />
  )
}

type TAnchorEl = HTMLButtonElement | HTMLDivElement | null
export const PopoverWithoutAnchor = ({
  Content,
  label = '',
  Trigger,
  fullWidth = false,
  anchorEl,
  setAnchorEl,
  popoverProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
}: Props & {
  anchorEl: TAnchorEl
  setAnchorEl: React.Dispatch<React.SetStateAction<TAnchorEl>>
}) => {
  const classes = useStyles()
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'fct-popover' : undefined
  return (
    <div>
      {Trigger ? (
        <div onClick={handleClick}>
          <Trigger open={open} />
        </div>
      ) : (
        <FCTButton
          className={classes.buttonMore}
          onClick={handleClick}
          endIcon={
            <FCTIcon
              name="arrowdown"
              width={10}
              height={10}
              style={{
                marginLeft: 8,
                transform: Boolean(anchorEl) ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              color={theme.palette.grey[400]}
            />
          }
        >
          {label}
        </FCTButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={popoverProps?.anchorOrigin}
        transformOrigin={popoverProps?.transformOrigin}
        marginThreshold={32}
        className={classes.popover}
        PaperProps={{
          className: classes.paper,
          ...(fullWidth
            ? {
                style: {
                  minWidth: anchorEl?.getBoundingClientRect().width || 'auto',
                },
              }
            : {}),
        }}
      >
        {Content}
      </Popover>
    </div>
  )
}

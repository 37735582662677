import { makeStyles } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import theme from '../../theme'
import { ISearchCompany } from '../../store/operations/queries/local/rest/SearchCompanies'
import { IHit } from '../../store/operations/queries/local/rest/RestfulResponse'
import Avatar from '@material-ui/core/Avatar'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import styled from 'styled-components'
import { decodeSearchString } from '../../store/cache/Common'
import { EProfileTab } from '../../types/Profile'
import { setProfileFilter } from '../../store/cache/ProfileFilterPolicies'
import { ISearchInputOption } from './interfaces'

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    width: '100%',
  },
  companyNameLogo: {
    textDecoration: 'none',
    display: 'flex',
  },
  logoComapny: {
    width: 24,
    height: 24,
    borderRadius: 2,
    marginRight: 12,
  },
  itemName: {
    fontSize: 14,
    width: 230,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    display: 'block',
    color: theme.palette.grey[900],
    fontWeight: 500,
    lineHeight: 2,
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[700],
    overflow: 'hidden',
  },
  itemBody: {
    flexGrow: 1,
    padding: '0 20px',
    width: '100%',
    marginBottom: 12,
  },
}))

const ShowMore = styled('div')`
  margin-top: 2px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: ${theme.palette.secondary.main};
  }
`

const highlightDesc = (searchValue: ISearchInputOption[], desc: string | undefined) => {
  if (!desc) return ''
  let result = desc
  searchValue?.forEach(e => {
    if (e.field === 'text') {
      const querystr = e.name.trim().replaceAll('(', '').replaceAll(')', '')
      const reg = new RegExp(querystr, 'gi')
      result = result.replace(reg, function (str) {
        return '<mark style="background:#D4F1E3">' + str + '</mark>'
      })
    }
  })
  return result
}

const highlightText = (str: string = '') =>
  str
    .split('*')
    .map((text, index) =>
      index % 2 == 1 ? `<mark style="background:#D4F1E3">${text}</mark>` : text
    )
    .join('')

const HEIGHT_DESCRIPTION_THREE_LINE = 51

const SearchResultItem = ({
  ChipComponent = null,
  searchOptions,
  company,
}: SearchResultItemProps) => {
  const classes = useStyles()

  const descriptionRef = useRef<HTMLDivElement>(null)

  const [isReadMore, setIsReadMore] = useState<boolean>(true)
  const [hasMoreThanThreeLine, setHasMoreThanThreeLine] = useState<boolean>(false)

  useEffect(() => {
    if ((descriptionRef?.current?.offsetHeight || 0) > HEIGHT_DESCRIPTION_THREE_LINE) {
      setHasMoreThanThreeLine(true)
      setIsReadMore(false)
    }
  }, [descriptionRef?.current])

  return (
    <div className={classes.item}>
      <Link
        to={`app/profile/${company?.fields?.company_id[0]}`}
        target="_blank"
        className={classes.companyNameLogo}
        onClick={() => {
          setProfileFilter({ profileTab: EProfileTab.News })
        }}
      >
        <Avatar
          className={classes.logoComapny}
          variant="square"
          alt={company?.fields?.name[0]}
          src={(company?.fields?.logo_url || [])[0]}
        />
        <span className={classes.itemName}>
          {company?.highlights?.name_encode_word
            ? parse(
                highlightText(decodeSearchString((company?.highlights as any)?.name_encode_word))
              )
            : company?.highlights?.name_encode
            ? parse(highlightText(decodeSearchString((company?.highlights as any)?.name_encode)))
            : company?.highlights?.name
            ? parse(highlightText((company?.highlights as any)?.name))
            : company?.fields?.name}
        </span>
      </Link>
      <div className={classes.itemBody}>
        <div
          className={classes.description}
          style={{ height: isReadMore ? 'auto' : HEIGHT_DESCRIPTION_THREE_LINE }}
          ref={descriptionRef}
        >
          {parse(highlightDesc(searchOptions || [], (company?.fields?.description || [])[0]))}
        </div>
        {hasMoreThanThreeLine && !isReadMore && (
          <ShowMore
            onClick={() => {
              setIsReadMore(true)
            }}
          >
            Read more...
          </ShowMore>
        )}
        {ChipComponent}
      </div>
    </div>
  )
}

export default SearchResultItem

export interface SearchResultItemProps {
  ChipComponent?: React.ReactElement | null
  searchOptions?: ISearchInputOption[]
  company: IHit<ISearchCompany>
}

import { useOktaAuth } from '@okta/okta-react'
import updateCookies from 'axios/updateCookies'
import { useEffect, useRef } from 'react'
import { useUserContext } from 'routers/InitUserWrapper'
import { getCookie } from 'utils/Cookie'

const CookiesClockContainer = () => {
  const { user } = useUserContext()
  const cookie = useRef<any>(JSON.parse(getCookie('CookieScriptConsent') || '{}'))

  const { oktaAuth } = useOktaAuth()

  const update = async () => {
    const accessToken = oktaAuth.getAccessToken()

    if (accessToken && !!cookie.current.action) {
      await updateCookies(
        {
          action: cookie.current.action,
          categories: cookie.current.categories,
        },
        accessToken
      )
    }
  }

  useEffect(() => {
    if (!user.profile.acceptedCookiesDate && !user.profile.rejectedCookiesDate) {
      update()
    }
  }, [user])

  useEffect(() => {
    const interval = setInterval(() => {
      const cookieScript = JSON.parse(getCookie('CookieScriptConsent') || '{}')

      if (cookie.current.action !== cookieScript.action) {
        cookie.current = cookieScript
        update()
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return null
}

export default CookiesClockContainer

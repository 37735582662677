import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ICompanyOption } from '../../features/search-companies-name/SearchCompanies'
import { numberWithCommas } from '../../utils/convert/number'

export enum EToastType {
  NormalAddCompaniesToWL,
  AddPartOfCompaniesToWl,
  RenameWL,
  DeletePartOfCompaniesFromWL,
  DeleteWL,
  CopyWL,
  UpdateWatchListFilter,
  UpdateNotiSetting,
  SetPermissions,
  ApprovedPermissions,
  DeclinedPermissions,
  RestoreWL,
}

const FCTStrong = styled.strong`
  max-width: ${props => props.theme.maxWidth};
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: bottom;
`
interface IWatchListToastsSuccessProps {
  type: EToastType
  res?: any
  selectedName?: string
  selectedList?: ICompanyOption[] | string[]
  watchListName?: string
  userName?: string | null
  isShareWl?: boolean
}

export const WatchListToastsSuccess = (props: IWatchListToastsSuccessProps) => {
  const { type, res, selectedName, selectedList, watchListName, userName, isShareWl } = props

  const watchListToastsSuccess = (type: EToastType) => {
    switch (type) {
      case EToastType.NormalAddCompaniesToWL:
        return (
          <>
            {selectedName && (
              <>
                {selectedList?.length! > 1 ? (
                  <strong>{numberWithCommas(selectedList?.length!)} companies</strong>
                ) : (
                  <strong>{selectedName}</strong>
                )}
                {selectedList?.length! > 1 ? ' have ' : ' has '}been added to{' '}
                <FCTStrong>{watchListName}.</FCTStrong>
              </>
            )}
            {!selectedName && (
              <>
                <strong>
                  {numberWithCommas(selectedList?.length!)}{' '}
                  {selectedList?.length! > 1 ? 'companies' : 'company'}
                </strong>
                {selectedList?.length! > 1 ? ' have ' : ' has '}been added to{' '}
                <FCTStrong>{watchListName}.</FCTStrong>
              </>
            )}
          </>
        )

      case EToastType.AddPartOfCompaniesToWl:
        return (
          <>
            {numberWithCommas(res?.data?.updateCompanyWatchlist?.duplicated)}{' '}
            {res?.data?.updateCompanyWatchlist?.duplicated > 1
              ? 'companies already exist'
              : 'company already exists'}
            .{' '}
            <strong>
              {numberWithCommas(res?.data?.updateCompanyWatchlist?.new)}{' '}
              {res?.data?.updateCompanyWatchlist?.new > 1 ? 'companies' : 'company'}
            </strong>{' '}
            {res?.data?.updateCompanyWatchlist?.new > 1 ? ' have ' : ' has '}been added to{' '}
            <FCTStrong theme={{ maxWidth: '50%' }}>{watchListName}.</FCTStrong>
          </>
        )

      case EToastType.RenameWL:
        return <>Watchlist name has been updated.</>

      case EToastType.DeletePartOfCompaniesFromWL:
        return (
          <>
            {selectedList?.length! > 1 ? (
              <strong>{numberWithCommas(selectedList?.length!)} companies</strong>
            ) : (
              <strong>{selectedName}</strong>
            )}
            {selectedList?.length! > 1 ? ' have ' : ' has '}been deleted from{' '}
            <FCTStrong theme={{ maxWidth: '50%' }}>{watchListName}.</FCTStrong>
          </>
        )

      case EToastType.DeleteWL:
        return (
          <>
            <FCTStrong>{watchListName}</FCTStrong> has been deleted.
          </>
        )

      case EToastType.CopyWL:
        return (
          <>
            <FCTStrong theme={{ maxWidth: '50%' }}>{watchListName}</FCTStrong> has been created.
          </>
        )

      case EToastType.UpdateWatchListFilter:
        return (
          <>
            <FCTStrong>{watchListName}</FCTStrong> has been updated with new filter conditions.
          </>
        )
      case EToastType.UpdateNotiSetting:
        return <>Update notifications settings successfully.</>

      case EToastType.SetPermissions:
        return <>Access has been updated successfully</>

      case EToastType.ApprovedPermissions:
        return (
          <>
            You approved the edit request of <strong>{userName}.</strong>
          </>
        )

      case EToastType.DeclinedPermissions:
        return (
          <>
            You declined the edit request of <strong>{userName}.</strong>
          </>
        )
      case EToastType.RestoreWL:
        return (
          <>
            <FCTStrong>{watchListName}</FCTStrong> has been recovered to
            {isShareWl ? ' Shared Watchlists' : ' My Watchlists'}.
          </>
        )

      default:
        break
    }
  }

  return (
    <Typography
      style={{ fontSize: 14, lineHeight: '22px', overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {watchListToastsSuccess(type)}
    </Typography>
  )
}

import styled from 'styled-components'
import theme from 'theme'

export const LinkHref = styled('a')`
  text-decoration: none;
  color: ${theme.palette.grey[800]};
  &:hover {
    text-decoration: underline;
  }
`

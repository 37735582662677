import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { updateRouter } from './features/router/RouterSlice'

export const SyncHistoryWithStore: React.FC = props => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      updateRouter({
        fullPath: `${location.pathname}${location.search}`,
        path: location.pathname,
        searchQuery: location.search,
      })
    )
  }, [location])
  return <>{props.children}</>
}

import { ETaxonomyCategory } from 'utils/FlattenEcoSystem'
import { CategoriesName, SearchFields, TagGroups } from './enums'
import { ITagGroupMap } from './interfaces'
import { IFieldBoostMap, ITagMap } from './type'

export const BUSINESS_AND_DIMENSIONS: Record<ETaxonomyCategory, Record<number, CategoriesName>> = {
  [ETaxonomyCategory.FinTech]: {
    [1]: CategoriesName.ProductLine,
    [2]: CategoriesName.BusinessLine,
  },
  [ETaxonomyCategory.InsurTech]: {
    [1]: CategoriesName.ProductLine,
    [2]: CategoriesName.ValueChain,
    [3]: CategoriesName.OperatingModel,
  },
  [ETaxonomyCategory.RegTech]: {
    [1]: CategoriesName.ProductLine,
    [2]: CategoriesName.Risk,
  },
}

export const TagMap: ITagMap = {
  industry: 'Industry',
  sectors: 'Business Line', // business line
  fintech_product: 'Product', // product
  insurtech_product: 'Product', // product
  regtech_product: 'Product', // product
  value_chains: 'Value Chain',
  operating_models: 'Operating Model',
  risks: 'Risks',
  tag_names: 'Business Model', // business model
  all_tag_names: 'Tag',
  all_tag_group_names: 'Tag Group',
  name: 'Company Name',
  location_region1_names: 'Region',
  location_region2_names: 'Sub-region',
  location_region3_names: 'Sub-region 2',
  location_countries: 'Country',
  location_cities: 'City',
  // TODO: team_name
  deal_types: 'Deal Type',
  investor_names: 'Investor Name',
  investor_types: 'Investor Type',
  acquisition_names: 'Acquirer Name', // TODO: will be generate from cloudsearch
  acquisition_types: 'Acquirer Type', // TODO: will be generate from cloudsearch

  website_keywords: 'Website Keywords',
  product_names: 'Product Names',

  // TODO: report
  last_deal_type: 'Latest Deal Type',
  text: 'Text',
}

export const TagGroupMap: ITagGroupMap = {
  industry: TagGroups.Dimension,
  sectors: TagGroups.Dimension, // bussiness line
  fintech_product: TagGroups.Dimension, // product
  insurtech_product: TagGroups.Dimension, // product
  regtech_product: TagGroups.Dimension, // product
  value_chains: TagGroups.Dimension,
  operating_models: TagGroups.Dimension,
  risks: TagGroups.Dimension,
  tag_names: TagGroups.Tag, // bussuness model
  all_tag_names: TagGroups.Tag, // bussuness model
  all_tag_group_names: TagGroups.Tag,
  name: TagGroups.UnknownCategory,
  location_region1_names: TagGroups.HQGeography,
  location_region2_names: TagGroups.HQGeography,
  location_region3_names: TagGroups.HQGeography,
  location_countries: TagGroups.HQGeography,
  location_cities: TagGroups.HQGeography,
  // TODO: team_name
  deal_types: TagGroups.Financing,
  last_deal_type: TagGroups.Financing,
  investor_names: TagGroups.InvestorName,
  investor_types: TagGroups.Financing,
  acquisition_names: TagGroups.InvestorName,
  acquisition_types: TagGroups.Financing,

  website_keywords: TagGroups.WebsiteKeyword,
  product_names: TagGroups.ProductName,
  // TODO: report
  text: TagGroups.Text,
}

export const BOOST_THRESHOLD = 100

export const HAVING_COUNT_FIELDS = [
  SearchFields.LOCATION_CITIES,
  SearchFields.LOCATION_COUNTRIES,
  SearchFields.INDUSTRY,
  SearchFields.FINTECH_PRODUCT,
  SearchFields.INSURTECH_PRODUCT,
  SearchFields.REGTECH_PRODUCT,
  SearchFields.SECTORS,
  SearchFields.VALUE_CHAINS,
  SearchFields.OPERATING_MODELS,
  SearchFields.RISKS,
  SearchFields.LOCATION_REGION1_NAMES,
  SearchFields.LOCATION_REGION2_NAMES,
  SearchFields.LOCATION_REGION3_NAMES,
  SearchFields.INVESTOR_TYPES,
  SearchFields.ACQUISITION_TYPES,
]
export const boostRanking = {
  TAXONOMY: Math.pow(BOOST_THRESHOLD, 8),
  COMPANY_NAME: Math.pow(BOOST_THRESHOLD, 7),
  COMPANY_NAME_ALIAS: Math.pow(BOOST_THRESHOLD, 6),
  DESCRIPTION: Math.pow(BOOST_THRESHOLD, 5),
  GEOGRAPHY: Math.pow(BOOST_THRESHOLD, 4),
  ACQUIRER_INVESTOR: Math.pow(BOOST_THRESHOLD, 3),
  WEBSITE_KEYWORDS: Math.pow(BOOST_THRESHOLD, 2),
  PRODUCT_NAMES: Math.pow(BOOST_THRESHOLD, 1),
}
export const FieldBoostMap: IFieldBoostMap = {
  industry: boostRanking.TAXONOMY,
  sectors: boostRanking.TAXONOMY,
  value_chains: boostRanking.TAXONOMY,
  operating_models: boostRanking.TAXONOMY,
  risks: boostRanking.TAXONOMY,
  fintech_product: boostRanking.TAXONOMY,
  regtech_product: boostRanking.TAXONOMY,
  insurtech_product: boostRanking.TAXONOMY,
  name: boostRanking.COMPANY_NAME,
  company_alias: boostRanking.COMPANY_NAME_ALIAS,
  description: boostRanking.DESCRIPTION,
  location_cities: boostRanking.GEOGRAPHY,
  location_countries: boostRanking.GEOGRAPHY,
  location_region1_names: boostRanking.GEOGRAPHY,
  location_region2_names: boostRanking.GEOGRAPHY,
  location_region3_names: boostRanking.GEOGRAPHY,
  investor_names: boostRanking.ACQUIRER_INVESTOR,
  acquisition_names: boostRanking.ACQUIRER_INVESTOR,
  website_keywords: boostRanking.WEBSITE_KEYWORDS,
  website_url: boostRanking.WEBSITE_KEYWORDS,
  product_names: boostRanking.PRODUCT_NAMES,
}

export const TagGroupMapColor = new Map([
  [TagGroups.Taxonomy, '#173E59'],
  [TagGroups.HQGeography, '#F3A93C'],
  [TagGroups.Financing, '#CE618E'],
  [TagGroups.WebsiteKeyword, '#6CAB57'],
  [TagGroups.ProductName, '#007BCA'],
])

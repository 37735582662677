import React from 'react'
import { UsePaginationProps } from '@material-ui/lab/Pagination'
import { usePagination } from '@material-ui/lab/Pagination'
import { Theme } from '@material-ui/core/styles'
import styled from 'styled-components'
import Icon from '../Icon/Icon'

const PaginationContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`

const PaginationItem = styled.button`
  background: #f1f5f9;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  border: none;
  font-weight: 600;
  font-size: 14px;

  &:not(last-child) {
    margin-right: 5px;
  }

  ${({ theme }: { theme: Theme }) => `
    color: ${theme.palette.grey[400]};
    cursor: pointer;

    &:hover, &:focus {
      outline: none;
    }

    &.selected, &:hover {
      color: ${theme.palette.primary.contrastText};
      background: ${theme.palette.primary.light};
    }
  `}
`

export const FCTPagination = (props: UsePaginationProps) => {
  const { items } = usePagination({
    ...props,
  })

  return (
    <PaginationContainer>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null

        if (type === 'previous') {
          children = (
            <PaginationItem {...item}>
              <Icon name="arrowleft" width="12px"></Icon>
            </PaginationItem>
          )
        } else if (type === 'next') {
          children = (
            <PaginationItem {...item}>
              <Icon name="arrowright" width="12px"></Icon>
            </PaginationItem>
          )
        } else if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = <PaginationItem>...</PaginationItem>
        } else if (type === 'page') {
          children = (
            <PaginationItem type="button" className={selected ? 'selected' : ''} {...item}>
              {page}
            </PaginationItem>
          )
        } else {
          children = (
            <PaginationItem type="button" {...item}>
              {type}
            </PaginationItem>
          )
        }

        return <li key={index}>{children}</li>
      })}
    </PaginationContainer>
  )
}

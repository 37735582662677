export enum EInvestorProfileTab {
  Portfolio = 'Portfolio',
}

export enum EInvestorProfilePortfolioTab {
  Investments = 'Investments',
  Acquisitions = 'Acquisitions',
}

export const INVESTOR_PROFILE_TAB_NAME = new Map<string, string>([
  [EInvestorProfileTab.Portfolio, 'Portfolio'],
])

export enum EEquityFChartInvestorProfile {
  EFProductLine = 'Product Line',
  EFBusinessLine = 'Business Line',
  EFMaturity = 'Funding Maturity',
  EFValueChain = 'Value Chain',
  EOperatingModel = 'Operating Model',
}

import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { AuthenticationTemplate } from '../templates/AuthenticationTemplate/AuthenticationTemplate'
import Loading from '../components/Loading'
import Flex from '../components/Flex/Flex'
import { makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { REDIRECT_URL } from '../core/constants/Router'
import SignInContainer from '../containers/SignInContainer'
import ExpiryDateContainer from 'containers/ExpiryDateContainer'
import { LOCAL_STORAGE_FIELDS } from 'utils/consts'
import { EErrorCode } from 'components/Authentication/AuthError'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#f8fafc',
  },
})

export const LoginPage = () => {
  const classes = useStyles()
  const { authState, oktaAuth } = useOktaAuth()
  const history = useHistory()
  const accessToken = oktaAuth.getAccessToken()
  const callBackOriginUrl = localStorage.getItem('CALLBACK_ORIGIN_URL')
  const [isExpiredGroup, setIsExpiredGroup] = useState(false)

  useEffect(() => {
    if (
      localStorage.getItem(LOCAL_STORAGE_FIELDS.SIGN_IN_UP_ERROR) ===
      `${EErrorCode.USER_GROUP_EXPIRED}`
    ) {
      setIsExpiredGroup(true)
    }
  })

  useEffect(() => {
    if (
      authState?.isAuthenticated &&
      accessToken &&
      callBackOriginUrl &&
      callBackOriginUrl == '/auth/callback/login'
    ) {
      localStorage.setItem('login', '1')
      const redirectUrl = localStorage.getItem(REDIRECT_URL)
      if (redirectUrl) localStorage.removeItem(REDIRECT_URL)

      history.push(redirectUrl || '/app')
    }
  }, [authState?.isAuthenticated])

  if (!authState || (authState?.isAuthenticated && !accessToken)) {
    return (
      <Flex className={classes.root}>
        <Loading />
      </Flex>
    )
  }

  if (isExpiredGroup) {
    return <ExpiryDateContainer />
  }

  return (
    <AuthenticationTemplate>
      <SignInContainer />
    </AuthenticationTemplate>
  )
}

import { makeStyles, Theme, Typography, Link as MTULink } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import React, { useState } from 'react'
import FCTIcon from '../Icon/Icon'
import Loading from '../Loading'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '14px',
    lineHeight: '22px',
    '& p': {
      margin: 0,
    },
    marginBottom: '16px',
    textAlign: 'center',
  },
  linkMail: {
    marginBottom: '10px',
    fontWeight: 600,
  },
  link: {
    color: `${theme.palette.primary.light}`,
    marginLeft: '4px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  footer: {
    color: '#475569',
    marginTop: '50px',
  },
  titleForm: {
    marginTop: '40px',
    color: `${theme.palette.grey[900]}`,
    fontSize: '28px',
    lineHeight: '42px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  resend: {
    marginTop: 16,
  },
}))

export const ConfirmMail = ({
  email,
  handleResend,
  loadingResend,
  hasError = false,
  textError,
  name,
  type,
}: {
  name?: string
  email?: string
  handleResend: () => Promise<void>
  loadingResend?: boolean
  hasError?: boolean
  textError?: string
  type?: 'confirm' | 'notification'
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { oktaAuth } = useOktaAuth()

  const onClickResend = () => {
    setLoading(!hasError)
    handleResend()
  }

  return (
    <div className={classes.root}>
      <FCTIcon name="mail" width={95} height={71} />
      {type && type == 'notification' ? (
        <>
          <Typography className={classes.titleForm} variant="h3">
            Thank you, {name ? `${name}!` : ''}
          </Typography>
          <p>
            Thank you for signing up to the FCT. Our team is reviewing your request and will get
            back to you shortly.
          </p>
          <MTULink className={classes.link} onClick={() => oktaAuth.signOut()}>
            Go back to login
          </MTULink>
        </>
      ) : (
        <>
          <Typography className={classes.titleForm} variant="h3">
            Confirm your email!
          </Typography>
          <div className={classes.linkMail}>
            We've sent an email to
            <MTULink className={classes.link}>{email}</MTULink>
          </div>
          <p>Click on the link in the email to confirm your account.</p>
          <div className={classes.footer}>
            <p>Didn't get the confirmation email?</p>
            <p>
              Check your spam folder or
              <MTULink className={classes.link} onClick={onClickResend}>
                <FCTIcon name="refreshAuth" width={9} height={9} style={{ marginRight: '3px' }} />
                Send again
              </MTULink>
            </p>
            <div className={classes.resend}>
              {loadingResend ? (
                <Loading size={30} />
              ) : loading && !hasError ? (
                'Resend verify email success!'
              ) : (
                ''
              )}
              {hasError && (
                <span style={{ color: 'red' }}>
                  {!!textError ? textError : 'Error occurred, please try again!'}
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

import { makeStyles } from '@material-ui/core'
import { PropsWithChildren } from 'react'
import Header from '../containers/HeaderContainer'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#f8fafc',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
})
const MainTemplate = ({
  children,
  disabledHeader,
}: PropsWithChildren<{
  disabledHeader?: {
    watchlist?: boolean
    search?: boolean
    requestCompany?: boolean
  }
}>) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Header disabled={disabledHeader} />
      <div className={classes.content}>{children}</div>
    </div>
  )
}
export default MainTemplate

import gql from 'graphql-tag'

export interface GetCompanyListVariables {
  input: {
    pageSize?: number
    pageNumber?: number
    filterBy?:
      | {
          key?: string
          value?: string
        }[]
      | null
  }
}
export interface GetCompanyList_getCompanyList {
  data: {
    items: GetCompanyList_getCompanyList_items[]
  }
}

export interface GetCompanyList_getCompanyList_items {
  id: number
  name: string
  websiteUrl: string | null
  logoBucketUrl: string | null
}
export const GET_COMPANY_LIST = gql`
  query GetCompanyList($input: GetCompanyListInput!) {
    getCompanyList(input: $input)
      @rest(
        path: ""
        method: "POST"
        type: "GetCompanyList"
        endpoint: "get_company_list"
        bodyKey: "input"
      ) {
      data {
        items {
          id
          name
          websiteUrl
          logoBucketUrl
        }
      }
    }
  }
`

export interface CompanyListItem
  extends Omit<GetCompanyList_getCompanyList_items, 'id' | '__typename'> {
  id: string
}

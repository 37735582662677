import { IAnalysisFilter } from './../../../types/Filter'
import { Action } from 'redux-actions'
import { ofType } from 'redux-observable'
import { RootState } from '../../store'
import { concat, Observable, of } from 'rxjs'
import { switchMap, withLatestFrom } from 'rxjs/operators'
import { onSearchActionEmpty } from '../search/SearchAction'
import {
  clearAnalysisSearchOption,
  initialAnalysisFilter,
  updateAnalysisFilter,
} from './AnalysisSlice'
import { applySearchOptToAnalysis } from './AnalysisAction'
import { ISearchInputOption } from 'components/Search/interfaces'

const resetAnalysisSearchOptionEpic = (action$: Observable<Action<any>>, store: RootState) =>
  action$.pipe(
    ofType(onSearchActionEmpty.type),
    switchMap((action: Action<ISearchInputOption[]>) => {
      return concat(of(clearAnalysisSearchOption(null)))
    })
  )

const applySearchOptionToFilterEpic = (
  action$: Observable<Action<any>>,
  store$: Observable<RootState>
) =>
  action$.pipe(
    ofType(applySearchOptToAnalysis.type),
    withLatestFrom(store$),
    switchMap(([action, store]) => {
      const searchOption = store.searchReducer.options
      return concat(
        of(
          updateAnalysisFilter({
            ...initialAnalysisFilter,
            searchData: {
              ...initialAnalysisFilter.searchData,
              options: [...searchOption],
            },
          } as IAnalysisFilter)
        )
      )
    })
  )
export default [resetAnalysisSearchOptionEpic, applySearchOptionToFilterEpic]

import axios from 'axios'
import config from 'core/config/auth'

export default function (body: { action: 'reject' | 'accept'; categories: string }, token: string) {
  return axios.post(`${config.api}/users/cookies`, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
}

import { createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    styleGroup: {
      margin: '17px 0 20px',
      display: 'flex',
      alignItems: 'center',
    },
    groupText: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '18px',
      color: '#6C809D',
    },
    divider: {
      margin: 6,
      flex: 1,
    },
    centerItem: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

interface IGroupTitleProps {
  name: string
  children?: React.ReactNode
  icon?: React.ReactNode
}

export const GroupTitle = (props: IGroupTitleProps) => {
  const classes = useStyles()
  const { name, children, icon } = props

  return (
    <Grid item container xs={12} className={classes.styleGroup}>
      <div className={classes.centerItem}>
        <Typography className={classes.groupText}>{name}</Typography>
        <div>{icon || ''}</div>
      </div>
      <Divider className={classes.divider} />
      {children}
    </Grid>
  )
}

import { makeVar, useQuery } from '@apollo/client'

export const FCT_SCORE_OVERALL_KEY = 'fct_score_overall'

export interface IColumnGroup {
  key: EColumnGroupKey
  name: string
  status: EColumnGroupStatus
}

export interface IColumnState {
  key: string
  selected: boolean
  name: string
  groupKey: EColumnGroupKey | null
  order: number
  status?: EColumnStateStatus
}

export enum EColumnStateStatus {
  Open,
  Hide,
}

export interface ICompanyColumnFilter {
  key: string
  name?: string
  type: 'checkboxes' | 'choice'
  values?: ICompanyFilterValue[]
  value?: any
  display?: boolean
}

export interface ICompanyFilterValue {
  key: string
  name: string
  selected: boolean
}

export enum EColumnGroupStatus {
  Checked,
  Indeterminate,
  Unchecked,
}

export enum EColumnGroupKey {
  Taxonomy,
  BasicInfo,
  Geography,
  Financing,
  Investor,
  MaAndIPO,
  Partnerships,
  Ipo,
}

export const initSearchCompanyColumns: IColumnState[] = [
  // Taxonomy ~ Products
  {
    key: 'industry',
    selected: false,
    name: 'Industry',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'finTechBussinessLine',
    selected: false,
    name: 'FinTech Business Line',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'issueTechValueChainL1',
    selected: false,
    name: 'InsurTech Value Chain L1',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'issueTechValueChainL2',
    selected: false,
    name: 'InsurTech Value Chain L2',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'issueTechOperatingModel',
    selected: false,
    name: 'InsurTech Operating Model',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'productL1',
    selected: true,
    name: 'Product L1',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 2,
  },
  {
    key: 'productL2',
    selected: false,
    name: 'Product L2',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'productL3',
    selected: false,
    name: 'Product L3',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'productL4',
    selected: false,
    name: 'Product L4',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'productL5',
    selected: false,
    name: 'Product L5',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'tag_name',
    selected: false,
    name: 'Tag',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  {
    key: 'finTech_type',
    selected: false,
    name: 'Business Model',
    groupKey: EColumnGroupKey.Taxonomy,
    order: 10,
  },
  //TODO: temporary remove
  // {
  //   key: 'distribution_model',
  //   selected: false,
  //   name: 'Distribution Model',
  //   groupKey: EColumnGroupKey.Taxonomy,
  //   order: 10,
  // },

  // Basic Info
  {
    key: 'status',
    selected: true,
    name: 'Operating Status',
    groupKey: EColumnGroupKey.BasicInfo,
    order: 3,
  },
  {
    key: 'founded_year',
    selected: true,
    name: 'Founded Year',
    groupKey: EColumnGroupKey.BasicInfo,
    order: 9,
  },
  {
    key: 'numEmployees',
    selected: false,
    name: 'Employee Count',
    groupKey: EColumnGroupKey.BasicInfo,
    order: 10,
  },
  // Geography ~ location
  {
    key: 'region1_name',
    selected: false,
    name: 'HQ Region',
    groupKey: EColumnGroupKey.Geography,
    order: 10,
  },
  {
    key: 'region2_name',
    selected: false,
    name: 'HQ Sub-region 1',
    groupKey: EColumnGroupKey.Geography,
    order: 10,
  },
  {
    key: 'region3_name',
    selected: false,
    name: 'HQ Sub-region 2',
    groupKey: EColumnGroupKey.Geography,
    order: 10,
  },
  {
    key: 'country_name',
    selected: true,
    name: 'HQ Country',
    groupKey: EColumnGroupKey.Geography,
    order: 8,
  },

  // Equity Financing
  {
    key: 'total_funding_amount',
    selected: false,
    name: 'Total Funding',
    groupKey: EColumnGroupKey.Financing,
    order: 10,
  },
  {
    key: FCT_SCORE_OVERALL_KEY,
    selected: true,
    name: 'FCT Score',
    groupKey: EColumnGroupKey.BasicInfo,
    order: 4,
  },
  {
    key: 'distance',
    selected: false,
    name: 'Smart Cluster Distance',
    groupKey: null,
    order: 4,
  },
  {
    key: 'equity_funding_amount',
    selected: true,
    name: 'Equity Funding',
    groupKey: EColumnGroupKey.Financing,
    order: 4,
  },
  {
    key: 'latestDealType',
    selected: true,
    name: 'Latest Deal Type',
    groupKey: EColumnGroupKey.Financing,
    order: 5,
  },
  {
    key: 'latestDealDate',
    selected: true,
    name: 'Latest Deal Date',
    groupKey: EColumnGroupKey.Financing,
    order: 7,
  },
  {
    key: 'latestDealAmount',
    selected: true,
    name: 'Latest Deal Amount',
    groupKey: EColumnGroupKey.Financing,
    order: 6,
  },
  {
    key: 'total_funding_round',
    selected: false,
    name: 'Number of Funding Rounds',
    groupKey: EColumnGroupKey.Financing,
    order: 10,
  },

  // Investor
  {
    key: 'investor',
    selected: false,
    name: 'Investor',
    groupKey: EColumnGroupKey.Investor,
    order: 10,
  },
  {
    key: 'investorType',
    selected: false,
    name: 'Investor Type',
    groupKey: EColumnGroupKey.Investor,
    order: 10,
  },
  {
    key: 'leadInvestor',
    selected: false,
    name: 'Lead Investor',
    groupKey: EColumnGroupKey.Investor,
    order: 10,
  },
  {
    key: 'leadInvestorType',
    selected: false,
    name: 'Lead Investor Type',
    groupKey: EColumnGroupKey.Investor,
    order: 10,
  },
  {
    key: 'number_of_investors',
    selected: false,
    name: 'Number of Investors',
    groupKey: EColumnGroupKey.Investor,
    order: 10,
  },

  // M&A & IPO
  {
    key: 'acquirer',
    selected: false,
    name: 'Acquirer',
    groupKey: EColumnGroupKey.MaAndIPO,
    order: 10,
  },
  {
    key: 'acquirer_date',
    selected: false,
    name: 'Acquired Date',
    groupKey: EColumnGroupKey.MaAndIPO,
    order: 10,
  },
  {
    key: 'acquirer_type',
    selected: false,
    name: 'Acquirer Type',
    groupKey: EColumnGroupKey.MaAndIPO,
    order: 10,
  },
  {
    key: 'went_public_on',
    selected: false,
    name: 'Went public on',
    groupKey: EColumnGroupKey.Ipo,
    order: 10,
  },
]

const initColumnGroups: IColumnGroup[] = [
  {
    name: 'Taxonomy',
    key: EColumnGroupKey.Taxonomy,
    status: EColumnGroupStatus.Unchecked,
  },
  {
    name: 'Company Overview',
    key: EColumnGroupKey.BasicInfo,
    status: EColumnGroupStatus.Unchecked,
  },
  {
    name: 'HQ Geography',
    key: EColumnGroupKey.Geography,
    status: EColumnGroupStatus.Unchecked,
  },
  {
    name: 'Financing',
    key: EColumnGroupKey.Financing,
    status: EColumnGroupStatus.Unchecked,
  },
  {
    name: 'Investor',
    key: EColumnGroupKey.Investor,
    status: EColumnGroupStatus.Unchecked,
  },
  {
    name: 'M&A',
    key: EColumnGroupKey.MaAndIPO,
    status: EColumnGroupStatus.Unchecked,
  },
  {
    name: 'IPO',
    key: EColumnGroupKey.Ipo,
    status: EColumnGroupStatus.Unchecked,
  },
]

//TODO list key column Companies can sort
export const arrColumnCompaniesSort: IColumnSort[] = [
  { key: 'name', groupFilter: '', hasSortBy: true },
  { key: 'numEmployees', groupFilter: 'companyOverview', hasSortBy: true },
  { key: 'finTechBussinessLine', groupFilter: 'taxonomy', hasSortBy: true },
  { key: 'issueTechValueChainL1', groupFilter: 'taxonomy', hasSortBy: true },
  { key: 'issueTechValueChainL2', groupFilter: 'taxonomy', hasSortBy: true },
  { key: 'issueTechOperatingModel', groupFilter: 'taxonomy', hasSortBy: true },
  { key: 'region1_name', groupFilter: 'geography', hasSortBy: true },
  { key: 'region2_name', groupFilter: 'geography', hasSortBy: true },
  { key: 'region3_name', groupFilter: 'geography', hasSortBy: true },
  { key: 'country_name', groupFilter: 'geography', hasSortBy: true },
  { key: 'total_funding_amount', groupFilter: 'financing', hasSortBy: true },
  { key: 'total_funding_round', groupFilter: 'financing', hasSortBy: true },
  { key: 'founded_year', groupFilter: 'companyOverview', hasSortBy: true },
  { key: 'latestDealDate', groupFilter: '', hasSortBy: true },
  { key: 'latestDealAmount', groupFilter: 'financing', hasSortBy: true },
  { key: 'latestDealType', groupFilter: 'financing', hasSortBy: true },
  { key: 'acquirer_date', groupFilter: 'ma', hasSortBy: true },
  { key: 'number_of_investors', groupFilter: 'investor', hasSortBy: true },
  { key: 'equity_funding_amount', groupFilter: 'financing', hasSortBy: true },
  { key: 'status', groupFilter: 'companyOverview', hasSortBy: true },
  { key: 'finTech_type', groupFilter: 'taxonomy', hasSortBy: true },
  { key: 'distribution_model', groupFilter: 'taxonomy', hasSortBy: true },
  { key: 'tag_name', groupFilter: 'taxonomy', hasSortBy: false },
  { key: 'industry', groupFilter: '', hasSortBy: true },
  { key: 'investor', groupFilter: 'investor', hasSortBy: false },
  { key: 'investorType', groupFilter: 'investor', hasSortBy: false },
  { key: 'leadInvestor', groupFilter: 'investor', hasSortBy: false },
  { key: 'leadInvestorType', groupFilter: 'investor', hasSortBy: false },
  { key: 'acquirer', groupFilter: 'ma', hasSortBy: false },
  { key: 'acquirer_type', groupFilter: 'ma', hasSortBy: false },
  { key: 'went_public_on', groupFilter: 'ipo', hasSortBy: true },
  { key: FCT_SCORE_OVERALL_KEY, groupFilter: '', hasSortBy: true },
  { key: 'distance', groupFilter: '', hasSortBy: false },
]

export interface IColumnSort {
  key: string
  groupFilter: string
  hasSortBy: boolean
}

//TODO list key column using tooltip
export const arrColumnTooltip = [
  'leadInvestor',
  'investors_name',
  'investor',
  'acquirer',
  'acquirer_type',
  'leadInvestorType',
  'investorType',
  'tag_name',
]

export const KEY_COLUMN_DISTANCE = 'distance'

export const companiesListSelectedVar = makeVar<number[]>([])
export const searchCompanyColumnsVar = makeVar<IColumnState[]>(initSearchCompanyColumns)
export const columnGroupsVar = makeVar<IColumnGroup[]>(initColumnGroups)

export const companiesListPolicies = {
  companiesListSelected: {
    read() {
      return companiesListSelectedVar()
    },
  },
  searchCompanyColumns: {
    read() {
      return searchCompanyColumnsVar()
    },
  },
  columnGroups: {
    read() {
      return columnGroupsVar()
    },
  },
}

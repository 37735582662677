import { useQuery } from '@apollo/client'
import { makeStyles, TableContainer, Tabs, Typography } from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { GET_DEAL_FILTER_DATA } from 'store/operations/queries/GetDealFilterData'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '__generated__/GetDealFilterDataResult'
import { AntTab } from '../components/AntTab'
import FCTCard from '../components/Card/FCTCard'
import FCTCardContent from '../components/Card/FCTCardContent'
import FCTCardHeader from '../components/Card/FCTCardHeader'
import EFByFundingAmountChart from '../components/EFByFundingAmountChart'
import EFByGeographyChart from '../components/EFByGeographyChart'
import Flex from '../components/Flex/Flex'
import FCTIcon from '../components/Icon/Icon'
import Loading from '../components/Loading'
import { SelectAnalysis } from '../components/SelectAnalysis/SelectAnalysis'
import { industryOptions, periodTypeOptions } from '../core/constants/Chart'
import mixpanel from '../mixpanel'
import { useAnalysisFilter, useAnalysisLocalFilter } from '../store/features/analysis/AnalysisHook'

import { GET_EQUITY_FUNDING_BY_FUNDING_AMOUNT } from '../store/operations/queries/GetEquityFundingByFundingAmount'
import { GET_EF_BY_GEOGRAPHY_DATA } from '../store/operations/queries/GetEquityFundingByGeographyData'
import theme from '../theme'
import { LocationType, PeriodType } from '../utils/convert/Title'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../__generated__/globalTypes'
import useGetSearchCompanies from './../core/hooks/useGetSearchCompanies'
import { getInputAnalysis } from 'utils/Filter'
import { useCompanyDealFilter } from 'store/features/company-deal/CompanyDealHook'
import { DashboardChildProps } from 'pages/DashboardPage'

const useStyles = makeStyles({
  card: {
    border: '1px solid #E7ECF3',
    boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    height: '100%',
  },
  cardHeader: {
    borderBottom: '1px solid #E7ECF3',
  },
  headerText: {
    textAlign: 'start',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
  },
  cardContent: {
    height: 'calc((100vh - 130px)/2)',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    padding: '0px 10px 0 20px',
  },
  container: {
    height: '100%',
    padding: '10px 10px 0 0',
    boxSizing: 'border-box',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  tabs: {
    flexShrink: 1,
  },
  tooltip: {
    opacity: '1!important',
    border: '1px solid #000!important',
    '&::before': {
      borderTop: '8px solid #000!important',
    },
    fontWeight: 'normal',
    fontSize: 12,
  },
})

const DashboardChartContainer = (props: DashboardChildProps) => {
  const classes = useStyles()
  const history = useHistory()
  const [analysisFilter, setAnalysisFilter] = useAnalysisFilter()
  const [_, setCompanyFilter] = useCompanyDealFilter()

  const [selectedTab, setSelectedTab] = React.useState(EEquityFChart.EFProductLine)
  const [localFilter, setLocalFilter] = useAnalysisLocalFilter()
  const { periodType, locationType } = localFilter
  const { similarCompaniesIds, loadingSimilarCompaniesByKeywords } = props

  // GRAPHQL
  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA)
  const filterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData

  const handleChange = (event: { type: string; value: PeriodType }) => {
    setLocalFilter({
      periodType: event.value,
    })
    trackingEvent(selectedTab, event.value, analysisFilter.category)
  }

  const handleChangeIndustry = (event: { type: string; value: InternalDbCompanyTypes }) => {
    props.handleResetFilter({
      category: event.value,
    })
    trackingEvent(selectedTab, periodType, event.value)
  }

  const viewByChartGeo = useMemo(() => {
    return analysisFilter.viewBy === GlobalFilterViewBy.product ||
      analysisFilter.category !== InternalDbCompanyTypes.fintech
      ? GlobalFilterViewBy.product
      : GlobalFilterViewBy.business
  }, [analysisFilter.viewBy, analysisFilter.category])

  const trackingEvent = (
    selectedTab: EEquityFChart,
    periodType: PeriodType,
    industry: InternalDbCompanyTypes
  ) => {
    mixpanel.track('Dashboard_Analytics', {
      dashboardAnalyticsTab: selectedTab,
      dashboardAnalyticsTimePeriod: periodType,
      dashboardAnalyticsIndustry: industry,
    })
  }

  // Get companyIds filter
  const companySearchFilter = useGetSearchCompanies(analysisFilter)

  const inputAnalysis = {
    ...getInputAnalysis(analysisFilter, filterData, companySearchFilter, similarCompaniesIds),
    type: periodType as any,
  }

  const { data: queryDataFundingBusinessChart, loading: loadingFundingBusinessChart } = useQuery(
    GET_EQUITY_FUNDING_BY_FUNDING_AMOUNT,
    {
      variables: {
        input: {
          ...inputAnalysis,
          megaRoundsOnly: false,
          viewBy: GlobalFilterViewBy.business,
        },
      },
      skip: loadingSimilarCompaniesByKeywords,
      fetchPolicy: 'network-only',
    }
  )

  const { data: queryDataFundingProductChart, loading: loadingFundingProductChart } = useQuery(
    GET_EQUITY_FUNDING_BY_FUNDING_AMOUNT,
    {
      variables: {
        input: {
          ...inputAnalysis,
          megaRoundsOnly: false,
          viewBy: GlobalFilterViewBy.product,
        },
      },
      skip: loadingSimilarCompaniesByKeywords,
      fetchPolicy: 'network-only',
    }
  )

  const { data: queryDataGeographyChart, loading: loadingGeographyChart } = useQuery(
    GET_EF_BY_GEOGRAPHY_DATA,
    {
      variables: {
        input: {
          ...inputAnalysis,
          locationType: locationType || LocationType.SubRegion,
          type: periodType,
          viewBy: viewByChartGeo,
        },
      },
      skip: loadingSimilarCompaniesByKeywords,
      fetchPolicy: 'network-only',
    }
  )

  const onViewDetail = (
    selectedTab: EEquityFChart,
    industry: InternalDbCompanyTypes,
    periodType: PeriodType
  ) => {
    setLocalFilter({ periodType: periodType })

    switch (selectedTab) {
      case EEquityFChart.EFGeography: {
        setAnalysisFilter({
          viewBy: viewByChartGeo,
        })
        history.push('/app/analysis/charts/equity-funding/by-funding-amount')
        break
      }
      default: {
        setAnalysisFilter({
          viewBy:
            selectedTab === EEquityFChart.EFBusinessLine &&
            industry === InternalDbCompanyTypes.fintech
              ? GlobalFilterViewBy.business
              : GlobalFilterViewBy.product,
        })
        history.push('/app/analysis/charts/equity-funding/by-funding-amount')
        break
      }
    }
  }

  useEffect(() => {
    if (
      selectedTab === EEquityFChart.EFBusinessLine &&
      analysisFilter.category !== InternalDbCompanyTypes.fintech
    ) {
      setSelectedTab(EEquityFChart.EFProductLine)
    }
  }, [analysisFilter.category, selectedTab])

  return (
    <FCTCard className={classes.card}>
      <FCTCardHeader
        className={classes.cardHeader}
        titleElement={
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Typography className={classes.headerText}>Equity Funding by</Typography>
              <Tabs
                className={classes.tabs}
                indicatorColor="primary"
                value={selectedTab}
                onChange={(_, v) => {
                  trackingEvent(v, periodType, analysisFilter.category)
                  setSelectedTab(v)
                }}
                variant="scrollable"
                scrollButtons="off"
              >
                <AntTab
                  label="Product Line"
                  value={EEquityFChart.EFProductLine}
                  data-testid="dashboard-product-line"
                />
                {analysisFilter.category === InternalDbCompanyTypes.fintech && (
                  <AntTab
                    label="Business Line"
                    value={EEquityFChart.EFBusinessLine}
                    data-testid="dashboard-business-line"
                  />
                )}
                <AntTab
                  label="Geography"
                  value={EEquityFChart.EFGeography}
                  data-testid="dashboard-geography"
                />
              </Tabs>
            </Flex>
            <Flex>
              <SelectAnalysis
                labelSelect="Industry"
                options={industryOptions}
                values={[analysisFilter.category]}
                setValues={() => {}}
                handleChange={handleChangeIndustry}
              />
              <SelectAnalysis
                labelSelect="Period Type"
                options={periodTypeOptions}
                values={[`${periodType}`]}
                setValues={() => {}}
                handleChange={handleChange}
              />
            </Flex>
          </Flex>
        }
        avatar={
          <FCTIcon name="funding" width={14} height={14} color={`${theme.palette.grey[400]}`} />
        }
      ></FCTCardHeader>
      <FCTCardContent className={classes.cardContent}>
        {loadingGeographyChart ||
        loadingFundingProductChart ||
        loadingFundingBusinessChart ||
        loadingSimilarCompaniesByKeywords ? (
          <Loading />
        ) : (
          <>
            <ReactTooltip id="tooltip" type="dark" className={classes.tooltip}>
              Click to view details
            </ReactTooltip>
            <TableContainer
              className={classes.container}
              onClick={() => onViewDetail(selectedTab, analysisFilter.category, periodType)}
              data-tip
              data-for="tooltip"
            >
              {(() => {
                switch (selectedTab) {
                  case EEquityFChart.EFGeography:
                    return (
                      <EFByGeographyChart
                        data={queryDataGeographyChart?.getEquityFundingByGeographyData}
                        periodType={periodType}
                        locationType={LocationType.SubRegion}
                        type={EChartPosition.Dashboard}
                        filter={analysisFilter}
                        filterData={filterData}
                      ></EFByGeographyChart>
                    )
                  case EEquityFChart.EFBusinessLine:
                    return (
                      <EFByFundingAmountChart
                        data={
                          queryDataFundingBusinessChart?.getEquityFundingByFundingAmountChartData
                        }
                        periodType={periodType}
                        type={EChartPosition.Dashboard}
                        filter={analysisFilter}
                        filterData={filterData}
                        viewBy={GlobalFilterViewBy.business}
                      ></EFByFundingAmountChart>
                    )
                  case EEquityFChart.EFProductLine:
                    return (
                      <EFByFundingAmountChart
                        data={
                          queryDataFundingProductChart?.getEquityFundingByFundingAmountChartData
                        }
                        periodType={periodType}
                        type={EChartPosition.Dashboard}
                        filter={analysisFilter}
                        filterData={filterData}
                        viewBy={GlobalFilterViewBy.product}
                      ></EFByFundingAmountChart>
                    )
                  default:
                    return null
                }
              })()}
            </TableContainer>
          </>
        )}
      </FCTCardContent>
    </FCTCard>
  )
}
export default DashboardChartContainer

export enum EEquityFChart {
  EFProductLine = 'Product Line',
  EFBusinessLine = 'Business Line',
  EFValueChain = 'Value Chain',
  EFGeography = 'Geography',
  EFMaturity = 'Maturity',
  EFOperatingModel = 'Operating Model',
}

export const OrderViewBy = new Map<EEquityFChart, number>([
  [EEquityFChart.EFProductLine, 0],
  [EEquityFChart.EFBusinessLine, 1],
  [EEquityFChart.EFValueChain, 2],
  [EEquityFChart.EFGeography, 3],
  [EEquityFChart.EFMaturity, 4],
  [EEquityFChart.EFOperatingModel, 5],
])

export enum EChartPosition {
  Analysis,
  Dashboard,
  Sunburst,
  InvestorProfile,
}

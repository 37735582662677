/* Password requirements:
 ** At least 12 characters
 ** A lowercase letter
 ** An uppercase letter
 ** A number
 ** No parts of your username (aka 'business email')
 */

export const PASSWORD_REGEX = /(?=(.*[0-9]))(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,72}/
export const passwordMustNotContains = (
  pwd: string,
  { firstName = '', lastName = '', businessEmail = '' }: Record<string, string | undefined>
) => {
  const list: string[] = [
    ...firstName.split(/[!#$%&@~'*+-\/\=\?\^\_\`\{\|\}\s]/gi),
    ...lastName.split(/[!#$%&@~'*+-\/\=\?\^\_\`\{\|\}\s]/gi),
  ]
  if (businessEmail) {
    list.push(...businessEmail.split(/[!#$%&@~'*+-\/\=\?\^\_\`\{\|\}]/gi))
  }
  return ![...list.filter(Boolean), ' ']
    .map(s => s.toLowerCase())
    .some(v => pwd?.toLowerCase()?.includes(v.toLowerCase()))
}

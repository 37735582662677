import { useDispatch, useSelector } from 'react-redux'
import { IAnalysisFilter, IAnalysisLocalFilter } from '../../../types/Filter'
import {
  selectAnalysisFilter,
  selectAnalysisLocalFilter,
  selectChipsHeader,
  selectBreadcrumbs,
  selectExportMultiple,
} from './AnalysisSelector'
import {
  ChipItem,
  IBreadcrumbsData,
  IExportMultiple,
  updateAnalysisFilter,
  updateAnalysisLocalFilter,
  updateBreadcrumbs,
  updateChipsHeader,
  updateExportMultiple,
} from './AnalysisSlice'

const useAnalysisFilter = (): [IAnalysisFilter, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectAnalysisFilter)
  const update = (state: any) => {
    dispatch(updateAnalysisFilter(state))
  }

  return [state, update]
}

const useAnalysisLocalFilter = (): [IAnalysisLocalFilter, (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectAnalysisLocalFilter)
  const update = (state: any) => {
    dispatch(updateAnalysisLocalFilter(state))
  }

  return [state, update]
}

const useChipsHeader = (): [ChipItem[], (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectChipsHeader)
  const update = (state: any) => {
    dispatch(updateChipsHeader(state))
  }

  return [state, update]
}

const useBreadcrumbs = (): [IBreadcrumbsData[], (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectBreadcrumbs)
  const update = (state: any) => {
    dispatch(updateBreadcrumbs(state))
  }

  return [state, update]
}

const useExportMultiple = (): [IExportMultiple[], (state: any) => void] => {
  const dispatch = useDispatch()
  const state = useSelector(selectExportMultiple)
  const update = (state: any) => {
    dispatch(updateExportMultiple(state))
  }

  return [state, update]
}

export {
  useAnalysisFilter,
  useAnalysisLocalFilter,
  useChipsHeader,
  useBreadcrumbs,
  useExportMultiple,
}

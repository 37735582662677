export const readFile = (url: string) => {
  return fetch(url, {
    headers: {
      'Content-Type': 'text/plain',
      Accept: 'text/plain',
    },
  })
    .then(r => r.text())
    .then(text => text)
}

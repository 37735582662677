import { ReactiveVar } from '@apollo/client'
import { IProfileFilter } from '../../../types/ProfileFilter'
import { mergeDeeps } from '../../../utils/TaxonomyMapping'

export default (setProfileFilter: ReactiveVar<IProfileFilter>) => {
  return (filter: Partial<IProfileFilter>) => {
    const newFilter = mergeDeeps(setProfileFilter(), filter)
    setProfileFilter(newFilter)
  }
}

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CardContent, { CardContentProps } from '@material-ui/core/CardContent'

const FCTCardContent = withStyles({
  root: {
    padding: '32px 16px',
    fontSize: 14,
    fontWeight: 400,
    '&:last-child': {
      paddingBottom: 10,
    },
  },
})((props: CardContentProps) => {
  return <CardContent {...props}></CardContent>
})

export default FCTCardContent

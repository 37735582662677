import { createContext, PropsWithChildren, useState, useEffect, useContext } from 'react'
import Loading from '../components/Loading'
import { useOktaAuth } from '@okta/okta-react'
import axios from 'axios'
import config from '../core/config/auth'
import mixpanel from 'mixpanel-browser'
import Flex from '../components/Flex/Flex'
import { onError } from '../utils/sentry'
import usePushNotifications, {
  EnumMessageType,
} from '../features/push-notifications/usePushNotifications'
import { useNumOfNotifications } from '../store/features/notification/NotificationHook'
import { makeStyles } from '@material-ui/core'
import { EGroupName, isAdmin, isClient, isExpired, isStartup, isTrial, IUser } from '../utils/auth'
import UpdatePoliciesContainer from '../containers/UpdatePoliciesContainer'
import CookiesClockContainer from 'containers/CookiesClockContainer'
import { LOCAL_STORAGE_FIELDS } from 'utils/consts'
import { EErrorCode } from 'components/Authentication/AuthError'
import PasswordExpiredDialog, {
  usePasswordExpired,
} from 'components/PasswordExpired/PasswordExpiredDialog'
import changePassword from 'axios/changePassword'
import differenceInDays from 'date-fns/differenceInDays'
import addDays from 'date-fns/addDays'
import { EDocumentTemplate } from 'components/Policy/types'
import ExpiryDateContainer from 'containers/ExpiryDateContainer'

const useStyles = makeStyles({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#f8fafc',
  },
})

export interface IUserContext {
  user: IUser
  shouldShowWelcome: boolean
  setShouldShowWelcome: React.Dispatch<React.SetStateAction<boolean>>
  isStartupUser: boolean
  isAdmin: boolean
  isExpired: boolean
  isTrial: boolean
  restricted: {
    search: boolean
    navbar: boolean
    watchlist: boolean
    export: {
      charts: boolean
      profile: boolean
      reports: boolean
      listView: boolean
    }
    requestUC: boolean
    requestCompany: boolean
    notifications: boolean
  }
}

export const initUserContext = {
  user: {} as IUser,
  shouldShowWelcome: false,
  setShouldShowWelcome: () => {},
  isStartupUser: false,
  isAdmin: false,
  isExpired: false,
  isTrial: false,
  restricted: {
    search: false,
    navbar: false,
    watchlist: false,
    export: {
      charts: false,
      profile: false,
      reports: false,
      listView: false,
    },
    requestUC: false,
    requestCompany: false,
    notifications: false,
  },
}

export const UserContext = createContext<IUserContext>(initUserContext)

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) throw new Error('useUserContext must be used within a UserProvider')
  return context
}

const GroupOkta = [
  'Everyone',
  'MissionPlus',
  'MissionPlus Dev',
  'Paying',
  'NonPaying',
  'FCT Prod',
  'FCT Dev',
  'FCT Internal',
  'Pentest',
]

export const GroupInternal = ['MissionPlus', 'MissionPlus Dev', 'FCT Internal', 'Pentest']

type Props = PropsWithChildren<{}>

const InitUserWrapper = (props: Props) => {
  const { authState, oktaAuth } = useOktaAuth()
  const classes = useStyles()

  const [showPrivacy, setShowPrivacy] = useState(false)
  const [showTermsOfUse, setShowTermsOfUse] = useState(false)
  const [user, setUser] = useState<IUser>()
  const [shouldShowWelcome, setShouldShowWelcome] = useState(false)
  const { increase, updateNotiStatus } = useNumOfNotifications()

  const [isLoading, setIsLoading] = useState(true)
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)

  const { initWebsocket } = usePushNotifications({
    handleMessage: event => {
      const data = JSON.parse(event.data)
      if (data.type === EnumMessageType.NOTIFY) {
        increase()
        updateNotiStatus(true)
      }
    },
  })

  const { passwordExpiredData, setPasswordExpiredData } = usePasswordExpired()

  const signOut = (withoutError: boolean = false) => {
    localStorage.setItem(
      LOCAL_STORAGE_FIELDS.SIGN_IN_UP_ERROR,
      `${EErrorCode.NOT_ASSIGN_TO_ANY_GROUPS}`
    )
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/login${withoutError ? '' : '?error=true'}`,
    })
  }

  const checkPasswordExpiredDate = (
    passwordChanged: string,
    data: { businessEmail: string; firstName: string; lastName: string }
  ) => {
    const PASSWORD_EXPIRED_AFTER_DAYS = 120,
      SHOW_PASSWORD_EXPIRED_BEFORE_DAYS = 5

    const diff = differenceInDays(
      addDays(new Date(passwordChanged), PASSWORD_EXPIRED_AFTER_DAYS),
      new Date()
    )

    if (diff <= SHOW_PASSWORD_EXPIRED_BEFORE_DAYS) {
      setPasswordExpiredData({
        ...data,
        remainingDays: diff,
      })
    }
  }

  const setMixpanelUserDetails = (user: IUser) => {
    const { id, company } = user
    const { displayName, email, firstName = '', lastName = '', companyId } = user.profile
    try {
      mixpanel.identify(id)
      mixpanel.people.set({
        $name: displayName || `${firstName} ${lastName}`,
        $email: email,
        companyId: companyId,
        companyName: company?.name,
      })
      const groups = user.groups.filter(e => !GroupOkta.includes(e.name)).map(e => e.name)
      mixpanel.set_group('GroupOkta', groups)
    } catch (err) {
      const errMsg = JSON.stringify({
        error: err,
        message: 'MixPanel error',
        displayName: displayName || `${firstName} ${lastName}`,
        email,
      })
      onError(new Error(errMsg))
    }
  }

  const checkAndShowPolicies = async (token: string, user: IUser) => {
    try {
      const { acceptedPrivacyPolicyVersions, acceptedTermsOfUseVersions } = user.profile

      const lastestVersion: {
        privacyPolicy: string | null
        termsOfUse: string | null
      } = await axios
        .get(`${config.api}/policies/lastest-version`, {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        })
        .then(response => response?.data?.data)

      setShowPrivacy(
        !(
          !!lastestVersion.privacyPolicy &&
          (acceptedPrivacyPolicyVersions || []).some(
            item => item.version == lastestVersion.privacyPolicy
          )
        )
      )

      setShowTermsOfUse(
        !!lastestVersion.termsOfUse &&
          !(acceptedTermsOfUseVersions || []).some(
            item => item.version == lastestVersion.termsOfUse
          )
      )
    } catch (err) {
      onError(err)
    }
  }

  const getUser = async (token: string) => {
    try {
      const response = await axios.get(`${config.api}/users/me`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      })
      const user = response?.data?.data as IUser

      if (isExpired(user)) {
        localStorage.setItem(
          LOCAL_STORAGE_FIELDS.SIGN_IN_UP_ERROR,
          `${EErrorCode.USER_GROUP_EXPIRED}`
        )
        oktaAuth.signOut()
        return
      }

      if (user?.status === 'ACTIVE' && !user?.profile?.pendingApproval) {
        if (!isStartup(user) && !isClient(user)) {
          signOut()
        }

        const { id, passwordChanged } = user
        const { email, firstName = '', lastName = '', companyId } = user.profile
        setUser(user)

        setShouldShowWelcome(!user?.profile?.recentCompanies?.some(c => c.id === companyId))
        localStorage.setItem('userId', id)
        localStorage.setItem('userName', `${firstName} ${lastName}`)

        // Show agree Privacy Policy / T&Cs
        await checkAndShowPolicies(token, user)

        // set user details for MixPanel
        setMixpanelUserDetails(user)

        if (passwordChanged) {
          checkPasswordExpiredDate(passwordChanged, {
            businessEmail: email,
            firstName,
            lastName,
          })
        }
      }
    } catch (error) {
      onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const token = oktaAuth.getAccessToken()
    if (token && authState?.isAuthenticated) {
      getUser(token as string)
    }
  }, [oktaAuth.getAccessToken(), authState?.isAuthenticated])

  useEffect(() => {
    const token = oktaAuth.getAccessToken()
    if (token && authState?.isAuthenticated && user && !isTrial(user)) {
      const ws = initWebsocket(token)
      return () => {
        ws?.close()
      }
    }
  }, [oktaAuth.getAccessToken(), authState?.isAuthenticated, user])

  // Not logging in yet, just render the route
  if (!authState?.isAuthenticated && !!authState) {
    return <>{props.children}</>
  }

  if (isLoading || !authState) {
    return (
      <Flex className={classes.loading}>
        <Loading />
      </Flex>
    )
  }

  if (!user) return null
  const IS_TRIAL = isTrial(user)
  const IS_STARTUP = isStartup(user)
  const IS_ADMIN = isAdmin(user)
  const IS_EXPIRED = isExpired(user)

  return (
    <UserContext.Provider
      value={{
        user,
        shouldShowWelcome,
        setShouldShowWelcome,
        isStartupUser: IS_STARTUP,
        isAdmin: IS_ADMIN,
        isExpired: IS_EXPIRED,
        isTrial: IS_TRIAL,
        restricted: {
          watchlist: IS_STARTUP,
          search: IS_STARTUP,
          navbar: IS_STARTUP,
          export: {
            charts: false,
            profile: false,
            reports: IS_TRIAL,
            listView: false,
          },
          requestUC: IS_TRIAL || IS_STARTUP,
          requestCompany: IS_TRIAL || IS_STARTUP,
          notifications: false,
        },
      }}
    >
      {props.children}

      <UpdatePoliciesContainer
        showPrivacy={showPrivacy}
        setShowPrivacy={setShowPrivacy}
        showTermsOfUse={showTermsOfUse}
        setShowTermsOfUse={setShowTermsOfUse}
        template={isStartup(user) ? EDocumentTemplate.STARTUP : EDocumentTemplate.CLIENT}
      />
      <CookiesClockContainer />

      {!!passwordExpiredData && (
        <PasswordExpiredDialog
          successMessage="You will be logged out from all devices and please login again with your new password."
          validatePasswordMaterials={{
            businessEmail: passwordExpiredData.businessEmail,
            firstName: passwordExpiredData.firstName,
            lastName: passwordExpiredData.lastName,
          }}
          remainingDays={passwordExpiredData.remainingDays}
          handleSubmit={async data => {
            await changePassword(
              {
                newPassword: data.password,
                oldPassword: data.oldPassword,
              },
              {
                headers: {
                  authorization: oktaAuth.getAccessToken(),
                },
              }
            )
            setIsPasswordChanged(true)
          }}
          handleCancel={() => {
            setPasswordExpiredData(undefined)
            isPasswordChanged && signOut(true)
          }}
        />
      )}
    </UserContext.Provider>
  )
}

export default InitUserWrapper

import { PeriodType, LocationType, ETimePeriod } from '../../utils/convert/Title'
import { InternalDbCompanyTypes } from '../../__generated__/globalTypes'

export const periodTypeOptions = [
  { value: PeriodType.Monthly, label: 'Monthly' },
  { value: PeriodType.Quarterly, label: 'Quarterly' },
  { value: PeriodType.Annually, label: 'Annually' },
]

export const locationTypeOptions = [
  { value: LocationType.Region, label: 'Region' },
  { value: LocationType.SubRegion, label: 'Sub-region' },
  { value: LocationType.SubRegion2, label: 'Sub-region 2' },
  { value: LocationType.Country, label: 'Country' },
]

export const timePeriodOptions = [
  { value: ETimePeriod.ThreeYears, label: `${ETimePeriod.ThreeYears} years` },
  { value: ETimePeriod.FiveYears, label: `${ETimePeriod.FiveYears} years` },
  { value: ETimePeriod.TenYears, label: `${ETimePeriod.TenYears} years` },
  { value: ETimePeriod.FifteenYears, label: `${ETimePeriod.FifteenYears} years` },
]

export const industryOptions = [
  { value: InternalDbCompanyTypes.all, label: 'All Industries' },
  { value: InternalDbCompanyTypes.fintech, label: 'FinTech' },
  { value: InternalDbCompanyTypes.regtech, label: 'RegTech' },
  { value: InternalDbCompanyTypes.insurtech, label: 'InsurTech' },
]

//define size limit to change class in tooltip for chart
export const LIMITED_COMPANIES_STYLE_TOOLTIP = 4

//define minimum radius to insert text into bubble chart
export const MINIMUM_BUBBLE_RADIUS = 20

//define limited characters to increase minimum radius for circle
export const LIMITED_CHARACTERS_TO_INCREASE_RADIUS = 6

//define step of radius circle
export const STEP_RADIUS_CIRCLE = 5

export const MAXIMUM_PERCENT = 100

export const NAME_EXPORT_CHART = 'chart-analysis'

import { gql } from '@apollo/client'

export const GET_SIMILAR_COMPANIES_BY_KEYWORDS = gql`
  query GetSimilarCompaniesByKeywords($input: DealListViewQueryInput!) {
    getSimilarCompaniesByKeywords(input: $input) {
      items {
        companyId
        distance
      }
      distanceStart
      distanceEnd
    }
  }
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISearchInputOption } from 'components/Search/interfaces'

export interface ISearchSlice {
  options: ISearchInputOption[]
}
const initialState: ISearchSlice = {
  options: [],
}
const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchOption: (state, action: PayloadAction<ISearchInputOption[]>) => {
      state.options = action.payload
    },
    resetSearchOption: (state, action) => {
      state.options = []
    },
  },
})
export const { updateSearchOption, resetSearchOption } = searchSlice.actions
export default searchSlice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ILocation {
  fullPath: string
  path: string
  searchQuery: string
}
export interface IRouterSlice extends ILocation {
  history: string[]
  routerName: RouterName
}
export enum RouterName {
  UNKNOWN = 'UNKNOWN',
  DASHBOARD = 'DASHBOARD',
  INVESTOR_PROFILE = 'INVESTOR_PROFILE',
}
export const routerNameMap = [
  {
    key: '/app/dashboard',
    name: RouterName.DASHBOARD,
  },
  {
    key: '/app/investor-profile',
    name: RouterName.INVESTOR_PROFILE,
  },
]

const initialState: IRouterSlice = {
  fullPath: '',
  path: '',
  searchQuery: '',
  routerName: RouterName.UNKNOWN,
  history: [],
}
const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    updateRouter: (state, action: PayloadAction<ILocation>) => {
      state.fullPath = action.payload.fullPath
      state.path = action.payload.path
      state.searchQuery = action.payload.searchQuery
    },
    updateRouterName: (state, action: PayloadAction<RouterName>) => {
      state.routerName = action.payload
    },
  },
})
export const { updateRouter, updateRouterName } = routerSlice.actions
export default routerSlice.reducer

import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { IOpenOptionFilter } from '../../store/features/filter/FilterSlice'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import { ITreeNode } from '../CheckboxTree/CheckboxTree'
import { AccordionItem } from '../Filters/AccordionItem'
import BusinessModelItem from './BusinessModelItem'
import DistributionModelItem from './DistributionModelItem'
import TagsItem from './TagsItem'
import TaxonomyChildItem from './TaxonomyChildItem'

const useStyles = makeStyles(theme => ({
  group: {
    borderBottom: '1px solid #E7ECF3',
  },
}))

const Taxonomy = (props: ITaxonomyProps) => {
  const classes = useStyles()
  const {
    filter,
    setFilter,
    getFilterData,
    enableNodesAnalysis,
    expandedNodesAnalysis,
    openOption,
    setOpenOption,
    customStyle,
  } = props

  return (
    <>
      <div className={classes.group}>
        <AccordionItem
          title="Taxonomy"
          open={openOption?.taxonomy}
          setOpen={value => setOpenOption && setOpenOption(value, 'taxonomy')}
          customStyle={customStyle}
        >
          <TaxonomyChildItem
            filter={filter}
            setFilter={setFilter}
            getFilterData={getFilterData}
            enableNodesAnalysis={enableNodesAnalysis}
            expandedNodesAnalysis={expandedNodesAnalysis}
          />

          <TagsItem filter={filter} setFilter={setFilter} getFilterData={getFilterData} />

          <BusinessModelItem filter={filter} setFilter={setFilter} getFilterData={getFilterData} />
          {/* TODO: temporary remove
          <DistributionModelItem
            filter={filter}
            setFilter={setFilter}
            getFilterData={getFilterData}
          /> */}
        </AccordionItem>
      </div>
    </>
  )
}

export default Taxonomy

interface ITaxonomyProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  enableNodesAnalysis?: ITreeNode[]
  expandedNodesAnalysis?: string[]
  openOption?: IOpenOptionFilter
  setOpenOption?: (value: boolean, tag: string) => void
  customStyle?: { title?: React.CSSProperties }
}

import { ITreeView } from 'components/Profile/Sidebar/CategoriesTags'
import { TooltipDesc } from 'components/Tabs/FCTTab'
import { IDataTags } from 'features/profile/ProfileContext'
import { IOriginalData, ITreeData } from 'types/Tree'
import {
  GetDealFilterDataResult_getDealFilterData,
  GetDealFilterDataResult_getDealFilterData_tags_tags,
} from '__generated__/GetDealFilterDataResult'
import { InternalDbCompanyTypes } from '__generated__/globalTypes'
import { ETaxonomyAvatar, TaxonomyFilterAvatar } from './FlattenEcoSystem'

export function makeTreeTaxonomy(treeNodes: ITreeView[]): ITreeView[] {
  const { res } = treeNodes.reduce(
    (acc: any, curr: any) => {
      if (acc.parentMap?.[curr?.parentId]) {
        ;(acc.parentMap[curr.parentId].children = acc.parentMap[curr.parentId].children || []).push(
          curr
        )
      } else {
        acc.res.push(curr)
      }
      acc.parentMap[curr.id] = curr
      return acc
    },
    { parentMap: {}, res: [] }
  )
  return res
}

export const convertTreeNodes = (treeNodes: ITaxonomyChangeItem[]) => {
  const data = [...treeNodes]
    ?.sort((a, b) => (a?.id || 0) - (b?.id || 0))
    ?.map(e => ({
      id: e.id,
      name: e.name,
      category: e.category,
      children: e.children,
      description: e.description,
      parentId: e.parent_id || 0,
    })) as ITreeView[]

  const res = data.reduce((acc, curr) => {
    return acc.some((e: ITreeView) => e.id === curr.id)
      ? acc.map((e: ITreeView) =>
          e.id === curr.id ? { ...e, children: curr.children || e.children } : e
        )
      : acc.concat(curr)
  }, [] as ITreeView[])

  return makeTreeTaxonomy(res)
}

export const checkDisableTaxonomy = (data: ITaxonomy[]) => {
  const dataPrimary = data.filter(e => !e.notSend).find(e => e.isPrimary)

  return (
    dataPrimary?.newValue?.industry?.id == dataPrimary?.sourceValue?.industry?.id &&
    dataPrimary?.newValue?.productLines?.id == dataPrimary?.sourceValue?.productLines?.id &&
    dataPrimary?.newValue?.businessLines?.id == dataPrimary?.sourceValue?.businessLines?.id &&
    dataPrimary?.newValue?.valueChains?.id == dataPrimary?.sourceValue?.valueChains?.id
  )
}

export interface ITaxonomy {
  id: string
  newValue: IValueTaxonomy
  sourceValue: IValueTaxonomy
  isPrimary: boolean
  reason: string
  notSend?: boolean
}

export type ITaxonomyChangeItem = {
  id: number | null
  name: string
  description?: string | null
  is_primary?: number | null
  parent_id?: number | null
  category?: string | null
  dimension?: number | null
  can_be_final?: number | null
  children?: ITaxonomyChangeItem[]
}

export type MutateDataTaxonomy<T> = T & {}

export interface IValueTaxonomy {
  industry: IIndustriesTaxonomy
  businessLines: ITaxonomyChangeItem | null
  valueChains: ITaxonomyChangeItem | null
  operatingModel: ITaxonomyChangeItem | null
  productLines: ITaxonomyChangeItem | null
  productLinesTree: ITaxonomyChangeItem[]
  valueChainsTree: ITaxonomyChangeItem[]
}

export interface IIndustriesTaxonomy {
  id: InternalDbCompanyTypes
  name: string
  description?: string | null
}

export const TOOLTIPS: Record<string, string> = {
  fintech: TooltipDesc.FinTech,
  regtech: TooltipDesc.RegTech,
  insurtech: TooltipDesc.InsurTech,
}

export const INIT_TAXONOMY_ITEM: ITaxonomyChangeItem = {
  id: null,
  name: '',
  description: '',
  is_primary: null,
  parent_id: null,
}

export const INIT_TAGS: IDataTags = {
  tags: [],
  businessModel: [],
}

// TODO: remove this const & related parts when return data has description
export const MOCK_MODEL_DESC = new Map<string, string>([
  [
    'Enabler',
    'Companies working with financial institutions and offering solutions enabling incumbent financial institutions & insurers',
  ],
  [
    'Disruptor',
    'Companies offering solutions that aim to capture market share from incumbent financial institutions & insurers',
  ],
  [
    'Enabler and Disruptor',
    'Companies offering solutions that both enable and aim to capture market share from incumbent financial institutions & insurers',
  ],
])

export enum ETagGroupsType {
  Categories = 1,
  Taxonomy = 2,
}

export const tagGroupsMapData = ({
  getFilterData,
  type = ETagGroupsType.Categories,
}: {
  getFilterData: GetDealFilterDataResult_getDealFilterData
  type?: ETagGroupsType
}) => {
  const getMapTree = (
    data: (GetDealFilterDataResult_getDealFilterData_tags_tags | null)[],
    parentId: number
  ) => {
    const dataSort = [...data]?.sort((a, b) => (a?.tag_name || '').localeCompare(b?.tag_name || ''))
    return dataSort?.map(e => ({
      id: type === ETagGroupsType.Categories ? e?.tag_id.toString() || '' : e?.tag_id || -1,
      name: e?.tag_name || '',
      description: e?.tag_desc || '',
      parentId: parentId,
      children: [],
    })) as ITreeData[]
  }

  const getMapOriginal = (
    data: (GetDealFilterDataResult_getDealFilterData_tags_tags | null)[],
    parentId: number
  ) => {
    const dataSort = [...data]?.sort((a, b) => (a?.tag_name || '').localeCompare(b?.tag_name || ''))
    return dataSort?.map(e => ({
      id: type === ETagGroupsType.Categories ? e?.tag_id.toString() || '' : e?.tag_id || -1,
      name: e?.tag_name || '',
      parentIds: [parentId],
    }))
  }

  const tagsOriginal: IOriginalData[] = []
  const data = [...(getFilterData?.tags || [])]
    ?.sort((a, b) => a?.tagGroupName?.localeCompare(b?.tagGroupName))
    ?.sort((a, b) => (b?.tagGroupName === 'Other' ? -1 : 1))

  const tagsTree: ITreeData[] = data.map(e => {
    tagsOriginal.push(...getMapOriginal(e.tags || [], e.tagGroupId))
    return {
      id: type === ETagGroupsType.Categories ? e.tagGroupId.toString() || '' : e.tagGroupId,
      parentId: null,
      name: e.tagGroupName,
      children: getMapTree(e.tags || [], e.tagGroupId) || [],
    }
  })

  const tagData = tagsOriginal.map(i => ({
    label: i.name,
    value: i.id + '',
  }))

  return { tagsTree, tagsOriginal, tagData }
}

export const getTaxonomyTags = (dataPrimary: IValueTaxonomy | null) => {
  return [
    {
      label: 'Business Line',
      color: TaxonomyFilterAvatar.get(ETaxonomyAvatar.BusinessLine)?.color,
      children: ETaxonomyAvatar.BusinessLine,
      disable: dataPrimary?.industry?.id !== InternalDbCompanyTypes.fintech,
    },
    {
      label: 'Product Line',
      color: TaxonomyFilterAvatar.get(ETaxonomyAvatar.ProductLine)?.color,
      children: ETaxonomyAvatar.ProductLine,
      disable: false,
    },
    {
      label: 'Value Chain',
      color: TaxonomyFilterAvatar.get(ETaxonomyAvatar.ValueChain)?.color,
      children: ETaxonomyAvatar.ValueChain,
      disable: dataPrimary?.industry?.id !== InternalDbCompanyTypes.insurtech,
    },
    {
      label: 'Operating Model',
      color: TaxonomyFilterAvatar.get(ETaxonomyAvatar.OperatingModel)?.color,
      children: ETaxonomyAvatar.OperatingModel,
      disable: dataPrimary?.industry?.id !== InternalDbCompanyTypes.insurtech,
    },
  ] as ITaxonomyTags[]
}

export interface ITaxonomyTags {
  label: string
  color: string | undefined
  children: ETaxonomyAvatar
  disable: boolean
}

import React, { useState, useEffect, useMemo } from 'react'
import * as d3 from 'd3'
import { makeStyles } from '@material-ui/core'
import PanBox from './PanBox.d3'
import Sunburst from './Sunburst.d3'
import ChartWrapper from '../ChartWrapper'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  sunburst: {
    flex: 1,
    height: '100%',
  },
  panBox: {
    width: '100px',
    height: '100px',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
})

const SunburstChart = ({
  data,
  activeNode,
  onSelectActiveNode,
  onActiveTooltip,
  onPinTooltip,
  isPin,
}: any) => {
  const classes = useStyles()
  const [sunburst, setSunburst] = useState<any>(null)
  const [panBox, setPanBox] = useState<any>(null)
  const [brushEvent, setBrushEvent] = useState<any>(null)
  const [zoomEvent, setZoomEvent] = useState<any>(null)
  const panBoxUpdateProps = useMemo(() => ({ data: data.data, activeNode }), [data, activeNode])

  // PanBox brush handler
  useEffect(() => {
    if (sunburst && panBox && brushEvent) {
      if (!brushEvent.sourceEvent) return // stop infinite loop
      const [[x0, y0], [x1, y1]] = brushEvent?.selection || [
        [0, 0],
        [panBox.TOTAL_WIDTH, panBox.TOTAL_HEIGHT],
      ]

      // force brush to be square
      const sideLength = Math.min(x1 - x0, y1 - y0)
      panBox.brush.move(panBox.svg, [
        [x0, y0],
        [x0 + sideLength, y0 + sideLength],
      ])

      const panBoxCenterX = (x0 + x1) / 2
      const panBoxCenterY = (y0 + y1) / 2
      const panBoxTranslationX = -(panBox.TOTAL_WIDTH / 2 - panBoxCenterX)
      const panBoxTranslationY = -(panBox.TOTAL_HEIGHT / 2 - panBoxCenterY)
      const sunburstTranslationX = panBoxTranslationX * (sunburst.TOTAL_WIDTH / panBox.TOTAL_WIDTH)
      const sunburstTranslationY =
        panBoxTranslationY * (sunburst.TOTAL_HEIGHT / panBox.TOTAL_HEIGHT)

      sunburst.zoom.transform(
        sunburst.svg,
        d3.zoomIdentity
          .scale(panBox.TOTAL_WIDTH / sideLength)
          .translate(-sunburstTranslationX, -sunburstTranslationY)
      )
    }
  }, [brushEvent])

  // Sunburst zoom handler
  useEffect(() => {
    if (sunburst && panBox && zoomEvent) {
      if (!zoomEvent.sourceEvent) return // stop infinite loop
      const t = zoomEvent.transform

      const panBoxCenterX =
        panBox.TOTAL_WIDTH / 2 - (panBox.TOTAL_WIDTH / sunburst.TOTAL_WIDTH) * t.x
      const panBoxBrushWidthX = panBox.TOTAL_WIDTH / t.k
      const panBoxCenterY =
        panBox.TOTAL_HEIGHT / 2 - (panBox.TOTAL_HEIGHT / sunburst.TOTAL_HEIGHT) * t.y
      const panBoxBrushHeightY = panBox.TOTAL_HEIGHT / t.k
      const x0 = panBoxCenterX - panBoxBrushWidthX / 2
      const x1 = panBoxCenterX + panBoxBrushWidthX / 2
      const y0 = panBoxCenterY - panBoxBrushHeightY / 2
      const y1 = panBoxCenterY + panBoxBrushHeightY / 2

      panBox.brush.move(panBox.svg, [
        [x0, y0],
        [x1, y1],
      ])
    }
  }, [zoomEvent])

  return (
    <div className={classes.container}>
      <div className={classes.sunburst}>
        <ChartWrapper
          Chart={Sunburst}
          initProps={{
            data: data.data,
            activeNode,
            onSelectActiveNode,
            setSunburst,
            setZoomEvent,
            onActiveTooltip,
            onPinTooltip,
            isPin,
          }}
          updateProps={{
            ...data,
            onSelectActiveNode,
            isPin,
          }}
          margin={{ TOP: 0, LEFT: 0, RIGHT: 0, BOTTOM: 0 }}
        />
      </div>
      <div className={classes.panBox}>
        <ChartWrapper
          Chart={PanBox}
          initProps={{ data: data.data, setPanBox, setBrushEvent, activeNode }}
          updateProps={panBoxUpdateProps}
        />
      </div>
    </div>
  )
}

export default SunburstChart

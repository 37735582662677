import { useHistory } from 'react-router'
import { LoginCallback, useOktaAuth } from '@okta/okta-react'
import { decodeObjectToURLParams, useCustomParams } from './QueryParams'
import { useEffect } from 'react'
import Flex from '../components/Flex/Flex'
import Loading from '../components/Loading'
import { makeStyles } from '@material-ui/core'
import { EErrorCode } from '../components/Authentication/AuthError'
import { LOCAL_STORAGE_FIELDS } from '../utils/consts'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#f8fafc',
  },
})

export const CallBackComponent = (props: any) => {
  const classes = useStyles()
  const history = useHistory()
  const [params, updateSearch] = useCustomParams() as [string, (searchParams: string) => void]
  const search = decodeObjectToURLParams(params, result => result)
  const { authState, oktaAuth } = useOktaAuth()
  const path = history.location.pathname

  useEffect(() => {
    localStorage.setItem('CALLBACK_ORIGIN_URL', path)
  }, [path])

  useEffect(() => {
    if (search.error) {
      if (path == '/auth/callback/register') {
        oktaAuth.signOut({
          postLogoutRedirectUri: window.location.origin + '/register',
        })
      } else {
        const isPendingApproval = !!(search.error_description == 'User status is invalid.')
        localStorage.setItem(
          LOCAL_STORAGE_FIELDS.SIGN_IN_UP_ERROR,
          `${isPendingApproval ? EErrorCode.IS_PENDING_APPROVAL : EErrorCode.UNKNOWN}`
        )
        oktaAuth.signOut({
          postLogoutRedirectUri: window.location.origin + `/login?error=true`,
        })
      }
    }
  }, [search])

  if (!authState && search.error) {
    return (
      <Flex className={classes.root}>
        <Loading />
      </Flex>
    )
  }

  return <LoginCallback {...props} />
}

import { useMutation, useQuery } from '@apollo/client'
import { Avatar, IconButton, Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import suggestCompanies from 'axios/suggestCompanies'
import clsx from 'clsx'
import mixpanel from 'mixpanel'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { cloneDeep, difference, union } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { pathnameTracking } from 'utils/Tracking'
import FCTButton from '../../components/Button/FCTButton'
import { ETypeDialog, FCTDialog } from '../../components/Dialog/FCTDialog'
import FCTIcon from '../../components/Icon/Icon'
import Loading from '../../components/Loading'
import FCTRadio from '../../components/Radio/FCTRadio'
import { FCTTooltipMove } from '../../components/Tooltip/TooltipMove'
import { ListCompaniesFilterConditions } from '../../components/WatchList/ListCompaniesFilterConditions'
import { EWatchListType } from '../../components/WatchList/SelectTabWatchList'
import { useSyncState } from '../../core/hooks/useSyncState'
import {
  ICompanyOption,
  IGetSuggestCompanies,
  SearchCompanies,
} from '../../features/search-companies-name/SearchCompanies'
import { useOktaAuth } from '@okta/okta-react'
import { GetWatchListContext } from '../../features/watch-list/GetWatchList'
import { GetWatchListSharedContext } from '../../features/watch-list/GetWatchListWereShared'
import {
  useSharePermission,
  useShareWatchList,
  useWatchListId,
  useWatchListName,
  useWatchListTab,
  useWatchListType,
} from '../../store/features/watch-list/WatchListHook'
import {
  EShareTab,
  EWatchListTab,
  initialWatchlistFilter,
} from '../../store/features/watch-list/WatchListSlice'
import { CREATE_WATCH_LIST } from '../../store/operations/mutations/SetCreateWatchList'
import { UPDATE_COMPANY_WATCH_LIST } from '../../store/operations/mutations/UpdateCompanyWatchlist'
import { GET_DEAL_FILTER_DATA } from '../../store/operations/queries/GetDealFilterData'
import { EMessageType, WatchListMessage } from '../../templates/WatchListHandling/WatchListMessage'
import { WatchListToastsError } from '../../templates/WatchListHandling/WatchListToastsError'
import {
  EToastType,
  WatchListToastsSuccess,
} from '../../templates/WatchListHandling/WatchListToastsSuccess'
import theme from '../../theme'
import { IAnalysisFilter } from '../../types/Filter'
import { commonErrorHandler } from '../../utils/CommonErrorHandler'
import { numberWithCommas } from '../../utils/convert/number'
import { getTitleShowMore, renameLogo } from '../../utils/convert/Title'
import { mergeDeeps } from '../../utils/TaxonomyMapping'
import { stringifyFilterOptionWL, TYPE_ROLE } from '../../utils/WatchList'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '../../__generated__/GetDealFilterDataResult'
import { UpdateCompanyWatchlist_updateCompanyWatchlist } from '../../__generated__/UpdateCompanyWatchlist'
import { useNavigateProfile } from './../../utils/navigationHook'
import ConfirmDisableFilterDialog from './ConfirmDisableFilterDialog'
import { WatchListFilterConditions } from './WatchListFilterConditions'
import Flex from 'components/Flex/Flex'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex',
    height: 'calc(78vh - 140px)',
  },
  item: {
    flex: '1 0 0%',
    padding: '20px',
  },
  listCompanies: {
    height: 'calc(100% - 110px)',
    display: 'flex',
    flexDirection: 'column',
  },
  listSelected: {
    flex: 3,
    overflowY: 'auto',
  },
  listSuggested: {
    marginTop: 10,
    flex: 2,
    overflowY: 'auto',
  },
  descSuggested: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: 8,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  titleSelected: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    justifyContent: 'space-between',
  },
  titleText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    marginRight: 8,
    padding: '8px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  titleInput: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    marginRight: 8,
    border: '1px solid #29BA74',
    borderRadius: '3px',
    padding: '7px 8px',
    flex: 1,
    '&:focus, &:active': {
      background: '#FFFFFF',
      outline: 'none',
    },
    //Chrome, Firefox, Opera, Safari 10.1+
    '&::placeholder': {
      color: theme.palette.grey[500],
      fontSize: 14,
      fontWeight: 400,
    },
    //Internet Explorer 10-11
    '&:-ms-input-placeholder': {
      color: theme.palette.grey[500],
      fontSize: 14,
      fontWeight: 400,
    },
    //Microsoft Edge
    '&::-ms-input-placeholder': {
      color: theme.palette.grey[500],
      fontSize: 14,
      fontWeight: 400,
    },
  },
  titleGroup: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  decs: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[500],
    marginBottom: '6px',
  },
  containerItem: {
    minHeight: '56px',
    display: 'flex',
    padding: '7px 14px',
    boxSizing: 'border-box',
    border: '1px solid #FFFFFF',
    cursor: 'pointer',
    position: 'relative',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[50]}`,
    },
    '&:hover': {
      background: '#FFFFFF',
      border: '1px solid #E7ECF3',
      boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    },
    '&:hover $deleteCompany': {
      visibility: 'visible',
    },
    '&:hover $mutateAction': {
      visibility: 'visible',
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  avatar: {
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    width: 24,
    height: 24,
    fontSize: 10,
  },
  content: {
    fontWeight: 600,
    textAlign: 'left',
    alignSelf: 'center',
    fontSize: 14,
    lineHeight: '22px',
  },
  webCompany: {
    display: 'table',
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    padding: 0,
    margin: 0,
    color: '#6C809D',
    '&:hover': {
      textDecoration: 'underline',
    },
    maxWidth: 280,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  link: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  deleteCompany: {
    alignItems: 'center',
    display: 'flex',
    visibility: 'hidden',
    transition: 'all 0.05s ease-in-out',
  },
  wrapperIcon: {
    position: 'absolute',
    top: 8,
    right: 1,
    alignItems: 'center',
    display: 'flex',
  },
  mutateAction: {
    visibility: 'hidden',
    transition: 'all 0.05s ease-in-out',
  },
  border: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      left: 'calc(100% - 0.5px)',
      top: 0,
      bottom: 0,
      height: '100%',
      width: '1px',
      backgroundColor: '#E7ECF3',
      content: '""',
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: 'calc(50% - 8px)',
    padding: '2px 3px',
    justifyContent: 'start',
    fontSize: '14px',
    lineHeight: '22px',
  },
  active: {
    border: '1px solid #29BA74',
  },
  icon: {
    minWidth: 'auto',
    padding: 0,
    width: '40px',
    height: '40px',
  },
  searchInput: {
    margin: '10px 0 12px',
  },
  buttonHeader: {
    minWidth: 40,
    height: 40,
    padding: 0,
  },
  optionSelect: {
    display: 'flex',
    padding: '8px 14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[50]}`,
    },
    alignItems: 'center',
    textDecoration: 'none',
    flexDirection: 'row',
  },
  createListText: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 500,
    marginLeft: 8,
  },
  btnCreateEmpty: {
    margin: '24px auto 0px',
    padding: '9px 14px',
  },
  totalCompanies: {
    alignItems: 'center',
    minWidth: 50,
    display: 'flex',
    marginLeft: 10,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  totalText: {
    display: 'flex',
  },
  textNavigate: {
    color: theme.palette.primary.light,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  dialogConfirmContent: {
    padding: 20,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 'normal',
  },
  arrowIcon: {
    width: 12,
    height: 9,
    marginRight: 10,
  },
}))
let isPause = false

export const CreateNewWatchList = (props: CreateNewWatchListProps) => {
  const classes = useStyles()
  const { handleComplete = () => {}, type = ETypeAction.Create, listCompanies = [] } = props
  const { addToast } = useToasts()
  const { navigateProfile } = useNavigateProfile()
  const history = useHistory()
  const { oktaAuth } = useOktaAuth()
  const token = oktaAuth?.getAccessToken()

  const userName = localStorage.getItem('userName')

  const getWatchList = useContext(GetWatchListContext)
  const getWatchListShared = useContext(GetWatchListSharedContext)

  const [watchListId, __] = useWatchListId()
  const [watchListName, ______] = useWatchListName()
  const [watchListTab, ___] = useWatchListTab()
  const [sharePermission, ____] = useSharePermission()
  const [_____, setShareWatchList] = useShareWatchList()
  const [_, searchValue, setSearchValue] = useSyncState('')

  const [wLName, setWLName] = useState<string>('Untitled')
  const [selected, setSelected] = useState<ICompanyOption[]>([])
  const [listCompany, setListCompany] = useState<ICompanyOption[]>([])
  const [suggested, setSuggested] = useState<ICompanyOption[]>([])
  const [suggestedQueue, setSuggestedQueue] = useState<IGetSuggestCompanies[]>([])
  const [ignoreSuggested, setIgnoreSuggested] = useState<ICompanyOption[]>([])
  const [toggleEditWLName, setToggleEditWLName] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [option, setOption] = useState(EWatchListType.companyIDs)
  const [open, setOpen] = useState<boolean>(false)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState<IAnalysisFilter>(initialWatchlistFilter)
  const [listCompanyIds, setWatchListCompanyIds] = useState<(number | null)[]>([])
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [watchListType, setWatchListType] = useWatchListType()

  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA)
  const filterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData

  const inputEl = useRef(null)

  const setWatchListFilterCreating = (values: Object) => {
    setFilter(mergeDeeps(filter, values))
  }

  const getSuggestCompanies = useCallback(
    async (id: number | string) => {
      await suggestCompanies([+id], token).then(async (res: any) => {
        const dataSuggestCompanies = await suggestCompaniesMapping([
          ...((res?.data?.data?.companies || []) as IGetSuggestCompanies[]),
          ...suggestedQueue,
        ])
        if (!suggestedQueue.length) setSuggestedQueue([])
        setSuggested(prev => handleSortSuggestCompanies(prev, dataSuggestCompanies))
      })
    },
    [selected, suggestedQueue]
  )

  const suggestCompaniesMapping = (data: IGetSuggestCompanies[]) => {
    return data?.map(e => ({
      id: e?.similarCompanyId,
      name: e?.similarCompanyName || '',
      logoUrl: e?.logoBucketUrl || '',
      url: e?.similarCompanyWebsiteUrl || '',
      total: null,
      rank: e?.rankCompany || 0,
      inputId: [e.inputCompanyId],
      fintechScore: e?.fintechScore || 0,
      occurrences: 1,
    })) as ICompanyOption[]
  }

  const handleSortSuggestCompanies = (dataOrigin: ICompanyOption[], dataPush: ICompanyOption[]) => {
    let res = cloneDeep(dataOrigin)
    dataPush.forEach(item => {
      const ids = res.map(e => e.id)
      if (ids.includes(item.id))
        res = [...res].map(e =>
          e.id == item.id
            ? {
                ...e,
                occurrences: (e.occurrences || 0) + 1,
                inputId: union(e.inputId, item.inputId),
              }
            : e
        )
      else res = [item, ...res]
    })

    return res.sort(
      (a, b) =>
        (b?.occurrences || 0) - (a?.occurrences || 0) ||
        (b?.fintechScore || 0) - (a?.fintechScore || 0) ||
        (a?.rank || 0) - (b?.rank || 0)
    )
  }

  const getRelevanceOfCompany = (occurrence = 0) => {
    const percent = (occurrence / (selected.length + listCompanies.length)) * 100
    if (percent >= 30) return 3
    else if (percent >= 20) return 2
    else if (percent >= 10) return 1
    else return 0
  }

  const handleIgnoreSuggest = (data: ICompanyOption[], selected: ICompanyOption[]) => {
    const ignoreIds = [...selected.map(e => e.id), ...listCompanies.map(e => +e)]
    return data
      .filter(e => !ignoreIds.includes(e.id))
      .slice(0, 3)
      .map(item => ({ ...item, relevance: getRelevanceOfCompany(item.occurrences) }))
  }

  const callSuggestListCompanies = (index: number) => {
    const nextIndex = index + (index === 0 ? 5 : 50)
    suggestCompanies(listCompanies.slice(index, nextIndex), token).then(async res => {
      const data = (res?.data?.data?.companies as IGetSuggestCompanies[]) || []
      if (index == 0) {
        const dataSort = handleSortSuggestCompanies([], suggestCompaniesMapping(data))
        setSuggested(dataSort)
      } else setSuggestedQueue(prev => [...prev, ...data])
      if (nextIndex < listCompanies.length && isPause) callSuggestListCompanies(nextIndex)
    })
  }

  useEffect(() => {
    if (open) {
      setSelected([])
      setSuggested([])
      setIgnoreSuggested([])
      setSuggestedQueue([])
      if (type === ETypeAction.Update) {
        isPause = true
        callSuggestListCompanies(0)
      }
      setSearchValue('')
      setWLName(type === ETypeAction.Update ? watchListName! : 'Untitled')
      setToggleEditWLName(false)
      setTimeout(() => {
        inputEl && (inputEl?.current as any)?.focus()
      }, 100)
    }
  }, [open])

  const enableEditWLName = () => {
    setToggleEditWLName(false)
  }

  const changeToggleEditWLName = () => {
    setToggleEditWLName(true)
  }

  const handleEditWLName = (event: any) => {
    setWLName(event.target.value)
  }

  const addWLCompany = (val: ICompanyOption) => {
    getSuggestCompanies(val.id)
    setSearchValue('')
    const check = selected.find(e => e.id === val.id)
    if (!check) setSelected([val, ...selected])
  }

  const removeWLCompany = useCallback(
    (company: ICompanyOption) => {
      setSelected(selected.filter(item => item.id !== company.id))
      const newSuggested = suggested
        .map(e =>
          e.inputId?.includes(company.id)
            ? {
                ...e,
                occurrences: (e?.occurrences || 0) - 1,
                inputId: difference(e.inputId, [company.id]),
              }
            : e
        )
        .filter(e => e?.occurrences && e.occurrences > 0)

      if (suggestedQueue.length) {
        const dataMapping = handleSortSuggestCompanies(
          newSuggested,
          suggestCompaniesMapping(suggestedQueue)
        )
        setSuggestedQueue([])
        setSuggested(dataMapping)
      } else setSuggested(handleSortSuggestCompanies(newSuggested, []))
    },
    [suggestedQueue, suggested, selected]
  )

  const [createWatchList, { loading: createLoading, error }] = useMutation(CREATE_WATCH_LIST)
  const [updateWatchList, { loading: updateLoading }] = useMutation(UPDATE_COMPANY_WATCH_LIST)
  const handleAgree = useCallback(
    (option: EWatchListType) => {
      createWatchList({
        variables: {
          input: {
            watchlistName: wLName,
            companyIds:
              option == EWatchListType.companyIDs ? selected.map(e => +e.id) : listCompanyIds,
            filterOption:
              option == EWatchListType.companyIDs
                ? null
                : stringifyFilterOptionWL(filter, filterData),
          },
        },
      })
        .then(res => {
          const pathname = history.location.pathname
          mixpanel.track(`${pathnameTracking(pathname)}_Create Watchlist`, {
            watchlistId: res?.data?.createWatchlist,
            watchlistName: wLName,
            watchlistSaveType: option == EWatchListType.filterConditions ? 'Filter' : 'ID',
          })
          addToast(
            <WatchListToastsSuccess
              type={EToastType.NormalAddCompaniesToWL}
              selectedList={
                option == EWatchListType.companyIDs ? selected : listCompanyIds.map(e => String(e))
              }
              watchListName={wLName}
            />,
            {
              appearance: 'success',
            }
          )
          setOpen(false)
          getWatchList.fnWatchList()
          handleComplete()
        })
        .catch(
          commonErrorHandler((err: any) => {
            addToast(<WatchListToastsError res={err.graphQLError.message} />, {
              appearance: 'error',
            })
          })
        )
    },
    [wLName, selected, listCompanyIds]
  )

  const handleAddWList = useCallback(
    option => {
      let updatePromise = updateWatchList({
        variables: {
          input: {
            watchlistId: watchListId,
            companyIds:
              option == EWatchListType.filterConditions ? listCompanyIds : selected.map(e => +e.id),
          },
        },
      })
      updatePromise
        .then(res => {
          const resData = res?.data
            ?.updateCompanyWatchlist as UpdateCompanyWatchlist_updateCompanyWatchlist
          addToast(
            <WatchListToastsSuccess
              type={
                !!resData?.duplicated
                  ? EToastType.AddPartOfCompaniesToWl
                  : EToastType.NormalAddCompaniesToWL
              }
              res={res}
              selectedList={
                selected.length ? selected : listCompanyIds.map(idx => (idx ? idx.toString() : ''))
              }
              watchListName={wLName}
            />,
            { appearance: 'success' }
          )
          setWatchListType(EWatchListType.companyIDs)
          setOpen(false)
          watchListTab === EWatchListTab.MyWatchLists && getWatchList.fnWatchList()
          watchListTab === EWatchListTab.Shared && getWatchListShared.fnWatchListShared()
          handleComplete()
        })
        .catch(
          commonErrorHandler((err: any) => {
            addToast(<WatchListToastsError res={err.graphQLError.message} />, {
              appearance: 'error',
            })
          })
        )
    },
    [wLName, selected, listCompanyIds]
  )

  const handleAction = () => {
    if (type === ETypeAction.Create || type === ETypeAction.CreateEmpty) handleAgree(option)
    else handleAddWList(option)
  }

  const handleAgreeConfirm = () => {
    setOpenConfirm(false)
    setOpen(true)
  }

  const handleSetListCompany = (options: ICompanyOption[]) => {
    const newOptions = options?.filter(option => !selected?.find(elm => elm?.id === option?.id))
    setListCompany(newOptions)
  }

  const handleRequest = () => {
    setShareWatchList({
      open: true,
      shareTab: EShareTab.TeamMembers,
    })
  }

  const hasAddCompanies =
    TYPE_ROLE[sharePermission.roleKey || 'OWNER'].addCompanies ||
    !(watchListTab === EWatchListTab.Shared)

  return (
    <>
      {(() => {
        switch (type) {
          case ETypeAction.Create:
            return (
              <div
                onClick={() => {
                  setOpen(true)
                }}
                className={classes.optionSelect}
              >
                <FCTIcon
                  name="plusBorder"
                  width={16}
                  height={16}
                  color={theme.palette.primary.light}
                />
                <div className={classes.createListText}>Create New</div>
              </div>
            )
          case ETypeAction.Update:
            return (
              <div style={{ marginLeft: 20 }}>
                <FCTTooltipMove
                  text={
                    !hasAddCompanies ? (
                      <>
                        Not available in View access. Click to request for{' '}
                        <span className={classes.textNavigate} onClick={handleRequest}>
                          Edit Access
                        </span>
                      </>
                    ) : (
                      'Add companies'
                    )
                  }
                  isMove={false}
                  isHover={!hasAddCompanies}
                >
                  <FCTButton
                    variant="outlined"
                    className={classes.buttonHeader}
                    onClick={() => (watchListType ? setOpenConfirm(true) : setOpen(true))}
                    disabled={!hasAddCompanies}
                  >
                    <FCTIcon
                      name="addBuilding"
                      width={16}
                      height={16}
                      color={
                        hasAddCompanies ? theme.palette.primary.light : theme.palette.grey[400]
                      }
                    />
                  </FCTButton>
                </FCTTooltipMove>
              </div>
            )
          case ETypeAction.CreateEmpty:
            return (
              <FCTButton
                className={classes.btnCreateEmpty}
                variant="outlined"
                onClick={() => setOpen(true)}
              >
                <FCTIcon
                  name="plusBorder"
                  width={16}
                  height={16}
                  color={theme.palette.primary.light}
                  style={{ marginRight: '8px' }}
                />
                <div className={classes.createListText}>Create New</div>
              </FCTButton>
            )
          default:
            return null
        }
      })()}

      <ConfirmDisableFilterDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleAgree={handleAgreeConfirm}
        watchListName={watchListName}
      />

      <FCTDialog
        open={open}
        setOpen={v => {
          if (!v) isPause = false
          setOpen(v)
        }}
        title={
          type === ETypeAction.Create || type === ETypeAction.CreateEmpty
            ? 'Create a New Watchlist'
            : 'Add to Watchlist'
        }
        type={ETypeDialog.Default}
        titleBtnAgree={type === ETypeAction.Create ? 'Create' : 'Confirm'}
        handleAgree={handleAction}
        disableBtnAgree={
          (option == EWatchListType.companyIDs && !selected.length) ||
          (option == EWatchListType.filterConditions && (total >= 10000 || total == 0)) ||
          createLoading ||
          updateLoading ||
          toggleEditWLName
        }
        itemStyle={{
          width: 813,
        }}
        hasCloseOutside={false}
        loadingBtnAgree={
          createLoading ||
          updateLoading ||
          getWatchList.refetchLoading ||
          getWatchListShared.refetchLoading
        }
      >
        <div className={classes.dialogContent}>
          <div className={clsx(classes.item, classes.border)}>
            <div className={classes.buttonGroup}>
              <FCTButton
                variant="outlined"
                className={
                  option == EWatchListType.companyIDs
                    ? clsx(classes.button, classes.active)
                    : classes.button
                }
                onClick={() => setOption(EWatchListType.companyIDs)}
              >
                <FCTTooltipMove text="Track only the companies selected" isMove={false}>
                  <FCTRadio checked={option == EWatchListType.companyIDs} />
                  Company IDs
                </FCTTooltipMove>
              </FCTButton>

              <FCTButton
                variant="outlined"
                className={
                  option == EWatchListType.filterConditions
                    ? clsx(classes.button, classes.active)
                    : classes.button
                }
                onClick={() => setOption(EWatchListType.filterConditions)}
              >
                <FCTTooltipMove
                  text="Automatically update watchlist based on the conditions selected in filter"
                  isMove={false}
                >
                  <FCTRadio
                    checked={option == EWatchListType.filterConditions}
                    disabled={type == ETypeAction.CreateEmpty}
                  />
                  Filter Conditions
                </FCTTooltipMove>
              </FCTButton>
            </div>
            {option == EWatchListType.companyIDs && (
              <>
                <div className={classes.searchInput}>
                  <SearchCompanies
                    handleData={handleSetListCompany}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setLoading={setLoadingSearch}
                  />
                </div>
                <div className={classes.listCompanies}>
                  <div className={clsx(classes.listSelected, classes.scroll)}>
                    {loadingSearch ? (
                      <Loading style={{ width: 30, height: 30 }} />
                    ) : (
                      <RenderItem
                        listData={listCompany}
                        addWLCompany={addWLCompany}
                        navigateProfile={navigateProfile}
                      ></RenderItem>
                    )}
                  </div>
                  <div className={classes.descSuggested}>FCT suggestions</div>
                  <RenderItem
                    listData={handleIgnoreSuggest(suggested, [...selected, ...ignoreSuggested])}
                    addWLCompany={addWLCompany}
                    navigateProfile={navigateProfile}
                    isSuggested={true}
                    setIgnoreSuggested={setIgnoreSuggested}
                  ></RenderItem>
                </div>
              </>
            )}
            {option == EWatchListType.filterConditions && (
              <WatchListFilterConditions
                open={open}
                filter={filter}
                setWatchListFilterCreating={setWatchListFilterCreating}
              />
            )}
          </div>
          <div className={classes.item} style={{ overflowY: 'auto' }}>
            <div className={classes.titleSelected}>
              <div className={classes.titleGroup}>
                {type === ETypeAction.Update ? (
                  <Typography className={classes.titleText}>{wLName || 'New Watchlist'}</Typography>
                ) : !toggleEditWLName ? (
                  <>
                    <Typography className={classes.titleText}>
                      {wLName || 'New Watchlist'}
                    </Typography>
                    <IconButton size="small" onClick={changeToggleEditWLName}>
                      <FCTIcon
                        name="edit"
                        width={16}
                        height={16}
                        color={theme.palette.primary.light}
                      />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <input
                      ref={inputEl}
                      className={classes.titleInput}
                      value={wLName}
                      onChange={handleEditWLName}
                      placeholder={'Untitled'}
                      onBlur={() => !!wLName.trim() && enableEditWLName()}
                    />
                    <FCTButton
                      variant="outlined"
                      onClick={enableEditWLName}
                      className={classes.icon}
                      disabled={!wLName.trim()}
                    >
                      <FCTIcon
                        name="checked"
                        width={16}
                        height={16}
                        color={
                          !wLName.trim() ? theme.palette.grey[400] : theme.palette.primary.light
                        }
                      />
                    </FCTButton>
                  </>
                )}
              </div>
              <div className={classes.totalCompanies}>
                <div className={classes.totalText}>
                  <FCTIcon name="companyWatchlist" width={13} />
                  <span style={{ fontSize: '12px', margin: '2px 5px 0px' }}>
                    {numberWithCommas(
                      option == EWatchListType.companyIDs ? selected?.length : total
                    )}
                  </span>
                </div>
                {(option == EWatchListType.companyIDs ? selected?.length : total) >= 10000 && (
                  <FCTTooltipMove
                    text={
                      <span>
                        You have exceeded the limit of <b>10,000 companies</b> for watchlist. To
                        continue, please delete some companies
                      </span>
                    }
                    isMove={false}
                  >
                    <FCTIcon name="warningCircle" width={13} style={{ marginTop: 2 }} />
                  </FCTTooltipMove>
                )}
              </div>
            </div>
            {option != EWatchListType.filterConditions ? (
              !selected.length ? (
                <Typography className={classes.decs}>
                  <WatchListMessage type={EMessageType.CreateNew} />
                </Typography>
              ) : (
                <>
                  {selected.map((e, key) => (
                    <div className={classes.containerItem} key={key}>
                      <div className={classes.link}>
                        <div className={classes.logoWrapper} onClick={() => navigateProfile(+e.id)}>
                          <Avatar
                            alt={e.name || ''}
                            variant="square"
                            src={e.logoUrl || ''}
                            className={classes.avatar}
                            children={renameLogo(e.name)}
                          />
                        </div>

                        <div className={classes.content}>
                          <span onClick={() => navigateProfile(+e.id)}>{e.name}</span>
                          <Link
                            underline="none"
                            href={e.url}
                            target="_blank"
                            className={classes.webCompany}
                          >
                            {getTitleShowMore(e.url || '', 40)}
                          </Link>
                        </div>
                      </div>
                      <div className={clsx(classes.deleteCompany)}>
                        <IconButton onClick={() => removeWLCompany(e)}>
                          <FCTIcon
                            name="cancel"
                            width={14}
                            height={14}
                            color={theme.palette.error.main}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </>
              )
            ) : (
              <ListCompaniesFilterConditions
                filter={filter}
                setTotal={total => setTotal(total)}
                totalCompany={total}
                setWatchListCompanyIds={listIds => setWatchListCompanyIds(listIds)}
              />
            )}
          </div>
        </div>
      </FCTDialog>
    </>
  )
}

export const RenderItem = ({
  listData,
  addWLCompany,
  navigateProfile,
  isSuggested,
  setIgnoreSuggested = () => {},
}: {
  listData: ICompanyOption[]
  addWLCompany: (v: ICompanyOption) => void
  navigateProfile: (v: number) => void
  isSuggested?: boolean
  setIgnoreSuggested?: React.Dispatch<React.SetStateAction<ICompanyOption[]>>
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(isSuggested ? classes.listSuggested : classes.listSelected, classes.scroll)}
    >
      {listData.map((e, key) => (
        <div
          className={classes.containerItem}
          onClick={() => {
            if (!isSuggested) {
              addWLCompany(e)
            }
          }}
          key={key}
        >
          <div className={classes.link}>
            <div
              className={classes.logoWrapper}
              onClick={event => {
                navigateProfile(+e.id)
                event.stopPropagation()
              }}
            >
              <Avatar
                alt={e.name || ''}
                variant="square"
                src={e.logoUrl || ''}
                className={classes.avatar}
                children={renameLogo(e.name)}
              />
            </div>

            <div className={classes.content}>
              <span
                onClick={event => {
                  navigateProfile(+e.id)
                  event.stopPropagation()
                }}
              >
                {e.name}
              </span>
              <Link
                underline="none"
                href={e.url}
                target="_blank"
                onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                  event.stopPropagation()
                }}
                className={classes.webCompany}
              >
                {getTitleShowMore(e.url || '', 40)}
              </Link>
            </div>

            {isSuggested && (
              <Flex className={classes.wrapperIcon}>
                <Flex className={classes.mutateAction}>
                  <IconButton onClick={() => addWLCompany(e)}>
                    <FCTIcon
                      name="plus"
                      width={14}
                      height={14}
                      color={theme.palette.primary.light}
                    />
                  </IconButton>
                  <IconButton onClick={() => setIgnoreSuggested(prev => [...prev, e])}>
                    <FCTIcon name="minus" width={14} height={14} color={theme.palette.error.main} />
                  </IconButton>
                </Flex>
                {/* TODO: temporary remove in EFDP-1871
                <Flex
                  flexDirection={'column'}
                  style={{
                    marginLeft: 5,
                    width: 22,
                  }}
                >
                  {Array.from({ length: e.relevance || 0 }).map((_, index) => (
                    <FCTIcon
                      name="arrowtop"
                      className={classes.arrowIcon}
                      color={theme.palette.grey[500]}
                      key={index}
                    />
                  ))}
                </Flex> */}
              </Flex>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export interface CreateNewWatchListProps {
  handleComplete?: () => void
  type?: ETypeAction
  listCompanies?: string[]
}

export enum ETypeAction {
  Create,
  Update,
  CreateEmpty,
}

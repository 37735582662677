import { Theme, withStyles } from '@material-ui/core'
import { TreeItem, TreeItemProps } from '@material-ui/lab'

const FCTTreeItem = withStyles((theme: Theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed #dddd`,
  },
  label: {
    backgroundColor: 'transparent!important',
  },
}))((props: TreeItemProps) => <TreeItem {...props} />)

export default FCTTreeItem

import { NetworkStatus, useApolloClient, useQuery } from '@apollo/client'
import { omit } from 'lodash'
import React, { createContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { EWatchListType } from '../../components/WatchList/SelectTabWatchList'
import { useCompanyDealFilter } from '../../store/features/company-deal/CompanyDealHook'
import {
  useSharePermission,
  useWatchListFilter,
  useWatchListId,
  useWatchListName,
  useWatchListType,
} from '../../store/features/watch-list/WatchListHook'
import { GET_WATCH_LIST_WERE_SHARED } from '../../store/operations/queries/GetWatchlistWereShared'
import { onError } from '../../utils/sentry'
import {
  GetWatchlistWereShared,
  GetWatchlistWereShared_getWatchlistWereShared,
} from '../../__generated__/GetWatchlistWereShared'

import { parseFilterOptionWL } from './../../utils/WatchList'

export const initGetWLSharedContext = {
  dataWatchListShared: [],
  fnWatchListShared: async () => {},
  loading: true,
  refetchLoading: false,
}

export const GetWatchListSharedContext = createContext<ICreateContextState>(initGetWLSharedContext)

export const GetWatchListWereShared = (props: { children: React.ReactNode }) => {
  const history = useHistory()
  const hasLogin = Boolean(Number(localStorage.getItem('login')))
  const client = useApolloClient()

  const [watchListId, setWatchListId] = useWatchListId()
  const [__, setWatchListName] = useWatchListName()
  const [___, setWatchListType] = useWatchListType()
  const [____, setSharePermission] = useSharePermission()
  const [_____, setWatchListFilter] = useWatchListFilter()
  const [______, setCompanyDealFilter] = useCompanyDealFilter()

  const {
    data: myWatchlist,
    loading,
    refetch: fnWatchListShared,
    networkStatus,
  } = useQuery<GetWatchlistWereShared>(GET_WATCH_LIST_WERE_SHARED, {
    notifyOnNetworkStatusChange: true,
  })

  const dataWatchListShared = myWatchlist?.getWatchlistWereShared as GetWatchlistWereShared_getWatchlistWereShared[]
  const refetchLoading = networkStatus === NetworkStatus.refetch
  const currentPath = history.location.pathname

  const setDefault = (data: GetWatchlistWereShared_getWatchlistWereShared) => {
    setWatchListId(data?.watchlistId)
    setWatchListName(data?.watchlistName)
    if (data?.filterOption) {
      setWatchListType(EWatchListType.filterConditions)
      const newFilter = parseFilterOptionWL(data?.filterOption)
      setWatchListFilter(newFilter)
      if (currentPath.includes('/watch-lists') || currentPath.includes('/investor-profile'))
        setCompanyDealFilter({ ...newFilter })
    } else {
      setWatchListType(EWatchListType.companyIDs)
    }
    setSharePermission({
      needRequest: data?.needRequest,
      roleId: data?.roleId,
      roleKey: data?.roleKey,
      sharedBy: data?.sharedBy,
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.query<GetWatchlistWereShared>({
          query: GET_WATCH_LIST_WERE_SHARED,
        })

        const data = res?.data?.getWatchlistWereShared
        const defaultWL = data?.find(e => e.isDefault === true)
        const watchListUrl = data?.find(e => e.watchlistId === watchListId)

        if (hasLogin && !!defaultWL) {
          setDefault(defaultWL)
          localStorage.setItem('login', '0')
        }

        if (!!watchListUrl && !!watchListId) {
          setDefault(watchListUrl)
        }
      } catch (err) {
        onError(err)
      }
    }

    fetchData()
  }, [])

  return (
    <GetWatchListSharedContext.Provider
      value={{
        dataWatchListShared,
        fnWatchListShared: async () => {
          await fnWatchListShared()
        },
        loading,
        refetchLoading,
      }}
    >
      {props.children}
    </GetWatchListSharedContext.Provider>
  )
}

interface ICreateContextState {
  dataWatchListShared: GetWatchlistWereShared_getWatchlistWereShared[]
  fnWatchListShared: () => Promise<void>
  loading: boolean
  refetchLoading: boolean
}

import { Entity } from 'dto-mapping/lib/decorators/entity'
import { SafeType } from 'dto-mapping/lib/decorators/safeType'
import { IRealValue } from '../components/Slider/FCTSlider'
import { ETimePeriod, LocationType, PeriodType } from '../utils/convert/Title'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../__generated__/globalTypes'
import { EModeName, ICompanySearchData, ISmartCluster, SearchCompany } from './CompanyDealFilter'

export interface IAnalysisFilter {
  category: InternalDbCompanyTypes
  businessLines: number[] | null
  risks: number[] | null
  valueChains: number[] | null
  products: number[] | null
  operatingModels: number[] | null
  regions: string[] | null
  subRegions: string[] | null
  subRegion2s: string[] | null
  countries: number[] | null
  businessModelIds: number[] | null
  distributionModelIds: number[] | null
  fundingRound1s: number[]
  fundingRound2s: number[]
  viewBy: GlobalFilterViewBy
  selectedProductId: number | null
  selectedBusinessLineId: number | null
  searchData?: ICompanySearchData | null
  companyStatuses: number[] | null
  //new
  productParentId: number | null
  numOfEmployeesRanges: IRealValue
  switchFemalePicker: ESwitchFemale
  numOfFemaleFounderFrom: string | null
  numOfFemaleFounderTo: string | null
  numOfFemaleFounderPercentFrom: string | null
  numOfFemaleFounderPercentTo: string | null
  yearFoundedRange: boolean
  foundedYearFrom: string[] | null
  foundedYearTo: string[] | null
  latestFundingRound1s: number[]
  latestFundingRound2s: number[]
  totalFundingFrom: string | null
  totalFundingTo: string | null
  equityFundingFrom: string | null
  equityFundingTo: string | null
  latestDealAmountFrom: string | null
  latestDealAmountTo: string | null
  dealAmountFrom: string | null
  dealAmountTo: string | null
  yearFundingRange: boolean
  fundingDateFrom: string | null
  fundingDateTo: string | null
  investors: string[]
  investorsTypes: string[]
  leadInvestors: string[]
  leadInvestorTypes: string[]
  numberOfInvestorsRanges: IRealValue
  numberOfFundingRounds: IRealValue
  acquirerTypes: string[]
  acquirers: string[]
  acquisitionYearRange: boolean
  acquiredDateFrom: string | null
  acquiredDateTo: string | null
  tagGroupIds: number[] | null
  includedEntities: string[] | null
  wentPublicOnDateFrom: string | null
  wentPublicOnDateTo: string | null
  smartCluster: ISmartCluster
  modeName: EModeName | null
}

export enum ESwitchFemale {
  LeastFemale = 1,
  CustomCount = 2,
  CustomDistribution = 3,
}

export const LABLE_SWITCH_FEMALE = new Map<ESwitchFemale, string>([
  [ESwitchFemale.LeastFemale, 'At least 1 Female Founder'],
  [ESwitchFemale.CustomCount, 'Custom Count'],
  [ESwitchFemale.CustomDistribution, 'Custom Distribution'],
])

export enum CountrySortBy {
  countCompany = 'countCompany',
  countFunding = 'countFunding',
  totalFunding = 'totalFunding',
}

export interface ICustomizeDate {
  dateFrom: string
  dateTo: string
}

export interface IAnalysisLocalFilter {
  periodType: PeriodType
  locationType: LocationType
  timePeriod: ETimePeriod
  breakdownStatus: boolean
  isFunding: boolean
  customizeDate: ICustomizeDate
}

@Entity()
export class AnalysisLocalFilter {
  periodType?: PeriodType | null
  locationType?: LocationType | null
  timePeriod?: ETimePeriod | null
  @SafeType({ type: Boolean })
  breakdownStatus?: boolean
  @SafeType({ type: Boolean })
  isFunding?: boolean
  customizeDate?: ICustomizeDate

  constructor(filters: any) {}
}

@Entity()
export class AnalysisFilter {
  @SafeType({ type: Number })
  businessLines?: number[] | null
  @SafeType({ type: Number })
  products?: number[] | null

  @SafeType({ type: Number })
  operatingModels?: number[] | null

  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  @SafeType({ type: Number })
  countries?: number[] | null
  @SafeType({ type: Number })
  businessModelIds?: number[] | null
  @SafeType({ type: Number })
  distributionModelIds?: number[] | null
  @SafeType({ type: Number })
  fundingRound1s?: number[] | null
  @SafeType({ type: Number })
  fundingRound2s?: number[] | null
  category?: InternalDbCompanyTypes
  viewBy?: GlobalFilterViewBy
  @SafeType({ type: Number })
  risks?: number[] | null
  @SafeType({ type: Number })
  valueChains?: number[] | null
  @SafeType({ type: Number })
  selectedProductId?: number | null
  @SafeType({ type: Number })
  selectedBusinessLineId?: number | null
  @SafeType({ type: SearchCompany })
  searchData?: SearchCompany
  @SafeType({ type: Number })
  companyStatuses?: number[] | null
  @SafeType({ type: Number })
  tagGroupIds?: number[] | null

  @SafeType({ type: Number })
  productParentId?: number
  numOfEmployeesRanges?: IRealValue

  @SafeType({ type: Number })
  switchFemalePicker?: ESwitchFemale

  numOfFemaleFounderFrom?: string | null
  numOfFemaleFounderTo?: string | null
  numOfFemaleFounderPercentFrom?: string | null
  numOfFemaleFounderPercentTo?: string | null

  @SafeType({ type: Boolean })
  yearFoundedRange?: boolean
  foundedYearFrom?: string[] | null
  foundedYearTo?: string[] | null
  @SafeType({ type: Number })
  latestFundingRound1s?: number[]
  @SafeType({ type: Number })
  latestFundingRound2s?: number[]
  totalFundingFrom?: string | null
  totalFundingTo?: string | null
  equityFundingFrom?: string | null
  equityFundingTo?: string | null
  latestDealAmountFrom?: string | null
  latestDealAmountTo?: string | null
  dealAmountFrom?: string | null
  dealAmountTo?: string | null

  @SafeType({ type: Boolean })
  yearFundingRange?: boolean
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  investors?: string[]
  investorsTypes?: string[]
  leadInvestors?: string[]
  leadInvestorTypes?: string[]
  numberOfInvestorsRanges?: IRealValue
  numberOfFundingRounds?: IRealValue
  acquirerTypes?: string[]
  acquirers?: string[]
  includedEntities?: string[]

  @SafeType({ type: Boolean })
  acquisitionYearRange?: boolean
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  smartCluster?: ISmartCluster
  modeName?: EModeName | null

  // Using filters: any type for converting type from any to CompanyFilters
  constructor(filters: any) {}
}

export interface IViewBy {
  displayName: string
  propName: string
  name: string
}

export type IViewByCompanyTypesMap = {} & Record<InternalDbCompanyTypes, IViewBy>

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  makeStyles,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import SubmitButton from 'components/Authentication/BusinessEmail/SubmitButton'
import { IField, FormField } from 'components/Authentication/FormField/FormField'
import { DateRangePickerController } from 'components/DateRangePickerController/DateRangePickerController'
import Flex from 'components/Flex/Flex'
import FCTIcon from 'components/Icon/Icon'
import FCTRadio from 'components/Radio/FCTRadio'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import theme from 'theme'
import * as yup from 'yup'

export interface IBCGDetailsFormData {
  email: string
  firstName: string
  lastName: string
  caseCode: string
  partnerName: string
  clientName: string
  numberOfLogin: string
  caseText: string
  isTier: string
  fromDate: string | null
  toDate: string | null
}

const useStyles = makeStyles((theme: Theme) => ({
  formElement: {
    marginTop: '30px',
  },
  input: {
    width: '100%',
    borderRadius: 3,
    paddingRight: 'unset',
    height: 68,
    backgroundColor: theme.palette.grey[100],
    fontSize: 14,
    '& .MuiFilledInput-input': {
      paddingLeft: 17,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[100],
    },
    '&:focus': {
      backgroundColor: 'unset',
    },
    '&.MuiFilledInput-underline::before': {
      content: 'unset ',
      position: 'unset',
    },
    '&.MuiFilledInput-underline::after': {
      content: 'unset',
      position: 'unset',
    },
  },
  switchLink: {
    color: `${theme.palette.primary.light}`,
    marginLeft: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: `${theme.palette.grey[800]}`,
  },
  isTier: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 12,
  },
  labelRadio: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  yourDetail: {
    color: theme.palette.grey[600],
    textAlign: 'left',
    marginBottom: 16,
  },
  companyDetail: {
    color: theme.palette.grey[600],
    textAlign: 'left',
    marginTop: 32,
  },
  calculated: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.grey[600],
    borderTop: `1px solid #E7ECF3`,
    paddingTop: 20,
  },
  btnChange: {
    color: theme.palette.primary.light,
    textTransform: 'unset',
    marginBottom: 28,
    paddingRight: 20,
    '& span': {
      marginLeft: '5px',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  labelEmail: {
    color: theme.palette.grey[600],
    fontSize: 14,
    fontWeight: 400,
    marginTop: 4,
    marginLeft: 6,
  },
  mb30: {
    marginBottom: 30,
  },
}))

type Props = {
  handleChangeEmail(): void
  handleSubmit: (data: IBCGDetailsFormData) => void | Promise<void>
  email: string
}

const BCGDetails = ({ handleChangeEmail, handleSubmit, email }: Props) => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
  } = useForm<IBCGDetailsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: email,
      firstName: '',
      lastName: '',
      caseCode: '',
      partnerName: '',
      clientName: '',
      numberOfLogin: '',
      caseText: '',
      isTier: 'Yes',
      fromDate: null,
      toDate: null,
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required('This field is required.'),
        firstName: yup.string().required('This field is required.'),
        lastName: yup.string().required('This field is required.'),
        caseCode: yup.string().required('This field is required.'),
        partnerName: yup.string().required('This field is required.'),
        clientName: yup.string().required('This field is required.'),
        numberOfLogin: yup.string().required('This field is required.'),
        fromDate: yup
          .string()
          .required('This field is required.')
          .test('Date format', 'Invalid date format', v => {
            return v !== 'Invalid Date'
          }),
        toDate: yup
          .string()
          .required('This field is required.')
          .test('Date format', 'Invalid date format', v => {
            return v !== 'Invalid Date'
          }),
        caseText: yup.string().required('This field is required.'),
        isTier: yup.string().required(''),
      })
    ),
  })

  const onBlurField = (field: IField<IBCGDetailsFormData>) => {
    const value = control.getValues(field.name)
    typeof value === 'string' && control.setValue(field.name, value?.trim() || '')
  }

  return (
    <div>
      <form
        id="getAccessBCGDetailsForm"
        onSubmit={async e => {
          try {
            setLoading(true)
            await handleSubmitForm(d => handleSubmit(d))(e)
          } finally {
            setLoading(false)
          }
        }}
      >
        <Typography className={clsx(classes.yourDetail, classes.title)}>Your Details</Typography>

        <FormControl style={{ width: '100%' }}>
          <InputLabel htmlFor="filled-adornment" className={classes.labelEmail} variant="filled">
            Email Address
          </InputLabel>
          <Controller
            control={control}
            rules={{ required: true }}
            name="email"
            render={props => (
              <FilledInput
                disabled
                fullWidth
                className={classes.input}
                value={props.value}
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      onClick={() => handleChangeEmail()}
                      style={{ minWidth: 'unset' }}
                      className={classes.btnChange}
                    >
                      <FCTIcon color={theme.palette.primary.light} name="edit" />
                      <Typography component="span">Change</Typography>
                    </Button>
                  </InputAdornment>
                }
              />
            )}
          />
        </FormControl>

        <Flex gap="12px">
          {([
            { name: 'firstName', label: 'First Name', placeholder: 'Enter first name' },
            { name: 'lastName', label: 'Last Name', placeholder: 'Enter last name' },
          ] as IField<IBCGDetailsFormData>[]).map(field => (
            <div key={field.name} className={classes.formElement}>
              <FormField
                control={control}
                field={field}
                error={errors[field.name]}
                onBlur={() => onBlurField(field)}
              />
            </div>
          ))}
        </Flex>

        <Typography className={clsx(classes.companyDetail, classes.title)}>Case Details</Typography>

        <Flex gap="12px">
          {([
            { name: 'caseCode', label: 'Case Code', placeholder: 'Enter case code' },
            {
              name: 'partnerName',
              label: 'BCG Partner(s) Name',
              placeholder: 'Enter partner’s name',
            },
          ] as IField<IBCGDetailsFormData>[]).map(field => (
            <div key={field.name} className={classes.formElement}>
              <FormField
                control={control}
                field={field}
                error={errors[field.name]}
                onBlur={() => onBlurField(field)}
              />
            </div>
          ))}
        </Flex>

        <Flex gap="12px" className={classes.mb30}>
          {([
            { name: 'clientName', label: 'Client Name', placeholder: 'Enter client name' },
            {
              name: 'numberOfLogin',
              label: 'Enter number of logins',
              placeholder: 'Enter number of logins',
              type: 'number',
            },
          ] as IField<IBCGDetailsFormData>[]).map(field => (
            <div key={field.name} className={classes.formElement}>
              <FormField
                control={control}
                field={field}
                error={errors[field.name]}
                onBlur={() => onBlurField(field)}
              />
            </div>
          ))}
        </Flex>

        <DateRangePickerController
          filter={{
            fromDate: control.getValues('fromDate'),
            toDate: control.getValues('toDate'),
          }}
          setFilter={values => {
            if ('fromDate' in values) {
              control.setValue('fromDate', values.fromDate)
            }
            if ('toDate' in values) {
              control.setValue('toDate', values.toDate)
            }
          }}
          label={{ fromDate: 'Period of Use From Date', toDate: 'To date' }}
          placeholder="Select date"
          keys={{ fromDate: 'fromDate', toDate: 'toDate' }}
          control={control}
          errors={{ fromDate: errors['fromDate']?.message, toDate: errors['toDate']?.message }}
        />

        {([
          {
            name: 'caseText',
            label: 'Case Context',
            placeholder: 'Description of the case requirements',
          },
        ] as IField<IBCGDetailsFormData>[]).map(field => (
          <div key={field.name} className={classes.formElement}>
            <FormField
              control={control}
              field={field}
              error={errors[field.name]}
              onBlur={() => onBlurField(field)}
            />
          </div>
        ))}

        <Typography className={classes.isTier}> Is it Tier 1?</Typography>

        <div style={{ marginBottom: 20 }}>
          <Controller
            rules={{ required: true }}
            control={control}
            name="isTier"
            defaultValue="yes"
            render={params => (
              <RadioGroup {...params}>
                <Flex>
                  <FormControlLabel
                    className={classes.labelRadio}
                    style={{ marginRight: 30 }}
                    value="Yes"
                    control={<FCTRadio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    className={classes.labelRadio}
                    value="No"
                    control={<FCTRadio />}
                    label="No"
                  />
                </Flex>
              </RadioGroup>
            )}
          />
        </div>

        <Divider />

        <SubmitButton
          form="getAccessBCGDetailsForm"
          disabled={!isValid}
          text="Submit"
          loading={loading}
        />
      </form>
    </div>
  )
}

export default BCGDetails

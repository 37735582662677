import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useSubFilter } from 'store/features/company-deal/CompanyDealHook'
import theme from '../../theme'
import FCTCheckbox from '../Checkbox/FCTCheckbox'
import { TooltipDescription } from '../Tooltip/TooltipDescription'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    paddingBottom: 13,
  },
  checkBox: {
    padding: 0,
    marginRight: 9,
  },
  checkboxText: {
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.grey[800],
  },
  infoDescription: {
    opacity: 0,
  },
  titleName: {
    '&:hover': {
      cursor: 'pointer',
      '& $infoDescription': {
        opacity: 1,
      },
    },
    display: 'flex',
    alignItems: 'center',
  },
}))

export const FilterItem = (props: any) => {
  const classes = useStyles()
  const [subFilter, _____] = useSubFilter()
  const changeValue = (e: any, v: any) => {
    let filterIds: any[] = []
    if (v) {
      filterIds = [...props.filterIds, props.id]
    } else {
      filterIds = props.filterIds.filter((el: any) => el != props.id)
    }

    props.setFilterIds(filterIds)
  }

  const disableCheckBox = (id: number) => {
    if (props?.hasDisable && subFilter?.currentCompanyName) return true
    return props.enableNode ? !props.enableNode.includes(props.id) : false
  }

  return (
    <>
      <label key={props.id} className={classes.root}>
        <FCTCheckbox
          className={classes.checkBox}
          checked={
            !!props?.filterIds?.filter((el: any) => {
              return el == props.id
            }).length
          }
          onChange={changeValue}
          disabled={disableCheckBox(props.id)}
        />
        <div className={classes.titleName}>
          <Typography className={classes.checkboxText}>{props.name}</Typography>
          <TooltipDescription
            description={props.description}
            style={classes.infoDescription}
            isDescription={props.isDescription}
            id={props.id}
          />
        </div>
      </label>
    </>
  )
}

import {
  makeStyles,
  Theme,
  withStyles,
  List as MuiList,
  ListItem,
  ListItemText as MuiListItemText,
  ListSubheader as MuiListSubheader,
  Divider,
} from '@material-ui/core'
import { CompanyListItem } from '../../../containers/CompanyListContainer'
import { ICompanyDetailsData } from './CompanyDetailsForm'
import SubmitButton from './SubmitButton'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '3px',
    height: '50px',
    fontSize: '14px',
    border: 'none',
    outline: 'none',
    width: '100%',
    cursor: 'pointer',
    marginTop: '30px',
    '&:disabled': {
      opacity: 0.8,
    },
  },
  secondaryBtn: {
    backgroundColor: `#FFF`,
    color: '#000',
    marginTop: '12px',
  },
  pointer: {
    cursor: 'pointer',
  },
}))

const Confirmation = ({
  companyDetails,
  companyInfo,
  businessEmail,
  handleSubmit,
  loadingSubmit,
  onBack,
}: {
  companyInfo: CompanyListItem
  companyDetails: ICompanyDetailsData
  businessEmail: string
  handleSubmit(): void
  loadingSubmit?: boolean
  onBack?(): void
}) => {
  const classes = useStyles()
  return (
    <>
      <List subheader={<ListSubheader>Company Info</ListSubheader>}>
        <Divider />
        <ListItem>
          <ListItemText secondary={'Company Name'} primary={companyInfo.name} />
        </ListItem>
        <ListItem>
          <ListItemText secondary={'Company URL'} primary={companyInfo.websiteUrl} />
        </ListItem>
        <ListItem>
          <ListItemText secondary={'Business Email'} primary={businessEmail} />
        </ListItem>
      </List>
      <List subheader={<ListSubheader>Company Details</ListSubheader>}>
        <Divider />
        <ListItem>
          <ListItemText
            secondary={'What best describes your product?'}
            primary={companyDetails.productDesc}
          />
        </ListItem>
        <ListItem>
          <ListItemText secondary={'Are you a:'} primary={companyDetails.category} />
        </ListItem>
        <ListItem>
          <ListItemText secondary={'Company Description'} primary={companyDetails.description} />
        </ListItem>
      </List>
      <SubmitButton loading={loadingSubmit} onClick={() => handleSubmit()} />
      {onBack && (
        <button
          className={clsx(classes.button, classes.secondaryBtn)}
          onClick={() => onBack()}
          type="button"
          disabled={loadingSubmit}
        >
          Back
        </button>
      )}
    </>
  )
}
export default Confirmation

const ListItemText = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& .MuiListItemText-primary': {
      fontSize: '14px',
      lineHeight: '22px',
      color: theme.palette.grey[900],
      wordBreak: 'break-word',
    },
    '& .MuiListItemText-secondary': {
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.palette.grey[600],
      wordBreak: 'break-word',
    },
  },
}))(MuiListItemText)

const List = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '3px',
    marginTop: '24px',
  },
}))(MuiList)

const ListSubheader = withStyles(theme => ({
  root: {
    textAlign: 'left',
    fontWeight: 700,
    color: theme.palette.grey[900],
    lineHeight: '22px',
    paddingTop: '9px',
    paddingBottom: '9px',
    borderTopRightRadius: '3px',
    borderTopLeftRadius: '3px',
  },
}))(MuiListSubheader)

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ChartTimeType {
  annually = 'annually',
  monthly = 'monthly',
  pre2000 = 'pre2000',
  quarterly = 'quarterly',
}

export enum ConditionCombination {
  and = 'and',
  or = 'or',
}

export enum EDealType {
  Funding = 'Funding',
  IPO = 'IPO',
  MA = 'MA',
}

export enum FundingHeatMapReportType {
  countDeal = 'countDeal',
  totalFunding = 'totalFunding',
}

export enum GlobalFilterViewBy {
  business = 'business',
  operatingModel = 'operatingModel',
  product = 'product',
  risk = 'risk',
  valuechain = 'valuechain',
}

export enum InternalDbCompanyTypes {
  PrivateEquity = 'PrivateEquity',
  all = 'all',
  fintech = 'fintech',
  insurtech = 'insurtech',
  out = 'out',
  regtech = 'regtech',
}

export interface AcquisitionItemInput {
  price?: string | null
  status?: string | null
  comment?: string | null
  acquisition_date?: string | null
  investors: string[]
  reason: string
}

export interface AddDownloadHistoryInput {
  listDownloadHistories?: DownloadHistoryInputItem[] | null
  isTrial: boolean
}

export interface AppendAcquisitionInvestorInput {
  company_id: number
  acquisitions: AppendAcquisitionInvestorItemInput[]
}

export interface AppendAcquisitionInvestorItemInput {
  acquisition_id: string
  reason?: string | null
  investors: string[]
}

export interface AppendCompanyAcquisitionInput {
  companyId: number
  acquisitions: AcquisitionItemInput[]
}

export interface AppendCompanyAliasInput {
  company_id: number
  company_aliases: CompanyAliasInput[]
}

export interface AppendCompanyFundingInput {
  companyId: number
  fundings: FundingItemInput[]
}

export interface AppendCompanyPeopleInput {
  companyId: number
  records: AppendCompanyPeopleItemInput[]
}

export interface AppendCompanyPeopleItemInput {
  name?: string | null
  gender?: string | null
  imageUrl?: string | null
  facebook?: string | null
  linkedin?: string | null
  twitter?: string | null
  jobTitle?: string | null
  description?: string | null
  numExits?: string | null
  reason: string
  numFoundedOrganizations?: number | null
  emailAddress?: string | null
  lgbtqIdentity?: string | null
  ethnicity?: string | null
  hashedImage?: string | null
}

export interface AppendCompanyProfileInput {
  companyId: number
  profiles: AppendCompanyProfileItemInput[]
}

export interface AppendCompanyProfileItemInput {
  profile_type_id: string
  new_value: string[]
  reason: string
}

export interface AppendCompanyTechnologyInput {
  company_id: number
  technologies: AppendCompanyTechnologyItemInput[]
}

export interface AppendCompanyTechnologyItemInput {
  technology_type_id: number
  values: string[]
  reason?: string | null
}

export interface AppendCurrentClientInput {
  companyId: number
  currentClients: (AppendCurrentClient_CurrentClient | null)[]
}

export interface AppendCurrentClient_CurrentClient {
  name?: string | null
  logo_bucket_url?: string | null
  url?: string | null
  client_id?: string | null
  reason?: string | null
}

export interface AppendFinancialLicensesInput {
  company_id: number
  financial_licenses: AppendFinancialLicensesItemInput[]
}

export interface AppendFinancialLicensesItemInput {
  license_type: string
  license_jurisdiction: string
  reason?: string | null
}

export interface AppendFundingInvestorInput {
  company_id: number
  fundings: AppendFundingInvestorItemInput[]
}

export interface AppendFundingInvestorItemInput {
  funding_id: string
  lead_investors: string[]
  investors: string[]
  reason?: string | null
}

export interface AppendFundraisingInput {
  company_id: number
  reason?: string | null
  fundraising: AppendFundraisingItemInput
}

export interface AppendFundraisingItemInput {
  pitch_deck_bucket_key?: string | null
  is_fundraising?: boolean | null
  proceeds_utilization?: string | null
  investor_relations_contact?: string | null
}

export interface AppendInvestorInput {
  investors: AppendInvestorItemInput[]
}

export interface AppendInvestorItemInput {
  investor_name?: string | null
  investor_type?: string | null
  reason?: string | null
}

export interface AppendLocationsInput {
  companyId: number
  locations: LocationsInput[]
}

export interface AppendTechnologyCertificationInput {
  company_id: number
  certifications: AppendTechnologyCertificationItemInput[]
}

export interface AppendTechnologyCertificationItemInput {
  certification: string
  certification_upload_bucket_key: string
  reason?: string | null
}

export interface AppendTechnologyProviderInput {
  company_id: number
  technology_providers: AppendTechnologyProviderItemInput[]
}

export interface AppendTechnologyProviderItemInput {
  name: string
  description?: string | null
  reason?: string | null
  technology_provider_id?: number | null
}

export interface AppendUseCaseInput {
  companyId: number
  useCases: AppendUseCaseItemInput[]
}

export interface AppendUseCaseItemInput {
  use_case_type_id: number
  use_case_value: string[]
  reason?: string | null
}

export interface ApproveShareAccessInput {
  watchlistId: number
  userRequestId: string
  requestTypeId: number
  requestId: number
}

export interface AtlasViewQueryInput {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  operatingModels?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChangeRequestFundingItem {
  funding_id: string
  round_2_id?: number | null
  amount_usd?: string | null
  funding_date?: string | null
  fct_status_id?: number | null
  company_id?: string | null
}

export interface ChartAcquirerTypeFilter {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  type: string
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterEquityFundingByCagr {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  timePeriod?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  locationType?: string | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterEquityFundingByGeography {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  type?: string | null
  locationType?: string | null
  regionNames?: string[] | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  megaRoundsOnly?: boolean | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterIposByGeography {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  type?: string | null
  locationType?: string | null
  regionNames?: string[] | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterIposProductLine {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  type: string
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  getAllTimeData?: boolean | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterMaByGeography {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  type?: string | null
  locationType?: string | null
  regionNames?: string[] | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterMaProductLine {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  type: string
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  getAllTimeData?: boolean | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterTrendsByProductNames {
  filterInput?: ChartFilterTrendsByProductNamesInput | null
  limit?: number | null
}

export interface ChartFilterTrendsByProductNamesInput {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  type?: string | null
  locationType?: string | null
  regionNames?: string[] | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterTrendsByAlerts {
  filterInput?: ChartFilterTrendsByAlertsInput | null
  limit?: number | null
}

export interface ChartFilterTrendsByAlertsInput {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  type?: string | null
  locationType?: string | null
  regionNames?: string[] | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface ChartFilterWithYearPeriod {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  fromYear: number
  toYear: number
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  timePeriod: number
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface CompanyAliasInput {
  company_alias: string
  reason: string
}

export interface CompanyKeywordInput {
  companyIds: (number | null)[]
}

export interface CompanyListViewQueryInput {
  descriptionKeywords?: string[] | null
  descriptionKeywordsCombination?: ConditionCombination | null
  category: InternalDbCompanyTypes
  industry?: string | null
  businessLinesRisksValueChains?: number[] | null
  products?: number[] | null
  operatingModels?: number[] | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  companyIds?: number[] | null
  tagIds?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  selectedColumns?: string[] | null
  pageNumber?: number | null
  pageSize?: number | null
  orderBy?: SortInfo[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  watchListId?: number | null
  search?: string | null
  notificationId?: number | null
  includedEntities?: string[] | null
  currentCompanyId?: number | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  isInvestment?: boolean | null
  keywords?: string | null
  similarCompaniesByKeywords?: similarCompaniesByKeywordsItem[] | null
  viewBy?: string | null
  hasFilterSmartCluster?: boolean | null
}

export interface CompanyNewsChartSentimentInput {
  companyId: number
  timeFrame: number
  type: string
}

export interface CompanyProductsInput {
  company_id: number
  product_names: string[]
  pageIndex: number
  pageSize: number
}

export interface CompanyRedFlagsInput {
  companyId: number
  pageIndex: number
  pageSize: number
  fromDate?: string | null
  toDate?: string | null
}

export interface CompanySimilarInput {
  companyId: number
  companyIds?: number[] | null
}

export interface ContactUsInput {
  lastName?: string | null
  firstName?: string | null
  contactContent?: string | null
  userType?: string | null
}

export interface CopyWatchlistInput {
  watchListIdDuplicate?: number | null
  watchlistName?: string | null
}

export interface CountByFemaleFounderQueryInput {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  fintechTypes?: number | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  timePeriod: number
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  locationType?: string | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface CountByGeoChartQueryInput {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface CreateChangeRequestFundingInput {
  reason: string
  funding: ChangeRequestFundingItem
}

export interface CreateChangeRequestItemInput {
  companyId: number
  id: string
  tableName: string
  columnName: string
  reason: string
  newValue?: string | null
}

export interface CreateRequestShareInput {
  watchlistId: number
  roleId: number
}

export interface CreateWatchlistInput {
  watchlistName?: string | null
  companyIds?: number[] | null
  filterOption?: any | null
}

export interface DashboardRecentlyViewedCompanyInput {
  companyIds?: number[] | null
}

export interface DealListViewQueryInput {
  descriptionKeywords?: string[] | null
  descriptionKeywordsCombination?: ConditionCombination | null
  category: InternalDbCompanyTypes
  industry?: string | null
  businessLinesRisksValueChains?: number[] | null
  products?: number[] | null
  operatingModels?: number[] | null
  fintechTypes?: number | null
  companyStatuses?: number[] | null
  distributionModelIds?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  companyIds?: number[] | null
  tagIds?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  selectedColumns?: string[] | null
  pageNumber: number
  pageSize: number
  orderBy?: SortInfo[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  watchListId?: number | null
  search?: string | null
  notificationId?: number | null
  includedEntities?: string[] | null
  currentCompanyId?: number | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  dealType?: EDealType | null
  similarCompaniesByKeywords?: similarCompaniesByKeywordsItem[] | null
  keywords?: string | null
  distance?: Distance | null
  hasFilterSmartCluster?: boolean | null
  viewBy?: string | null
}

export interface DeclineChangeRequestsInput {
  company_id: number
  dataOverrideIds: number[]
  reason?: string | null
}

export interface DeclineChangeRequestsTagsInput {
  reject?: number[] | null
  reason?: string | null
  companyId: number
}

export interface DeclineChangeRequestsTaxonomiesInput {
  company_id: number
  rejectIds: number[]
  reason?: string | null
}

export interface DeclineShareAccessInput {
  watchlistId: number
  userRequestId: string
  requestId: number
}

export interface DeleteCurrentClientInput {
  companyId: number
  ids: number[]
  reason?: string | null
}

export interface Distance {
  start?: number | null
  end?: number | null
}

export interface DownloadHistoryInputItem {
  type: number
  name: string
  sub_type: string
  filter_option?: any | null
  properties?: any | null
}

export interface EditFinancialLicensesInput {
  company_id: number
  financial_license: EditFinancialLicensesItemInput[]
}

export interface EditFinancialLicensesItemInput {
  financial_service_license_id?: number | null
  license_type?: string | null
  license_jurisdiction?: string | null
  fct_status_id?: number | null
  reason?: string | null
}

export interface EditPeopleInput {
  company_id: number
  people: EditPeopleInput_People[]
}

export interface EditPeopleInput_People {
  people_id: string
  name?: string | null
  gender?: string | null
  linkedin?: string | null
  facebook?: string | null
  twitter?: string | null
  num_exits?: number | null
  job_title?: string | null
  description?: string | null
  num_founded_organizations?: number | null
  email_address?: string | null
  fct_status_id?: string | null
  hashed_image?: string | null
  reason?: string | null
}

export interface EditProfileInput {
  profile_item?: EditProfileItemInput[] | null
  company_id: number
}

export interface EditProfileItemInput {
  profile_id?: number | null
  profile_value?: string | null
  fct_status_id?: number | null
  reason?: string | null
}

export interface EditTagsProfileInput {
  companyId: number
  tags?: TaxonomyTagsInput | null
}

export interface EditTaxonomyProfileInput {
  companyId: number
  dimensions?: (TaxonomyDimension | null)[] | null
  categories?: (TaxonomyCategory | null)[] | null
  primaryReason?: string | null
  auxiliaryReason?: string | null
}

export interface EquityFundingByFundingAmountChartFilter {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  type: ChartTimeType
  megaRoundsOnly?: boolean | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  getAllTimeData?: boolean | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface EquityFundingChartFilter {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  type: ChartTimeType
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  locationType?: string | null
  includedEntities?: string[] | null
  getAllTimeData?: boolean | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface FilterBy {
  key?: string | null
  value?: string | null
}

export interface FundingHeatMapInput {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  timeType: ChartTimeType
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  reportBy: FundingHeatMapReportType
  customizeDate?: HeadMapCustomizeDateFilter | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface FundingItemInput {
  round_2_id?: number | null
  investment?: string | null
  valuation?: string | null
  comment?: string | null
  date: string
  lead_investors: string[]
  investors: string[]
  reason: string
}

export interface GetCompanyBusinessInput {
  companyId: number
}

export interface GetCompanyListInput {
  pageSize?: number | null
  pageNumber?: number | null
  filterBy?: (FilterBy | null)[] | null
  companyIds?: number[] | null
  isFundingIncluded?: boolean | null
  isGeographyIncluded?: boolean | null
}

export interface GetCompanyNewsInput {
  companyId: number
  pageSize: number
  pageNumber: number
  orderBy?: SortInfo[] | null
  date?: string | null
}

export interface GetCompanyPartnershipsInput {
  id: number
  pageSize: number
  page: number
  partnerNames: string[]
}

export interface GetCurrentClientsQueryInput {
  pageNumber?: number | null
  pageSize?: number | null
  orderBy?: SortInfo[] | null
  companyId: number
}

export interface GetDealFilterInput {
  countrySortBy?: string | null
}

export interface GetDownloadHistoryInput {
  type: number
  isTrial: boolean
}

export interface GetInvestorDealsInput {
  companyIds: number[]
  selectedColumns: string[]
  isInvestment: boolean
  category: string
}

export interface GetInvestorProfileInput {
  investorId?: string | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  tagIds?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  includedEntities?: string[] | null
  category?: string | null
  watchListId?: number | null
  companyIds?: number[] | null
}

export interface GetInvestorsFilterDataInput {
  search?: string | null
  types?: string[] | null
  isAcquirer?: boolean | null
  pageNumber?: number | null
  pageSize?: number | null
  ids?: string[] | null
}

export interface GetReportByIssueNumberInput {
  issueNumber: string
  isTrial: boolean
}

export interface GetReportsInput {
  name?: string | null
  description?: string | null
  isTrial: boolean
}

export interface GetSignUrlPeopleImagesInput {
  people_id: string
  hashed_image: string
}

export interface GetSignedLinkUrlInput {
  data_type: string
  content_types: string[]
  put_id?: string | null
  put_hash_image?: string | null
}

export interface GetSignedUrlInput {
  data_type: string
  ids: string[]
}

export interface HeadMapCustomizeDateFilter {
  dateMaxFrom?: string | null
  dateMaxTo?: string | null
  dateMinFrom?: string | null
  dateMinTo?: string | null
}

export interface ListRequestsInput {
  watchlistId: number
  userId: string
}

export interface LocationsInput {
  country: string
  city: string
  is_headquarters?: boolean | null
  reason: string
}

export interface MapViewQueryInput {
  businessLines?: number[] | null
  riskValueChains?: number[] | null
  products?: number[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  tagIds?: number[] | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  category: InternalDbCompanyTypes
  viewBy: GlobalFilterViewBy
  topN: number
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  companyIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  watchListId?: number | null
  includedEntities?: string[] | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  operatingModels?: number[] | null
  hasFilterSmartCluster?: boolean | null
}

export interface NewsPortalInput {
  descriptionKeywords?: string[] | null
  descriptionKeywordsCombination?: ConditionCombination | null
  category: InternalDbCompanyTypes
  industry?: string | null
  businessLinesRisksValueChains?: number[] | null
  products?: number[] | null
  operatingModels?: number[] | null
  fintechTypes?: number | null
  distributionModelIds?: number[] | null
  companyStatuses?: number[] | null
  numOfEmployeesRanges?: string[] | null
  foundedYearFrom?: number | null
  foundedYearTo?: number | null
  equityFundingFrom?: number | null
  equityFundingTo?: number | null
  totalFundingFrom?: number | null
  totalFundingTo?: number | null
  investorTypes?: string[] | null
  investors?: string[] | null
  acquirerTypes?: string[] | null
  acquirers?: string[] | null
  regions?: string[] | null
  subRegions?: string[] | null
  subRegion2s?: string[] | null
  countries?: number[] | null
  fundingDateFrom?: string | null
  fundingDateTo?: string | null
  acquiredDateFrom?: string | null
  acquiredDateTo?: string | null
  fundingRound1s?: number[] | null
  fundingRound2s?: number[] | null
  latestFundingRound1s?: number[] | null
  latestFundingRound2s?: number[] | null
  companyIds?: number[] | null
  tagIds?: number[] | null
  latestDealSizeFrom?: number | null
  latestDealSizeTo?: number | null
  leadInvestorTypes?: string[] | null
  leadInvestors?: string[] | null
  dealAmountFrom?: number | null
  dealAmountTo?: number | null
  numOfInvestorsFrom?: number | null
  numOfInvestorsTo?: number | null
  numOfFundingRoundsFrom?: number | null
  numOfFundingRoundsTo?: number | null
  numOfFemaleFounderFrom?: number | null
  numOfFemaleFounderTo?: number | null
  numOfFemaleFounderPercentFrom?: number | null
  numOfFemaleFounderPercentTo?: number | null
  selectedColumns?: string[] | null
  pageNumber: number
  pageSize: number
  orderBy?: SortInfo[] | null
  selectedProductId?: number | null
  selectedBusinessLineId?: number | null
  watchListId?: number | null
  search?: string | null
  notificationId?: number | null
  includedEntities?: string[] | null
  currentCompanyId?: number | null
  wentPublicOnDateFrom?: string | null
  wentPublicOnDateTo?: string | null
  dealType?: EDealType | null
  hasFilterSmartCluster?: boolean | null
}

export interface NotificationInput {
  groupId: number
  pageNumber?: number | null
  pageSize?: number | null
  unRead?: number | null
  watchlistId?: number | null
}

export interface PaginationInput {
  page?: number | null
  size?: number | null
}

export interface RemoveCompanyWatchlistInput {
  watchlistId: number
  companyIds?: number[] | null
}

export interface RequestSearchType {
  requestType?: string | null
  companyUrl?: string | null
  suggestion?: string | null
}

export interface RequestUseCaseInput {
  companyId: number
  companyName: string
  companyUrl?: string | null
}

export interface SearchCurrentClientsInput {
  pageNumber: number
  pageSize: number
  orderBy?: SortInfo[] | null
  searchString?: string | null
  ignoreClientIds?: number[] | null
}

export interface SearchInvestorInput {
  query?: string | null
  pagination: PaginationInput
}

export interface SearchTechnologyProviderInput {
  search_phrase: string
}

export interface SetWatchlistDefaultInput {
  watchlistId: number
  isDefault?: boolean | null
}

export interface SettingWatchlistNotificationInput {
  listSettings?: SettingWatchlistNotificationItem[] | null
}

export interface SettingWatchlistNotificationItem {
  watchlistId: number
  hasSubscribedEmail: boolean
  hasSubscribedPlatform: boolean
}

export interface ShareWatchlistDetailInput {
  groupOrUserId: string
  roleId: number
}

export interface ShareWatchlistInput {
  watchlistId: number
  listShares?: ShareWatchlistDetailInput[] | null
}

export interface SimilarCompanyInput {
  companyId: number
  limit?: number | null
}

export interface SortInfo {
  field: string
  direction: string
}

export interface TaxonomyCategory {
  name?: string | null
  isPrimary?: boolean | null
}

export interface TaxonomyDimension {
  dimensionId?: string | null
  isPrimary?: boolean | null
}

export interface TaxonomyTagsInput {
  addedTags?: (tagsItemInput | null)[] | null
  updatedTags?: (tagsItemInput | null)[] | null
}

export interface TopSimilarCompanyInput {
  companyId: number
  locationType: string
  dimensionIds?: (number | null)[] | null
  countryInt?: number | null
  regionName?: string | null
  isHeadquarter?: boolean | null
}

export interface UpdateCompanyWatchlistInput {
  watchlistId: number
  companyIds?: number[] | null
}

export interface UpdateTrashInput {
  watchlistId: number
  isShare?: boolean | null
}

export interface UpdateWatchlistFilterOptionInput {
  watchlistId: number
  companyIds?: number[] | null
  filterOption?: any | null
}

export interface UpdateWatchlistInput {
  watchlistId: number
  watchlistName: string
}

export interface UserAndRoleInput {
  userIds?: string[] | null
  watchlistId: number
}

export interface similarCompaniesByKeywordsItem {
  companyId: number
  distance?: number | null
}

export interface tagsItemInput {
  id?: string | null
  reason?: string | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import { gql } from '@apollo/client'

export const UPDATE_WATCH_LIST = gql`
  mutation UpdateWatchlist($input: UpdateWatchlistInput!) {
    updateWatchlist(input: $input)
  }
`

export const UPDATE_WATCH_LIST_FILTER_CONDITIONS = gql`
  mutation UpdateWatchlistFilterConditions($input: UpdateWatchlistFilterOptionInput!) {
    updateWatchlistFilterOption(input: $input) {
      id
      new
      duplicated
      removed
    }
  }
`

import { gql } from '@apollo/client'

export const GET_EF_BY_GEOGRAPHY_DATA = gql`
  query GetEquityFundingByGeographyData($input: ChartFilterEquityFundingByGeography) {
    getEquityFundingByGeographyData(input: $input) {
      chartData {
        from
        to
        lineChartCount
        totalToolTip {
          topCountries {
            name
            totalFunding
            percentFunding
          }
          companies {
            company_id
            logo_url
            name
          }
        }
        stacks {
          id
          name
          from
          to
          countRound
          totalFunding
          toolTip {
            id
            name
            breakDown
            topCountries {
              name
              totalFunding
              percentFunding
            }
            companies {
              company_id
              logo_url
              name
            }
          }
          topCountries {
            name
            totalFunding
            percentFunding
          }
          companies {
            company_id
            logo_url
            name
          }
        }
        total
      }
      groupInfo {
        id
        name
      }
    }
  }
`

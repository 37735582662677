import { IAnalysisFilter, IAnalysisLocalFilter } from './../../types/Filter'

export const DOWNLOAD_HISTORY_TYPE = {
  LIST_VIEW: 1,
  PROFILE: 2,
  REQUEST: 3,
  REPORT: 4,
  ANALYTICS: 5,
}

export enum DownloadHistoryRequestType {
  Company = 'Company',
  UseCase = 'UseCase',
}

export enum DownloadHistoryListViewType {
  Company = 'Company',
  Deal = 'Deal',
}

export type IFilterOption = {
  filter?: IAnalysisFilter
  localFilter?: IAnalysisLocalFilter
  watchListId?: number | null
}

export type IPropertiesProfile = {
  companyId?: number
  investorId?: number
  companyName?: string
}

export type IPropertiesRequestUseCase = {
  companyId?: number
  companyName?: string
  type?: DownloadHistoryRequestType
}

export type IPropertiesRequestCompany = {
  companyUrl?: string
  type?: DownloadHistoryRequestType
}

export type IPropertiesListView = {
  type: DownloadHistoryListViewType
  total_rows: number
}

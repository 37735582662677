import { KnowledgeHubBreadcrumbs } from 'components/KnowledgeHub/KnowledgeHubBreadcrumbs/KnowledgeHubBreadcrumbs'
import {
  IKnowledgeHubCategory,
  IKnowledgeHubTopic,
  useKnowledgeHubContext,
} from 'contexts/KnowledgeHubContext'
import { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

export const useKnowledgeBreadcrumbs = () => {
  const { categories, topics } = useKnowledgeHubContext()

  const cateLookup: Record<string, IKnowledgeHubCategory> = categories.reduce((acc, cur) => {
    return { ...acc, [cur.Slug]: cur }
  }, {})

  const topicLookup: Record<string, IKnowledgeHubTopic> = topics.reduce((acc, cur) => {
    return { ...acc, [cur.Slug]: cur }
  }, {})

  const matchTopic = useRouteMatch<{ slug: string }>('/knowledge-hub/topic/:slug')

  const matchCate = useRouteMatch<{ slug: string }>('/knowledge-hub/cate/:slug')

  const matchSearch = useRouteMatch('/knowledge-hub/search')

  const getBreadCrumbs = () => {
    if (matchSearch)
      return [
        {
          name: 'Search',
          to: `/knowledge-hub/search`,
          slug: '',
        },
      ]

    const currentTopic = matchTopic ? topicLookup[matchTopic.params.slug] : null
    const parentTopic = currentTopic ? topicLookup[currentTopic['Parent Topic']] : null

    const parentTopicCrumb = parentTopic
      ? [
          {
            name: parentTopic?.Name,
            to: `/knowledge-hub/topic/${parentTopic.Slug}`,
            slug: parentTopic.Slug,
          },
        ]
      : []

    const topic = currentTopic
      ? [
          {
            name: currentTopic?.Name,
            to: `/knowledge-hub/topic/${currentTopic.Slug}`,
            slug: currentTopic.Slug,
          },
        ]
      : []

    const currentCate = matchCate
      ? cateLookup[matchCate.params.slug]
      : categories.find(i =>
          i.Topics.some(i => i === currentTopic?.Slug || i === parentTopic?.Slug)
        )

    const cateCrumb = currentCate
      ? [
          {
            name: currentCate.Name,
            to: `/knowledge-hub/cate/${currentCate.Slug}`,
            slug: currentCate.Slug,
          },
        ]
      : []

    return [...cateCrumb, ...parentTopicCrumb, ...topic]
  }
  return { getBreadCrumbs }
}

const KnowledgeHubBreadcrumbsContainer = () => {
  const { getBreadCrumbs } = useKnowledgeBreadcrumbs()

  return (
    <KnowledgeHubBreadcrumbs
      breadcrumbs={[{ name: 'FCT Knowledge Hub', to: `/knowledge-hub` }, ...getBreadCrumbs()]}
    />
  )
}

export default KnowledgeHubBreadcrumbsContainer

import { IResearchItem } from 'pages/ResearchAndAdvisoryPage'
import mapImg from 'assets/images/researchAndAdvisory/map.svg'
import researchImg2 from 'assets/images/researchAndAdvisory/research-img-2.svg'
import researchImg3 from 'assets/images/researchAndAdvisory/research-img-3.svg'
import researchImg5 from 'assets/images/researchAndAdvisory/research-img-5.png'

export enum EFaqs {
  AboutUs = 'About Us',
  Methodology = 'Methodology',
  Data = 'Data',
}

export const FAQS_DATA = {
  [EFaqs.AboutUs]: [
    {
      title: 'What is the FinTech Control Tower?',
      content: `<p>The FCT is a research-focused unit developed jointly by the Boston Consulting Group (BCG) and Expand
              Research. As an integral part of BCG’s expertise in financial services, we focus on the FinTech start-up
              ecosystem, by identifying initiatives, technologies, and companies that matter most in today’s FinTech
              ecosystem and assessing their impact.</p>

              <p>‍<br>We engage directly with financial institutions, regulators, industry stakeholders and innovators via
              our FinTech intelligence platform, bespoke consulting services and deep-dive industry reports.<br>‍
              <br>
              The FCT platform is an extension of the unit, allowing our subscribers to gain access to the tools
               and knowledge we have long held internally.</p>
    `,
    },
    {
      title: 'Who can access the FCT platform?',
      content: `<p>The FCT platform can be accessed online by our subscribers.</p>`,
    },
  ],
  [EFaqs.Methodology]: [
    {
      title: 'What industries does the FCT platform track?',
      content: `<p>The FCT platform tracks FinTechs, InsurTechs and RegTechs globally across all maturity and
                funding stages. Our coverage includes both public and private companies.</p>
                <ul role="list"><li><strong>FinTechs</strong> are companies that leverage technology to either provide banking services directly 
                or <br>provide solutions to enable existing banking service providers</li>
                <li><strong>InsurTechs</strong> are companies that leverage technology to either provide insurance directly or
                provide solutions to enable existing insurers</li>
                <li><strong>RegTechs</strong> are companies that leverage technology to provide solutions that enhance
                regulatory compliance capabilities of financial service providers</li>
                </ul>`,
    },
    {
      title: 'How does the FCT platform organize companies?',
      content: `<p>The FCT has developed a proprietary taxonomy that organizes companies by industry, product
                 line, business vertical, value chain and business model.</p>
                <ul role="list"><li><strong>Industry:</strong> companies are categorized into one of three core sectors, FinTech, InsurTech or
                 RegTech</li>
                 <li><strong>Product line:</strong> each industry sector has a unique product ontology developed by the FCT that
                further maps companies up to five levels of granularity, totaling ~250 product categories</li>
                <li><strong>Business vertical:</strong> FinTechs are additionally classified by banking business verticals
                 such as Retail Banking, SME Banking and Capital Markets amongst others</li>
                 <li><strong>Value chain: </strong>(currently available only for InsurTech) InsurTechs are additionally classified by
                the activity where they add value, such as policy administration, claims management, risk
                assessment and underwriting amongst others</li>
                <li><strong>Business model:</strong> companies are also defined by their intended customer base such as B2C,
                 B2B and B2B2C amongst others</li></ul> 
                 `,
    },
  ],
  [EFaqs.Data]: [
    {
      title: 'Where does the FCT get its data from?',
      content: `<p>The FCT data assets come from 3 core sources.<br>‍<br>Firstly, we engage directly with industry stakeholders, 
              through primary interviews with executives or founders of FinTechs, investors and financial institutions. 
              This is curated and conducted by our team of FinTech experts.<br>‍<br>Secondly, we work with data providers such 
              as Crunchbase, Dealroom, Venturecap Insights as well as global and regional industry associations.<br>‍<br>Thirdly,
               we conduct a significant amount of secondary research to validate data points and trends, across publicly available 
               sources such as news articles and announcements, company filings, annual reports and company social media profiles.</p>`,
    },
    {
      title: 'How can I partner with or contribute data to the FCT platform?',
      content: `<p>If you are an industry association, research organization or non-profit looking to collaborate with us or contribute data,
               please reach out to us via email at fct.portal@bcg.com.</p>`,
    },
  ],
}

export const RESEARCH_DATA: IResearchItem[] = [
  {
    img: mapImg,
    title: 'Market Intelligence',
    subTitle:
      'FinTech, InsurTech and RegTech are rapidly evolving spaces; gaining a competitive advantage begins by staying at the forefront of innovation. Through our research expertise, we help you identify market trends, analyze underlying drivers and turn future challenges into opportunities',
    subLastTitle:
      'This is powered by our data-driven methodology and proprietary information assets that have been carefully curated by our passionate team of FinTech analysts',
  },
  {
    img: researchImg3,
    title: 'Technology Scouting',
    subTitle:
      'Identifying the right FinTech partnership or investment can be challenging in a fast-growing sector. We perform detailed comparable firm analysis, source targets that best match your needs and leverage our strategic network to get you connected quickly to the right people at each organization, resulting in actionable outcomes',
    subLastTitle:
      'Our proprietary taxonomy classifies FinTechs based on 350+ clusters across business vertical, product line, business models. This allows us to identify the exact solutions for your problem statement, and compare them on metrics that are key to your organization',
  },
  {
    img: researchImg2,
    title: 'Strategic Advisory',
    subTitle:
      'Where data alone is unable to answer all your business questions, BCG provides bespoke consulting engagements to accelerate your path to innovation. Our advisory services are tailored to help you address organizational pain points, develop growth strategies and support decision-making',
    subLastTitle:
      'Through our strong consulting roots and unique network of investors, founders and policymakers, BCG partners with clients to transform their organizations, discover new opportunities and quickly get up to speed on new spaces',
    textButton: 'Learn more about BCG',
  },
  {
    img: researchImg5,
    title: 'Foundry-as-a-Service',
    subTitle:
      'To unlock new revenue streams or stay ahead of disruption, firms often seek to build an ecosystem of start-ups by setting up innovation and partnership units. This can take considerable time and investment until results are delivered',
    subLastTitle:
      'With our unique FaaS capability, we offer our clients the ability to shortcut this process by taking advantage of our end-end Foundry services. On a unique commercial subscription-basis model, we help clients to scout and vet FinTechs as well as structure and execute partnerships and PoCs',
  },
]

export const TOPICS_PRIVACY = [
  '01. Who we are',
  '02. Applicability of this Privacy Policy',
  '03. Important information about this Privacy Policy',
  '04. Changes to this Privacy Policy',
  '05. The data we collect about you',
  '06. Authentication through Okta',
  '07. How your personal data is collected',
  '08. Purposes for use of your personal data',
  '09. How your personal data is used',
  '10. Disclosure of personal data',
  '11. Choices regarding disclosures',
  '12. Data retention',
  '13. International data protection standards',
  '14. Data security',
  '15. Your rights',
  '16. Contact us',
]

export const TOPICS_TERMS = [
  '01. Prohibited Material',
  '02. Restrictions',
  '03. Suspension',
  '04. Compliance with Legal Requirements and Law Enforcement.',
]

import { REACT_APP_LOG_LEVEL } from './core/config/app-config'
declare const window: any
const ConfigContainer = (props: any) => {
  const { children } = props
  console.clear()
  const info = console.info
  const debug = console.log
  const error = console.error
  window._log = info
  if (!REACT_APP_LOG_LEVEL?.includes('INFO')) {
    window.console.info = function () {}
    window.console.table = function () {}
  } else {
    window.console.info = info
  }
  if (!REACT_APP_LOG_LEVEL?.includes('DEBUG')) {
    window.console.log = function () {}
  } else {
    window.console.log = debug
  }
  if (!REACT_APP_LOG_LEVEL?.includes('ERROR')) {
    window.console.error = function () {}
  } else {
    window.console.error = error
  }
  return <div {...props}>{children}</div>
}
export default ConfigContainer

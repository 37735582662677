import { Box } from '@material-ui/core'
import { InformationItem } from 'components/InvestorProfile/Sidebar/InvestorInformation'
import { PreviewHeader } from './PreviewHeader'
import { ISearchInputDetail } from '../interfaces'
import { ApplyButton } from './ApplyButton'
import { getNumberValue } from '../helpers'
import { SearchFields } from '../enums'

export const DealTypeDataSection = ({
  option,
  onClickSearch,
}: {
  option: ISearchInputDetail
  onClickSearch: () => void
}) => {
  const { deal_count, last_deal_count } = option.dealTypeMetaData[0] || {}

  const count = option.field === SearchFields.LATEST_DEAL_TYPE ? last_deal_count : deal_count

  return (
    <Box>
      <PreviewHeader option={option} />
      <InformationItem
        title="Total number of companies"
        data={getNumberValue(count)}
        icon="stars"
      ></InformationItem>
      <ApplyButton onClick={onClickSearch} />
    </Box>
  )
}

import { PUBLIC_API, SEARCH_API, TAXONOMY_API, COMPANY_API } from '../../core/config'
import { RestLink } from 'apollo-link-rest'
export default new RestLink({
  endpoints: {
    companies_search: SEARCH_API + '/companies',
    suggest: SEARCH_API + '/suggest',
    get_company_list: PUBLIC_API + '/companies/list',
    get_atlas_view: TAXONOMY_API + '/atlas-view',
    get_filter_data: COMPANY_API + '/filter-data',
  },
})

import KnowledgeHubSearchInput from 'components/KnowledgeHub/KnowledgeHubSearchInput/KnowledgeHubSearchInput'
import { useKnowledgeHubContext } from 'contexts/KnowledgeHubContext'
import { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import mixpanel from 'mixpanel'

const KnowledgeHubSearchContainer = () => {
  const matchSearch = useRouteMatch('/knowledge-hub/search')
  const history = useHistory()

  const { query, updateQuery } = useKnowledgeHubContext()
  const [text, setText] = useState(query)

  return (
    <KnowledgeHubSearchInput
      onSearch={() => {
        if (!text) return
        mixpanel.track('KnowledgeHub_Search', { KH_Query: text })
        updateQuery(text)
        if (!matchSearch) {
          history.push('/knowledge-hub/search')
        }
      }}
      value={text}
      setValue={setText}
    />
  )
}

export default KnowledgeHubSearchContainer

import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { FCTSelectRadio } from 'components/Select/FCTSelectRadio'
import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import { FCTSelectProps } from '../Select/FCTSelect'
import { FCTTooltipMove } from '../Tooltip/TooltipMove'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    styleTitle: {
      fontSize: '16px',
      fontWeight: 600,
      color: theme.palette.grey['900'],
    },
    tooltipItem: {
      fontSize: 12,
    },
  })
)

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export type SelectAnalysisProps = {
  title?: string
  labelSelect: string
  handleChange?(v: object): void
  desc?: string
} & Pick<FCTSelectProps, 'values' | 'setValues' | 'options'>
export interface IValueSelect {
  value: string
  label: string
}

export const SelectAnalysis = (props: SelectAnalysisProps) => {
  const classes = useStyles()
  return (
    <FCTTooltipMove
      text={<div className={classes.tooltipItem}>{props.desc}</div>}
      isMove={false}
      disable={!props.desc}
    >
      <TitleContainer>
        <Typography className={classes.styleTitle}>{props.title}</Typography>
        <FCTSelectRadio
          data={props.options || []}
          value={props.values}
          setValue={props.handleChange}
          placeholderValue={props.labelSelect}
          size="small"
          containerStyle={{ marginRight: 10 }}
          fullWidthPercent={140 / 310}
          buttonCSS={{
            height: 30,
            width: 140,
            backgroundColor: theme.palette.grey[100],
          }}
        />
      </TitleContainer>
    </FCTTooltipMove>
  )
}

import { makeStyles } from '@material-ui/core/styles'
import { CheckboxTree, ITreeNode } from 'components/CheckboxTree/CheckboxTree'
import { EXPAND_ROUND1_ID } from 'core/constants/companyFilter'
import React, { useEffect, useState } from 'react'
import { getDealType, setDealTypeFilter } from 'utils/DealType'
import InvestorListContainer from '../../containers/InvestorListContainer'
import { IOpenOptionFilter } from '../../store/features/filter/FilterSlice'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import { FCTDateRangePicker } from '../DateRangePicker/FCTDateRange'
import FCTDropdownListItem from '../DropdownListItem/FCTDropdownListItem'
import { AccordionItem } from '../Filters/AccordionItem'
import { AcquirerTypesFilter } from '../Filters/AcquirerTypesFilter'
import { initIsDealType } from './share/AllDealItem'

const useStyles = makeStyles(theme => ({
  container: {
    borderBottom: '1px solid #E7ECF3',
  },
}))

const MAItem = (props: IMAItemProps) => {
  const classes = useStyles()
  const {
    filter,
    setFilter,
    handleChange,
    getFilterData,
    openOption,
    setOpenOption,
    customStyle,
  } = props

  return (
    <div className={classes.container}>
      <AccordionItem
        title="M&A"
        open={openOption?.ma}
        setOpen={value => setOpenOption && setOpenOption(value, 'ma')}
        customStyle={customStyle}
      >
        <MAChildItem filter={filter} setFilter={setFilter} getFilterData={getFilterData} />
      </AccordionItem>
    </div>
  )
}

export default MAItem

interface IMAItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  handleChange(e: React.SyntheticEvent): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  openOption?: IOpenOptionFilter
  setOpenOption?: (value: boolean, tag: string) => void
  customStyle?: { title?: React.CSSProperties }
}

interface IMAChildItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  isOnAdvancedSearchPage?: boolean
}

export const MAChildItem = (props: IMAChildItemProps) => {
  const { filter, setFilter, getFilterData, isOnAdvancedSearchPage } = props

  const [isDealType, setIsDealType] = useState<{
    dealType: ITreeNode[] | null
    allDealType: ITreeNode[] | null
  }>(initIsDealType)

  const fundingRound1s = getFilterData?.fundingRound1s || []
  const fundingRound2s = getFilterData?.fundingRound2s || []

  const setAllDealType = (value: (number | string)[]) => {
    setFilter({
      latestFundingRound1s: [],
      latestFundingRound2s: [],
      ...setDealTypeFilter(
        value,
        fundingRound1s,
        fundingRound2s,
        'fundingRound1s',
        'fundingRound2s'
      ),
    })
  }

  useEffect(() => {
    if (
      filter.fundingRound1s?.length ||
      filter.fundingRound2s?.length ||
      filter.latestFundingRound1s?.length ||
      filter.latestFundingRound2s?.length
    ) {
      if (filter.fundingRound1s?.length || filter.fundingRound2s?.length)
        setIsDealType({
          ...isDealType,
          dealType: null,
        })
      else
        setIsDealType({
          ...isDealType,
          allDealType: null,
        })
    } else setIsDealType(initIsDealType)
  }, [
    filter.fundingRound1s,
    filter.fundingRound2s,
    filter.latestFundingRound1s,
    filter.latestFundingRound2s,
  ])

  const allDealType = getDealType(
    filter.fundingRound1s,
    filter.fundingRound2s,
    fundingRound1s,
    fundingRound2s
  )

  return (
    <>
      <FCTDropdownListItem
        isOpen={true}
        title="Acquired"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <CheckboxTree
          originalData={allDealType.dealTypeOriginal}
          selectedIds={allDealType.dealTypeIds}
          dataTree={allDealType.dealTypeTree
            .map(e => (e.id === EXPAND_ROUND1_ID.MA ? { ...e, name: 'Has been acquired' } : e))
            .filter(e => e.id === EXPAND_ROUND1_ID.MA)}
          setFilter={setAllDealType}
          enableNodes={isDealType.allDealType}
        />
      </FCTDropdownListItem>

      <FCTDropdownListItem
        isOpen={true}
        title="Acquirer"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <InvestorListContainer
          hiddenSelectedOption
          placeHolder="Search for acquirer name..."
          value={filter.acquirers}
          onChange={acquirers => {
            setFilter({ acquirers: acquirers.map(i => i.id) })
          }}
          onDelete={investor =>
            setFilter({ acquirers: filter.acquirers.filter(id => id !== investor.id) })
          }
          isAcquirer={true}
        />
      </FCTDropdownListItem>

      <FCTDropdownListItem
        isOpen={true}
        title="Acquired Date"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <FCTDateRangePicker
          filter={filter}
          setFilter={setFilter}
          label=""
          placeholder="Select date"
          tagDateFrom="acquiredDateFrom"
          tagDateTo="acquiredDateTo"
        ></FCTDateRangePicker>
      </FCTDropdownListItem>

      <FCTDropdownListItem
        isOpen={!isOnAdvancedSearchPage}
        title="Acquirer Type"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <AcquirerTypesFilter
          data={getFilterData?.acquirerTypes || []}
          acquirerTypes={filter.acquirerTypes || []}
          setAnalysisFilter={setFilter}
        />
      </FCTDropdownListItem>
    </>
  )
}

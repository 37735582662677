import { makeStyles, Typography } from '@material-ui/core'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import {
  GetDealFilterDataResult_getDealFilterData_countries,
  GetDealFilterDataResult_getDealFilterData_subRegion2s,
  GetDealFilterDataResult_getDealFilterData_subRegions,
} from '../../__generated__/GetDealFilterDataResult'
import { GetCompanyFilterDataResult_getCompanyFilterData } from '../../__generated__/GetCompanyFilterDataResult'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import Flex from '../Flex/Flex'
import FCTIcon from '../Icon/Icon'
import FCTListCheckbox from '../ListCheckbox/FCTListCheckbox'
import FCTSearchInput from '../SearchInput/FCTSearchInput'
import FCTTooltip from '../Tooltip/Tooltip'

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey[800],
    lineHeight: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    padding: '0 10px',
    marginBottom: 2,
    backgroundColor: '#EEF2F6',
    borderRadius: 3,
    height: 32,
  },
  icon: {
    paddingRight: 8,
    marginLeft: 2,
  },
  tooltip: {
    width: 12,
    height: 12,
    color: theme.palette.grey[400],
    margin: '0 8px',
  },
  tooltipIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface IItemProps {
  title: string
  data: any[]
  filterIds: any[]
  setFilterIds(v: string[]): void
  searchValue: string
  tooltipText?: string
  customerStyleDropList?: React.CSSProperties
}

const Item = ({
  title,
  data,
  filterIds,
  setFilterIds,
  searchValue,
  tooltipText,
  customerStyleDropList = {},
}: IItemProps) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true)
  return (
    <div>
      <div className={classes.title} onClick={() => setIsOpen(!isOpen)}>
        <Flex>
          {title}
          {!!tooltipText && (
            <FCTTooltip
              title={<Typography style={{ fontSize: 12 }}>{tooltipText}</Typography>}
              arrow
              placement="top"
            >
              <div className={classes.tooltipIcon}>
                <FCTIcon name="info" className={classes.tooltip} />
              </div>
            </FCTTooltip>
          )}
        </Flex>
        <div className={classes.icon}>
          <FCTIcon width="6" height="6" name={isOpen ? 'up' : 'down'} />
        </div>
      </div>
      {isOpen && (
        <div>
          <FCTListCheckbox
            data={data}
            values={filterIds}
            setValues={setFilterIds}
            searchValue={searchValue}
            customerStyleDropList={customerStyleDropList}
          ></FCTListCheckbox>
        </div>
      )}
    </div>
  )
}

interface IProps {
  filterData:
    | GetCompanyFilterDataResult_getCompanyFilterData
    | GetDealFilterDataResult_getDealFilterData
    | undefined
  filter: IAnalysisFilter | ICompanyDealFilter | undefined
  setFilter: (value: any) => void
  isOnAdvancedSearchPage?: boolean
  searchExternal?: string
}

export const GeographyFilter = forwardRef(
  (
    { filterData, filter, setFilter, isOnAdvancedSearchPage = false, searchExternal = '' }: IProps,
    ref: any
  ) => {
    const [searchInternal, setSearchInternal] = useState('')
    const regions = filter?.regions || []
    const subRegions = filter?.subRegions || []
    const subRegion2s = filter?.subRegion2s || []

    const handleRegions = (value: String[]) =>
      setFilter({
        regions: value?.length ? value : null,
        subRegions: null,
        subRegion2s: null,
        countries: null,
      })
    const handleSubRegions = (value: String[]) => {
      setFilter({
        subRegions: value?.length ? value : null,
        subRegion2s: null,
        countries: null,
      })
    }
    const handleSubRegion2s = (value: String[]) => {
      setFilter({ subRegion2s: value?.length ? value : null, countries: null })
    }

    const getSubRegion2s = () => {
      let listSubRegion2s = filterData?.subRegion2s || []
      if (regions?.length) {
        listSubRegion2s = listSubRegion2s.filter(
          (subRegion2: GetDealFilterDataResult_getDealFilterData_subRegion2s) =>
            subRegion2?.region1 && regions.includes(subRegion2.region1)
        )
      }
      if (subRegions?.length) {
        listSubRegion2s = listSubRegion2s.filter(
          (subRegion2: GetDealFilterDataResult_getDealFilterData_subRegion2s) =>
            subRegion2?.region2 && subRegions.includes(subRegion2.region2)
        )
      }
      return listSubRegion2s
    }

    const getSubRegions = () => {
      let listSubRegions = filterData?.subRegions || []
      if (regions?.length) {
        listSubRegions = listSubRegions.filter(
          (subRegion: GetDealFilterDataResult_getDealFilterData_subRegions) =>
            subRegion?.region1 && regions.includes(subRegion.region1)
        )
      }
      return listSubRegions
    }

    const resetSearch = () => {
      setSearchInternal('')
    }

    useImperativeHandle(ref, () => ({
      reset() {
        resetSearch()
      },
    }))
    const searchValue = isOnAdvancedSearchPage ? searchExternal : searchInternal

    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          {!isOnAdvancedSearchPage && (
            <FCTSearchInput
              handleSearch={setSearchInternal}
              resetSearch={resetSearch}
              search={searchInternal}
              placeholder="Search"
            />
          )}
        </div>
        <Item
          searchValue={searchValue}
          title="HQ Region"
          data={filterData?.regions || []}
          filterIds={regions}
          setFilterIds={handleRegions}
          tooltipText={'In descending order based on number of companies'}
        />
        <Item
          searchValue={searchValue}
          title="HQ Sub-Region 1"
          data={getSubRegions()}
          filterIds={subRegions}
          setFilterIds={handleSubRegions}
          tooltipText={'In descending order based on number of companies'}
        />
        <Item
          searchValue={searchValue}
          title="HQ Sub-Region 2"
          data={getSubRegion2s()}
          filterIds={subRegion2s}
          setFilterIds={handleSubRegion2s}
          tooltipText={'In descending order based on number of companies'}
        />
        {!isOnAdvancedSearchPage && (
          <HqCountry
            searchValue={searchInternal}
            filter={filter}
            setFilter={setFilter}
            filterData={filterData}
          />
        )}
      </div>
    )
  }
)

interface IHqCountry {
  filterData:
    | GetCompanyFilterDataResult_getCompanyFilterData
    | GetDealFilterDataResult_getDealFilterData
    | undefined
  filter: IAnalysisFilter | ICompanyDealFilter | undefined
  setFilter: (value: any) => void
  searchValue: string
  customerStyleDropList?: React.CSSProperties
}

export const HqCountry = ({
  searchValue,
  filter,
  setFilter,
  filterData,
  customerStyleDropList = {},
}: IHqCountry) => {
  const regions = filter?.regions || []
  const subRegions = filter?.subRegions || []
  const subRegion2s = filter?.subRegion2s || []
  const countries = filter?.countries || []

  const getCountry = () => {
    let listCountry = filterData?.countries || []
    if (regions?.length) {
      listCountry = listCountry.filter(
        (country: GetDealFilterDataResult_getDealFilterData_countries) =>
          country?.region1 && regions.includes(country.region1)
      )
    }
    if (subRegions?.length) {
      listCountry = listCountry.filter(
        (country: GetDealFilterDataResult_getDealFilterData_countries) =>
          country?.region2 && subRegions.includes(country.region2)
      )
    }
    if (subRegion2s?.length) {
      listCountry = listCountry.filter(
        (country: GetDealFilterDataResult_getDealFilterData_countries) =>
          country?.region3 && subRegion2s.includes(country.region3)
      )
    }

    return listCountry
  }

  const handleCountries = (value: String[]) =>
    setFilter({ countries: value?.length ? value : null })

  return (
    <Item
      searchValue={searchValue}
      title="HQ Country"
      data={getCountry()}
      filterIds={countries}
      setFilterIds={handleCountries}
      customerStyleDropList={customerStyleDropList}
      tooltipText={'In descending order based on number of companies'}
    />
  )
}

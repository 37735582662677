import {
  DataTableProps,
  IDataItem,
} from '../components/TableBottomChartAnalytics/FCTTableDealCountAndBreakDown'
import {
  GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData,
  GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_groupInfo,
} from '../__generated__/GetEquityFundingByFundingAmount'
import {
  GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData,
  GetEquityFundingByGeographyData_getEquityFundingByGeographyData_groupInfo,
} from '../__generated__/GetEquityFundingByGeographyData'
import totalByXAxis from './charts/totalByXAxis'
import { LocationType, TitleLocationType } from './convert/Title'

export const detectWheelTable = (event: any, ref: any) => {
  if ((ref.current as any)?.scrollHeight !== (ref.current as any)?.clientHeight) {
    if ((ref.current as any)?.scrollTop === 0 && event.deltaY < 0) return
    if (
      (ref.current as any)?.scrollTop ===
        (ref.current as any)?.scrollHeight - (ref.current as any)?.clientHeight &&
      event.deltaY > 0
    )
      return
    event.stopPropagation()
  }
}

export function handleGetDataTableGeographyChart(props: DataTableGeographyProps) {
  const { keyCount, keyBreakDown, nameCount, namePercent, data, legendItem, keyCountTotal } = props

  const dataTable: DataTableProps[] = []

  const dataTableFunding = data?.map(item => {
    const stacks = item.stacks?.map(item2 => {
      return {
        count: (item2 as any)[keyCount],
        breakDown: (item2 as any).toolTip[keyBreakDown],
        name: item2.toolTip.name,
      }
    })
    return {
      from: item.from,
      stacks: stacks,
      totalCount: (item as any)[keyCountTotal],
      totalBreakDown: '100',
    }
  })

  legendItem?.forEach((item: any) => {
    const dataItem = dataTableFunding?.map(el =>
      el?.stacks?.find(e => e.name === item.name)
    ) as IDataItem[]
    dataTable.push({
      name: item.name,
      dataItem: dataItem,
      nameCount: nameCount,
      namePercent: namePercent,
      color: item.color,
    })
  })

  dataTable.push({
    name: 'Total',
    dataItem: dataTableFunding?.map(item => ({
      count: item.totalCount,
      breakDown: item.totalBreakDown,
      name: item.from,
    })) as IDataItem[],
    nameCount: nameCount,
    namePercent: '% Funding',
    color: '',
  })

  return dataTable
}

export interface DataTableGeographyProps {
  keyCount: string
  keyBreakDown: string
  keyCountTotal: string
  nameCount: string
  namePercent: string
  data: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_chartData[] | null
  legendItem: GetEquityFundingByGeographyData_getEquityFundingByGeographyData_groupInfo[] | null
}

export function handleGetDataTableProducLineChart(props: DataTableProductLineProps) {
  const {
    keyCount,
    keyBreakDown,
    keyCountTotal,
    keyBreakDownTotal,
    nameCount,
    namePercent,
    data,
    legendItem,
  } = props

  const dataTableBodyProductLineFunding: DataTableProps[] = []

  const dataTableProductLineFunding = data?.map(item => {
    const stacks = item.stacks?.map(item2 => {
      return {
        count: (item2 as any).toolTip[keyCount],
        breakDown: (item2 as any).toolTip[keyBreakDown],
        name: item2.name,
      }
    })
    return {
      from: item.from,
      totalBreakDown: (item as any).totalToolTip[keyCountTotal],
      totalCount: (item as any).totalToolTip[keyBreakDownTotal],
      stacks: stacks,
    }
  })

  legendItem?.forEach((item: any) => {
    const dataItem = dataTableProductLineFunding?.map(el =>
      el?.stacks?.find(e => e.name === item.name)
    ) as IDataItem[]
    dataTableBodyProductLineFunding.push({
      name: item.name,
      dataItem: dataItem,
      nameCount: nameCount,
      namePercent: namePercent,
      color: item.color,
    })
  })

  dataTableBodyProductLineFunding.push({
    name: 'Total',
    dataItem: dataTableProductLineFunding?.map(item => ({
      count: item.totalCount,
      breakDown: item.totalBreakDown,
      name: item.from,
    })) as IDataItem[],
    nameCount: nameCount,
    namePercent: namePercent,
    color: '',
  })
  return dataTableBodyProductLineFunding
}

export interface DataTableProductLineProps {
  keyCount: string
  keyBreakDown: string
  keyCountTotal: string
  keyBreakDownTotal: string
  nameCount: string
  namePercent: string
  data: GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_chartData[] | null
  legendItem:
    | GetEquityFundingByFundingAmount_getEquityFundingByFundingAmountChartData_groupInfo[]
    | null
    | Record<'description' | 'name' | 'color', string>[]
}

export function locationTypeToString(value: LocationType) {
  if (!value) return ''
  if (value === LocationType.Country) return 'Countries'
  return TitleLocationType.get(value)
}

export function handleGetDataLegendItemGeo(
  data: any,
  colorScale: d3.ScaleOrdinal<string, unknown, never>,
  locationType: LocationType,
  getValue: (d: any, key: string) => number
) {
  return [...(data?.[0]?.stacks || [])]
    ?.sort((a, b) => totalByXAxis(data, b, getValue, 'id') - totalByXAxis(data, a, getValue, 'id'))
    .map((d: any, index: number) =>
      d.name === 'Other'
        ? { name: `All Other ${locationTypeToString(locationType)}`, color: colorScale(index + '') }
        : { name: d.name || '', color: colorScale(index + '') }
    )
}

import { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    position: 'relative',
  },
  filter: {
    backgroundColor: theme.palette.primary.contrastText,
    border: '1px solid #E7ECF3',
    boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    borderRadius: '3px',
    zIndex: 5,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  header: {
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 15px',
    borderBottom: '1px solid #E7ECF3',
    boxSizing: 'border-box',
    fontWeight: 600,
  },
  accordionWrapper: {
    padding: '5px 15px',
    maxHeight: 'calc(100vh - 280px)',
    overflowY: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    background: '#ffffff',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  arrow: {
    position: 'absolute',
    left: '14px',
    height: '10px',
    width: '10px',
    transform: 'rotate(45deg)',
    background: '#ffffff',
    top: '-5px',
    border: '1px solid #E7ECF3',
  },
}))

interface IProps {
  renderButton: ReactNode
  popoverHeader?: ReactNode
  children: React.ReactElement
  open: Boolean
  style?: object
  styleWrapper?: object
  isArrow?: boolean
}

export const CustomPopover = (props: IProps) => {
  const { renderButton, popoverHeader, children, open, style, isArrow, styleWrapper } = props
  const classes = useStyles()

  return (
    <div className={classes.filterContainer}>
      <div className={classes.buttonContainer}>{renderButton}</div>

      {open && (
        <div className={classes.filter} style={style}>
          {isArrow && <div className={classes.arrow}></div>}
          {popoverHeader && <header className={classes.header}>{popoverHeader}</header>}
          <div className={classes.accordionWrapper} style={styleWrapper}>
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

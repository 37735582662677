import { Action } from 'redux-actions'
import { ofType } from 'redux-observable'
import { RootState } from '../../store'
import { concat, Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { updateGlobalStateAction } from './GlobalAction'
import { updateSearchOption } from '../search/SearchSlice'
import { IGlobalData } from './GlobalSelector'
import { updateWatchListId } from '../watch-list/WatchListSlice'
import { updateSubFilter } from '../company-deal/CompanyDealSlice'
import { ISubFilter } from '../../../types/CompanyDealFilter'

const updateGlobalStateEpic = (action$: Observable<Action<any>>, store: RootState) =>
  action$.pipe(
    ofType(updateGlobalStateAction.type),
    switchMap((action: Action<IGlobalData>) => {
      const payload = []
      if (action.payload?.search?.options)
        payload.push(updateSearchOption(action.payload?.search?.options))
      if (action.payload?.watchList?.watchListId)
        payload.push(updateWatchListId(+action.payload?.watchList?.watchListId))
      if (action.payload?.compare?.compareFilter)
        payload.push(updateSubFilter(convertData(action.payload?.compare?.compareFilter)))
      return concat(of(...payload))
    })
  )

export default [updateGlobalStateEpic]

const convertData = (data: ISubFilter) => ({
  companyIds: data?.companyIds?.map(e => +e) || [],
  compareType: !!data.compareType ? +data.compareType : null,
  currentCompanyName: data.currentCompanyName,
  product: !!data.product ? +data.product : null,
  businessLines: !!data.businessLines ? +data.businessLines : null,
  valueChains: !!data.valueChains ? +data.valueChains : null,
  operatingModel: !!data.operatingModel ? +data.operatingModel : null,
})

import { useQuery } from '@apollo/client'
import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import { uniqBy } from 'lodash'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { List } from 'react-virtualized'
import { encodeSearchWord } from 'store/cache/Common'
import { useInvestorProfileTab } from 'store/features/investor-profile/InvestorProfileHook'
import { EInvestorProfilePortfolioTab } from 'types/InvestorProfile'
import Flex from '../components/Flex/Flex'
import FCTIcon from '../components/Icon/Icon'
import Loading from '../components/Loading'
import SearchInput, { mapSuggestToOption } from '../components/Search/SearchInput'
import SearchNotLookingForDialog from '../components/Search/SearchNotLookingForDialog'
import { ModalUpdateWatchListFilter } from '../components/WatchList/ModalUpdateWatchListFilter'
import { useSyncState } from '../core/hooks/useSyncState'
import mixpanel from '../mixpanel'
import { ViewWatchList } from '../module/WatchList/ViewWatchList'
import { setProfileFilter } from '../store/cache/ProfileFilterPolicies'
import { useAnalysisFilter, useAnalysisLocalFilter } from '../store/features/analysis/AnalysisHook'
import { initialAnalysisFilter, initLocalFilter } from '../store/features/analysis/AnalysisSlice'
import { useCompanyDealFilter, useSubFilter } from '../store/features/company-deal/CompanyDealHook'
import {
  initCompanyDealFilter,
  initSubFilter,
} from '../store/features/company-deal/CompanyDealSlice'
import { useOpenFilter } from '../store/features/filter/FilterHook'
import { selectSearchOption } from '../store/features/search/SearchSelector'
import { updateSearchOption } from '../store/features/search/SearchSlice'
import { useWatchListId, useWatchListName } from '../store/features/watch-list/WatchListHook'
import { GET_DEAL_FILTER_DATA } from '../store/operations/queries/GetDealFilterData'
import { ISuggestResult, SUGGEST } from '../store/operations/queries/local/rest/Suggest'
import { EProfileTab } from '../types/Profile'
import { useResetFilter } from '../utils/CompanyFilter'
import { useBlockBeforeNavigate } from '../utils/navigationHook'
import { GetDealFilterDataResult_getDealFilterData } from '../__generated__/GetDealFilterDataResult'
import { getDomainName } from './../utils/helper'
import { ISearchInputOption } from 'components/Search/interfaces'

const ITEM_HEIGHT = 40

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 1px 0px #EBEFF4',
    zIndex: 1,
    minHeight: '56px',
  },
  spreader: {
    margin: '8px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    height: '100%',
    paddingLeft: '32.4px',
    '@media screen and (max-width: 1366px)': {
      paddingLeft: '24px',
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  logo: {
    height: '37px',
    marginBottom: 0,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F8FAFC',
    marginLeft: theme.spacing(2),
    maxWidth: '100%',
    flex: 1,
  },
  searchIcon: {
    padding: '0px 10px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  autocomplete: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '450px',
    },
  },
  input: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
  },
  notiText: {
    fontSize: 14,
    lineHeight: '22px',
  },
  list: {
    width: '100% !important',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  canFindCompany: {
    padding: '10px 0px',
    textAlign: 'center',
  },
  textFindCompany: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[900],
    '& span': {
      fontSize: 14,
    },
  },
  textRequest: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.light,
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
}))

type Props = {
  disabled?: {
    watchlist?: boolean
    search?: boolean
    requestCompany?: boolean
  }
}

const Header = ({ disabled }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { isBlocked } = useBlockBeforeNavigate()

  const [_, setOpenFilter] = useOpenFilter()
  const [inputValue, inputValueDebounce, setInputValue, getCurrentInputValue] = useSyncState('')
  const searchOptions = useSelector(selectSearchOption)

  const [___, setWatchListId] = useWatchListId()
  const [____, setWatchListName] = useWatchListName()
  const [_____, setLocalFilter] = useAnalysisLocalFilter()
  const [______, setAnalysisFilter] = useAnalysisFilter()
  const [_______, setCompanyFilter] = useCompanyDealFilter()
  const [________, setSubFilter] = useSubFilter()
  const { setSelectedTab } = useInvestorProfileTab()

  const [confirmNavigate, setConfirmNavigate] = useState<boolean>(false)
  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA)
  const companyFilterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData

  const isSunburstPage = history.location.pathname.includes('analysis/sunburst')

  const [open, setOpen] = useState<boolean>(false)
  const [freeSolo, setFreeSolo] = useState(true)
  const [enableLimitTags] = useState(true)
  const searchStr = useMemo(() => encodeSearchWord(getDomainName(inputValueDebounce)), [
    inputValueDebounce,
  ])

  const { data: searchByValue } = useQuery<ISuggestResult>(SUGGEST, {
    variables: {
      input: `q=${searchStr}&suggester=value`,
    },
    skip: searchStr == '',
  })

  const { data: searchByWebsiteUrl } = useQuery<ISuggestResult>(SUGGEST, {
    variables: {
      input: `q=${searchStr}&suggester=website_url`,
    },
    skip: searchStr == '',
  })

  const onKeyDown = () => (event: any) => {
    if (event.keyCode === 13) {
      setFreeSolo(true)
    }
  }

  const onValueChange = useMemo(
    () => (opts: ISearchInputOption[], reason: string) => {
      resetFilter()
      if (opts.length == 1) {
        const companyOpt = opts
          .slice()
          .reverse()
          .find(opt => opt.field == 'name')
        if (companyOpt) {
          const metaObjs = companyOpt.meta.map(meta => JSON.parse(meta))
          const meta =
            metaObjs.find(meta => meta.type == 'company_id') ||
            metaObjs.find(meta => meta.type == 'alias')
          if (meta?.company_id) {
            setProfileFilter({ profileTab: EProfileTab.News })
            history.push(`/app/profile/${meta.company_id}`)
            return
          }
        }
        const investorOrAcquirerOpt = opts.find(opt => opt.field == 'investor_names')
        if (investorOrAcquirerOpt) {
          const metaObjs = investorOrAcquirerOpt.meta.map(meta => JSON.parse(meta))
          const meta = metaObjs.find(meta => meta.type == 'investor' || meta.type == 'acquirer')

          if (meta?.investor_id) {
            const isInvestor = meta.type == 'investor'
            setSelectedTab(
              isInvestor
                ? EInvestorProfilePortfolioTab.Investments
                : EInvestorProfilePortfolioTab.Acquisitions
            )
            setCompanyFilter({
              investors: isInvestor ? [meta.investor_id] : [],
              acquirers: isInvestor ? [] : [meta.investor_id],
            })
            history.push(`/app/investor-profile/${meta.investor_id}`)
            return
          }
        }
      }
      if (opts.length && !location.pathname.startsWith('/search')) {
        history.push({
          pathname: `/search`,
        })
      }
    },
    [location]
  )

  const resetFilter = () => {
    setWatchListId(null)
    setWatchListName(null)
    setLocalFilter(initLocalFilter)
    useResetFilter(setAnalysisFilter, initialAnalysisFilter, companyFilterData)
    !location.pathname.includes('investor-profile/') &&
      useResetFilter(setCompanyFilter, initCompanyDealFilter, companyFilterData)
    setSubFilter(initSubFilter)
  }

  const ImageWrapper = useCallback(
    ({ children }: PropsWithChildren<{}>) => (
      <Link
        to="/app/dashboard"
        className={classes.logoWrapper}
        onClick={resetFilter}
        data-testid="header-avatar"
      >
        {children}
      </Link>
    ),
    []
  )

  const options = mapSuggestToOption([
    ...(searchByValue?.suggest?.hits?.hit || []),
    ...(searchByWebsiteUrl?.suggest?.hits?.hit || []),
  ])

  useEffect(() => {
    setFreeSolo(
      !(searchByValue?.suggest?.hits === null && !searchByWebsiteUrl?.suggest?.hits?.hit?.length)
    )
  }, [searchByValue, searchByWebsiteUrl])

  const handleOnBlur = () => {
    setFreeSolo(true)
    setInputValue('')
  }

  return (
    <header className={classes.header}>
      <Container className={classes.spreader} maxWidth="xl">
        <ImageWrapper>
          <FCTIcon width="100%" height={32} name="newLogo" color="#000000" />
        </ImageWrapper>

        {!disabled?.search && (
          <div className={classes.search}>
            <div className={classes.searchIcon}></div>
            <SearchInput
              clearOnBlur
              enableLimitTags={enableLimitTags}
              hiddenSelectedOption
              getValue={searchOptions || []}
              setValue={values => {
                const newsValue = values.map(e => ({ ...e, name: getDomainName(e.name) }))
                if (newsValue.length)
                  mixpanel.track('Search Query', {
                    query: newsValue
                      .map((option: any) => `${option.name} ${option.operator}`)
                      .join(', '),
                  })
                dispatch(updateSearchOption(newsValue))
              }}
              getInput={inputValue}
              onClick={e => {
                isSunburstPage && setOpenFilter(true)
                e.stopPropagation()
              }}
              setInput={setInputValue as any}
              className={classes.autocomplete}
              options={options}
              onKeyDown={onKeyDown}
              onValueChange={(opts: ISearchInputOption[], reason: string) =>
                isBlocked ? setConfirmNavigate(true) : onValueChange(opts, reason)
              }
              placeHolder={'Search keyword here'}
              ListboxProps={{
                setOpen: setOpen,
                open: open,
                enableLimitTags,
                restrictedRequestCompany: disabled?.requestCompany,
              }}
              ListboxComponent={ListboxComponent}
              noOptionsText={
                <CantFindCompany
                  enableLimitTags={enableLimitTags}
                  setOpen={setOpen}
                  restricted={disabled?.requestCompany}
                />
              }
              freeSolo={freeSolo}
              handleOnBlur={handleOnBlur}
            />
          </div>
        )}
        {!disabled?.watchlist && <ViewWatchList />}

        <ModalUpdateWatchListFilter
          confirmNavigate={confirmNavigate}
          setConfirmNavigate={setConfirmNavigate}
          navigateToDestination={() => {
            onValueChange(searchOptions, '')
          }}
          handleClose={() => dispatch(updateSearchOption([]))}
        />

        <SearchNotLookingForDialog
          open={open}
          setOpen={setOpen}
          setInputValue={setInputValue as any}
        />
      </Container>
    </header>
  )
}
export default Header

export const ListboxComponent = React.forwardRef(function (props: any, ref: any) {
  const classes = useStyles()
  const {
    children,
    role,
    className,
    loading,
    open,
    setOpen,
    enableLimitTags,
    restrictedRequestCompany,
    ...other
  } = props
  const itemCount = Array.isArray(children) ? children.length : 0
  const itemSize = ITEM_HEIGHT
  const visibleItem = 7

  return (
    <div
      ref={ref}
      onMouseDown={e => {
        enableLimitTags && e.stopPropagation()
      }}
    >
      <Box {...other} style={{ overflowY: 'hidden' }}>
        <List
          className={classes.list}
          width={1300}
          height={(itemCount > visibleItem ? visibleItem : itemCount) * itemSize}
          rowHeight={itemSize}
          rowCount={itemCount}
          rowRenderer={props => {
            return children[props.index] ? (
              React.cloneElement(children[props.index], {
                style: props.style,
              })
            ) : (
              <Flex key="loading" style={props.style} alignItems="center" justifyContent="center">
                <Loading />
              </Flex>
            )
          }}
          overscanCount={5}
          role={role}
          onScroll={props.onScroll}
        />
        <CantFindCompany
          setOpen={setOpen}
          enableLimitTags={enableLimitTags}
          restricted={restrictedRequestCompany}
        />
      </Box>
    </div>
  )
})

const CantFindCompany = (props: ICantFindCompany) => {
  const classes = useStyles()
  const { setOpen, enableLimitTags, restricted } = props

  return (
    <Box role="presentation" onMouseDown={e => enableLimitTags && e.stopPropagation()}>
      <div className={classes.canFindCompany}>
        <Typography className={classes.textFindCompany}>
          Can't find what you are looking for?{' '}
          <span
            className={classes.textRequest}
            onMouseDown={e => {
              if (restricted) {
                window.open('mailto:fct.portal@bcg.com?Subject=Trial user: Request for paid access')
              } else {
                setOpen(true)
                e.stopPropagation()
              }
            }}
          >
            {restricted ? 'Contact with us to request company' : 'Request it here'}
          </span>
        </Typography>
      </div>
    </Box>
  )
}

export interface ICantFindCompany {
  setOpen: (value: boolean) => void
  enableLimitTags?: boolean
  restricted?: boolean
}

import { FormControlLabel, RadioGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import FCTDropdownListItem from '../DropdownListItem/FCTDropdownListItem'
import FCTRadio from '../Radio/FCTRadio'

interface IBusinessModelItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
}

const useStyles = makeStyles(theme => ({
  labelRadio: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  smallMarginLeft: {
    marginLeft: 2,
  },
  typoFontSize: {
    fontSize: 12,
  },
}))

const BusinessModelItem = (props: IBusinessModelItemProps) => {
  const classes = useStyles()
  const { filter, setFilter, getFilterData } = props
  const businessModels = getFilterData?.businessModels?.map(({ id, name }) => ({
    value: `${id}`,
    label: `${name}`,
  }))

  const handleBusinessModelChange = (e: any) => {
    setFilter({ businessModelIds: [+e.target.value] })
  }

  return (
    <FCTDropdownListItem
      isOpen={true}
      title="Business Model"
      size="small"
      openedIcon="down"
      closedIcon="up"
    >
      <RadioGroup
        name="businessModels"
        value={filter?.businessModelIds?.map(String)[0] || []}
        onChange={handleBusinessModelChange}
      >
        {businessModels?.map(item => (
          <FormControlLabel
            key={item.value}
            className={classes.labelRadio && classes.smallMarginLeft}
            value={item.value}
            label={<Typography className={classes.typoFontSize}>{item.label}</Typography>}
            control={<FCTRadio />}
          />
        ))}
      </RadioGroup>
    </FCTDropdownListItem>
  )
}
export default BusinessModelItem

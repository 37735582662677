import React, { FocusEventHandler, MouseEventHandler } from 'react'
import { makeStyles, Typography, Box, Link, debounce, Divider, BoxProps } from '@material-ui/core'
import Flex from '../components/Flex/Flex'
import { useQuery } from '@apollo/client'
import Avatar from '@material-ui/core/Avatar'
import FCTTextField from '../components/TextField/FCTTextField'
import { AutoSizer, List } from 'react-virtualized'
import {
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'
import Loading from '../components/Loading'
import { StyledAutocomplete } from '../components/AutoComplete/FCTAutoCompleteVirtualized'
import {
  GetCompanyListVariables,
  GetCompanyList_getCompanyList,
  GetCompanyList_getCompanyList_items,
  GET_COMPANY_LIST,
} from '../store/operations/queries/local/rest/GetCompanyList'
import isUrl from '../utils/isUrl'

const PAGE_SIZE = 100
const ITEM_HEIGHT = 56

export interface CompanyListItem
  extends Omit<GetCompanyList_getCompanyList_items, 'id' | '__typename'> {
  id: string
}

const useStyles = makeStyles(theme => ({
  companyOption: {
    minHeight: ITEM_HEIGHT,
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 3,
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: `${theme.palette.grey[800]}`,
  },
  name: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
  },
  website: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  list: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))

type Props = {
  value: CompanyListItem | undefined
  setValue(d: CompanyListItem | undefined): void
  noOptionsText?: React.ReactNode
  handleCantFindCompany: MouseEventHandler
  disabledLoadMore?: boolean
  InputProps: {
    error?: boolean
    helperText?: string
    icon?: React.ReactElement
    iconStart?: React.ReactElement
    disabled?: boolean
    onBlur?: FocusEventHandler
  }
}

const CompanyListContainer = ({
  setValue,
  InputProps,
  noOptionsText,
  value,
  handleCantFindCompany,
  disabledLoadMore = false,
}: Props) => {
  const [companies, setCompanies] = React.useState<CompanyListItem[]>([])
  const [pageNumber, setPageNumber] = React.useState(1)
  const [companyInput, setCompanyInput] = React.useState('')
  const [hasMore, setHasMore] = React.useState(!disabledLoadMore)

  const { loading } = useQuery<
    { getCompanyList: GetCompanyList_getCompanyList },
    GetCompanyListVariables
  >(GET_COMPANY_LIST, {
    notifyOnNetworkStatusChange: true,
    skip: companyInput?.length < 2,
    variables: {
      input: {
        pageSize: PAGE_SIZE,
        pageNumber,
        filterBy: !companyInput
          ? null
          : [{ key: isUrl(companyInput) ? 'url' : 'name', value: companyInput }],
      },
    },
    onCompleted: data => {
      const incomingData = [
        ...(data?.getCompanyList?.data?.items || []).map(item => ({
          ...item,
          id: `${item.id}`,
        })),
      ]
      if (disabledLoadMore) {
        incomingData.sort((a, b) => a.name.localeCompare(b.name))
      }
      setCompanies(prev =>
        disabledLoadMore ? incomingData : [...(pageNumber === 1 ? [] : prev), ...incomingData]
      )

      setHasMore(!disabledLoadMore && incomingData?.length < PAGE_SIZE)
    },
  })

  const handleCompanyListScroll = (
    event: Record<'scrollTop' | 'clientHeight' | 'scrollHeight', number>,
    hasMore: boolean
  ) => {
    if (!hasMore) return
    const listboxNode = event
    if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight) {
      setPageNumber(prev => prev + 1)
    }
  }

  const renderOption = (option: CompanyListItem) => {
    return <CompanyOption option={option} />
  }
  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setCompanies([])
    setPageNumber(1)
    setHasMore(true)
    setCompanyInput(value)
    !value && setValue(undefined)
  }

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <FCTTextField
        {...params}
        {...InputProps}
        inputProps={{
          ...params.inputProps,
          ...(value ? { value: '', placeholder: '' } : {}),
        }}
        InputProps={{
          ...params.InputProps,
          startAdornment: value && (
            <Box ml="6px">
              <CompanyOption hideLogo option={value} boxProps={{ minHeight: 'auto !important' }} />
            </Box>
          ),
        }}
        style={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
        label="Company Name/URL"
        placeholder="Company Name/URL"
        onKeyDown={e => {
          if (e.key === 'Backspace' && value) {
            setValue(undefined)
          }
        }}
      />
    )
  }

  return (
    <>
      <StyledAutocomplete
        options={companies}
        loading={loading}
        ListboxProps={{
          loading,
          handleClickHere: handleCantFindCompany,
          onScroll: debounce(
            (event: Record<'scrollTop' | 'clientHeight' | 'scrollHeight', number>) => {
              !loading && handleCompanyListScroll(event, hasMore)
            },
            100
          ),
        }}
        ListboxComponent={ListboxComponent as any}
        onInputChange={debounce(handleInputChange, 500)}
        renderInput={renderInput}
        renderOption={renderOption}
        getOptionSelected={(option: CompanyListItem, value: CompanyListItem) =>
          option.id === value.id
        }
        onChange={(e, v) => {
          if (typeof v === 'string') return
          setValue(v || undefined)
        }}
        getOptionLabel={(option: CompanyListItem) => companyInput}
        freeSolo={companyInput.length < 2}
        noOptionsText={noOptionsText}
        popupIcon={null}
      />
    </>
  )
}

export default CompanyListContainer

export const ListboxComponent = React.forwardRef(function (props: any, ref: any) {
  const classes = useStyles()
  const { children, role, className, loading, handleClickHere, ...other } = props
  const itemCount = Array.isArray(children) ? children.length + (loading ? 1 : 0) : 0
  const itemSize = ITEM_HEIGHT
  const visibleItem = 3.5

  return (
    <div ref={ref}>
      <Box {...other} style={{ overflowY: 'hidden', width: '100%' }}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              className={classes.list}
              height={(itemCount > visibleItem ? visibleItem : itemCount) * itemSize}
              width={width}
              rowHeight={itemSize}
              overscanCount={5}
              rowCount={itemCount}
              rowRenderer={props => {
                return children[props.index] ? (
                  React.cloneElement(children[props.index], {
                    style: props.style,
                  })
                ) : (
                  <Flex
                    key="loading"
                    style={props.style}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Loading />
                  </Flex>
                )
              }}
              role={role}
              onScroll={props.onScroll}
            />
          )}
        </AutoSizer>
        <Divider />
        <CantFindCompany p={'10px 14px'} handleClickHere={handleClickHere} />
      </Box>
    </div>
  )
})

type CompanyOptionProps = {
  option: CompanyListItem
  onClick?(e: React.SyntheticEvent): void
  hideLogo?: boolean
  boxProps?: BoxProps
}

export const CompanyOption = ({
  option,
  onClick = () => {},
  hideLogo,
  boxProps,
}: CompanyOptionProps) => {
  const classes = useStyles()
  return (
    <Flex
      className={classes.companyOption}
      alignItems="center"
      gap={16}
      onClick={onClick}
      {...boxProps}
    >
      {!hideLogo && (
        <Avatar
          alt="logo"
          src={option?.logoBucketUrl || ''}
          className={classes.avatar}
          children={option?.name?.toUpperCase().match(/\b(\w)/g)}
        />
      )}

      <Flex flexDirection="column" justifyContent="center" textAlign="left">
        <Typography className={classes.name}>{option?.name}</Typography>
        <Typography className={classes.website}>{option?.websiteUrl || ' '}</Typography>
      </Flex>
    </Flex>
  )
}

export const CantFindCompany = ({
  handleClickHere,
  ...props
}: { handleClickHere: MouseEventHandler } & BoxProps) => {
  const classes = useStyles()
  return (
    <Box {...props} role="presentation">
      <Typography className={classes.text} component="span">
        Can't find your company?{' '}
        <Link
          underline="none"
          style={{ cursor: 'pointer' }}
          onMouseDown={(e: React.MouseEvent<Element>) => {
            handleClickHere(e)
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          Click here to request your profile.
        </Link>
      </Typography>
    </Box>
  )
}

import { createStyles, makeStyles, Theme, withStyles, Box } from '@material-ui/core'
import FCTButton from 'components/Button/FCTButton'
import styled from 'styled-components'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      verticalAlign: 'baseline',
      minWidth: 32,
      marginTop: 1,
    },
  })
)

interface IKnowledgeHubSearchProps {
  onSearch(): void
  value: string
  setValue(v: string): void
  placeholder?: string
  name?: string
  role?: string
}

const KnowledgeHubSearch = ({
  placeholder = 'Search for articles...',
  value,
  name,
  role,
  setValue,
  onSearch,
}: IKnowledgeHubSearchProps) => {
  const classes = useStyles()

  const handleChange = (event: any) => {
    setValue(event.target.value)
  }

  return (
    <InputContainer>
      <Input
        name={name || ''}
        value={value}
        onChange={handleChange}
        onKeyPress={event => {
          if (event.key === 'Enter') onSearch()
        }}
        placeholder={placeholder}
        role={role}
      />
      <FCTButton variant="outlined" color="primary" className={classes.button} onClick={onSearch}>
        Search
      </FCTButton>
    </InputContainer>
  )
}

export default KnowledgeHubSearch

const InputContainer = withStyles(theme => ({
  root: {
    background: 'white',
    display: 'flex',
    alignItems: 'middle',
    height: '40px',
    padding: '5px',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '3px',
    position: 'relative',
    width: '100%',
  },
}))(Box)

const Input = styled('input').attrs(() => ({
  type: 'text',
}))`
  ${({ theme }: { theme: Theme }) => `
    padding: 0 40px 0 14px;
    width: 100%;
    box-sizing: border-box;
    background: white;
    border: 0;
    outline: 0;
    height: 100%;
    border-radius: 4px;
    color: ${theme.palette.grey[500]};
    &::placeholder {
      color: ${theme.palette.grey[500]};
      font-size: 14px;
    }
  `}
`

import {
  Avatar,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import React from 'react'
import Moment from 'react-moment'
import theme from '../../theme'
import { formatAbbreviation } from '../../utils/convert/number'
import { renameLogo } from '../../utils/convert/Title'
import { DashboardLastestInvestmentItemResult_getDashboardLastestInvestments_investments } from '../../__generated__/DashboardLastestInvestmentItemResult'
import FCTTooltip from '../Tooltip/Tooltip'
import FCTIcon from '../Icon/Icon'
import { FCTTooltipMove } from '../Tooltip/TooltipMove'

const useStyles = makeStyles({
  container: {
    height: '100%',
    padding: '0 11px 0 0',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  table: {
    width: '100%',
  },
  content: {
    whiteSpace: 'normal',
    fontWeight: 600,
    fontSize: 14,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1,
  },
  investorText: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  avatar: {
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    width: 24,
    height: 24,
    fontSize: 10,
  },
  linkTo: {
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.grey[800],
    alignItems: 'center',
    cursor: 'pointer',
  },
  linkShow: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '4px 10px',
    margin: '10px auto',
    borderRadius: 3,
  },
  showText: {
    fontSize: 14,
    lineHeight: '22px',
    color: '#1DA462',
    fontWeight: 'normal',
    paddingRight: '10px',
  },
  linkNavigateInvestor: {
    textDecoration: 'none',
    color: theme.palette.grey[100],
    '&:hover': {
      opacity: 0.8,
    },
    alignItems: 'center',
  },
  contentTooltip: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

const StyledTableCell = withStyles(() => ({
  root: {
    padding: '9px 16px',
  },
  head: {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #E7ECF3',
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    fontWeight: 600,
    whiteSpace: 'nowrap',
    maxWidth: 400,
  },
  body: {
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.grey[800],
    border: 0,
    whiteSpace: 'nowrap',
  },
}))(TableCell)

export const StyledTableRow = withStyles(() => ({
  root: {
    border: '1px solid #FFF',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[50]}`,
    },
    '&:hover': {
      background: '#FFFFFF',
      outline: '1px solid #E7ECF3',
      boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    },
  },
}))(TableRow)

const LatestInvestmentsTable = (props: ILatestInvestmentsProps) => {
  const classes = useStyles()

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table}>
        <TableHead className={classes.stickyHeader}>
          <TableRow>
            <StyledTableCell align="left">Company Name</StyledTableCell>
            <StyledTableCell align="left">Product L1</StyledTableCell>
            <StyledTableCell align="left">Amount</StyledTableCell>
            <StyledTableCell align="left">Round</StyledTableCell>
            <StyledTableCell align="left">Investor</StyledTableCell>
            <StyledTableCell align="left">Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data?.map(item => (
            <StyledTableRow key={item?.id} data-testid="dashboard-latest-investment-item">
              <StyledTableCell align="left">
                <div style={{ display: 'flex' }}>
                  <div
                    className={classes.linkTo}
                    onClick={() => props.navigateProfile(item.company_id)}
                  >
                    <FCTTooltip
                      title={<Typography style={{ fontSize: 12 }}>{item.description}</Typography>}
                      arrow
                      placement="top"
                    >
                      <div className={classes.linkTo}>
                        <div className={classes.logoWrapper}>
                          <Avatar
                            alt={item.company_name || ''}
                            variant="square"
                            src={item.logo_url || ''}
                            className={classes.avatar}
                            children={renameLogo(item.company_name)}
                          />
                        </div>
                        <div className={classes.content}>{item.company_name}</div>
                      </div>
                    </FCTTooltip>
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell align="left">
                {item.products?.find(p => p.level === 1)?.dimension_name}
              </StyledTableCell>
              <StyledTableCell align="left">
                {item?.deal_amount
                  ? item.deal_amount === 0
                    ? 'Undisclosed '
                    : '$ ' + formatAbbreviation(item.deal_amount || 0)
                  : 'Undisclosed'}
              </StyledTableCell>
              <StyledTableCell align="left">{item?.deal_type}</StyledTableCell>
              <StyledTableCell align="left">
                {item?.investor_names ? (
                  <FCTTooltipMove
                    text={
                      <div className={classes.contentTooltip}>
                        {item?.investors?.map((item2, index) => (
                          <a
                            className={classes.linkNavigateInvestor}
                            key={item2.investor_id}
                            target="_blank"
                            href={`/app/investor-profile/${item2.investor_id}`}
                          >
                            {item2.investor_name}
                            {index + 1 !== item?.investors?.length ? <span>,&nbsp;</span> : ''}
                          </a>
                        ))}
                      </div>
                    }
                    isHover={true}
                    isMove={false}
                  >
                    <div className={classes.investorText}>{item?.investor_names}</div>
                  </FCTTooltipMove>
                ) : (
                  'Undisclosed'
                )}
              </StyledTableCell>
              <StyledTableCell align="left">
                <Moment format="MMM D, YYYY">{item?.announced_date || ''}</Moment>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <div
        className={classes.linkShow}
        onClick={() => props.navigateListView('investments')}
        data-testid="dashboard-latest-investment-button"
      >
        <Typography className={classes.showText}>Show more</Typography>
        <FCTIcon name="arrowright" width={8} height={8} color={`${theme.palette.grey[500]}`} />
      </div>
    </TableContainer>
  )
}
export default LatestInvestmentsTable

interface ILatestInvestmentsProps {
  data: DashboardLastestInvestmentItemResult_getDashboardLastestInvestments_investments[]
  navigateProfile: (id: string | number) => void
  navigateListView: (type: string) => void
}

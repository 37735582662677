import { gql } from '@apollo/client'

const Fragment = {
  CompanyInfoLocation: gql`
    fragment CompanyInfoLocation on CompanyInfoLocation {
      location_id
      headquarter
      address
      company_id
      country
      city
      region1_name
      region2_name
      region3_name
      country_int
    }
  `,
}

export const GET_COMPANY_INFORMATION = gql`
  query GetCompanyInformation($id: Int!) {
    getCompanyInformation(id: $id) {
      name
      description
      longDescription
      logoUrl
      status
      statusDate
      statusWho

      companyInformation {
        foundedDate
        totalEquityFunding
        latestFundingStage
        employees {
          range
          exact
        }
        websiteUrl
        headquarter
        headquarterObject {
          ...CompanyInfoLocation
        }
        twitterUrl
        linkedinUrl
        facebookUrl
        crunchbaseUrl
        dealroomUrl
        address
        hasEquityRound
        femaleFounder
        companyAlias {
          companyId
          aliasId
          companyAlias
        }
        otherLocations {
          ...CompanyInfoLocation
        }
        associated_investor_id
        investorHasDeal
      }
    }
  }
  ${Fragment.CompanyInfoLocation}
`

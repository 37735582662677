import { ISearchCompany } from '../../store/operations/queries/local/rest/SearchCompanies'
import { makeStyles } from '@material-ui/core'
import FCTList from '../List/FCTList'
import { Link, useHistory } from 'react-router-dom'
import { IHit } from '../../store/operations/queries/local/rest/RestfulResponse'
import { calculateStats } from './utils'
import { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import theme from '../../theme'
import FCTIcon from '../Icon/Icon'
import SearchResultItem from './SearchResultItem'
import Legend from '../Legend'
import { useSelector } from 'react-redux'
import { selectSearchOption } from '../../store/features/search/SearchSelector'
import { resetSearchOption } from '../../store/features/search/SearchSlice'
import { applySearchOptToCompanyDeal } from '../../store/features/company-deal/CompanyDealAction'
import { FCTLink } from '../Link/FCTLink'
import { uniq } from 'lodash'
import SearchResultChips from './SearchResultChips'
import { TagGroupMapColor } from './consts'
import { TagGroups } from './enums'

const useStyles = makeStyles(theme => ({
  listHeader: {
    borderBottom: '1px solid #E7ECF3',
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  listHeaderLegend: {
    paddingTop: '18px',
  },
  listReadMore: {
    paddingLeft: 26,
    paddingTop: 16,
    paddingBottom: 32,
    width: '380px',
    textAlign: 'right',
  },
  link: {
    color: '#29BA74',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 400,
  },
  blankTitle: {
    fontSize: '1.2em',
    fontWeight: 600,
    color: theme.palette.grey[400],
  },
  blankContent: {
    maxWidth: '500px',
    textAlign: 'center',
    lineHeight: 1.5,
  },
  blank: {
    height: 'calc(100vh - 215px)',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))
export interface ISearchResultProps {
  companies?: IHit<ISearchCompany>[]
  total?: number
  searchKey: string
  totalCompanies?: number
  getTooltip?(name: string, type: TagGroups.Tag | TagGroups.Dimension): string
}

export default ({ getTooltip = () => '', ...props }: ISearchResultProps) => {
  const history = useHistory()
  const classes = useStyles()
  const [openNotify, setOpenNotify] = useState<boolean>(false)
  const searchOptions = useSelector(selectSearchOption)
  // data for legend items
  const legendItems = [
    { name: 'Taxonomy', color: TagGroupMapColor.get(TagGroups.Taxonomy) },
    { name: 'HQ Geography', color: TagGroupMapColor.get(TagGroups.HQGeography) },
    { name: 'Financing', color: TagGroupMapColor.get(TagGroups.Financing) },
    { name: 'Keywords', color: TagGroupMapColor.get(TagGroups.WebsiteKeyword) },
    { name: 'Product Names', color: TagGroupMapColor.get(TagGroups.ProductName) },
  ]

  const onShowMore = () => {
    if ((props as any).total >= 10000) {
      setOpenNotify(true)
    }
  }

  return (
    <>
      {!!props.total && (
        <FCTList
          renderHeader={() => (
            <>
              {!!props.total && (
                <div className={classes.listHeader}>
                  <div className={classes.listHeaderLegend}>
                    <Legend legendItems={legendItems} data={null} />
                  </div>
                </div>
              )}
            </>
          )}
          items={props?.companies}
          childrenStyle={{ height: 'calc(100vh - 420px)' }}
          getKey={item => item?.fields?.company_id}
          renderItem={(item: IHit<ISearchCompany>) => {
            const tagStats = calculateStats(item, TagGroups.Tag)
            const dimensionStats = calculateStats(item, TagGroups.Dimension)

            const taxonomyChip = uniq([
              ...Array.from((tagStats?.keywords || new Set()).values()),
              ...Array.from((dimensionStats?.keywords || new Set()).values()),
            ]).map(item => ({
              value: item,
              group: TagGroups.Taxonomy,
              tooltip:
                getTooltip(item, TagGroups.Tag) || getTooltip(item, TagGroups.Dimension) || '',
            }))

            const geographyStats = calculateStats(item, TagGroups.HQGeography)
            const geographyChip = Array.from(
              (geographyStats?.keywords || new Set()).values()
            ).map(item => ({ value: item, group: TagGroups.HQGeography }))

            const investorNameStats = calculateStats(item, TagGroups.InvestorName)
            const investorKeyWords = Array.from((investorNameStats?.keywords || new Set()).values())
            const financingStats = calculateStats(item, TagGroups.Financing)

            const keywordStats = calculateStats(item, TagGroups.WebsiteKeyword)
            const keywordChip = Array.from(
              (keywordStats?.keywords || new Set()).values()
            ).map(item => ({ value: item, group: TagGroups.WebsiteKeyword }))

            const productNameStats = calculateStats(item, TagGroups.ProductName)
            const productNameChip = Array.from(
              (productNameStats?.keywords || new Set()).values()
            ).map(item => ({ value: item, group: TagGroups.ProductName }))

            const financingChip = uniq([
              ...investorKeyWords,
              ...Array.from((financingStats?.keywords || new Set()).values()),
            ]).map(item => ({ value: item, group: TagGroups.Financing }))

            const getInvestorId = (value: string) => {
              if (investorKeyWords.includes(value)) {
                investorKeyWords.includes(value)
                const investorIdName = item.fields.investor_id_names.find(s => s.includes(value))
                const [investorId] = investorIdName?.split(':') || []
                return investorId
              }
              return undefined
            }

            return (
              <SearchResultItem
                ChipComponent={
                  <SearchResultChips
                    data={[
                      ...taxonomyChip,
                      ...geographyChip,
                      ...financingChip,
                      ...keywordChip,
                      ...productNameChip,
                    ]}
                    isClickable={chip => !!getInvestorId(chip.value)}
                    onClickChipItem={chip => {
                      const investorId = getInvestorId(chip.value)
                      if (investorId) {
                        history.push({
                          pathname: `/app/investor-profile/${investorId}`,
                          search: '',
                        })
                      }
                    }}
                  />
                }
                searchOptions={searchOptions}
                company={item}
              />
            )
          }}
        />
      )}
      {!!props.total && (
        <div className={classes.listReadMore}>
          <FCTLink
            className={classes.link}
            reactLink={
              !(props as any).total
                ? `/redirect?cb=/app/list-view&action=${resetSearchOption.type}`
                : (props as any).total < 10000
                ? `/redirect?cb=/app/list-view&action=${applySearchOptToCompanyDeal.type}`
                : undefined
            }
            onClick={onShowMore}
          >
            Show more
            <FCTIcon
              style={{ marginTop: 4, marginLeft: 11 }}
              name="arrowright"
              width={10}
              height={10}
              color={`${theme.palette.secondary.main}`}
            />
          </FCTLink>
        </div>
      )}
      {!props.total && (
        <div className={classes.blank}>
          <FCTIcon name="searchPlus" width={36} height={36} color={theme.palette.grey[400]} />
          <p className={classes.blankTitle}>No result found</p>
          <div className={classes.blankContent}>
            <span>
              Your search did not return any results. Try a different search term or&nbsp;
            </span>
            <Link
              to={`/redirect?cb=/app/analysis&action=${resetSearchOption.type}`}
              className={classes.link}
              style={{ fontSize: 16, fontWeight: 600 }}
            >
              <span>try navigating taxonomy.</span>
            </Link>
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openNotify}
        autoHideDuration={5000}
        onClose={() => setOpenNotify(false)}
        message="The current search is too broad. Please refine your keywords to narrow down the results."
      />
    </>
  )
}

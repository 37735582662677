import { withStyles } from '@material-ui/core/styles'
import theme from 'theme'
import FCTSliderSingle from 'components/Slider/FCTSliderSingle'
import { SliderProps } from 'material-ui'
import { useState, useMemo, useEffect } from 'react'

const CustomSliderSingle = withStyles({
  root: {
    color: theme.palette.primary.main,
    height: 4,
    margin: '10px 10px 0',
    width: 'calc(100% - 20px)',
  },
  rail: {
    height: 4,
    borderRadius: 4,
    background: '#EEF2F6',
    opacity: 1,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.contrastText}`,
    marginTop: -8,
    marginLeft: -12,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    '&:focus, &:hover, &$active': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    },
  },
  active: {},
  track: {
    height: 4,
    borderRadius: 4,
  },
  mark: {
    height: 0,
  },
  markLabel: {
    top: -12,
    fontSize: 12,
    fontWeight: 600,
    color: '#334155',
    transform: 'translateX(-100%)',
    '&[data-index="0"]': {
      transform: 'translateX(-10%)',
    },
  },
})((props: CustomSliderSingleProps) => {
  const { defaultRealValue, dataMarks, onChangeValue, step, ...rest } = props
  const [value, setValue] = useState(0)

  useEffect(() => {
    const defaultValue = dataMarks.findIndex(e => defaultRealValue == e)
    defaultValue !== value && setValue(defaultValue)
  }, [defaultRealValue])

  const convertValueToRealValue = (value: number) => {
    const markIndex = Math.floor(value)
    return dataMarks[markIndex]
  }

  const marks = useMemo(() => {
    return dataMarks.map((e, idx) => {
      if (idx === 0)
        return {
          value: idx,
          label: `${e}`,
        }
      else if (idx === dataMarks.length - 1)
        return {
          value: idx,
          label: convertValueToRealValue(value),
        }
      return { value: idx }
    })
  }, [dataMarks, value])

  const handleChangeCommitted = (event: React.ChangeEvent<{}>, argValue: number | number[]) => {
    if (Array.isArray(argValue)) return
    onChangeValue(convertValueToRealValue(argValue))
  }

  return (
    <FCTSliderSingle
      {...rest}
      value={value}
      min={0}
      max={marks[marks.length - 1]?.value}
      step={step || null}
      marks={marks}
      onChange={(event, argValue) => {
        if (Array.isArray(argValue)) return
        setValue(argValue)
      }}
      onChangeCommitted={handleChangeCommitted}
      valueLabelDisplay="off"
    />
  )
})

export default CustomSliderSingle

export interface CustomSliderSingleProps extends SliderProps {
  defaultRealValue?: number | string
  dataMarks: (string | number)[]
  onChangeValue: (newValue: string | number) => void
  step?: number
}

import { useMutation, useQuery } from '@apollo/client'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { ETypeDialog, FCTDialog } from '../../components/Dialog/FCTDialog'
import FCTIcon from '../../components/Icon/Icon'
import FCTTextField from '../../components/TextField/FCTTextField'
import FCTButton from '../../components/Button/FCTButton'
import FCTRadio from '../../components/Radio/FCTRadio'
import { GetWatchListContext } from '../../features/watch-list/GetWatchList'
import { CREATE_WATCH_LIST } from '../../store/operations/mutations/SetCreateWatchList'
import { EMessageType, WatchListMessage } from '../../templates/WatchListHandling/WatchListMessage'
import { WatchListToastsError } from '../../templates/WatchListHandling/WatchListToastsError'
import {
  EToastType,
  WatchListToastsSuccess,
} from '../../templates/WatchListHandling/WatchListToastsSuccess'
import { commonErrorHandler } from '../../utils/CommonErrorHandler'
import { useHistory } from 'react-router-dom'
import { useAnalysisFilter } from '../../store/features/analysis/AnalysisHook'
import theme from '../../theme'
import { EWatchListType } from '../../components/WatchList/SelectTabWatchList'
import { FCTTooltipMove } from '../../components/Tooltip/TooltipMove'
import { useCompanyDealFilter } from '../../store/features/company-deal/CompanyDealHook'
import { GET_DEAL_FILTER_DATA } from '../../store/operations/queries/GetDealFilterData'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '../../__generated__/GetDealFilterDataResult'
import { stringifyFilterOptionWL } from '../../utils/WatchList'
import mixpanel from '../../mixpanel'
import { pathnameTracking } from '../../utils/Tracking'
import { checkProductIdAtlas } from 'containers/SunburstContainer'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: '16px 20px',
  },
  rootText: {
    width: '100%',
  },
  title: {
    fontSize: 14,
    marginBottom: 16,
  },
  textInput: {
    width: '100%',
  },
  createList: {
    justifyContent: 'left',
  },
  error: {
    fontSize: 14,
    marginTop: 8,
    color: theme.palette.error.main,
  },
  createListText: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 500,
    marginLeft: 8,
  },
  optionSelect: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[50]}`,
    },
  },
  notiText: {
    fontSize: 14,
    lineHeight: '22px',
  },
  buttonGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    gridGap: '10px',
    marginBottom: '15px',
  },
  button: {
    width: '100%',
    padding: '2px 3px',
    justifyContent: 'start',
    fontSize: '14px',
    lineHeight: '22px',
  },
  active: {
    border: '1px solid #29BA74',
  },
}))

export const CreateWatchList = (props: CreateWatchListProps) => {
  const classes = useStyles()
  const { selectedList, selectedName, handleCompleteCreate, disableFilter = false } = props
  const [textInput, setTextInput] = useState<string>('')
  const { addToast } = useToasts()
  const [open, setOpen] = useState<boolean>(false)
  const [option, setOption] = useState<EWatchListType>(EWatchListType.companyIDs)
  const history = useHistory()
  const getWatchList = useContext(GetWatchListContext)
  const [analysisFilter, __] = useAnalysisFilter()
  const [companyDealFilter, ___] = useCompanyDealFilter()
  const [createWatchList, { loading: createLoading }] = useMutation(CREATE_WATCH_LIST)

  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA)
  const filterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData

  const hasListView = history?.location.pathname.includes('/list-view')

  const handleAgree = useCallback(
    (option: EWatchListType, watchlistName: string) => {
      const pathname = history.location.pathname
      const companyInfo = pathname.includes('/profile')
        ? {
            companyID: selectedList?.[0],
            companyName: selectedName,
          }
        : {}

      let input: CreateWatchListInput = {
        watchlistName: watchlistName,
        companyIds: selectedList.map(e => +e),
      }
      if (option == EWatchListType.filterConditions) {
        input.filterOption = stringifyFilterOptionWL(
          hasListView
            ? companyDealFilter
            : {
                ...analysisFilter,
                selectedProductId: checkProductIdAtlas(
                  analysisFilter.selectedProductId,
                  analysisFilter.selectedBusinessLineId
                ),
              },
          filterData
        )
      }
      createWatchList({
        variables: {
          input,
        },
      })
        .then(res => {
          mixpanel.track(`${pathnameTracking(history.location.pathname)}_Create Watchlist`, {
            watchlistId: res?.data?.createWatchlist,
            watchlistName: watchlistName,
            watchlistSaveType: option == EWatchListType.filterConditions ? 'Filter' : 'ID',
            ...companyInfo,
          })
          addToast(
            <WatchListToastsSuccess
              type={EToastType.NormalAddCompaniesToWL}
              selectedList={selectedList}
              watchListName={watchlistName}
            />,
            {
              appearance: 'success',
            }
          )
          setOpen(false)
          getWatchList.fnWatchList()
          handleCompleteCreate()
        })
        .catch(
          commonErrorHandler((err: any) => {
            addToast(<WatchListToastsError res={err.graphQLError.message} />, {
              appearance: 'error',
            })
          })
        )
    },
    [selectedList]
  )

  useEffect(() => {
    setTextInput('')
  }, [open])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(event.target.value)
  }

  return (
    <>
      <div
        onClick={() => {
          setOpen(true)
        }}
        className={clsx(classes.optionSelect, classes.createList)}
        data-testid="createWLBtn"
      >
        <FCTIcon name="plusBorder" width={16} height={16} color={theme.palette.primary.light} />
        <div className={classes.createListText}>Create New</div>
      </div>
      <FCTDialog
        open={open}
        setOpen={setOpen}
        title="Create a New Watchlist"
        type={ETypeDialog.Create}
        titleBtnAgree="Create"
        handleAgree={() => handleAgree(option, textInput)}
        disableBtnAgree={createLoading || textInput.trim() === ''}
        minHeight={140}
        loadingBtnAgree={createLoading || getWatchList.refetchLoading}
      >
        <div className={classes.dialogContent}>
          <Typography className={classes.title}>
            <WatchListMessage
              type={EMessageType.Create}
              selectedName={selectedName}
              selectedList={selectedList}
            />
          </Typography>
          <div className={classes.buttonGroup}>
            <div>
              <FCTTooltipMove text="Track only the companies selected" isMove={false}>
                <FCTButton
                  variant="outlined"
                  className={
                    option == EWatchListType.companyIDs
                      ? clsx(classes.button, classes.active)
                      : classes.button
                  }
                  onClick={() => setOption(EWatchListType.companyIDs)}
                >
                  <FCTRadio checked={option == EWatchListType.companyIDs} />
                  Company IDs
                </FCTButton>
              </FCTTooltipMove>
            </div>
            <div>
              <FCTTooltipMove
                text="Automatically update watchlist based on the conditions selected in filter"
                isMove={false}
              >
                <FCTButton
                  variant="outlined"
                  className={
                    option == EWatchListType.filterConditions
                      ? clsx(classes.button, classes.active)
                      : classes.button
                  }
                  disabled={history?.location.pathname.includes('search') || disableFilter}
                  onClick={() =>
                    !history.location.pathname.includes('search') &&
                    setOption(EWatchListType.filterConditions)
                  }
                >
                  <FCTRadio checked={option == EWatchListType.filterConditions} />
                  Filter Conditions
                </FCTButton>
              </FCTTooltipMove>
            </div>
          </div>
          <FCTTextField
            value={textInput}
            className={classes.rootText}
            placeholder="Enter Watchlist name"
            onChange={handleInputChange}
          ></FCTTextField>
        </div>
      </FCTDialog>
    </>
  )
}

export interface CreateWatchListProps {
  selectedList: string[]
  selectedName?: string
  handleCompleteCreate: () => void
  disableFilter?: boolean
}

interface CreateWatchListInput {
  watchlistName: string | null
  companyIds: number[] | null
  filterOption?: string | null
}

import { useState, useEffect, CSSProperties } from 'react'
import { makeStyles } from '@material-ui/core'
import { useResizeDetector } from 'react-resize-detector'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  fullContainer: {
    height: '100%',
    width: '100%',
    flex: 1,
  },
  chartWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%',
    overflow: 'auto',
  },
})

interface Props {
  Chart: any // TODO new (...args: any) => Chart
  initProps: any
  updateProps: any
  margin?: any
  extraWidth?: number
  extraHeight?: number
  style?: CSSProperties
}

const ChartWrapper = ({ Chart, initProps, updateProps, margin, style, ...props }: Props) => {
  const classes = useStyles()
  const [chart, setChart] = useState<any>(null)
  const [isChartSet, setIsChartSet] = useState<boolean>(false)
  const { width, height, ref } = useResizeDetector()
  const history = useHistory()
  const isChartAnalysisScreen = history.location.pathname.includes('/analysis/charts/')

  useEffect(() => {
    if (!chart) {
      setChart(new Chart(ref.current, initProps))
    } else {
      if (isChartSet) {
        chart.update(updateProps)
      }
    }
  }, [chart, isChartSet, updateProps])

  useEffect(() => {
    if (ref && chart) {
      const { offsetWidth, offsetHeight } = ref.current.parentElement
      chart.setDimensions(
        offsetWidth + (props.extraWidth || 0),
        offsetHeight + (props.extraHeight || 0),
        margin
      )
      setIsChartSet(true)
    }
  }, [ref, chart, width, height])

  return (
    <div
      className={classes.fullContainer}
      style={{ minWidth: isChartAnalysisScreen ? 800 : 'unset', ...style }}
    >
      <div
        className={classes.chartWrapper}
        style={{
          width: props.extraWidth
            ? (ref?.current?.parentElement.offsetWidth || 0) + (props.extraWidth || 0)
            : null,
        }}
        ref={ref}
        data-testid="chartWrapper"
      ></div>
    </div>
  )
}

export default ChartWrapper

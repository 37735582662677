import { AuthenticationTemplate } from 'templates/AuthenticationTemplate/AuthenticationTemplate'
import { RequestAccessContainer } from 'containers/RequestAccessContainer'

export const GetAccessPage = () => {
  return (
    <AuthenticationTemplate width="500px">
      <RequestAccessContainer />
    </AuthenticationTemplate>
  )
}

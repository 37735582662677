import { Box } from '@material-ui/core'
import { InformationItem } from 'components/InvestorProfile/Sidebar/InvestorInformation'
import { TaxonomyFilterLabel, ETaxonomyCategory } from 'utils/FlattenEcoSystem'
import { AvatarSection } from './AvatarSection'
import { ISearchInputDetail } from '../interfaces'
import { DescriptionSection } from './DescriptionSection'
import { TaxonomySection } from './TaxonomyOverviewSection'

export const CompanyOverviewSection = ({
  option,
  onNavigation,
}: {
  option: ISearchInputDetail
  onNavigation: () => void
}) => {
  const {
    logo,
    url,
    dimension1s,
    dimension2s,
    dimension3s,
    description,
    founded_year,
    ftes_range,
    industry,
    city,
    country,
  } = option.companyMetaData[0] || {}

  const dimensions = [dimension1s, dimension2s, dimension3s].filter(Boolean)

  const HQLocation = [country, city].filter(Boolean).join(', ')
  return (
    <>
      <Box>
        <AvatarSection onNavigation={onNavigation} name={option.name} logo={logo} url={url} />
        <DescriptionSection description={description || ''} />
        <Box>
          <InformationItem
            title="Industry"
            data={
              industry?.map(
                category => TaxonomyFilterLabel.get(category as ETaxonomyCategory) || null
              ) || [null]
            }
            icon="stars"
            widthXl={4}
            widthSm={5}
          ></InformationItem>
          <TaxonomySection dimensionGroups={dimensions} category={industry[0]} />
          <InformationItem
            title="HQ location"
            data={[HQLocation || null]}
            icon="company"
            widthXl={4}
            widthSm={5}
          ></InformationItem>
          <InformationItem
            title="Founded year"
            data={[founded_year || null]}
            icon="calendar"
            widthXl={4}
            widthSm={5}
          ></InformationItem>
          <InformationItem
            title="Number of employees"
            data={[ftes_range || null]}
            icon="team"
            widthXl={4}
            widthSm={5}
          ></InformationItem>
        </Box>
      </Box>
    </>
  )
}

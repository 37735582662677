export const ToolTipTemplate = (
  header: string,
  content: string,
  navigate: string,
  topCompanies: string
) => {
  return `
    <div class="tip-content">
      <div class="tip-header">
        <div class="tip-header-text">${header}</div>
        ${navigate}
      </div>
      <div class="tip-container-body">
        ${content}
      </div>
      ${topCompanies}
    </div>
  `
}

export const ToolTipDonutChart = (title: string, color: string, total: string, percent: string) => {
  return `
    <div class="tip-container-donut">
      <div class="tip-color-donut" style="background-color: ${color};"></div>
      <div class="tip-text-donut">${title}: ${percent} (${total})</div>
    </div>
  `
}

export enum SearchMetaDataType {
  COMPANY_ID = 'company_id',
  INVESTOR = 'investor',
  ALIAS = 'alias',
  ACQUIRER = 'acquirer',
  DEAL_TYPES = 'deal_types',
  LATEST_DEAL_TYPE = 'last_deal_type',
  INVESTOR_TYPES = 'types',
  OTHERS = 'other_data',
}

export enum SearchFields {
  NAME = 'name',
  INVESTOR_NAMES = 'investor_names',
  INDUSTRY = 'industry',
  INVESTOR_TYPES = 'investor_types',
  LOCATION_CITIES = 'location_cities',
  LOCATION_COUNTRIES = 'location_countries',
  FINTECH_PRODUCT = 'fintech_product',
  INSURTECH_PRODUCT = 'insurtech_product',
  REGTECH_PRODUCT = 'regtech_product',
  ACQUISITION_TYPES = 'acquisition_types',
  SECTORS = 'sectors',
  VALUE_CHAINS = 'value_chains',
  OPERATING_MODELS = 'operating_models',
  DEAL_TYPES = 'deal_types',
  LATEST_DEAL_TYPE = 'last_deal_type',
  RISKS = 'risks',
  LOCATION_REGION1_NAMES = 'location_region1_names',
  LOCATION_REGION2_NAMES = 'location_region2_names',
  LOCATION_REGION3_NAMES = 'location_region3_names',
}

export enum DescriptionFields {
  INDUSTRY_DESCRIPTION = 'industry_description',
  SECTORS_DESCRIPTION = 'sectors_description',
  VALUE_CHAINS_DESCRIPTION = 'value_chains_description',
  OPERATING_MODELS_DESCRIPTION = 'operating_models_description',
  RISKS_DESCRIPTION = 'risks_description',
  FINTECH_PRODUCT_DESCRIPTION = 'fintech_product_description',
  REGTECH_PRODUCT_DESCRIPTION = 'regtech_product_description',
  INSURTECH_PRODUCT_DESCRIPTION = 'insurtech_product_description',
}

export enum TopResultsFields {
  INDUSTRY_TOP_COMPANIES = 'industry_top_companies',
  SECTORS_TOP_COMPANIES = 'sectors_top_companies',
  VALUE_CHAINS_TOP_COMPANIES = 'value_chains_top_companies',
  OPERATING_MODELS_TOP_COMPANIES = 'operating_models_top_companies',
  RISKS_TOP_COMPANIES = 'risks_top_companies',
  FINTECH_PRODUCT_TOP_COMPANIES = 'fintech_product_top_companies',
  REGTECH_PRODUCT_TOP_COMPANIES = 'regtech_product_top_companies',
  INSURTECH_PRODUCT_TOP_COMPANIES = 'insurtech_product_top_companies',
  INVESTOR_TYPES_TOP_INVESTORS = 'investor_types_top_investors',
  ACQUISITION_TYPES_TOP_INVESTORS = 'acquisition_types_top_investors',
}

export enum CategoriesName {
  Industry = 'Industry',
  BusinessLine = 'Business Line',
  ValueChain = 'Value Chain',
  OperatingModel = 'Operating Model',
  Risk = 'Risk',
  ProductLine = 'Product Line',
  TagGroup = 'Tags',
  ProductLineID = 'Product Line ID',
  BusinessModel = 'Business Model',
  DistributionModel = 'Distribution Model',
}

export enum TagGroups {
  Tag = 'Tag',
  Dimension = 'Dimension',
  Taxonomy = 'Taxonomy',
  HQGeography = 'HQ Geography',
  Financing = 'Financing',
  InvestorName = 'InvestorName',
  Text = 'Text',
  Report = 'Report',
  UnknownCategory = 'UnknownCategory',
  WebsiteKeyword = 'Keywords',
  ProductName = 'ProductName',
}

import { useState } from 'react'
import { Box, Link, makeStyles, Theme, Typography } from '@material-ui/core'
import { useOktaAuth } from '@okta/okta-react'
import AuthError from '../components/Authentication/AuthError'
import { SignInSignUpTitle } from '../components/Authentication/SignInSignUp'
import SignInWithCredentialsForm from '../components/Authentication/SignInWithCredentialsForm'
import { ISignInWithCredentialsFormData } from '../components/Authentication/SignInWithCredentialsForm/SignInWithCredentialsForm'
import config from '../core/config/auth'
import { onError } from '../utils/sentry'
import ForgotPasswordDialogContainer from './ForgotPasswordContainer'
import PasswordExpiredDialog, {
  usePasswordExpired,
} from 'components/PasswordExpired/PasswordExpiredDialog'
import changePassword from 'axios/changePassword'

const SignInContainer = () => {
  const classes = useStyles()
  const TYPE = 'signIn'
  const { oktaAuth } = useOktaAuth()

  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const {
    passwordExpiredData,
    setPasswordExpiredData,
    expiredToken,
    setExpiredToken,
  } = usePasswordExpired()

  const handlePasswordExpired = (
    data: {
      firstName: string
      lastName: string
      businessEmail: string
    },
    stateToken: string
  ) => {
    setPasswordExpiredData(data)
    setExpiredToken(stateToken)
  }

  const handleSignInWithCredentials = async (data: ISignInWithCredentialsFormData) => {
    try {
      setError('')

      const res = await oktaAuth
        .signIn({
          username: data.username,
          password: data.password,
        })
        .catch(e => {
          throw new Error('The business email or password is incorrect')
        })

      if (res.status === 'SUCCESS') {
        await oktaAuth.signInWithRedirect({
          sessionToken: res.sessionToken,
          originalUri: window.location.href,
          redirectUri: config.redirectUri + '/login',
        })
      } else if (res.status === 'PASSWORD_EXPIRED') {
        const { firstName = '', lastName = '', login: businessEmail = '' } = res.user?.profile || {}
        handlePasswordExpired({ firstName, lastName, businessEmail }, res.data?.stateToken || '')
      } else if (res.status === 'LOCKED_OUT') {
        throw new Error('Your account has been locked due to too many failed login attempts')
      } else {
        throw new Error('The business email or password is incorrect')
      }
    } catch (e: any) {
      onError(e)
      setError(e.message)
    }
  }

  return (
    <>
      <SignInSignUpTitle type={TYPE} />
      {error && <AuthError error={error} />}
      <SignInWithCredentialsForm
        handleSubmit={handleSignInWithCredentials}
        ForgotPasswordElement={
          <Box textAlign="left">
            <Link className={classes.link} onClick={() => setOpen(true)}>
              Forgot Password?
            </Link>
          </Box>
        }
      />
      <DontHaveAccountText />
      <ForgotPasswordDialogContainer open={open} setOpen={setOpen} />
      {!!passwordExpiredData && (
        <PasswordExpiredDialog
          validatePasswordMaterials={{
            businessEmail: passwordExpiredData.businessEmail,
            firstName: passwordExpiredData.firstName,
            lastName: passwordExpiredData.lastName,
          }}
          handleSubmit={async data => {
            await changePassword({
              newPassword: data.password,
              oldPassword: data.oldPassword,
              token: expiredToken,
            })
          }}
          handleCancel={() => {
            setPasswordExpiredData(undefined)
            setExpiredToken('')
          }}
        />
      )}
    </>
  )
}

export default SignInContainer

const useStyles = makeStyles((theme: Theme) => ({
  strike: {
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '0',
      width: '100%',
      height: '1px',
      background: theme.palette.divider,
    },
    '& > span': {
      position: 'relative',
      background: 'white',
      padding: '0 8px',
      fontSize: '14px',
      lineHeight: '22px',
      color: theme.palette.grey[500],
    },
  },

  dontHaveAccount: {
    textAlign: 'center',
    marginTop: '20px',
    color: theme.palette.grey[800],
    '& > *': {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },

  textButton: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  link: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    lineHeight: '18px',
    cursor: 'pointer',
  },
}))

const DontHaveAccountText = () => {
  const classes = useStyles()
  const MAILTO = {
    TO: 'fct.portal@bcg.com',
    SUBJECT: 'Enquiry - sign up to FCT',
    BODY:
      'Dear%20FCT%2C%20I%20would%20like%20to%20know%20more%20about%20the%20FCT%20platform.%20%0D%0AThank%20you.%0D%0ASignature%2C%20name%0D%0A%0D%0A',
  }

  return (
    <Box className={classes.dontHaveAccount}>
      <Typography>Don't have an account yet?</Typography>
      <Link
        className={classes.textButton}
        href={`mailto:${MAILTO.TO}?subject=${MAILTO.SUBJECT}&body=${MAILTO.BODY}`}
      >
        Speak to our team today
      </Link>
    </Box>
  )
}

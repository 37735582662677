import React, { WheelEventHandler } from 'react'
import { createStyles, makeStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Dialog, Typography } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent, { DialogContentProps } from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FCTButton from '../Button/FCTButton'
import Loading from '../Loading'
import { FCTTooltipMove } from '../Tooltip/TooltipMove'
import theme from '../../theme'
import FCTIcon from '../Icon/Icon'

const useStyles = makeStyles(theme => ({
  btnFooter: {
    minWidth: 60,
    padding: '10px 24px',
  },
  titleFooter: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
  },
  footer: {
    flex: 1,
    textAlign: 'end',
    display: 'flex',
    justifyContent: ' flex-end',
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))

const MIN_WIDTH = 500
const MIN_HEIGHT = 50

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '16px 20px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      padding: 8,
      color: theme.palette.grey[500],
    },
    titleHeader: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '26px',
    },
    hasAddNew: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.light,
      fontSize: 14,
      fontWeight: 500,
      position: 'absolute',
      right: theme.spacing(0.125),
      top: theme.spacing(0.25),
      padding: '16px 20px',
      '&:hover': {
        cursor: 'pointer',
        opacity: '0.7',
      },
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
  hasAddNew?: boolean
  handleAddItem?: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps & { hasButtonClose: boolean }) => {
  const { children, classes, onClose, hasAddNew, handleAddItem, hasButtonClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {typeof children === 'string' ? (
        <Typography className={classes.titleHeader}>{children}</Typography>
      ) : (
        <>{children}</>
      )}
      {hasAddNew ? (
        <div onClick={handleAddItem} className={classes.hasAddNew} role="btnAddNewInModal">
          <FCTIcon name="plusBorder" width={13} height={13} color={theme.palette.primary.light} />
          <Typography style={{ marginLeft: 5 }}> Add New </Typography>
        </div>
      ) : hasButtonClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    overflow: 'auto',
    padding: '0!important',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: '16px 20px',
  },
}))(MuiDialogActions)

const StyledDialog = withStyles(theme => ({
  paperScrollPaper: {
    //TODO: update later...
    // overflow: 'hidden',
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))(Dialog)

export const FCTDialog = (props: FCTDialogProps) => {
  const {
    open,
    setOpen,
    title,
    children,
    childrenFooter,
    childrenButtonMore,
    type,
    titleBtnCancel = 'Cancel',
    titleBtnAgree,
    disableBtnCancel,
    disableBtnAgree,
    loadingBtnAgree,
    loadingBtnCancel,
    hasNoAction,
    handleAgree,
    minWidth,
    minHeight,
    maxHeight,
    maxWidth,
    itemStyle,
    handleClose = () => {},
    handleCancel = () => {},
    hasCloseOutside = true,
    hasCloseWhenCancel = true,
    iconCancel,
    tooltipAgree,
    fullWidth = false,
    containerStyle,
    tooltipCancel,
    disableTooltipAgree = true,
    disableTooltipCancel = true,
    hasAddNew,
    handleAddItem,
    hasFullScreen = false,
    hasButtonClose = true,
    hasNoTitle,
    DialogContentProps,
  } = props
  const classes = useStyles()

  return (
    <StyledDialog
      disableEnforceFocus
      maxWidth={!fullWidth && (maxWidth || 'md')}
      fullWidth={fullWidth}
      onClose={() => {
        hasCloseOutside && setOpen(false)
        handleClose()
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={hasFullScreen}
      style={{ ...containerStyle }}
    >
      {hasNoTitle ? null : (
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpen(false)
            handleClose()
          }}
          hasAddNew={hasAddNew}
          handleAddItem={handleAddItem}
          hasButtonClose={hasButtonClose}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        style={
          itemStyle || {
            minWidth: minWidth || MIN_WIDTH,
            minHeight: minHeight || MIN_HEIGHT,
            maxHeight: maxHeight || 'none',
          }
        }
        dividers
        className={classes.scroll}
        {...DialogContentProps}
      >
        {children}
      </DialogContent>
      {hasNoAction ? null : (
        <DialogActions>
          {childrenFooter}
          <div className={classes.footer}>
            {TYPE_DIALOG[type].hasCancel ? (
              <FCTTooltipMove
                isMove={false}
                text={tooltipCancel || ''}
                disable={disableTooltipCancel}
              >
                <FCTButton
                  variant={TYPE_DIALOG[type].variantCancel}
                  color={TYPE_DIALOG[type].colorCancel}
                  className={classes.btnFooter}
                  onClick={() => {
                    hasCloseWhenCancel && setOpen(false)
                    handleCancel()
                  }}
                  disabled={disableBtnCancel || loadingBtnCancel || loadingBtnAgree}
                >
                  {loadingBtnCancel ? (
                    <Loading size={22} />
                  ) : (
                    <>
                      {typeof titleBtnCancel === 'string' ? (
                        <Typography className={classes.titleFooter}>
                          {titleBtnCancel || 'Cancel'}
                        </Typography>
                      ) : (
                        titleBtnCancel
                      )}
                      {iconCancel}
                    </>
                  )}
                </FCTButton>
              </FCTTooltipMove>
            ) : null}
            {childrenButtonMore}
            <FCTTooltipMove isMove={false} text={tooltipAgree || ''} disable={disableTooltipAgree}>
              <FCTButton
                variant={TYPE_DIALOG[type].variantAgree}
                color={TYPE_DIALOG[type].colorAgree}
                className={classes.btnFooter}
                onClick={handleAgree}
                style={{ marginLeft: '10px' }}
                disabled={disableBtnAgree || loadingBtnAgree || loadingBtnCancel}
                role="btnAgree"
              >
                {loadingBtnAgree ? (
                  <Loading size={22} />
                ) : (
                  <Typography className={classes.titleFooter}>{titleBtnAgree || 'Save'}</Typography>
                )}
              </FCTButton>
            </FCTTooltipMove>
          </div>
        </DialogActions>
      )}
    </StyledDialog>
  )
}

export interface FCTDialogProps {
  open: boolean
  setOpen: (value: boolean) => void
  title: string | React.ReactNode
  type: ETypeDialog
  handleAgree: any
  children?: React.ReactNode
  childrenButtonMore?: React.ReactNode
  childrenFooter?: React.ReactNode
  titleBtnCancel?: string | React.ReactElement
  titleBtnAgree?: string
  disableBtnCancel?: boolean
  disableBtnAgree?: boolean
  loadingBtnAgree?: boolean
  loadingBtnCancel?: boolean
  hasNoAction?: boolean
  hasNoTitle?: boolean
  minWidth?: number
  minHeight?: number
  maxHeight?: number
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
  itemStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  handleClose?: () => void
  handleCancel?: () => void
  hasCloseOutside?: boolean
  hasCloseWhenCancel?: boolean
  iconCancel?: React.ReactNode
  tooltipAgree?: string
  fullWidth?: boolean
  tooltipCancel?: string
  disableTooltipAgree?: boolean
  disableTooltipCancel?: boolean
  hasFullScreen?: boolean
  hasButtonClose?: boolean
  hasAddNew?: boolean
  handleAddItem?: () => void
  DialogContentProps?: DialogContentProps
}

export enum ETypeDialog {
  Default = 'default',
  Create = 'create',
  Remove = 'remove',
  Update = 'update',
}

export const TYPE_DIALOG: ITypeDialog = {
  default: {
    variantCancel: 'text',
    colorCancel: 'default',
    hasCancel: true,
    variantAgree: 'outlined',
    colorAgree: 'primary',
  },
  create: {
    variantCancel: 'text',
    colorCancel: 'default',
    hasCancel: false,
    variantAgree: 'outlined',
    colorAgree: 'primary',
  },
  remove: {
    variantCancel: 'text',
    colorCancel: 'default',
    hasCancel: true,
    variantAgree: 'outlined',
    colorAgree: 'secondary',
  },
  update: {
    variantCancel: 'outlined',
    colorCancel: 'default',
    hasCancel: true,
    variantAgree: 'outlined',
    colorAgree: 'primary',
  },
}

export type ITypeDialog = {} & Record<ETypeDialog, IAction>

export interface IAction {
  variantCancel: 'contained' | 'text' | 'outlined'
  colorCancel: 'default' | 'primary' | 'secondary'
  hasCancel: boolean
  variantAgree: 'contained' | 'text' | 'outlined'
  colorAgree: 'default' | 'primary' | 'secondary'
}

import { Link, Theme, Typography, makeStyles } from '@material-ui/core'
import { useState } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  descriptionText: {
    fontSize: 14,
    lineHeight: '22px',
    color: '#1E293B',
    letterSpacing: '0.2px',
    marginBottom: 25,
  },
  showMoreBtn: {
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.2px',
  },
}))

export const DescriptionSection = ({ description = '' }: { description: string }) => {
  const [showMore, setShowMore] = useState(false)
  const decsLength = 200

  const classes = useStyles()

  return (
    <Typography className={classes.descriptionText} style={{ marginTop: 10 }}>
      {showMore ? description : description.slice(0, decsLength)}{' '}
      {description.length > decsLength && (
        <Link
          component="button"
          onClick={() => {
            setShowMore(!showMore)
          }}
        >
          <Typography className={classes.showMoreBtn}>
            {showMore ? 'Show less' : 'Show more...'}
          </Typography>
        </Link>
      )}
    </Typography>
  )
}

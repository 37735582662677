import { makeVar } from '@apollo/client'

export const isAuthenticatedVar = makeVar<boolean>(false)
export const isAuthenticatedPolicies = {
  isAuthenticated: {
    read() {
      return isAuthenticatedVar()
    },
  },
}

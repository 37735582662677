import { IViewByCompanyTypesMap } from '../../types/Filter'

export const VIEW_BY: IViewByCompanyTypesMap = {
  PrivateEquity: {
    displayName: 'Private Equity',
    propName: 'privateEquity',
    name: 'PrivateEquity',
  },
  fintech: {
    displayName: 'Business Line',
    propName: 'businessLines',
    name: 'FinTech',
  },
  regtech: {
    displayName: 'Risks',
    propName: 'risks',
    name: 'RegTech',
  },
  insurtech: {
    displayName: 'Value Chain',
    propName: 'valueChains',
    name: 'InsurTech',
  },
  out: {
    displayName: '',
    propName: '',
    name: '',
  },
  all: {
    displayName: 'All',
    propName: 'all',
    name: 'All',
  },
}

import { Container, Grid, makeStyles } from '@material-ui/core'
import FCTIcon from 'components/Icon/Icon'
import { PropsWithChildren, useCallback } from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  header: {
    boxSizing: 'border-box',
    width: '100%',
    minHeight: 59,
    maxHeight: 59,
    padding: '0 30px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}))

export default function () {
  const classes = useStyles()

  const ImageWrapper = useCallback(
    ({ children }: PropsWithChildren<{}>) => (
      <Link to="/app/dashboard" className={classes.logoWrapper}>
        {children}
      </Link>
    ),
    []
  )

  return (
    <header className={classes.header}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item sm={4} md={3}>
            <ImageWrapper>
              <FCTIcon width="185px" height="32px" name="newLogo" color="#000000" />
            </ImageWrapper>
          </Grid>
        </Grid>
      </Container>
    </header>
  )
}

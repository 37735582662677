import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles, Theme } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import FCTTextField from '../../TextField/FCTTextField'
import SubmitButton from './SubmitButton'
import * as yup from 'yup'

const IS_URL_REGEX = /^((http:|https:|http:|https:)\/\/(www\.)?)?[a-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF]+([-.]{1}[a-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF]+)*\.[a-z\u3000\u3400-\u4DBF\u4E00-\u9FFF]{2,255}(:[0-9]{1,5})?(\/.*)?$/i

const useStyles = makeStyles((theme: Theme) => ({
  formElement: {
    marginTop: '30px',
  },
  input: {
    width: '100%',
  },
}))

export interface IFillCompanyFormData {
  name: string
  url: string
}
interface IFillCompanyFormProps {
  handleSubmit?(data: IFillCompanyFormData): void
  loadingSubmit?: boolean
  defaultValues?: IFillCompanyFormData
  viewOnly?: boolean
}

const FillCompanyForm = ({
  handleSubmit = () => {},
  loadingSubmit,
  defaultValues,
  viewOnly,
}: IFillCompanyFormProps) => {
  const classes = useStyles()
  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
    trigger,
  } = useForm<IFillCompanyFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || {
      name: '',
      url: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Please enter company name.'),
        url: yup
          .string()
          .required('Please enter company url.')
          .matches(IS_URL_REGEX, 'Invalid URL format.'),
      })
    ),
  })
  const fields: { name: keyof IFillCompanyFormData; label: string }[] = [
    { name: 'name', label: 'Company Name' },
    { name: 'url', label: 'Company URL' },
  ]
  return (
    <form onSubmit={handleSubmitForm(handleSubmit)}>
      {fields.map(field => (
        <div key={field.name} className={classes.formElement}>
          <Controller
            control={control}
            name={field.name}
            render={props => (
              <FCTTextField
                {...props}
                className={classes.input}
                label={field.label}
                placeholder={field.label}
                error={!!errors[field.name]}
                FormHelperTextProps={!!errors[field.name] ? { role: 'alert' } : undefined}
                helperText={errors[field.name]?.message || ''}
                fullWidth
                disabled={viewOnly}
                onBlur={() => {
                  if (viewOnly) return
                  control.setValue(field.name, control.getValues(field.name)?.trim() || '')
                  trigger(field.name)
                }}
                inputProps={{
                  style: { opacity: 1 },
                  id: `#${field.name}`,
                }}
                InputLabelProps={{
                  disabled: false,
                  htmlFor: `#${field.name}`,
                  shrink: true,
                }}
              />
            )}
          />
        </div>
      ))}
      {!viewOnly && <SubmitButton disabled={!isValid} loading={loadingSubmit} />}
    </form>
  )
}

export default FillCompanyForm

import { Tabs, TabsProps, withStyles } from '@material-ui/core'
import { IconMap } from '../Icon/Icon'
import clsx from 'clsx'

const FCTTabs = withStyles(theme => ({
  root: {
    display: 'inline-flex',
    minHeight: 0,
    background: '#ffffff',
    '&.small': {
      border: '1px solid #E7ECF3',
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: 3,
      '& $indicator': {
        height: 0,
      },
    },
    '&.large': {
      background: '#F8FAFC',
      overflow: 'unset',
      '& $indicator': {
        height: 10,
        background: theme.palette.primary.contrastText,
        bottom: -9,
        borderLeft: '1px solid #e7ecf3',
        width: '202.2px !important',
      },
      '& $scroller': {
        overflowY: 'hidden',
        paddingBottom: 1,
        marginBottom: '-1px !important',
      },
      '&.notSelectedTabs': {
        background: theme.palette.primary.contrastText,
        '& svg': {
          color: theme.palette.primary.light,
        },
      },
    },
  },
  indicator: {},
  scroller: {},
}))((props: ITabsProps) => {
  const { value, size, width = 'unset' } = props
  return (
    <Tabs
      {...props}
      style={{ width: width == 'fullWidth' ? '100%' : width }}
      className={clsx(size, value === false ? 'notSelectedTabs' : '')}
    ></Tabs>
  )
})

export default FCTTabs

export interface ITabsProps extends TabsProps {
  size: 'small' | 'large'
  width?: 'fullWidth' | 'unset'
}

export interface IDataLargeTabs {
  logo: keyof typeof IconMap
  title: string
  amount: number
}

import { onError } from './sentry'

export const commonErrorHandler = (
  callback: (input: {
    graphQLError: {
      error: string
      message: string
    }
  }) => void
) => {
  return (error: any) => {
    const graphQLError = error.graphQLErrors?.[0]?.extensions?.code?.extensions?.response?.body || {
      error: error.graphQLErrors?.[0]?.message,
      message: error.graphQLErrors?.[0]?.message,
    }

    callback({ graphQLError })
    onError(error)
  }
}

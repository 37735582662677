import { makeVar } from '@apollo/client'

export const loadingVar = makeVar<boolean>(false)
export const loadingPolicies = {
  loading: {
    read() {
      return loadingVar()
    },
  },
}

import Loading from 'components/Loading'
import htmlparse from 'html-react-parser'
import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import Flex from 'components/Flex/Flex'
import { format } from 'date-fns'
import { IWebflowCollection } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.grey[500],
    '& strong': {
      color: theme.palette.grey[800],
    },
  },
  page: {
    padding: '20px 20px 0 47px',
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.grey[800],

    '& a': {
      color: theme.palette.primary.light,
    },
    '& h2': {
      fontSize: '16px',
      lineHeight: '24px',
      marginLeft: '-27px',
    },
    '& strong': {
      fontWeight: 600,
    },
    '& h4': {
      fontSize: '22px',
      lineHeight: '36px',
      marginBottom: 20,
      marginTop: 0,
      '& > strong': {
        color: theme.palette.primary.light,
      },
    },
    '& h5': {
      fontSize: '16px',
      lineHeight: '24px',
      margin: '24px 0px',
      '& > strong': {
        color: theme.palette.primary.light,
      },
    },
    '& h6': {
      fontSize: '14px',
      lineHeight: '22px !important',
      marginTop: 0,
      marginBottom: 16,
      color: theme.palette.grey[800],
      fontWeight: 'normal',
    },
    '& ul li::marker': {
      color: theme.palette.primary.light,
    },
  },
  content: {
    '& strong': {
      fontWeight: 600,
      color: theme.palette.primary.light,
    },
  },
}))

export type Props = {
  content: IWebflowCollection
  loading?: boolean
}
export const PolicyDocumentTemplate = ({ loading, content }: Props) => {
  const classes = useStyles()

  if (loading) return <Loading />
  return !content ? null : (
    <Box className={classes.page}>
      <Flex gap="24px" marginLeft="-27px" marginBottom="16px">
        <Typography className={classes.text}>
          Version: <strong>{content?.Version}</strong>
        </Typography>
        {!!content?.['Updated On'] && (
          <Typography className={classes.text}>
            Updated: <strong>{format(new Date(content?.['Updated date']), 'MMM dd, yyyy')}</strong>
          </Typography>
        )}
      </Flex>
      <Box className={classes.content}>
        {!!content?.Content ? htmlparse(content?.Content) : null}
      </Box>
    </Box>
  )
}

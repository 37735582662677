import gql from 'graphql-tag'

export const GET_COMPANY_IDS_LIST = gql`
  query GetCompanyList($input: GetCompanyListInput!) {
    getCompanyList(input: $input) {
      items {
        id
        name
        websiteUrl
        logoBucketUrl
        description
        longDescription
        foundedYear
        status
        totalFundingAmount
        country
        fct_score_overall
        totalFunding
      }
    }
  }
`

import axios from 'axios'

const REACT_APP_COMPANY_API = process.env.REACT_APP_COMPANY_API as string

export default function (ids: string[] | number[], token?: string) {
  return axios.post<{ data: any }>(
    `${REACT_APP_COMPANY_API}/suggest-companies`,
    { ids },
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FCTTextField, { NumberFormatCustom } from '../../TextField/FCTTextField'

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: theme.palette.grey['800'],
    fontSize: 12,
    fontWeight: 600,
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 6,
  },
  line: {
    width: 4,
    height: 1,
    backgroundColor: '#CBD5E1',
    margin: '0 6px',
  },
  flex: {
    flex: 1,
  },
}))

const TotalRangeValue = (props: TotalRangeValueProps) => {
  const classes = useStyles()
  const {
    data,
    setFilter,
    tagTotalTo,
    tagTotalFrom,
    placeholderTo,
    placeholderFrom,
    unit = '',
    numberType = 'Integer',
  } = props
  const [textInput, setTextInput] = useState({
    textInputFrom: data[tagTotalFrom],
    textInputTo: data[tagTotalTo],
  })

  const [error, setError] = useState({
    errorFrom: false,
    errorTo: false,
  })

  const fetchData = () => {
    if (!error.errorFrom && !error.errorTo)
      setFilter({ [tagTotalFrom]: textInput.textInputFrom, [tagTotalTo]: textInput.textInputTo })
  }

  const handleInputChangeFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.abs(+event.target.value)
    setTextInput({ ...textInput, [event.target.name]: value })
    const valueTo = textInput.textInputTo
    if ((!!value && !!valueTo && +value > +valueTo) || checkOutOfRange(value))
      setError({ ...error, errorFrom: true })
    else {
      if (!!value && !!valueTo && +value <= +valueTo) setError({ errorFrom: false, errorTo: false })
      else setError({ ...error, errorFrom: false })
    }
  }

  const handleInputChangeTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.abs(+event.target.value)
    setTextInput({ ...textInput, [event.target.name]: value })
    const valueFrom = textInput.textInputFrom
    if ((!!valueFrom && !!value && +value < +valueFrom) || checkOutOfRange(value))
      setError({ ...error, errorTo: true })
    else {
      if (!!valueFrom && !!value && +value >= +valueFrom)
        setError({ errorFrom: false, errorTo: false })
      else setError({ ...error, errorTo: false })
    }
  }

  const checkOutOfRange = (value: string | number) => {
    if (!!placeholderFrom && !!placeholderTo)
      if (Number(value) < Number(placeholderFrom!) || Number(value) > Number(placeholderTo!))
        return true
    return false
  }

  useEffect(() => {
    setTextInput({ textInputFrom: data[tagTotalFrom], textInputTo: data[tagTotalTo] })
    setError({ errorFrom: false, errorTo: false })
  }, [data[tagTotalFrom], data[tagTotalTo]])

  return (
    <div className={classes.dFlex}>
      <FCTTextField
        className={classes.flex}
        placeholder={!!placeholderFrom ? placeholderFrom + unit : ''}
        value={textInput.textInputFrom ? textInput.textInputFrom + unit : ''}
        label="From"
        onChange={handleInputChangeFrom}
        onKeyPress={event => {
          if ((numberType === 'Integer' && event.key === '.') || event.key === '-')
            event.preventDefault()
          if (event.key === 'Enter') fetchData()
        }}
        onBlur={fetchData}
        name="textInputFrom"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        error={error.errorFrom}
      ></FCTTextField>
      <div className={classes.line}></div>
      <FCTTextField
        className={classes.flex}
        placeholder={!!placeholderTo ? placeholderTo + unit : ''}
        value={textInput.textInputTo ? textInput.textInputTo + unit : ''}
        label="To"
        onChange={handleInputChangeTo}
        onKeyPress={event => {
          if (numberType === 'Integer' && event.key === '.') event.preventDefault()
          if (event.key === 'Enter') fetchData()
        }}
        onBlur={fetchData}
        name="textInputTo"
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        error={error.errorTo}
      ></FCTTextField>
    </div>
  )
}

export default TotalRangeValue

interface TotalRangeValueProps {
  data: any
  setFilter(value: any): void
  tagTotalTo: string
  tagTotalFrom: string
  placeholderFrom?: string | null
  placeholderTo?: string | null
  unit?: string
  numberType?: 'Integer' | 'Float'
}

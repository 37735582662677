import React, { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import theme from '../../theme'
import FCTIcon from '../Icon/Icon'
import { renderToStaticMarkup } from 'react-dom/server'

const checkedIcon = encodeURIComponent(
  renderToStaticMarkup(
    <FCTIcon name="checked" width={10} height={10} color={theme.palette.primary.contrastText} />
  )
)
const indeterminateIcon = encodeURIComponent(
  renderToStaticMarkup(
    <FCTIcon name="minus1" width={10} height={10} color={theme.palette.grey['400']} />
  )
)

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 2,
    width: 16,
    height: 16,
    border: `1px solid ${theme.palette.grey[300]}`,
    background: theme.palette.primary.contrastText,
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
    boxSizing: 'border-box',
  },
  checkedIcon: {
    display: 'flex',
    border: 0,
    width: 16,
    height: 16,
    background: theme.palette.primary.light,
    '&:before': {
      display: 'block',
      width: 10,
      height: 10,
      backgroundImage: `url("data:image/svg+xml,${checkedIcon}")`,
      backgroundRepeat: 'no-repeat',
      content: '""',
      margin: 'auto',
    },
  },
  indeterminateIcon: {
    display: 'flex',
    background: theme.palette.grey['200'],
    '&:before': {
      display: 'block',
      width: 10,
      height: 10,
      backgroundImage: `url("data:image/svg+xml,${indeterminateIcon}")`,
      backgroundRepeat: 'no-repeat',
      content: '""',
      margin: 'auto',
    },
  },
})

const FCTCheckbox = (props: PropsWithChildren<CheckboxProps>) => {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      indeterminateIcon={<span className={clsx(classes.icon, classes.indeterminateIcon)} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

export default React.forwardRef<any, CheckboxProps>((props, _ref) => <FCTCheckbox {...props} />)

import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#29BA74',
      main: '#1DA462',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#6C809D',
    },
    grey: {
      '50': '#F8FAFC',
      '100': '#F1F5F9',
      '200': '#E2E8F0',
      '300': '#CBD5E1',
      '400': '#94A3B8',
      '500': '#6C809D',
      '600': '#475569',
      '700': '#334155',
      '800': '#1E293B',
      '900': '#0F172A',
    },
    error: {
      main: '#C41300',
    },
    divider: '#E7ECF3',
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
})

export default theme

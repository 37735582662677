import { bubbleActiveNodeVar } from '../../cache/TaxonomiesBubble'
import { queryParamsVar, viewTypeVar } from '../../cache/Common'
import { SetBubbleActiveNode } from './SetBubbleActiveNode'
import SetViewType from './SetViewType'
import { loadingVar } from '../../cache/Loading'
import SetLoading from './SetLoading'
import SetQueryParams from './SetQueryParams'
import { currentTabVar } from '../../cache/CompanyDealFilterPolicies'
import { SetCurrentProfileTab } from './SetCurrentProfileTab'
import { isAuthenticatedVar } from '../../cache/Authenticated'
import SetAuthenticated from './SetAuthenticated'

export const setViewType = SetViewType(viewTypeVar)
export const setBubbleActiveNode = SetBubbleActiveNode(bubbleActiveNodeVar)
export const setLoading = SetLoading(loadingVar)
export const setQueryParams = SetQueryParams(queryParamsVar)
export const setCurrentProfileTab = SetCurrentProfileTab(currentTabVar)
export const setAuthenticated = SetAuthenticated(isAuthenticatedVar)

import { makeStyles, Theme, useTheme, Typography, BoxProps } from '@material-ui/core'
import Icon from '../../Icon/Icon'
import Flex from '../../Flex/Flex'

const useStyles = makeStyles((theme: Theme) => ({
  hint: {
    fontSize: 12,
    lineHeight: '18px',
    textAlign: 'left',
    color: `${theme.palette.grey[500]}`,
  },
}))

const Hint = ({ text, boxProps }: { text: string; boxProps?: BoxProps }) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Flex gap="8px" {...boxProps}>
      <Flex width="12px">
        <Icon name="info" color={theme.palette.grey[400]} />
      </Flex>
      <Typography className={classes.hint}>{text}</Typography>
    </Flex>
  )
}

export default Hint

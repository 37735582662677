import React from 'react'
import { Box, debounce, makeStyles, withStyles } from '@material-ui/core'
import Autocomplete, {
  AutocompleteProps,
  AutocompleteGetTagProps,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete'
import FCTIcon from '../Icon/Icon'
import theme from '../../theme'
import FCTTextField from '../TextField/FCTTextField'
import { ChipItemFilter } from '../ExploreDBComponent/share/ChipItemFilter'
import Flex from '../Flex/Flex'
import Loading from '../Loading'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  IndexRange,
  List as VirtualizedList,
  ListRowProps,
} from 'react-virtualized'
import { Value } from '@material-ui/lab'

const Autocomplete1 = withStyles(() => ({
  root: {
    width: '100%',
    fontSize: 12,
    lineHeight: '18px',
  },
  inputRoot: {
    paddingRight: '26px!important',
  },
  input: {
    padding: '8px 4px!important',
  },
  tag: {
    height: 28,
    margin: 2,
    borderRadius: 3,
    background: '#F1F5F9',
  },
  option: {
    fontSize: 14,
  },
  noOptions: {
    fontSize: 12,
    lineHeight: '18px',
  },
  loading: {
    fontSize: 12,
    lineHeight: '18px',
  },
}))(Autocomplete) as typeof Autocomplete

export function StyledAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return <Autocomplete1 {...props} />
}

export default function FCTAutoCompleteVirtualized<T extends IOption>({
  options,
  onInputChange,
  placeHolder,
  label,
  hiddenSelectedOption,
  ...props
}: Props<T, boolean>) {
  const { onBlur = () => {} } = props
  const renderTags = (value: T[], getTagProps: AutocompleteGetTagProps) => {
    return (
      !hiddenSelectedOption &&
      value.map((option, index) => (
        <ChipItemFilter
          key={option.name}
          label={option.name}
          size="small"
          {...getTagProps({ index })}
        />
      ))
    )
  }

  return (
    <StyledAutocomplete
      multiple={props.multiple}
      options={options}
      value={props.value || null}
      onChange={(e, v) => props.onChange && props.onChange(e, v)}
      closeIcon={!hiddenSelectedOption}
      loading={!!props.loading}
      getOptionSelected={(option, value) => option.id === value?.id}
      getOptionLabel={option => option.name || ''}
      popupIcon={
        !hiddenSelectedOption && (
          <FCTIcon name="arrowdown" width={10} height={10} color={theme.palette.grey[400]} />
        )
      }
      renderTags={props.renderTags || renderTags}
      renderOption={props.renderOption}
      onInputChange={debounce(onInputChange, 400)}
      renderInput={params => (
        <FCTTextField
          {...params}
          onBlur={onBlur}
          style={{ width: '100%' }}
          InputLabelProps={{ shrink: true }}
          label={label}
          placeholder={placeHolder}
        />
      )}
      ListboxProps={props.ListboxProps}
      ListboxComponent={props.ListboxComponent || (ListboxComponent as any)}
      {...props}
    />
  )
}

type ListboxProps = {
  onScroll?(event: Record<'scrollTop' | 'clientHeight' | 'scrollHeight', number>): void
  loading?: boolean
  visibleItem?: number
  isRowLoaded?({ index }: { index: number }): boolean
  loadMoreRows?: (params: IndexRange) => Promise<any>
  handleClickHere?: () => void
  searchText?: string
  findText?: string
  isShowFind?: boolean
  isAcquirer?: boolean
}

type Props<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = {
  placeHolder?: string
  options: T[]
  label?: string
  onInputChange(
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ): void
  hiddenSelectedOption?: boolean
  ListboxProps?: ListboxProps
  multiple?: Multiple
  onBlur?: () => void
  onChange?(e: React.ChangeEvent<{}>, value: Value<T, Multiple, DisableClearable, FreeSolo>): void
} & Partial<
  Pick<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    | 'ListboxComponent'
    | 'renderOption'
    | 'value'
    | 'loading'
    | 'renderTags'
    | 'noOptionsText'
    | 'PaperComponent'
  >
>

export interface IOption {
  id: string
  name: string
}

export type { Props as FCTAutoCompleteVirtualizedProps }

const useStyles = makeStyles(theme => ({
  list: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))

const ListboxComponent = React.forwardRef(function (props: any, ref: any) {
  const ITEM_HEIGHT = 36

  const classes = useStyles()
  const {
    children,
    role,
    className,
    loading,
    visibleItem = 7,
    loadMoreRows,
    isRowLoaded,
    ...other
  } = props
  const itemCount = Array.isArray(children) ? children.length + (loading ? 1 : 0) : 0
  const itemSize = ITEM_HEIGHT

  const cache = React.useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        keyMapper: index => index,
      }),
    []
  )

  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
        {({ measure }) =>
          React.cloneElement(children[index], {
            style,
          })
        }
      </CellMeasurer>
    )
  }

  return (
    <div ref={ref}>
      <Box {...other} style={{ overflow: 'hidden' }}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <VirtualizedList
              className={classes.list}
              height={(itemCount > visibleItem ? visibleItem : itemCount) * itemSize}
              rowHeight={cache.rowHeight}
              overscanCount={5}
              rowCount={itemCount}
              width={width}
              rowRenderer={props => {
                return children[props.index] ? (
                  rowRenderer(props)
                ) : loading ? (
                  <Flex
                    key="loading"
                    style={props.style}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Loading size={24} />
                  </Flex>
                ) : null
              }}
              role={role}
              onScroll={props.onScroll}
            />
          )}
        </AutoSizer>
      </Box>
    </div>
  )
})

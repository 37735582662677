import { Avatar, TableContainer, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useWatchListId } from 'store/features/watch-list/WatchListHook'
import { useDataMembersContext } from '../../features/watch-list/GetDataMembers'
import { GetWatchListContext } from '../../features/watch-list/GetWatchList'
import { GetWatchListSharedContext } from '../../features/watch-list/GetWatchListWereShared'
import { IWatchListSelect } from '../../module/WatchList/AddWatchList'
import theme from '../../theme'
import { numberWithCommas } from '../../utils/convert/number'
import { renameLogo } from '../../utils/convert/Title'
import { IRoleKey, ROLE_BY } from '../../utils/WatchList'
import { GetMyWatchlist_getMyWatchlist } from '../../__generated__/GetMyWatchlist'
import { GetWatchlistWereShared_getWatchlistWereShared } from '../../__generated__/GetWatchlistWereShared'
import { AntTab } from '../AntTab'
import FCTCard from '../Card/FCTCard'
import FCTCardContent from '../Card/FCTCardContent'
import FCTCardHeader from '../Card/FCTCardHeader'
import FCTIcon from '../Icon/Icon'
import Loading from '../Loading'
import { WATCH_LIST_DEFAULT_LIMIT } from './../../core/constants/WatchList'
import { EmptyWatchList } from './EmptyWatchList'

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    height: '100%',
    border: 0,
  },
  cardHeader: {
    borderBottom: '1px solid #E2E8F0',
    padding: '0 16px',
  },
  cardContent: {
    height: '100%',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    padding: '10px 0px',
  },
  container: {
    boxSizing: 'border-box',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  label: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
  },
  tabs: {
    flexShrink: 1,
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      minWidth: 155,
      width: '100%',
    },
  },
  watchlist: {
    height: '300px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
  optionSelect: {
    display: 'flex',
    padding: '8px 14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[100]}`,
    },
    alignItems: 'center',
  },
  optionDisable: {
    display: 'flex',
    alignItems: 'start',
    padding: '8px 14px',
    backgroundColor: `${theme.palette.grey[100]}`,
    flexDirection: 'column',
  },
  optionText: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600,
    color: theme.palette.grey[800],
    whiteSpace: 'nowrap',
    maxWidth: 300,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  groupName: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  descText: {
    display: 'flex',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '18px',
    color: theme.palette.grey[400],
  },
  content: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  badge: {
    color: theme.palette.grey[400],
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    marginTop: '-6px',
    paddingLeft: '6px',
  },
  badgeActive: {
    color: theme.palette.primary.light,
  },
  iconStyle: {
    margin: '0px 6px 2px 0px',
  },
  flexItem: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
  },
  avatar: {
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    borderRadius: '50%',
    width: 18,
    height: 18,
    fontSize: 10,
  },
}))

export const SelectTabWatchList = (props: SelectTabWatchListProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { handleOption, type = ETypeSelectTabWL.View, watchListSelect } = props
  const [watchListId, _] = useWatchListId()

  const getWatchList = useContext(GetWatchListContext)
  const dataWatchList = getWatchList?.dataWatchList

  const getWatchListShared = useContext(GetWatchListSharedContext)
  const dataWatchListShared = getWatchListShared.dataWatchListShared

  const { dataMembers } = useDataMembersContext()

  const [viewTab, setViewTab] = useState<EViewWatchList>(EViewWatchList.MyWatchList)

  const isCurrentPage = (url: string) => {
    return history?.location.pathname.includes(url)
  }

  const dataPermissionShare = useMemo(() => {
    return type === ETypeSelectTabWL.View
      ? dataWatchListShared
      : dataWatchListShared?.filter(e => e.roleKey === IRoleKey.Editor && !e.needRequest)
  }, [dataWatchListShared])

  const hasDisableView =
    isCurrentPage('/reports') ||
    isCurrentPage('/search') ||
    isCurrentPage('/watch-lists') ||
    isCurrentPage('/profile')

  const isMyWatchListTab = viewTab === EViewWatchList.MyWatchList
  const isWatchListShareTab = viewTab === EViewWatchList.SharedWatchLists

  const getNameUser = (id: string | null) => {
    return dataMembers?.find(e => e.id === id)?.profile.firstName || ''
  }

  return (
    <>
      <FCTCard className={classes.card}>
        <FCTCardHeader
          className={classes.cardHeader}
          titleElement={
            <Tabs
              className={classes.tabs}
              value={viewTab}
              indicatorColor="primary"
              onChange={(_, v) => {
                setViewTab(v)
              }}
              variant="scrollable"
              scrollButtons="off"
            >
              <AntTab
                label={
                  <>
                    <div className={classes.label}>My Watchlists</div>
                    <span className={clsx(classes.badge, isMyWatchListTab && classes.badgeActive)}>
                      {`${dataWatchList?.length || 0}/${WATCH_LIST_DEFAULT_LIMIT}`}
                    </span>
                  </>
                }
                value={EViewWatchList.MyWatchList}
              />

              <AntTab
                label={
                  <>
                    <div className={classes.label}>Shared Watchlists</div>
                    <span
                      className={clsx(classes.badge, isWatchListShareTab && classes.badgeActive)}
                    >
                      {dataPermissionShare?.length || 0}
                    </span>
                  </>
                }
                value={EViewWatchList.SharedWatchLists}
              />
            </Tabs>
          }
        ></FCTCardHeader>
        <FCTCardContent className={classes.cardContent}>
          <TableContainer className={classes.container}>
            {(() => {
              switch (viewTab) {
                case EViewWatchList.MyWatchList:
                  return (
                    <>
                      {getWatchList.loading ? (
                        <Loading style={{ width: 30, height: 30, margin: '10px 0' }} />
                      ) : (
                        <div className={classes.watchlist}>
                          {dataWatchList?.length ? (
                            dataWatchList?.map((item, index) => (
                              <div
                                key={index}
                                className={
                                  hasDisableView && type === ETypeSelectTabWL.View
                                    ? classes.optionDisable
                                    : classes.optionSelect
                                }
                                onClick={() => {
                                  !(hasDisableView && type === ETypeSelectTabWL.View) &&
                                    item.watchlistId != watchListId &&
                                    handleOption(item)
                                }}
                                style={
                                  type === ETypeSelectTabWL.Copy
                                    ? {
                                        background:
                                          item.watchlistId === watchListSelect?.id
                                            ? theme.palette.grey[50]
                                            : '#fff',
                                      }
                                    : undefined
                                }
                              >
                                <div className={classes.content}>
                                  <div className={classes.groupName}>
                                    <div className={classes.optionText}>{item.watchlistName}</div>
                                    {item.isDefault && (
                                      <FCTIcon
                                        name="stars"
                                        width={12}
                                        height={12}
                                        color="#F3A93C"
                                        style={{
                                          margin: '0 0 2px 8px',
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className={classes.descText}>
                                    <div className={classes.flexItem}>
                                      <FCTIcon
                                        name="company"
                                        width={12}
                                        height={12}
                                        color={theme.palette.grey[400]}
                                        className={classes.iconStyle}
                                      />
                                      {numberWithCommas(item.numOfCompanies || 0)}
                                    </div>
                                    <div className={classes.flexItem}>
                                      <FCTIcon
                                        name={'save'}
                                        width={12}
                                        height={12}
                                        color={theme.palette.grey[400]}
                                        className={classes.iconStyle}
                                      />
                                      {item.filterOption ? 'Filter' : 'ID'}
                                    </div>
                                  </div>
                                </div>
                                {item.watchlistId === watchListSelect?.id ? (
                                  <FCTIcon
                                    name="checked"
                                    width={16}
                                    height={16}
                                    color={theme.palette.primary.light}
                                  />
                                ) : null}
                              </div>
                            ))
                          ) : (
                            <EmptyWatchList
                              icon={<FCTIcon name="list2" width={32} height={32} />}
                              title="You haven’t created any watchlist"
                              titleStyle={{ lightHeight: '22px' }}
                              wrapIconStyle={{ width: 80, height: 80 }}
                            ></EmptyWatchList>
                          )}
                        </div>
                      )}
                    </>
                  )

                case EViewWatchList.SharedWatchLists:
                  return (
                    <>
                      {getWatchListShared.loading ? (
                        <Loading style={{ width: 30, height: 30, margin: '10px 0' }} />
                      ) : (
                        <div className={classes.watchlist}>
                          {dataPermissionShare?.length ? (
                            dataPermissionShare?.map((item, index) => (
                              <div
                                key={index}
                                className={
                                  hasDisableView && type === ETypeSelectTabWL.View
                                    ? classes.optionDisable
                                    : classes.optionSelect
                                }
                                onClick={() => {
                                  !(hasDisableView && type === ETypeSelectTabWL.View) &&
                                    item.watchlistId != watchListId &&
                                    handleOption(item)
                                }}
                                style={
                                  type === ETypeSelectTabWL.Copy
                                    ? {
                                        background:
                                          item.watchlistId === watchListSelect?.id
                                            ? theme.palette.grey[50]
                                            : '#fff',
                                      }
                                    : undefined
                                }
                              >
                                <div className={classes.content}>
                                  <div className={classes.groupName}>
                                    <div className={classes.optionText}>{item.watchlistName}</div>
                                    {item.isDefault && (
                                      <FCTIcon
                                        name="stars"
                                        width={12}
                                        height={12}
                                        color="#F3A93C"
                                        style={{
                                          margin: '0 0 2px 8px',
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className={classes.descText}>
                                    <div className={classes.flexItem}>
                                      <FCTIcon
                                        name="company"
                                        width={12}
                                        height={12}
                                        color={theme.palette.grey[400]}
                                        className={classes.iconStyle}
                                      />
                                      {numberWithCommas(item.numOfCompanies || 0)}
                                    </div>
                                    <div className={classes.flexItem}>
                                      <FCTIcon
                                        name={'save'}
                                        width={12}
                                        height={12}
                                        color={theme.palette.grey[400]}
                                        className={classes.iconStyle}
                                      />
                                      {item.filterOption ? 'Filter' : 'ID'}
                                    </div>
                                    <div className={classes.flexItem}>
                                      <FCTIcon
                                        name="eyeopen"
                                        width={12}
                                        height={12}
                                        color={theme.palette.grey[400]}
                                        className={classes.iconStyle}
                                      />
                                      {ROLE_BY[item?.roleKey || 'VIEW'].name}
                                    </div>
                                    <div className={classes.flexItem}>
                                      <FCTIcon
                                        name="network"
                                        width={12}
                                        height={12}
                                        color={theme.palette.grey[400]}
                                        className={classes.iconStyle}
                                      />
                                      <div className={classes.logoWrapper}>
                                        <Avatar
                                          alt={'image'}
                                          variant="square"
                                          src={null || ''}
                                          className={classes.avatar}
                                          children={renameLogo(getNameUser(item?.sharedBy))}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item.watchlistId === watchListSelect?.id ? (
                                  <FCTIcon
                                    name="checked"
                                    width={16}
                                    height={16}
                                    color={theme.palette.primary.light}
                                  />
                                ) : null}
                              </div>
                            ))
                          ) : (
                            <EmptyWatchList
                              icon={<FCTIcon name="share" width={32} height={32} />}
                              title="No shared watchlist yet"
                              titleStyle={{ lightHeight: '22px' }}
                              contentStyle={{ marginTop: 8 }}
                              content="Contact your team members to onboard some watchlists here."
                              wrapIconStyle={{ width: 80, height: 80 }}
                            ></EmptyWatchList>
                          )}
                        </div>
                      )}
                    </>
                  )
                default:
                  return null
              }
            })()}
          </TableContainer>
        </FCTCardContent>
      </FCTCard>
    </>
  )
}

interface SelectTabWatchListProps {
  handleOption: (
    watchList: GetMyWatchlist_getMyWatchlist | GetWatchlistWereShared_getWatchlistWereShared
  ) => void
  type?: ETypeSelectTabWL
  watchListSelect?: IWatchListSelect
}

export enum EViewWatchList {
  MyWatchList,
  SharedWatchLists,
}

export enum ETypeSelectTabWL {
  View,
  Add,
  Copy,
}

export enum EWatchListType {
  companyIDs,
  filterConditions,
}

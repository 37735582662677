import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

export interface INotificationSlice {
  numOfNotifications: number
  notificationStatus: boolean
}

const numOfNotifications: number = 0
const notificationStatus: boolean = false

const NotificationSlice = createSlice<INotificationSlice, SliceCaseReducers<INotificationSlice>>({
  name: 'notification',
  initialState: {
    numOfNotifications,
    notificationStatus,
  },
  reducers: {
    updateNumOfNotifications: (state, action: PayloadAction<number>) => {
      state.numOfNotifications = action.payload && action.payload > 0 ? action.payload : 0
    },
    increaseNumOfNotifications: (state, action: PayloadAction<number>) => {
      state.numOfNotifications += action.payload || 1
    },
    updateNotificationStatus: (state, action: PayloadAction<boolean>) => {
      state.notificationStatus = action.payload
    },
  },
})

export const {
  updateNumOfNotifications,
  increaseNumOfNotifications,
  updateNotificationStatus,
} = NotificationSlice.actions

export default NotificationSlice.reducer

import { Typography } from '@material-ui/core'
import Flex from 'components/Flex/Flex'
import Icon from 'components/Icon/Icon'
import theme from 'theme'

type Props = {
  title: string
}
const BreadcrumbAdmin = ({ title }: Props) => {
  return (
    <>
      <Flex alignItems="center">
        <Typography>FCT Admin </Typography>
        <Icon
          name="arrowright"
          height="10"
          width="10"
          color={theme.palette.grey[400]}
          style={{ margin: '0px 5px' }}
        />
        <Typography style={{ color: theme.palette.primary.main }}> {title} </Typography>
      </Flex>
    </>
  )
}

export default BreadcrumbAdmin

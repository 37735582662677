import { makeStyles, Theme } from '@material-ui/core'
import FCTIcon from '../Icon/Icon'

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    display: 'flex',
    background: '#0077B7',
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 6,
    outline: 'none',
    border: 'none',
    width: '100%',
    '&:disabled': {
      opacity: 0.7,
      cursor: 'unset',
    },
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#fff',
    flex: 1,
    textAlign: 'center',
  },
}))

export type LinkedInProps = {
  onClick(): void
  type: 'signIn' | 'signUp'
  disabled?: boolean
}

const LinkedIn = ({ onClick, type, disabled }: LinkedInProps) => {
  const classes = useStyles()

  return (
    <>
      <button className={classes.btn} onClick={onClick} disabled={disabled}>
        <FCTIcon name="linkedIn" height={38} width={38} />
        <div className={classes.text}>
          {type == 'signIn' ? 'Login with' : 'Sign up with'}
          <strong> LinkedIn</strong>
        </div>
      </button>
    </>
  )
}

export default LinkedIn

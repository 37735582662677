/* eslint-disable no-mixed-operators */
import * as d3 from 'd3'
import { isEqual } from 'lodash'
import bottomIconGreen from '../../assets/images/icons/analyticsGreen.svg'
import bottomIcon from '../../assets/images/icons/analyticsWhite.svg'
import leftIconGreen from '../../assets/images/icons/dashboardGreen.svg'
import leftIcon from '../../assets/images/icons/dashboardWhite.svg'
import rightIconGreen from '../../assets/images/icons/listGreen.svg'
import rightIcon from '../../assets/images/icons/listWhite.svg'
import d3tip, { NOT_DELAY } from '../../core/lib/d3-tip'
import Chart from '../../utils/Chart'
import './styles.css'

const Colors = [
  '#54C890',
  '#B4DDF0',
  '#C9BCE2',
  '#E4ADE1',
  '#F9B1C6',
  '#F5B298',
  '#EAD494',
  '#F3A93C',
  '#CE618E',
  '#EF8251',
]

const hasStroke = p => {
  return p?.data?.children?.every(item => !item.isShow)
}

let width_temp = 0
let industry_temp = ''

class D3Chart extends Chart {
  init({
    data,
    activeNode,
    onSelectActiveNode,
    setSunburst,
    setZoomEvent,
    onActiveTooltip,
    onPinTooltip,
    isPin,
  }) {
    let vis = this
    vis.activeNode = activeNode
    vis.onSelectActiveNode = onSelectActiveNode
    vis.setSunburst = setSunburst
    vis.setZoomEvent = setZoomEvent
    vis.onActiveTooltip = onActiveTooltip
    vis.onPinTooltip = onPinTooltip
    vis.isPin = isPin

    vis.svg.style('background', '#f8fafc')

    vis.numberFormat = d3.format(',')

    vis.tip = d3tip()
      .attr('class', 'd3-tip-black')
      .html((e, d) => {
        return `<div class="tip-tooltip-sunburst">Right-click to pin</div>`
      })

    vis.svg.call(vis.tip)

    vis.color = d3.scaleOrdinal(Colors)

    vis.format = d3.format(',d')

    vis.zoom = d3
      .zoom()
      .scaleExtent([1, Infinity])
      .on('zoom', function (event) {
        vis.svg.style('position', 'static')
        vis.svg.attr('transform', event.transform)
        vis.setZoomEvent(event)
      })

    vis.g = vis.g.append('g').call(vis.zoom)

    vis.arc = d3
      .arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))

    vis.partition = data => {
      const root = d3
        .hierarchy(data)
        .sum(d => d.size)
        .sort((a, b) => b.size - a.size)
      return d3.partition().size([2 * Math.PI, root.height + 1])(root)
    }
  }

  update({ data, nodeId, parentId, onSelectActiveNode, onclickNavigate, isPin }) {
    let vis = this
    let isUpdate = false
    vis.onSelectActiveNode = onSelectActiveNode || vis.onSelectActiveNode
    vis.onclickNavigate = onclickNavigate || vis.onclickNavigate
    vis.isPin = isPin !== undefined ? isPin : vis.isPin

    // Responsive
    vis.DIAMETER = Math.min(vis.WIDTH, vis.HEIGHT)
    vis.RADIUS = vis.DIAMETER / 8

    vis.svg.attr('viewBox', `0 0 ${vis.WIDTH} ${vis.HEIGHT}`)
    vis.svg.style('position', 'absolute')

    vis.zoom = vis.zoom
      .translateExtent([
        [-vis.WIDTH, -vis.HEIGHT],
        [vis.WIDTH, vis.HEIGHT],
      ])
      .extent([
        [-vis.WIDTH, -vis.HEIGHT],
        [vis.WIDTH, vis.HEIGHT],
      ])

    vis.g = vis.g
      .attr('transform', `translate(${vis.WIDTH / 2}, ${vis.HEIGHT / 2 - 10})`)
      .style('font-size', `${vis.RADIUS / 10}px`)

    function calcRadiusRecursive(curr, acc) {
      if (curr < 1) return acc
      const arcWidth = curr < 4 ? vis.RADIUS : vis.RADIUS * 0.3
      return calcRadiusRecursive(curr - 1, acc + arcWidth)
    }

    vis.arc = vis.arc
      .padRadius(vis.RADIUS * 1.5)
      .innerRadius(d => Math.max(vis.RADIUS, calcRadiusRecursive(d.y0, 0)))
      .outerRadius(d =>
        Math.max(vis.RADIUS, Math.max(calcRadiusRecursive(d.y0, 0), calcRadiusRecursive(d.y1, 0)))
      )

    vis.TOTAL_WIDTH = vis.WIDTH + vis.MARGIN.LEFT + vis.MARGIN.RIGHT
    vis.TOTAL_HEIGHT = vis.HEIGHT + vis.MARGIN.TOP + vis.MARGIN.BOTTOM

    vis.setSunburst(vis)
    // End responsive

    // Draw sunburt chart
    if (!isEqual(data, vis.data) && data) {
      vis.industry = data?.name
      vis.g.selectAll('*').remove()
      vis.data = data || vis.data
      vis.color = d3.scaleOrdinal(Colors)
      vis.root = vis.partition(vis.data)
      setTimeout(() => {
        vis.root.each(d => (d.current = d))

        vis.path = vis.g
          .append('g')
          .selectAll('path')
          .data(vis.root.descendants().slice(1), d => d.data.name)
          .join('path')
          .attr('fill', d => {
            while (d.depth > 1) d = d.parent
            return vis.color(d.data.name)
          })
          .attr('fill-opacity', d =>
            d.current.y0 === 1 ? 0.6 : d.current.y0 === 2 ? 0.8 : d.current.y0 === 3 ? 1 : 1
          )
          .attr('pointer-events', d => (d.current.y0 <= 3 ? 'all' : 'none'))
          .attr('stroke', '#f8fafc')
          .attr('stroke-width', d => (d.current.y0 <= 3 && d.data.isShow ? '2px' : '0px'))
          .attr('d', d => (d.data.isShow ? vis.arc(d.current) : ''))
          .on('mouseout', (e, d) => {
            vis.path.attr('fill', d => {
              while (d.depth > 1) d = d.parent
              return vis.color(d.data.name)
            })
            vis.onActiveTooltip(false, d)
            return vis.tip.hide('notDelay')
          })
          .on('mouseover', (e, d) => {
            const sequenceArray = d.ancestors()
            vis.path.attr('fill', '#CBD5E1')
            d3.selectAll('path')
              .filter(node => sequenceArray.indexOf(node) >= 0)
              .attr('fill', d => d3.color(vis.color(vis.findName.call(vis, d))).darker(0.3))
          })
          .on('mousemove', (e, d) => {
            if (!d) return vis.tip.hide('notDelay')
            !vis.isPin && vis.onActiveTooltip(true, d)
            return vis.tip.show(e, d, NOT_DELAY)
          })
          .on('contextmenu', (e, d) => {
            vis.onPinTooltip(e, d)
            e.preventDefault()
          })

        vis.path.style('cursor', 'pointer').on('click', (e, d) => {
          if (d.data.category == vis.data.oldCategory) {
            return vis.clicked.call(vis, null, d, true)
          } else {
            return vis.clicked.call(vis, null, d, false)
          }
        })

        vis.label = vis.g
          .append('g')
          .attr('pointer-events', 'none')
          .attr('text-anchor', 'middle')
          .style('user-select', 'none')
          .selectAll('text')
          .data(vis.root.descendants().slice(1), d => d.data.name)
          .join('text')
          .attr('class', 'arc-label')
          .attr('fill', d => (d.data.isShow ? '#0F172A' : '#f8fafc'))
          .attr('dy', '0.35em')
          .attr('fill-opacity', d => +vis.labelVisible.call(vis, d.current))
          .text(d => d.data.name)
          .call(vis.wrap.bind(vis), vis.RADIUS - 10)
          .attr('transform', d => vis.labelTransform(d.current))

        vis.selectedLabelG = vis.g.append('g')

        vis.innerCircle = vis.g
          .append('circle')
          .datum(vis.root, d => d.data.name)
          .attr('r', vis.RADIUS)
          .attr('fill', 'none')
          .attr('pointer-events', 'all')
          .on('mouseover', () => {
            vis.g.selectAll('.center-hover').attr('display', 'inline')
          })

        vis.generateLabel(vis.data.name, vis.numberFormat(vis.root.value) + ' companies', vis.root)
        if (vis.activeNode?.parent) {
          vis.clicked.call(vis, null, vis.activeNode, true, 0)
        }
        if (nodeId || parentId) {
          vis.selectNode(nodeId, parentId)
        }
      }, 10)
      isUpdate = true
    }

    setTimeout(() => {
      //update size chart when resize window
      if (width_temp != vis.WIDTH && !isUpdate) {
        width_temp = vis.WIDTH
        if (!nodeId && !parentId && industry_temp != vis.industry) {
          vis.selectNode(nodeId, parentId)
        } else {
          vis.selectNode(nodeId || vis.nodeId, parentId || vis.businessLineId)
        }
      }

      if (
        (nodeId || parentId || nodeId == 0) &&
        vis.root &&
        (!isEqual(vis.nodeId, nodeId) || !isEqual(vis.businessLineId, parentId)) &&
        !isUpdate
      ) {
        vis.selectNode(nodeId, parentId)
      }
    }, 15)
  }
  //End update

  findName(d) {
    const vis = this
    if (d.depth === 1) {
      return d.data.name
    }
    if (d.depth > 1) {
      return vis.findName(d.parent)
    }
  }

  wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.4, // ems
        y = text.attr('y'),
        dy = parseFloat(text.attr('dy')),
        tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', dy + 'em')
      while ((word = words.pop())) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan?.node()?.getComputedTextLength() > width) {
          line.pop()
          tspan.text(line.join(' '))
          line = [word]
          lineNumber++
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${lineHeight + dy}em`)
            .text(word)
        }
        if (words.length === 0) {
          text.attr('y', `${-(lineNumber * lineHeight) / 2}em`)
        }
      }
    })
  }

  textCenterSunburst(line, text, tspan, hasConcat) {
    if (!text.includes('-') || text.includes(' ')) {
      tspan.text(line.join(' '))
    } else if (text.includes('-')) {
      hasConcat ? tspan.text(line.join('-').concat('-')) : tspan.text(line.join('-'))
    }
  }

  generateLabel(text, companiesText, activeNode) {
    const vis = this
    vis.selectedLabelG.selectAll('*').remove()
    const textSVG = vis.selectedLabelG.append('text').attr('class', 'selected-label')
    let words
    if (!text.includes('-') || text.includes(' ')) {
      words = text.split(/\s+/).reverse()
    } else if (text.includes('-')) {
      words = text.split(/\-+/).reverse()
    }
    let word
    let line = []
    let y = 0
    let dy = 0
    let lineNumber = 0
    let wordNumber = 0
    const lineHeight = 1.3 // ems
    const fontSize = text.length > 35 ? '1.75em' : text.length > 20 ? '2em' : '2.875em'
    let tspan = textSVG.append('tspan').attr('x', 0).attr('y', 0).attr('font-size', fontSize)

    while ((word = words.pop())) {
      wordNumber++
      line.push(word)
      vis.textCenterSunburst(line, text, tspan)
      if (
        tspan?.node()?.getComputedTextLength() > vis.RADIUS * 2 - 25 &&
        wordNumber > 1 // in case first word is slightly wider than margins - don't break
      ) {
        line.pop()
        vis.textCenterSunburst(line, text, tspan, true)
        line = [word]
        lineNumber++
        tspan = textSVG
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', `${lineNumber * lineHeight + dy}em`)
          .attr('font-size', fontSize)
          .text(word)
      }
    }
    const offsetY = lineNumber === 0 ? -4.5 : lineNumber * 9
    vis.selectedLabelG.attr('transform', `translate(0,${-offsetY})`)
    vis.selectedLabelG
      .append('text')
      .attr('class', 'companies-label')
      .attr('x', 0)
      .attr('y', `${textSVG?.node()?.getBBox()?.height || 0}px`)
      .attr('font-size', '1.5em')
      .text(companiesText)

    vis.generateCenterHover(activeNode)
  }

  generateCenterHover(activeNode) {
    const vis = this
    vis.g.selectAll('.center-hover').remove()
    const centerHover = vis.g
      .append('g')
      .attr('class', 'center-hover')
      .attr('display', 'none')
      .on('mouseout', () => {
        vis.g.selectAll('.center-hover').attr('display', 'none')
      })

    const arcRight = d3
      .arc()
      .innerRadius(0)
      .outerRadius(vis.RADIUS)
      .startAngle(0)
      .endAngle((Math.PI * 2) / 3)

    const arcBottom = d3
      .arc()
      .innerRadius(0)
      .outerRadius(vis.RADIUS)
      .startAngle((Math.PI * 2) / 3)
      .endAngle((Math.PI * 4) / 3)

    const arcLeft = d3
      .arc()
      .innerRadius(0)
      .outerRadius(vis.RADIUS)
      .startAngle((Math.PI * 4) / 3)
      .endAngle(Math.PI * 2)

    const rightG = centerHover
      .append('g')
      .style('cursor', 'pointer')
      .on('click', (e, d) => {
        vis.clicked.call(vis, null, activeNode, false, 750, true, 'list-view')
      })
      .on('mouseover', (e, d) => {
        vis.g.selectAll('.img-right').attr('xlink:href', rightIconGreen)
        vis.g.selectAll('.text-right').attr('fill', '#1DA462')
        vis.g.selectAll('.bg-right').attr('fill', '#F8FAFC')
      })
      .on('mouseout', function (e, d) {
        vis.g.selectAll('.img-right').attr('xlink:href', rightIcon)
        vis.g.selectAll('.text-right').attr('fill', '#6C809D')
        vis.g.selectAll('.bg-right').attr('fill', 'white')
      })
      .on('mousemove', function (e, d) {})

    const leftG = centerHover
      .append('g')
      .style('cursor', 'pointer')
      .on('click', (e, d) => {
        vis.clicked.call(vis, null, activeNode, false, 750, true, 'analysis/landscape')
      })
      .on('mouseover', (e, d) => {
        vis.g.selectAll('.img-left').attr('xlink:href', leftIconGreen)
        vis.g.selectAll('.text-left').attr('fill', '#1DA462')
        vis.g.selectAll('.bg-left').attr('fill', '#F8FAFC')
      })
      .on('mouseout', function (e, d) {
        vis.g.selectAll('.img-left').attr('xlink:href', leftIcon)
        vis.g.selectAll('.text-left').attr('fill', '#6C809D')
        vis.g.selectAll('.bg-left').attr('fill', 'white')
      })
      .on('mousemove', function (e, d) {})

    const bottomG = centerHover
      .append('g')
      .style('cursor', 'pointer')
      .on('click', (e, d) => {
        vis.clicked.call(vis, null, activeNode, false, 750, true, 'analysis/charts')
      })
      .on('mouseover', (e, d) => {
        vis.g.selectAll('.img-bottom').attr('xlink:href', bottomIconGreen)
        vis.g.selectAll('.text-bottom').attr('fill', '#1DA462')
        vis.g.selectAll('.bg-bottom').attr('fill', '#F8FAFC')
      })
      .on('mouseout', function (e, d) {
        vis.g.selectAll('.img-bottom').attr('xlink:href', bottomIcon)
        vis.g.selectAll('.text-bottom').attr('fill', '#6C809D')
        vis.g.selectAll('.bg-bottom').attr('fill', 'white')
      })
      .on('mousemove', function (e, d) {})

    leftG.append('svg:title')
    rightG.append('svg:title')
    bottomG.append('svg:title')

    leftG
      .append('path')
      .attr('class', 'bg-left')
      .attr('d', arcLeft)
      .attr('fill', 'white')
      .style('stroke', '#CBD5E1')
      .style('stroke-width', '0.5')
    rightG
      .append('path')
      .attr('class', 'bg-right')
      .attr('d', arcRight)
      .attr('fill', 'white')
      .style('stroke', '#CBD5E1')
      .style('stroke-width', '0.5')
    bottomG
      .append('path')
      .attr('class', 'bg-bottom')
      .attr('d', arcBottom)
      .attr('fill', 'white')
      .style('stroke', '#CBD5E1')
      .style('stroke-width', '0.5')

    leftG
      .append('svg:image')
      .attr('class', 'img-left')
      .attr('xlink:href', leftIcon)
      .attr('width', '2em')
      .attr('height', '2em')
      .attr('x', '-5.8em')
      .attr('y', '-6em')

    leftG
      .append('text')
      .attr('class', 'text-left')
      .attr('text-anchor', 'middle')
      .attr('x', '-4em')
      .attr('y', '-1.5em')
      .attr('fill', '#6C809D')
      .text('Landscape')

    rightG
      .append('svg:image')
      .attr('class', 'img-right')
      .attr('xlink:href', rightIcon)
      .attr('width', '2em')
      .attr('height', '2em')
      .attr('x', '3.2em')
      .attr('y', '-6em')

    rightG
      .append('text')
      .attr('class', 'text-right')
      .attr('text-anchor', 'middle')
      .attr('x', '3.5em')
      .attr('y', '-1.5em')
      .attr('fill', '#6C809D')
      .text('List view')

    bottomG
      .append('svg:image')
      .attr('class', 'img-bottom')
      .attr('xlink:href', bottomIcon)
      .attr('width', '2em')
      .attr('height', '2em')
      .attr('x', '-1.2em')
      .attr('y', '3em')

    bottomG
      .append('text')
      .attr('class', 'text-bottom')
      .attr('text-anchor', 'middle')
      .attr('x', '0em')
      .attr('y', '6em')
      .attr('fill', '#6C809D')
      .text('Analytics')
  }

  labelVisible(d, name) {
    const vis = this
    name = name || d?.data?.name || ''
    const nameLines = (1 + (name.length / (vis.RADIUS - 10)) * 5).toFixed(0)
    return d.y1 < 4 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.08 * nameLines
  }

  arcVisible(d) {
    return d.y0 >= 1 && d.x1 > d.x0
  }

  labelTransform(d) {
    const vis = this
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI
    const y = ((d.y0 + d.y1) / 2) * vis.RADIUS
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`
  }

  clicked(e, p, isChange = false, time = 750, isRoot, route) {
    const vis = this
    vis.tip.hide('notDelay')
    industry_temp = vis.industry
    vis.generateLabel(p.data.name, `${vis.numberFormat(p.value)} companies`, p || vis.root)
    vis.onSelectActiveNode(e, p.data, isRoot, route)
    if (isChange) {
      const t = vis.g.transition().duration(time)
      vis.innerCircle
        .attr('r', vis.RADIUS)
        .datum(p || vis.root)
        .attr(
          'stroke',
          (!p?.children?.length || hasStroke(p)) && vis.findName.call(vis, p)
            ? vis.color(vis.findName.call(vis, p))
            : 'none'
        )

      vis.root.each(d => {
        const x0 = p.x1 - p.x0 ? (d.x0 - p.x0) / (p.x1 - p.x0) : 0
        const x1 = p.x1 - p.x0 ? (d.x1 - p.x0) / (p.x1 - p.x0) : 0
        return (d.target = {
          x0: Math.max(0, Math.min(1, x0)) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, x1)) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
        })
      })

      try {
        vis.path
          .transition(t)
          .tween('data', d => {
            const i = d3.interpolate(d.current, d.target)

            return t => {
              // update d.current position and avoid null, undefined or NaN
              const nextPosition = i(t)
              d.current = {
                x0: nextPosition.x0 || 0,
                x1: nextPosition.x1 || 0,
                y0: nextPosition.y0 || 0,
                y1: nextPosition.y1 || 0,
              }
              return d.current
            }
          })
          .filter(function (d) {
            return +this.getAttribute('fill-opacity') || vis.arcVisible.call(vis, d.target)
          })
          .attr('stroke', '#f8fafc')
          .attr('stroke-width', d => (d.target.y0 <= 3 ? '2px' : '0px'))
          .attr('fill-opacity', d =>
            d.target.y0 === 1 ? 0.6 : d.target.y0 === 2 ? 0.8 : d.target.y0 === 3 ? 1 : 1
          )
          .attr('pointer-events', d => (d.target.y0 <= 3 ? 'all' : 'none'))
          .attrTween('d', d => () => (d.data.isShow ? vis.arc(d.current) : ''))
      } catch (error) {
        console.log(error)
      }

      vis.label
        .filter(function (d) {
          return +this.getAttribute('fill-opacity') || vis.labelVisible.call(vis, d.target)
        })
        .transition(t)
        .attr('fill-opacity', d => +vis.labelVisible.call(vis, d.target, d.data.name))
        .attrTween('transform', d => () => vis.labelTransform.call(vis, d.current))
    }
  }

  selectNode = (id, parentId) => {
    const vis = this
    const nodeId = id && id != 0 ? id : parentId
    const targetNode = nodeId
      ? vis.root.find(
          node =>
            node.data.id === nodeId && (parentId ? node.data.businessLineId === parentId : true)
        )
      : vis.root
    if (!targetNode) {
      console.error(Error(`Can\'t found node with id = ${id}`))
      return
    }

    vis.nodeId = nodeId
    vis.businessLineId = parentId
    vis.clicked.call(vis, null, targetNode, true)
  }
}

export default D3Chart

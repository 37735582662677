export enum EProfileTab {
  News = 'news',
  Partnerships = 'partnerships',
  UseCase = 'useCase',
  Funding = 'funding',
  People = 'people',
  Business = 'business',
  Profile = 'profile',
  Statistics = 'statistics',
  Technology = 'technology',
  Taxonomy = 'taxonomy',
  Portfolio = 'portfolio',
  Products = 'products',
  Alerts = 'alerts',
  Investments = 'investments',
  Acquisitions = 'acquisitions',
  InvestorProfile = 'investorProfile',
}

export const PROFILE_TAB_NAME = new Map<string, string>([
  [EProfileTab.News, 'News'],
  [EProfileTab.Partnerships, 'Partnerships'],
  [EProfileTab.UseCase, 'Use Case'],
  [EProfileTab.Funding, 'Funding'],
  [EProfileTab.People, 'People'],
  [EProfileTab.Business, 'Business'],
  [EProfileTab.Profile, 'Profile'],
  [EProfileTab.Statistics, 'Statistics'],
  [EProfileTab.Technology, 'Technology'],
  [EProfileTab.Taxonomy, 'Taxonomy'],
])

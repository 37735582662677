import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import Flex from 'components/Flex/Flex'
import { useOktaAuth } from '@okta/okta-react'
import { onError } from 'utils/sentry'
import ExpiredUser from '../components/Admin/ExpiredUser'
import NoExpiredUser from '../components/Admin/NoExpiredUser'
import getMemberNoExpired from 'axios/getMemberNoExpired'
import getMemberExpired from 'axios/getMemberExpired'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '42px',
    textAlign: 'center',
    fontSize: '50px',
    lineHeight: '72px',
    whiteSpace: 'normal',
    fontWeight: 700,
  },
  container: {
    height: '100%',
    marginTop: 20,
  },
  content: {
    padding: '30px !important',
  },
  itemSideBar: {
    fontSize: 16,
    fontWeight: 600,
    transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  navbar: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    padding: '25px 30px 30px 0px !important',
  },
}))

export default function () {
  const classes = useStyles()
  const { oktaAuth } = useOktaAuth()
  const accessToken = oktaAuth?.getAccessToken()

  const [userType, setUserType] = useState<EUserType>(EUserType.NO_EXPIRY_DATE_USER)
  const [dataManagerAdmin, setDataManagerAdmin] = useState<IDataUserAdmin>(initialDataUserAdmin)
  const [loading, setLoading] = useState<boolean>(false)

  const { expiredUsers, userNoExpireds, totalExpired, totalNoExpired } = dataManagerAdmin

  const handleChangEUserType = (type: EUserType) => {
    setUserType(type)
  }

  const getData = async (token: string) => {
    //NOTE: get data user no Expired
    setLoading(true)
    try {
      await getMemberNoExpired(token).then(response => {
        setDataManagerAdmin(prev => ({
          ...prev,
          userNoExpireds: response?.data?.data,
          totalNoExpired: response?.data?.total,
        }))
      })
      await getMemberExpired(token).then(response => {
        setDataManagerAdmin(prev => ({
          ...prev,
          expiredUsers: response?.data?.data,
          totalExpired: response?.data?.total,
        }))
      })
    } catch (error) {
      onError(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (accessToken) {
      getData(accessToken)
    }
  }, [])

  return (
    <Container className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        Welcome to the admin panel
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.navbar}>
          <Flex flexDirection={'column'} gap="10px">
            {groups.map((item, idx) => (
              <div
                className={classes.itemSideBar}
                onClick={() => handleChangEUserType(item.type as EUserType)}
                key={idx}
              >
                {idx + 1} . {item.name}
              </div>
            ))}
          </Flex>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} className={classes.content}>
          {(() => {
            switch (userType) {
              case EUserType.NO_EXPIRY_DATE_USER:
                return (
                  <NoExpiredUser
                    noExpiredUsers={userNoExpireds || []}
                    title="Users with no expiry date"
                    loading={loading}
                    total={totalNoExpired}
                  />
                )
              case EUserType.EXPIRED_USER:
                return (
                  <ExpiredUser
                    expiredUsers={expiredUsers || []}
                    title="Expired users"
                    loading={loading}
                    total={totalExpired}
                  />
                )

              default:
                return null
            }
          })()}
        </Grid>
      </Grid>
    </Container>
  )
}

export enum EUserType {
  NO_EXPIRY_DATE_USER = 'NO_EXPIRY_DATE_USER',
  EXPIRED_USER = 'EXPIRED_USER',
}

const groups = [
  {
    name: 'Users with no expiry date',
    type: EUserType.NO_EXPIRY_DATE_USER,
  },
  {
    name: 'Expired users',
    type: EUserType.EXPIRED_USER,
  },
]

export type ClientUser = {
  id: string
  name: string
  email: string
  banksy_client_access_end_date?: string
}

export interface IDataUserAdmin {
  expiredUsers: ClientUser[]
  userNoExpireds: ClientUser[]
  totalExpired: number
  totalNoExpired: number
}

export const initialDataUserAdmin: IDataUserAdmin = {
  expiredUsers: [],
  userNoExpireds: [],
  totalExpired: 0,
  totalNoExpired: 0,
}

import { gql } from '@apollo/client'

export const GET_EQUITY_FUNDING_BY_FUNDING_AMOUNT = gql`
  query GetEquityFundingByFundingAmount($input: EquityFundingByFundingAmountChartFilter) {
    getEquityFundingByFundingAmountChartData(input: $input) {
      chartData {
        from
        to
        lineChartCount
        total
        totalToolTip {
          name
          count
          totalFundings
          countGrowth
          fundingGrowth
          companies {
            company_id
            logo_url
            name
          }
        }
        stacks {
          id
          name
          fundings
          toolTip {
            name
            count
            totalFundings
            countGrowth
            fundingGrowth
            companies {
              company_id
              logo_url
              name
            }
          }
        }
      }
      groupInfo {
        id
        name
        description
      }
    }
  }
`

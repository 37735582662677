import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { useState } from 'react'
import '../../components/Upload/index.css'
import theme from '../../theme'
import { ETypeDialog, FCTDialog, FCTDialogProps } from '../Dialog/FCTDialog'
import TermsOfUseDocument, { ITermsOfUseDocumentProps } from './TermsOfUseDocument'
import { EDocumentTemplate } from './types'

type Props = {
  open: boolean
  setOpen(v: boolean): void
  handleAgree(): void
} & Pick<FCTDialogProps, 'titleBtnCancel' | 'childrenFooter' | 'handleCancel'> &
  Partial<Pick<ITermsOfUseDocumentProps, 'template'>>

export default function TermsOfUseDialog({
  open,
  setOpen,
  handleAgree,
  handleCancel = () => {},
  titleBtnCancel,
  childrenFooter,
  template = EDocumentTemplate.CLIENT,
}: Props) {
  const [loadingBtnAgree, setLoadingBtnAgree] = useState(false)
  const [loadingBtnCancel, setLoadingBtnCancel] = useState(false)

  return (
    <>
      <FCTDialog
        open={open}
        setOpen={(value: boolean) => setOpen(value)}
        title="Acceptable Use and Content Policy"
        type={ETypeDialog.Default}
        titleBtnAgree="I Agree"
        titleBtnCancel={titleBtnCancel || 'Back'}
        handleAgree={async () => {
          try {
            setLoadingBtnAgree(true)
            await handleAgree()
          } finally {
            setLoadingBtnAgree(false)
          }
        }}
        handleCancel={async () => {
          try {
            setLoadingBtnCancel(true)
            await handleCancel()
          } finally {
            setLoadingBtnCancel(false)
          }
        }}
        loadingBtnAgree={loadingBtnAgree}
        loadingBtnCancel={loadingBtnCancel}
        minHeight={30}
        itemStyle={{ width: 750, height: 620, position: 'relative' }}
        childrenFooter={
          <div
            style={{
              fontSize: 12,
              lineHeight: '18px',
              color: theme.palette.grey[600],
            }}
          >
            <span>
              * We have updated our Acceptable Use and Content Policy to serve you better.
            </span>
            <br />
            <span>To continue using the platform, please agree</span>
          </div>
        }
        hasCloseOutside={false}
        hasButtonClose={false}
      >
        <TermsOfUseDocument template={template} />
      </FCTDialog>
    </>
  )
}

import _categories from './categories.csv'
import _topics from './topics.csv'
import _privacy from './privacy.csv'
import _termsOfUse from './termsOfUse.csv'

export const categories = _categories
export const topics = _topics

export const privacy = _privacy
export const termsOfUse = _termsOfUse

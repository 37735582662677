import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Card, { CardProps } from '@material-ui/core/Card'

const FCTCard = withStyles({
  root: {
    color: '#1E293B',
    borderColor: '#E7ECF3',
  },
})((props: CardProps) => {
  return <Card variant="outlined" {...props}></Card>
})

export default FCTCard

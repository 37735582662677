export const NUM_OF_EMPLOYEES_RANGES_ARR_VALUE = [
  {
    id: 1,
    value: '1-10',
  },
  {
    id: 10,
    value: '11-50',
  },
  {
    id: 50,
    value: '51-100',
  },
  {
    id: 100,
    value: '101-250',
  },
  {
    id: 250,
    value: '251-500',
  },
  {
    id: 500,
    value: '501-1000',
  },
  {
    id: 1001,
    value: '1001-5000',
  },
  {
    id: 5000,
    value: '5001-10000',
  },
  {
    id: 10000,
    value: '10001+',
  },
]

export const EXPAND_ROUND1_ID = {
  DEBT_FINANCING: 1,
  EQUITY_FINANCING: 2,
  MA: -1,
  IPO: -2,
}

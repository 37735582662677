import {
  GetAtlasViewData_getAtlasViewData,
  GetAtlasViewData_getAtlasViewData_companies,
  GetAtlasViewData_getAtlasViewData_latestDeals,
} from '../__generated__/GetAtlasViewData'
import { IDimensionTree } from '../types/Dimension'
import { concat, groupBy, isNil } from 'lodash'
import { GetDealFilterDataResult_getDealFilterData_products } from '../__generated__/GetDealFilterDataResult'
import { ITreeData } from 'types/Tree'

const fillTaxonomiesTreeObject = (
  dimensionTree: IDimensionTree,
  data: GetAtlasViewData_getAtlasViewData | IAnalysisProduct
): IDimensionTree => {
  dimensionTree.children = dimensionTree.children || []
  dimensionTree.id = !isNil(data.id) ? data.id : dimensionTree.id
  dimensionTree.name = data.name || dimensionTree.name
  dimensionTree.size = data.size || dimensionTree.size || 0
  dimensionTree.parentId = !isNil(data.parentId) ? data.parentId : dimensionTree.parentId
  dimensionTree.description = data.description || dimensionTree.description
  dimensionTree.businessLineId = data.businessLineId || dimensionTree.businessLineId
  dimensionTree.companies = data.companies || dimensionTree.companies
  dimensionTree.totalFundings = data.totalFundings || dimensionTree.totalFundings
  dimensionTree.isShow = data.isShow || dimensionTree.isShow
  dimensionTree.category = data.category || dimensionTree.category
  dimensionTree.latestDeals = data.latestDeals || dimensionTree.latestDeals
  return dimensionTree
}

export const TaxonomyMapping = (
  taxonomies: (GetAtlasViewData_getAtlasViewData | IAnalysisProduct)[]
): IDimensionTree => {
  if (taxonomies.length === 0) return {} as IDimensionTree
  let idToNodeMap = new Map<number, IDimensionTree>()
  let root = {} as IDimensionTree
  const ids = new Map(taxonomies.map(item => [item.id, item]))
  const rootIds = taxonomies.filter(el => isNil(el.parentId) || !ids.get(el.parentId))

  for (let i = 0; i < taxonomies.length; i++) {
    let datum = idToNodeMap.get(taxonomies[i].id as number)
      ? fillTaxonomiesTreeObject(
          idToNodeMap.get(taxonomies[i].id as number) as IDimensionTree,
          taxonomies[i] as GetAtlasViewData_getAtlasViewData
        )
      : fillTaxonomiesTreeObject({} as IDimensionTree, taxonomies[i])
    idToNodeMap.set(taxonomies[i].id, datum)

    if (taxonomies[i].id === rootIds[0].id) {
      root = datum
    } else {
      const parentNode =
        idToNodeMap.get(taxonomies[i].parentId as number) ||
        (fillTaxonomiesTreeObject(
          { id: taxonomies[i].parentId } as IDimensionTree,
          {} as GetAtlasViewData_getAtlasViewData
        ) as IDimensionTree)
      idToNodeMap.set(taxonomies[i].parentId as number, parentNode)
      parentNode && (parentNode.children as IDimensionTree[]).push(datum)
    }
  }

  return root
}

function partition<T>(ary: T[], callback: (item: T) => boolean): [T[], T[]] {
  return ary.reduce(
    (acc: [T[], T[]], e) => {
      acc[callback(e) ? 0 : 1].push(e)
      return acc
    },
    [[], []]
  )
}

export const TaxonomyMappingWithBusiness = (
  taxonomies: GetAtlasViewData_getAtlasViewData[]
): IDimensionTree => {
  const [[root], items] = partition(taxonomies, item => item.id == 0)
  if (!root) return {} as IDimensionTree

  const groups = groupBy(items, 'businessLineId')
  const trees = Object.values(groups).map((el: any) => TaxonomyMapping(el))

  const newTrees = trees
    .filter(item => !item.parentId)
    .map(el => ({
      ...el,
      children: concat(
        el.children || [],
        trees.filter(node => node.parentId == el.id)
      ),
    }))

  let rootNode = {} as IDimensionTree
  if (newTrees.length === 1) {
    rootNode = newTrees[0] as IDimensionTree
  } else {
    rootNode = fillTaxonomiesTreeObject({} as IDimensionTree, root)
    rootNode.children = newTrees
  }

  return rootNode
}

export const TaxonomyFilterMapping = (
  data: GetDealFilterDataResult_getDealFilterData_products[]
) => {
  const newData = data.map(
    (el: GetDealFilterDataResult_getDealFilterData_products) =>
      ({
        ...el,
        parentId: el.id == el.productLv1Id ? 0 : el.parentIds[0],
      } as IAnalysisProduct)
  )

  const groups = groupBy(newData, 'productLv1Id')

  const tree = Object.values(groups).map(el => {
    return TaxonomyMapping(el)
  })
  return tree.map(node => ({
    ...node,
    parentId: node.id,
  }))
}

export const getProductsByIds = (
  products: GetDealFilterDataResult_getDealFilterData_products[],
  ids: number[]
) => {
  return products.filter(
    (d: GetDealFilterDataResult_getDealFilterData_products) =>
      d.parentIds.filter((parentId: number) => ids.includes(parentId)).length > 0
  )
}

export const getProductByBusinessLine = (
  products: GetDealFilterDataResult_getDealFilterData_products[],
  ids: number[]
) => {
  return ids?.length
    ? products.filter((product: GetDealFilterDataResult_getDealFilterData_products) =>
        getProductsByIds(products, ids).find(
          (p: GetDealFilterDataResult_getDealFilterData_products) => product.productLv1Id == p.id
        )
      )
    : products
}

function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export function mergeDeeps(target: any, source: any) {
  let output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeeps(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

export function getFlatArrayFromTree(productsTree: ITreeData[]) {
  let list: ITreeData[] = []
  const flatten = (item: ITreeData[] | undefined) => {
    item &&
      item.forEach(({ children, ...child }) => {
        list.push({ ...child })
        flatten(children)
      })
  }
  flatten(productsTree)
  return list
}

export interface IAnalysisProduct extends GetDealFilterDataResult_getDealFilterData_products {
  parentId: number
  size: number | null
  description: string | null
  businessLineId?: number | null
  totalFundings: number | null
  companies: GetAtlasViewData_getAtlasViewData_companies[] | null
  isShow: boolean
  latestDeals: GetAtlasViewData_getAtlasViewData_latestDeals[] | null
}

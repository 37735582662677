import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { IOpenOptionFilter } from '../../store/features/filter/FilterSlice'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import { AccordionItem } from '../Filters/AccordionItem'
import AllDealItem from './share/AllDealItem'
import FundingItem from './share/FundingItem'

const useStyles = makeStyles(theme => ({
  formLabel: {
    color: theme.palette.grey['800'],
    fontSize: 12,
    fontWeight: 600,
  },
  flex: {
    flex: 1,
  },
  container: {
    borderBottom: '1px solid #E7ECF3',
  },
}))

const initIsDealType = {
  dealType: [],
  allDealType: [],
}

const FinancingItem = (props: IFinancingItemProps) => {
  const classes = useStyles()
  const {
    filter,
    setFilter,
    handleChange,
    getFilterData,
    openOption,
    setOpenOption,
    customStyle,
    disabledFundingDate,
  } = props

  return (
    <div className={classes.container}>
      <AccordionItem
        title="Funding"
        open={openOption?.financing}
        setOpen={value => setOpenOption && setOpenOption(value, 'financing')}
        customStyle={customStyle}
      >
        <FundingItem filter={filter} setFilter={setFilter} getFilterData={getFilterData} />
        <AllDealItem
          filter={filter}
          setFilter={setFilter}
          getFilterData={getFilterData}
          disabledFundingDate={disabledFundingDate}
        />
      </AccordionItem>
    </div>
  )
}

export default FinancingItem

interface IFinancingItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  handleChange(e: React.SyntheticEvent): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  openOption?: IOpenOptionFilter
  setOpenOption?: (value: boolean, tag: string) => void
  customStyle?: { title?: React.CSSProperties }
  disabledFundingDate?: boolean
}

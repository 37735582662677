import { makeStyles, Typography } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import React, { useState } from 'react'
import { useUserContext } from 'routers/InitUserWrapper'
import { AddWatchList } from '../../module/WatchList/AddWatchList'
import { applySearchOptToAnalysis } from '../../store/features/analysis/AnalysisAction'
import { applySearchOptToCompanyDeal } from '../../store/features/company-deal/CompanyDealAction'
import { setCurrentProfileTab } from '../../store/operations/mutations'
import theme from '../../theme'
import { numberWithCommas } from '../../utils/convert/number'
import FCTButton from '../Button/FCTButton'
import { ESearchTab } from '../ExploreDBComponent/MockData'
import FCTIcon from '../Icon/Icon'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '25px 30px',
    border: '1px solid #E7ECF3',
    boxShadow: '0px 3px 4px rgba(148, 163, 184, 0.05)',
    boxSizing: 'border-box',
  },
  headerText: {
    maxWidth: 400,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
  },
  totalResult: {
    color: theme.palette.primary.light,
  },
  group: {
    display: 'flex',
  },
  btn: {
    minWidth: '110px',
    border: '0 !important',
    borderRadius: 2,
    '& span': {
      color: theme.palette.grey[500],
    },
    '&:disabled': {
      backgroundColor: '#ffffff !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:hover $icon': {
      color: '#fff',
    },
    '&:hover $span': {
      color: '#fff',
    },
  },
  contentNavgate: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
  },
  itemRight: {
    width: 1,
    height: '70%',
    background: theme.palette.grey[200],
  },
  icon: {},
}))

export interface ISearchResultProps {
  total?: number
  listAllCompanyIds: string[]
  selectedName?: string
}

export default (props: ISearchResultProps) => {
  const classes = useStyles()
  const [openNotify, setOpenNotify] = useState<boolean>(false)
  const { restricted } = useUserContext()

  const { listAllCompanyIds, selectedName } = props

  const navigateHandler = () => {
    if ((props as any).total >= 10000) {
      setOpenNotify(true)
    } else {
      setCurrentProfileTab(ESearchTab.Company)
    }
  }

  return (
    <div className={classes.header}>
      <Typography className={classes.headerText}>
        <span className={classes.totalResult}>{numberWithCommas(props.total || 0)}</span> results
        found.
        <br />
        For deep dives, view results using our other tools.
      </Typography>
      <div className={classes.group}>
        {!restricted.watchlist && (
          <div style={{ marginRight: 16 }}>
            <AddWatchList selectedName={selectedName} selectedList={listAllCompanyIds} />
          </div>
        )}

        <div className={classes.contentNavgate}>
          <FCTButton
            variant="outlined"
            startIcon={
              <FCTIcon
                name="dashboard"
                width={16}
                height={16}
                color={`${theme.palette.grey[400]}`}
                className={classes.icon}
              />
            }
            className={classes.btn}
            disabled={!props.total}
            reactLink={
              (props as any).total < 10000
                ? `/redirect?cb=/app/analysis/landscape&action=${applySearchOptToAnalysis.type}`
                : undefined
            }
            onClick={navigateHandler}
          >
            Landscape
          </FCTButton>
          <div className={classes.itemRight}> </div>
          <FCTButton
            variant="outlined"
            startIcon={
              <FCTIcon
                name="chartcompare"
                width={18}
                height={18}
                color={`${theme.palette.grey[400]}`}
                className={classes.icon}
              />
            }
            className={classes.btn}
            disabled={!props.total}
            reactLink={
              (props as any).total < 10000
                ? `/redirect?cb=/app/analysis/charts&action=${applySearchOptToAnalysis.type}`
                : undefined
            }
            onClick={navigateHandler}
          >
            Analytics
          </FCTButton>
          <div className={classes.itemRight}> </div>
          <FCTButton
            variant="outlined"
            startIcon={
              <FCTIcon
                name="list"
                width={16}
                height={16}
                color={`${theme.palette.grey[400]}`}
                className={classes.icon}
              />
            }
            className={classes.btn}
            disabled={!props.total}
            reactLink={
              (props as any).total < 10000
                ? `/redirect?cb=/app/list-view&action=${applySearchOptToCompanyDeal.type}`
                : undefined
            }
            onClick={navigateHandler}
          >
            List view
          </FCTButton>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openNotify}
        autoHideDuration={5000}
        onClose={() => setOpenNotify(false)}
        message="The current search is too broad. Please refine your keywords to narrow down the results."
      />
    </div>
  )
}

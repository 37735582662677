import { makeVar } from '@apollo/client'
import { EProfileTab } from '../../types/Profile'
import { IProfileFilter } from '../../types/ProfileFilter'
import SetProfileFilter from '../operations/mutations/SetProfileFilter'

export const initProfileFilter: IProfileFilter = {
  profileTab: EProfileTab.News,
}

export const profileFilterVar = makeVar<IProfileFilter>(initProfileFilter)
export const profileFilterPolicies = {
  profileFilter: {
    read() {
      return profileFilterVar()
    },
  },
}
export const setProfileFilter = SetProfileFilter(profileFilterVar)

import { Typography, useTheme, makeStyles, Box } from '@material-ui/core'
import SubmitButton from 'components/Authentication/BusinessEmail/SubmitButton'
import Icon from 'components/Icon/Icon'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[900],
  },
  link: {
    color: `${theme.palette.primary.light}`,
    marginLeft: '4px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}))

type Props = {
  onClick(): void
  message?: string
}

export default function SuccessfulPasswordReset({
  onClick,
  message = 'You can now use your new password to login to your account.',
}: Props) {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Box textAlign="center">
      <Icon name="passwordChanged" width="80px" height="80px" />
      <Typography
        style={{
          color: theme.palette.grey[900],
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '34px',
          marginBottom: '16px',
          marginTop: '24px',
        }}
        component="h3"
      >
        Successful Password Reset
      </Typography>

      <Typography
        className={classes.text}
        style={{
          marginBottom: '10px',
        }}
      >
        {message}
      </Typography>

      <SubmitButton onClick={onClick} text="Login Now" />
    </Box>
  )
}

import { gql } from '@apollo/client'

export const GET_ATLAS_VIEW_DATA = gql`
  query GetAtlasViewData($input: AtlasViewQueryInput) {
    getAtlasViewData(input: $input) {
      id
      name
      size
      parentId
      description
      businessLineId
      totalFundings
      companies {
        company_id
        logo_url
        name
        totalFunding
        fct_score_overall
      }
      category
      isShow
      latestDeals {
        company_id
        logo_url
        deal_id
        deal_date
        amount
        round
        investor_names
      }
    }
  }
`

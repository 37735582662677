import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles'
import { UsePaginationProps } from '@material-ui/lab/Pagination'
import clsx from 'clsx'
import { FCTSelectRadio } from 'components/Select/FCTSelectRadio'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import theme from '../../theme'
import { ChangeType, FCTOption } from '../Select/FCTSelect'
import { FCTPagination } from './FCTPagination'

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0px 22px 0;
`
const RowsPerPageSelect = styled.div`
  display: flex;
  align-items: center;
`

const useStyles = makeStyles((theme: Theme) => ({
  paginationText: {
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.grey['800'],
  },
  labelDisplayedRows: {
    marginLeft: '14px',
  },
}))

export function FCTTablePagination(props: FCTTablePaginationProp) {
  const { onChangeRowsPerPage, paginationProps, rowsPerPage, labelDisplayedRows } = props
  const classes = useStyles()

  const rowsPerPageOptions = useMemo(() => {
    return props.rowsPerPageOptions.map(
      opt => ({ value: opt.toString(), label: opt.toString() } as FCTOption)
    )
  }, [props.rowsPerPageOptions])

  return (
    <MuiThemeProvider theme={theme}>
      <PaginationContainer>
        <Grid container justify="space-between">
          <Grid item>
            <RowsPerPageSelect>
              <FCTSelectRadio
                data={rowsPerPageOptions}
                value={[rowsPerPage?.toString() || '10']}
                setValue={v => {
                  onChangeRowsPerPage({ type: ChangeType.SELECT, value: v.value })
                }}
                extraText="Displaying"
                size="small"
                fullWidthPercent={120 / 310}
              />

              <Typography className={clsx(classes.paginationText, classes.labelDisplayedRows)}>
                {labelDisplayedRows}
              </Typography>
            </RowsPerPageSelect>
          </Grid>

          <Grid container item justify="flex-end" alignItems="center" style={{ flex: 1 }}>
            <FCTPagination {...paginationProps} />
          </Grid>
        </Grid>
      </PaginationContainer>
    </MuiThemeProvider>
  )
}

interface FCTTablePaginationProp {
  rowsPerPage?: number
  rowsPerPageOptions: number[]
  labelDisplayedRows?: JSX.Element
  onChangeRowsPerPage: (event: { type: ChangeType; value: string }) => void

  // Material-ui pagination props
  paginationProps: UsePaginationProps
}

import { Tab, TabProps, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import FCTTooltip from '../Tooltip/Tooltip'

export enum TooltipDesc {
  AllIndustries = 'Universe of FCT companies across all industries in FinTech, RegTech and InsurTech.',
  FinTech = 'FinTechs are firms that innovatively apply technology to transform and enhance the financial services industry.',
  RegTech = 'RegTechs are FinTechs that innovatively apply technology in financial services to enhance regulatory compliance or supervision.',
  InsurTech = 'InsurTechs are firms that innovatively apply technology to transform and enhance the insurance industry.',

  Product = 'Product classifies companies based on their specific product and/or services offered.',
  Business = 'Business classifies companies based on the business line within an incumbent financial institution that they aim to service/disrupt.',
  ValueChain = 'Value chain classifies companies based on the journey of the insurance lifecycle.',
  OperatingModel = '',
}

export const DESCRIPTIONS_DATA_MISSING = [
  {
    id: -1,
    text: TooltipDesc.InsurTech,
  },
  {
    id: -2,
    text: TooltipDesc.FinTech,
  },
  {
    id: -3,
    text: TooltipDesc.RegTech,
  },
]

const FCTTab = withStyles(theme => ({
  root: {
    minHeight: 0,
    textTransform: 'none',
    opacity: 1,
    '&.small': {
      color: theme.palette.secondary.main,
      height: 32,
      borderRadius: 2,
      margin: 4,
      minWidth: 32,
      whiteSpace: 'nowrap',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '18px',
      padding: '0 8px',
      '& $wrapper': {
        '& .MuiSvgIcon-root': {
          marginRight: 5,
          marginBottom: '0!important',
          fontSize: 15,
        },
      },
    },
    '&.large': {
      border: '1px solid #E7ECF3',
      background: theme.palette.grey[200],
      height: 60,
      borderRadius: '3px 3px 0 0',
      borderBottom: 0,
      marginRight: 31,
      minWidth: 204,
      whiteSpace: 'nowrap',
      overflow: 'unset',
      '& $wrapper': {
        justifyContent: 'left',
        '& svg': {
          color: theme.palette.grey[400],
        },
      },
    },
  },
  selected: {
    '&.small': {
      color: '#FFFFFF',
      background: theme.palette.primary.main,
    },
    '&.large': {
      marginBottom: 0,
      paddingTop: 0,
      backgroundColor: theme.palette.primary.contrastText,
      borderBottom: 0,
      borderRadius: '3px 3px 0 0',
      '& $wrapper': {
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  wrapper: {
    flexDirection: 'row',
  },
  labelIcon: {
    '&.small': {
      '& $wrapper > *:first-child': {
        marginBottom: 0,
        marginRight: 8,
      },
    },
    '&.large': {
      padding: '8px 19px 8px',
      '& $wrapper > *:first-child': {
        marginBottom: 0,
        marginRight: 15,
      },
    },
  },
  disabled: {
    opacity: 0.5,
  },
}))((props: ITabProps) => {
  const { size, tooltip, width = 'unset' } = props

  return (
    <FCTTooltip
      title={<Typography style={{ fontSize: 12 }}>{tooltip}</Typography>}
      disableHoverListener={!tooltip || props.disabled}
      arrow
      placement="bottom"
    >
      <div style={{ width: width === 'fullWidth' ? '100%' : 'unset', display: 'flex' }}>
        <Tab {...props} className={size} fullWidth={width === 'fullWidth'} />
      </div>
    </FCTTooltip>
  )
})

export default FCTTab

export interface ITabProps extends TabProps {
  size: 'small' | 'large'
  tooltip?: string
  width?: 'fullWidth' | 'unset'
}

import { makeStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
export interface ListProps {
  title?: string
  renderHeader?: () => JSX.Element
  items?: any[]
  renderItem: (item: any) => JSX.Element
  getKey: (item: any) => any
  childrenStyle?: React.CSSProperties
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiListSubheader-gutters': {
      padding: 0,
    },
  },
  children: {
    padding: 10,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))

const FCTList = (props: ListProps) => {
  const classes = useStyles()
  return (
    <List
      disablePadding={true}
      className={classes.root}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" disableSticky={true}>
          {props.renderHeader ? props.renderHeader() : props.title}
        </ListSubheader>
      }
    >
      <div className={classes.children} style={props.childrenStyle}>
        {props.items?.map(item => (
          <div key={props.getKey(item)}>
            <ListItem>{props.renderItem(item)}</ListItem>
          </div>
        ))}
      </div>
    </List>
  )
}
export default FCTList

import { useState, useRef } from 'react'
import { FormField, FormFieldProps } from 'components/Authentication/FormField/FormField'
import { useTheme } from '@material-ui/core'
import PasswordPolicy from 'components/PasswordPolicy/PasswordPolicy'
import { FCTTooltipMove } from 'components/Tooltip/TooltipMove'
import ReactTooltip from 'react-tooltip'

export function PasswordField<T>({
  firstName,
  lastName,
  email,
  ...props
}: {
  firstName?: string
  lastName?: string
  email: string
} & FormFieldProps<T>) {
  const theme = useTheme()

  const [_, setForceRender] = useState(false)
  const toolTipRef = useRef<any>(null)

  return (
    <FCTTooltipMove
      ref={toolTipRef}
      place="left"
      type="light"
      event="none"
      globalEventOff="none"
      text={
        <PasswordPolicy
          password={(props.control.getValues('password') as string) || ''}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      }
      isMove={false}
      border
      borderColor={theme.palette.divider}
      clickable
    >
      <FormField
        {...props}
        afterChange={() => setForceRender(p => !p)}
        onFocus={() => {
          !!toolTipRef?.current && ReactTooltip.show(toolTipRef?.current)
        }}
        onBlur={() => {
          props.onBlur()
          !!toolTipRef?.current && ReactTooltip.hide(toolTipRef?.current)
        }}
      />
    </FCTTooltipMove>
  )
}

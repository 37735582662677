import { createSelector } from '@reduxjs/toolkit'
import { Selector } from 'react-redux'
import { RootState } from '../../store'
import { IWatchListSlice } from './WatchListSlice'

const selectWatchList: Selector<RootState, IWatchListSlice> = (state: RootState) =>
  state.watchListReducer
const selectWatchListId = createSelector(selectWatchList, state => state.watchListId)
const selectWatchListName = createSelector(selectWatchList, state => state.watchListName)
const selectWatchListFilter = createSelector(selectWatchList, state => state.filter)
const selectWatchListType = createSelector(selectWatchList, state => state.watchListType)
const selectWatchListTab = createSelector(selectWatchList, state => state.watchListTab)
const selectSharePermission = createSelector(selectWatchList, state => state.sharePermission)
const selectShareWatchList = createSelector(selectWatchList, state => state.shareWatchList)
const selectOpenNotifications = createSelector(selectWatchList, state => state.openNotifications)

export {
  selectWatchList,
  selectWatchListId,
  selectWatchListName,
  selectWatchListFilter,
  selectWatchListType,
  selectWatchListTab,
  selectSharePermission,
  selectShareWatchList,
  selectOpenNotifications,
}

import { Typography, useTheme, makeStyles, Box } from '@material-ui/core'
import Icon, { IconMap } from 'components/Icon/Icon'
import Flex from 'components/Flex/Flex'
import AuthButton from 'components/AuthButton/AuthButton'
import React, { SetStateAction, useState } from 'react'
import ResetPasswordForm, {
  IResetPasswordFormProps,
} from 'components/ResetPasswordForm/ResetPasswordForm'
import SuccessfulPasswordReset from 'components/SuccessfulPasswordReset/SuccessfulPasswordReset'
import { ErrorMessage } from 'components/Authentication/AuthError'
import { StringHighlightList } from 'components/Search/type'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[900],
  },
  link: {
    color: `${theme.palette.primary.light}`,
    marginLeft: '4px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}))

export enum EPaswordExpiredStep {
  ASK_FOR_RESET,
  FILL_PASSWORD,
  SUCCESS,
}

export type PasswordExpiredProps = {
  handleCancel: (isSuccess?: boolean) => void
  validatePasswordMaterials: Pick<
    IResetPasswordFormProps,
    'businessEmail' | 'firstName' | 'lastName'
  >
  remainingDays?: number
  step: EPaswordExpiredStep
  setStep: React.Dispatch<React.SetStateAction<EPaswordExpiredStep>>
  successMessage?: string
} & Pick<IResetPasswordFormProps, 'handleSubmit'>

const PasswordExpired = ({
  handleCancel,
  validatePasswordMaterials,
  handleSubmit,
  remainingDays = 0,
  step,
  setStep,
  successMessage,
}: PasswordExpiredProps) => {
  const theme = useTheme()

  const [error, setError] = useState('')

  const gotoNextStep = () => {
    const nextStep = {
      [EPaswordExpiredStep.ASK_FOR_RESET]: EPaswordExpiredStep.FILL_PASSWORD,
      [EPaswordExpiredStep.FILL_PASSWORD]: EPaswordExpiredStep.SUCCESS,
      [EPaswordExpiredStep.SUCCESS]: EPaswordExpiredStep.SUCCESS,
    }[step]

    nextStep !== step && setStep(nextStep)
  }

  return (
    <>
      {step === EPaswordExpiredStep.ASK_FOR_RESET && (
        <PasswordExpiredConfirm
          onCancel={() => handleCancel()}
          onResetNow={() => gotoNextStep()}
          remainingDays={remainingDays}
        />
      )}
      {step === EPaswordExpiredStep.FILL_PASSWORD && (
        <>
          <Typography
            style={{
              color: theme.palette.grey[900],
              fontWeight: 600,
              fontSize: 24,
              lineHeight: '34px',
              marginBottom: '10px',
            }}
            component="h3"
          >
            Reset Your Password
          </Typography>
          <Typography
            style={{
              color: theme.palette.grey[600],
              fontSize: 14,
              lineHeight: '22px',
              marginBottom: '32px',
            }}
          >
            A strong password helps prevent unauthorized access to your account.
          </Typography>
          {error && <ErrorMessage text={error} />}
          <ResetPasswordForm
            requiredOldPassword
            businessEmail={validatePasswordMaterials.businessEmail}
            handleSubmit={async data => {
              try {
                await handleSubmit(data)
                gotoNextStep()
              } catch (e: any) {
                setError(e?.response?.data?.message || e.message)
              }
            }}
          />
        </>
      )}
      {step === EPaswordExpiredStep.SUCCESS && (
        <SuccessfulPasswordReset onClick={() => handleCancel(true)} message={successMessage} />
      )}
    </>
  )
}

export default PasswordExpired

const PasswordExpiredConfirm = ({
  onCancel,
  onResetNow,
  remainingDays = 0,
}: {
  onCancel: React.MouseEventHandler<HTMLButtonElement>
  onResetNow: React.MouseEventHandler<HTMLButtonElement>
  remainingDays?: number
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const {
    title,
    message,
    icon,
    cancelText,
  }: { title: string; message: string; icon: keyof typeof IconMap; cancelText: string } =
    remainingDays > 0
      ? {
          title: 'Password Expire Warning',
          message: `Your password expires in ${remainingDays} days! Do you want to reset it now?`,
          icon: 'expiredWarning',
          cancelText: 'Maybe Later',
        }
      : {
          title: 'Password Has Expired',
          message: 'Your password has expired and needs to be updated.',
          icon: 'pwdLock',
          cancelText: 'Cancel',
        }

  return (
    <Box textAlign="center">
      <Icon name={icon} width="80px" height="80px" />
      <Typography
        style={{
          color: theme.palette.grey[900],
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '34px',
          marginBottom: '16px',
          marginTop: '24px',
        }}
        component="h3"
      >
        {title}
      </Typography>

      <Typography
        className={classes.text}
        style={{
          marginBottom: '10px',
        }}
      >
        {message}
      </Typography>

      <Flex justifyContent="space-between">
        <AuthButton text={cancelText} onClick={onCancel} />
        <AuthButton variant="contained" text="Reset now" onClick={onResetNow} />
      </Flex>
    </Box>
  )
}

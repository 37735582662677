import { gql } from '@apollo/client'

export const SEARCH_TAGS = gql`
  query SearchTags($names: [String!]) {
    searchTags(names: $names) {
      id
      tagName
      tagDesc
    }
  }
`

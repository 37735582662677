import { createSlice } from '@reduxjs/toolkit'
import { ESwitchFemale, IAnalysisFilter } from '../../../types/Filter'
import { mergeDeeps } from '../../../utils/TaxonomyMapping'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../../../__generated__/globalTypes'
import { EWatchListType } from '../../../components/WatchList/SelectTabWatchList'
import { initSmartCluster } from '../company-deal/CompanyDealSlice'

export const initialWatchlistFilter: IAnalysisFilter = {
  businessLines: null,
  valueChains: null,
  risks: null,
  products: null,
  operatingModels: null,
  regions: null,
  subRegions: null,
  subRegion2s: null,
  countries: null,
  businessModelIds: [0],
  distributionModelIds: null,
  fundingRound1s: [],
  fundingRound2s: [],
  category: InternalDbCompanyTypes.all,
  viewBy: GlobalFilterViewBy.product,
  selectedBusinessLineId: null,
  selectedProductId: null,
  companyStatuses: null,
  //new
  productParentId: null,
  numOfEmployeesRanges: {
    start: '1',
    end: '10001+',
  },
  switchFemalePicker: ESwitchFemale.CustomCount,
  numOfFemaleFounderFrom: '',
  numOfFemaleFounderTo: '',
  numOfFemaleFounderPercentFrom: '',
  numOfFemaleFounderPercentTo: '',
  yearFoundedRange: false,
  foundedYearFrom: null,
  foundedYearTo: null,
  latestFundingRound1s: [],
  latestFundingRound2s: [],
  totalFundingFrom: '',
  totalFundingTo: '',
  equityFundingFrom: '',
  equityFundingTo: '',
  latestDealAmountFrom: '',
  latestDealAmountTo: '',
  dealAmountFrom: '',
  dealAmountTo: '',
  yearFundingRange: false,
  fundingDateFrom: null,
  fundingDateTo: null,
  investors: [],
  investorsTypes: [],
  leadInvestors: [],
  leadInvestorTypes: [],
  numberOfInvestorsRanges: {
    start: '0',
    end: '0',
  },
  numberOfFundingRounds: {
    start: '0',
    end: '0',
  },
  acquirerTypes: [],
  acquirers: [],
  acquisitionYearRange: false,
  acquiredDateFrom: null,
  acquiredDateTo: null,
  tagGroupIds: [],
  includedEntities: [],
  wentPublicOnDateFrom: null,
  wentPublicOnDateTo: null,
  smartCluster: initSmartCluster,
  modeName: null,
}
export interface IWatchListSlice {
  filter: IAnalysisFilter
  watchListId: number | null
  watchListName: string | null
  watchListType: EWatchListType | null
  watchListTab: EWatchListTab
  sharePermission: ISharePermission
  shareWatchList: IShareWatchList
  openNotifications: boolean
}

export interface ISharePermission {
  needRequest: boolean | null
  roleId: number | null
  roleKey: string | null
  sharedBy: string | null
}

export interface IShareWatchList {
  open: boolean
  shareTab: EShareTab
}

export enum EShareTab {
  TeamMembers,
  ManageRequests,
}

const watchListId: number | null = null
const watchListName: string | null = null
const watchListType: EWatchListType | null = null

const sharePermission: ISharePermission = {
  needRequest: null,
  roleId: null,
  roleKey: null,
  sharedBy: null,
}

export enum EWatchListTab {
  MyWatchLists = 'myWatchList',
  Shared = 'sharedWatchList',
  Trash = 'trashWatchList',
}

const watchListTab: EWatchListTab = EWatchListTab.MyWatchLists

const watchListSlice = createSlice({
  name: 'filter',
  initialState: {
    filter: initialWatchlistFilter,
    watchListId,
    watchListName,
    watchListType,
    watchListTab,
    sharePermission: sharePermission,
    shareWatchList: {
      open: false,
      shareTab: EShareTab.TeamMembers,
    },
    openNotifications: false,
  },
  reducers: {
    updateWatchListId: (state, action) => {
      state.watchListId = action.payload
    },
    updateWatchListName: (state, action) => {
      state.watchListName = action.payload
    },
    updateWatchListTab: (state, action) => {
      state.watchListTab = action.payload
    },
    updateSharePermission: (state, action) => {
      state.sharePermission = mergeDeeps(state.sharePermission, action.payload)
    },
    updateWatchListFilter: (state, action) => {
      state.filter = mergeDeeps(state.filter, action.payload)
    },
    updateWatchListType: (state, action) => {
      state.watchListType = action.payload
    },
    updateShareWatchList: (state, action) => {
      state.shareWatchList = mergeDeeps(state.shareWatchList, action.payload)
    },
    updateOpenNotificationse: (state, action) => {
      state.openNotifications = action.payload
    },
  },
})

export const {
  updateWatchListId,
  updateWatchListTab,
  updateWatchListName,
  updateSharePermission,
  updateWatchListFilter,
  updateWatchListType,
  updateShareWatchList,
  updateOpenNotificationse,
} = watchListSlice.actions
export default watchListSlice.reducer

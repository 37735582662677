import { IconMap } from '../Icon/Icon'

export enum ESearchTab {
  Company = 'Company',
  Funding = 'Funding',
  MA = 'M&A',
  IPO = 'IPO',
}

export const DataLVTabs: IDataLargeTabs[] = [
  {
    id: ESearchTab.Company,
    logo: 'company',
    title: 'Company',
    amount: 0,
    isInitialized: false,
    totalCompaniesByDeal: 0,
  },
  {
    id: ESearchTab.Funding,
    logo: 'analytics',
    title: 'Funding',
    amount: 0,
    isInitialized: false,
    totalCompaniesByDeal: 0,
  },
  {
    id: ESearchTab.MA,
    logo: 'analytics',
    title: 'M&A',
    amount: 0,
    isInitialized: false,
    totalCompaniesByDeal: 0,
  },
  {
    id: ESearchTab.IPO,
    logo: 'analytics',
    title: 'IPO',
    amount: 0,
    isInitialized: false,
    totalCompaniesByDeal: 0,
  },
]

export interface IDataLargeTabs {
  id: ESearchTab
  logo: keyof typeof IconMap
  title: string
  amount: number
  totalCompaniesByDeal: number
  isInitialized: boolean
}

import { makeStyles, TextField, TextFieldProps, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import { FCTTooltipMove, FCTTooltipMoveProps } from '../Tooltip/TooltipMove'
import theme from '../../theme'

export const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator={true}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: '100px',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 3,
      '& fieldset': {
        borderColor: '#E7ECF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7ECF3',
      },
      '&.Mui-error': {
        '&:hover fieldset, &:focus fieldset': {
          borderColor: '#C41300',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#C41300',
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.light,
        borderWidth: 1,
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#E7ECF3',
      },
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.grey[800],
      fontSize: 12,
      transform: 'translate(14px, -6px) scale(1)',
      backgroundColor: '#fff',
      paddingRight: 5,
      '&.Mui-error': {
        color: '#C41300',
      },
      '&.Mui-focused': {
        color: theme.palette.primary.light,
      },
      '&.Mui-error.Mui-focused': {
        color: '#C41300',
      },
      '&.Mui-disabled': {
        opacity: 0.7,
      },
    },
    '& .MuiInputBase-input': {
      '&.Mui-disabled': {
        opacity: 0.7,
      },
      '&::-webkit-input-placeholder': {
        color: theme.palette.grey[500],
        opacity: 1,
      },
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      color: theme.palette.grey[800],
      paddingRight: 0,
    },
    '& textarea': {
      marginRight: 5,
      '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey['300'],
        borderRadius: 10,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: isSmall => (isSmall ? '12px 14px' : '17px 14px'),
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#C41300',
      marginLeft: 0,
      lineHeight: '18px',
      fontSize: 12,
      position: 'absolute',
      top: '100%',
      left: '0',
    },
  },
  inputRoot: {
    WebkitBoxShadow: '0 0 0 1000px #fff inset',
  },
}))

const FCTTextField = (
  props: TextFieldProps & {
    errortext?: string
    icon?: React.ReactElement
    iconStart?: React.ReactElement
    readOnly?: boolean
    typesize?: 'small' | 'large'
    errortooltip?: string
    maxLength?: number
    tooltipProps?: Omit<FCTTooltipMoveProps, 'children' | 'text' | 'isMove'>
  },
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined
) => {
  const isSmall = props.typesize === 'small'
  const classes = useStyles(isSmall)
  return (
    <FCTTooltipMove
      {...props.tooltipProps}
      text={props.errortooltip}
      isMove={false}
      type="error"
      disable={!props.error || !props.errortooltip}
    >
      <TextField
        data-testid="FCTTextField"
        ref={ref}
        classes={{ root: classes.root }}
        type="text"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        helperText={props.error ? props.errortext : ''}
        InputProps={{
          readOnly: props.readOnly,
          endAdornment: (
            <>
              {props.icon ? <InputAdornment position="end">{props.icon}</InputAdornment> : null}
              {props.iconStart ? (
                <InputAdornment position="start">{props.iconStart}</InputAdornment>
              ) : null}
            </>
          ),
          classes: { input: classes.inputRoot },
        }}
        {...props}
      />
      {!!props.maxLength && (
        <Typography
          style={{
            color: theme.palette.grey[500],
            fontSize: '10px',
            lineHeight: '14px',
            width: '100%',
            textAlign: 'right',
          }}
        >
          {`${String(props?.value)?.length} / ${props.maxLength}`}
        </Typography>
      )}
    </FCTTooltipMove>
  )
}

export default React.forwardRef(FCTTextField)

import { useOktaAuth } from '@okta/okta-react'
import { AuthenticationTemplate } from '../templates/AuthenticationTemplate/AuthenticationTemplate'
import { ConfirmMail } from '../components/Authentication/ConfirmMail'
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react'
import Loading from '../components/Loading'
import {
  BusinessEmail,
  IRegisterFields,
  NEW_COMPANY_ID,
} from '../components/Authentication/BusinessEmail'
import axios from 'axios'
import config from '../core/config/auth'
import { useCustomParams, decodeObjectToURLParams } from '../routers/QueryParams'
import { Box, Link, makeStyles, Theme, Typography, useTheme, withStyles } from '@material-ui/core'
import FCTCheckbox from '../components/Checkbox/FCTCheckbox'
import Flex from '../components/Flex/Flex'
import FCTTooltip from '../components/Tooltip/Tooltip'
import PrivacyDialog from '../components/Policy/PrivacyDialog'
import { LOCAL_STORAGE_FIELDS } from '../utils/consts'
import { EErrorCode } from '../components/Authentication/AuthError'
import SignInSignUpContainer from '../containers/SignInSignUpContainer'
import { EDocumentTemplate } from 'components/Policy/types'

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  text12: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  checkbox: {
    '&:checked': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
}))

export enum ESignUpComponent {
  SignUp,
  BusinessEmail,
  ConfirmMail,
}

export enum ESignUpAs {
  FINTECH = 'fintech',
}

const Tooltip = (props: PropsWithChildren<{ tooltip?: string | ReactElement }>) => {
  const classes = useStyles()
  return props.tooltip ? (
    <FCTTooltip
      title={<Typography className={classes.text12}>{props.tooltip}</Typography>}
      placement="top"
      arrow
      interactive
    >
      <div>{props.children}</div>
    </FCTTooltip>
  ) : (
    <>{props.children}</>
  )
}
/*
 * Legacy
 * Disabled due to (FB-1027)[https://bcgplatinionwesa.atlassian.net/browse/FB-1027]
 */
export const RegistrationPage = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [email, setEmail] = useState('')
  const [signUpTab, setSignUpTab] = useState(ESignUpComponent.SignUp)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSecondEmail, setLoadingSecondEmail] = useState<boolean>(false)
  const [hasError, setError] = useState<boolean>(false)
  const [textError, setTextError] = useState<string>()
  const [policyList, setPolicyList] = useState<EPolicies[]>([])
  const accessToken = oktaAuth.getAccessToken()
  const callBackOriginUrl = localStorage.getItem('CALLBACK_ORIGIN_URL')
  const [params] = useCustomParams() as [string, (searchParams: string) => void]
  const search = decodeObjectToURLParams(params, result => result)
  // Disable Clients Register via Linkedin
  const isFintech = search.as === ESignUpAs.FINTECH || true

  const getSecondEmail = async (token: string) => {
    try {
      const response = await axios.get(`${config.api}/users/me/is-active`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      })

      if (response?.data?.data?.secondEmailUpdated) {
        if (callBackOriginUrl == '/auth/callback/register') {
          localStorage.setItem(
            LOCAL_STORAGE_FIELDS.SIGN_IN_UP_ERROR,
            `${EErrorCode.EMAIL_ALREADY_USED}`
          )
          oktaAuth.signOut({
            postLogoutRedirectUri: window.location.origin + `/register?error=true`,
          })
        }
      } else {
        const localMktConsent = localStorage.getItem(LOCAL_STORAGE_FIELDS.MARKETING_CONSENT)
        if (localMktConsent === '1') {
          setPolicyList(prev => [...prev, EPolicies.MARKETING_CONSENT])
        }
        setSignUpTab(ESignUpComponent.BusinessEmail)
      }
    } catch (err: any) {
      setError(true)
      console.error(err)
      setTextError(err?.response?.data?.data?.err || '')
    } finally {
      setLoading(false)
      setLoadingSecondEmail(false)
    }
  }

  useEffect(() => {
    if (!!authState) {
      if (authState?.isAuthenticated && accessToken && callBackOriginUrl) {
        setLoadingSecondEmail(true)
        getSecondEmail(accessToken as string)
      } else {
        localStorage.removeItem(LOCAL_STORAGE_FIELDS.MARKETING_CONSENT)
      }
    }
  }, [authState?.isAuthenticated, authState])

  const resetState = () => {
    setError(false)
    setTextError('')
    setLoading(true)
  }

  const onSubmit = async (data: IRegisterFields) => {
    resetState()
    try {
      await axios.post(
        `${config.api}/users/me/email`,
        {
          email: data.email,
          marketingConsent: policyList.includes(EPolicies.MARKETING_CONSENT),
          company: !data.company
            ? undefined
            : {
                id: data.company?.id === NEW_COMPANY_ID ? null : data.company?.id,
                name: data.company?.name,
                websiteUrl: data.company?.websiteUrl,
                productDesc: data.companyDetails?.productDesc,
                description: data.companyDetails?.description,
                category: data.companyDetails?.category,
              },
        },
        {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        }
      )
      setEmail(data.email)
      setSignUpTab(ESignUpComponent.ConfirmMail)
      localStorage.removeItem(LOCAL_STORAGE_FIELDS.MARKETING_CONSENT)
    } catch (error: any) {
      setError(true)
      console.error(error)
      setTextError(error?.response?.data?.data?.err)
    } finally {
      setLoading(false)
    }
  }

  const handleResend = async () => {
    resetState()
    try {
      await axios.post(`${config.api}/users/resend-verify-second-email`, {
        email: email,
      })
    } catch (error: any) {
      setError(true)
      console.error(error)
      setTextError(error.response.data.data.err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthenticationTemplate>
      {!authState ||
      loadingSecondEmail ||
      (authState?.isAuthenticated && !oktaAuth.getAccessToken()) ? (
        <Loading style={{ marginTop: 30 }} />
      ) : (
        <>
          {(() => {
            switch (signUpTab) {
              case ESignUpComponent.SignUp:
                return (
                  <SignInSignUpContainer
                    type="signUp"
                    disabledBtn={
                      !policyList.includes(EPolicies.PRIVACY_POLICY) ||
                      !policyList.includes(EPolicies.TERMS_OF_USE)
                    }
                    PoliciesComponent={
                      <Policies checkedList={policyList} setCheckedList={setPolicyList} />
                    }
                    Tooltip={({ children }) => (
                      <Tooltip tooltip="To sign up, please read and agree">{children}</Tooltip>
                    )}
                  />
                )
              case ESignUpComponent.ConfirmMail:
                return (
                  <ConfirmMail
                    hasError={hasError}
                    email={email}
                    loadingResend={loading}
                    handleResend={handleResend}
                    textError={textError}
                  />
                )
              case ESignUpComponent.BusinessEmail:
                return (
                  <BusinessEmail
                    hasError={hasError}
                    onSubmit={onSubmit}
                    loadingSubmit={loading}
                    name={authState?.idToken?.claims.name}
                    textError={textError}
                    signUpAsFintech={isFintech}
                  />
                )
              default:
                return null
            }
          })()}
        </>
      )}
    </AuthenticationTemplate>
  )
}

enum EPolicies {
  PRIVACY_POLICY,
  TERMS_OF_USE,
  MARKETING_CONSENT,
}

const Policies = ({
  checkedList,
  setCheckedList,
}: {
  checkedList: EPolicies[]
  setCheckedList: React.Dispatch<React.SetStateAction<EPolicies[]>>
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)

  const policies: IPolicyItem[] = [
    {
      text: 'Privacy Policy',
      value: EPolicies.PRIVACY_POLICY,
      disabled: true,
      onClickText: () => {
        setShowPrivacyPolicy(true)
      },
      tooltip: 'Click on Privacy Policy to review and accept',
    },
    {
      text: 'Acceptable Use and Content Policy',
      value: EPolicies.TERMS_OF_USE,
      onClickText: () => {
        window.open(`${window.location.origin}/terms`, '_blank')
      },
    },
    {
      text: `I agree to receive updates on the release of new features and improvements to the platform from FCT`,
      value: EPolicies.MARKETING_CONSENT,
    },
  ]

  return (
    <>
      <Box textAlign="left" mt="24px">
        <Typography className={classes.text}>I have read and agree to:</Typography>
        <Box marginX="-9px" mt="12px">
          {policies.map(item => {
            const checked = checkedList.includes(item.value)
            return (
              <PolicyItem
                {...item}
                disabled={!checked && item.disabled}
                key={item.text}
                checked={checked}
                onToggle={() => {
                  if (item.value === EPolicies.PRIVACY_POLICY) return
                  if (item.value === EPolicies.MARKETING_CONSENT) {
                    localStorage.setItem(
                      LOCAL_STORAGE_FIELDS.MARKETING_CONSENT,
                      checked ? '0' : '1'
                    )
                  }
                  setCheckedList(prev =>
                    checked ? prev.filter(i => item.value !== i) : [...prev, item.value]
                  )
                }}
              />
            )
          })}
        </Box>
      </Box>
      <PrivacyDialog
        template={EDocumentTemplate.STARTUP}
        open={showPrivacyPolicy}
        setOpen={setShowPrivacyPolicy}
        handleAgree={() => {
          setCheckedList(prev =>
            prev.includes(EPolicies.PRIVACY_POLICY) ? prev : [...prev, EPolicies.PRIVACY_POLICY]
          )
          setShowPrivacyPolicy(false)
        }}
        titleBtnCancel={
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 14,
              lineHeight: '22px',
              color: theme.palette.grey[500],
            }}
          >
            Back
          </Typography>
        }
      />
    </>
  )
}

interface IPolicyItem {
  text: string
  value: EPolicies
  disabled?: boolean
  onClickText?(): void
  tooltip?: string
}

const PolicyItem = ({
  checked,
  onToggle,
  ...props
}: IPolicyItem & {
  checked?: boolean
  onToggle(): void
}) => {
  const classes = useStyles()
  const Comp = props.onClickText
    ? withStyles((theme: Theme) => ({
        root: { cursor: 'pointer', color: theme.palette.primary.light },
      }))(Link)
    : withStyles((theme: Theme) => ({
        root: { color: theme.palette.grey[800] },
      }))(Typography)

  return (
    <Flex alignItems="flex-start">
      <Tooltip tooltip={props.tooltip}>
        <FCTCheckbox
          checked={checked}
          disabled={props.disabled}
          onClick={onToggle}
          className={classes.checkbox}
        />
      </Tooltip>

      <Comp className={classes.text} style={{ marginTop: '6px' }} onClick={props.onClickText}>
        {props.text}
      </Comp>
    </Flex>
  )
}

import { Typography, withStyles } from '@material-ui/core'
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'
import React, { HTMLAttributes, RefObject } from 'react'
import { v4 as uuidv4 } from 'uuid'
import theme from '../../theme'
import { defaultProps } from '../../utils/DefaultProps'
import { ChipItemFilter } from '../ExploreDBComponent/share/ChipItemFilter'
import FCTIcon from '../Icon/Icon'
import FCTTextField from '../TextField/FCTTextField'

const StyledAutoSuggest = withStyles(() => ({
  root: {
    width: '100%',
    fontSize: 12,
    lineHeight: '18px',
  },
  inputRoot: {
    padding: '6px 30px 6px 14px !important',
  },
  input: {
    lineHeight: '28px',
    padding: '0 !important',
    height: 'auto',
  },
  tag: {
    height: 28,
    margin: 2,
    borderRadius: 3,
    background: '#F1F5F9',
  },
  option: {
    fontSize: 14,
  },
}))((props: any) => <Autocomplete {...props} />)
type IChangeReason = 'create-option' | 'select-option' | 'remove-option' | 'blur' | 'clear'
type IInputChangeReason = 'input' | 'reset' | 'clear'
function _FCTAutoSuggest<IOption extends IAutoSuggestOption>(props: FCTAutoSuggestProps<IOption>) {
  return (
    <StyledAutoSuggest
      clearOnBlur
      filterOptions={(options: IOption[], state: object) => options}
      multiple
      freeSolo={props.freeSolo}
      closeIcon={null}
      disabled={props.disabled}
      loading={props.loading}
      value={props.getValue}
      options={props.options}
      getOptionSelected={(option: IOption, value: IOption) =>
        option[props.keyField as keyof IOption] === value[props.keyField as keyof IOption]
      }
      getOptionLabel={(option: IOption) => option.name}
      popupIcon={!props.hiddenSelectedOption && props.popupIcon}
      renderTags={(value: any[], getTagProps: AutocompleteGetTagProps) =>
        (props as any).renderTags(props, value, getTagProps)
      }
      renderInput={(params: AutocompleteRenderInputParams) =>
        (props as any).renderInput(props, params)
      }
      onChange={(event: object, value: IOption[], reason: IChangeReason) => {
        let currentValue = props.getValue
        switch (reason) {
          case 'select-option': {
            currentValue = value
            props.setValue(currentValue)
            break
          }
          case 'remove-option': {
            currentValue = value
            props.setValue(currentValue)
            break
          }
          case 'create-option': {
            currentValue = [
              ...(value as any).map((v: string) =>
                typeof v === 'string' ? (props as any).generateOption(v) : v
              ),
            ]
            props.setValue(currentValue)
            break
          }
        }
        props.onValueChange && props.onValueChange(currentValue, reason)
      }}
      renderOption={(option: IOption, state: object) =>
        (props as any).renderOption(props, option, state)
      }
      onInputChange={(event: object, value: string, reason: IInputChangeReason) =>
        (props as any).onInputChange(props, event, value, reason)
      }
      ListboxComponent={props.ListboxComponent}
      ListboxProps={props.ListboxProps}
      noOptionsText={props.noOptionsText}
    />
  )
}

const withDefaultProps = defaultProps<Partial<FCTAutoSuggestProps<IAutoSuggestOption>>>({
  keyField: 'id',
  loading: false,
  renderInput: (
    props: FCTAutoSuggestProps<IAutoSuggestOption>,
    params: AutocompleteRenderInputParams
  ) => {
    return (
      <FCTTextField
        {...params}
        value={props.getInput}
        style={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
        label={props.label}
        onClick={props.onClick}
        placeholder={!props.getValue?.length ? props.placeHolder : ''}
        onChange={e => props.setInput(e.target.value)}
        onKeyDown={props.onKeyDown}
        onBlur={props.handleOnBlur}
        onFocus={props.handleOnFocus}
        InputProps={{
          ...params?.InputProps,
          endAdornment: (
            <>
              {params?.InputProps?.endAdornment}
              {props.endAdornment}
            </>
          ),
        }}
      />
    )
  },
  popupIcon: <FCTIcon name="arrowdown" width={10} height={10} color={theme.palette.grey[400]} />,
  renderTags: (
    props: FCTAutoSuggestProps<IAutoSuggestOption>,
    value: IAutoSuggestOption[],
    getTagProps: any
  ) => {
    return value.map((option, index) => (
      <ChipItemFilter
        key={option.name}
        label={option.name}
        size="small"
        {...getTagProps({ index })}
      />
    ))
  },
  generateOption: (name: string) => ({
    id: uuidv4(),
    name,
  }),
  options: [],
  onInputChange: (
    props: FCTAutoSuggestProps<IAutoSuggestOption>,
    event: object,
    value: string,
    reason: IInputChangeReason
  ) => {},
  renderOption: (
    props: FCTAutoSuggestProps<IAutoSuggestOption>,
    option: IAutoSuggestOption,
    state: object
  ) => <Typography noWrap>{option.name}</Typography>,
  onKeyDown: (event: any) => {},
  onValueChange: (value: any[], reason: string) => {},
  onOpen: () => {},
  onClose: () => {},
})

export const FCTAutoSuggest = withDefaultProps(_FCTAutoSuggest as any)

export interface FCTAutoSuggestProps<IOption extends IAutoSuggestOption>
  extends HTMLAttributes<HTMLElement> {
  disabled?: boolean
  placeHolder?: string
  options?: IOption[] | null
  label?: string
  getValue: IOption[]
  setValue: (searchData: IOption[]) => void
  getInput: string
  setInput: React.Dispatch<React.SetStateAction<string>>
  loading?: boolean
  keyField?: keyof IOption
  renderTags?: (
    props: FCTAutoSuggestProps<IOption>,
    value: IOption[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode
  renderInput?: (
    props: FCTAutoSuggestProps<IOption>,
    params: AutocompleteRenderInputParams
  ) => React.ReactNode
  popupIcon?: React.ReactNode
  generateOption?: (name: string) => IOption
  onInputChange?: (
    props: FCTAutoSuggestProps<IOption>,
    event: object,
    value: string,
    reason: IInputChangeReason
  ) => void
  renderOption?: (
    props: FCTAutoSuggestProps<IOption>,
    option: IOption,
    state: object
  ) => React.ReactNode
  onKeyDown?: (event: any) => void
  clearOnBlur?: boolean
  disableClearable?: boolean
  onValueChange?: (value: IOption[], reason: string) => void
  ListboxComponent?: React.ReactNode
  ListboxProps?: ListboxProps
  noOptionsText?: React.ReactNode
  hiddenSelectedOption?: boolean
  handleOnBlur?: () => void
  handleOnFocus?: () => void
  inputProps?: object
  freeSolo?: boolean
  endAdornment?: React.ReactNode
  onOpen?: () => void
  onClose?: () => void
  open?: boolean
}

export interface IAutoSuggestOption {
  name: string
  id: string
}

export interface ListboxProps {
  open: boolean
  setOpen: (value: boolean) => void
  enableLimitTags?: boolean
  restrictedRequestCompany?: boolean
}

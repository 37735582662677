import { gql } from '@apollo/client'

export const GET_LIST_NOTIFICATIONS = gql`
  query GetListNotifications($input: NotificationInput!) {
    getListNotifications(input: $input) {
      id
      userId
      sourceId
      sourceType
      content
      valueContent
      groupId
      type
      createdBy
      createdDate
      isRead
      requestStatus
      avatarType
      watchlistId
      requestTypeId
      companyLogo
      company_id
      isMine
      url
    }
  }
`

export enum ENotiTypes {
  Profile = 0,
  Requests = 1,
  CompanyUpdates = 2,
  WatchlistUpdates = 3,
}

export const GET_UNREAD_NOTICATIONS_COUNT = gql`
  query GetNotificationsCount($watchlistId: Int, $unRead: Int = 1) {
    requestCount: getTotalNotifications(input: { groupId: ${ENotiTypes.Requests}, unRead: $unRead, watchlistId: $watchlistId}) {
      totalNotifications
    }
    companyUpdateCount: getTotalNotifications(input: { groupId: ${ENotiTypes.CompanyUpdates}, unRead: $unRead, watchlistId: $watchlistId}) {
      totalNotifications
    }
    watchListUpdateCount: getTotalNotifications(input: { groupId: ${ENotiTypes.WatchlistUpdates}, unRead: $unRead, watchlistId: $watchlistId}) {
      totalNotifications
    }
  }
`

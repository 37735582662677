import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { SecureRoute, Security } from '@okta/okta-react'
import AdminPage from 'pages/AdminPage'
import { FaqsPage } from 'pages/FaqsPage'
import { GetAccessPage } from 'pages/GetAccessPage'
import { PrivacyPage } from 'pages/PrivacyPage'
import { ResearchAndAdvisoryPage } from 'pages/ResearchAndAdvisoryPage'
import { TermsOfUsePage } from 'pages/TermsOfUsePage'
import { Redirect, useHistory } from 'react-router'
import { Route } from 'react-router-dom'
import config from '../core/config/auth'
import { REDIRECT_URL } from '../core/constants/Router'
import { LoginPage } from '../pages/LoginPage'
import RedirectPage from '../pages/RedirectPage'
import SearchPage from '../pages/SearchPage'
import AppRouter from './AppRouter'
import { CallBackComponent } from './CallBackComponent'
import CredentialsRouter from './CredentialsRouter'
import InitUser, { UserContext } from './InitUserWrapper'
import KnowledgeHubRouter from './KnowledgeHubRouter'

const pathURL = [
  '/app',
  '/knowledge-hub',
  '/redirect',
  '/credentials',
  '/get-access',
  '/auth/callback/',
  '/privacy',
  '/terms',
  '/research-and-advisory',
  '/faqs',
  '/admin',
]

export const AuthRouter = () => {
  const history = useHistory()
  const onAuthRequired = () => {
    localStorage.setItem(REDIRECT_URL, window.location.pathname + window.location.search)
    history.push('/login')
  }

  const oktaAuth = new OktaAuth({
    issuer: config.issuer,
    clientId: config.clientId,
    redirectUri: config.redirectUri,
    pkce: true,
    tokenManager: {
      autoRenew: true,
    },
  })

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin))
      }}
    >
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <InitUser>
        <UserContext.Consumer>
          {({ user, restricted }) =>
            user &&
            (pathURL.some(e => window.location.pathname.includes(e)) ? (
              <>
                <Route exact path="/">
                  <Redirect to="/app" />
                </Route>
                <SecureRoute path="/app" component={AppRouter} />
                {!restricted.search && <SecureRoute path="/search" component={SearchPage} />}
                <SecureRoute path="/knowledge-hub" component={KnowledgeHubRouter} />
                <SecureRoute path="/redirect" component={RedirectPage} />
                <SecureRoute path="/privacy" component={PrivacyPage} />
                <SecureRoute path="/terms" component={TermsOfUsePage} />
                <SecureRoute path="/research-and-advisory" component={ResearchAndAdvisoryPage} />
                <SecureRoute path="/faqs" component={FaqsPage} />
                {<SecureRoute path="/admin" component={AdminPage} />}
              </>
            ) : (
              <>
                <Route render={() => <Redirect to={{ pathname: '/app' }} />} />
                <SecureRoute path="/app" component={AppRouter} />
              </>
            ))
          }
        </UserContext.Consumer>
      </InitUser>
      <Route path="/login" render={() => <LoginPage />} />
      <Route path="/credentials" render={() => <CredentialsRouter />} />
      <Route path="/get-access" render={() => <GetAccessPage />} />
      <Route path="/auth/callback/:type" component={CallBackComponent} />
    </Security>
  )
}

import { isEqual, omit } from 'lodash'
import { useEffect } from 'react'
import { useSubFilter } from 'store/features/company-deal/CompanyDealHook'
import { InternalCompanyName } from 'types/InternalCompanyName'
import mixpanel from '../mixpanel'
import { IAnalysisFilter, LABLE_SWITCH_FEMALE } from '../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../__generated__/GetDealFilterDataResult'
import {
  ChipItem,
  IBreadcrumbsData,
  initialAnalysisFilter,
} from './../store/features/analysis/AnalysisSlice'
import { ECompareType, ICompanyDealFilter, ISubFilter } from './../types/CompanyDealFilter'
import { ETimePeriod, LocationType, PeriodType } from './convert/Title'
import { tagGroupsMapDataFilter } from './Filter'

export function convertIdsToNames(ids: number[], obj: IntIdName[]) {
  return obj.filter(n => ids.includes(n.id)).map(i => i.name)
}

const convertIdToName = (id: number | null, obj: IntIdName[] | null) => {
  return id && obj ? obj.find(e => e.id === id)?.name : id
}

export function trackingTaxonomy(
  filterOrgin: IAnalysisFilter | ICompanyDealFilter,
  filterOptionsData: GetDealFilterDataResult_getDealFilterData,
  breadcrumbs?: IBreadcrumbsData[]
) {
  const lastBreadcrumb = breadcrumbs?.[breadcrumbs?.length - 1]
  const breadcrumbEndpoint = !!lastBreadcrumb
    ? {
        breadcrumbEndpoint: !!lastBreadcrumb.name
          ? lastBreadcrumb.name
          : InternalCompanyName.get(filterOrgin.category),
        breadcrumbEndpoint_ID: !!lastBreadcrumb.id ? lastBreadcrumb.id : null,
      }
    : {}

  return {
    taxonomyProduct: convertIdsToNames(
      filterOrgin.products || [],
      filterOptionsData?.products || []
    ),
    taxonomyProductID: filterOrgin.products,
    taxonomyBusiness: convertIdsToNames(
      filterOrgin.businessLines || [],
      filterOptionsData?.businessLines || []
    ),
    taxonomyBusinessID: filterOrgin.businessLines,
    taxonomyValueChain: convertIdsToNames(
      filterOrgin.valueChains || [],
      filterOptionsData?.valueChains || []
    ),
    taxonomyValueChainID: filterOrgin.valueChains,
    taxonomyRisks: convertIdsToNames(filterOrgin.risks || [], filterOptionsData?.risks || []),
    taxonomyRisksID: filterOrgin.risks,
    taxonomyTags: convertIdsToNames(
      filterOrgin.tagGroupIds || [],
      tagGroupsMapDataFilter(filterOptionsData?.tags || []) || []
    ),
    taxonomyTagsID: filterOrgin.tagGroupIds,
    ...breadcrumbEndpoint,
  }
}

export const trackingFilter = (
  filterOrgin: IAnalysisFilter | ICompanyDealFilter,
  filterOptionsData: GetDealFilterDataResult_getDealFilterData,
  chips: ChipItem[],
  chartName?: string,
  subFilter?: ISubFilter
) => {
  const filter: any = { ...filterOrgin }
  delete filter.businessLines
  delete filter.products
  delete filter.valueChains
  delete filter.risks
  delete filter.risks
  delete filter.tagGroupIds

  filter.investorIDs = filterOrgin.investors
  filter.leadInvestorIDs = filterOrgin.leadInvestors
  filter.acquirerIDs = filterOrgin.acquirers

  filter.investors = chips.filter(e => e.key == 'investors').map(e => e.label)
  filter.leadInvestors = chips.filter(e => e.key == 'leadInvestors').map(e => e.label)
  filter.acquirers = chips.filter(e => e.key == 'acquirers').map(e => e.label)

  filter.switchFemalePicker = LABLE_SWITCH_FEMALE.get(filterOrgin.switchFemalePicker)
  filter.businessModelIds = convertIdsToNames(
    filterOrgin.businessModelIds || [],
    filterOptionsData.businessModels || []
  )
  filter.companyStatuses = convertIdsToNames(
    filterOrgin.companyStatuses || [],
    filterOptionsData.companyStatuses || []
  )
  filter.countries = convertIdsToNames(
    filterOrgin.countries || [],
    filterOptionsData.countries || []
  )
  filter.filterCondition = subFilter?.currentCompanyName
    ? `Compare: ${subFilter?.currentCompanyName}, ${
        subFilter.compareType === ECompareType.ProfileSimilar ? 'Self Suggested' : 'FCT view'
      }`
    : ''
  if (chartName) filter['chartName'] = chartName
  return { ...filter, ...trackingTaxonomy(filterOrgin, filterOptionsData) }
}

export function useTrackingFilterChart(obj: ITrackingValue) {
  const [subFilter, _] = useSubFilter()
  const typeName = obj.typeName || 'Analytics_Filter'
  !obj.disable &&
    useEffect(() => {
      const isDefault = isEqual(
        omit(initialAnalysisFilter, [
          'searchData',
          'orderByCompany',
          'orderByDeal',
          'numberOfInvestorsRanges',
          'numberOfFundingRounds',
          'selectedBusinessLineId',
          'selectedProductId',
          'category',
          'viewBy',
          'includedEntities',
        ]),
        omit(obj.filter, [
          'searchData',
          'orderByCompany',
          'orderByDeal',
          'numberOfInvestorsRanges',
          'numberOfFundingRounds',
          'selectedBusinessLineId',
          'selectedProductId',
          'category',
          'viewBy',
          'includedEntities',
        ])
      )

      const checkInitData =
        !obj.filter.numberOfFundingRounds.end || !obj.filter.numberOfInvestorsRanges.end

      if (!isDefault && !checkInitData) {
        mixpanel.track(
          typeName,
          trackingFilter(
            { ...obj.filter },
            { ...obj.filterData },
            obj.chips,
            obj.chartName || '',
            subFilter
          )
        )
      }
    }, [obj.chips])
}

export function useTrackingTypeViewChart(obj: ITrackingTypeViewChart) {
  useEffect(() => {
    let filterNew = {} as any
    if (!!obj.periodType) filterNew['periodType'] = obj.periodType
    if (!!obj.timePeriod) filterNew['timePeriod'] = obj.timePeriod
    if (!!obj.locationType) filterNew['locationType'] = obj.locationType
    mixpanel.track('Charts Type View', filterNew)
  }, [obj.periodType])
}

export const pathnameTracking = (pathname: string) => {
  if (pathname.includes('analysis/sunburst')) return 'Atlas'
  if (pathname.includes('analysis/charts')) return 'Analytics'
  if (pathname.includes('/list-view')) return 'List'
  if (pathname.includes('analysis/landscape')) return 'Landscape'
  if (pathname.includes('/search')) return 'Search'
  if (pathname.includes('/profile')) return 'Profile'
  if (pathname.includes('/dashboard')) return 'Dashboard'
}

interface IntIdName {
  id: number
  name: string
}

interface ITrackingValue {
  filter: IAnalysisFilter | ICompanyDealFilter
  filterData: GetDealFilterDataResult_getDealFilterData
  chips: ChipItem[]
  chartName?: string
  typeName?: string
  disable?: boolean
}

interface ITrackingTypeViewChart {
  periodType?: PeriodType
  timePeriod?: ETimePeriod
  locationType?: LocationType
}

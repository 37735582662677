import mixpanel, { Dict, OutTrackingOptions } from 'mixpanel-browser'
import { COOKIE_SCRIPT_CATEGORIES } from '../types/Cookie'
import { getCookie } from '../utils/Cookie'

const key = process.env.REACT_APP_MIXPANEL_KEY
const env_check = !!key
const argumentOptOut = {
  delete_user: false,
  clear_persistence: false,
} as Partial<OutTrackingOptions>

if (key) {
  mixpanel.init(key)
  // TODO: test
  // mixpanel.opt_in_tracking()
  mixpanel.set_config({
    secure_cookie: true,
    debug: process.env.REACT_APP_ENV !== 'prod' ? true : false,
  })
}

function timer() {
  setInterval(() => {
    const cookieScript = JSON.parse(getCookie('CookieScriptConsent') || '{}')

    if (cookieScript?.action === 'reject') {
      !mixpanel.has_opted_out_tracking() && mixpanel.opt_out_tracking(argumentOptOut)
      return
    }

    const categories = JSON.parse(cookieScript?.categories || '[]')

    if (env_check && cookieScript?.action === 'accept') {
      if (!categories?.includes(COOKIE_SCRIPT_CATEGORIES.PERFORMANCE)) {
        !mixpanel.has_opted_out_tracking() && mixpanel.opt_out_tracking(argumentOptOut)
      } else {
        mixpanel.has_opted_out_tracking() && mixpanel.opt_in_tracking()
      }
    }
  }, 5000)
}

timer()

const actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id)
  },

  //A unique identifier that you want to use as an identifier for this user.
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id)
  },
  track: (name: string, props?: Dict) => {
    if (env_check) mixpanel.track(name, props, () => {})
  },
  set_group: (group_key: string, group_ids: string | number | string[] | number[]) => {
    if (env_check) mixpanel.set_group(group_key, group_ids)
  },
  people: {
    set: (props: Dict) => {
      if (env_check) mixpanel.people.set(props)
    },
  },
}

export default actions

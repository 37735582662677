import Loading from '../Loading'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '3px',
    height: '50px',
    fontSize: '14px',
    border: 'none',
    outline: 'none',
    width: '100%',
    cursor: 'pointer',
    marginTop: '30px',
    '&:disabled': {
      opacity: 0.8,
    },
  },
  primaryBtn: {
    backgroundColor: `${theme.palette.primary.light}`,
    color: '#FFF',
  },
  textBtn: {
    backgroundColor: `transparent`,
    color: `${theme.palette.grey[500]}`,
  },

  btnDisabled: {
    backgroundColor: `${theme.palette.grey[200]}`,
    color: `${theme.palette.grey[400]}`,
    cursor: 'unset',
  },
}))

const AuthButton = ({
  disabled = false,
  text = 'Continue',
  loading = false,
  variant = 'default',
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  disabled?: boolean
  text?: string | React.ReactNode
  loading?: boolean
  variant?: 'contained' | 'default'
}) => {
  const classes = useStyles()
  return (
    <button
      disabled={disabled || loading}
      className={clsx({
        [classes.button]: true,
        [classes.primaryBtn]: variant === 'contained',
        [classes.textBtn]: variant === 'default',
        [classes.btnDisabled]: disabled,
      })}
      {...props}
    >
      {loading ? <Loading size={30} color="inherit" /> : text}
    </button>
  )
}

export default AuthButton

import { CheckboxTree, ITreeNode } from 'components/CheckboxTree/CheckboxTree'
import { EXPAND_ROUND1_ID } from 'core/constants/companyFilter'
import React, { useEffect, useState } from 'react'
import { getDealType, setDealTypeFilter } from 'utils/DealType'
import { IOpenOptionFilter } from '../../store/features/filter/FilterSlice'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import { FCTDateRangePicker } from '../DateRangePicker/FCTDateRange'
import FCTDropdownListItem from '../DropdownListItem/FCTDropdownListItem'
import { AccordionItem } from '../Filters/AccordionItem'
import { initIsDealType } from './share/AllDealItem'

const IPOItem = (props: IIPOItemProps) => {
  const {
    filter,
    setFilter,
    handleChange,
    getFilterData,
    openOption,
    setOpenOption,
    customStyle,
  } = props

  return (
    <AccordionItem
      title="IPO"
      open={openOption?.ipo}
      setOpen={value => setOpenOption && setOpenOption(value, 'ipo')}
      customStyle={customStyle}
    >
      <IPOChildItem filter={filter} setFilter={setFilter} getFilterData={getFilterData} />
    </AccordionItem>
  )
}

export default IPOItem

interface IIPOItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  handleChange(e: React.SyntheticEvent): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  openOption?: IOpenOptionFilter
  setOpenOption?: (value: boolean, tag: string) => void
  customStyle?: { title?: React.CSSProperties }
}

interface IIPOChildItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
}

export const IPOChildItem = (props: IIPOChildItemProps) => {
  const { filter, setFilter, getFilterData } = props

  const [isDealType, setIsDealType] = useState<{
    dealType: ITreeNode[] | null
    allDealType: ITreeNode[] | null
  }>(initIsDealType)

  const fundingRound1s = getFilterData?.fundingRound1s || []
  const fundingRound2s = getFilterData?.fundingRound2s || []

  const setAllDealType = (value: (number | string)[]) => {
    setFilter({
      latestFundingRound1s: [],
      latestFundingRound2s: [],
      ...setDealTypeFilter(
        value,
        fundingRound1s,
        fundingRound2s,
        'fundingRound1s',
        'fundingRound2s'
      ),
    })
  }

  useEffect(() => {
    if (
      filter.fundingRound1s?.length ||
      filter.fundingRound2s?.length ||
      filter.latestFundingRound1s?.length ||
      filter.latestFundingRound2s?.length
    ) {
      if (filter.fundingRound1s?.length || filter.fundingRound2s?.length)
        setIsDealType({
          ...isDealType,
          dealType: null,
        })
      else
        setIsDealType({
          ...isDealType,
          allDealType: null,
        })
    } else setIsDealType(initIsDealType)
  }, [
    filter.fundingRound1s,
    filter.fundingRound2s,
    filter.latestFundingRound1s,
    filter.latestFundingRound2s,
  ])

  const allDealType = getDealType(
    filter.fundingRound1s,
    filter.fundingRound2s,
    fundingRound1s,
    fundingRound2s
  )

  return (
    <>
      <FCTDropdownListItem isOpen={true} title="IPO" size="small" openedIcon="down" closedIcon="up">
        <CheckboxTree
          originalData={allDealType.dealTypeOriginal}
          selectedIds={allDealType.dealTypeIds}
          dataTree={allDealType.dealTypeTree
            .map(e => (e.id === EXPAND_ROUND1_ID.IPO ? { ...e, name: 'Has IPO' } : e))
            .filter(e => e.id === EXPAND_ROUND1_ID.IPO)}
          setFilter={setAllDealType}
          enableNodes={isDealType.allDealType}
        />
      </FCTDropdownListItem>

      <FCTDropdownListItem
        isOpen={true}
        title="IPO Date"
        size="small"
        openedIcon="down"
        closedIcon="up"
      >
        <FCTDateRangePicker
          filter={filter}
          setFilter={setFilter}
          label=""
          placeholder="Select date"
          tagDateFrom="wentPublicOnDateFrom"
          tagDateTo="wentPublicOnDateTo"
        ></FCTDateRangePicker>
      </FCTDropdownListItem>
    </>
  )
}

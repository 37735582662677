import { gql } from '@apollo/client'
import { IProfileFilter } from '../../../../types/ProfileFilter'

export const GET_PROFILE_FILTER = gql`
  query GetProfileFilter {
    profileFilter @client
  }
`
export interface PROFILE_FILTER {
  profileFilter: IProfileFilter
}

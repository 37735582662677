import { Avatar, makeStyles } from '@material-ui/core'
import React from 'react'
import theme from '../../theme'
import { formatAbbreviation } from '../../utils/convert/number'
import { renameLogo } from '../../utils/convert/Title'
import Loading from '../Loading'
import FCTTooltip from './Tooltip'

const useStyles = makeStyles(theme => ({
  header: {
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    borderBottom: '1px solid #E7ECF3',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600,
  },
  content: {
    minWidth: 250,
    color: `${theme.palette.grey['800']}`,
    fontFamily: `${theme.typography.fontFamily}`,
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  avatar: {
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    width: 32,
    height: 32,
    fontSize: 10,
    boxSizing: 'border-box',
    borderRadius: 2,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: '10px',
    '& *:nth-child(n + 2)': {
      fontWeight: 600,
    },
  },
}))

interface IProps {
  children: React.ReactElement
  logoUrl?: string
  name?: string
  foundedYear?: number | ''
  fundingAmount?: number
  country?: string
  status?: string
  description?: string
  fctScoreOverall?: number
  disable?: boolean
  loading?: boolean
}

export const CompanyTooltip = ({
  children,
  logoUrl,
  name,
  foundedYear,
  fundingAmount,
  country,
  status,
  description,
  fctScoreOverall,
  disable = false,
  loading = false,
}: IProps) => {
  const classes = useStyles()
  const renderTooltip = (loading: boolean) => (
    <>
      {loading ? (
        <Loading size={28} style={{ color: theme.palette.primary.light }} />
      ) : (
        <>
          <div className={classes.header}>
            <div className={classes.logoWrapper}>
              <Avatar
                alt={logoUrl || ''}
                variant="square"
                src={logoUrl || ''}
                className={classes.avatar}
                children={renameLogo(name || '')}
              />
            </div>
            <span>{name}</span>
          </div>
          <div className={classes.content}>
            <div className={classes.item}>
              <span>Founding year</span>
              <span>{foundedYear}</span>
            </div>
            <div className={classes.item}>
              <span>Equity funding</span>
              <span>$ {formatAbbreviation(fundingAmount || 0)}</span>
            </div>
            <div className={classes.item}>
              <span>FCT score</span>
              <span>{fctScoreOverall?.toFixed(0)}</span>
            </div>
            <div className={classes.item}>
              <span>Country</span>
              <span>{country}</span>
            </div>
            <div className={classes.item}>
              <span>Status</span>
              <span>{status}</span>
            </div>
            {!!description && (
              <div className={classes.item}>
                <span>
                  {description.length > 330 ? description?.slice(0, 330) + '...' : description}
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )

  return (
    <FCTTooltip
      disableHoverListener={disable}
      title={renderTooltip(loading)}
      type="white"
      arrow
      placement="top"
    >
      {children}
    </FCTTooltip>
  )
}

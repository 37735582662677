import { makeVar } from '@apollo/client'
import { ESearchTab } from '../../components/ExploreDBComponent/MockData'

export const currentTabVar = makeVar<ESearchTab>(ESearchTab.Company)
export const currentTabPolicies = {
  tab: {
    read() {
      return currentTabVar()
    },
  },
}

import { configureStore, getDefaultMiddleware, Reducer } from '@reduxjs/toolkit'
import { createInjectorsEnhancer } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'

import { createReducer, IReducers } from './reducers'

export function configureAppStore(staticReducers: IReducers = {}, _middleware: any[] = []) {
  const createReducerHandler = createReducer(staticReducers)

  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const { run: runSaga } = sagaMiddleware

  // Create the store with saga middleware
  const middleware = [sagaMiddleware, ..._middleware]

  const enhancers = [
    createInjectorsEnhancer({
      createReducer: createReducerHandler,
      runSaga,
    }),
  ]

  const store = configureStore({
    reducer: createReducerHandler(),
    middleware: [...getDefaultMiddleware(), ...middleware],
    devTools: true,
    enhancers,
  })
  return store
}

import { DatePicker, LocalizationProvider } from '@material-ui/pickers'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import { makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import FCTTextField from '../TextField/FCTTextField'
import theme from '../../theme'
import FCTIcon from '../Icon/Icon'
import FCTButton from '../Button/FCTButton'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPickersDay-root:focus.Mui-selected': {
      background: theme.palette.primary.light,
      willChange: 'auto',
    },
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  line: {
    width: '4px',
    height: '1px',
    margin: ' 0px 5px',
    backgroundColor: '#CBD5E1',
  },
  labelText: {
    fontWeight: 400,
    color: '#1E293B',
    fontSize: '12px',
    marginBottom: '16px',
  },
  clearBtn: {
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    color: '#FF9F19',
    marginBottom: 16,
    minWidth: 75,
    '&.MuiButton-root:hover': {
      background: theme.palette.primary.contrastText,
    },
    '& .MuiButton-startIcon': {
      marginRight: 3,
    },
  },
  iconStart: {
    position: 'absolute',
    left: 96,
    top: 12,
    padding: 8,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
    borderRadius: '50%',
  },
  iconEnd: {
    position: 'absolute',
    // right: 46,
    left: 96,
    top: 12,
    padding: 8,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
    borderRadius: '50%',
  },
  rootText: {
    '& .MuiOutlinedInput-input': {
      padding: '17px 0 17px 14px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 3,
      '& fieldset': {
        borderColor: '#E7ECF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7ECF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.light,
        borderWidth: 1,
      },
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.grey[800],
      fontSize: 12,
      transform: 'translate(14px, -6px) scale(1)',
      backgroundColor: '#fff',
      paddingRight: 5,
      '&.Mui-focused': {
        color: theme.palette.primary.light,
      },
    },
    '& .MuiInputBase-input': {
      '&::-webkit-input-placeholder': {
        color: theme.palette.grey[500],
        opacity: 1,
      },
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      color: theme.palette.grey[800],
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    '& .MuiIconButton-root': {
      marginRight: -18,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300],
      background: theme.palette.grey[100],
      opacity: 0.6,
    },
  },
  wrapperTextField: {
    position: 'relative',
  },
}))

export const FCTDateRangePicker = (props: FCTDateRangeProps) => {
  const classes = useStyles()
  const { filter, setFilter, tagDateFrom, tagDateTo, label, placeholder, disabled = false } = props
  const [value, setValue] = React.useState({
    valueFrom: !filter[tagDateFrom] ? filter[tagDateFrom] : new Date(filter[tagDateFrom]),
    valueTo: !filter[tagDateTo] ? filter[tagDateTo] : new Date(filter[tagDateTo]),
  })

  const checkValidate = (value: Date | null) => {
    if (String(value) === 'Invalid Date') return false
    const time = value?.getTime() || 0
    if (time < new Date('1/1/1700').getTime()) return false
    return true
  }

  const onChangeDate = (value: { valueFrom: Date | null; valueTo: Date | null }) => {
    if (value.valueFrom && value.valueTo && value.valueFrom > value.valueTo) return
    setValue(value)
    checkValidate(value.valueFrom) &&
      setFilter({ [tagDateFrom]: !!value.valueFrom ? value.valueFrom?.toISOString() : null })
    checkValidate(value.valueTo) &&
      setFilter({ [tagDateTo]: !!value.valueTo ? value.valueTo?.toISOString() : null })
  }

  useEffect(() => {
    setValue({
      valueFrom: filter[tagDateFrom] && new Date(filter[tagDateFrom]),
      valueTo: filter[tagDateTo] && new Date(filter[tagDateTo]),
    })
  }, [filter[tagDateFrom], filter[tagDateTo]])

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils as any}>
      <div className={classes.dFlex}>
        <Typography className={classes.labelText}>{label}</Typography>
        <FCTButton
          className={classes.clearBtn}
          startIcon={<FCTIcon name="refresh" width={10} height={10} />}
          variant="text"
          onClick={() =>
            onChangeDate({
              valueFrom: null,
              valueTo: null,
            })
          }
        >
          Clear date
        </FCTButton>
      </div>
      <div className={classes.dFlex}>
        <DatePicker
          className={classes.root}
          label="From date"
          value={value.valueFrom}
          maxDate={value.valueTo}
          onChange={newValue => onChangeDate({ ...value, valueFrom: newValue })}
          inputFormat="dd/MM/yyyy"
          showDaysOutsideCurrentMonth={true}
          openPickerIcon={
            <FCTIcon name="calendar" width={16} height={16} color={theme.palette.grey[500]} />
          }
          disabled={disabled}
          renderInput={props => (
            <div className={classes.wrapperTextField}>
              <FCTTextField
                {...props}
                classes={{ root: classes.rootText }}
                style={{ flex: 1 }}
                helperText=""
                placeholder={placeholder}
              />
              {!disabled && (
                <FCTIcon
                  className={classes.iconStart}
                  name="close"
                  width={10}
                  height={10}
                  color={theme.palette.grey[500]}
                  onClick={() => onChangeDate({ ...value, valueFrom: null })}
                />
              )}
            </div>
          )}
        />

        <div className={classes.line}></div>
        <DatePicker
          className={classes.root}
          value={value.valueTo}
          minDate={value.valueFrom}
          label="To date"
          onChange={newValue => onChangeDate({ ...value, valueTo: newValue })}
          inputFormat="dd/MM/yyyy"
          showDaysOutsideCurrentMonth={true}
          openPickerIcon={
            <FCTIcon name="calendar" width={16} height={16} color={theme.palette.grey[500]} />
          }
          disabled={disabled}
          renderInput={props => (
            <div className={classes.wrapperTextField}>
              <FCTTextField
                {...props}
                classes={{ root: classes.rootText }}
                style={{ flex: 1 }}
                helperText=""
                placeholder={placeholder}
              />
              {!disabled && (
                <FCTIcon
                  className={classes.iconEnd}
                  name="close"
                  width={10}
                  height={10}
                  color={theme.palette.grey[500]}
                  onClick={() => onChangeDate({ ...value, valueTo: null })}
                />
              )}
            </div>
          )}
        />
      </div>
    </LocalizationProvider>
  )
}

interface FCTDateRangeProps {
  filter: any
  setFilter(value: any): void
  label: string
  placeholder?: string
  tagDateFrom: string
  tagDateTo: string
  disabled?: boolean
}

import { debounce, Theme } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import theme from '../../theme'
import FCTIcon from '../Icon/Icon'

const Input = styled('input').attrs(() => ({
  type: 'text',
}))`
  ${({ theme }: { theme: Theme }) => `
    padding: 0 40px 0 14px;
    width: 100%;
    box-sizing: border-box;
    background: #F8FAFC;
    border: 0;
    outline: 0;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    color: ${theme.palette?.grey[800]};
    &::placeholder {
      color: ${theme.palette?.grey[500]};
      font-size: 14px;
    }
    &:focus {
      border: 1px solid ${theme.palette?.primary.light};
    }
  `}
`

const InputContainer = styled('div')`
  background: #f8fafc;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 3px 3px 0 0;
  position: relative;
`
const IconSearch = styled('button')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: center;
`

interface IProps {
  handleSearch(v: string): void
  resetSearch(): void
  search: string
  isDebounce?: boolean
  placeholder?: string
  name?: string
  role?: string
}

const FCTSearchInput = ({
  handleSearch,
  resetSearch,
  search,
  isDebounce = true,
  placeholder = '',
  name = '',
  role,
}: IProps) => {
  const [searchValue, setSearchValue] = useState(search)

  const fetchData = (filter: string) => handleSearch(filter)

  const debounceLoadData = useCallback(debounce(fetchData, 500), [])

  const handleFilterChange = (event: any) => {
    const { value } = event.target
    setSearchValue(value)
    isDebounce && debounceLoadData(value)
  }

  useEffect(() => {
    if (!search) setSearchValue('')
  }, [search])

  const resetFilter = () => {
    setSearchValue('')
    resetSearch()
    fetchData('')
  }

  return (
    <InputContainer>
      <Input
        name={name || ''}
        value={searchValue}
        onChange={handleFilterChange}
        onKeyPress={event => {
          if (event.key === 'Enter') fetchData(searchValue)
        }}
        onBlur={() => fetchData(searchValue)}
        placeholder={placeholder}
        role={role}
        data-testid="searchInput"
      />
      {searchValue ? (
        <IconSearch>
          <FCTIcon
            name="close"
            width={16}
            height={16}
            color={theme.palette.grey[400]}
            onClick={() => resetFilter()}
          ></FCTIcon>
        </IconSearch>
      ) : (
        <IconSearch>
          <FCTIcon name="search" width={16} height={16} color={theme.palette.grey[400]} />
        </IconSearch>
      )}
    </InputContainer>
  )
}

export default FCTSearchInput

import { Avatar, Box, Theme, Typography, makeStyles } from '@material-ui/core'
import Flex from 'components/Flex/Flex'
import { LinkHref } from 'components/LinkHref/LinkHref'
import clsx from 'clsx'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 102,
    height: 102,
    cursor: 'pointer',
    borderRadius: 3,
    '& img': {
      width: 'unset',
      height: 'unset',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  nameText: {
    fontWeight: 600,
    marginRight: 10,
    fontSize: 26,
    lineHeight: '34px',
    cursor: 'pointer',
    color: '#0F172A',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  contentText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.3px',
    color: '#1E293B',
    wordBreak: 'break-word',
  },
  linkColor: {
    color: theme.palette.primary.light,
  },
}))

export const AvatarSection = ({
  name,
  logo,
  url,
  onNavigation,
  className,
  isOtherDataSection,
}: {
  name: string
  url: string
  logo: string
  onNavigation: () => void
  className?: ClassNameMap<'avatar' | 'nameText' | 'contentText'>
  isOtherDataSection?: boolean
}) => {
  const classes = useStyles()

  return (
    <Flex alignItems="center" gap={10}>
      <Box onClick={onNavigation}>
        <Avatar
          alt="Avatar"
          src={logo}
          className={`${classes.avatar} ${className?.avatar}`}
          children={name?.toUpperCase().match(/\b(\w)/g)}
        />
      </Box>
      <Box>
        <Box onClick={onNavigation}>
          <Typography className={`${classes.nameText} ${className?.nameText}`} title={name}>
            {isOtherDataSection && name?.length > 18 ? name?.slice(0, 18) + '...' : name}
          </Typography>
        </Box>
        {url?.length > 0 && (
          <LinkHref href={`//${url}`} target="_blank">
            <span className={clsx(classes.contentText, classes.linkColor, className?.contentText)}>
              {isOtherDataSection && url?.length > 22 ? url?.slice(0, 22) + '...' : url}
            </span>
          </LinkHref>
        )}
      </Box>
    </Flex>
  )
}

import React, { useMemo } from 'react'
import { Button, withStyles, ButtonProps } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { omit } from 'lodash'
export interface FCTButtonProps extends ButtonProps {
  reactLink?: string
}

const FCTButton = withStyles(theme => ({
  root: {
    textTransform: 'none',
    letterSpacing: '0.5px',
    fontWeight: 600,
    padding: '10px 14px',
    minWidth: 160,
    fontSize: 12,
    lineHeight: '18px',
  },
  label: {
    width: '100%',
    display: 'flex',
  },
  outlined: {
    backgroundColor: '#ffffff',
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.grey[200]}`,
    '&$disabled': {
      color: theme.palette.grey[400],
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  outlinedPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  outlinedSecondary: {
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    border: theme.palette.error.main,
    '&:hover': {
      border: theme.palette.error.main,
      backgroundColor: theme.palette.error.main,
    },
  },
  sizeSmall: {
    padding: '6px 12px',
    minWidth: 84,
    fontWeight: 400,
  },
  sizeLarge: {
    padding: 14,
    fontSize: 14,
    lineHeight: '22px',
  },
  disabled: {
    background: theme.palette.grey[200],
  },
  startIcon: {
    flex: 1,
    marginLeft: 0,
  },
  endIcon: {
    flex: 1,
    justifyContent: 'flex-end',
  },
}))((props: FCTButtonProps) => {
  const linkProps = useMemo(() => omit(props, ['reactLink']), [props])
  return !!props.reactLink && !props.disabled ? (
    <Link to={props.reactLink} style={{ textDecoration: 'none' }} data-testid="FCTLinkBtn">
      <Button {...linkProps} disableRipple></Button>
    </Link>
  ) : (
    <Button {...linkProps} disableRipple data-testid="FCTButton"></Button>
  )
})

export default FCTButton

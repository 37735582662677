import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../theme'

const FCTSliderSingle = withStyles({
  root: {
    color: theme.palette.primary.light,
  },
})(Slider)

export default FCTSliderSingle

import { Link } from 'react-router-dom'
import { omit } from 'lodash'
import { useMemo } from 'react'
export interface FCTLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  reactLink?: string
}
export const FCTLink: React.FC<FCTLinkProps> = props => {
  const linkProps = useMemo(() => omit(props, ['reactLink']), [props])
  return !!props.reactLink ? (
    <Link {...linkProps} to={props.reactLink} style={{ textDecoration: 'none' }}>
      {props.children}
    </Link>
  ) : (
    <span {...linkProps}>{props.children}</span>
  )
}

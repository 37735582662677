import { makeVar } from '@apollo/client'
import { IVIEW_TYPE } from '../../types/ViewType'
import { separateArray } from '../../utils/separateArray'
import { ISearchInputOption } from 'components/Search/interfaces'
import { FieldBoostMap } from 'components/Search/consts'
import { IFieldBoostMap } from 'components/Search/type'
export interface ISearch {
  options: ISearchInputOption[]
}
const freeTextFields = [
  'description',
  'name',
  'industry',
  'location_cities',
  'location_countries',
  'location_region1_names',
  'location_region2_names',
  'location_region3_names',
  'risks',
  'sectors',
  'value_chains',
  'operating_models',
  'insurtech_product',
  'fintech_product',
  'regtech_product',
  'tag_names',
  'investor_names',
  'investor_types',
  'acquisition_names',
  'acquisition_types',
  'deal_types',
  'last_deal_type',
  'company_alias',
  'all_tag_names',
  'all_tag_group_names',
  'website_keywords',
  'website_url',
  'product_names',
]
export const parseStringToArray = (str = '') => str.split('||')
const ENCODE_SEARCH_WORD_MAP = {
  '~': '_1_',
  '`': '_2_',
  '!': '_3_',
  '@': '_4_',
  '#': '_5_',
  $: '_6_',
  '%': '_7_',
  '^': '_8_',
  '&': '_9_',
  '*': '_10_',
  '(': '',
  ')': '',
  '-': '_13_',
  '+': '_14_',
  '=': '_15_',
  '[': '_16_',
  ']': '_17_',
  '{': '_18_',
  '}': '_19_',
  '\\': '_20_',
  '|': '_21_',
  ';': '_22_',
  ':': '_23_',
  '"': '_24_',
  "'": '_25_',
  '<': '_26_',
  '>': '_27_',
  ',': '_28_',
  '.': '_29_',
  '?': '_30_',
  '/': '_31_',
} as any
const ENCODE_SEARCH_STRING_MAP = {
  ' ': '_0_',
  ...ENCODE_SEARCH_WORD_MAP,
} as any
const DECODE_SEARCH_WORD_MAP = {
  _1_: '~',
  _2_: '`',
  _3_: '!',
  _4_: '@',
  _5_: '#',
  _6_: '$',
  _7_: '%',
  _8_: '^',
  _9_: '&',
  _10_: '*',
  _11_: '(',
  _12_: ')',
  _13_: '-',
  _14_: '+',
  _15_: '=',
  _16_: '[',
  _17_: ']',
  _18_: '{',
  _19_: '}',
  _20_: '\\',
  _21_: '|',
  _22_: ';',
  _23_: ':',
  _24_: '"',
  _25_: "'",
  _26_: '<',
  _27_: '>',
  _28_: ',',
  _29_: '.',
  _30_: '?',
  _31_: '/',
} as any
const DECODE_SEARCH_STRING_MAP = {
  _0_: ' ',
  ...DECODE_SEARCH_WORD_MAP,
}
export const encodeSearchWord = (str: string) =>
  str.replace(
    /(?:~|`|!|@|#|\$|%|\^|&|\*|\(|\)|-|\+|=|\[|]|{|}|\\|\||;|:|\"|'|<|>|,|\.|\?|\/)/g,
    matched => ENCODE_SEARCH_WORD_MAP[matched]
  )
export const decodeSearchWord = (str: string) =>
  str.replace(
    /(?:_1_|_2_|_3_|_4_|_5_|_6_|_7_|_8_|_9_|_10_|_11_|_12_|_13_|_14_|_15_|_16_|_17_|_18_|_19_|_20_|_21_|_22_|_23_|_24_|_25_|_26_|_27_|_28_|_29_|_30_|_31_)/g,
    matched => DECODE_SEARCH_WORD_MAP[matched]
  )

export const encodeSearchString = (str: string) =>
  str.replace(
    /(?: |~|`|!|@|#|\$|%|\^|&|\*|\(|\)|-|\+|=|\[|]|{|}|\\|\||;|:|\"|'|<|>|,|\.|\?|\/)/g,
    matched => ENCODE_SEARCH_STRING_MAP[matched]
  )
export const decodeSearchString = (str: string) =>
  str.replace(
    /(?:_0_|_1_|_2_|_3_|_4_|_5_|_6_|_7_|_8_|_9_|_10_|_11_|_12_|_13_|_14_|_15_|_16_|_17_|_18_|_19_|_20_|_21_|_22_|_23_|_24_|_25_|_26_|_27_|_28_|_29_|_30_|_31_)/g,
    matched => DECODE_SEARCH_STRING_MAP[matched]
  )

const generateFreeTextQuery = (value: string) =>
  freeTextFields
    .map(field =>
      value.includes(' ')
        ? `(phrase field=${
            FieldBoostMap[field as keyof IFieldBoostMap]
              ? field + ` boost=${FieldBoostMap[field as keyof IFieldBoostMap]}`
              : field
          } '${value}')`
        : `(prefix field=${field}_encode_word ${
            FieldBoostMap[field as keyof IFieldBoostMap]
              ? `boost=${FieldBoostMap[field as keyof IFieldBoostMap]}`
              : ''
          } '${encodeSearchWord(value)}')`
    )
    .join(' ')

export const convertOptionsToQuery = (options: ISearchInputOption[]) =>
  `(or ${separateArray(options || [], item => item.operator === 'OR')
    .map(
      listAnd =>
        `(and ${listAnd
          .map(item =>
            item.field == 'text'
              ? '(or ' + generateFreeTextQuery(item.name) + ') '
              : `(phrase field=${item.field}_encode ${
                  item.boost ? `boost=${item.boost}` : ''
                } '${encodeSearchString(item.name)}')`
          )
          .join(' ')})`
    )
    .join(' ')}  )`
export const convertOptionsToHighlight = (options: ISearchInputOption[]) =>
  options
    .filter(op => op.field !== 'text')
    .map(op => op.field + '_encode')
    .concat(
      options.find(op => op.field === 'text' && op.name.includes(' '))
        ? freeTextFields.map(i => `${i}`)
        : [],
      options.find(op => op.field === 'text' && !op.name.includes(' '))
        ? freeTextFields.map(i => `${i}_encode_word`)
        : []
    )
    .join(',')
export const viewTypeVar = makeVar<IVIEW_TYPE>('Bubble')
export const queryParamsVar = makeVar<string>('')
export const commonPolicies = {
  viewType: {
    read() {
      return viewTypeVar()
    },
  },
  COMMON_QUERY_PARAMS: {
    read() {
      return queryParamsVar()
    },
  },
}

import { Theme, Typography, makeStyles } from '@material-ui/core'
import { PreviewHeader } from './PreviewHeader'
import {
  ISearchInputDetail,
  OtherMetaData,
  TopCompaniesType,
  TopInvestorsType,
} from '../interfaces'
import { ApplyButton } from './ApplyButton'
import { getEntityMessage } from '../helpers'
import { AvatarSection } from './AvatarSection'
import { DescriptionFields, SearchFields, TopResultsFields } from '../enums'
import { DescriptionSection } from './DescriptionSection'

const useStyles = makeStyles((theme: Theme) => ({
  descriptionText: {
    fontSize: 14,
    lineHeight: '22px',
    color: '#1E293B',
    letterSpacing: '0.2px',
    marginBottom: 25,
    marginTop: -10,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 30,
    marginBottom: 40,
  },
}))
const parentStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 45,
    height: 45,
  },
  nameText: {
    fontSize: 14,
    lineHeight: '18px',
  },
  contentText: {
    fontSize: 11,
    lineHeight: '14px',
  },
}))

export const OtherDataSection = ({
  option,
  onClickSearch,
  onNavigateTopResults,
}: {
  option: ISearchInputDetail
  onClickSearch: () => void
  onNavigateTopResults: (value: any) => void
}) => {
  const dataObject = option.otherMetaData[0] || {}

  const descriptionTaxonomy = () => {
    switch (option.field) {
      case SearchFields.INDUSTRY:
        return DescriptionFields.INDUSTRY_DESCRIPTION
      case SearchFields.SECTORS:
        return DescriptionFields.SECTORS_DESCRIPTION
      case SearchFields.VALUE_CHAINS:
        return DescriptionFields.VALUE_CHAINS_DESCRIPTION
      case SearchFields.OPERATING_MODELS:
        return DescriptionFields.OPERATING_MODELS_DESCRIPTION
      case SearchFields.RISKS:
        return DescriptionFields.RISKS_DESCRIPTION
      case SearchFields.REGTECH_PRODUCT:
        return DescriptionFields.REGTECH_PRODUCT_DESCRIPTION
      case SearchFields.INSURTECH_PRODUCT:
        return DescriptionFields.INSURTECH_PRODUCT_DESCRIPTION
      default:
        return DescriptionFields.FINTECH_PRODUCT_DESCRIPTION
    }
  }

  const topResultsGroup = () => {
    switch (option.field) {
      case SearchFields.INDUSTRY:
        return TopResultsFields.INDUSTRY_TOP_COMPANIES
      case SearchFields.SECTORS:
        return TopResultsFields.SECTORS_TOP_COMPANIES
      case SearchFields.VALUE_CHAINS:
        return TopResultsFields.VALUE_CHAINS_TOP_COMPANIES
      case SearchFields.OPERATING_MODELS:
        return TopResultsFields.OPERATING_MODELS_TOP_COMPANIES
      case SearchFields.RISKS:
        return TopResultsFields.RISKS_TOP_COMPANIES
      case SearchFields.REGTECH_PRODUCT:
        return TopResultsFields.REGTECH_PRODUCT_TOP_COMPANIES
      case SearchFields.INSURTECH_PRODUCT:
        return TopResultsFields.INSURTECH_PRODUCT_TOP_COMPANIES
      case SearchFields.INVESTOR_TYPES:
        return TopResultsFields.INVESTOR_TYPES_TOP_INVESTORS
      case SearchFields.ACQUISITION_TYPES:
        return TopResultsFields.ACQUISITION_TYPES_TOP_INVESTORS
      default:
        return TopResultsFields.FINTECH_PRODUCT_TOP_COMPANIES
    }
  }
  const topResults = dataObject[topResultsGroup()] as TopCompaniesType[] | TopInvestorsType[]

  const classes = useStyles()
  const parentClassName = parentStyles()

  return (
    <>
      <PreviewHeader option={option} />
      <DescriptionSection description={dataObject[descriptionTaxonomy()] || ''} />
      <Typography className={classes.descriptionText}>
        <b>{dataObject[option.field as keyof OtherMetaData] || 0}</b>
        {' ' + getEntityMessage(option) + ' tracked by the FCT'}
      </Typography>
      <div className={classes.list}>
        {option.field === SearchFields.INVESTOR_TYPES ||
        option.field === SearchFields.ACQUISITION_TYPES
          ? (topResults as TopInvestorsType[])?.map((item: TopInvestorsType) => (
              <div key={item.investor_id}>
                <AvatarSection
                  className={parentClassName}
                  onNavigation={() =>
                    onNavigateTopResults({
                      name: item.investor_name,
                      field: 'investor_names',
                      meta: [`{"type":"investor","investor_id":"${item.investor_id}"}`],
                    })
                  }
                  name={item.investor_name}
                  logo={item.investor_logo_bucket_url}
                  url={item.investor_url
                    .replace('https://', '')
                    .replace('http://', '')
                    .replace('www.', '')}
                  isOtherDataSection={true}
                />
              </div>
            ))
          : (topResults as TopCompaniesType[])?.map((item: TopCompaniesType) => (
              <div key={item.company_id}>
                <AvatarSection
                  className={parentClassName}
                  onNavigation={() =>
                    onNavigateTopResults({
                      name: item.name,
                      field: 'name',
                      meta: [`{"type":"company_id","company_id":${Number(item.company_id)}}`],
                    })
                  }
                  name={item.name}
                  logo={item.logo_bucket_url}
                  url={item.website_url
                    .replace('https://', '')
                    .replace('http://', '')
                    .replace('www.', '')}
                  isOtherDataSection={true}
                />
              </div>
            ))}
      </div>
      <ApplyButton onClick={onClickSearch} />
    </>
  )
}

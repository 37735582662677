import { createSlice } from '@reduxjs/toolkit'
import { mergeDeeps } from '../../../utils/TaxonomyMapping'

export interface IFilterSlice {
  openFilter: boolean
  openOptionFilter: IOpenOptionFilter
}

export interface IOpenOptionFilter {
  filter: boolean
  taxonomy: boolean
  companyOverview: boolean
  geography: boolean
  financing: boolean
  investor: boolean
  ma: boolean
  ipo: boolean
}

export const initOpenOptionFilter: IOpenOptionFilter = {
  filter: false,
  taxonomy: false,
  companyOverview: false,
  geography: false,
  financing: false,
  investor: false,
  ma: false,
  ipo: false,
}

const openFilter: boolean = true

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    openFilter,
    openOptionFilter: initOpenOptionFilter,
  },
  reducers: {
    updateOpenFilter: (state, action) => {
      state.openFilter = action.payload
    },
    updateOpenOptionFilter: (state, action) => {
      state.openOptionFilter = mergeDeeps(state.openOptionFilter, action.payload)
    },
  },
})
export const { updateOpenFilter, updateOpenOptionFilter } = filterSlice.actions
export default filterSlice.reducer

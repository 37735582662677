import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { FCTSelectRadio } from 'components/Select/FCTSelectRadio'
import React, { useEffect, useState } from 'react'
import theme from '../../../theme'
import FCTButton from '../../Button/FCTButton'
import FCTIcon from '../../Icon/Icon'
import FCTSwitch from '../../Switch/FCTSwitch'

const useStyles = makeStyles(theme => ({
  selectText: {
    fontSize: 14,
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  line: {
    width: 4,
    height: 1,
    backgroundColor: '#CBD5E1',
    margin: '0 6px',
  },
  switch: {
    '& .MuiSwitch-root': {
      marginTop: -3,
    },
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    alignItems: 'center',
  },
  formLabel: {
    color: theme.palette.grey['800'],
    fontSize: 12,
    fontWeight: 600,
  },
  clearBtn: {
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    color: '#FF9F19',
    minWidth: 75,
    '&.MuiButton-root:hover': {
      background: theme.palette.primary.contrastText,
    },
    '& .MuiButton-startIcon': {
      marginRight: 3,
    },
  },
}))

const YearRangePicker = (props: YearRangePickerProps) => {
  const classes = useStyles()
  const { filter, setFilter, tagYearFrom, tagYearTo, isYear, handleChange } = props

  const arrayYear = (start: number, end: number) => {
    let yearDefault: string[] = []
    if (start == -1) {
      start = 2000
      yearDefault.push('Pre 2000')
    }
    for (let i = start; i <= end; i++) {
      yearDefault.push(String(i))
    }
    return yearDefault
  }
  const yearDefault = arrayYear(-1, new Date().getFullYear())
  const [yearOptionFrom, setYearOptionFrom] = useState<string[]>(yearDefault)
  const [yearOptionTo, setYearOptionTo] = useState<string[]>(yearDefault)

  useEffect(() => {
    if (filter[tagYearFrom] || filter[tagYearTo]) {
      if (filter[isYear]) {
        filter[tagYearFrom]?.length && handleYearFrom(filter[tagYearFrom])
        filter[tagYearTo]?.length && handleYearTo(filter[tagYearTo])
      } else {
        const tagYear = filter[tagYearTo] || filter[tagYearFrom]
        setFilter({ [tagYearTo]: tagYear })
        setFilter({ [tagYearFrom]: tagYear })
      }
    }
  }, [filter[isYear]])

  const handleYearFrom = (value: string[]) => {
    let year = value[0]
    setFilter({ [tagYearFrom]: value })
    if (year === 'Pre 2000') year = '-1'
    let arrYear = arrayYear(Number(year), new Date().getFullYear())
    setYearOptionTo(arrYear)
  }

  const handleYearTo = (value: string[]) => {
    let year = value[0]
    setFilter({ [tagYearTo]: value })
    if (year === 'Pre 2000') year = '-1'
    let arrYear = arrayYear(-1, Number(year))
    setYearOptionFrom(arrYear)
  }

  return (
    <>
      <div className={classes.switch}>
        <div className={classes.dFlex}>
          <Typography
            className={classes.formLabel}
            style={{ color: theme.palette.grey['900'], fontWeight: 400, marginRight: 14 }}
          >
            Year range
          </Typography>
          <FCTSwitch
            name="yearFoundedRange"
            checked={filter.yearFoundedRange}
            onChange={handleChange}
            size="medium"
          ></FCTSwitch>
        </div>
        <FCTButton
          className={classes.clearBtn}
          startIcon={<FCTIcon name="refresh" width={10} height={10} />}
          variant="text"
          onClick={() => {
            setFilter({ [tagYearTo]: null })
            setFilter({ [tagYearFrom]: null })
            setYearOptionFrom(yearDefault)
            setYearOptionTo(yearDefault)
          }}
        >
          Clear year
        </FCTButton>
      </div>
      {filter[isYear] ? (
        <div className={classes.dFlex}>
          <FCTSelectRadio
            data={yearOptionFrom.map(i => ({ label: i, value: i }))}
            value={filter[tagYearFrom]}
            setValue={v => handleYearFrom([v.value])}
            headLabel="From"
            placeholderValue="From year"
            fullWidthPercent={150 / 310}
            buttonCSS={{ width: 150 }}
          />

          <div className={classes.line} />

          <FCTSelectRadio
            data={yearOptionTo.map(i => ({ label: i, value: i }))}
            value={filter[tagYearTo]}
            setValue={v => handleYearTo([v.value])}
            headLabel="To"
            placeholderValue="To year"
            fullWidthPercent={150 / 310}
            buttonCSS={{ width: 150 }}
          />
        </div>
      ) : (
        <FCTSelectRadio
          data={yearDefault.map(i => ({
            label: i,
            value: i,
          }))}
          value={filter[tagYearTo]}
          setValue={v => {
            setFilter({ [tagYearTo]: [v.value] })
            setFilter({ [tagYearFrom]: [v.value] })
          }}
          headLabel="Individual year"
          placeholderValue="Select Year"
        />
      )}
    </>
  )
}

export default YearRangePicker

interface YearRangePickerProps {
  filter: any
  setFilter(value: any): void
  tagYearFrom: string
  tagYearTo: string
  isYear: string
  handleChange(e: React.SyntheticEvent): void
}

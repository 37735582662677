import { FormControlLabel, makeStyles, RadioGroup, Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FormField, IField } from 'components/Authentication/FormField/FormField'
import SubmitButton from 'components/Authentication/BusinessEmail/SubmitButton'
import clsx from 'clsx'
import FCTRadio from 'components/Radio/FCTRadio'

const { REACT_APP_ENV } = process.env

const useStyles = makeStyles(theme => ({
  labelRadio: {
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  desc: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  detail: {
    marginTop: 24,
    marginBottom: 22,
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'left',
    color: theme.palette.grey[600],
  },
  input: {
    height: 49,
  },
  btn: {
    width: '100%',
    height: 50,
    marginTop: 32,
    '& span': {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  radioGroup: {
    marginTop: 12,
    marginBottom: 24,
    color: theme.palette.grey[800],
    fontSize: 12,
    fontWeight: 400,
  },
}))

export enum EUserType {
  BCG = 'BCG',
  NON_BCG = 'NON_BCG',
}

const VALID_DOMAIN_BCG =
  REACT_APP_ENV !== 'prod'
    ? ['bcg.com', 'bcgdv.com', 'bcgplatinion.com', 'mission.plus']
    : ['bcg.com', 'bcgdv.com', 'bcgplatinion.com']

export interface ISelectUserTypeFormData {
  userType: EUserType
  email: string
}

interface ISelectUserTypeFormProps {
  handleSubmit: (data: ISelectUserTypeFormData) => void
  defaultValues?: ISelectUserTypeFormData
}

export const SelectUserTypeForm = ({ defaultValues, handleSubmit }: ISelectUserTypeFormProps) => {
  const classes = useStyles()

  const {
    control,
    handleSubmit: handleSubmitForm,
    formState: { errors, isValid },
    trigger,
  } = useForm<ISelectUserTypeFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || {
      userType: EUserType.BCG,
      email: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        userType: yup.string().required(''),
        email: yup
          .string()
          .email('Invalid email address')
          .required('This field is required')
          .when('userType', (userTypeValue: EUserType, schema: any) => {
            if (userTypeValue === EUserType.BCG) {
              const regex = new RegExp(
                `^(([^<>()[\\]\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\.,;:\\s@"]+)*)|(".+"))@(${[
                  ...VALID_DOMAIN_BCG,
                ].join('|')})$`
              )

              return schema.matches(
                regex,
                'Invalid email address. Please provide a valid bcg email address'
              )
            }
            return schema
          }),
      })
    ),
  })

  return (
    <form
      onSubmit={e => {
        handleSubmitForm(d => handleSubmit(d))(e)
      }}
    >
      <Typography className={clsx(classes.desc)} style={{ textAlign: 'left' }}>
        Are you a:
      </Typography>
      <Controller
        control={control}
        name="userType"
        defaultValue=""
        render={props => {
          return (
            <RadioGroup
              row
              className={classes.radioGroup}
              name={props.name}
              value={props.value}
              onChange={e => {
                props.onChange(e)
                control.getValues('email') && trigger('email')
              }}
            >
              {[
                { value: EUserType.BCG, label: 'BCG User' },
                { value: EUserType.NON_BCG, label: 'Non BCG User' },
              ].map(e => (
                <FormControlLabel
                  key={e.value}
                  className={classes.labelRadio}
                  style={{ marginRight: 30 }}
                  control={<FCTRadio />}
                  value={e.value}
                  label={e.label}
                />
              ))}
            </RadioGroup>
          )
        }}
      />

      <Typography className={classes.detail}>Your Details</Typography>
      {([
        {
          name: 'email',
          label: 'Email Address',
        },
      ] as IField<ISelectUserTypeFormData>[]).map(field => (
        <FormField
          key={field.name}
          field={field}
          control={control}
          error={errors[field.name as keyof ISelectUserTypeFormData]}
          onBlur={() => {
            control.setValue(field.name, control.getValues(field.name)?.trim() || '')
          }}
        />
      ))}

      <SubmitButton disabled={!isValid || !!errors.email?.message} text="Continue" />
    </form>
  )
}

import { Switch, SwitchClassKey, SwitchProps, withStyles } from '@material-ui/core'
import React from 'react'
import theme from '../../theme'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

interface Props extends SwitchProps {
  classes: Styles
}

const FCTSwitch = withStyles(() => ({
  root: {
    width: 53,
    height: 26,
    padding: 0,
  },
  switchBase: {
    top: 3,
    left: 3,
    padding: '0!important',
    color: '#FFFFFF',
    '&$checked': {
      transform: 'translateX(27px)',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
        padding: 0,
      },
    },
  },

  thumb: {
    width: 20,
    height: 20,
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
  },
  track: {
    borderRadius: 13,
    backgroundColor: theme.palette.grey[200],
    opacity: 1,
  },
  sizeSmall: {
    width: 39,
    height: 22,
  },
  checked: {},
}))((props: Props) => <Switch {...props} disableRipple />)

export default FCTSwitch

import { DatePicker, LocalizationProvider } from '@material-ui/pickers'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import FCTTextField from '../TextField/FCTTextField'
import theme from '../../theme'
import FCTIcon from '../Icon/Icon'
import { Control, Controller } from 'react-hook-form'
import { addDays } from 'date-fns'
import { FormFieldProps } from 'components/Authentication/FormField/FormField'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPickersDay-root:focus.Mui-selected': {
      background: theme.palette.primary.light,
      willChange: 'auto',
    },
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  line: {
    width: '4px',
    height: '1px',
    margin: ' 0px 5px',
    backgroundColor: '#CBD5E1',
  },
  labelText: {
    fontWeight: 400,
    color: theme.palette.grey[800],
    fontSize: '12px',
    marginBottom: '16px',
  },
  clearBtn: {
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    color: '#FF9F19',
    marginBottom: 16,
    minWidth: 75,
    '&.MuiButton-root:hover': {
      background: theme.palette.primary.contrastText,
    },
    '& .MuiButton-startIcon': {
      marginRight: 3,
    },
  },
  iconStart: {
    position: 'absolute',
    left: 112,
    padding: 8,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
    borderRadius: '50%',
  },
  iconEnd: {
    position: 'absolute',
    right: 46,
    padding: 8,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
    borderRadius: '50%',
  },
  rootText: {
    '& .MuiOutlinedInput-input': {
      padding: '17px 0 17px 14px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 3,
      '& fieldset': {
        borderColor: '#E7ECF3',
      },
      '&:hover fieldset': {
        borderColor: '#E7ECF3',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.light,
        borderWidth: 1,
      },
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.grey[800],
      fontSize: 12,
      transform: 'translate(14px, -6px) scale(1)',
      backgroundColor: '#fff',
      paddingRight: 5,
      '&.Mui-focused': {
        color: theme.palette.primary.light,
      },
    },
    '& .MuiInputBase-input': {
      '&::-webkit-input-placeholder': {
        color: theme.palette.grey[500],
        opacity: 1,
      },
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      color: theme.palette.grey[800],
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    '& .MuiIconButton-root': {
      marginRight: -18,
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300],
      background: theme.palette.grey[100],
      opacity: 0.6,
    },
  },
}))

export const DateRangePickerController = (props: FCTDateRangeProps) => {
  const classes = useStyles()
  const {
    filter,
    keys,
    placeholder,
    disabled = false,
    control,
    label = { fromDate: 'From date', toDate: 'To date' },
    errors,
  } = props

  const [value, setValue] = React.useState<Record<'valueFrom' | 'valueTo', Date | null>>({
    valueFrom: !filter[keys.fromDate] ? null : new Date(filter[keys.fromDate]!),
    valueTo: !filter[keys.toDate] ? null : new Date(filter[keys.toDate]!),
  })

  useEffect(() => {
    setValue({
      valueFrom: filter[keys.fromDate] ? new Date(filter[keys.fromDate]!) : null,
      valueTo: filter[keys.toDate] ? new Date(filter[keys.toDate]!) : null,
    })
  }, [filter[keys.fromDate], filter[keys.toDate]])

  const today = new Date()

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils as any}>
      <div className={classes.dFlex}>
        <DatePickerField
          control={control}
          field={{
            name: 'fromDate',
            label: label.fromDate,
            placeholder,
            disabled,
          }}
          error={errors?.fromDate ? { message: errors?.fromDate } : undefined}
          minDate={today}
          maxDate={!!value.valueTo ? addDays(value.valueTo, -1) : null}
          onBlur={() => {}}
          onChange={v => {}}
        />
        <div className={classes.line}></div>
        <DatePickerField
          control={control}
          field={{
            name: 'toDate',
            label: label.toDate,
            placeholder,
            disabled,
          }}
          error={errors?.toDate ? { message: errors?.toDate } : undefined}
          minDate={addDays(value.valueFrom || today, 1)}
          onBlur={() => {}}
          onChange={v => {}}
        />
      </div>
    </LocalizationProvider>
  )
}

interface FCTDateRangeProps {
  filter: Record<string, string | null>
  setFilter(value: Record<string, string | null>): void
  label?: {
    fromDate: string
    toDate: string
  }
  keys: { fromDate: string; toDate: string }
  placeholder?: string
  disabled?: boolean
  control: Control<Record<'fromDate' | 'toDate', string | null>>
  errors?: {
    fromDate?: string
    toDate?: string
  }
}

function DatePickerField<T>(
  props: FormFieldProps<T> & {
    minDate?: Date | null
    maxDate?: Date | null
    onChange(t: T): void
  }
) {
  const classes = useStyles()
  return (
    <Controller
      control={props.control as any}
      name={props.field.name as string}
      render={params => (
        <DatePicker
          {...params}
          showDaysOutsideCurrentMonth
          className={classes.root}
          minDate={props.minDate}
          maxDate={props.maxDate}
          label={props.field.label}
          onChange={newValue => {
            props.onChange(newValue || '')
            params.onChange(newValue || '')
          }}
          inputFormat="dd/MM/yyyy"
          openPickerIcon={
            <FCTIcon name="calendar" width={16} height={16} color={theme.palette.grey[500]} />
          }
          disabled={props.field.disabled}
          renderInput={inputProps => (
            <FCTTextField
              {...inputProps}
              onChange={e => {
                inputProps.onChange && inputProps.onChange(e)
                params.onChange(e)
              }}
              classes={{ root: classes.rootText }}
              style={{ flex: 1 }}
              placeholder={props.field.placeholder}
              error={!!props.error}
              FormHelperTextProps={
                !!props.error
                  ? {
                      role: 'alert',
                      style: {
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        marginLeft: 0,
                      },
                    }
                  : undefined
              }
              helperText={props.error?.message || ''}
              inputProps={{
                id: `#${props.field.name}`,
                ...inputProps.inputProps,
              }}
              InputLabelProps={{
                htmlFor: `#${props.field.name}`,
                shrink: true,
                ...inputProps.InputLabelProps,
              }}
            />
          )}
        />
      )}
    />
  )
}

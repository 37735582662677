import { IReducers } from './reducers'
import searchReducer from './features/search/SearchSlice'
import companyDealReducer from './features/company-deal/CompanyDealSlice'
import analysisReducer from './features/analysis/AnalysisSlice'
import filterReducer from './features/filter/FilterSlice'
import routerReducer from './features/router/RouterSlice'
import watchListReducer from './features/watch-list/WatchListSlice'
import notificationReducer from './features/notification/NotificationSlice'
import investorProfileReducer from './features/investor-profile/InvestorProfileSlice'
import { configureAppStore } from './configureStore'
import { createEpicMiddleware } from 'redux-observable'
import epics from './epic'

const reducers: IReducers = {
  searchReducer,
  companyDealReducer,
  analysisReducer,
  filterReducer,
  routerReducer,
  watchListReducer,
  notificationReducer,
  investorProfileReducer,
}
const epicMiddleware = createEpicMiddleware()
const middlewares = [epicMiddleware]
const store = configureAppStore(reducers, middlewares)

epicMiddleware.run(epics as any)
export type RootState = ReturnType<typeof store.getState>

export default store

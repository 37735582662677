import * as d3 from 'd3'

export default function formatAbbreviationFlexible(x: number | string, f = '~s') {
  if (!+x) return ''
  const s = d3.format(f)(+x)

  switch (s[s.length - 1]) {
    case 'G':
      return s.slice(0, -1) + 'B' // billions
    case 'k':
      return s.slice(0, -1) + 'K' // thousands
  }
  return s
}

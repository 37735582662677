import { withStyles } from '@material-ui/core'
import Slider, { SliderProps } from '@material-ui/core/Slider'
import React, { useEffect } from 'react'
import { formatAbbreviation, formatNumWithKMBToNum } from '../../utils/convert/number'

let valueBeforeChange: number[] | null = null
const FCTSlider = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    height: 4,
    margin: '10px 10px 0',
    width: 'calc(100% - 20px)',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.contrastText}`,
    marginTop: -8,
    marginLeft: -12,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    '&:focus, &:hover, &$active': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
    },
  },
  active: {},
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    background: '#EEF2F6',
    opacity: 1,
  },
  mark: {
    height: 0,
  },
  markLabel: {
    top: -12,
    fontSize: 12,
    fontWeight: 600,
    color: '#334155',
    transform: 'translateX(-100%)',
    '&[data-index="0"]': {
      transform: 'translateX(-10%)',
    },
  },
}))((props: FCTSliderProps) => {
  const { defaultRealValue, dataMarks, onChangeRealValue, min, max, step, unit, ...rest } = props

  const dataMarksSliderEqualSteps: string[] = []

  if (!dataMarks && max && (min || min === 0) && step) {
    for (let i = min; i < max + 1; i += (max - min) / step) {
      if (Math.round(i) !== max) {
        dataMarksSliderEqualSteps.push(String(Math.round(i)))
      } else {
        dataMarksSliderEqualSteps.push(formatAbbreviation(Math.round(i)) + '+')
      }
    }
  }

  const [value, setValue] = React.useState<number[]>([0, 1])

  useEffect(() => {
    const defaultRealValueArr = !dataMarks
      ? [
          formatAbbreviation(Number(defaultRealValue?.start)),
          formatAbbreviation(Number(defaultRealValue?.end)) !== formatAbbreviation(max || 0)
            ? formatAbbreviation(Number(defaultRealValue?.end))
            : formatAbbreviation(Number(defaultRealValue?.end)) + '+',
        ]
      : [defaultRealValue?.start, defaultRealValue?.end]
    const defaultValue = defaultRealValueArr.map((item, index) => {
      const marks = dataMarks?.length ? dataMarks : dataMarksSliderEqualSteps
      for (let i = 0; i < marks?.length; i++) {
        if (marks[i] == item) {
          return i
        }
      }
      return index
    })
    setValue(defaultValue)
  }, [min, max, step, defaultRealValue])

  const convertValueToRealValue = (value: number) => {
    const markIndex = Math.floor(value)
    const realValue = (dataMarks?.length ? dataMarks : dataMarksSliderEqualSteps)[markIndex]
    return realValue
  }

  const marks =
    (dataMarks?.length ? dataMarks : dataMarksSliderEqualSteps).map(
      (item: string | number, index: number) => {
        if (index === 0) {
          return {
            value: index,
            label: !!unit ? String(value[0]) + unit : convertValueToRealValue(value[0]),
          }
        }
        if (index === (dataMarks?.length ? dataMarks : dataMarksSliderEqualSteps).length - 1) {
          return {
            value: index,
            label: !!unit ? String(value[1]) + unit : convertValueToRealValue(value[1]),
          }
        }
        return {
          value: index,
        }
      }
    ) || []

  const handleChange = (event: React.ChangeEvent<{}>, argValue: number | number[]) => {
    const newValue: number[] = typeof argValue === 'number' ? [argValue] : argValue
    if (!valueBeforeChange) {
      valueBeforeChange = [...value]
    }
    if (newValue[0] - newValue[1] === 0) {
      valueBeforeChange = [-1, -1]
    }
    if (
      (valueBeforeChange[0] !== newValue[0] && valueBeforeChange[1] !== newValue[1]) ||
      Math.abs(newValue[0] - newValue[1]) < 1
    ) {
      return
    }
    onChangeRealValue({
      start: dataMarks?.length
        ? convertValueToRealValue(newValue[0])
        : formatNumWithKMBToNum(convertValueToRealValue(newValue[0])),
      end: dataMarks?.length
        ? convertValueToRealValue(newValue[1])
        : formatNumWithKMBToNum(convertValueToRealValue(newValue[1])),
    })
  }

  const handleChangeCommitted = (event: React.ChangeEvent<{}>, argValue: number | number[]) => {
    valueBeforeChange = null
    handleChange(event, argValue)
  }

  return (
    <Slider
      {...rest}
      value={value}
      min={0}
      max={marks[marks.length - 1]?.value}
      step={null}
      marks={marks}
      onChange={(event, argValue) => {
        setValue(argValue as number[])
      }}
      onChangeCommitted={handleChangeCommitted}
      valueLabelDisplay="off"
    />
  )
})

export default FCTSlider

export interface FCTSliderProps extends SliderProps {
  defaultRealValue?: IRealValue
  dataMarks?: string[]
  onChangeRealValue: (newValue: IRealValue) => void
  unit?: String
}

export interface IRealValue {
  start: string | number
  end: string | number
}

import React from 'react'
import { ETagGroupsType, tagGroupsMapData } from 'utils/Taxonomy'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import { CheckboxTree } from '../CheckboxTree/CheckboxTree'
import FCTDropdownListItem from '../DropdownListItem/FCTDropdownListItem'

interface ITagsItemItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
}

const TagsItem = (props: ITagsItemItemProps) => {
  const { filter, setFilter, getFilterData } = props

  const tagsMapping = tagGroupsMapData({
    getFilterData: getFilterData!,
    type: ETagGroupsType.Taxonomy,
  })

  return (
    <FCTDropdownListItem isOpen={true} title="Tag" size="small" openedIcon="down" closedIcon="up">
      <CheckboxTree
        dataTree={tagsMapping.tagsTree}
        originalData={tagsMapping.tagsOriginal}
        selectedIds={filter.tagGroupIds || []}
        setFilter={(value: (string | number)[]) => setFilter({ tagGroupIds: value })}
        isDescription={true}
      />
    </FCTDropdownListItem>
  )
}
export default TagsItem

import React, { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  scrollContainer: {
    maxHeight: '100vh',
    overflow: 'auto',
  },
  container: {
    background: 'linear-gradient(90deg, #0095C5 0%, #0DB966 100%)',
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    background: '#fff',
    borderRadius: 5,
    padding: '32px',
    boxSizing: 'border-box',
    marginTop: '40px',
  },
  popup: {
    textAlign: 'center',
    top: '50%',
    left: '50%',
    width: '430px',
    margin: '30px 0',
  },
}))

interface IProps {
  children?: ReactNode
  width?: string
}

export const AuthenticationTemplate = ({ children, width }: IProps) => {
  const classes = useStyles()

  return (
    <div className={classes.scrollContainer}>
      <div className={classes.container}>
        <div className={classes.popup} style={width ? { width } : {}}>
          <Logo />
          <div className={classes.form}>{children}</div>
        </div>
      </div>
    </div>
  )
}

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <path
        opacity="0.8"
        d="M16.9961 51.9269L30.1777 44.2619V35.7767L16.9961 43.4417V51.9269Z"
        fill="url(#paint0_linear_24804:297420)"
      />
      <path
        opacity="0.8"
        d="M43.3612 51.9269L30.1797 44.2619V35.7767L43.3612 43.4417V51.9269Z"
        fill="url(#paint1_linear_24804:297420)"
      />
      <path
        opacity="0.8"
        d="M16.9961 43.8499L30.1777 51.5148V60L16.9961 52.3392V43.8499Z"
        fill="url(#paint2_linear_24804:297420)"
      />
      <path
        opacity="0.8"
        d="M43.3612 43.8499L30.1797 51.5148V60L43.3612 52.3392V43.8499Z"
        fill="url(#paint3_linear_24804:297420)"
      />
      <path
        opacity="0.8"
        d="M13.834 41.6347L30.1798 32.1337V15.459L13.834 24.9808V41.6347Z"
        fill="url(#paint4_linear_24804:297420)"
      />
      <path
        d="M46.5255 41.6347L30.1797 32.1337V15.459L46.5255 24.9808V41.6347Z"
        fill="url(#paint5_linear_24804:297420)"
      />
      <path
        d="M13.834 31.6425L30.1798 41.1435V51.6688L13.834 42.1677V31.6425Z"
        fill="url(#paint6_linear_24804:297420)"
      />
      <path
        d="M46.5255 31.6425L30.1797 41.1435V51.6688L46.5255 42.1677V31.6425Z"
        fill="url(#paint7_linear_24804:297420)"
      />
      <path
        opacity="0.8"
        d="M7.5 27.7829H30.1784V0L7.5 13.1816V27.7829Z"
        fill="url(#paint8_linear_24804:297420)"
      />
      <path
        opacity="0.8"
        d="M52.8581 27.7829L30.1797 27.6621V0L52.8581 13.1816V27.7829Z"
        fill="url(#paint9_linear_24804:297420)"
      />
      <path
        d="M7.5 13.8934L30.1784 27.0625V41.6763L7.5 28.4906V13.8934Z"
        fill="url(#paint10_linear_24804:297420)"
      />
      <path
        d="M52.8581 13.8934L30.1797 27.0625V41.6763L52.8581 28.4906V13.8934Z"
        fill="url(#paint11_linear_24804:297420)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_24804:297420"
          x1="17.3666"
          y1="37.109"
          x2="26.9301"
          y2="47.4761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3E062" />
          <stop offset="1" stopColor="#4CBC8B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_24804:297420"
          x1="36.7705"
          y1="51.9269"
          x2="36.7705"
          y2="35.7767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6EC8BB" />
          <stop offset="1" stopColor="#2BB996" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_24804:297420"
          x1="23.8742"
          y1="48.2715"
          x2="23.0914"
          y2="58.3013"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008254" />
          <stop offset="1" stopColor="#62BF80" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_24804:297420"
          x1="34.797"
          y1="50.6821"
          x2="42.5535"
          y2="55.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#179067" />
          <stop offset="1" stopColor="#65C4AB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_24804:297420"
          x1="21474.1"
          y1="4470.83"
          x2="20990.4"
          y2="4616.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#179067" />
          <stop offset="1" stopColor="#65C4AB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_24804:297420"
          x1="21999.6"
          y1="4183.69"
          x2="22163.4"
          y2="5185.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008254" />
          <stop offset="1" stopColor="#62BF80" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_24804:297420"
          x1="21461.2"
          y1="2948.68"
          x2="21044"
          y2="2734.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#179067" />
          <stop offset="1" stopColor="#65C4AB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_24804:297420"
          x1="22009.4"
          y1="3092.67"
          x2="22066.4"
          y2="2496.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008254" />
          <stop offset="1" stopColor="#62BF80" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_24804:297420"
          x1="28875.1"
          y1="6570.65"
          x2="29968.7"
          y2="5603.24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3E062" />
          <stop offset="1" stopColor="#4CBC8B" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_24804:297420"
          x1="30716.4"
          y1="4869.5"
          x2="30716.4"
          y2="6723.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6EC8BB" />
          <stop offset="1" stopColor="#2BB996" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_24804:297420"
          x1="28875.1"
          y1="4109.09"
          x2="29968.7"
          y2="5076.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3E062" />
          <stop offset="1" stopColor="#4CBC8B" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_24804:297420"
          x1="30716.4"
          y1="5810.23"
          x2="30716.4"
          y2="3956.28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6EC8BB" />
          <stop offset="1" stopColor="#2BB996" />
        </linearGradient>
      </defs>
    </svg>
  )
}

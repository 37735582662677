import { Box, Link, makeStyles, Theme, Typography, useTheme } from '@material-ui/core'
import { AuthenticationTemplate } from 'templates/AuthenticationTemplate/AuthenticationTemplate'
import Icon from 'components/Icon/Icon'
import styled from 'styled-components'
import { LOCAL_STORAGE_FIELDS } from 'utils/consts'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  textButton: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  flexNavItem: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      opacity: '0.5',
    },
  },
}))

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 17px;
  cursor: pointer;
  min-width: 60px;
  justify-content: flex-end;
`

const ToolbarText = styled(Typography)`
  margin-left: 6px;
  font-size: 14px;
  ${({ theme }: { theme: Theme }) => `
    color: ${theme.palette.grey['700']};
  `}
`

const ExpiryDateContainer = () => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_FIELDS.SIGN_IN_UP_ERROR)
    history.push('/')
  }

  const MAILTO = {
    TO: 'fct.portal@bcg.com',
  }

  return (
    <>
      <AuthenticationTemplate width="500px">
        <Box textAlign="center">
          <Icon name="sendEmail" width="80px" height="80px" />
          <Typography
            style={{
              color: theme.palette.grey[900],
              fontWeight: 500,
              fontSize: 14,
              lineHeight: '34px',
              marginBottom: '16px',
              marginTop: '24px',
            }}
            component="h3"
          >
            {`Your subscription to the FinTech Control Tower has now expired, please contact `}
            <Link className={classes.textButton} href={`mailto:${MAILTO.TO}`}>
              fct.portal@bcg.com
            </Link>
            {` to renew your subscription`}
          </Typography>
          <Toolbar onClick={() => logout()}>
            <div className={classes.flexNavItem}>
              <Icon
                name="export2"
                width={20}
                height={20}
                color={`${theme.palette.primary.light}`}
              />
              {<ToolbarText>Return</ToolbarText>}
            </div>
          </Toolbar>
        </Box>
      </AuthenticationTemplate>
    </>
  )
}

export default ExpiryDateContainer

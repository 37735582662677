import { useMutation } from '@apollo/client'
import { Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import FCTButton from '../../components/Button/FCTButton'
import { ETypeDialog, FCTDialog } from '../../components/Dialog/FCTDialog'
import FCTIcon from '../../components/Icon/Icon'
import { ETypeSelectTabWL, SelectTabWatchList } from '../../components/WatchList/SelectTabWatchList'
import { GetWatchListContext } from '../../features/watch-list/GetWatchList'
import { GetWatchListSharedContext } from '../../features/watch-list/GetWatchListWereShared'
import mixpanel from '../../mixpanel'
import { UPDATE_COMPANY_WATCH_LIST } from '../../store/operations/mutations/UpdateCompanyWatchlist'
import { EMessageType, WatchListMessage } from '../../templates/WatchListHandling/WatchListMessage'
import { WatchListToastsError } from '../../templates/WatchListHandling/WatchListToastsError'
import {
  EToastType,
  WatchListToastsSuccess,
} from '../../templates/WatchListHandling/WatchListToastsSuccess'
import theme from '../../theme'
import { commonErrorHandler } from '../../utils/CommonErrorHandler'
import { pathnameTracking } from '../../utils/Tracking'
import { GetMyWatchlist_getMyWatchlist } from '../../__generated__/GetMyWatchlist'
import { GetWatchlistWereShared_getWatchlistWereShared } from '../../__generated__/GetWatchlistWereShared'
import { UpdateCompanyWatchlist_updateCompanyWatchlist } from '../../__generated__/UpdateCompanyWatchlist'
import ConfirmDisableFilterDialog from './ConfirmDisableFilterDialog'
import { CreateWatchList } from './CreateWatchList'

const useStyles = makeStyles(theme => ({
  root: {},
  buttonText: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#1E293B',
    fontWeight: 600,
    marginLeft: 8,
  },
  buttonIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    verticalAlign: 'baseline',
    minWidth: 32,
  },
  popover: {
    minWidth: 280,
    boxSizing: 'border-box',
    border: '1px solid #E2E8F0',
    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.06)',
    borderRadius: 3,
    padding: '8px 0px',
  },
  optionSelect: {
    display: 'flex',
    padding: '8px 14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[100]}`,
    },
    alignItems: 'center',
  },
  optionText: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    whiteSpace: 'nowrap',
    maxWidth: 300,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  descText: {
    display: 'flex',
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.grey[400],
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.grey[200],
    margin: '10px 0',
  },
  dialogContent: {
    padding: 20,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 'normal',
  },
  btnSelected: {
    padding: 6,
    marginRight: 3,
    display: 'flex',
    cursor: 'pointer',
    borderRadius: 3,
    '&:hover': {
      backgroundColor: `${theme.palette.grey[50]}`,
    },
  },
  btnDisable: {
    backgroundColor: '#E2E8F0',
    cursor: 'context-menu',
    '&:hover': {
      backgroundColor: '#E2E8F0',
    },
  },
  dialogContentCopy: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  copyText: {
    marginBottom: 6,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 'normal',
  },
  copyCompany: {
    maxHeight: 200,
    overflow: 'auto',
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))

export const MAX_COMPANY = 9999

export const AddWatchList = (props: AddWatchListProps) => {
  const classes = useStyles()
  const {
    selectedList,
    selectedName,
    textAdd,
    positionLeft,
    handleCompleteAdd = () => {},
    handleCompleteCreate = () => {},
    type = ETypeUpdate.Update,
    disableFilter = false,
    checkListView = false,
    disabled = false,
  } = props

  const [updateWatchList, { loading: updateLoading }] = useMutation(UPDATE_COMPANY_WATCH_LIST)

  const { addToast } = useToasts()
  const [open, setOpen] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const history = useHistory()

  const getWatchListShared = useContext(GetWatchListSharedContext)
  const getWatchList = useContext(GetWatchListContext)

  const [watchListSelect, setWatchListSelect] = useState<IWatchListSelect>({
    id: null,
    name: null,
    filterOption: null,
  })

  const handleOpenConfirm = () => {
    setOpen(false)
    setOpenConfirm(true)
  }

  const handleAddWList = useCallback(() => {
    const pathname = history.location.pathname
    const companyInfo = pathname.includes('/profile')
      ? {
          companyID: selectedList?.[0],
          companyName: selectedName,
        }
      : {}
    mixpanel.track(`${pathnameTracking(pathname)}_Add to Watchlist`, {
      watchlistID: watchListSelect.id,
      watchlistName: watchListSelect.name,
      watchlistSaveType: !!watchListSelect.filterOption ? 'Filter' : 'ID',
      ...companyInfo,
    })

    updateWatchList({
      variables: {
        input: {
          watchlistId: watchListSelect.id,
          companyIds: selectedList.map(e => +e),
        },
      },
    })
      .then(res => {
        const resData = res?.data
          ?.updateCompanyWatchlist as UpdateCompanyWatchlist_updateCompanyWatchlist
        addToast(
          <WatchListToastsSuccess
            type={
              !!resData?.duplicated
                ? EToastType.AddPartOfCompaniesToWl
                : EToastType.NormalAddCompaniesToWL
            }
            res={res}
            selectedList={selectedList}
            selectedName={selectedName}
            watchListName={watchListSelect.name!}
          />,
          { appearance: 'success' }
        )
        setAnchorEl(null)
        getWatchList.fnWatchList()
        getWatchListShared.fnWatchListShared()
        setOpen(false)
        handleCompleteAdd()
      })
      .catch(
        commonErrorHandler((err: any) => {
          addToast(<WatchListToastsError res={err.graphQLError.message} />, {
            appearance: 'error',
          })
        })
      )
  }, [selectedList, watchListSelect, selectedName])

  const isSelectedList = useMemo(() => {
    return !selectedList.length || selectedList.length > MAX_COMPANY
  }, [selectedList])

  const [anchorEl, setAnchorEl] = useState(null)

  const handleOption = (
    watchlist: GetMyWatchlist_getMyWatchlist | GetWatchlistWereShared_getWatchlistWereShared
  ) => {
    setOpen(true)
    setWatchListSelect({
      id: watchlist.watchlistId,
      name: watchlist.watchlistName,
      filterOption: watchlist.filterOption,
    })
  }

  return (
    <div className={classes.root}>
      {type === ETypeUpdate.Update ? (
        (!isSelectedList || checkListView) && (
          <FCTButton
            variant="outlined"
            color="default"
            className={isSelectedList ? classes.btnDisable : classes.button}
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget)
            }}
            disabled={(checkListView && isSelectedList) || disabled}
          >
            <FCTIcon
              className={classes.buttonIcon}
              name="plusBorder"
              width={16}
              height={16}
              color={`${theme.palette.primary.light}`}
            />
            {textAdd ? (
              textAdd
            ) : (
              <Typography className={classes.buttonText}>Add to Watchlist</Typography>
            )}
          </FCTButton>
        )
      ) : (
        <div className={classes.btnSelected}>
          <FCTIcon
            name="copy"
            width={16}
            height={16}
            color={theme.palette.primary.light}
            onClick={() => setOpen(true)}
          />
        </div>
      )}
      <Popover
        classes={{ paper: classes.popover }}
        id={Boolean(anchorEl) ? 'simple-popover' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: positionLeft ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: positionLeft ? 'left' : 'right',
        }}
      >
        <SelectTabWatchList
          handleOption={handleOption}
          type={ETypeSelectTabWL.Add}
        ></SelectTabWatchList>
        <div className={classes.line}></div>
        <CreateWatchList
          selectedName={selectedName}
          selectedList={selectedList}
          handleCompleteCreate={() => {
            setAnchorEl(null)
            handleCompleteCreate()
          }}
          disableFilter={disableFilter}
        ></CreateWatchList>
      </Popover>
      <FCTDialog
        maxWidth={watchListSelect.filterOption && type === ETypeUpdate.Update ? 'sm' : 'md'}
        open={open}
        setOpen={setOpen}
        title={type === ETypeUpdate.Update ? 'Add to Watchlist' : 'Copy to Watchlist'}
        type={ETypeDialog.Default}
        titleBtnAgree="Confirm"
        handleAgree={
          watchListSelect.filterOption && type !== ETypeUpdate.Update
            ? handleOpenConfirm
            : handleAddWList
        }
        disableBtnAgree={updateLoading || !selectedList?.length || watchListSelect.id === null}
        minHeight={30}
      >
        {type === ETypeUpdate.Update ? (
          <div className={classes.dialogContent}>
            <WatchListMessage
              type={watchListSelect.filterOption ? EMessageType.AddFilter : EMessageType.Add}
              selectedList={selectedList}
              selectedName={selectedName}
              watchListName={watchListSelect.name!}
            />
          </div>
        ) : (
          <div className={classes.dialogContentCopy}>
            <div className={classes.copyText}>
              <WatchListMessage
                type={EMessageType.Copy}
                selectedList={selectedList}
                selectedName={selectedName}
              />
            </div>
            <SelectTabWatchList
              handleOption={handleOption}
              type={ETypeSelectTabWL.Copy}
              watchListSelect={watchListSelect}
            ></SelectTabWatchList>
          </div>
        )}
      </FCTDialog>

      <ConfirmDisableFilterDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleAgree={handleAddWList}
        watchListName={watchListSelect.name!}
      />
    </div>
  )
}

export interface AddWatchListProps {
  selectedList: string[]
  selectedName?: string
  handleCompleteCreate?: () => void
  handleCompleteAdd?: () => void
  textAdd?: React.ReactNode
  positionLeft?: boolean
  type?: ETypeUpdate
  disableFilter?: boolean
  checkListView?: boolean
  disabled?: boolean
}

export interface IWatchListSelect {
  id: number | null
  name: string | null
  filterOption: Object | null
  isShare?: boolean
}

export enum ETypeUpdate {
  Update,
  Copy,
}

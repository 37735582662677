import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client'
import { APOLLO_SERVER } from '../core/config'
import cache from './cache'
import restfulLink from './restful'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import { errorLink } from './ErrorHandling'

import { useEffect, useState, useCallback, SetStateAction, Dispatch } from 'react'
import { setContext } from '@apollo/client/link/context'
import { useOktaAuth } from '@okta/okta-react'
import { SentryLink, SentryLinkOptions } from 'apollo-link-sentry'

const SENTRY_OPTIONS = {
  setFingerprint: true,
  setTransaction: true,

  attachBreadcrumbs: {
    includeQuery: true,
    includeError: true,
  },
} as SentryLinkOptions

// No Authen
export const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink as any,
    restfulLink,
    createPersistedQueryLink({ sha256 }),
    createHttpLink({ uri: APOLLO_SERVER }),
    new SentryLink(SENTRY_OPTIONS),
  ]),
  cache: cache,
  connectToDevTools: true,
})

type TClient = ApolloClient<any> | null
type TSetClient = Dispatch<SetStateAction<TClient | null>>

// Need Okta Authen
export function useApolloClient(): {
  client: TClient
  setClient: TSetClient
} {
  const { oktaAuth } = useOktaAuth()
  const [client, setClient] = useState<TClient>(null)

  const getToken = useCallback(async () => {
    const authLink = setContext((_, { headers }) => {
      const token = oktaAuth.getAccessToken()
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      }
    })

    const newClient = new ApolloClient({
      link: ApolloLink.from([
        errorLink as any,
        authLink,
        restfulLink,
        createPersistedQueryLink({ sha256 }),
        createHttpLink({ uri: APOLLO_SERVER }),
        new SentryLink(SENTRY_OPTIONS),
      ]),
      cache: cache,
      connectToDevTools: true,
    })

    setClient(newClient)
  }, [oktaAuth])

  useEffect(() => {
    getToken()
  }, [getToken])

  return { client, setClient }
}

import { memo, Dispatch, SetStateAction } from 'react'
import { ISearchInputDetail } from '../interfaces'
import { ResultEntity } from './ResultEntity'
import { isEqual } from 'lodash'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    maxHeight: '50vh',
    overflowY: 'auto',
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey['300'],
      borderRadius: 10,
    },
    scrollbarWidth: 'thin',
    scrollbarColor: theme.palette.grey['300'],
  },
}))

export const OptionsSection = memo(
  ({
    options,
    onClick,
  }: {
    options: ISearchInputDetail[]
    onClick: Dispatch<SetStateAction<ISearchInputDetail | null>>
  }) => {
    const classes = useStyles()

    return (
      <div className={clsx(classes.searchBox, classes.scroll)}>
        {options.map((option, index) => (
          <ResultEntity key={index} option={option} onClick={onClick} />
        ))}
      </div>
    )
  },
  (prevProps, nextProps) => isEqual(prevProps.options, nextProps.options)
)

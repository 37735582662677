import { combineEpics } from 'redux-observable'
import analysisEpics from './features/analysis/AnalysisEpic'
import globalEpics from './features/global/GlobalEpic'
import companyDealEpics from './features/company-deal/CompanyDealEpic'
import searchEpics from './features/search/SearchEpic'
import routerEpics from './features/router/RouterEpic'

const epics = combineEpics(
  // global epic
  ...globalEpics,
  ...companyDealEpics,
  ...searchEpics,
  ...analysisEpics,
  ...routerEpics
  // modular epic
)

export default epics

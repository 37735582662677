import { makeStyles } from '@material-ui/core'
import { FilterItem } from './FilterItem'

interface IAcquirerTypesFilterProps {
  data: any // To match with dynamic type in GetAnalysisFilterData Props type
  acquirerTypes: string[]
  setAnalysisFilter: (filter: any) => void // any type use for setAnalysisFilter and mergeDeeps
}

const useStyles = makeStyles({
  root: {
    display: 'block',
    padding: '0 10px',
  },
})

export const AcquirerTypesFilter = ({
  data,
  acquirerTypes,
  setAnalysisFilter,
}: IAcquirerTypesFilterProps) => {
  const classes = useStyles()

  const setFilter = (value: any) => {
    setAnalysisFilter({ acquirerTypes: value?.length ? value : null })
  }

  return (
    <div className={classes.root}>
      {data.length > 0 &&
        data.map((item: any) => (
          <div key={item}>
            <FilterItem id={item} name={item} filterIds={acquirerTypes} setFilterIds={setFilter} />
          </div>
        ))}
    </div>
  )
}

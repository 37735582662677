import { useCallback } from 'react'
import { useState, useRef } from 'react'
import { debounce } from 'lodash'

type GetState<A> = () => A
type SetState<A> = (v: A) => void
export function useSyncState<T>(
  initial: T,
  debounceTime: number = 500
): [T, T, SetState<T>, GetState<T>] {
  const [state, setState] = useState<T>(initial)
  const [debounceState, setDebounceState] = useState<T>(initial)
  const setDebounceStateFn = useCallback(debounce(setDebounceState, debounceTime), [])
  let resolverRef = useRef<T>(initial)
  const setter = (_state: any) => {
    resolverRef.current = _state
    setState(_state)
    setDebounceStateFn(_state)
  }
  const getter = () => resolverRef.current
  return [state, debounceState, setter, getter]
}

import { ITreeData } from '../types/Tree'
import { GetDealFilterDataResult_getDealFilterData_valueChains } from './../__generated__/GetDealFilterDataResult'

export const getChildrenIds = (children: ITreeData[]) => {
  let ids: (number | string)[] = []
  for (const child of children) {
    ids = [...ids, child.id]
    if (child?.children?.length) {
      ids = [...ids, ...getChildrenIds(child.children as ITreeData[])]
    }
  }

  return ids
}

export const getChildrenValueChainIds = (
  parentId: number,
  valueChains: GetDealFilterDataResult_getDealFilterData_valueChains[] | null | undefined
) => {
  return (valueChains || []).filter(e => e.parent_id === parentId).map(({ id }) => id)
}

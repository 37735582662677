import React, { useState } from 'react'
import Flex from 'components/Flex/Flex'
import { Box, Container, makeStyles, createStyles, Grid, Typography } from '@material-ui/core'
import AccordionFaqs from 'components/Policy/AccordionFaqs'
import theme from 'theme'
import { EFaqs, FAQS_DATA } from 'core/constants/String'

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      marginBottom: '32px',
      textAlign: 'center',
      fontSize: '60px',
      lineHeight: '72px',
      whiteSpace: 'normal',
      fontWeight: 700,
    },
    container: {
      height: '100%',
      marginTop: 80,
    },
    page: {
      color: `${theme.palette.grey[800]}`,
    },
    item: {
      margin: 'auto',
      marginTop: '40px',
      display: 'flex',
      justifyContent: 'center',
    },
    list: {
      justifyContent: 'center',
    },
    itemFre: {
      position: 'relative',
      cursor: 'pointer',
    },
    itemTab: {
      marginRight: '16px',
      marginLeft: '16px',
      transition: 'all 500ms ease',
      color: `${theme.palette.grey[500]}`,
      fontWeight: 600,
      fontSize: 14,
    },
    itemDot: {
      pointerEvents: 'none',
      position: 'absolute',
      left: '50%',
      bottom: '-12px',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: `${theme.palette.primary.light}`,
      transform: 'translate(-50%, 0px)',
      transition: 'all 500ms ease',
    },
    listItem: {
      marginTop: 50,
      fontSize: 14,
    },
  })
)

export const FaqsPage = () => {
  const classes = useStyles()
  const [currentTab, setcurrentTab] = useState(EFaqs.AboutUs)

  return (
    <>
      <Box height="100vh" overflow="auto" className={classes.page}>
        <Container className={classes.container}>
          <Grid container className={classes.item}>
            <Grid item md={6}>
              <Typography variant="h1" className={classes.title}>
                Frequently Asked Questions
              </Typography>
              <Flex className={classes.list}>
                {Object.values(EFaqs).map((item, idx) => (
                  <div key={idx} onClick={() => setcurrentTab(item)} className={classes.itemFre}>
                    <div
                      className={classes.itemTab}
                      style={{
                        color: item === currentTab ? `${theme.palette.primary.light}` : '',
                      }}
                    >
                      {item}
                    </div>
                    <div
                      className={classes.itemDot}
                      style={{ opacity: item === currentTab ? '1' : '0' }}
                    ></div>
                  </div>
                ))}
              </Flex>
            </Grid>
            <Grid item md={8} className={classes.listItem}>
              <AccordionFaqs data={FAQS_DATA[currentTab]} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

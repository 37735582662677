import {
  makeStyles,
  Theme,
  Typography,
  Stepper,
  Step,
  StepLabel as MuiStepLabel,
  StepIconProps,
  StepConnector as MuiStepConnector,
  withStyles,
} from '@material-ui/core'
import FCTTextField from '../../TextField/FCTTextField'
import React from 'react'
import CompanyListContainer, {
  CantFindCompany,
  CompanyListItem,
  CompanyOption,
} from '../../../containers/CompanyListContainer'
import clsx from 'clsx'
import Icon from '../../Icon/Icon'
import Flex from '../../Flex/Flex'
import theme from '../../../theme'
import SubmitButton from './SubmitButton'
import CompanyDetailsForm, {
  DEFAULT_COMPANY_DETAILS,
  ICompanyDetailsData,
} from './CompanyDetailsForm'
import Confirmation from './Confirmation'
import FillCompanyForm from './FillCompanyForm'
import EnterEmailForm, { DEFAULT_EMAIL_FORM, IEnterEmailFormData } from './EnterEmailForm'
import getEmailDomain from '../../../utils/email/getEmailDomain'

export const NEW_COMPANY_ID = '-1'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    borderRadius: '3px',
    height: '50px',
    fontSize: '14px',
    border: 'none',
    outline: 'none',
    width: '100%',
    cursor: 'pointer',
    marginTop: '30px',
    '&:disabled': {
      opacity: 0.8,
    },
  },
  secondaryBtn: {
    backgroundColor: `#FFF`,
    color: '#000',
    marginTop: '12px',
  },
  formElement: {
    marginTop: '30px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: `${theme.palette.grey[800]}`,
  },
  titleForm: {
    marginBottom: 10,
    color: `${theme.palette.grey[900]}`,
    fontSize: '28px',
    lineHeight: '42px',
    fontWeight: 600,
  },
  error: {
    marginTop: 16,
    fontSize: '14px',
    lineHeight: '22px',
    color: 'red',
  },
  pointer: {
    cursor: 'pointer',
  },
  stepper: {
    marginTop: '24px',
    padding: 0,
    width: 'calc(100% + 48px)',
    position: 'relative',
    left: '-24px',
  },
}))

export interface IRegisterFields {
  email: string
  company?: CompanyListItem
  companyDetails?: ICompanyDetailsData
}

enum EStep {
  SELECT_COMPANY = 'Select Company',
  ENTER_EMAIL = 'Enter Email',
  COMPANY_DETAILS = 'Company Details',
  CONFIRMATION = 'Confirmation',
}

enum EMode {
  NORMAL,
  COMPANY_NOT_EXIST,
}

function getSteps() {
  return [
    { value: [EStep.SELECT_COMPANY, EStep.ENTER_EMAIL], label: 'Company Info' },
    { value: [EStep.COMPANY_DETAILS], label: EStep.COMPANY_DETAILS },
    { value: [EStep.CONFIRMATION], label: EStep.CONFIRMATION },
  ]
}

interface IReducerState {
  mode: EMode
  step: EStep
}
type TReducerAction = { type: 'nextStep' } | { type: 'prevStep' } | { type: 'switchMode' }
function reducer(state: IReducerState, action: TReducerAction) {
  switch (action.type) {
    case 'nextStep': {
      if (state.mode === EMode.NORMAL) return { mode: EMode.NORMAL, step: EStep.ENTER_EMAIL }

      const map = {
        [EStep.SELECT_COMPANY]: EStep.ENTER_EMAIL,
        [EStep.ENTER_EMAIL]: EStep.COMPANY_DETAILS,
        [EStep.COMPANY_DETAILS]: EStep.CONFIRMATION,
        [EStep.CONFIRMATION]: EStep.CONFIRMATION,
      }

      return { ...state, step: map[state.step] }
    }
    case 'prevStep': {
      const map = {
        [EStep.SELECT_COMPANY]: EStep.SELECT_COMPANY,
        [EStep.ENTER_EMAIL]: EStep.SELECT_COMPANY,
        [EStep.COMPANY_DETAILS]: EStep.ENTER_EMAIL,
        [EStep.CONFIRMATION]: EStep.COMPANY_DETAILS,
      }
      return { ...state, step: map[state.step] }
    }
    case 'switchMode': {
      return {
        ...state,
        mode: state.mode === EMode.NORMAL ? EMode.COMPANY_NOT_EXIST : EMode.NORMAL,
      }
    }
    default:
      throw new Error()
  }
}

interface IProps {
  onSubmit: (data: IRegisterFields) => void
  name?: string
  loadingSubmit?: boolean
  hasError?: boolean
  textError?: string
  signUpAsFintech?: boolean
}

export default function BusinessEmail({
  onSubmit,
  name,
  loadingSubmit,
  hasError = false,
  textError,
  signUpAsFintech = false,
}: IProps) {
  const classes = useStyles()

  const [{ step, mode }, dispatch] = React.useReducer<React.Reducer<IReducerState, TReducerAction>>(
    reducer,
    {
      mode: EMode.NORMAL,
      step: signUpAsFintech ? EStep.SELECT_COMPANY : EStep.ENTER_EMAIL,
    }
  )

  const [company, setCompany] = React.useState<CompanyListItem | undefined>()
  const [emailFormData, setEmailFormData] = React.useState<IEnterEmailFormData>({
    ...DEFAULT_EMAIL_FORM,
  })
  const [companyDetails, setCompanyDetails] = React.useState<ICompanyDetailsData>({
    ...DEFAULT_COMPANY_DETAILS,
  })

  const getBusinessEmail = (data: IEnterEmailFormData) => {
    const domain = getEmailDomain(company?.websiteUrl || '')

    return data.checkAnotherEmail ? data.anotherEmail : `${data.email}${domain ? `@${domain}` : ''}`
  }

  const steps = getSteps()
  const activeStep: number = React.useMemo(() => {
    const idx = steps.findIndex(({ value }) => value.includes(step))
    return idx === -1 ? 0 : idx
  }, [step])

  const title = React.useMemo(() => {
    if (step === EStep.SELECT_COMPANY && mode !== EMode.COMPANY_NOT_EXIST)
      return 'Please check if your company exists on FCT'
    return mode === EMode.COMPANY_NOT_EXIST
      ? 'Please fill in your information below to help our team review your request.'
      : 'Please fill in information below to finish your registration.'
  }, [step, mode])

  return (
    <>
      <Typography className={classes.titleForm} variant="h3">
        Hello {name || ''}!
      </Typography>
      <div className={classes.text}>{title}</div>

      {mode === EMode.COMPANY_NOT_EXIST && (
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className={classes.stepper}
          connector={<StepConnector />}
        >
          {steps.map(({ label }) => (
            <Step key={label} style={{ padding: 0 }}>
              <StepLabel StepIconComponent={StepCircle}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {step === EStep.SELECT_COMPANY && (
        <>
          {mode === EMode.NORMAL ? (
            <SelectCompany
              defaultValue={company}
              loadingSubmit={loadingSubmit}
              handleSubmit={({ company }: { company: CompanyListItem }) => {
                setCompany(company)
                dispatch({ type: 'nextStep' })
              }}
              handleClickCantFindCompany={() => {
                setCompany(undefined)
                dispatch({ type: 'switchMode' })
              }}
            />
          ) : (
            <FillCompanyForm
              defaultValues={{
                name: company?.name || '',
                url: company?.websiteUrl || '',
              }}
              handleSubmit={data => {
                setCompany({
                  id: NEW_COMPANY_ID,
                  name: data.name,
                  websiteUrl: data.url,
                  logoBucketUrl: '',
                })
                dispatch({ type: 'nextStep' })
              }}
            />
          )}
        </>
      )}

      {step === EStep.ENTER_EMAIL && (
        <>
          {signUpAsFintech && (
            <>
              {mode === EMode.COMPANY_NOT_EXIST ? (
                <FillCompanyForm
                  viewOnly
                  defaultValues={{ name: company?.name || '', url: company?.websiteUrl || '' }}
                />
              ) : (
                <div className={classes.formElement}>
                  <FCTTextField
                    disabled
                    label="Company Name"
                    inputProps={{
                      style: { width: 0 },
                    }}
                    InputProps={{
                      startAdornment: company && <CompanyOption hideLogo option={company} />,
                    }}
                    InputLabelProps={{
                      disabled: false,
                    }}
                  />
                </div>
              )}
            </>
          )}
          <EnterEmailForm
            loadingSubmit={loadingSubmit}
            signUpAsFintech={signUpAsFintech}
            companyURL={company?.websiteUrl || ''}
            defaultValues={emailFormData}
            handleSubmit={data => {
              if (mode === EMode.NORMAL) {
                onSubmit({
                  email: getBusinessEmail(data),
                  company,
                })
              } else {
                setEmailFormData(data)
                dispatch({ type: 'nextStep' })
              }
            }}
            onBack={!signUpAsFintech ? undefined : () => dispatch({ type: 'prevStep' })}
          />
        </>
      )}

      {step === EStep.COMPANY_DETAILS && (
        <CompanyDetailsForm
          defaultValues={companyDetails}
          handleSubmit={data => {
            setCompanyDetails(data)
            dispatch({ type: 'nextStep' })
          }}
          onBack={() => dispatch({ type: 'prevStep' })}
        />
      )}

      {step === EStep.CONFIRMATION && company && (
        <Confirmation
          companyInfo={company!}
          companyDetails={companyDetails}
          businessEmail={getBusinessEmail(emailFormData)}
          loadingSubmit={loadingSubmit}
          handleSubmit={() => {
            onSubmit({ email: getBusinessEmail(emailFormData), company, companyDetails })
          }}
          onBack={() => dispatch({ type: 'prevStep' })}
        />
      )}

      {hasError && (
        <Typography className={classes.error} variant="h3">
          {!!textError ? textError : 'Error occurred, please try again!'}
        </Typography>
      )}
    </>
  )
}

interface ISelectCompanyData {
  company: CompanyListItem
}

interface SelectCompanyProps {
  handleSubmit: (data: ISelectCompanyData) => void
  loadingSubmit?: boolean
  onBack?(): void
  hideSubmit?: boolean
  handleClickCantFindCompany?(): void
  defaultValue?: CompanyListItem
}

const SelectCompany = ({
  handleSubmit,
  onBack,
  loadingSubmit,
  handleClickCantFindCompany = () => {},
  defaultValue,
}: SelectCompanyProps) => {
  const classes = useStyles()
  const [touched, setTouched] = React.useState(false)
  const [company, setCompany] = React.useState<CompanyListItem | undefined>(defaultValue)

  const error = touched && !company ? { message: 'Please choose company.' } : null
  return (
    <>
      <div className={classes.formElement}>
        <CompanyListContainer
          disabledLoadMore
          handleCantFindCompany={handleClickCantFindCompany}
          value={company}
          setValue={v => setCompany(v)}
          InputProps={{
            error: !!error,
            helperText: error?.message,
            onBlur: () => setTouched(true),
          }}
          noOptionsText={<CantFindCompany handleClickHere={handleClickCantFindCompany} />}
        />
      </div>

      <SubmitButton
        disabled={!company}
        loading={loadingSubmit}
        onClick={() => !error && company !== undefined && handleSubmit({ company })}
      />

      {onBack && (
        <button
          className={clsx(classes.button, classes.secondaryBtn)}
          onClick={onBack}
          type="button"
          disabled={loadingSubmit}
        >
          Back
        </button>
      )}
    </>
  )
}

const StepCircle = ({ active, completed, ...props }: StepIconProps) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: `1px solid ${active ? theme.palette.primary.light : theme.palette.grey[100]}`,
        backgroundColor: active
          ? 'transparent'
          : completed
          ? theme.palette.primary.light
          : theme.palette.grey[100],
        color: active ? theme.palette.primary.light : completed ? '#FFF' : theme.palette.grey[600],
      }}
    >
      {completed ? (
        <Icon name="checked" />
      ) : (
        <Typography style={{ fontWeight: 700 }}>{String(props.icon)}</Typography>
      )}
    </Flex>
  )
}

const StepConnector = withStyles({
  root: {
    '&.MuiStepConnector-active .MuiStepConnector-line': {
      borderColor: theme.palette.primary.light,
    },
    '&.MuiStepConnector-completed .MuiStepConnector-line': {
      borderColor: theme.palette.primary.light,
    },
  },
  alternativeLabel: {
    top: '20px',
  },
  line: {
    borderColor: '#E7ECF3',
    borderTopWidth: 1,
    borderRadius: 1,
    width: '85%',
    margin: '0 auto',
  },
})(MuiStepConnector)

const StepLabel = withStyles(
  {
    root: {
      '& .MuiStepLabel-alternativeLabel': {
        marginTop: '4px',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '18px',
        color: theme.palette.grey[800],
      },
      '& .MuiStepLabel-active': {
        color: theme.palette.primary.light,
      },
    },
  },
  { name: 'MuiStepLabel' }
)(MuiStepLabel)

import { makeStyles, Theme, Typography, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import FCTIcon from '../Icon/Icon'
import clsx from 'clsx'
import theme from '../../theme'
import { useOktaAuth } from '@okta/okta-react'
import { Link as MTLink } from '@material-ui/core'
import { ESignUpAs } from '../../pages/RegistrationPage'
import AuthError from './AuthError'
import LinkedinAuthContainer from '../../containers/LinkedinAuthContainer'

type Type = 'signIn' | 'signUp'

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: '14px',
    lineHeight: '22px',
    color: `${theme.palette.grey[800]}`,
  },
  backToHome: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'center',
  },
  titleForm: {
    marginTop: '40px',
    color: `${theme.palette.grey[900]}`,
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: 600,
  },
  switchLink: {
    color: `${theme.palette.primary.main}`,
    marginLeft: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  error: {
    fontSize: '14px',
    lineHeight: '18px',
  },
}))

export interface ISignInSignUpProps {
  type: Type
  error?: boolean
  signUpAs?: ESignUpAs.FINTECH
  PoliciesComponent?: React.ReactNode
  Tooltip?: (props: { children: React.ReactElement }) => React.ReactElement
  disabledBtn?: boolean
}

export const SignInSignUp = ({
  type,
  signUpAs,
  PoliciesComponent,
  Tooltip = ({ children }) => children,
  disabledBtn = false,
  error,
}: ISignInSignUpProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { oktaAuth, authState } = useOktaAuth()

  const switchLink = () => {
    const callback = `${type == 'signIn' ? '/register' : '/login'}`

    if (authState?.isAuthenticated) {
      oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin + callback,
      })
    } else {
      history.push(callback)
    }
  }

  return (
    <>
      <a role="homepage-link" className={classes.backToHome} href="#" target="_blank">
        <FCTIcon
          name="home"
          width={16}
          height={16}
          style={{ marginRight: '8px' }}
          color={theme.palette.primary.light}
        />
        <div className={classes.text}>Back to homepage</div>
      </a>

      <Typography className={classes.titleForm} variant="h3">
        {type == 'signIn' ? 'Log in' : 'Create new account'}
      </Typography>

      <div style={{ marginTop: '10px' }} className={classes.text}>
        {type == 'signIn'
          ? 'Connect with us to get the latest updates on the FinTech world.'
          : 'Use LinkedIn to sign up to FCT with your professional identity.'}
      </div>

      {PoliciesComponent && PoliciesComponent}

      <Box position="relative" mt="32px" mb="10px">
        {Tooltip({
          children: (
            <LinkedinAuthContainer signUpAs={signUpAs} disabled={disabledBtn} type={type} />
          ),
        })}
      </Box>
      {error && <AuthError type={type} />}

      <Box className={clsx(classes.text)} mt={'24px'}>
        {type == 'signIn' ? "Don't have an account yet?" : 'Already have an account?'}
        <MTLink className={classes.switchLink} onClick={() => switchLink()}>
          {type == 'signIn' ? 'Create now' : 'Log in?'}
        </MTLink>
      </Box>
    </>
  )
}

export const SignInSignUpTitle = ({ type }: { type: Type }) => {
  const classes = useStyles()
  return (
    <>
      <a role="homepage-link" className={classes.backToHome} href="#" target="_blank">
        <FCTIcon
          name="home"
          width={16}
          height={16}
          style={{ marginRight: '8px' }}
          color={theme.palette.primary.light}
        />
        <div className={classes.text}>Back to homepage</div>
      </a>

      <Typography className={classes.titleForm} variant="h3">
        {type == 'signIn' ? 'Log in' : 'Create new account'}
      </Typography>

      <div style={{ marginTop: '10px' }} className={classes.text}>
        {type == 'signIn'
          ? 'Connect with us to get the latest updates on the FinTech world.'
          : 'Sign up to the FCT today and access cutting-edge FinTech insights.'}
      </div>
    </>
  )
}

export interface ITree<T> {
  children?: ITree<T>[]
  parent?: ITree<T>
}

export interface IOriginalData {
  id: number | string
  parentIds: (number | string)[]
  name: string
}

export interface ITreeData {
  id: number | string
  parentId: number | null | string
  name: string
  children?: ITreeData[]
  description?: string
  type?: TaxonomyType
}

export enum TaxonomyType {
  Product = 'Product',
  ValueChain = 'ValueChain',
}

import { createSelector } from '@reduxjs/toolkit'
import { Selector } from 'react-redux'
import { RootState } from '../../store'
import { IAnalysisSlice } from './AnalysisSlice'

const selectAnalysis: Selector<RootState, IAnalysisSlice> = (state: RootState) =>
  state.analysisReducer
const selectAnalysisFilter = createSelector(selectAnalysis, state => state.filter)
const selectAnalysisLocalFilter = createSelector(selectAnalysis, state => state.localFilter)
const selectChipsHeader = createSelector(selectAnalysis, state => state.chipsHeader)
const selectBreadcrumbs = createSelector(selectAnalysis, state => state.breadcrumbs)
const selectExportMultiple = createSelector(selectAnalysis, state => state.exportMultiple)
export {
  selectAnalysis,
  selectAnalysisFilter,
  selectChipsHeader,
  selectBreadcrumbs,
  selectAnalysisLocalFilter,
  selectExportMultiple,
}

import { isNil } from 'lodash'
import {
  decodeSearchString,
  decodeSearchWord,
  encodeSearchString,
  encodeSearchWord,
} from '../../store/cache/Common'
import { IHit } from '../../store/operations/queries/local/rest/RestfulResponse'
import { ISearchCompany } from '../../store/operations/queries/local/rest/SearchCompanies'
import { Logger } from '../../utils/Logger'
import { TagGroupMap } from './consts'
import { TagGroups } from './enums'

const logger = new Logger('Search/utils')

export const stringToArray = (str: string = '', separator: string = ',') => str.split(separator)

export const getHighlight = (str: string = '', separator: string = '*'): string[] =>
  stringToArray(str, separator).filter((v, k) => k % 2)

export const stringToJson = (str: string = '') => (str ? JSON.parse(str) : {})

export const getSearchKey = (str: string = '', separator: string = '*') => {
  const data = stringToArray(str, separator).map((value: string, key: number) => ({
    value,
    key,
  }))
  return data
    .reduce(
      function (arr: any[], v: { value: string; key: number }) {
        if (!(v.value == ' ' || v.value == '') && v.key % 2 == 0) arr.push([v])
        else arr[arr.length - 1].push(v)
        return arr
      },
      [[]]
    )
    .map((items: any[]) => items.filter(({ value, key }) => key % 2).map(({ value }) => value))
    .map(items => items.join(' '))
    .filter(x => !!x)
}
export interface ICompanyStats {
  total: number
  group: string
  keywords: Set<string>
}
export function calculateStats(data: IHit<ISearchCompany>, group: TagGroups): ICompanyStats {
  return Object.entries(data.highlights || {}).reduce(
    (acc: ICompanyStats, [fullFieldName, highlight]: [string, any]) => {
      const field = fullFieldName.replace('_encode_word', '').replace('_encode', '')
      const isEncode = fullFieldName.includes('_encode')
      const isEncodeWord = fullFieldName.includes('_encode_word')
      if (TagGroupMap[field as keyof ISearchCompany] == group) {
        const _keywords = getHighlight(highlight)
        const fieldData = data.fields[field as keyof ISearchCompany]
        let fieldDatas = ((Array.isArray(fieldData) ? fieldData : [fieldData]) as string[]).filter(
          d => !isNil(d)
        )
        fieldDatas = isEncodeWord
          ? fieldDatas.map(v => decodeSearchWord(encodeSearchWord(v)))
          : isEncode
          ? fieldDatas.map(v => decodeSearchString(encodeSearchString(v)))
          : fieldDatas
        const keywords = isEncodeWord
          ? _keywords.map(decodeSearchWord)
          : isEncode
          ? _keywords.map(decodeSearchString)
          : _keywords
        const matched = fieldDatas.filter((field: string) =>
          keywords.some(key => field.includes(key))
        )
        matched.map((keyword: string) => {
          acc.keywords.add(decodeSearchString(keyword))
        })

        acc.total = acc.keywords.size
        acc.group = group
      }
      return acc
    },
    {
      total: 0,
      keywords: new Set(),
      group: '',
    }
  )
}

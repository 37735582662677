import { ICompanyDealFilter } from './../../../types/CompanyDealFilter'
import { Action } from 'redux-actions'
import { ofType } from 'redux-observable'
import { RootState } from '../../store'
import { concat, Observable, of } from 'rxjs'
import { switchMap, withLatestFrom } from 'rxjs/operators'
import { onSearchActionEmpty } from '../search/SearchAction'
import {
  clearCompanyDealSearchOption,
  initCompanyDealFilter,
  updateCompanyDealFilter,
  updateCompanyDealSearchOption,
} from './CompanyDealSlice'
import { applySearchOptToCompanyDeal } from './CompanyDealAction'
import { ISearchInputOption } from 'components/Search/interfaces'

const resetCompanyDealSearchOptionEpic = (
  action$: Observable<Action<any>>,
  store: Observable<RootState>
) =>
  action$.pipe(
    ofType(onSearchActionEmpty.type),
    switchMap((action: Action<ISearchInputOption[]>) => {
      return concat(of(clearCompanyDealSearchOption(null)))
    })
  )

const applySearchOptionToFilterEpic = (
  action$: Observable<Action<any>>,
  store$: Observable<RootState>
) =>
  action$.pipe(
    ofType(applySearchOptToCompanyDeal.type),
    withLatestFrom(store$),
    switchMap(([action, store]) => {
      const searchOption = store.searchReducer.options
      return concat(
        of(
          updateCompanyDealFilter({
            ...initCompanyDealFilter,
            searchData: {
              ...initCompanyDealFilter.searchData,
              options: [...searchOption],
            },
          } as ICompanyDealFilter)
        )
      )
    })
  )
export default [resetCompanyDealSearchOptionEpic, applySearchOptionToFilterEpic]

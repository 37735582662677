import { FilterItem } from 'components/Filters/FilterItem'
import { concat } from 'lodash'
import isNil from 'lodash/isNil'
import union from 'lodash/union'
import React, { useEffect, useMemo, useState } from 'react'
import { useSubFilter } from 'store/features/company-deal/CompanyDealHook'
import { CATEGORY_TAXONOMY, TAXONOMY_ID } from 'utils/CompanyFilter'
import { VIEW_BY } from '../../core/constants/Filter'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IDimensionTree } from '../../types/Dimension'
import { IAnalysisFilter } from '../../types/Filter'
import { ITreeData, TaxonomyType } from '../../types/Tree'
import { FindTaxonomy } from '../../utils/FindTaxonomy'
import { TaxonomyFilterMapping } from '../../utils/TaxonomyMapping'
import { getChildrenIds } from '../../utils/Tree'
import { GetDealFilterDataResult_getDealFilterData } from '../../__generated__/GetDealFilterDataResult'
import { GlobalFilterViewBy, InternalDbCompanyTypes } from '../../__generated__/globalTypes'
import { CheckboxTree, ITreeNode } from '../CheckboxTree/CheckboxTree'
import FCTDropdownListItem from '../DropdownListItem/FCTDropdownListItem'
import { BusinessFilter } from '../Filters/BusinessFilter'
import { TooltipDesc } from '../Tabs/FCTTab'
import { GetDealFilterDataResult_getDealFilterData_valueChains } from './../../__generated__/GetDealFilterDataResult'

const DISPLAY_NAMES = [
  VIEW_BY.fintech.displayName,
  VIEW_BY.insurtech.displayName,
  'Product',
  'Operating Model',
]

const TaxonomyChildItem = (props: ITaxonomyChildItemProps) => {
  const { filter, setFilter, getFilterData, enableNodesAnalysis, expandedNodesAnalysis } = props
  const [positionProducts, setPositionProducts] = useState<boolean>(true)
  const category = filter.category
  const data = VIEW_BY[category].propName as keyof GetDealFilterDataResult_getDealFilterData
  const productsData = getFilterData?.products || []
  const filteredProducts = productsData.filter(product => product.category == filter.category) || []
  const [subFilter, _] = useSubFilter()

  const getTreeIndustry = (id: number, name: string, description: TooltipDesc) => {
    const products = productsData.filter(product => product.category == CATEGORY_TAXONOMY.get(id))
    return {
      businessLineId: null,
      companies: null,
      description: description,
      id: id,
      name: name,
      size: 0,
      totalFundings: null,
      __typename: 'AtlasViewDataDetail',
      children: TaxonomyFilterMapping(products || []).map(v => ({
        ...v,
        parentId: id,
      })),
      parentId: id,
      category: null,
      isShow: null,
      latestDeals: [],
    } as IDimensionTree
  }

  const operatingModels = getFilterData?.operatingModels || []

  const productsTree = useMemo(() => {
    if (filter.category == InternalDbCompanyTypes.all) {
      return concat(
        getTreeIndustry(TAXONOMY_ID.FINTECH_ID, 'FinTech', TooltipDesc.FinTech),
        getTreeIndustry(TAXONOMY_ID.REGTECH_ID, 'RegTech', TooltipDesc.RegTech),
        getTreeIndustry(TAXONOMY_ID.INSURANCE_ID, 'InsurTech', TooltipDesc.InsurTech)
      )
    }

    return TaxonomyFilterMapping(filteredProducts)
  }, [filteredProducts])

  const { valueChainsTree, valueChainsOriginal } = useMemo(() => {
    const itemMapping = (e: GetDealFilterDataResult_getDealFilterData_valueChains) => ({
      id: e.id,
      parentId: e.parent_id,
      name: e.name,
      children: [],
      description: e.description,
    })

    const valueChains = getFilterData?.valueChains || []
    const valueChainsL1 = valueChains.filter(e => e.level === 1)
    const valueChainsL2 = valueChains.filter(e => e.level === 2)

    return {
      valueChainsTree: valueChainsL1.map(e => {
        return {
          ...itemMapping(e),
          parentId: e.level === 1 ? e.id : e.parent_id,
          parentIds: [e.parent_id],
          children: valueChainsL2.filter(el => el.parent_id === e.id).map(el => itemMapping(el)),
        }
      }),
      valueChainsOriginal: [
        ...valueChainsL1.map(e => ({
          id: e.id,
          name: e.name,
          parentIds: [],
        })),
        ...valueChainsL2.map(e => ({
          id: e.id,
          name: e.name,
          parentIds: e.parent_id ? [e.parent_id] : [],
        })),
      ],
    }
  }, [getFilterData?.valueChains])

  const enableNodes = useMemo(() => {
    let nodes: (number | string)[] = []
    let getTreeNodes: IDimensionTree[] = []

    const parentNodes = filter.selectedBusinessLineId ? [filter.selectedBusinessLineId] : []

    const selectedNodes = filter.selectedProductId
      ? filteredProducts.filter(product => product.id == filter.selectedProductId)
      : filteredProducts.filter(
          product =>
            product.parentIds.filter(el => parentNodes.includes(el)).length ||
            parentNodes.includes(product.id)
        )

    if (productsTree?.length) {
      for (const selectedNode of selectedNodes) {
        for (const node of productsTree) {
          const findTaxonomyByNodeId = FindTaxonomy(node)
          getTreeNodes = union(
            getTreeNodes,
            (findTaxonomyByNodeId(selectedNode.id)
              ? [findTaxonomyByNodeId(selectedNode.id)]
              : []) as IDimensionTree[]
          )
        }
      }
    }

    if (filter.selectedBusinessLineId && filter.viewBy === GlobalFilterViewBy.valuechain) {
      return (getFilterData?.valueChains || [])
        .filter(
          e => e.parent_id == filter.selectedBusinessLineId || e.id == filter.selectedBusinessLineId
        )
        .map(item => ({
          ...item,
          parentIds: [item.parent_id],
          parent_id: item.level === 1 ? null : item.parent_id,
          type: TaxonomyType.ValueChain,
        })) as any[]
    }

    if (getTreeNodes?.length) {
      for (const item of getTreeNodes) {
        const children = getChildrenIds(item.children as ITreeData[])
        nodes = union(
          nodes,
          children,
          getTreeNodes.map(el => el.id)
        )
      }

      const filteredProductMaps = filteredProducts.map(e => {
        if (e.parentIds[0] == 365)
          return {
            ...e,
            parentIds: [TAXONOMY_ID.REGTECH_ID],
          }
        return e
      })

      const regTech = {
        __typename: 'ProductFilterItem',
        id: TAXONOMY_ID.REGTECH_ID,
        name: 'RegTech',
        category: null,
        parentIds: [11],
        productLv1Id: [11],
        description: TooltipDesc.RegTech,
      }

      return [...filteredProductMaps, regTech]
        .filter(el => !!nodes.find(item => item == el.id))
        .map(item => ({
          ...item,
          type: TaxonomyType.Product,
        }))
    }

    return []
  }, [
    filteredProducts,
    filter.selectedProductId,
    filter.selectedBusinessLineId,
    filter.viewBy,
    filter.products,
    filter.businessLines,
  ])

  const setProductFilter = (value: (number | string)[]) => {
    setFilter({ products: value.map(Number) })
  }

  const setValueChainFilter = (value: (number | string)[]) => {
    setFilter({ valueChains: value.map(Number) })
  }

  useEffect(() => {
    if (category === InternalDbCompanyTypes.insurtech) setPositionProducts(false)
    else setPositionProducts(true)
  }, [category])

  useEffect(() => {
    const productParentId = (filter as ICompanyDealFilter).productParentId
    if (!isNil(productParentId) && Array.isArray(getFilterData?.products)) {
      const products = getFilterData?.products
        .filter(item => item.parentIds.includes(productParentId))
        .map(item => item.id)
      setFilter({
        productParentId: null,
        products: union(products, [productParentId]),
      })
    }
  }, [filter, getFilterData])

  const displayName = useMemo(() => VIEW_BY[category].displayName, [category])

  const getTooltip = (displayName: string) => {
    return DISPLAY_NAMES.includes(displayName)
      ? `${displayName} filters disabled whilst in compare mode. Please remove compare filter if you wish to use ${displayName.toLowerCase()} filters`
      : ''
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: positionProducts ? 'column' : 'column-reverse',
        }}
      >
        {InternalDbCompanyTypes.fintech === filter.category && (
          <FCTDropdownListItem
            title={displayName}
            size="small"
            openedIcon="down"
            closedIcon="up"
            isOpen={true}
            tooltipText={
              !!(subFilter.currentCompanyName && subFilter.businessLines)
                ? getTooltip(displayName)
                : ''
            }
          >
            <BusinessFilter
              name={VIEW_BY[category].propName}
              businessLines={filter?.businessLines || []}
              data={getFilterData ? getFilterData[data] || [] : []}
              valueChains={filter?.valueChains || []}
              risks={filter?.risks || []}
              setAnalysisFilter={setFilter}
              isDescription={true}
            />
          </FCTDropdownListItem>
        )}

        {InternalDbCompanyTypes.insurtech === filter.category && (
          <FCTDropdownListItem
            isOpen={true}
            title="Value Chain"
            size="small"
            openedIcon="down"
            closedIcon="up"
            tooltipText={
              !!(subFilter.currentCompanyName && subFilter.valueChains)
                ? getTooltip(displayName)
                : ''
            }
          >
            <CheckboxTree
              dataTree={valueChainsTree as ITreeData[]}
              originalData={valueChainsOriginal}
              selectedIds={filter.valueChains || []}
              setFilter={setValueChainFilter}
              enableNodes={enableNodesAnalysis || enableNodes}
              expandedNodes={expandedNodesAnalysis}
              isDescription={true}
              isValueChain
              type={TaxonomyType.ValueChain}
            />
          </FCTDropdownListItem>
        )}
        <FCTDropdownListItem
          isOpen={true}
          title="Product"
          size="small"
          openedIcon="down"
          closedIcon="up"
          tooltipText={
            !!(subFilter.currentCompanyName && subFilter.product) ? getTooltip('Product') : ''
          }
        >
          <CheckboxTree
            dataTree={productsTree as ITreeData[]}
            originalData={getFilterData?.products || []}
            selectedIds={filter.products || []}
            setFilter={setProductFilter}
            enableNodes={enableNodesAnalysis || enableNodes}
            expandedNodes={expandedNodesAnalysis}
            isDescription={true}
            isProduct
            type={TaxonomyType.Product}
          />
        </FCTDropdownListItem>
      </div>
      {InternalDbCompanyTypes.insurtech === filter.category && (
        <FCTDropdownListItem
          title="Operating Model"
          size="small"
          openedIcon="down"
          closedIcon="up"
          isOpen={true}
          tooltipText={
            !!(subFilter.currentCompanyName && subFilter.operatingModel)
              ? getTooltip('Operating Model')
              : ''
          }
        >
          <div style={{ paddingLeft: 9 }}>
            {operatingModels.map(item => (
              <FilterItem
                {...item}
                filterIds={filter.operatingModels || []}
                setFilterIds={(value: number[]) =>
                  setFilter({
                    operatingModels: value?.length ? value : null,
                  })
                }
                isDescription
                hasDisable={!!subFilter.operatingModel}
                id={item.id}
              />
            ))}
          </div>
        </FCTDropdownListItem>
      )}
    </>
  )
}

export default TaxonomyChildItem

interface ITaxonomyChildItemProps {
  filter: ICompanyDealFilter | IAnalysisFilter
  setFilter(value: any): void
  getFilterData: GetDealFilterDataResult_getDealFilterData | null | undefined
  enableNodesAnalysis?: ITreeNode[]
  expandedNodesAnalysis?: string[]
}

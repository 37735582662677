import axios, { AxiosRequestConfig } from 'axios'
import config from 'core/config/auth'

interface IGetAccess {
  email: string
  firstName: string
  lastName: string
  isBCG: boolean
  caseDetails?: {
    caseCode: string
    partnerName: string
    clientName: string
    numberOfLogins: number
    periodOfUseFrom: string
    periodOfUseTo: string
    caseContext: string
    tier1: boolean
  }
  companyDetails?: {
    name: string
    url: string
    roleInCompany: string
    numberOfLogins: number
  }
}

export default function (body: IGetAccess, c?: AxiosRequestConfig) {
  return axios.post<{ data: boolean }>(`${config.api}/send-get-access-request`, body, c)
}

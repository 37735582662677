import { gql } from '@apollo/client'

export const SEARCH_DIMENSIONS = gql`
  query SearchDimensions($names: [String!]) {
    searchDimensions(names: $names) {
      id
      dimensionName
      dimensionDesc
    }
  }
`

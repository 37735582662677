import { makeStyles, Typography } from '@material-ui/core'
import { TreeView } from '@material-ui/lab'
import clsx from 'clsx'
import { concat, union } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useSubFilter } from 'store/features/company-deal/CompanyDealHook'
import theme from '../../theme'
import { IOriginalData, ITreeData, TaxonomyType } from '../../types/Tree'
import { getChildrenIds } from '../../utils/Tree'
import FCTCheckbox from '../Checkbox/FCTCheckbox'
import FCTIcon from '../Icon/Icon'
import { TooltipDescription } from '../Tooltip/TooltipDescription'
import FCTTreeItem from '../TreeItem'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 1,
    padding: '0 10px',
  },
  groupLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
    maxWidth: 200,
    textTransform: 'capitalize',
    color: theme.palette.grey[800],
  },
  labelTitle: {
    fontWeight: 'normal',
  },
  icon: {
    paddingRight: 8,
    display: 'flex',
  },
  radio: {
    padding: 0,
    margin: 9,
  },
  titleName: {
    display: 'flex',
    alignItems: 'center',
    '&:hover $infoDescription': {
      opacity: 1,
    },
  },
  infoDescription: {
    opacity: 0,
  },
}))

const selectedCount = (data: ITreeData[], ids: (number | string)[]) => {
  if (data?.length && ids?.length) {
    const selected = data.filter((node: ITreeData) => ids.includes(node.id))
    return selected.length
  }
  return 0
}

const selected = (
  data: IOriginalData[],
  parentId: number | string,
  ids: (number | string)[],
  isCheck: boolean
) => {
  let parentIds: (number | string)[] = []
  const parent = data.find((child: IOriginalData) => child.id == parentId)

  const children = data.filter((child: IOriginalData) => child.parentIds[0] == parentId)
  const count = children.filter((child: IOriginalData) => ids.includes(child.id)).length

  if (isCheck) {
    if (children.length == count) {
      if (parent?.parentIds.length == 1) {
        parentIds = union(parentIds, selected(data, parent.parentIds[0], [...ids, parentId], true))
      }
      parentIds = union(parentIds, [parentId])
    }
  } else {
    if (count == 0) {
      const newIds = ids.filter((id: number | string) => id != parentId)
      if (parent?.parentIds.length == 1) {
        parentIds = union(parentIds, selected(data, parent.parentIds[0], newIds, false))
      }
      parentIds = union(parentIds, [parentId])
    }
  }

  return parentIds
}

const filterNodes = (list: ITreeData[], ids: (number | string)[]) => {
  let nodes: ITreeData[] = []
  let children: ITreeData[] = []
  for (const item of list) {
    children = concat(children, item?.children?.length ? item.children : [])
  }
  if (children.length > 0) {
    nodes = concat(
      nodes,
      children.filter(el => ids.includes(el.id)),
      filterNodes(children, ids)
    )
  }
  return nodes
}

const checkIndeterminate = (nodes: ITreeData, ids: (number | string)[]) => {
  if (nodes?.children?.length && ids?.length) {
    const count = selectedCount(nodes.children as ITreeData[], ids)

    const listNode = filterNodes(nodes.children, ids)

    if (ids.includes(nodes.id) && getChildrenIds(nodes.children).some(e => !ids.includes(e)))
      return true

    return (
      !!(selectedCount(nodes.children as ITreeData[], ids) == 0 && listNode.length > 0) ||
      (0 < count && count < nodes.children.length)
    )
  }
  return false
}

interface ITreeProps {
  nodes: ITreeData
  selectedIds: (number | string)[]
  onSelected: (value: (number | string)[]) => void
  index?: number
  original: IOriginalData[]
  enableNodes?: ITreeNode[] | null
  isDescription?: boolean
  isCollapsible?: boolean
  searchValue?: string
  isProduct?: boolean
  isValueChain?: boolean
  type?: TaxonomyType
}

export const Tree = ({
  nodes,
  selectedIds,
  onSelected,
  index = -1,
  original,
  enableNodes,
  isDescription,
  isCollapsible = false,
  searchValue = '',
  isProduct = false,
  isValueChain = false,
  type,
}: ITreeProps) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [subFilter, _____] = useSubFilter()

  const count = selectedCount(nodes.children as ITreeData[], selectedIds)
  const isIndeterminate = useMemo(() => checkIndeterminate(nodes, selectedIds), [selectedIds])
  selectedIds = selectedIds || []

  const changeValue = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: boolean
  ) => {
    const childrenIds = getChildrenIds(nodes.children as ITreeData[])

    if (value) {
      let ids = union(selectedIds, [nodes.id], childrenIds)

      if (nodes.parentId) {
        ids = union(ids, selected(original, nodes.parentId, [...selectedIds, nodes.id], true))
      }

      onSelected(ids)
    } else {
      let unSelected: (number | string)[] = []
      const newProduct = selectedIds.filter((id: number | string) => id != nodes.id)
      if (nodes.parentId) {
        unSelected = selected(original, nodes.parentId, newProduct, false)
      }

      onSelected(
        selectedIds.filter(
          (id: number | string) =>
            id != nodes.id &&
            id != nodes.parentId &&
            !childrenIds.includes(id) &&
            !unSelected.includes(id)
        )
      )
    }
  }

  const labelClick = (e: React.SyntheticEvent) => setIsOpen(!isOpen)

  const isChecked = (): boolean => {
    if (count && nodes?.children?.length && count == nodes.children.length) return true
    return selectedIds?.includes(nodes.id) || false
  }

  const children = nodes.children as ITreeData[]
  const isEnable = !!enableNodes
    ? enableNodes?.length
      ? enableNodes.some(
          (el: ITreeNode) =>
            el.type != type ||
            (el.id == nodes.id &&
              (el.id == nodes.parentId ? true : nodes.parentId == el.parentIds[0]))
        )
      : true
    : false

  const disableCheckBox = (id: number) => {
    if (subFilter?.currentCompanyName) {
      return (isProduct && !!subFilter.product) || (isValueChain && !!subFilter.valueChains)
    }
    return false
  }

  const checkCollapseItems: boolean = useMemo(() => !isCollapsible || !nodes.children?.length, [
    isCollapsible,
    nodes,
  ])

  const matchNameSearch = (value: string) => value.toLowerCase().includes(searchValue.toLowerCase())

  const checkSearch: boolean = useMemo(
    () =>
      nodes.children!.some(i => matchNameSearch(i.name)) ||
      (matchNameSearch(nodes.name) && !nodes.children?.length),
    [nodes, searchValue]
  )

  return checkSearch ? (
    <FCTTreeItem
      key={nodes.id}
      nodeId={String(nodes.id)}
      icon={
        checkCollapseItems ? (
          <FCTCheckbox
            disabled={!isEnable || disableCheckBox(+nodes.id)}
            indeterminate={isIndeterminate}
            checked={isChecked()}
            onChange={(event, value) => setTimeout(() => changeValue(event, value), 0)}
          />
        ) : null
      }
      label={
        <>
          <div
            className={clsx(classes.groupLabel, { [classes.labelTitle]: index >= 0 })}
            style={{ margin: !checkCollapseItems ? '8px 0 8px -15px' : '' }}
          >
            <div className={classes.titleName}>
              <Typography
                className={classes.label}
                onClick={e => isCollapsible && changeValue(e, !isChecked())}
              >
                {nodes.name}
              </Typography>
              <TooltipDescription
                description={nodes.description}
                style={classes.infoDescription}
                isDescription={isDescription}
                id={nodes.id}
              />
            </div>
            {nodes.children && nodes.children.length > 0 && (
              <div className={classes.icon}>
                {isOpen ? (
                  <FCTIcon name="up" width="6" height="6" color={theme.palette.grey['600']} />
                ) : (
                  <FCTIcon name="down" width="6" height="6" color={theme.palette.grey['600']} />
                )}
              </div>
            )}
          </div>
        </>
      }
      onLabelClick={labelClick}
    >
      {children?.length
        ? children.map((node: ITreeData) => {
            return (
              <Tree
                key={node.id}
                nodes={node}
                selectedIds={selectedIds}
                onSelected={onSelected}
                original={original}
                enableNodes={enableNodes}
                isDescription={isDescription}
                searchValue={searchValue}
                isCollapsible={isCollapsible}
                isProduct={isProduct}
                isValueChain={isValueChain}
                type={type}
              />
            )
          })
        : null}
    </FCTTreeItem>
  ) : null
}

export interface ITreeNode {
  id: number | string
  parentIds: (number | string)[]
  type: TaxonomyType
}

export interface ICheckboxTree {
  dataTree: ITreeData[]
  originalData: IOriginalData[]
  selectedIds: (number | string)[]
  setFilter: (value: (number | string)[]) => void
  enableNodes?: ITreeNode[] | null
  defaultExpanded?: string[]
  expandedNodes?: string[]
  isDescription?: boolean
  isCollapsible?: boolean
  searchValue?: string
  handleExpandedNodes?: (v: ITreeData) => ITreeNode[] | null | undefined
  isProduct?: boolean
  isValueChain?: boolean
  type?: TaxonomyType
}

export const CheckboxTree = ({
  dataTree,
  originalData,
  selectedIds,
  setFilter,
  enableNodes = [],
  defaultExpanded,
  expandedNodes,
  isDescription,
  isCollapsible = false,
  searchValue = '',
  handleExpandedNodes,
  isProduct = false,
  isValueChain = false,
  type,
}: ICheckboxTree) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(defaultExpanded || expandedNodes)

  useEffect(() => {
    setExpanded(union(expandedNodes, expanded))
  }, [expandedNodes])

  return (
    <TreeView
      className={classes.root}
      multiSelect
      onNodeToggle={(event: any, nodeIds: string[]) => setExpanded(nodeIds)}
      expanded={expanded || []}
    >
      {dataTree?.length &&
        dataTree.map((item: ITreeData, index: number) => {
          return (
            <Tree
              key={item.id}
              nodes={item}
              selectedIds={selectedIds}
              onSelected={setFilter}
              index={index}
              original={originalData}
              enableNodes={handleExpandedNodes ? handleExpandedNodes(item) : enableNodes}
              isDescription={isDescription}
              isCollapsible={isCollapsible}
              searchValue={searchValue}
              isProduct={isProduct}
              isValueChain={isValueChain}
              type={type}
            />
          )
        })}
    </TreeView>
  )
}

import { useMutation, useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { omit } from 'lodash'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { VIEW_BY } from '../../core/constants/Filter'
import useGetSearchCompanies from '../../core/hooks/useGetSearchCompanies'
import { GetWatchListContext } from '../../features/watch-list/GetWatchList'
import { selectAnalysisFilter } from '../../store/features/analysis/AnalysisSelector'
import { selectCompanyDealFilter } from '../../store/features/company-deal/CompanyDealSelector'
import {
  useWatchListFilter,
  useWatchListId,
  useWatchListName,
} from '../../store/features/watch-list/WatchListHook'
import { UPDATE_WATCH_LIST_FILTER_CONDITIONS } from '../../store/operations/mutations/UpdateWatchlist'
import { GET_DEAL_FILTER_DATA } from '../../store/operations/queries/GetDealFilterData'
import { GET_WATCH_LIST_COMPANY_IDS } from '../../store/operations/queries/GetWatchListCompanyIds'
import { EMessageType, WatchListMessage } from '../../templates/WatchListHandling/WatchListMessage'
import { WatchListToastsError } from '../../templates/WatchListHandling/WatchListToastsError'
import {
  EToastType,
  WatchListToastsSuccess,
} from '../../templates/WatchListHandling/WatchListToastsSuccess'
import { ICompanyDealFilter } from '../../types/CompanyDealFilter'
import { IAnalysisFilter } from '../../types/Filter'
import { commonErrorHandler } from '../../utils/CommonErrorHandler'
import { getCompanyInput } from '../../utils/CompanyFilter'
import { stringifyFilterOptionWL } from '../../utils/WatchList'
import {
  GetDealFilterDataResult,
  GetDealFilterDataResult_getDealFilterData,
} from '../../__generated__/GetDealFilterDataResult'
import { GetWatchListCompanyIds } from '../../__generated__/GetWatchListCompanyIds'
import { ETypeDialog, FCTDialog } from '../Dialog/FCTDialog'
import { useGetSimilarCompaniesByKeywords } from 'core/hooks/useGetSimilarCompaniesByKeywords'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: 20,
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.grey[800],
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

interface IProps {
  confirmNavigate: boolean
  setConfirmNavigate: (value: boolean) => void
  navigateToDestination: () => void
  handleClose?: () => void
}

export const ModalUpdateWatchListFilter = ({
  confirmNavigate,
  setConfirmNavigate,
  navigateToDestination,
  handleClose = () => {},
}: IProps) => {
  const classes = useStyles()
  const { addToast } = useToasts()
  const history = useHistory()
  const currentPath = history.location.pathname

  const [watchListId, setWatchListId] = useWatchListId()
  const [watchListName, _] = useWatchListName()
  const getWatchList = useContext(GetWatchListContext)
  const [watchListFilter, setWatchListFilter] = useWatchListFilter()

  const { data: getFilterData } = useQuery(GET_DEAL_FILTER_DATA)
  const filterData = getFilterData?.getDealFilterData
  const listViewFilterData = getFilterData?.getDealFilterData
    ?.data as GetDealFilterDataResult_getDealFilterData

  const [updateWatchlistFilterConditions, { loading: updateLoading }] = useMutation(
    UPDATE_WATCH_LIST_FILTER_CONDITIONS
  )

  let filterToCompare: ICompanyDealFilter | IAnalysisFilter
  if (
    currentPath.includes('/sunburst') ||
    currentPath.includes('/landscape') ||
    currentPath.includes('/charts') ||
    currentPath.includes('/news')
  ) {
    filterToCompare = useSelector(selectAnalysisFilter)
  } else {
    filterToCompare = useSelector(selectCompanyDealFilter)
  }
  const { similarCompaniesIds } = useGetSimilarCompaniesByKeywords(filterToCompare, filterData)
  const dataName = VIEW_BY[filterToCompare.category].propName as keyof IAnalysisFilter
  const companySearchFilter = useGetSearchCompanies(filterToCompare)
  const payload = getCompanyInput(filterToCompare, listViewFilterData, companySearchFilter)
  const { data: getWatchListCompanyIds } = useQuery<GetWatchListCompanyIds>(
    GET_WATCH_LIST_COMPANY_IDS,
    {
      variables: {
        input: {
          ...payload,
          businessLinesRisksValueChains: filterToCompare[dataName],
          pageNumber: 0,
          pageSize: 10,
          selectedColumns: [],
          orderBy: [],
          watchListId: null,
          companyIds: [...(payload.companyIds || []), ...similarCompaniesIds],
        },
      },
    }
  )

  const selectedIds = (getWatchListCompanyIds?.getWatchListCompanyIds || [])?.map(e => String(e))

  const handleAgree = () => {
    updateWatchlistFilterConditions({
      variables: {
        input: {
          watchlistId: watchListId,
          filterOption: stringifyFilterOptionWL(filterToCompare, listViewFilterData),
          companyIds: selectedIds.map(e => +e),
        },
      },
    })
      .then(res => {
        navigateToDestination()
        setConfirmNavigate(false)
        setWatchListFilter(omit(filterToCompare, ['searchData', 'orderByCompany', 'orderByDeal']))
        addToast(
          <WatchListToastsSuccess
            type={EToastType.UpdateWatchListFilter}
            watchListName={watchListName || ''}
          />,
          {
            appearance: 'success',
          }
        )
        getWatchList.fnWatchList()
      })
      .catch(
        commonErrorHandler((err: any) => {
          addToast(<WatchListToastsError res={err.graphQLError.message} />, {
            appearance: 'error',
          })
        })
      )
  }

  const handleCancel = () => {
    navigateToDestination()
  }

  return (
    <FCTDialog
      open={confirmNavigate}
      setOpen={(value: boolean) => setConfirmNavigate(value)}
      title="Update Watchlist"
      type={ETypeDialog.Update}
      titleBtnAgree="Update"
      titleBtnCancel="Skip & Continue"
      handleAgree={handleAgree}
      handleCancel={handleCancel}
      minHeight={30}
      loadingBtnAgree={updateLoading}
      handleClose={handleClose}
    >
      <div className={classes.dialogContent}>
        <WatchListMessage type={EMessageType.WLUpdateFilter} watchListName={watchListName || ''} />
      </div>
    </FCTDialog>
  )
}
